import { documentTypes, imageTypes } from '@rmr/controllers'

export const acceptedFileTypes = (fileType: string): string[] => {
  return fileType === 'document' ? documentTypes : imageTypes
}

export const getExtension = (key: string) => {
  if (!key) return ''

  return key.split('.').pop()
}
