export const CONTENT_TYPES = {
  HERO: 'WpPage_Layoutsettings_Layout_Hero',
  HUBSPOT: 'WpPage_Layoutsettings_Layout_HubspotForm',
  CONTENTFORM: 'WpPage_Layoutsettings_Layout_ContentForm',
  HEROEARNINGS: 'WpPage_Layoutsettings_Layout_Heroearnings',
  BANNER: 'WpPage_Layoutsettings_Layout_Banner',
  SECTIONSPLIT: 'WpPage_Layoutsettings_Layout_SectionSplit',
  SECTIONFEATURES: 'WpPage_Layoutsettings_Layout_Features',
  SECTIONTESTIMONIALS: 'WpPage_Layoutsettings_Layout_Testimonials',
  SECTIONFEATURESSPLIT: 'WpPage_Layoutsettings_Layout_SectionFeaturesSplit',
  FAQS: 'WpPage_Layoutsettings_Layout_Faqs',
  FEATUREDVEHICLES: 'WpPage_Layoutsettings_Layout_FeaturedVehicles',
  CARDCAROUSEL: 'WpPage_Layoutsettings_Layout_SectionCardCarousel'
}

export const EARN_PARAMS = {
  base: 50000,
  baseDayRate: 180
}
// 10 MB
export const MAX_FILE_SIZE = 10485760

export const MAX_CHARACTERS = 300
export const VEHICLE_SEARCH_LIMIT = 200
export const FEATURED_VEHICLE_SEARCH_LIMIT = 10

export const facebookSharer = (url) =>
  `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)};src=sdkpreparse`
export const twitterSharer = (url) => `https://twitter.com/intent/tweet?text=${encodeURIComponent(url)}`
export const emailSharer = (url) =>
  `mailto:?subject=${encodeURIComponent('From Rent My Ride website')}&body=${encodeURIComponent(url)}`

export const GOOGLE_MAP_SCRIPT_BASE_URL = 'https://maps.googleapis.com/maps/api/js'
