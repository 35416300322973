import { QueryLazyOptions } from '@apollo/client'
import { Button, FormMessage, Heading, Link, MessageColor, Text } from '@rmr/components'
import { Exact, MeQuery, SubmitState } from '@rmr/controllers'
import { navigate } from 'gatsby'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { Box, Flex } from 'rebass'
import { languages } from '../../common/i18n'
import FacebookLogin from '../FacebookLogin'
import GoogleLogin, { ProviderType } from '../GoogleLogin'
import ReferralSignupForm from './ReferralSignupForm'

interface ReferralSignupStartFormProps extends Partial<StepWizardChildProps> {
  idRef: string
  getMe: (
    options?: QueryLazyOptions<
      Exact<{
        [key: string]: never
      }>
    >
  ) => void
  user: MeQuery['me']
  formHeadline: string
}

const ReferralSignupStart: FC<ReferralSignupStartFormProps> = ({
  formHeadline,
  idRef,
  getMe,
  user,
  nextStep,
  lastStep
}) => {
  const onSubmit = useCallback(
    (submitResult: SubmitState) => {
      console.log(submitResult)
    },
    [getMe]
  )

  return (
    <Box>
      <Heading size="h5" fontWeight="extraBold" mb={6} textAlign="left">
        {formHeadline || languages.en.referralSignup.headline}
      </Heading>
      <Box>
        <ReferralSignupForm idRef={idRef} />
      </Box>
      <Box mb={2}>
        <FacebookLogin onSubmit={onSubmit} providerType={ProviderType.Register} />
      </Box>
      <Box mb={4}>
        <GoogleLogin onSubmit={onSubmit} providerType={ProviderType.Register} />
      </Box>

      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="center"
        mt={2}
        flexWrap={'wrap'}
      >
        <Link to="/app/login/">
          <Text size="body" fontWeight="medium" mr={[0, 0, 2]} mb={[2, 2, 0]}>
            {languages.en.auth.signup.haveAccount}
          </Text>
        </Link>
        <Link to="/app/login/">
          <Button
            variant="transparent"
            label={languages.en.auth.signup.haveAccountButtonText}
            sx={{ py: 0, fontSize: 'medium' }}
          />
        </Link>
      </Flex>
    </Box>
  )
}
export default ReferralSignupStart
