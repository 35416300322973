import React, { FC } from 'react'
import { Box, BoxProps } from 'rebass'

import Testimonial, { TestimonialProps } from '../Testimonial'
import Carousel from '../Carousel'
import { carouselStyles, testimonialStyles } from './TestimonialsCarousel.styled'

export interface TestimonialsCarouselProps extends BoxProps {
  /** Array of Vehicle Cards */
  testimonials: TestimonialProps[]
}

const TestimonialsCarousel: FC<TestimonialsCarouselProps> = ({ testimonials, ...props }) => {
  return (
    <Box {...props} sx={carouselStyles}>
      <Carousel variant="testimonialsCarousel">
        {testimonials.map((car, index) => (
          <Box key={index}>
            <Testimonial {...car} height="100%" sx={testimonialStyles} />
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}
TestimonialsCarousel.defaultProps = {
  testimonials: []
}

export default TestimonialsCarousel
