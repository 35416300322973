import { Carousel, Text } from '@rmr/components'
import React from 'react'
import { Box, Flex, Image } from 'rebass'
import ListCarInstruction1 from '../../images/list-a-car-instruction1.png'
import ListCarInstruction2 from '../../images/list-a-car-instruction2.png'
import ListCarInstruction3 from '../../images/list-a-car-instruction3.png'

const ImageInfoCarousel = () => {
  return (
    <>
      <Text size="large" fontWeight="bold" textAlign="center">
        Taking photos of your car
      </Text>
      <Box
        sx={{
          textAlign: 'center',
          mx: 'auto',
          width: '40px',
          borderBottom: '2px solid',
          borderColor: 'black',
          mt: [1],
          mb: [4]
        }}
      />
      <Carousel variant="instructionsCarousel">
        <Content
          caption={'Be sure the front image of your car is in the centre of the photo and taken at hip height.'}
          image={ListCarInstruction1}
        />
        <Content
          caption={
            'Take the side view image of your car so that you can see the front and the rear bumper in the picture.'
          }
          image={ListCarInstruction2}
        />
        <Content
          caption={
            'The rear view of your car also needs to be centered in the middle of the photo and taken from hip height.'
          }
          image={ListCarInstruction3}
        />
      </Carousel>
    </>
  )
}

const Content = (props: { image?: string; caption: string }) => {
  const { image, caption } = props

  return (
    <Flex alignItems="center" flexDirection="column">
      {image && (
        <Box mb={[4]}>
          <Image src={image} sx={{ height: '300px' }} />
        </Box>
      )}
      <Text size="small" color="textGrayLight" textAlign="center" mb={[2]}>
        {caption}
      </Text>
    </Flex>
  )
}

export default ImageInfoCarousel
