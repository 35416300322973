import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import theme from '../../theme'

export const StyledHero = styled(Flex)`
  min-height: ${(props) => (props.type === 'calculator' ? '60vh' : props.type === 'small' ? 'unset' : '80vh')};
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  em {
    font-weight: ${theme.fontWeights.extraBold};
    font-style: inherit;
  }
`

export const StyledOverlay = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  pointer-events: none;
`
export const StyledContent = styled(Box)`
  z-index: 1;
`

export const StyledImages = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
