import React, { FC } from 'react'
import { Container } from '../Grid'
import Heading from '../Heading'
import { StyledContentPoster, StyledSectionImagePoster } from './SectionFeatures.styled'
import theme from '../../theme'
import { SectionFeaturesTypes } from './SectionFeatures'
import { Box, Flex } from 'rebass'

const SectionFeaturesPoster: FC<SectionFeaturesTypes> = ({
  headline,
  headlineColor,
  sectionImage,
  bgColor,
  children,
  content,
  sx,
  ...props
}) => {
  return (
    <Box {...props} bg={bgColor} pt={[4, 6]} sx={{ ...sx }}>
      <Container>
        <StyledContentPoster>
          {headline && (
            <Heading
              size="h5"
              fontWeight={theme.fontWeights.extraBold}
              textAlign={['center', 'center', 'left']}
              color={headlineColor}
              mb={[2, 4]}
            >
              {headline}
            </Heading>
          )}
          <Flex flexDirection={['column', 'column', 'row']} alignItems={['flex-start']}>
            {children && (
              <Box mb={[2]} width={['100%', '100%', '50%']}>
                {children}
              </Box>
            )}
            {sectionImage && (
              <StyledSectionImagePoster ml={[0, 0, 10]} width={['100%', '100%', '50%']}>
                {sectionImage}
              </StyledSectionImagePoster>
            )}
          </Flex>
        </StyledContentPoster>
      </Container>
    </Box>
  )
}

SectionFeaturesPoster.defaultProps = {
  headline: '',
  headlineColor: 'textDark',
  bgColor: 'white',
  variant: 'featuresPoster',
  sx: {}
}

export default SectionFeaturesPoster
