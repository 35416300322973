import React, { useEffect } from 'react'
import { Box, Flex } from 'rebass'

import { BadgeProps, StepsWrapper, StepsWizard, WizardStep } from '@rmr/components'
import { wrapperStyles, stepsWizzardStyles, stepsWrapperStyles } from './Layout.styled'
import StepsNavigation, { scrollToHeadline } from '../../StepsNavigation'

interface LayoutProps {
  badgeProps?: BadgeProps
  title?: string
  subtitle?: string
  currentStep?: number
  previousStep?: () => void
  buttonNextClick?: () => void
  buttonBacklabel?: string
  buttonNextlabel?: string
  finishLaterButton?: boolean
  finishLaterLabel?: string
  children?: React.ReactNode
  idRef?: string
  steps?: WizardStep[]
  isCompleted?: boolean
  separator?: boolean
  separatorColor?: string
}

const Layout = ({
  children,
  badgeProps,
  title,
  subtitle,
  separator,
  separatorColor,
  currentStep,
  previousStep,
  buttonNextClick,
  buttonBacklabel,
  buttonNextlabel,
  finishLaterButton,
  finishLaterLabel,
  idRef,
  steps,
  isCompleted
}: LayoutProps) => {
  useEffect(() => {
    scrollToHeadline(idRef)
  }, [idRef])
  return (
    <React.Fragment>
      <Box id={idRef} sx={{ position: 'relative', bottom: [100] }} />

      <StepsWrapper
        badgeProps={badgeProps}
        title={title}
        subtitle={subtitle}
        separator={separator}
        separatorColor={separatorColor}
        sx={stepsWrapperStyles}
      >
        <Flex sx={wrapperStyles}>
          <Box id={idRef} sx={{ position: 'relative', bottom: [100] }} />
          {steps.length ? (
            <Box sx={stepsWizzardStyles}>
              <StepsWizard steps={steps} withSides={true} currentStep={currentStep} isCompleted={isCompleted} />
            </Box>
          ) : null}
          {children}
          <StepsNavigation
            idRef={idRef}
            currentStep={currentStep}
            previousStep={previousStep}
            buttonNextClick={buttonNextClick}
            buttonBacklabel={buttonBacklabel}
            buttonNextlabel={buttonNextlabel}
            finishLaterButton={finishLaterButton}
            finishLaterLabel={finishLaterLabel}
          />
        </Flex>
      </StepsWrapper>
    </React.Fragment>
  )
}

export default Layout
