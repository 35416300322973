import * as React from 'react'
import { addressToString, Reservation } from '@rmr/controllers'
import {
  Container,
  StepsWrapper,
  Button,
  Separator,
  Text,
  Link,
  VehicleCardMobile,
  ListItems,
  ListItem,
  Radio,
  Icon
} from '@rmr/components'
import { Box, Flex } from 'rebass'
import { stepsWrapperStyles } from '../ApprovalToList/GetApprovedList/GetApprovedList.styled'
import { languages } from '../../common/i18n'
import Dates from './ReservationQuote/Dates'
import { itemStyles, reservationWrapperStyles } from './ReservationQuote/ReservationQuote.styled'

interface ReservationProps {
  reservation: Reservation
}

export const ReservationConfirmation: React.FC<ReservationProps> = ({ reservation }) => {
  const { vehicle, lineItems, address } = reservation

  return (
    <Box as="section" sx={{ pt: [15], pb: [6] }}>
      <Container>
        <StepsWrapper
          badgeProps={{ variant: 'circle', size: [150], icon: 'check', color: 'statusSuccess' }}
          title={languages.en.bookingConfirmation.title}
          subtitle={languages.en.bookingConfirmation.subtitle}
          sx={stepsWrapperStyles}
        >
          <Box sx={reservationWrapperStyles}>
            <Box>
              <Box mb={[3]}>
                <VehicleCardMobile
                  // price={vehicle.pricePerDay.formatted}
                  // priceDistribution="daily"
                  carName={vehicle.name}
                  image={vehicle.images[0].file.url}
                  totalTrips={vehicle.stats.trips}
                  rating={vehicle.stats.ratings}
                  language="en"
                  boxShadow={false}
                />
              </Box>
              <ListItems>
                {lineItems.map((line, index) => {
                  return <ListItem {...line} key={line.title} />
                })}
              </ListItems>
            </Box>
            <Box>
              <Dates startDate={reservation.dates.startFullDate} endDate={reservation.dates.endFullDate} />
              <Box sx={itemStyles}>
                <Text size="large" color="textBlack" fontWeight="bold" mb={3}>
                  {languages.en.booking.deliverOptions}
                </Text>
                {address && (
                  <Box>
                    <Text size="small" color="textBlack" fontWeight="bold">
                      {languages.en.booking.addressLabel}
                    </Text>
                    <Flex alignItems="center">
                      <Text size="small" color="textLight">
                        {addressToString(address)}
                      </Text>
                      {address.placeId && (
                        <RebassLink
                          href={`https://www.google.com/maps/place/?q=place_id:${address.placeId}`}
                          flexShrink={0}
                          ml={2}
                          target="_blank"
                        >
                          <Icon name="navigation" color="primary" />
                        </RebassLink>
                      )}
                    </Flex>
                  </Box>
                )}
                {reservation.vehicle.is_deliver_renter ? (
                  <Radio
                    name="isDropoff"
                    variant={'vertical'}
                    disabled
                    options={[
                      {
                        label: `Vehicle Drop-off - ${
                          !reservation.vehicle.deliveryFeeRenter ||
                          reservation.vehicle.deliveryFeeRenter.amountFloat === 0
                            ? 'Free'
                            : reservation.vehicle.deliveryFeeRenter.formatted
                        }`,
                        value: 1
                      }
                    ]}
                    selectedOption={reservation.vehicle.is_deliver_renter ? 1 : 0}
                  />
                ) : null}
              </Box>
              <Box marginY={[2]}>
                {languages.en.bookingConfirmation.textLines.map((text, index) => (
                  <Text key={index} size="small" color="textDark" textAlign="left" mb={2}>
                    {text}
                  </Text>
                ))}
                <a href={`${process.env.GATSBY_NX_DASHBOARD_LINK}/reservations/${reservation.id}`}>
                  <Button width={[1]} variant="primary">
                    {languages.en.bookingConfirmation.buttonText}
                  </Button>
                </a>
              </Box>
            </Box>
          </Box>
        </StepsWrapper>
      </Container>
    </Box>
  )
}

export default ReservationConfirmation
