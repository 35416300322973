import React, { FC } from 'react'
import { Box, Flex, Image } from 'rebass'
import Heading from '../../Heading'
import Button from '../../Button'
import Icon from '../../Icon'
import Text from '../../Text'
import languages from '../../../i18n'

const outlineButtonStyles = {
  height: [50, 80]
}
const primaryButtonStyles = {
  height: [50, 80],
  width: [205]
}

const GoogleLogo = () => <Image src={'/google.png'} alt="Google logo" />
const FacebookLogo = () => <Image src={'/facebook.png'} alt="Facebook logo" />

export interface LoginDashboardProps {
  /** Continue with email handler */
  emailHandler: () => void
  /** Continue with Facebook handler */
  facebookHandler: () => void
  /** Continue with Google handler */
  googleHandler: () => void
  /** Login handler */
  loginHandler: () => void
}

const LoginDashboard: FC<LoginDashboardProps> = ({ emailHandler, facebookHandler, googleHandler, loginHandler }) => {
  return (
    <Box maxWidth={500}>
      <Heading size="h3" fontWeight="extraBold" mb={4} textAlign="center">
        Welcome to RentMyRide{' '}
      </Heading>
      <Button
        label="Continue with Email"
        variant="outline"
        fullWidth
        mb={2}
        sx={outlineButtonStyles}
        icon={<Icon name="envelope" sx={{ fontSize: [30] }} />}
        onClick={emailHandler}
      />
      <Button
        label="Continue with Facebook"
        variant="outline"
        fullWidth
        mb={2}
        sx={outlineButtonStyles}
        icon={<FacebookLogo />}
        onClick={facebookHandler}
      />
      <Button
        label="Continue with Google"
        variant="outline"
        fullWidth
        sx={outlineButtonStyles}
        icon={<GoogleLogo />}
        onClick={googleHandler}
      />
      <Flex flexDirection={['column', 'row']} alignItems="center" justifyContent="space-between" mt={4}>
        <Text size="body" fontWeight="medium" mb={[2, 0]}>
          {languages.en.auth.haveAccount}
        </Text>
        <Button variant="primary" label="Login" sx={primaryButtonStyles} onClick={loginHandler} />
      </Flex>
    </Box>
  )
}
export default LoginDashboard

LoginDashboard.defaultProps = {
  emailHandler: () => null,
  facebookHandler: () => null,
  googleHandler: () => null,
  loginHandler: () => null
}
