import React, { forwardRef } from 'react'
import { Box, SxStyleProp, BoxProps } from 'rebass'
import languages from '../../i18n'
import Text from '../Text'
import Checkbox from '../Form/Checkbox'

import { checkboxStyles, tcTextStyles } from './TermsAndConditions.styled'
import { CheckboxProps } from '@rebass/forms'

export interface TermsAndConditionsProps extends BoxProps {
  /** Name field - for forms */
  name: string
  /** Terms Label */
  label?: string
  /** Terms Description */
  description?: string
  /** Error text */
  error?: string
  /** Error text */
  checkboxProps?: CheckboxProps
  /** Sx prop styles */
  sx?: SxStyleProp
}

const TermsAndConditions = forwardRef<HTMLInputElement, TermsAndConditionsProps>(
  ({ name, label, description, error, checkboxProps, sx, ...props }, ref) => {
    const labelToUse = typeof label === 'string' ? label : languages.en.tc.acceptLabel
    const descriptionToUse = typeof description === 'string' ? description : languages.en.tc.text
    return (
      <Checkbox
        name={name}
        sx={{ ...checkboxStyles, ...sx }}
        textAlign={['left']}
        rebassCheckboxProps={checkboxProps}
        ref={ref}
        error={error}
        {...props}
      >
        <Box ml={[2]}>
          {!!labelToUse && (
            <Text fontWeight="bold" size="small">
              {labelToUse}
            </Text>
          )}
          {!!descriptionToUse && (
            <Text size="tiny" dangerouslySetInnerHTML={{ __html: descriptionToUse }} sx={tcTextStyles} />
          )}
        </Box>
      </Checkbox>
    )
  }
)

TermsAndConditions.defaultProps = {
  error: '',
  checkboxProps: {},
  sx: {}
}

export default TermsAndConditions
