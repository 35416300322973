import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Box } from 'rebass'
import { Container } from '@rmr/components'

const HubspotWrapper = ({ portalId, formId }) => {
  useEffect(() => {
    if (portalId && formId) {
      const script = document.createElement('script')
      // script.src = 'js-eu1.hsforms.net/forms/shell.js'
      script.src = 'https://js.hsforms.net/forms/shell.js'
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.hbspt) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.hbspt.forms.create({
            region: 'eu1',
            portalId: portalId,
            formId: formId,
            target: '#hubspotForm'
          })
        }
      })
    }
  }, [formId, portalId])

  return (
    <Box as="section" py={[6, 10]}>
      <Container>
        <div id="hubspotForm"></div>
      </Container>
    </Box>
  )
}

export default HubspotWrapper

export const HubspotFields = graphql`
  fragment HubspotFields on WpPage_Layoutsettings_Layout_HubspotForm {
    formId
    portalId
  }
`
