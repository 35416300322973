import { useState, useReducer, useEffect } from 'react'

type Measure = (element: HTMLElement | null, index: number) => void

interface State {
  dimensions: number[]
  totalMeasurements: number
}

interface Action {
  type: string
  payload: {
    index: number
    dimension: number
  }
}

const reducer = (state: State, action: Action) => {
  const { dimensions, totalMeasurements } = state
  const { type, payload } = action
  const { index, dimension } = payload
  switch (type) {
    case 'add':
      if (dimension && dimension > 0 && dimension !== dimensions[index]) {
        let addMeasurement = 0
        if (!dimensions[index]) {
          addMeasurement = 1
        }
        dimensions[index] = dimension
        return {
          dimensions,
          totalMeasurements: totalMeasurements + addMeasurement
        }
      }
      return state
    case 'maxDimension':
    default:
      throw state
  }
}

const useMaxDimension = (totalElements: number, property = 'clientHeight'): [number | null, Measure, Array<number>] => {
  const [maxDimension, setMaxDimension] = useState<number | null>(null)

  const initialState: State = {
    dimensions: Array(totalElements).fill(0),
    totalMeasurements: 0
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const measure: Measure = (element, index) => {
    if (element && element[property] && element[property] !== state.dimensions[index]) {
      dispatch({
        type: 'add',
        payload: { index, dimension: element[property] }
      })
    }
  }

  useEffect(() => {
    if (state.totalMeasurements === totalElements) {
      const maxMeasurement = Math.max(...state.dimensions)
      if (maxMeasurement !== maxDimension) {
        setMaxDimension(maxMeasurement)
      }
    }
  })

  return [maxDimension, measure, state.dimensions]
}

export default useMaxDimension
