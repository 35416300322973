import { SxStyleProp } from 'rebass'

export const wrapperStyles: SxStyleProp = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: [1],
  cursor: 'pointer'
}

export const copiedStyles: SxStyleProp = {
  position: 'absolute',
  bottom: 'calc(100% + 5px)',
  left: '50%',
  transform: 'translateX(-50%)'
}
