import {
  Button,
  Container,
  Separator,
  StepsWrapper,
  Text,
  CopyToClipboard,
  IconWithContent,
  Emblem,
  Badge,
  WhatsappShare
} from '@rmr/components'
import * as React from 'react'
import { useLocation } from '@reach/router'
import { Box, Flex } from 'rebass'
import { languages } from '../../common/i18n'
import { stepsWrapperStyles } from '../ApprovalToList/GetApprovedList/GetApprovedList.styled'
import { tagStyle } from '../ListYourCar/ListingGuideline/ListingGuideline.styled'
import LoginForm from '../LoginForm'
import { WithUserProps } from '../PrivateRoute'
// import ReferralSignup from '../ReferralSignup'
import { lineStyles } from './ReferAFriend.styled'
import ReferByEmail from './ReferByEmail'
import { navigate } from 'gatsby'

const explainerIcons = ['speaker', 'carSide', 'faceMoney']

export const ReferAFriend: React.FC<WithUserProps> = ({ user }) => {
  const { origin } = useLocation()
  const [isEmailOpen, setIsEmailOpen] = React.useState(false)
  const onOpenEmail = () => setIsEmailOpen(true)
  const onCloseEmail = () => setIsEmailOpen(false)
  React.useEffect(() => {
    if (!user?.profile?.referralCode) {
      navigate('/app/create/profile/', { state: { fromReferAFriend: true } })
    }
  }, [user?.profile?.referralCode])
  return (
    <Box as="section" sx={{ pt: [15], pb: [6] }}>
      <Container>
        <StepsWrapper
          badgeProps={{ variant: 'circle', size: [150], image: <Emblem width="auto" height="80" />, color: 'primary' }}
          title={languages.en.referFriend.title}
          subtitle={languages.en.referFriend.subtitle}
          sxBody={{ maxWidth: 700 }}
          sx={stepsWrapperStyles}
        >
          <Box sx={{ position: 'relative' }} alignSelf="stretch">
            <Box sx={lineStyles} />
            <Flex
              flexDirection={['column', 'row']}
              justifyContent="center"
              alignItems={['center', 'start']}
              sx={{ gap: 3 }}
              mt={4}
            >
              {explainerIcons.map((icon, index) => (
                <Flex key={index} flexDirection="column" alignItems="center" sx={{ gap: 2 }} maxWidth={200} flex={1}>
                  <Box sx={{ position: 'relative' }}>
                    <Badge variant="circleOutlined" icon={icon} color="bgLight" contentColor="black" size={[120]} />
                    <Badge
                      variant="circle"
                      size={[40]}
                      color="primary"
                      contentColor="textWhite"
                      label={`${index + 1}`}
                      sx={tagStyle}
                    />
                  </Box>
                  <Text size="small" color="textLight" textAlign="center">
                    {languages.en.referFriend.explainers[index]}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Box>
          <Box py={[4]} px={[4]}>
            <Flex sx={{ justifyContent: 'center', mb: 5 }}>
              <Separator bg="primary" width={[87]} />
            </Flex>

            {user ? (
              <>
                {languages.en.referFriend.textLinesLoggedIn.map((text, index) => (
                  <Text
                    key={index}
                    size="medium"
                    color="textLight"
                    textAlign="center"
                    mb={2}
                    maxWidth={550}
                    margin="0 auto"
                  >
                    {text}
                  </Text>
                ))}
                <CopyToClipboard
                  variant="showLink"
                  icon="copy"
                  copyText={`${origin}/refer-a-friend/signup${
                    user?.profile?.referralCode ? `?ref=${user?.profile?.referralCode}` : ''
                  }`}
                  sx={{ '> div': { margin: '0 auto' }, mt: 4 }}
                />
                <Flex sx={{ justifyContent: 'center', alignItems: 'start', gap: 3, mt: 3 }}>
                  <Box sx={{ width: 100 }}>
                    <IconWithContent
                      headline="Email"
                      icon="envelopeOutline"
                      iconPosition="top"
                      sx={{ cursor: 'pointer' }}
                      onClick={onOpenEmail}
                    />
                  </Box>
                  <Box sx={{ width: 100 }}>
                    <CopyToClipboard
                      variant="iconText"
                      copyText={`${origin}/refer-a-friend/signup${
                        user?.profile?.referralCode ? `?ref=${user?.profile?.referralCode}` : ''
                      }`}
                    />
                  </Box>
                  <Box sx={{ width: 100 }}>
                    <WhatsappShare
                      copyText={`${languages.en.referFriend.whatsappText} ${origin}/refer-a-friend/signup${
                        user?.profile?.referralCode ? `?ref=${user?.profile?.referralCode}` : ''
                      }`}
                    />
                  </Box>
                </Flex>

                <Flex
                  flexDirection={['column', 'row']}
                  sx={{ alignItems: 'center', justifyContent: 'center', mt: 6, gap: 2 }}
                >
                  <a href={`/refer-a-friend/terms-and-conditions/`} target="_blank" rel="noreferrer">
                    <Button variant="transparent" sx={{ p: 0 }}>
                      {languages.en.referFriend.termsLinkTest}
                    </Button>
                  </a>
                  <a href={`${process.env.GATSBY_NX_DASHBOARD_LINK}`}>
                    <Button variant="transparent" sx={{ p: 0 }}>
                      {languages.en.referFriend.buttonText}
                    </Button>
                  </a>
                </Flex>
              </>
            ) : (
              <>
                {languages.en.referFriend.textLinesNotLoggedIn.map((text, index) => (
                  <Text
                    key={index}
                    size="medium"
                    color="textLight"
                    textAlign="center"
                    mb={2}
                    maxWidth={550}
                    margin="0 auto"
                  >
                    {text}
                  </Text>
                ))}
                <Box maxWidth={500} mx="auto" mt="3">
                  {/* <ReferralSignup /> */}
                  <LoginForm />
                </Box>
              </>
            )}
          </Box>
        </StepsWrapper>
      </Container>
      <ReferByEmail isOpen={isEmailOpen} onClose={onCloseEmail} />
    </Box>
  )
}

export default ReferAFriend
