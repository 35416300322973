import React from 'react'
import colors from '../../theme/colors'

import Svg, { SvgProps } from '../Svg'

const Emblem = (props: SvgProps) => {
  return (
    <Svg {...props}>
      <svg viewBox="0 0 105.83335 105.83334">
        <rect y="0" x="0" height="105.83334" width="105.83334" id="rect843" strokeWidth="0.264583" fill="none" />
        <g
          transform="translate(-70.842939,-56.334421)"
          id="layer1"
          display="inline"
          fill="currentcolor"
          fillOpacity={1}
        >
          <g
            transform="matrix(1.3551003,0,0,1.3551003,72.981799,56.334421)"
            id="g865"
            fill="currentcolor"
            fillOpacity={1}
          >
            <path
              id="path8"
              transform="translate(-15.63,-15.69)"
              d="M 67.52,42.67 A 22.54,22.54 0 0 0 52.89,48.05 22.62,22.62 0 0 0 15.63,65.3 v 28.49 h 16 V 65.3 a 6.58,6.58 0 0 1 13.16,0 v 28.49 h 16.1 V 65.3 a 6.58,6.58 0 0 1 13.16,0 v 28.49 h 16.1 V 65.3 A 22.65,22.65 0 0 0 67.52,42.67 Z"
              fill="currentcolor"
              fillOpacity={1}
            />
            <ellipse id="ellipse10" ry="11.73" rx="12.1" cy="11.73" cx="22.66" fill="currentcolor" fillOpacity={1} />
            <ellipse id="ellipse12" ry="11.73" rx="12.1" cy="11.75" cx="51.93" fill="currentcolor" fillOpacity={1} />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

Emblem.defaultProps = {
  width: '50px',
  color: 'baseWhite',
  sx: {}
}

export default Emblem
