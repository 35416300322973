import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateAccountInput, useUploadUserImageMutation } from '../lib/generated/generated'

import { useSubmitResult } from './useSubmitResult'
import { validationMessages } from '../i18n'

import { InputValidator, resizeImageFile } from '@rmr/helpers'
import { parseMobileNumber } from '../helpers/phoneNumber'
import { ApprovalIntention } from '../types'

type FormData = {
  country: string
  phoneNumber: string
  terms: boolean
}

type InputData = FormData & { avatarReady: boolean; intention: ApprovalIntention[] }
//       const input = { phoneNumber: parsedPhoneNumber, countryCode, ...rest, avatar, meta }
export type SocialSignupFormResponseData = Pick<
  CreateAccountInput,
  'phoneNumber' | 'terms' | 'avatar' | 'meta' | 'country' | 'countryCode'
>

interface useSocialRegistrationFormArgs {
  onSuccess: (response: SocialSignupFormResponseData) => void
  onError: (e: Error) => void
  avatarEditor: any
  firstName?: string
  lastName?: string
}

const signupSocialFormSchema = yup.object().shape({
  firstName: yup.string().required(validationMessages.en.required),
  lastName: yup.string().required(validationMessages.en.required),
  country: yup.string().required(validationMessages.en.required),
  phoneNumber: yup
    .string()
    .required(validationMessages.en.required)
    .test('phoneNumber', validationMessages.en.mobileNumber, function (value) {
      const inputValidator = new InputValidator(value)
      if (!inputValidator.validateCellphone()) return false
      return true
    }),
  terms: yup
    .bool()
    .required(validationMessages.en.required)
    .test('terms', validationMessages.en.termsAndConditions, (value) => {
      return !!value
    }),
  avatarReady: yup.bool().oneOf([true], validationMessages.en.avatar),
  intention: yup.array().min(1, validationMessages.en.oneCheckbox)
})

export function useSocialRegistrationForm({
  onSuccess,
  onError,
  avatarEditor,
  firstName,
  lastName
}: useSocialRegistrationFormArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(signupSocialFormSchema),
    defaultValues: {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      country: 'ZA',
      phoneNumber: '',
      terms: false,
      avatarReady: false,
      intention: []
    }
  })

  const [uploadUserImageMutation, { loading: loadingUploadUserImage }] = useUploadUserImageMutation()

  const onSubmit = useCallback(
    async (data: InputData) => {
      try {
        // proces profile image
        const imageCanvas = avatarEditor.getImage()

        const makeFile = new Promise((resolve) => {
          imageCanvas.toBlob((blob) => {
            let extension = ''
            if (blob.type) {
              const blobTypeArray = blob.type.split('/')
              if (Array.isArray(blobTypeArray) && blobTypeArray[1]) {
                extension = blobTypeArray[1]
              }
            }
            const imageFile = new File([blob], `avatar${extension ? `.${extension}` : ''}`, { type: blob.type })
            resolve(imageFile)
          })
        })
        const imageFile = (await makeFile) as File

        // upload profile image
        let avatar = ''
        if (imageFile) {
          const resizedFile = (await resizeImageFile({ file: imageFile })) as File
          const response = await uploadUserImageMutation({
            variables: {
              file: resizedFile
            }
          })
          avatar =
            response.data.uploadUserImage && response.data.uploadUserImage.url ? response.data.uploadUserImage.url : ''
        }

        const { phoneNumber, avatarReady, intention, ...rest } = data

        const isOwner = intention.includes(ApprovalIntention.Owner)
        const isRenter = intention.includes(ApprovalIntention.Renter)
        const meta = { isOwner, isRenter }
        let parsedPhoneNumber, countryCode
        if (phoneNumber) {
          const phoneNumberObject = parseMobileNumber(phoneNumber)
          parsedPhoneNumber = phoneNumberObject?.number
          countryCode = phoneNumberObject?.countryCallingCode ? `+${phoneNumberObject.countryCallingCode}` : ''
        } else {
          parsedPhoneNumber = undefined
          countryCode = undefined
        }
        const input = {
          phoneNumber: parsedPhoneNumber,
          countryCode,
          ...rest,
          avatar,
          meta
        } as SocialSignupFormResponseData
        onSuccess(input)
      } catch (err) {
        onError(err)
        onSubmitError('error', err.message)
      }
    },
    [avatarEditor, onSuccess, uploadUserImageMutation, onError, onSubmitError]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loadingUploadUserImage,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  }
}
