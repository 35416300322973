import { yupResolver } from '@hookform/resolvers/yup'
import { Button, FormMessage, Input, Loader, MessageColor, Text } from '@rmr/components'
import { useApplyCouponCodeMutation, useRemoveCouponCodeMutation } from '@rmr/controllers'
import { processCouponCode } from '@rmr/helpers'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Flex } from 'rebass'
import * as yup from 'yup'
import { languages } from '../../common/i18n'

const {
  couponLabel,
  appliedCouponLabel,
  couponPlaceholder,
  couponApplyButton,
  couponApplyButtonProcessing,
  couponRemoveButton,
  couponRemoveButtonProcessing,
  successAppliedCoupon,
  successRemoveCoupon,
  validationCouponMessage,
  applyCouponErrorMessage,
  removeCouponErrorMessage
} = languages.en.booking

const schema = yup.object().shape({
  couponCode: yup.string().required(validationCouponMessage)
})

interface ApplyRemoveCouponProps {
  id: string
  appliedCoupon: string
  onRefetch: () => void
}
const ApplyRemoveCoupon = ({ id, appliedCoupon, onRefetch }: ApplyRemoveCouponProps) => {
  const [successMessage, setSuccessMessage] = useState('')
  const { register, handleSubmit, watch, errors, setValue, clearErrors, setError, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { couponCode: appliedCoupon ?? '' }
  })
  const couponCode = watch('couponCode')
  const onCouponCodeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue('couponCode', processCouponCode(e.target.value))
  }

  const [applyCouponCode, { loading: loadingApply }] = useApplyCouponCodeMutation({
    onCompleted: ({ applyCouponCode }) => {
      const { __typename } = applyCouponCode
      if (__typename === 'Reservation') {
        setSuccessMessage(successAppliedCoupon)
        onRefetch()
        setTimeout(() => setSuccessMessage(''), 3000)
      } else {
        setError('couponCode', { message: validationCouponMessage })
      }
    },
    onError: (error) => {
      console.log({ error })
      setError('couponCode', { message: applyCouponErrorMessage })
    }
  })

  const [removeCouponCode, { loading: loadingRemove }] = useRemoveCouponCodeMutation({
    onCompleted: ({ removeCouponCode }) => {
      const { __typename } = removeCouponCode
      if (__typename === 'Reservation') {
        setSuccessMessage(successRemoveCoupon)
        setValue('couponCode', '')
        onRefetch()
        setTimeout(() => setSuccessMessage(''), 3000)
      } else {
        setError('couponCode', { message: validationCouponMessage })
      }
    },
    onError: (error) => {
      console.log({ error })
      setError('couponCode', { message: removeCouponErrorMessage })
    }
  })

  const processing = loadingApply || loadingRemove
  const onSubmit = async (data) => {
    const { couponCode } = data
    const couponCodeMutation = appliedCoupon ? removeCouponCode : applyCouponCode
    try {
      await couponCodeMutation({
        variables: { id, couponCode }
      })
    } catch (error) {
      console.log({ error })
    }
  }

  const heading = appliedCoupon ? appliedCouponLabel : couponLabel
  const placeholder = appliedCoupon ?? couponPlaceholder
  const isDisabled = processing || !couponCode
  const buttonLabel = processing
    ? appliedCoupon
      ? couponRemoveButtonProcessing
      : couponApplyButtonProcessing
    : appliedCoupon
    ? couponRemoveButton
    : couponApplyButton

  return (
    <>
      {processing && <Loader />}
      <Text size="large" color="textBlack" fontWeight="bold" mb={2}>
        {heading}
      </Text>
      <Flex as="form" flexDirection={['column', 'row']} alignItems="start">
        <Input
          type="text"
          name="couponCode"
          placeholder={placeholder}
          rebassInputProps={{
            readOnly: !!appliedCoupon,
            autoComplete: 'off',
            value: couponCode,
            onChange: (e) => {
              onCouponCodeChange(e)
              errors && errors.couponCode && errors.couponCode.message && clearErrors('couponCode')
            }
          }}
          ref={register}
          sx={{ flex: '1 0 auto', mr: [0, 2], mb: [2, 0] }}
          innerSx={{ height: 48 }}
          error={errors.couponCode && errors.couponCode.message}
        />
        <Button disabled={isDisabled} variant="black" mx={[null, 'auto']} onClick={handleSubmit(onSubmit)}>
          {buttonLabel}
        </Button>
      </Flex>
      <FormMessage
        size="small"
        showMessage={!!successMessage}
        message={successMessage}
        color={'statusSuccess' as MessageColor}
      />
    </>
  )
}

export default ApplyRemoveCoupon
