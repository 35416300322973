import { graphql, navigate } from 'gatsby'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet'
import { Heading, Link, CardPost, CssGrid, Pagination } from '@rmr/components'
import Main from '../components/Main'
import NoticeWrapper from '../components/NoticeWrapper'
import { extractMetaTags } from '../utils/extractMetaTags'
import { Box } from 'rebass'

export interface BlogPostArchiveProps {
  data: {
    allWpPost: any
    wp: GatsbyTypes.Wp
  }
  pageContext?: any
}

export function BlogPostArchive({ data, pageContext }: BlogPostArchiveProps) {
  const { allWpPost, wp } = data
  const { seo } = allWpPost
  const extraMeta = allWpPost.seo
    ? extractMetaTags({
        seoDefaults: wp.constantSettings.seo_defaults,
        seoIncoming: allWpPost.seo
      })
    : null
  const colNum = [1, 2, 2, 3]
  const { currentPage, numPages, paginationPath, categoryName, categorySlug } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  // The function below checks if the pagination is for the archive page or a category page and adjusts the path for the 'previous' button accordingly.
  const prevPage =
    currentPage === 1
      ? paginationPath
      : paginationPath === '/blog/'
      ? currentPage === 2
        ? paginationPath
        : `${paginationPath}page/${currentPage - 1}`
      : paginationPath !== '/blog/' && currentPage === 2
      ? paginationPath
      : `${paginationPath}${currentPage - 1}`
  // The function below checks if the pagination is for the archive page or a category page and adjusts the path for the 'next' button accordingly.
  const nextPage = `${paginationPath}${paginationPath === '/blog/' ? 'page/' : ''}${currentPage + 1}`
  const pagination = {
    currentPage,
    numPages,
    isFirst,
    isLast,
    prevPage,
    nextPage
  }
  const headline = categoryName ? `${categoryName}` : 'Latest Posts'
  return (
    <Main darkHeader renderNotice={() => <NoticeWrapper />}>
      <Helmet>
        {seo?.fullHead ? parse(seo.fullHead) : null}
        {extraMeta}
      </Helmet>
      <Box as="section" py={[6, 10]} sx={{ mx: 'auto', px: '15px', maxWidth: '1440px' }}>
        {headline && (
          <Box
            sx={{
              mx: 'auto',
              marginBottom: [4, 8]
            }}
          >
            <Heading fontWeight="boldest" size="h1" textAlign="center">
              {headline}
            </Heading>
          </Box>
        )}
        <Box
          sx={{
            mx: 'auto',
            marginBottom: [4, 8]
          }}
        >
          <CssGrid columns={colNum}>
            {data.allWpPost.nodes.map(({ id, title, postOptions, featuredImage, categories, slug, year, month }) => (
              <Link
                to={
                  categorySlug
                    ? `/blog/${categorySlug}/${year}/${month}/${slug}/`
                    : categories.nodes[0].slug === 'uncategorized'
                    ? `/blog/${year}/${month}/${slug}/`
                    : `/blog/${categories.nodes[0].slug}/${year}/${month}/${slug}/`
                }
                key={id}
              >
                <CardPost
                  category={
                    categories && categories.nodes[0].name !== 'Uncategorized' ? categories.nodes[0].name : null
                  }
                  title={title}
                  excerpt={postOptions.excerpt}
                  image={featuredImage.node.sourceUrl}
                  alt={featuredImage.node.altText}
                />
              </Link>
            ))}
          </CssGrid>
        </Box>
        <Pagination {...pagination} onNext={(page) => navigate(page)} onPrevious={(page) => navigate(page)} />
      </Box>
    </Main>
  )
}

export default BlogPostArchive

export const pageQuery = graphql`
  query WordPressPostArchive($skip: Int!, $limit: Int!, $categoryName: String) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { categories: { nodes: { elemMatch: { name: { eq: $categoryName } } } } }
    ) {
      nodes {
        id
        title
        content
        postOptions {
          excerpt
        }
        year: date(formatString: "YYYY")
        month: date(formatString: "MM")
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        slug
        categories {
          nodes {
            slug
            name
          }
        }
        seo {
          title
          metaDesc
          metaKeywords
          canonical
          fullHead
          opengraphAuthor
          opengraphDescription
          opengraphImage {
            sourceUrl
          }
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          twitterDescription
          twitterImage {
            sourceUrl
          }
          twitterTitle
        }
      }
    }
    wp {
      constantSettings {
        seo_defaults {
          siteName
          title
          shortTitle
          description
          baseUrl
          image {
            sourceUrl
          }
          author
          keywords
          ogType
        }
      }
    }
  }
`
