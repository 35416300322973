import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import Text from '../Text'
import theme from '../../theme'
import Content from '../Content'
import parse from 'html-react-parser'
import { FeatureIconTypes, BadgeProps, IconProps } from './types'
import Badge from '../Badge/Badge'
import Icon from '../Icon/Icon'

const StyledFeatureIconRow = styled(Flex)`
  position: relative;
`

const StyledIconWrapper = styled(Box)`
  position: relative;
  text-align: center;
`

const StyledBadge = styled(Flex)<BadgeProps>`
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  border-radius: ${theme.borderRadius.circle};
  z-index: 2;
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'inherit')};
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 2rem;
  height: 2rem;
  @media (min-width: ${theme.breakpoints[0]}) {
    top: -0.5rem;
    right: -0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
`
const StyledIcon = styled(Flex)<IconProps>`
  z-index: 1;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => (props.borderColor ? props.borderColor : 'transparent')};
  border-radius: 9999px;
  width: fit-content;
`

const StyledFeatureContent = styled(Box)`
  display: block;
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.medium};
`

export const FeatureIconRow: FC<FeatureIconTypes> = ({
  headline,
  featureContent,
  icon,
  iconColor,
  badge,
  badgeIcon,
  headlineColor,
  textColor,
  badgeTextColor,
  badgeBgColor,
  iconBgColor,
  iconBorderColor,
  sx,
  ...props
}) => {
  return (
    <StyledFeatureIconRow
      flexDirection={['column', 'row']}
      alignItems={['center']}
      justifyContent={['center', 'flex-start']}
      width={['100%']}
      mb={[4, 0]}
      {...props}
      sx={{ ...sx }}
    >
      <StyledIconWrapper className="StyledIconWrapper" ml={'auto'} mr={['auto', 4]} width={['50%', '20%']}>
        <Box sx={{ position: 'relative', width: 'fit-content', margin: 'auto' }}>
          {badge || badgeIcon ? (
            <StyledBadge bgColor={badgeBgColor}>
              {badgeIcon ? (
                <Icon name={badgeIcon} fontAwesomeIconProps={{ color: badgeTextColor }} />
              ) : (
                <Text size="small" fontWeight={theme.fontWeights.extraBold} color={badgeTextColor}>
                  {badge}
                </Text>
              )}
            </StyledBadge>
          ) : null}
          {icon && (
            <StyledIcon mb={[2, 0]} borderColor={iconBorderColor} mx={'auto'}>
              <Badge
                className="Badge"
                variant={'circle'}
                icon={icon}
                contentColor={iconColor}
                color={iconBgColor}
                size={[75, 100]}
              />
            </StyledIcon>
          )}
        </Box>
      </StyledIconWrapper>
      <Flex flexDirection={['column']} width={['100%', '80%']}>
        {headline && (
          <Text
            textAlign={['center', 'left']}
            size="body"
            fontWeight={theme.fontWeights.extraBold}
            color={headlineColor}
          >
            {headline}
          </Text>
        )}
        {featureContent && (
          <StyledFeatureContent color={textColor} textAlign={['center', 'left']}>
            <Content>{parse(featureContent)}</Content>
          </StyledFeatureContent>
        )}
      </Flex>
    </StyledFeatureIconRow>
  )
}

export default FeatureIconRow
