import { Icon, Button } from '@rmr/components'
import React from 'react'
import { Box, Flex, SxStyleProp } from 'rebass'

import Container from '../../Grid/Container'

import Link, { LinkProps } from '../../Link'
import LogoVertical from '../../LogoVertical'

import { MenuItemType } from '../../MenuItem'
import {
  sectionStyles,
  containerStyles,
  logoWrapperStyles,
  logoStyles,
  navigationWrapperStyles,
  itemStyles,
  linkStyles
} from './CopyrightSection.styled'

export interface CopyrightSectionProps {
  /** Home Link */
  homeLink: string
  /** Menu Items */
  menuItems?: MenuItemType[]
  /** Sx prop styles */
  sx?: SxStyleProp
}

const CopyrightSection = ({ homeLink, menuItems, sx, ...props }: CopyrightSectionProps) => {
  const today = new Date()
  return (
    <Box sx={{ ...sectionStyles, ...sx }} {...props}>
      <Container>
        <Flex sx={containerStyles}>
          <Box sx={logoWrapperStyles}>
            <Link to={homeLink} aria-label="RentMyRide Logo">
              <LogoVertical sx={logoStyles} />
            </Link>
          </Box>
          <Flex mb={4}>
            <a href="https://www.instagram.com/rentmyride_sa/" target="_blank" rel="noopener noreferrer">
              <Box
                sx={{
                  px: 2,
                  fontSize: '24px',
                  color: 'textWhite'
                }}
              >
                <Icon name={'instagram'} />
              </Box>
            </a>
            <a href="https://www.facebook.com/RentMyRide.GoPlaces/" target="_blank" rel="noopener noreferrer">
              <Box
                sx={{
                  px: 2,
                  fontSize: '24px',
                  color: 'textWhite'
                }}
              >
                <Icon name={'facebook'} />
              </Box>
            </a>
            <a href="https://www.linkedin.com/company/rentmyride-sa" target="_blank" rel="noopener noreferrer">
              <Box
                sx={{
                  px: 2,
                  fontSize: '24px',
                  color: 'textWhite'
                }}
              >
                <Icon name={'linkedin'} />
              </Box>
            </a>
            {/* <a href="https://www.facebook.com/RentMyRide.GoPlaces/" target="_blank" rel="noopener noreferrer">
              <Box
                onClick={() => null}
                mx={[1]}
                variant="outlineRound"
                sx={{
                  width: ['42px', '42px'],
                  height: ['42px', '42px'],
                  fontSize: '24px',
                  color:"textWhite",
                  p: '0',
                  flex: '0 0 auto'
                }}
                icon={<Icon name={'facebook'} />}
              />
            </a>
            {/* <a href="https://www.linkedin.com/company/rentmyride-sa" target="_blank" rel="noopener noreferrer">
              <Box
                onClick={() => null}
                mx={[1]}
                variant="outlineRound"
                sx={{
                  width: ['42px', '42px'],
                  height: ['42px', '42px'],
                  fontSize: '24px',
                  color:"textWhite",
                  p: '0',
                  flex: '0 0 auto'
                }}
                icon={<Icon name={'linkedin'} />}
              />
            </a>
            <a href="https://instagram.com/rentmyride_sa" target="_blank" rel="noopener noreferrer">
              <Box
                onClick={() => null}
                mx={[1]}
                variant="outlineRound"
                sx={{
                  width: ['42px', '42px'],
                  height: ['42px', '42px'],
                  fontSize: '24px',
                  color:"textWhite",
                  p: '0',
                  flex: '0 0 auto'
                }}
                icon={<Icon name={'instagram'} />}
              />
            </a> */}
          </Flex>
          <Flex sx={navigationWrapperStyles}>
            <Box as="span" sx={itemStyles}>{`Copyright ${today.getFullYear()} RentMyRide`}</Box>
            {menuItems.map(({ name, to }, index) => {
              return (
                <Link key={index} to={to} sx={linkStyles}>
                  {name}
                </Link>
              )
            })}
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

CopyrightSection.defaultProps = {
  homelink: {
    href: '#'
  },
  menuItems: [],
  sx: {}
}

export default CopyrightSection
