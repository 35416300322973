import React, { FC } from 'react'
import { Box, Flex } from 'rebass'
import { Button, StepsWrapper, Text } from '@rmr/components'
import { StepWizardChildProps } from 'react-step-wizard'
import { OptionsButtons } from './OptionsButtons'

interface SuccessProps extends Partial<StepWizardChildProps> {
  headline?: string
  text?: string
  buttonText?: string
  onClick?: () => void
  hasSuccess?: boolean
  socialSignup?: boolean
  idRef?: string
}

const Success: FC<SuccessProps> = ({ headline, text, buttonText, hasSuccess, socialSignup, onClick, idRef }) => {
  return (
    <>
      <Box pt={[10]} px={[0, null, 5]} pb={[1, 3]}>
        <Box id={idRef} sx={{ position: 'relative', bottom: [300] }} />
        <StepsWrapper
          badgeProps={{
            variant: 'circle',
            size: [150],
            icon: hasSuccess ? 'check' : 'error',
            color: hasSuccess ? 'statusSuccess' : 'statusError'
          }}
          title={headline}
          separator={true}
          separatorColor={hasSuccess ? 'statusSuccess' : 'statusError'}
          bg="transparent"
          sx={{ boxShadow: 'none' }}
        >
          <Text as="p" size="body" mt={[3]} textAlign="center" sx={{ a: { color: 'primary' } }}>
            <Box as="span" dangerouslySetInnerHTML={{ __html: text }} />
          </Text>
          {onClick && (
            <Flex justifyContent="center" mt={[3]}>
              <Button variant="primary" onClick={onClick}>
                {buttonText}
              </Button>
            </Flex>
          )}
        </StepsWrapper>
      </Box>
      {socialSignup && <OptionsButtons />}
    </>
  )
}

export default Success

Success.defaultProps = {
  hasSuccess: true,
  socialSignup: false
}
