import { VehicleAvailabilityType, Order, PaymentMethod, DocumentType } from '@rmr/controllers'

export const languages = {
  en: {
    auth: {
      emailLabel: 'Continue with Email',
      facebookLabel: 'Continue with Facebook',
      googleLabel: 'Continue with Google',
      signup: {
        dashboardHeadline: 'Welcome to RentMyRide',
        headline: 'Sign up',
        headlineSocial: 'You are signed up!',
        subHeadline: 'Please complete your profile',
        acceptLabel: 'Accept',
        terms:
          'By accepting the terms and conditions, you acknowledge that you have read and understood the terms and conditions and are bound by them.',
        haveAccount: 'Already have an account?',
        haveAccountButtonText: 'Login',
        intentionLabel: 'I would like to*',
        isRenterLabel: 'Rent a car',
        isOwnerLabel: 'List a car',
        intentToRent: 'Interested in renting',
        intentToList: 'Interested in listing',
        whatsAppNotice: 'By signing up, you agree to receive WhatsApp notifications. You can opt-out at any time.'
      },
      signin: {
        headline: 'Sign In',
        buttonText: 'Sign In',
        haveAccount: "Don't have an account?",
        haveAccountButtonText: 'Sign Up',
        forgotPasswordLabel: 'Forgot Password?'
      },
      forgot: {
        headline: 'Forgot Password',
        successHeadline: 'Check your inbox',
        successText: `If we found an account associated with that username, we've sent password reset instructions to the email address on the account.<br/><br/>Still having trouble logging in?<br/><a href="/contact/">Contact Support.</a>`,
        rememberPasswordLabel: 'Remembered Password?',
        buttonText: 'Submit'
      },
      reset: {
        headline: 'Reset Password',
        newPasswordLabel: 'New Password',
        confirmPasswordLabel: 'Confirm Password',
        resendPasswordLinkLabel: 'Resend Password Reset Link',
        buttonText: 'Reset password',
        loginButtonText: 'Login',
        successHeadline: 'Password reset successfully',
        successText: 'You can now log in with your new password'
      },
      haveAccount: 'Already have an account?',
      noAccount: "Don't have an account?",
      buttonSignIn: 'Login',
      buttonSignUp: 'Sign Up',
      buttonSave: 'Update Profile'
    },
    listingGuideline: {
      title: 'Listing Guideline',
      subTitle: 'Some important things to know before listing your car on the platform',
      badges: [
        { icon: 'tag', title: 'Vehicle Value', subTitle: 'Limit of R300 000' },
        { icon: 'calendar', title: 'Vehicle Age', subTitle: 'Limit of 15 Years' },
        { icon: 'odometer', title: 'Vehicle Mileage', subTitle: 'Limit of 200 000 Km' },
        { icon: 'signal', title: 'Tracking Device', subTitle: 'Tracking Device Required' }
      ],
      overview:
        'If you have a vehicle slightly outside these parameters, click the  “Request to list” button below to submit a request to have your vehicle added to the platform',
      buttonText: 'Continue to list'
    },
    getApprovedList: {
      title: 'Get Approved',
      subTitle: `You’ll need to provide us with some information before you can list or rent a car.`,
      privacyPolicyText: 'The verification check is done by a 3rd party. Read our',
      privacyPolicyLabel: 'Privacy Policy',
      buttonNextLabel: 'Accept',
      stepsWizard: ['Profile Photo', 'Mobile Number', 'Documents'],
      verifyEmailTitle: 'Verify your email',
      verifyEmailText: (email) =>
        `We sent a verification link to <span>${email}</span>. Click the link in your email to get verified.`,
      resendEmail: 'Resend Email',
      changeEmail: 'Change Email'
    },
    approvalToList: {
      stepsWizard: ['Profile Photo', 'Mobile Number', 'Documents']
    },
    identification: {
      titleLabel: 'Title',
      titleOptions: [
        {
          value: 'Mr.',
          label: 'Mr.'
        },
        {
          value: 'Mrs.',
          label: 'Mrs.'
        },
        {
          value: 'Miss.',
          label: 'Miss.'
        },
        {
          value: 'Ms.',
          label: 'Ms.'
        },
        {
          value: 'Dr.',
          label: 'Dr.'
        },
        {
          value: 'Dr.',
          label: 'Dr.'
        },
        {
          value: 'Professor',
          label: 'Professor'
        }
      ],
      addressLabel: 'Your home address',
      addressPlaceholder: 'Enter address',
      idNumberLabel: (passport: boolean) => (passport ? 'Passport Number' : 'ID Number'),
      dobLabel: 'Date of Birth',
      idTypeLabel: 'ID Type',
      idTypeOptions: [
        {
          value: DocumentType.IdBook,
          label: 'ID Green Book / Card'
        },
        {
          value: DocumentType.Passport,
          label: 'Passport'
        }
      ]
    },
    mobileNumber: {
      title: 'Mobile Number',
      subTitle: 'Enter your mobile number and we’ll text you a verification code.',
      mobileNumberLabel: 'Mobile Number',
      mobileNumberLabelAlt: 'Alternative Mobile Number',
      required: 'A mobile number is required',
      whatsAppOtpLabel: 'Send OTP via WhatsApp',
      whatsAppOtpTooltip: 'We will send an OTP to your WhatsApp app so that you can verify your cell phone number',
      resendOTP: 'Resend OTP',
      viaOTP: (isWhatsApp: boolean, isResent: boolean) => {
        const viaSMS = isResent || !isWhatsApp
        const viaBoth = isResent && isWhatsApp
        return `Sent via ${isWhatsApp ? 'WhatsApp' : ''}${viaBoth ? ` and ` : ''}${viaSMS ? 'SMS' : ''}`
      },
      OTP: (num: string) =>
        // `A one time pin has been sent to ${num.slice(0, 4)}${new Array(num.length - 4).fill('x').join('')}`
        `A one time pin has been sent to ${num}`
    },
    documents: {
      title: 'Documents',
      subTitle: 'Time for some important docs',
      dateYearPlaceholder: 'YYYY',
      dateMonthPlaceholder: 'MM',
      dateDayPlaceholder: 'DD',
      dateLabel: 'First Issued Date',
      invalidDateError: 'Please enter a valid date'
    },
    approvalConfirmation: {
      title: 'Your approval request has been received',
      subtitle: 'Thank you for completing all the steps',
      blurb:
        'We are currently reviewing your submission and will be in touch to let you know when you have been approved',
      buttonText: 'Go to my Dashboard'
    },
    listYourCar: {
      heroHeadline: 'Share your car',
      heroDescription: 'Complete each step to add your car to our platform.',
      stepsWizard: [
        'Vehicle Information',
        'Upload Vehicle Images',
        'Rental Conditions',
        'Rate Calculation',
        'Terms & Conditions'
      ],
      buttonTextBack: 'back',
      buttonTextNext: 'next',
      finishLaterLabel: 'Finish later',
      vehicleInformation: {
        headline: 'Vehicle Information',
        nameLabel: 'Car Name',
        nameToolTipInfo: 'Give a title to your car (e.g. mega super car)',
        namePlaceholder: 'Give a title to your car (e.g. mega super car)',
        licence_noLabel: 'Registration Number',
        licence_noToolTipInfo: 'Licence Plate Number',
        licence_noPlaceholder: 'Licence Plate Number',
        vinLabel: 'VIN Number',
        vinToolTipInfo: '17 character VIN number',
        vinPlaceholder: '17 character VIN number',
        yearLabel: 'Year',
        yearToolTipInfo: 'Select Year...',
        yearPlaceholder: 'Select Year...',
        makeLabel: 'Make',
        makeToolTipInfo: 'Select Make...',
        makePlaceholder: 'Select Make...',
        makePlaceholderDisabled: 'Select Year First',
        modelLabel: 'Model',
        modelToolTipInfo: 'Select Model...',
        modelPlaceholder: 'Select Model...',
        modelPlaceholderDisabled: 'Select Make First',
        variantLabel: 'Variant',
        variantToolTipInfo: 'Select Variant...',
        variantPlaceholder: 'Select Variant...',
        variantPlaceholderDisabled: 'Select Model First',
        colorLabel: 'Color',
        colorToolTipInfo: 'Select Color...',
        colorPlaceholder: 'Select Color...',
        transmissionLabel: 'Transmission',
        transmissionToolTipInfo: 'Select Transmission...',
        transmissionPlaceholder: 'Select Transmission...',
        categoryLabel: 'Category',
        categoryToolTipInfo: 'Select Category...',
        categoryPlaceholder: 'Select Category...',
        fueltypeLabel: 'Fuel Type',
        fueltypeToolTipInfo: 'Select Fuel Type...',
        fueltypePlaceholder: 'Select Fuel Type...',
        featuresLabel: 'Features',
        featuresToolTipInfo: 'Select Features...',
        featuresPlaceholder: 'Select Features...',
        odometerLabel: 'Odometer',
        odometerToolTipInfo: 'Your current Mileage Reading',
        odometerPlaceholder: 'Your current Mileage Reading',
        descriptionLabel: 'Vehicle Description',
        descriptionToolTipInfo:
          'A description of why a user would want to use your vehicle. Try to point out features that make your car stand out from the rest of the competition.',
        descriptionPlaceholder:
          'A description of why a user would want to use your vehicle. Try to point out features that make your car stand out from the rest of the competition.',
        licenceDiskLabel: 'Licence Disk Image',
        licenceDiskNote: 'Valid licence disk required',
        roadworthyCertificateLabel: 'Roadworthy Certificate',
        roadworthyCertificateNote: 'Optional Requirement',
        trackingDeviceCertificateLabel: 'Proof of Tracking Device',
        trackingDeviceCertificateNote: 'Certificate, statement or confirmation letter',
        LicenceDiskMesage: 'A Licence Disk document is required',
        createProblemMessage: 'Vehicle could not be created',
        updateProblemMessage: 'Vehicle could not be updated',
        serverProblemMessage: 'Server problem. Contact Admin',
        trackerHeadline: "Don't have a tracker?",
        trackerText:
          "Don't stress. Sign up today and get a RentMyRide exclusive deal from on of our tracking partner's for as little as R80 per month.",
        trackerLabel: 'I would like to sign up for a tracking device'
      },
      uploadVehicleImages: {
        headline: 'Upload Vehicle Images',
        carouselTitle: 'Your vehicle',
        imageTypeTitle: 'Image Type',
        uploadImageTitle: 'Upload Image',
        numberMessage: 'Please upload all 6 images',
        sameFileMessage: 'This image has already been used',
        serverProblemMessage: 'Server problem. Contact Admin'
      },
      rentalConditions: {
        headline: 'Rental Conditions',
        maxDailyDistanceLabel: 'Max Daily Distance',
        maxDailyDistanceInfo: 'The maximum daily distance a renter is allowed to do with your vehicle',
        maxDailyDistanceOptions: [
          { key: '100', value: 100, label: '100km' },
          { key: '200', value: 200, label: '200km' },
          { key: '300', value: 300, label: '300km' },
          { key: '0', value: 0, label: '400km+' }
        ],
        maxDailyDistancePlaceholder: 'Please select',
        minRentalPeriodLabel: 'Min Rental Period',
        minRentalPeriodInfo: 'The minimum rental period to book your vehicle',
        minRentalPeriodOptions: [
          { key: '2', value: 2, label: '2 Days' },
          { key: '3', value: 3, label: '3 Days' },
          { key: '4', value: 4, label: '4 Days' },
          { key: '5', value: 5, label: '5 Days' },
          { key: '0', value: 0, label: 'unlimited' }
        ],
        minRentalPeriodPlaceholder: 'Select Min Rental Period',
        allowGravelRoadsLabel: 'Allow on Gravel Roads',
        allowGravelRoadsInfo: 'The renter may drive your vehicle on unpaved roads.',
        allowPetsLabel: 'Allow Pets',
        allowPetsInfo: 'The renter will be allowed to have pets in your vehicle.',
        pickUpDeliveryInfoHeadline: 'Pick Up / Delivery Info',
        pickUpLabel: 'Pick up',
        pickUpInfo:
          "The renter will be allowed to pick up the vehicle from your address. You may select both 'Pick Up' and 'Delivery' as an option.",
        deliveryLabel: 'Delivery',
        deliveryInfo:
          "You, as the owner, are willing to deliver your vehicle. You may select both 'Pick Up' and 'Delivery' as an option.",
        vehicleLocationLabel: 'Vehicle Location',
        vehicleLocationPlaceholder: 'Enter address',
        showMyLocationLabel: 'Show My Location',
        showMyLocationInfo:
          'This allows the renter to see the exact location of your vehicle after they have paid for the rental.',
        airportDeliveryLabel: 'Deliver to Airport',
        airportDeliveryInfo: 'You, as the owner, are willing to deliver your vehicle to an airport',
        deliveryToRenterLabel: 'Deliver to Renter',
        deliveryToRenterInfo:
          "You, as the owner, are willing to deliver your vehicle to a renter's requested location.",
        feesHeadline: 'Fees',
        airportDeliveryFeeLabel: 'Airport Delivery Fee',
        airportDeliveryFeeInfo: 'The fee you will be charging, should you have to deliver your vehicle to the airport.',
        airportDeliveryFeeOptions: [
          { key: '0', value: 0, label: 'none' },
          { key: '5000', value: 5000, label: 'R50' },
          { key: '10000', value: 10000, label: 'R100' },
          { key: '15000', value: 15000, label: 'R150' },
          { key: '20000', value: 20000, label: 'R200' },
          { key: '25000', value: 25000, label: 'R250' },
          { key: '30000', value: 30000, label: 'R300' },
          { key: '35000', value: 35000, label: 'R350' },
          { key: '40000', value: 40000, label: 'R400' },
          { key: '45000', value: 45000, label: 'R450' },
          { key: '50000', value: 50000, label: 'R500' },
          { key: '55000', value: 55000, label: 'R550' },
          { key: '60000', value: 60000, label: 'R600' },
          { key: '65000', value: 65000, label: 'R650' },
          { key: '70000', value: 70000, label: 'R700' },
          { key: '75000', value: 75000, label: 'R750' },
          { key: '80000', value: 80000, label: 'R800' },
          { key: '85000', value: 85000, label: 'R850' },
          { key: '90000', value: 90000, label: 'R900' },
          { key: '95000', value: 95000, label: 'R950' },
          { key: '100000', value: 100000, label: 'R1000' }
        ],
        airportDeliveryFeePlaceholder: 'Please select',
        deliveryToRenterFeeLabel: 'Delivery to Renter Fee',
        deliveryToRenterFeeInfo: 'The fee you will be charging, should you have to deliver your vehicle to the renter.',
        deliveryToRenterFeeOptions: [
          { key: '0', value: 0, label: 'none' },
          { key: '5000', value: 5000, label: 'R50' },
          { key: '10000', value: 10000, label: 'R100' },
          { key: '15000', value: 15000, label: 'R150' },
          { key: '20000', value: 20000, label: 'R200' },
          { key: '25000', value: 25000, label: 'R250' },
          { key: '30000', value: 30000, label: 'R300' },
          { key: '35000', value: 35000, label: 'R350' },
          { key: '40000', value: 40000, label: 'R400' },
          { key: '45000', value: 45000, label: 'R450' },
          { key: '50000', value: 50000, label: 'R500' },
          { key: '55000', value: 55000, label: 'R550' },
          { key: '60000', value: 60000, label: 'R600' },
          { key: '65000', value: 65000, label: 'R650' },
          { key: '70000', value: 70000, label: 'R700' },
          { key: '75000', value: 75000, label: 'R750' },
          { key: '80000', value: 80000, label: 'R800' },
          { key: '85000', value: 85000, label: 'R850' },
          { key: '90000', value: 90000, label: 'R900' },
          { key: '95000', value: 95000, label: 'R950' },
          { key: '100000', value: 100000, label: 'R1000' }
        ],
        deliveryToRenterFeePlaceholder: 'Please select',
        deliveryWithinLabelBefore: 'Delivery within a',
        deliveryWithinLabelAfter: 'radius from my location',
        deliveryWithinInfo: 'The maximum distance you, as the owner, are willing to travel to deliver your vehicle.',
        deliveryWithinOptions: [
          { key: '5', value: 5, label: '5km' },
          { key: '10', value: 10, label: '10km' },
          { key: '20', value: 20, label: '20km' },
          { key: '0', value: 0, label: '25km+' }
        ],
        deliveryWithinPlaceholder: 'Select Distance',
        updateProblemMessage: 'Vehicle could not be updated',
        serverProblemMessage: 'Server problem. Contact Admin',
        placeError: 'Please select an option from the list'
      },
      rateCalculation: {
        headline: 'Rate Calculation',
        intro:
          'The daily rate is calculated based on the value of your vehicle, and derived from the TransUnion car value guide',
        recommendedRate: 'Recommended rate',
        minLabel: 'Min',
        maxLabel: 'Max',
        updateProblemMessage: 'Vehicle could not be updated',
        serverProblemMessage: 'Server problem. Contact Admin'
      },
      vehicleAvailability: {
        headline: 'Vehicle Availability',
        intro:
          'Please select the times of the week you want your vehicle to be available (You can make additional changes via the Owner Dashboard at a later stage.)',
        availabilityOptions: [
          { label: 'Select all dates', value: VehicleAvailabilityType.allDates },
          { label: 'Weekdays Only', value: VehicleAvailabilityType.WeekdaysOnly },
          { label: 'Weekends Only', value: VehicleAvailabilityType.WeekendsOnly }
        ]
      },
      termsConditions: {
        headline: 'Terms & Conditions',
        acceptTermsLabel:
          'Please agree to the terms & conditions in order for your vehicle to be successfully added to our platform',
        buttonNextlabel: 'submit',
        updateProblemMessage: 'Vehicle could not be updated',
        serverProblemMessage: 'Server problem. Contact Admin'
      },
      confirmation: {
        buttonText: 'View on your dashboard'
      }
    },
    searchBar: {
      locationLabel: 'Location',
      fromLabel: 'From',
      toLabel: 'To',
      defaultLocation: '',
      buttonLabel: 'Go',
      errorDates: 'Please select your dates',
      locationTestOptions: [
        { value: 'Cape Town', label: 'Cape Town' },
        { value: 'Johannesburg', label: 'Johannesburg' },
        { value: 'Durban', label: 'Durban' },
        { value: 'Los Angeles', label: 'Los Angeles' }
      ]
    },
    verification: {
      headline: 'Please check your email for a verification link.',
      resendButton: 'Resend Verification Link'
    },
    verificationSuccess: {
      headline: 'Email Sent'
    },
    signup: {
      success: 'Account created successfully',
      generalError: 'Account could not be created',
      checkEmailMessage: 'Please check your email inbox or spam folder to verify your email address.',
      socialSignup: "What's next?"
    },
    rentCar: {
      noResultsHeadline: 'No vehicles have been found that match your criteria',
      noResultsDescription: 'Update your filters or date range to show more results',
      noResultsImageAlt: 'No vehicles found',
      showMapLabel: 'Map',
      filtersLabel: 'Filters',
      addToFavourites: 'Add to favourites',
      addedToFavourites: 'Added to favourites',
      filters: {
        sortPrice: {
          filterName: 'Sort by Price',
          options: [
            { label: 'High to Low', value: Order.Desc },
            { label: 'Low to High', value: Order.Asc }
          ]
        },
        sortRating: {
          filterName: 'Sort by Rating',
          options: [
            { label: 'High to Low', value: Order.Desc },
            { label: 'Low to High', value: Order.Asc }
          ]
        },
        price: {
          filterName: 'Price',
          initialValues: [1, 1600],
          min: 1,
          max: 1600,
          unit: 'R',
          unitPlural: 'R',
          buttonText: 'Apply'
        },
        dailykm: {
          filterName: 'Daily Km',
          initialValues: [100, 400],
          min: 100,
          max: 400,
          unit: 'Km',
          unitPlural: 'Km',
          unitLeft: false,
          buttonText: 'Apply'
        },
        odometer: {
          filterName: 'Daily Km',
          initialValues: [0, 200000],
          min: 0,
          max: 200000,
          unit: 'Km',
          unitPlural: 'Km',
          unitLeft: false,
          buttonText: 'Apply'
        },
        year: {
          filterName: 'Year',
          buttonText: 'Apply'
        },
        isDelivery: {
          filterName: 'Can be delivered?',
          options: [{ label: 'Yes', value: 'true' }]
        },
        make: {
          filterName: 'Make'
        },
        category: {
          filterName: 'Vehicle Type'
        },
        fuelType: {
          filterName: 'Fuel Type'
        },
        transmission: {
          filterName: 'Transmission'
        },
        features: {
          filterName: 'Features'
        }
      }
    },
    vehicleListing: {
      blockedDaysError: 'Date range unavailable',
      unavailable: 'Vehicle is currently not available for rent.',
      galleryButtonText: 'Gallery',
      mapButtonText: 'Show on Map',
      mapButtonTextMobile: 'Map',
      showMoreButtonText: 'Show more',
      detailsHeadlineDetails: 'Details',
      detailsHeadlineDescription: 'Description',
      featuresHeadline: 'Features',
      reviewsHeadline: 'Reviews',
      pickupReturnLocationLabel: 'Pickup & Return Location',
      pickupReturnLocationFilter: 'Pickup & return location',
      pickupReturnLocationError: 'Please select valid pick up & return location',
      buttonLabel: 'Continue',
      pickupTitle: 'PICKUP AT CAR LOCATION',
      deliveryTitle: 'DELIVERY LOCATIONS',
      tripDatesLabel: 'Trip Dates',
      unlimitedKms: 'Unlimited',
      featuresTooltips: {
        kmDay: 'Daily kms',
        airportDelivery: 'Airport delivery',
        renterDelivery: 'Renter delivery'
      }
    },
    booking: {
      datesLabel: 'Dates',
      fromLabel: 'From',
      toLabel: 'To',
      expirationNotice: 'This quote expires at',
      expirationMessage: 'This quote has expired. You will be redirected to the vehicle page',
      airportDelivery: 'Airport Delivery',
      selectDropoffAddress: 'Select Drop-off Address',
      addDelivery: 'Enter drop-off address',
      deliveryButtonText: 'Add',
      selectAirportLabel: 'Select Airport',
      requiredForTrip: 'Required for your trip',
      requiredForTripInfo: "Let the owner know why you're travelling and when you'll check in.",
      selectAirport: 'Select the airport where you will be arriving',
      messageHost: 'Message the owner*',
      reasonTravelling: '',
      payWith: 'Pay with',
      note: '*Your request to rent a vehicle will be sent to the owner. You will be required to make payment immediately upon acceptance of your rental request.',
      deliverOptions: 'Pickup & return location',
      deliverOptionsInfo: 'Please choose one of the available options below',
      pickUpLabel: 'Pick Up',
      airportDeliveryLabel: 'Delivered to airport',
      addressPlaceholder: 'Enter your address',
      deliveryToRenterLabel: 'Delivered to me',
      airportPlaceholder: 'Enter airport name',
      addressLabel: 'Address',
      paymentLabel: 'Payment',
      expirationButtonText: 'Book Again',
      quoteTitle: 'Send Vehicle Request',
      quoteSubtitle: "Let the Host know why you're traveling and select your delivery options",
      pickupReturnLocationHeading: 'Pickup & return location',
      pickupLocationLabel: 'PICKUP AT CAR LOCATION',
      exactAddressNotice: "We'll send you the exact address once your trip is booked.",
      deliveryLocationsHeading: 'DELIVERY LOCATIONS',
      deliveryDetailsCoordinate: 'will coordinate delivery details for your trip.',
      buttonLabel: 'Send Request',
      buttonLabelLoading: 'Loading',
      buttonPayment: 'Process Payment',
      buttonPaymentProcessing: 'Proceeding',
      validationCouponMessage: 'Please enter a valid coupon code',
      applyCouponErrorMessage: 'Coupon Code could not be redeem. Please contact us',
      removeCouponErrorMessage: 'Coupon Code could not be removed. Please contact us',
      couponLabel: 'Apply a Coupon',
      appliedCouponLabel: 'Applied Coupon',
      couponPlaceholder: 'Enter your coupon code',
      couponApplyButton: 'Apply',
      couponApplyButtonProcessing: 'Applying',
      couponRemoveButton: 'Remove',
      couponRemoveButtonProcessing: 'Removing',
      successAppliedCoupon: 'Your coupon has been redeemed successfully. A discount has been applied!',
      successRemoveCoupon: 'Coupon and discount removed successfully!'
    },
    bookingConfirmation: {
      title: 'Request Sent',
      subtitle: 'The owner of the vehicle has been notified of your request and will respond within 24 hours.',
      textLines: [
        'Congratulations on requesting a vehicle with RentMyRide! We trust that you will have a good experience.',
        'You will be required to make payment immediately upon acceptance of your rental request. Please visit your dashboard to view all your rental requests, current rentals, and past rentals on the platform.'
      ],
      buttonText: 'View Rental Request'
    },
    bookingExpired: {
      title: 'Rental Request Expired!',
      // subtitle: 'This quote has expired. You can request another quote.',
      textLines: ['This quote has expired. You can request another quote.'],
      buttonText: 'Request Again'
    },
    paymentConfirmation: {
      title: 'Payment Completed',
      subtitle: 'Your payment has been received',
      textLines: ['Your rental is now confirmed.'],
      buttonText: 'View Rental'
    },
    paymentError: {
      title: 'Error processing payment',
      subtitle: 'Your rental has not been confirmed',
      textLines: ['Please try process payment again'],
      buttonText: 'Process Payment'
    },
    paymentForm: {
      cardHolderLabel: 'Cardholder Full Name',
      cardHolderPlaceholder: "Cardholder's name",
      expiryDateLabel: 'Expiry Date',
      expiryDatePlaceholder: 'MM/YYYY',
      cardNumberLabel: 'Card Number',
      cardNumberPlaceholder: 'Card Number',
      cvcNumberLabel: 'CVC Number',
      cvcNumberPlaceholder: 'CVC Number',
      termsTextLabel: 'Please agree to the',
      termsLinkLabel: 'terms & conditions',
      paymentMethodsLabel: 'Choose how to pay',
      paymentMethods: {
        [PaymentMethod.CreditCard]: 'Pay with Credit Card/Debit card',
        [PaymentMethod.Eft]: 'Pay with InstantEFT',
        [PaymentMethod.Qr]: 'Pay with Snapscan/MasterPass'
      },
      linkedMethodsLabel: 'Linked Methods',
      otherMethodsLabel: 'Payment Methods'
    },
    reservationReview: {
      titleDriver: 'Review Your Recent Rental',
      subTitleDriver: 'Please share your feedback regarding your rental experience',
      titleOwner: 'Review Your Recent Rental',
      subTitleOwner: 'Please share your feedback regarding your rental experience',
      fromLabel: 'From:',
      toLabel: 'To:',
      publicCommentLabel: 'Public Comment',
      publicCommentTooltip: 'Appears on the driver and users profile',
      privateCommentLabel: 'Private comment for internal review',
      privateCommentTooltip: 'For internal use only',
      buttonText: 'Submit',
      successHeadline: 'Review submitted successfully',
      successText: 'Thank you very much for your review. We look forward to seeing you again.',
      successButtonText: 'Back to Rental',
      reservationLinkText: 'View Rental'
    },
    verifyEmail: {
      headlineSuccess: 'Email Verified Successfully',
      headlineFailed: 'Email Verification Failed',
      resendButton: 'Resend Verification Link',
      successfulVerification: 'Your email address has been verified successfully',
      failedVerification: 'Your email could not be verified. Please try again.',
      listcarButtonText: 'Share your Car',
      rentcarButtonText: 'Rent a Car',
      approvalButtonText: 'Get Approved'
    },
    referFriend: {
      title: 'R200 for you, R200 for them!',
      subtitle: (
        <>
          <br />
          Refer-a-Friend and enjoy the joy of car-sharing
          <br /> <br />
          Thank you for choosing to Refer-a-Friend to RentMyRide. By doing so you help us grow a car-sharing community
          of like-minded folks, who are working together to make the world a bit greener and getting around a little
          smarter.
          <br /> <br />
          Here&apos;s how it works
        </>
      ),
      textLinesLoggedIn: [
        "Refer-a-Friend today, and when they join we'll reward each of you with a R200 coupon to redeem on your next rental."
      ],
      textLinesNotLoggedIn: ['Sign In to Refer-a-Friend today, or Sign Up if you dont have an account already?'],
      explainers: [
        'Share your referral code',
        'Your friend joins and takes their first ride',
        'You each get R200 off your next rental'
      ],
      termsLinkTest: 'Terms & Conditions',
      buttonText: 'Back To Dashboard',
      whatsappText:
        'You are invited to enjoy the perks of car-sharing with RentMyRide. Sign up to join today and we both get R200 off our next car rental.',
      noProfileCreated: 'Please note that you need to create a profile before referring a friend'
    },
    referFriendEmail: {
      title: 'Refer-a-Friend via Email',
      subtitle: 'Add as many friends as you wish and we will send the message previewed below, to each of them.',
      recipientsLabel: 'Email',
      emailBodyLabel: 'Email Preview',
      emailBody:
        "Hello! It's your lucky day. Your friend [xxx] invited you to enjoy the perks of car-sharing with RentMyRide. Sign up to join today and you both get R200 off your next rental.",
      addRecipientLabel: 'Add another friend',
      buttonText: 'Send Email',
      termsDescription: `By checking this box, you are agreeing to the
      <a href="/refer-a-friend/terms-and-conditions/" target="_blank" rel="noreferrer">Terms & Conditions</a> of
       this promotion. To comply with anti-spam legislation, you declare that the intended recipients of your
          message are people with whom you have a personal or family relationship. You agree to be contacted by
          RentMyRide about this promotion via e-mail with the option to unsubscribe at any time.
        `,
      termsError: 'Please accept our terms',
      invalidEmailError: 'Please enter a valid email address',
      successSend: 'Thank you! The email is on its way.',
      errorSend: 'Email could not be send. Please contact us.'
    },
    referralSignup: {
      headline: 'Sign Up'
    },
    defaultError: 'Something went wrong. Please contact admin.'
  }
}

export type Languages = keyof typeof languages
