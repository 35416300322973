import React from 'react'
import { Flex } from 'rebass'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import filesize from 'filesize'

import Icon from '../../Icon'
import Text from '../../Text'
import {
  dropZoneStyles,
  placeholderStylesVertical,
  placeholderStylesHorizontal,
  placeholderStylesMinimal,
  iconStylesHorizontal,
  iconStylesVertical
} from './DropZoneArea.styled'

import languages from '../../../i18n'
import { FileExtended } from '../types'

const dropZoneVariants = {
  vertical: placeholderStylesVertical,
  horizontal: placeholderStylesHorizontal,
  minimal: placeholderStylesMinimal
}
const iconVariants = {
  vertical: iconStylesVertical,
  horizontal: iconStylesHorizontal
}

type Variant = keyof typeof dropZoneVariants

interface DropZoneAreaProps {
  /** DropZone Variant */
  variant: Variant
  /** Hide Icon */
  hideIcon?: boolean
  /** Maximun file size (in bytes) */
  maxSize?: number
  /** Maximun file size (in bytes) */
  files?: FileExtended[]
  /** DropZone Root Props */
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps
  /** DropZone Root Props */
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T
  /** Drag Zone Active */
  isDragActive: boolean
}
const DropZoneArea = ({
  variant,
  hideIcon = false,
  maxSize,
  files = [],
  getRootProps,
  getInputProps,
  isDragActive
}: DropZoneAreaProps) => {
  const isMinimal = variant === 'minimal'
  const isMinimalActive = isMinimal && files.length === 1

  let percentage, percentageText
  if (isMinimalActive) {
    const fsize = files[0].size
    percentage = Math.round((fsize / maxSize) * 100)
    percentageText = `${percentage}% (${filesize(fsize)} of ${filesize(maxSize)})`
  }
  return (
    <Flex
      {...getRootProps({ className: 'dropzone' })}
      sx={dropZoneStyles(isDragActive, isMinimal, isMinimalActive, percentage)}
    >
      <input {...getInputProps()} />
      <Flex sx={dropZoneVariants[variant]} justifyContent={isMinimal ? 'center' : 'unset'}>
        {!hideIcon && <Icon name="imagePlaceholder" sx={iconVariants[variant]} />}
        <Text size="small" fontWeight="bold" textAlign="center">
          {isMinimalActive ? percentageText : languages['en'].dragDrop}
        </Text>
      </Flex>
    </Flex>
  )
}

export default DropZoneArea
