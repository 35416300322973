import { SxStyleProp } from 'rebass'

export const formStyles: SxStyleProp = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: ['repeat(1, 1fr)', null, 'repeat(2, 1fr)'],
  rowGap: [1],
  columnGap: [2],
  alignItems: 'center'
}
export const checkboxWrapper: SxStyleProp = {
  ...formStyles,
  gridColumn: [null, null, 'span 2', null, 'span 1'],
  gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', null, '40% auto', 'repeat(2, 1fr)']
}

export const fullWidthField: SxStyleProp = {
  gridColumn: [null, null, 'span 2']
}

export const deliveryWrapperStyles = (showSection: boolean, sectionHeight: number): SxStyleProp => ({
  height: showSection ? `${sectionHeight}px` : 0,
  overflow: showSection ? 'visible' : 'hidden',
  transition: 'height 0.2s'
})
