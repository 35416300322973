import React, { useMemo } from 'react'

import HeroWrapper from './HeroWrapper'
import HeroEarningsWrapper from './HeroEarningsWrapper'
import HubspotWrapper from './HubspotWrapper'
import ContentFormWrapper from './ContentFormWrapper'
import BannerWrapper from './BannerWrapper'
import SectionSplitWrapper from './SectionSplitWrapper'
import SectionFeaturesWrapper from './SectionFeaturesWrapper'
import TestimonialsWrapper from './TestimonialsWrapper'
import SectionFeaturesSplitWrapper from './SectionFeaturesSplitWrapper'
import FaqsWrapper from './FaqsWrapper'
import FeaturedVehiclesWrapper from './FeaturedVehiclesWrapper'
import SectionCardsCarouselWrapper from './SectionCardsCarouselWrapper'

import { CONTENT_TYPES } from '../common/constants'

export const LAYOUT_MAPPING = {
  [CONTENT_TYPES.HERO]: HeroWrapper,
  [CONTENT_TYPES.HUBSPOT]: HubspotWrapper,
  [CONTENT_TYPES.CONTENTFORM]: ContentFormWrapper,
  [CONTENT_TYPES.HEROEARNINGS]: HeroEarningsWrapper,
  [CONTENT_TYPES.BANNER]: BannerWrapper,
  [CONTENT_TYPES.SECTIONSPLIT]: SectionSplitWrapper,
  [CONTENT_TYPES.SECTIONFEATURES]: SectionFeaturesWrapper,
  [CONTENT_TYPES.SECTIONTESTIMONIALS]: TestimonialsWrapper,
  [CONTENT_TYPES.SECTIONFEATURESSPLIT]: SectionFeaturesSplitWrapper,
  [CONTENT_TYPES.FAQS]: FaqsWrapper,
  [CONTENT_TYPES.FEATUREDVEHICLES]: FeaturedVehiclesWrapper,
  [CONTENT_TYPES.CARDCAROUSEL]: SectionCardsCarouselWrapper
}

interface LayoutProps {
  layout: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.WpPage_Layoutsettings_Layout>>>
}

const Layout = ({ layout, ...rest }: LayoutProps) => {
  const modules = useMemo(() => {
    if (!layout) {
      return <h1>No layout</h1>
    }
    return layout.map((l, index) => {
      if (!l) return null
      const { __typename } = l

      const Component = LAYOUT_MAPPING[__typename]
      if (!Component) return null
      return <Component {...l} key={index} cIndex={index} {...rest} />
    })
  }, [layout, rest])

  return <>{modules}</>
}

export default Layout
