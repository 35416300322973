import React, { useCallback, useState } from 'react'
import { Box, Image as RebassImage, SxStyleProp } from 'rebass'
import Skeleton from 'react-loading-skeleton'

const imageStyles = (loading: boolean): SxStyleProp => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  opacity: loading ? 0 : 1,
  transition: 'opacity 0.2s'
})

interface ImageWrapperProps {
  src: string
  placeholderImg?: string
  alt: string
  ratio?: number
  onLoad?: () => void
}

const ImageWrapper = ({ src, alt, ratio = 1.67, onLoad }: ImageWrapperProps) => {
  const [loading, setLoading] = useState(true)

  const handleLoad = useCallback(() => {
    setLoading(false)
    onLoad && onLoad()
  }, [onLoad])

  return (
    <Box sx={{ position: 'relative', width: '100%', pt: `${100 / ratio}%` }}>
      <RebassImage src={src} alt={alt} sx={imageStyles(loading)} onLoad={handleLoad} />
      <Box sx={{ ...imageStyles(!loading) }}>
        <Skeleton width="100%" height="100%" />
      </Box>
    </Box>
  )
}

export default ImageWrapper
