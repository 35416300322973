import React, { useState, forwardRef } from 'react'

import { InputValidator } from '@rmr/helpers'
import Input, { InputProps } from '../Input'
import languages from '../../../i18n'

export interface CellPhoneInputProps extends InputProps {
  required?: boolean
  callback?: (phoneNumber: string | null) => void
  inError?: string
}
const CellPhoneInput = forwardRef<HTMLInputElement, CellPhoneInputProps>(
  ({ required, callback, inError, ...props }, ref) => {
    const [value, setValue] = useState('')
    const [error, setError] = useState('')

    const onChange = (phoneNumber: string) => {
      setError('')
      setValue(phoneNumber)
    }

    const validate = (phoneNumber: string) => {
      const inputValidator = new InputValidator(phoneNumber)
      if (!phoneNumber) {
        if (required) {
          setError(languages['en'].required)
        }
        return ''
      }
      if (!inputValidator.validateCellphone('ZA')) {
        callback && callback('')
        setError(languages['en'].invalidPhoneNumber)
        return ''
      }
      callback && callback(phoneNumber)
      const formatted = inputValidator.formatCellphone('INTERNATIONAL', 'ZA')
      setValue(formatted)
    }

    return (
      <Input
        {...props}
        type="text"
        rebassInputProps={{
          onBlur: (e) => validate(e.target.value),
          onChange: (e) => onChange(e.target.value),
          value: value
        }}
        error={inError || error}
        ref={ref}
      />
    )
  }
)

CellPhoneInput.defaultProps = {
  label: '',
  toolTipInfo: '',
  placeholder: '+27',
  name: '',
  required: true,
  callback: null,
  inError: '',
  sx: {},
  innerSx: {}
}

export default CellPhoneInput
