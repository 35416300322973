import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import Text from '../Text'
import theme from '../../theme'
import Content from '../Content'
import parse from 'html-react-parser'
import Icon from '../Icon/Icon'
import { FeatureIconTypes, BadgeProps, IconProps } from './types'

const StyledFeatureIconCard = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 1px 1px 15px 1px ${theme.colors.bgShadow};
  background-color: ${theme.colors.bgWhite};
  border-radius: ${theme.borderRadius.large};
`

const StyledIconWrapper = styled(Flex)`
  position: relative;
`

const StyledBadge = styled(Flex)<BadgeProps>`
  position: absolute;
  top: -1.25rem;
  left: 50%;
  border-radius: ${theme.borderRadius.circle};
  z-index: 2;
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'inherit')};
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateX(-50%);
  @media (min-width: ${theme.breakpoints[0]}) {
    top: -1.75rem;
    width: 3.5rem;
    height: 3.5rem;
  }
`
const StyledIcon = styled(Icon)<IconProps>`
  width: 100%;
  height: auto;
  overflow: 'hidden';
  svg {
    width: 100% !important;
    height: auto;
  }
`

const StyledFeatureContent = styled(Box)`
  display: block;
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.medium};
`

export const FeatureIconCard: FC<FeatureIconTypes> = ({
  headline,
  featureContent,
  icon,
  badge,
  headlineColor,
  textColor,
  badgeTextColor,
  badgeBgColor,
  iconBgColor,
  iconColor,
  sx,
  ...props
}) => {
  return (
    <StyledFeatureIconCard width={['100%']} mt={[6]} {...props} sx={{ ...sx }}>
      <Box sx={{ position: 'relative' }} py={[4, 4, 6]} px={[2]}>
        {badge && (
          <StyledBadge bgColor={badgeBgColor}>
            <Text size="small" fontWeight={theme.fontWeights.extraBold} color={badgeTextColor}>
              {badge}
            </Text>
          </StyledBadge>
        )}
        <Flex
          alignItems={['center', 'center', 'center', 'center', 'flex-start']}
          justifyContent={['flex-start']}
          width="100%"
          flexDirection={['column', 'column', 'column', 'column', 'row']}
        >
          <StyledIconWrapper mr={[0, 0, 0, 0, 3]} mb={[1]} width={['20%']} px={[0, 0, 0, 0, 2]}>
            {icon && <StyledIcon name={icon} fontAwesomeIconProps={{ color: iconColor }} />}
          </StyledIconWrapper>

          <Flex
            flexDirection="column"
            alignItems={['center', 'center', 'center', 'center', 'flex-start']}
            width={['100%', '100%', '100%', '100%', '80%']}
          >
            {headline && (
              <Text
                mb={[1]}
                lineHeight={1}
                textAlign={['center', 'center', 'center', 'center', 'left']}
                size="body"
                fontWeight={theme.fontWeights.extraBold}
                color={headlineColor}
              >
                {headline}
              </Text>
            )}
            {featureContent && (
              <StyledFeatureContent textAlign={['center', 'center', 'center', 'center', 'left']} color={textColor}>
                <Content>{parse(featureContent)}</Content>
              </StyledFeatureContent>
            )}
          </Flex>
        </Flex>
      </Box>
    </StyledFeatureIconCard>
  )
}

export default FeatureIconCard
