import styled from '@emotion/styled'
import { Box} from 'rebass'

export const StyledNotice = styled(Box)`
  position: relative;
`

export const StyledContainer = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  padding-left: 35px;
  padding-right: 35px;
  max-width: 1280px
`
export const closeIconStyles = {
  position: 'absolute',
  top: '4px',
  right: '8px',
  fontSize: '20px',
  cursor: 'pointer'
}
