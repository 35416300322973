import React, { FC } from 'react'
import { Box, SxStyleProp, Flex } from 'rebass'
import Skeleton from 'react-loading-skeleton'
import theme from '../../theme'
import Text from '../Text/Text'
import StarRating from '../StarRating/StarRating'

export interface VehicleSummaryProps {
  /** Number of reviews */
  reviewsCount?: number
  /** Number of trips */
  tripsCount?: number
  /** Average value of Ratings */
  averageRating?: number
  /** Sx Props */
  sx?: SxStyleProp
  /** Component is loading */
  loading?: boolean
}

const VehicleSummary: FC<VehicleSummaryProps> = ({
  reviewsCount,
  tripsCount,
  averageRating,
  sx,
  loading,
  ...props
}) => {
  return (
    <Box {...props} sx={{ ...sx }}>
      <Flex alignItems={['center']} justifyContent={['center']} flexDirection={['column']} mb={[2, 4]}>
        <Flex alignItems="center" flexWrap="wrap">
          <Text
            sx={{ fontSize: ['14px', '16px'] }}
            color={theme.colors.textDark}
            fontWeight={theme.fontWeights.semiBold}
            lineHeight={1}
            mx={[2]}
            textAlign={['center']}
          >
            {!loading ? `${reviewsCount} Reviews / ${tripsCount} Trips` : <Skeleton style={{ width: '100px' }} />}
          </Text>
        </Flex>
        {!loading ? (
          <Box flex="0 0 auto">
            {averageRating > 0 ? (
              <StarRating
                mt={[1]}
                activeColor={theme.colors.statusRating}
                color={theme.colors.bgGrayLight}
                size={18}
                value={averageRating}
                edit={false}
              />
            ) : null}
          </Box>
        ) : (
          <Flex alignItems="center">
            <Skeleton style={{ width: '100px' }} />
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

VehicleSummary.defaultProps = {
  reviewsCount: 0,
  averageRating: null,
  tripsCount: 0,
  loading: false
}

export default VehicleSummary
