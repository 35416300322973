import React, { FC } from 'react'
import { Box, BoxProps, SxStyleProp } from 'rebass'

import { gridStyles } from './CssGrid.styled'

export interface CssGridProps extends BoxProps {
  /** Children */
  children: React.ReactNode[]
  /** Number of columns depending on screen width */
  columns: number[]
  /** Sx Styles */
  sx?: SxStyleProp
}

const CssGrid = ({ children, columns, sx, ...props }: CssGridProps) => {
  return (
    <Box {...props} sx={{ ...gridStyles(columns), ...sx }}>
      {children.map((child, index) => (
        <Box key={index} p={2}>
          {child}
        </Box>
      ))}
    </Box>
  )
}

CssGrid.defaultProps = {
  children: [],
  colunns: [1, 2, 3],
  sx: {}
}

export default CssGrid
