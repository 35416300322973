import {
  Container,
  Icon,
  ListItem,
  ListItems,
  Radio,
  StepsWrapper,
  stepsWrapperStyles,
  Text,
  VehicleCardMobile
} from '@rmr/components'
import { addressToString, Reservation, useMeQuery } from '@rmr/controllers'
import React from 'react'
import { Box, Flex, Link as RebassLink } from 'rebass'
import { languages } from '../../../common/i18n'
import ApplyRemoveCoupon from '../../ApplyRemoveCoupon'
import PaymentForm from '../PaymentForm'
import Dates from './Dates'
import { itemStyles, reservationWrapperStyles } from './ReservationQuote.styled'

interface ReservationPaymentProps {
  reservation: Reservation
  onRefetch: () => void
}

const ReservationPayment = ({ reservation, onRefetch }: ReservationPaymentProps) => {
  const {
    data: { me }
  } = useMeQuery()

  const { vehicle, lineItems, address, couponCodes } = reservation
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
  }

  return (
    <Box as="section" sx={{ pt: [15], pb: [6] }}>
      <Container>
        <StepsWrapper
          badgeProps={{ variant: 'circle', size: [150], icon: 'money' }}
          title={'Rental Payment'}
          subtitle={'You will be redirected to the payment gateway to complete your rental payment securely.'}
          sx={stepsWrapperStyles}
        >
          <Box as="form" onSubmit={handleSubmit} sx={reservationWrapperStyles}>
            <Box>
              <Box mb={[3]}>
                <VehicleCardMobile
                  // price={vehicle.pricePerDay.formatted}
                  // priceDistribution="daily"
                  carName={vehicle.name}
                  image={vehicle.images[0].file.url}
                  totalTrips={vehicle.stats.trips}
                  rating={vehicle.stats.ratings}
                  language="en"
                  boxShadow={false}
                />
              </Box>
              <ListItems>
                {lineItems.map((line, index) => {
                  return <ListItem {...line} key={line.title} />
                })}
              </ListItems>
            </Box>
            <Box>
              <Dates startDate={reservation.dates.startFullDate} endDate={reservation.dates.endFullDate} />
              <Box sx={itemStyles}>
                <Text size="large" color="textBlack" fontWeight="bold" mb={3}>
                  {languages.en.booking.deliverOptions}
                </Text>
                {address && (
                  <Box mb={1}>
                    <Text size="small" color="textBlack" fontWeight="bold">
                      {languages.en.booking.addressLabel}
                    </Text>
                    <Flex alignItems="center">
                      <Text size="small" color="textLight">
                        {addressToString(address)}
                      </Text>
                      {address.placeId && (
                        <RebassLink
                          href={`https://www.google.com/maps/place/?q=place_id:${address.placeId}`}
                          flexShrink={0}
                          ml={2}
                          target="_blank"
                        >
                          <Icon name="navigation" color="primary" />
                        </RebassLink>
                      )}
                    </Flex>
                  </Box>
                )}
                {reservation.vehicle.is_deliver_renter ? (
                  <Radio
                    name="isDropoff"
                    variant={'vertical'}
                    disabled
                    options={[
                      {
                        label: `Vehicle Drop-off - ${
                          !reservation.vehicle.deliveryFeeRenter ||
                          reservation.vehicle.deliveryFeeRenter.amountFloat === 0
                            ? 'Free'
                            : reservation.vehicle.deliveryFeeRenter.formatted
                        }`,
                        value: 1
                      }
                    ]}
                    selectedOption={reservation.vehicle.is_deliver_renter ? 1 : 0}
                  />
                ) : null}
              </Box>
              <Box mt={2}>
                <ApplyRemoveCoupon id={reservation.id} appliedCoupon={couponCodes[0]} onRefetch={onRefetch} />
              </Box>
              <Box mt={2}>
                <Text size="large" color="textBlack" fontWeight="bold" mb={2}>
                  {languages.en.booking.paymentLabel}
                </Text>
                <PaymentForm reservation={reservation} cards={me?.cards ?? []} />
              </Box>
            </Box>
          </Box>
        </StepsWrapper>
      </Container>
    </Box>
  )
}

export default ReservationPayment
