import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginInput, LoginMutation, useLoginMutation } from '../lib/generated/generated'
import { validationMessages } from '../i18n'
import { useSubmitResult } from './useSubmitResult'
interface UseLoginArgs {
  onSuccess: (response: LoginMutation) => void
  onError: (e: Error) => void
}

const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email(validationMessages.en.email)
    .required(validationMessages.en.required)
    .test('email', validationMessages.en.email, (val) => val && val.length >= 6),
  password: yup
    .string()
    .required(validationMessages.en.required)
    .test('password', validationMessages.en.passwordLength, (val) => val && val.length >= 6)
})

export function useLoginForm({ onSuccess, onError }: UseLoginArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const [loginMutation, { loading }] = useLoginMutation()

  const onSubmit = useCallback(
    async (data: LoginInput) => {
      try {
        const response = await loginMutation({ variables: { input: data } })
        onSuccess(response.data)
        const { login } = response.data
        if (login.__typename === 'AuthTokens') {
          onSubmitSuccess()
        } else if (login.message) {
          onSubmitError('error', login.message)
        } else {
          onSubmitError()
        }
      } catch (err) {
        onError(err)
        onSubmitError()
      }
    },
    [loginMutation, onSuccess, onError, onSubmitError, onSubmitSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult,
    onSubmitSuccess
  }
}
