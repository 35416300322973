import { SxStyleProp } from 'rebass'
import theme from '../../theme/theme'
export const popoverStyles: SxStyleProp = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative'
}

export const iconStyles: SxStyleProp = {
  px: 1
}

export type TooltipOffset = { translate: string | number; left: string | number; right: string | number }

export const dropdownWrapperStyles = (open: boolean, height: number, offset: number): SxStyleProp => ({
  top: 'calc(100% + 15px)',
  position: 'absolute',
  left: '50%',
  height: open ? `${height}px` : 0,
  opacity: open ? 1 : 0,
  transition: 'all 0.2s',
  transform: `translateX(calc(${offset}px - 50%))`,
  zIndex: 2,
  overflow: 'hidden'
})
export const dropdownStyles: SxStyleProp = {
  px: [2],
  py: [1],
  borderRadius: `${theme.borderRadius.small}`
}

export const contentStyles: SxStyleProp = {
  width: 'max-content',
  maxWidth: ['calc(100vw - 60px)', 600]
}
