import React, { FC, forwardRef } from 'react'
import { Text as RebassText, TextProps as RebassTextProps, SxStyleProp } from 'rebass'
import { TextFontWeight } from './types'
import { textSizes, TextSize } from './Text.options'

type TextTag = 'span' | 'p'
export interface TextProps extends RebassTextProps {
  /** HTML text tag */
  as?: TextTag
  /** Size of text */
  size?: TextSize
  /** Weight of text */
  fontWeight?: keyof TextFontWeight
  /** Line Height */
  lineHeight?: number
  /** Colour of text in text */
  color?: string
  /** Sx prop */
  sx?: SxStyleProp
  /** Standard React children */
  children?: React.ReactNode
}

const Text = forwardRef<HTMLElement, TextProps>(({ children, as, size, sx, ...props }, ref) => {
  const textProps = { ...textSizes[size], ...sx }
  return (
    <RebassText as={as} sx={{ ...textProps }} ref={ref} {...props}>
      {children}
    </RebassText>
  )
})
Text.defaultProps = {
  as: 'p',
  size: 'body',
  color: 'textDark',
  lineHeight: 1.5,
  fontWeight: 'semiBold',
  children: null,
  letterSpacing: 'medium',
  sx: {}
}

export default Text
