export const sectionStyles = {
  py: [4, 6],
  bg: 'bgDark'
}

export const navigationWrapperStyles = {
  display: 'grid',
  columnGap: 4,
  rowGap: 4,
  gridTemplateColumns: [null, null, 'repeat(2,1fr)', null, 'repeat(4,1fr)']
}

export const textStyles = {}

export const listStyles = {
  listStyle: 'none',
  pl: 0,
  py: 2,
  mt: 2,
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: 'borderBase'
}

export const listItemStyles = {
  fontSize: 'small',
  py: 1,
  a: {
    color: 'textLight',
    textDecoration: 'none',
    transition: 'color 0.2s',
    ':hover': {
      color: 'primary'
    }
  }
}
