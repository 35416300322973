import React, { useState, useRef, useEffect } from 'react'
import { Box, BoxProps, Flex, SxStyleProp } from 'rebass'

import Icon from '../Icon'
import Badge, { BadgeProps } from '../Badge'
import Heading from '../Heading'
import Text from '../Text'
import { wrapperStyles, closeIconStyles, topBadgeStyles } from './StepsWrapper.styled'
import Separator from '../Separator'

export interface StepsWrapperProps extends BoxProps {
  /**
   * Optional top badge.
   */
  badgeProps?: BadgeProps
  /**
   * Optional Title
   */
  title?: string
  /**
   * Optional SubTitle.
   */
  subtitle?: React.ReactNode

  /**
   * Has a separator under title / subtitle?
   */
  separator?: boolean
  /**
   * Separator Color
   */
  separatorColor?: string
  /**
   * Callback function for closing the modal
   * (i.e. setting `isStepsWrapperOpen` to false, wherever that is stored in the component tree).
   */
  closeStepsWrapper?: () => void
  /**
   * The content to be rendered in the modal.
   */
  children: React.ReactNode
  /**
   * Sx Body Prop Styles
   */
  sxBody?: SxStyleProp
  /**
   * Sx Prop Styles
   */
  sx?: SxStyleProp
}

const StepsWrapper = ({
  badgeProps,
  title,
  subtitle,
  separator,
  separatorColor,
  children,
  closeStepsWrapper,
  sxBody,
  sx,
  ...props
}: StepsWrapperProps) => {
  const [badgeHeight, setBadgeHeight] = useState(0)

  const handleBadgeHeight = (e: HTMLDivElement) => {
    if (e && e.clientHeight > 0 && e.offsetTop > 0) {
      const newBadgeHeight = e.clientHeight / 2 + e.offsetTop
      if (newBadgeHeight !== badgeHeight) {
        setBadgeHeight(newBadgeHeight)
      }
    }
  }
  return (
    <Box sx={{ ...wrapperStyles, ...sx }} {...props}>
      {closeStepsWrapper && <Icon onClick={() => closeStepsWrapper()} name="close" sx={closeIconStyles} />}
      {badgeProps && <Badge ref={handleBadgeHeight} {...badgeProps} sx={topBadgeStyles(badgeHeight)} />}
      <Box sx={sxBody} mx="auto">
        {title && (
          <Heading size="h3" color="textBlack" textAlign="center" mb={1} lineHeight={'1'}>
            {title}
          </Heading>
        )}
        {subtitle && (
          <Text size="small" color="textLight" textAlign="center">
            {subtitle}
          </Text>
        )}
        {separator && (
          <Flex sx={{ justifyContent: 'center', mt: [2] }}>
            <Separator bg={separatorColor} width={[87]} />
          </Flex>
        )}
        <Box mx="auto">{children}</Box>
      </Box>
    </Box>
  )
}

StepsWrapper.defaultProps = {
  badgeProps: null,
  title: null,
  subtitle: null,
  separator: false,
  separatorColor: 'bgBlack',
  children: null,
  sxBody: {},
  sx: {}
}

export default StepsWrapper
