import { SxStyleProp } from 'rebass'

import theme from '../../../theme'

export const wrapperStyles: SxStyleProp = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative'
}

export const iconStyles: SxStyleProp = {
  px: 1
}

export type TooltipOffset = { translate: string | number; left: string | number; right: string | number }

export const contentStyles = (active: boolean): SxStyleProp => ({
  display: 'flex',
  alignItems: 'center',
  flex: '1 1 auto',
  py: [1],
  px: [2],
  bg: active ? 'primaryLight' : 'transparent',
  cursor: 'pointer',
  color: active ? `${theme.colors.textWhite} !important` : `${theme.colors.textDark} !important`,
  svg: {
    color: active ? 'textWhite' : 'primary'
  },
  ':hover': {
    bg: 'primaryLight',
    color: `${theme.colors.textWhite} !important`,
    svg: {
      color: 'textWhite'
    }
  }
})

export const closeIconStyles: SxStyleProp = {
  flex: '0 0 auto',
  bg: 'primaryLight',
  px: 1,
  zIndex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}

export const applyButtonStyles: SxStyleProp = {
  flexDirection: 'column',
  width: '100%',
  p: [1],
  fontSize: `${theme.fontSizes.tiny} !important`
}

export const selectButtonStyles: SxStyleProp = {
  width: '100% !important',
  height: [50],
  justifyContent: 'space-between',
  px: [2],
  bg: 'bgWhite',
  color: 'textDark',
  borderColor: 'borderInput',
  '> div': {
    svg: {
      color: 'textDark'
    }
  }
}

export const buttonStyles = (selectMode: boolean, groupMode: boolean): SxStyleProp =>
  groupMode
    ? {
        width: '100% !important',
        height: [30],
        justifyContent: 'space-between',
        px: [2],
        py: [0]
      }
    : selectMode
    ? selectButtonStyles
    : {}
