export const icons = {
  zero: 'zero',
  one: 'one',
  two: 'two',
  three: 'three',
  four: 'four',
  five: 'five',
  six: 'six',
  seven: 'seven',
  eight: 'eight',
  nine: 'nine',
  zeroCircle: 'zeroCircle',
  oneCircle: 'oneCircle',
  twoCircle: 'twoCircle',
  threeCircle: 'threeCircle',
  fourCircle: 'fourCircle',
  fiveCircle: 'fiveCircle',
  sixCircle: 'sixCircle',
  sevenCircle: 'sevenCircle',
  eightCircle: 'eightCircle',
  nineCircle: 'nineCircle',
  android: 'android',
  apple: 'apple',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  arrowDown: 'arrowDown',
  award: 'award',
  bakkie: 'bakkie',
  balance: 'balance',
  badgePercent: 'badgePercent',
  bicycle: 'bicycle',
  blackCheckOutline: 'blackCheckOutline',
  blackCheckOutlineInverted: 'blackCheckOutlineInverted',
  blackClose: 'blackClose',
  blackCircle: 'blackCircle',
  blankCard: 'blankCard',
  bluetooth: 'bluetooth',
  broadRoundBorder: 'broadRoundBorder',
  bumpy: 'bumpy',
  calendar: 'calendar',
  camera: 'camera',
  cameraHome: 'cameraHome',
  caretCircleRight: 'caretCircleRight',
  caretDown: 'caretDown',
  cars: 'cars',
  carSide: 'carSide',
  chargingStation: 'chargingStation',
  check: 'check',
  checkSolid: 'checkSolid',
  checkCircle: 'checkCircle',
  checklist: 'checklist',
  child: 'child',
  close: 'close',
  commentTimes: 'commentTimes',
  copy: 'copy',
  copyOutline: 'copyOutline',
  creditCardFront: 'creditCardFront',
  deposit: 'deposit',
  document: 'document',
  dog: 'dog',
  edit: 'edit',
  envelope: 'envelope',
  envelopeOutline: 'envelopeOutline',
  error: 'error',
  equals: 'equals',
  eyeClosed: 'eyeClosed',
  eyeOpen: 'eyeOpen',
  eyeOpenLight: 'eyeOpenLight',
  facebook: 'facebook',
  facebookCirle: 'facebookCirle',
  facebookOutline: 'facebookOutline',
  faceMoney: 'faceMoney',
  filter: 'filter',
  frontCar: 'frontCar',
  gasPump: 'gasPump',
  greenCheck: 'greenCheck',
  handHolding: 'handHolding',
  handshake: 'handshake',
  heart: 'heart',
  heartSolid: 'heartSolid',
  heat: 'heat',
  image: 'image',
  imagePlaceholder: 'imagePlaceholder',
  info: 'info',
  instagram: 'instagram',
  linkedin: 'linkedin',
  loader: 'loader',
  loaderDuotone: 'loaderDuotone',
  mapLocation: 'mapLocation',
  mobile: 'mobile',
  money: 'money',
  moneyCheck: 'moneyCheck',
  monsterTruck: 'monsterTruck',
  navigation: 'navigation',
  odometer: 'odometer',
  paperClip: 'paperClip',
  pen: 'pen',
  plane: 'plane',
  plug: 'plug',
  plus: 'plus',
  price: 'price',
  qrCode: 'qrCode',
  question: 'question',
  questionThin: 'questionThin',
  redClose: 'redClose',
  route: 'route',
  searchLocation: 'searchLocation',
  select: 'select',
  sensor: 'sensor',
  shieldCheck: 'shieldCheck',
  shieldCheckRegular: 'shieldCheckRegular',
  shieldCheckSolid: 'shieldCheckSolid',
  signal: 'signal',
  speaker: 'speaker',
  star: 'star',
  starHalf: 'starHalf',
  starLight: 'starLight',
  steeringWheel: 'steeringWheel',
  sun: 'sun',
  tachometerAltAverage: 'tachometerAltAverage',
  tag: 'tag',
  ticket: 'ticket',
  thumbsUp: 'thumbsUp',
  truck: 'truck',
  twitter: 'twitter',
  twitterOutline: 'twitterOutline',
  umbrella: 'umbrella',
  usb: 'usb',
  user: 'user',
  whatsapp: 'whatsapp',
  whatsappOutline: 'whatsappOutline',
  wheelchair: 'wheelchair'
}

export type IconType = keyof typeof icons
