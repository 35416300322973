import React from 'react'
import { useLocation } from '@reach/router'
import { Box, Flex } from 'rebass'
import { Container, Icon, theme, Link } from '@rmr/components'
import { facebookSharer, twitterSharer, emailSharer } from '../../common/constants'
import SearchBar, { SearchBarVariant } from '../SearchBar'
import {
  barSocialStyles,
  searchBarStyles,
  socialStyles,
  socialWrapperStyles,
  wrapperStyles
} from './SearchBarWithSocial.styled'
import { SearchVehicleInput } from '@rmr/controllers'
import useMedia from 'use-media'
import { LocationSources } from '../SearchBar/SearchBar'
interface SearchBarWithSocialProps {
  filter: SearchVehicleInput
  children: React.ReactNode
}

const SearchBarWithSocial = ({ filter, children }: SearchBarWithSocialProps) => {
  const { href } = useLocation()
  const isMobile = useMedia({ maxWidth: theme.breakpoints[2] }) // 576px
  return (
    <Box as="section" sx={wrapperStyles}>
      <Container maxWidth={'1620px'}>
        <Flex sx={barSocialStyles}>
          {children}
          {!isMobile && (
            <Flex sx={socialWrapperStyles}>
              <Link to={facebookSharer(href)} target="_blank" rel="noopener noreferrer" sx={socialStyles}>
                <Icon name="facebookOutline" />
              </Link>
              <Link to={twitterSharer(href)} target="_blank" rel="noopener noreferrer" sx={socialStyles}>
                <Icon name="twitterOutline" />
              </Link>
              <Link to={emailSharer(href)} target="_blank" rel="noopener noreferrer" sx={socialStyles}>
                <Icon name="envelopeOutline" />
              </Link>
            </Flex>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default SearchBarWithSocial
