import React from 'react'
import { Box, Link as RebassLink } from 'rebass'
import { graphql, useStaticQuery } from 'gatsby'

import { Button, Content, WizardStep } from '@rmr/components'
import Layout from './Layout'
import { StepWizardChildProps } from 'react-step-wizard'
import { languages } from '../../common/i18n'

interface ListingConfirmationProps extends Partial<StepWizardChildProps> {
  idRef: string
  vehicleid: string
  wizardSteps: WizardStep[]
}
const ListingConfirmation = ({
  idRef,
  vehicleid,
  currentStep,
  goToNamedStep,
  wizardSteps
}: ListingConfirmationProps) => {
  const { wpPage } = useStaticQuery<GatsbyTypes.ListingSuccessQuery>(graphql`
    query ListingSuccess {
      wpPage(slug: { eq: "listing-success" }) {
        layoutSettings {
          layout {
            __typename
            ... on WpPage_Layoutsettings_Layout_Banner {
              ...BannerFields
            }
          }
        }
      }
    }
  `)
  const {
    layoutSettings: { layout }
  } = wpPage
  let heading = '',
    content = ''
  if (layout[0].__typename === 'WpPage_Layoutsettings_Layout_Banner') {
    const { headline, bannerContent } = layout[0]
    heading = headline
    content = bannerContent
  }

  return (
    <Layout
      badgeProps={{ variant: 'circle', size: [150], icon: 'check', color: 'statusSuccess' }}
      stepsWrapperHeadline={heading}
      separator={true}
      separatorColor="statusSuccess"
      idRef={idRef}
      currentStep={currentStep}
      // previousStep={previousStep}
      stepsWrapperSx={{ mt: [2], maxWidth: [1000] }}
      showWizard={false}
      wizardSteps={wizardSteps}
      goToNamedStep={goToNamedStep}
    >
      <Content sx={{ '& p': { fontSize: 'body', textAlign: 'center' } }} mb={1}>
        <Box dangerouslySetInnerHTML={{ __html: content }} />
      </Content>
      <RebassLink href={`${process.env.GATSBY_NX_DASHBOARD_LINK}/vehicles/${vehicleid}`}>
        <Button variant="primary">{languages.en.listYourCar.confirmation.buttonText}</Button>
      </RebassLink>
    </Layout>
  )
}

export default ListingConfirmation
