import { Input as RebassInput, InputProps as RebassInputProps } from '@rebass/forms'
import { Label } from '@rmr/components'
import React, { forwardRef } from 'react'
import { Flex, SxStyleProp } from 'rebass'
import Error from '../Error'
import { fieldStyles, fieldWrapper, inlineLabelStyles, inlineLabelStylesWrapper } from '../Form.styled'
import FormGroup from '../FormGroup'
import LabelTooltip from '../LabelTooltip'

export interface InputProps {
  /**
   * Field label
   */
  label?: React.ReactNode
  /**
   * Is the label inline or above field?
   */
  labelInline?: boolean
  /**
   * Info text for help
   */
  toolTipInfo?: string
  /**
   * Field placeholder
   */
  placeholder?: string
  /**
   * Field type
   */
  type?: string
  /**
   * Field name
   */
  name: string
  /**
   * Error message
   */
  error?: string
  /**
   * Wrapper Sx prop
   */
  sx?: SxStyleProp
  /**
   * Inline Label Sx prop
   */
  inlineLabelSx?: SxStyleProp
  /**
   * Inner Sx prop
   */
  innerSx?: SxStyleProp
  /**
   * Rebass Input props
   */
  rebassInputProps?: RebassInputProps
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      labelInline,
      inlineLabelSx,
      placeholder,
      type,
      name,
      error,
      innerSx,
      rebassInputProps,
      toolTipInfo,
      ...props
    },
    ref
  ) => {
    return (
      <FormGroup {...props}>
        {!labelInline && label && <LabelTooltip labelText={label} toolTipInfo={toolTipInfo} mb={1} />}
        <Flex sx={fieldWrapper(labelInline)}>
          {labelInline && label && (
            <Flex sx={{ ...inlineLabelStylesWrapper, ...inlineLabelSx }}>
              <Label sx={inlineLabelStyles}>{label}</Label>
            </Flex>
          )}{' '}
          <RebassInput
            {...{ placeholder, type, name }}
            sx={{ ...fieldStyles, ...innerSx }}
            {...rebassInputProps}
            ref={ref}
            autoComplete="off"
          />
        </Flex>
        {error && <Error>{error}</Error>}
      </FormGroup>
    )
  }
)

Input.defaultProps = {
  label: null,
  labelInline: false,
  inlineLabelSx: {},
  toolTipInfo: null,
  placeholder: null,
  type: 'text',
  name: null,
  error: null,
  sx: {},
  innerSx: {},
  rebassInputProps: {}
}

export default Input
