import { SxStyleProp } from 'rebass'

export const fieldStyles: SxStyleProp = {
  width: '100%',
  flex: '1'
}

export const buttonStyles: SxStyleProp = {
  mt: [2]
}
