import { LocationMarkerIcon } from '@heroicons/react/solid'
import { VehiclePlaceFieldsFragment } from '@rmr/controllers'
import { getMapBounds } from '@rmr/helpers'
import GoogleMapReact, { fitBounds } from 'google-map-react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box } from 'rebass'

const defaultCenterZoom = { center: { lat: -28.739, lng: 24.746 }, zoom: 5 }

interface VehiclePlacesMapProps {
  vehiclePlaces: VehiclePlaceFieldsFragment[]
}
const VehicleMap = ({ vehiclePlaces }: VehiclePlacesMapProps) => {
  const mapRef = useRef(null)

  const [centerZoom, setCenterZoom] = useState(defaultCenterZoom)
  const { ne, sw } = useMemo(() => {
    return getMapBounds(
      vehiclePlaces.map((vehiclePlace) => {
        return { lat: vehiclePlace.lat, lng: vehiclePlace.lng }
      })
    )
  }, [vehiclePlaces])

  const getCenterZoom = useCallback(() => {
    const { map, maps } = mapRef.current
    const swLatLng = new maps.LatLng(sw.lat, sw.lng)
    const neLatLng = new maps.LatLng(ne.lat, ne.lng)
    const bounds = new maps.LatLngBounds(swLatLng, neLatLng)
    map.fitBounds(bounds)
    if (map.zoom > 15) {
      map.setZoom(15)
    }
    const { center, zoom } = map
    return { center, zoom }
  }, [ne.lat, ne.lng, sw.lat, sw.lng])

  useEffect(() => {
    if (mapRef.current) {
      if (vehiclePlaces.length > 0) {
        const centerZoom = getCenterZoom()
        setCenterZoom(centerZoom)
      } else {
        setCenterZoom(defaultCenterZoom)
      }
    }
  }, [getCenterZoom, vehiclePlaces.length])

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <GoogleMapReact
        // Load on React Helmet
        // bootstrapURLKeys={{ key: process.env.NX_GOOGLE_MAPS_API_KEY }}
        center={centerZoom.center}
        zoom={centerZoom.zoom}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = { map, maps }
          const centerZoom = getCenterZoom()
          setCenterZoom(centerZoom)
        }}
      >
        {vehiclePlaces.map(({ id, lat, lng }) => (
          //@ts-ignore
          <LocationMarkerIcon
            key={id}
            lat={lat}
            lng={lng}
            style={{ width: '40px', height: '40px', color: 'red', transform: 'translate(-50%,-100%)' }}
          />
        ))}
      </GoogleMapReact>
    </Box>
  )
}

export default VehicleMap
