import React from 'react'
import { theme, Text } from '@rmr/components'
import { languages } from '../../../common/i18n'
import { Box } from 'rebass'

interface DatesProps {
  startDate: string
  endDate: string
}

const Dates = ({ startDate, endDate }: DatesProps) => {
  return (
    <Box sx={{ pb: [2], borderBottom: `1px solid ${theme.colors.borderLight}` }}>
      <Text size="large" color="textBlack" fontWeight="bold" mb={2}>
        {languages.en.booking.datesLabel}
      </Text>
      <table>
        <tr>
          <td>
            <Text size="medium" color="textBlack" fontWeight="bold" pr={2}>
              {`${languages.en.booking.fromLabel}:`}
            </Text>
          </td>
          <td>
            <Text size="medium" color="textLight" fontWeight="bold">
              {startDate}
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Box as="span" color="textBlack" fontWeight="bold" pr={2}>
              {`${languages.en.booking.toLabel}:`}
            </Box>
          </td>
          <td>
            <Text size="medium" color="textLight" fontWeight="bold">
              {endDate}
            </Text>
          </td>
        </tr>
      </table>
    </Box>
  )
}

export default Dates
