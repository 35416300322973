import React from 'react'
import { Router } from '@reach/router'

import Main from '../components/Main'
import PrivateRoute from '../components/PrivateRoute'
import Login from '../components/Login'
import Signup from '../components/Signup'
import Profile from '../components/Profile'
import Reset from '../components/Reset'
import Forgot from '../components/Forgot'
import Default from '../components/Default'
import ListYourCar from '../components/ListYourCar'
import VehicleListing from '../components/VehicleListing'
import ApprovalToList from '../components/ApprovalToList'
import VerifyEmail from '../components/VerifyEmail'
import Reservation, { ReservationConfirmation, PaymentConfirmation } from '../components/Reservation'
import ReservationExpired from '../components/Reservation/ReservationExpired'
import ReservationReview from '../components/ReservationReview'
import ReferAFriend from '../components/ReferAFriend'
const App = () => (
  <Router>
    <Main darkHeader noFooter path="/app">
      {/* <PrivateRoute path="/list-your-car/" requirement="isApprovedOwner" component={ListYourCar} />
      <PrivateRoute path="/list-your-car/:vehicleid" requirement="isApprovedOwner" component={ListYourCar} /> */}
      <PrivateRoute path="/refer-a-friend" component={ReferAFriend} />
      <PrivateRoute path="/refer-a-friend" component={ReferAFriend} />
      <PrivateRoute path="/list-your-car/" component={ListYourCar} />
      <PrivateRoute path="/list-your-car/:vehicleid" component={ListYourCar} />
      <PrivateRoute path="/approval-to-list" component={ApprovalToList} />
      <PrivateRoute path="/approval" component={ApprovalToList} />
      <PrivateRoute path="/approval-to-drive" component={ApprovalToList} />
      <PrivateRoute path="/booking/quote/:reservationId" component={Reservation} step="form" />
      <PrivateRoute path="/booking/confirm/:reservationId" component={Reservation} step="confirmation" />
      <PrivateRoute path="/booking/expired/:reservationId" component={ReservationExpired} />
      <PrivateRoute path="/booking/payment/:reservationId" component={Reservation} step="payment" />
      <PrivateRoute path="/booking/settlement/:reservationId" component={Reservation} step="settlement" />
      <PrivateRoute path="/booking/complete/:reservationId" component={PaymentConfirmation} step="payment" />
      <PrivateRoute path="/booking/review/:reservationId" component={ReservationReview} />

      <VehicleListing path="/listing/:vehicleId" />
      <VehicleListing path="/listing/:vehicleId/:startdate/:enddate" />
      <VerifyEmail path="/verify-email/:token" />
      <Signup path="/signup/" />
      <PrivateRoute path="/create/profile" component={Profile} />
      <Reset path="/reset/:resetToken" />
      <Forgot path="/forgot/" />
      <Login path="/login/" />
      <Default default />
    </Main>
  </Router>
)

export default App
