import { SxStyleProp } from 'rebass'
import theme from '../../theme'

export const circleStyles = (bg: string, contentColor: string): SxStyleProp => ({
  bg: bg,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: `${theme.borderRadius.circle}`,
  overflow: 'hidden',
  '& >:first-of-type': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '&& svg': {
    width: '40% ',
    height: 'auto',
    color: contentColor || 'textWhite'
  }
})

export const circleOutlinedStyles = (color: string, iconColor: string): SxStyleProp => ({
  ...circleStyles(color, iconColor),
  bg: 'bgWhite',
  border: `5px solid ${theme.colors[color]}`,
  '&& svg': {
    width: '40% ',
    height: 'auto',
    color: iconColor || color
  }
})
