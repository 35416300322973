import React, { FC } from 'react'
import { Box, BoxProps, SxStyleProp } from 'rebass'

import Carousel from '../Carousel'
import { carouselStyles, imageContainerStyles } from './HeroImageGallery.styled'

export interface HeroImageGalleryProps extends BoxProps {
  /** Images */
  children: React.ReactNode[]
  /** Sx styles Carousel */
  sx?: SxStyleProp
  /** Sx styles Image Container*/
  imageSx?: SxStyleProp
}

const HeroImageGallery: FC<HeroImageGalleryProps> = ({ children, sx, imageSx, ...props }) => {
  return (
    children &&
    children.length > 0 && (
      <Box {...props} sx={{ ...carouselStyles, ...sx }}>
        {children.length === 1 ? (
          <Box sx={{ ...imageContainerStyles, ...imageSx }}> {children} </Box>
        ) : (
          <Carousel variant="heroCarousel">
            {children.map((child, index) => (
              <Box key={index} sx={{ ...imageContainerStyles, ...imageSx }}>
                {' '}
                {child}{' '}
              </Box>
            ))}
          </Carousel>
        )}
      </Box>
    )
  )
}
HeroImageGallery.defaultProps = {
  children: [],
  sx: {},
  imageSx: {}
}

export default HeroImageGallery
