import { SxStyleProp } from 'rebass'
import theme from '../../theme/theme'
export const vehicleCardStyles: SxStyleProp = {
  maxWidth: 380,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'borderBase',
  borderRadius: `${theme.borderRadius.small}`,
  p: 3,
  '& p': {
    lineHeight: 1.17
  },
  display: 'flex !important',
  flexDirection: 'column',
  '.card-rating span': {
    fontSize: ['20px !important', '24px !important']
  },
  overflow: 'hidden'
}

export const imageWrapperStyles: SxStyleProp = {
  position: 'relative'
}

export const headerImagestyles: SxStyleProp = {
  width: '100%'
}

export const closeStyles: SxStyleProp = {
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  cursor: 'pointer'
}
