import { SxStyleProp } from 'rebass'

export const fieldStyles: SxStyleProp = {
  width: '100%',
  '.react-tel-input': {
    '.form-control': {
      height: '50px',
      width: '100%',
      border: '2px solid #CACACA'
    },
    '.flag-dropdown': {
      border: '2px solid #CACACA'
    }
  }
}
