import { SxStyleProp } from 'rebass'
import theme from '../../theme'

export const trackStyles: SxStyleProp = {
  height: [36],
  display: 'flex',
  width: '100%'
}
export const activeTrackStyles: SxStyleProp = {
  position: 'relative',
  height: '12px',
  width: '100%',
  borderRadius: '6px',
  alignSelf: 'center',
  transition: `all ${theme.speed.fast}`
}
export const thumbStyles: SxStyleProp = {
  position: 'absolute',
  height: [30, 48],
  width: [30, 48],
  backgroundColor: 'baseWhite',
  borderWidth: [8, 15],
  borderStyle: 'solid',
  borderColor: 'bgDark',
  borderRadius: `${theme.borderRadius.circle}`,
  outline: 0
}

export const thumbLabelStyles = (showValueOnThumb: boolean, thumbLabelTop: boolean, left: number): SxStyleProp => ({
  position: 'absolute',
  display: showValueOnThumb ? 'flex' : 'none !important',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  top: thumbLabelTop ? '-60px' : '40px',
  left: '50%',
  transform: left ? `translate(calc(${left}px - 50%))` : 'translate(-50%)',
  color: 'primary',
  fontSize: ['h5'],
  fontWeight: 'bold',
  whiteSpace: 'nowrap'
})

export const staticLabelStyles = { color: 'textDark', fontWeight: 700, fontSize: ['h4', 'h3'], mb: 2 }

export const limitsStyles = { alignItems: 'center', justifyContent: 'space-between', mt: 2 }

export const limitsLabelStyles = {
  color: 'textLight',
  fontSize: ' 15px',
  fontWeight: 'bold',
  opacity: 0.75
}
