import React, { useEffect } from 'react'
import { Box, Flex } from 'rebass'
import { StepWizardChildProps } from 'react-step-wizard'

import { Badge, IconType, Text, WizardStep } from '@rmr/components'

import Layout from '../Layout'
import {
  wrapperStyles,
  tagStyle,
  lineStyles,
  cardTextStyles,
  cardWrapperStyles,
  cardStyles,
  cardTitleStyles
} from './ListingGuideline.styled'
import { languages } from '../../../common/i18n'
import { scrollToHeadline } from '../../StepsNavigation'

interface ListingGuidelineProps extends Partial<StepWizardChildProps> {
  idRef: string
  wizardSteps: WizardStep[]
}

const ListingGuideline = ({ idRef, wizardSteps, goToNamedStep, currentStep, nextStep }: ListingGuidelineProps) => {
  useEffect(() => {
    scrollToHeadline(idRef)
  }, [scrollToHeadline])
  return (
    <Layout
      headline={languages['en'].listingGuideline.title}
      currentStep={currentStep - 1}
      buttonNextClick={() => {
        nextStep()
        scrollToHeadline(idRef)
      }}
      idRef={idRef}
      wizardSteps={wizardSteps}
      goToNamedStep={goToNamedStep}
    >
      <Box sx={{ alignSelf: 'stretch' }}>
        <Text size="body" color="textLight" sx={{ mt: [-3] }}>
          {languages['en'].listingGuideline.subTitle}
        </Text>
      </Box>
      <Flex flexDirection="column" alignItems="center" sx={wrapperStyles}>
        <Box sx={{ position: 'relative', mb: [null, 20, null, 15] }} alignSelf="stretch">
          <Box sx={lineStyles} />
          <Box sx={cardWrapperStyles}>
            {languages['en'].listingGuideline.badges.map(({ icon, title, subTitle }, index) => (
              <Box key={index} sx={cardStyles}>
                <Box sx={{ position: 'relative' }}>
                  <Badge
                    variant="circleOutlined"
                    size={[110, 110, 130, 150, 200]}
                    icon={icon as IconType}
                    contentColor="textBlack"
                    mb={[1]}
                  />
                  <Badge
                    variant="circle"
                    size={[40, 50]}
                    color="primary"
                    contentColor="textWhite"
                    label={`${index + 1}`}
                    sx={tagStyle}
                  />
                </Box>
                <Box sx={cardTextStyles}>
                  <Text color="textBlack" textAlign="center" fontWeight="extraBold" mb={1} sx={cardTitleStyles}>
                    {title}
                  </Text>
                  <Text size="small" color="textLight" textAlign="center" mb={4}>
                    {subTitle}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {/* <Text size="small" color="textLight" textAlign="center" sx={{ maxWidth: 650 }}>
          {languages['en'].listingGuideline.overview}
        </Text> */}
      </Flex>
    </Layout>
  )
}

export default ListingGuideline
