import React from 'react'
import { Box, Flex, Image } from 'rebass'

import FlexGrid from '../../FlexGrid'
import Text from '../../Text'
import Icon from '../../Icon'
import Button from '../../Button'
import Error from '../../Form/Error'
import languages from '../../../i18n'
import FileCard from './FileCard'
import { thumbnailAreaStyles } from './FilePreview.styled'
import filesize from 'filesize'
import { FileExtended } from '../types'

export enum PreviewMode {
  Preview = 'preview',
  List = 'list'
}

export interface FilePreviewProps {
  files?: FileExtended[]
  maxFiles?: number
  maxSize?: number
  previewMode?: PreviewMode
  previewFiles?: boolean
  onClose?: (index: number) => void
  error?: string
}
const FilePreview = ({
  previewFiles = true,
  files = [],
  maxFiles = 0,
  maxSize = 0,
  previewMode = PreviewMode.Preview,
  onClose = null,
  description = '',
  error = ''
}) => {
  const isFull = maxFiles && maxFiles === files.length
  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {!isFull && maxSize && <Text size="tiny" mt={1}>{`(${languages['en'].maxSize}: ${filesize(maxSize)})`}</Text>}
        {error && <Error>{error}</Error>}
      </Box>
      {previewFiles && (
        <Box sx={thumbnailAreaStyles}>
          <FlexGrid>
            {files.map((file, index) => {
              return (
                <React.Fragment key={index}>
                  {previewMode === PreviewMode.Preview ? (
                    <FileCard file={file} onClose={() => onClose(index)} />
                  ) : (
                    <Button
                      variant="simpleOutlineRound"
                      icon={<Icon name="redClose" />}
                      iconPosition="right"
                      label={file.name}
                      onClick={() => onClose(index)}
                      sx={{ py: 1 }}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </FlexGrid>
        </Box>
      )}
    </>
  )
}

export default FilePreview
