import React from 'react'
import { Box, SxStyleProp, Flex } from 'rebass'
import Skeleton from 'react-loading-skeleton'
import IconWithContent from '../IconWithContent/IconWithContent'

export interface BenefitItemsProps {
  /** Headline for the benefit */
  headline: string
  /** Description of the benefit */
  description?: string
  /** Icon to be displayed */
  icon: string
}

export interface BookingBenefitsProps {
  /** Items to be displayed as benefits */
  items: BenefitItemsProps[]
  /** Sx Props */
  sx?: SxStyleProp
  /** Component is loading */
  loading?: boolean
}

const BookingBenefits = ({ items, sx, loading, ...props }: BookingBenefitsProps) => {
  return !loading ? (
    <Box {...props} sx={{ ...sx }}>
      {items && items.length > 0
        ? items.map((i, index) => {
            return <IconWithContent key={index} {...i} mb={4} iconSx={{ fontSize: '42px', width: '55px' }} />
          })
        : null}
    </Box>
  ) : (
    <Box {...props} sx={{ ...sx }}>
      {Array(2)
        .fill(1)
        .map((_, index) => (
          <Flex key={index} sx={{ my: 3, alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: 60, height: 60, borderRadius: '50%', overflow: 'hidden', mr: 1 }}>
              <Skeleton style={{ width: '100%', height: '100%', display: 'block' }} />
            </Box>
            <Skeleton style={{ width: '200px' }} />
          </Flex>
        ))}
    </Box>
  )
}

BookingBenefits.defaultProps = {
  items: null,
  loading: false
}

export default BookingBenefits
