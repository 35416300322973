import { Global, css, ThemeProvider } from '@emotion/react'
import { Link } from 'gatsby'
import React from 'react'
import 'react-dates/initialize'

import { theme } from '@rmr/components'
import { ApolloProvider } from '@apollo/client'
import { client } from './src/utils/apollo-client'
import { AuthProvider } from '@rmr/controllers'

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeProvider theme={{ ...theme, linkComponent: Link }}>
          <Global
            styles={css`
              /* reset / normalize */
              *,
              *::before,
              *::after {
                margin: 0;
                padding: 0;
                box-sizing: inherit;
              }
              html {
                box-sizing: border-box;
              }
              body {
                font-family: ${theme.fonts.sans};
                font-weight: ${theme.fontWeights.normal};
                color: ${theme.colors.textDark};
                font-size: ${theme.fontSizes.body};
              }
              a {
                text-decoration: none;
              }
            `}
          />
          <Global
            styles={{
              //   React Dates
              '.CalendarDay__selected_span': {
                color: theme.colors.textWhite,
                backgroundColor: theme.colors.primaryLight,
                borderColor: theme.colors.primaryLight
              },
              '.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover ': {
                color: theme.colors.textWhite,
                backgroundColor: theme.colors.primary,
                borderColor: theme.colors.primary
              },
              '.CalendarDay__hovered_span:hover, .CalendarDay__hovered_span': {
                color: theme.colors.textWhite,
                backgroundColor: theme.colors.primaryLight,
                borderColor: theme.colors.primaryLight
              },
              '.DateRangePicker_picker__fullScreenPortal': {
                zIndex: theme.zIndex.max
              }
            }}
          />
          {element}
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}
