import { SxStyleProp } from 'rebass'

export const ratingStyles: SxStyleProp = {
  alignItems: 'center',
  '.car-rating': {
    ':focus': { outline: 'none' }
  },
  '.car-rating span': {
    // fontSize: ['24px !important', '30px !important'],
    // px: 1
  }
}
