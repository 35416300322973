import React, { useEffect } from 'react'
// import { graphql, useStaticQuery } from 'gatsby'
import StepWizard from 'react-step-wizard'
// import { GatsbyImage } from 'gatsby-plugin-image'
import { navigate, RouteComponentProps } from '@reach/router'
import { Container, Loader, stepsWrapperStyles } from '@rmr/components'
import {
  useAuth,
  useGetReservationQuery,
  useMeQuery,
  useGetCategoriesQuery,
  TypeEnum,
  ReservationFieldsFragment
} from '@rmr/controllers'
import ReservationReviewContent from './ReservationReviewContent'
import Success from '../Success'
import { languages } from '../../common/i18n'
import { Box, Flex } from 'rebass'
import { imageStyles, sectionStyles, stepsWrapper, wrapperStyles } from '../Signup'
import SEO from '../Seo'

const idRef = 'success-top'

const customTransitions = {
  enterRight: 'animated-steps step-enter-right',
  enterLeft: 'animated-steps step-enter-left',
  exitRight: 'animated-steps step-exit-right',
  exitLeft: 'animated-steps step-exit-left'
}

interface ReservationReviewProps extends RouteComponentProps {
  reservationId?: string
  step: 'quote' | 'payment'
}
const ReservationReview = ({ reservationId }: ReservationReviewProps) => {
  // const { heroImage } = useStaticQuery<GatsbyTypes.ReservationReviewHeroQuery>(graphql`
  //   query ReservationReviewHero {
  //     heroImage: file(relativePath: { eq: "bg-image6.jpg" }) {
  //       childImageSharp {
  //         gatsbyImageData(layout: FULL_WIDTH, quality: 100)
  //       }
  //     }
  //   }
  // `)
  const { isAuthed, loading: loadingAuth } = useAuth()
  const { data: userData, loading: loadingUser } = useMeQuery({ skip: loadingAuth || !isAuthed })

  const { data: reservationData, loading: loadingReservation } = useGetReservationQuery({
    variables: {
      id: reservationId
    },
    skip: loadingAuth || !isAuthed
  })
  let isOwner = true
  if (userData && userData.me.id && reservationData && reservationData.getReservation.__typename === 'Reservation') {
    isOwner = userData.me.id === reservationData.getReservation.vehicle.user.id
  }

  const { data: reviewCategoriesData, loading: loadingReservationCategories } = useGetCategoriesQuery({
    variables: {
      input: { type: isOwner ? TypeEnum.Owner : TypeEnum.Driver }
    },
    skip: loadingReservation || !reservationData
  })

  const loading = loadingAuth || loadingUser || loadingReservation || loadingReservationCategories

  useEffect(() => {
    if (!loading && !isAuthed) {
      navigate('/', { replace: true })
    } else if (!loading && isAuthed) {
      if (
        userData &&
        userData.me &&
        userData.me.__typename === 'User' &&
        reservationData &&
        reservationData.getReservation &&
        reservationData.getReservation.__typename === 'Reservation'
      ) {
        const user = userData.me
        const reservation = reservationData.getReservation
        if (user.id !== reservation.user.id && user.id !== reservation.vehicle.user.id) {
          navigate('/', { replace: true })
        }
      }
    }
  }, [loading, userData, reservationData])

  if (
    loading ||
    !userData ||
    !userData.me ||
    userData.me.__typename !== 'User' ||
    !reservationData ||
    !reservationData.getReservation ||
    reservationData.getReservation.__typename !== 'Reservation'
  ) {
    return <Loader position="static" />
  } else {
    const reservation = reservationData.getReservation as ReservationFieldsFragment
    return (
      <Box sx={{ position: 'relative', overflow: 'auto' }}>
        <SEO
          title="Reservation Review | RentMyRide"
          description="We founded RentMyRide to allow mobility for everyone and change the way that people use cars. "
          path="/app/booking/review/"
        />
        <Box id={idRef} sx={{ position: 'relative', bottom: [100] }} />
        <Box as="section" sx={sectionStyles}>
          {/* <Box sx={imageStyles}>
            <GatsbyImage
              image={heroImage.childImageSharp.gatsbyImageData}
              alt="hero image"
              objectFit="cover"
              style={{ height: '100%' }}
            />
          </Box> */}
          <Container>
            <StepWizard transitions={customTransitions}>
              <ReservationReviewContent
                idRef={idRef}
                user={userData.me}
                reservation={reservation}
                reviewCategories={reviewCategoriesData.getCategories}
              />
              <Flex sx={wrapperStyles}>
                <Box sx={{ ...stepsWrapperStyles, ...stepsWrapper }}>
                  <Success
                    headline={languages.en.reservationReview.successHeadline}
                    text={languages.en.reservationReview.successText}
                    buttonText={languages.en.reservationReview.successButtonText}
                    onClick={() => navigate(`${process.env.GATSBY_NX_DASHBOARD_LINK}/reservations/${reservation.id}`)}
                  />
                </Box>
              </Flex>
            </StepWizard>
          </Container>
        </Box>
      </Box>
    )
  }
}

export default ReservationReview
