import { Text, Input, Select, Error, FormMessage, MessageColor, DatePicker } from '@rmr/components'
import {
  AddressInput,
  AddressTypes,
  DocumentType,
  extractAddressFieldsFromGooglePlace,
  FileType,
  useProfileUpdate,
  userDocTypeTitles,
  validationMessages,
  ZA,
  addressToString
} from '@rmr/controllers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { Box, SxStyleProp } from 'rebass'
import { getFile, idRef, uploadWrapperStyles } from '../helpers'
import Documents from '../Documents'
import Layout from '../Layout'
import * as yup from 'yup'
import { InputValidator, commaSeparatedStringsToArray } from '@rmr/helpers'
import { StepProps } from '../ApprovalToList'
import { languages } from '../../../common/i18n'
import { processTrimUpperCase } from '@rmr/helpers'
import moment from 'moment'
import { profile } from 'console'

const autocompleteCountries = commaSeparatedStringsToArray(process.env.GATSBY_AUTOCOMPLETE_COUNTRIES)

export const formStyles: SxStyleProp = {
  width: ['100%', '100%', '60%'],
  display: 'grid',
  gridTemplateColumns: ['repeat(1, 1fr)', null, 'repeat(1, 1fr)'],
  alignItems: 'start',
  rowGap: [1],
  columnGap: [2]
}

const AddressSchema = yup.object().shape({
  latitude: yup.number(),
  longitude: yup.number(),
  addressLine1: yup.string(),
  addressLine2: yup
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value)),
  suburb: yup.string(),
  city: yup.string(),
  country: yup.string(),
  province: yup.string(),
  addressType: yup.string(),
  postalCode: yup.string()
})

const schema = yup.object().shape({
  isInternational: yup.boolean(),
  idnumber: yup.string().when('isInternational', {
    is: true,
    then: yup.string(),
    otherwise: yup
      .string()
      .nullable()
      .transform((value) => (value === null ? '' : value))
      .required('Required Field')
      .test('idnumber', 'Please enter a valid ID number', function (value) {
        if (!this.parent.isInternational) {
          const inputValidator = new InputValidator(value)
          return inputValidator.validateIdNumber()
        }
        return true
      })
  }),
  passportNumber: yup.string().when('isInternational', {
    is: true,
    then: yup
      .string()
      .nullable()
      .transform((value) => (value === null ? '' : value))
      .required('Required Field'),
    otherwise: yup
      .string()
      .nullable()
      .transform((value) => (value === null ? '' : value))
  }),
  dateOfBirth: yup.string().when('isInternational', {
    is: true,
    then: yup.string().nullable().required('Required Field. Please enter a valid date'),
    otherwise: yup.string().nullable()
  }),
  hasIdDocument: yup.bool().when('isInternational', {
    is: true,
    then: yup.bool().oneOf([true], 'Passport copy is required.'),
    otherwise: yup.bool().oneOf([true], 'ID Book or Passport copy is required.')
  }),
  hasProofAddress: yup.bool().when('isInternational', {
    is: true,
    then: yup.bool().oneOf([true], 'Proof of Residence is required.'),
    otherwise: yup.bool().oneOf([true], 'Proof of Address is required.')
  }),
  addresses: yup.array().test({
    name: 'valid-address',
    message: 'Please enter a valid address',
    test: (val) => {
      if (!val || val.length === 0) return false
      for (let i = 0; i < val.length; i++) {
        if (i === 0 && !AddressSchema.isValidSync(val[i])) {
          return false
        }
      }
      return true
    }
  })
})

const IDDocument = ({ currentStep, previousStep, nextStep, step, steps, user }: StepProps) => {
  const [date, setDate] = useState(user.profile.dateOfBirth ? moment(user.profile.dateOfBirth) : undefined)
  const [doctype, setDocType] = useState(DocumentType.IdBook)
  const isOldAddress = useMemo(() => {
    const address = user.profile.addresses[0]
    if (address && address.latitude && address.longitude) {
      return false
    }
    return true
  }, [user.profile.addresses])
  const {
    watch,
    setValue,
    register,
    setError,
    handleSubmit,
    onSubmit,
    errors,
    clearErrors,
    setFiles,
    files,
    submitting: loadingUpdate,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  } = useProfileUpdate({
    onSuccess: (data) => {
      nextStep()
    },
    defaultValues: {
      ...user,
      profile: {
        ...user.profile,
        hasIdDocument: false,
        hasProofAddress: false,
        addresses: isOldAddress ? [] : user.profile.addresses
      }
    },
    schema
  })
  const numberOfErrors = Object.keys(errors).length

  const onPlaceSelected = useCallback(
    (place) => {
      if (!place.place_id) {
        setError('addresses', {
          type: 'required',
          message: languages['en'].listYourCar.rentalConditions.placeError
        })
        return
      }
      clearErrors('addresses')
      const { addressLine1, city, country, lat, lng, postalCode, state, streetNumber, suburb } =
        extractAddressFieldsFromGooglePlace(place)
      const address: AddressInput = {
        addressLine1: addressLine1 + ' ' + streetNumber,
        suburb,
        city,
        postalCode,
        country,
        province: state,
        latitude: lat,
        longitude: lng,
        addressType: AddressTypes.Home
      }
      setValue('addresses', [address])
    },
    [clearErrors, setValue, setError]
  )
  const { ref: googlePlacesRef } = usePlacesWidget({
    // Loaded on gatsby-ssr
    // apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    inputAutocompleteValue: 'off',
    onPlaceSelected,
    options: {
      types: ['address'],
      componentRestrictions: { country: autocompleteCountries }
    }
  })

  const validateFiles = useCallback(
    () =>
      files.reduce(
        (acc, file) => {
          if (
            (doctype === DocumentType.IdBook && file.type === DocumentType.IdBook) ||
            (doctype === DocumentType.Passport && file.type === DocumentType.Passport)
          ) {
            acc.hasIdDocument = true
          }
          if (file.type === DocumentType.ProofOfAddress) {
            acc.hasProofAddress = true
          }
          return acc
        },
        {
          hasIdDocument: false,
          hasProofAddress: false
        }
      ),
    [doctype, files]
  )

  const isInternational = watch('isInternational')
  const idnumber = watch('idnumber')
  const passportNumber = watch('passportNumber')
  const onDateChange = useCallback(
    (year: number, month: number, day: number) => {
      if (day && month && year) {
        const date = moment().set({ year: year, month: month - 1, date: day })
        if (date.get('month') + 1 === month) {
          clearErrors('dateOfBirth')
          setValue('dateOfBirth', date)
        } else {
          setError('dateOfBirth', { message: languages.en.documents.invalidDateError })
          setValue('dateOfBirth', null)
        }
      } else if (date) {
        setDate(undefined)
        setValue('dateOfBirth', null)
      }
    },
    [clearErrors, date, setError, setValue]
  )

  useEffect(() => {
    if (!user?.profile?.isSAcitizen) {
      setDocType(DocumentType.Passport)
      register('isInternational')
      setValue('isInternational', true)
      register('passportNumber')
    } else {
      register('idnumber')
    }
  }, [user?.profile.country, setDocType, setValue, register, user?.profile?.isSAcitizen])

  useEffect(() => {
    register('addresses')
    register('dateOfBirth')
    register('hasIdDocument')
    register('hasProofAddress')
    if (user.profile.dateOfBirth) {
      setDate(moment(user.profile.dateOfBirth))
    }
    const { hasIdDocument, hasProofAddress } = validateFiles()
    setValue('hasIdDocument', hasIdDocument)
    setValue('hasProofAddress', hasProofAddress)
  }, [register, setValue, user.profile.dateOfBirth, validateFiles])

  useEffect(() => {
    if (numberOfErrors > 0) {
      submitResult.message !== validationMessages.en.warnErrors && onSubmitError(null, validationMessages.en.warnErrors)
    } else if (submitResult.message === validationMessages.en.warnErrors) {
      onSubmitSuccess()
    }
  }, [numberOfErrors, errors, onSubmitError, onSubmitSuccess, submitResult.message])

  return (
    <Layout
      badgeProps={{ variant: 'circle', size: [150], icon: step.icon }}
      {...step}
      currentStep={currentStep}
      previousStep={previousStep}
      buttonNextClick={onSubmit}
      idRef={idRef}
      steps={steps}
    >
      <Box as="form" onSubmit={handleSubmit(onSubmit)} sx={formStyles}>
        {isInternational ? (
          <Input
            name="passportNumber"
            label={languages.en.identification.idNumberLabel(doctype === DocumentType.Passport)}
            rebassInputProps={{
              value: passportNumber,
              onChange: (e) => {
                setValue('passportNumber', processTrimUpperCase(e.target.value))
                clearErrors('passportNumber')
              }
            }}
            error={errors.passportNumber && errors.passportNumber.message}
          />
        ) : (
          <Input
            name="idnumber"
            label={languages.en.identification.idNumberLabel(false)}
            rebassInputProps={{
              value: idnumber,
              onChange: (e) => {
                setValue('idnumber', processTrimUpperCase(e.target.value))
                clearErrors('idnumber')
              }
            }}
            error={errors.idnumber && errors.idnumber.message}
          />
        )}

        {isInternational && (
          <Box mb={2}>
            <DatePicker
              dateInit={date}
              dateLabel={languages.en.identification.dobLabel}
              yearPlaceholder={languages['en'].documents.dateYearPlaceholder}
              monthPlaceholder={languages['en'].documents.dateMonthPlaceholder}
              dayPlaceholder={languages['en'].documents.dateDayPlaceholder}
              onDateChange={onDateChange}
            />
            {errors?.dateOfBirth?.message && (
              <Error sx={{ position: 'static', mt: -1 }}>{errors?.dateOfBirth?.message}</Error>
            )}
          </Box>
        )}

        {!isInternational && (
          <Select
            options={languages.en.identification.idTypeOptions}
            name="doctype"
            label={languages.en.identification.idTypeLabel}
            rebassSelectProps={{
              value: doctype,
              onChange: (e) => {
                setDocType(e.currentTarget.value as DocumentType)
              }
            }}
          />
        )}
        <Input
          ref={googlePlacesRef}
          name="addresses"
          label={languages['en'].identification.addressLabel}
          placeholder={
            user.profile?.addresses.length > 0 && !isOldAddress
              ? addressToString(user.profile?.addresses[0])
              : languages['en'].identification.addressPlaceholder
          }
          error={(errors?.addresses?.message as string) ?? ''}
        />
        <Box sx={uploadWrapperStyles}>
          <Box>
            <Documents
              key={doctype}
              idRef={idRef}
              user={user}
              title={userDocTypeTitles[doctype]}
              file={getFile(files, doctype)}
              onFileSet={(file) => {
                setFiles((files) => [...files, { type: doctype, fileId: file.id }])
                clearErrors('hasIdDocument')
              }}
              onFileRemove={(fileId) => {
                setFiles((files) => {
                  return files.filter((f) => f.fileId !== fileId)
                })
              }}
              fileType={FileType.Document}
            />
            {errors.hasIdDocument?.message && (
              <Error sx={{ position: 'static' }}>{errors.hasIdDocument?.message}</Error>
            )}
          </Box>
          <Box>
            <Documents
              key={DocumentType.ProofOfAddress}
              idRef={idRef}
              user={user}
              title={userDocTypeTitles[DocumentType.ProofOfAddress]}
              file={getFile(files, DocumentType.ProofOfAddress)}
              onFileSet={(file) => {
                clearErrors('hasProofAddress')
                setFiles((files) => [...files, { type: DocumentType.ProofOfAddress, fileId: file.id }])
              }}
              onFileRemove={(fileId) => {
                setFiles((files) => {
                  return files.filter((f) => f.fileId !== fileId)
                })
              }}
              fileType={FileType.Document}
            />
            {errors.hasProofAddress?.message && (
              <Error sx={{ position: 'static' }}>{errors.hasProofAddress.message}</Error>
            )}
          </Box>
        </Box>
        <FormMessage
          showMessage={submitResult.submitted && !!submitResult.message}
          message={submitResult.message}
          color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
        />
      </Box>
    </Layout>
  )
}

export { IDDocument }
