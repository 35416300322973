import React from 'react'

import Svg, { SvgProps } from '../Svg'

const LogoVertical = (props: SvgProps) => (
  <Svg {...props}>
    <svg viewBox="0 0 209.72286 130.62706">
      <path
        id="path8"
        d="m 119.6082,26.98 a 22.54,22.54 0 0 0 -14.63,5.38 22.62,22.62 0 0 0 -37.26,17.249999 V 78.1 h 16 V 49.609999 a 6.58,6.58 0 0 1 13.16,0 V 78.1 h 16.1 V 49.609999 a 6.58,6.58 0 0 1 13.16,0 V 78.1 h 16.1 V 49.609999 A 22.65,22.65 0 0 0 119.6082,26.98 Z"
        fill="currentcolor"
      />
      <ellipse id="ellipse10" ry="11.73" rx="12.1" cy="11.73" cx="90.378204" fill="currentcolor" />
      <ellipse id="ellipse12" ry="11.73" rx="12.1" cy="11.75" cx="119.64819" fill="currentcolor" />
      <path
        id="path14"
        d="m 18,105.39704 a 9.15,9.15 0 0 1 -0.95,4.16 6.67,6.67 0 0 1 -2.74,2.87 l 6.12,11.4 h -7.18 l -4.75,-9.84 h -2 v 9.84 H 0 V 97.227044 h 8.67 q 9.32,0 9.33,8.169996 z m -9.84,-2.59 H 6.52 v 5.74 h 1.94 a 2.85,2.85 0 0 0 2.13,-0.81 2.9,2.9 0 0 0 0.78,-2.15 c -0.02,-1.85 -1.09,-2.78 -3.21,-2.78 z"
        fill="currentcolor"
      />
      <path
        id="path16"
        d="M 41.06,115.50704 H 27.99 a 3.27,3.27 0 0 0 1.31,2.33 4.46,4.46 0 0 0 2.91,1 4.72,4.72 0 0 0 2.64,-0.7 5,5 0 0 0 1.54,-1.43 l 4.2,3.2 a 8.29,8.29 0 0 1 -2.89,2.91 9.71,9.71 0 0 1 -5.58,1.5 10,10 0 0 1 -10.63,-10.41 10.81,10.81 0 0 1 2.74,-7.51 9.11,9.11 0 0 1 7.07,-3 9.21,9.21 0 0 1 7.29,2.85 q 2.53,2.87 2.54,8 c -0.03,0.49 -0.04,0.95 -0.07,1.26 z m -7.35,-6.14 a 3.12,3.12 0 0 0 -2.3,-0.86 3,3 0 0 0 -2.28,0.9 3.42,3.42 0 0 0 -0.95,2.07 h 6.46 a 3.14,3.14 0 0 0 -0.93,-2.11 z"
        fill="currentcolor"
      />
      <path
        id="path18"
        d="m 55.35,123.82704 v -11.9 a 2.92,2.92 0 0 0 -0.72,-2.11 2.54,2.54 0 0 0 -1.9,-0.74 2.58,2.58 0 0 0 -2,0.8 2.91,2.91 0 0 0 -0.73,2 v 11.9 h -6.34 v -20 h 6.19 v 2 a 5.44,5.44 0 0 1 2,-1.79 6.07,6.07 0 0 1 3,-0.76 7.34,7.34 0 0 1 3.09,0.61 5.48,5.48 0 0 1 2,1.5 6.61,6.61 0 0 1 1.1,2.24 13,13 0 0 1 0.51,2.47 24.52,24.52 0 0 1 0.12,2.57 v 11.21 z"
        fill="currentcolor"
      />
      <path
        id="path20"
        d="m 72.07,99.657044 v 4.219996 h 3.38 v 5.13 h -3.38 v 7.33 c 0,1.44 0.83,2.17 2.47,2.17 0.43,0 0.79,0 1.07,0 v 5.43 a 10,10 0 0 1 -2.44,0.23 7.48,7.48 0 0 1 -5.56,-1.9 c -1.26,-1.27 -1.88,-3.33 -1.88,-6.19 v -7 h -1.94 v -5.2 h 1.94 v -4.219996 z"
        fill="currentcolor"
      />
      <path
        id="path22"
        d="m 97.08,121.65704 h -6 l -5.85,-15.28 v 17.45 H 79 V 97.227044 h 8.43 l 6.61,16.829996 6.65,-16.829996 h 8.44 v 26.599996 h -6.23 v -17.45 z"
        fill="currentcolor"
      />
      <path
        id="path24"
        d="m 132.3,103.87704 -9.88,26.75 h -6 l 2.69,-7.45 -7.44,-19.3 h 6.8 l 3.53,11.47 3.69,-11.47 z"
        fill="currentcolor"
      />
      <path
        id="path26"
        d="m 152.9,105.39704 a 9.15,9.15 0 0 1 -0.95,4.16 6.67,6.67 0 0 1 -2.74,2.87 l 6.12,11.4 h -7.18 l -4.75,-9.84 h -2 v 9.84 h -6.5 V 97.227044 h 8.69 q 9.3,0 9.31,8.169996 z m -9.84,-2.59 h -1.64 v 5.74 h 1.94 a 2.85,2.85 0 0 0 2.11,-0.78 2.9,2.9 0 0 0 0.78,-2.15 c 0,-1.88 -1.07,-2.81 -3.19,-2.81 z"
        fill="currentcolor"
      />
      <path
        id="path28"
        d="m 163.04,101.73704 a 3.53,3.53 0 0 1 -2.54,1 3.48,3.48 0 0 1 -2.53,-1 3.25,3.25 0 0 1 -1,-2.449996 3.32,3.32 0 0 1 1,-2.49 3.77,3.77 0 0 1 5.07,0 3.27,3.27 0 0 1 1,2.47 3.19,3.19 0 0 1 -1,2.469996 z m 0.69,2.19 v 20 h -6.35 v -20 z"
        fill="currentcolor"
      />
      <path
        id="path30"
        d="m 181.59,123.82704 v -1.56 a 6.73,6.73 0 0 1 -2,1.37 7.86,7.86 0 0 1 -3.37,0.68 9.18,9.18 0 0 1 -7,-2.93 10.57,10.57 0 0 1 -2.73,-7.52 11,11 0 0 1 2.56,-7.58 8.69,8.69 0 0 1 6.86,-2.91 7.6,7.6 0 0 1 3.38,0.69 5.84,5.84 0 0 1 1.94,1.33 v -8.999996 h 6.35 v 27.429996 z m -0.23,-9.92 a 5.2,5.2 0 0 0 -1.18,-3.63 4,4 0 0 0 -3.11,-1.31 3.88,3.88 0 0 0 -3.12,1.37 5.25,5.25 0 0 0 -1.18,3.57 5.09,5.09 0 0 0 1.18,3.53 3.94,3.94 0 0 0 3.08,1.3 3.88,3.88 0 0 0 3.13,-1.38 5.28,5.28 0 0 0 1.16,-3.45 z"
        fill="currentcolor"
      />
      <path
        id="path32"
        d="m 209.71,115.50704 h -13.12 a 3.31,3.31 0 0 0 1.31,2.33 4.46,4.46 0 0 0 2.91,1 4.72,4.72 0 0 0 2.64,-0.7 4.91,4.91 0 0 0 1.54,-1.43 l 4.18,3.23 a 8.2,8.2 0 0 1 -2.89,2.91 9.69,9.69 0 0 1 -5.58,1.5 10,10 0 0 1 -10.6,-10.41 10.8,10.8 0 0 1 2.73,-7.51 9.12,9.12 0 0 1 7.07,-3 9.21,9.21 0 0 1 7.26,2.87 c 1.7,1.91 2.54,4.58 2.54,8 q 0.04,0.75 0.01,1.21 z m -7.36,-6.14 a 3.1,3.1 0 0 0 -2.3,-0.86 3,3 0 0 0 -2.28,0.9 3.48,3.48 0 0 0 -0.95,2.07 h 6.46 a 3.14,3.14 0 0 0 -0.93,-2.11 z"
        fill="currentcolor"
      />
    </svg>
  </Svg>
)
LogoVertical.defaultProps = {
  width: '105px',
  color: 'baseWhite',
  sx: {}
}

export default LogoVertical
