import { HandoverNote, Role, VehicleColor, VehicleFuel, VehicleTransmission } from './lib/generated/generated'

export interface UsePaginationRes {
  currentPage: number
  offset: number
  limit: number
  nextPage: () => void
  prevPage: () => void
  setPage: (page: number) => void
}

export enum VehicleDocumentEnum {
  LICENSE_DISK = 'licenceDisk',
  ROADWORTHY_CERTIFICATE = 'roadsworthyCertificate',
  TRACKER_DOCUMENTATION = 'trackingDeviceCertificate'
}

export type VehicleDocuments = {
  licenceDisk: string
  roadsworthyCertificate?: string
  trackingDeviceCertificate?: string
}

export interface VehicleInformationData {
  id?: string
  name: string
  licence_no: string
  vin: string
  year: string
  make: string
  model: string
  transunionId: string
  categoryId: string
  fueltype: VehicleFuel | null
  color: VehicleColor | null
  transmission: VehicleTransmission | null
  featureIds: string[]
  odometer: number
  description: string
  licenceDisk: string
  trackingDeviceCertificate?: string
  roadsworthyCertificate?: string
  tracker: boolean
}

export interface RentalConditionsData {
  id: string
  max_kilometers_per_day: number
  minimum_days: number
  is_drive_on_unpaved_roads: boolean
  is_allow_pets: boolean
  is_collect: boolean
  is_delivery: boolean
  is_deliver_airport: boolean
  is_deliver_renter: boolean
  vehicleLocation: string
  address: string
  is_show_location: boolean
  delivery_fee_airport: number
  delivery_fee_renter: number
  delivery_radius: number
}

export enum VehicleAvailabilityType {
  allDates,
  WeekdaysOnly,
  WeekendsOnly
}

export interface RateCalculationData {
  id: string
  price_per_day: number
  availability: VehicleAvailabilityType
}

export interface RecommendedPricePerDayData {
  price: number
  min: number
  max: number
  price_label: string
}

export interface TermsConditionsData {
  id: string
  accept_terms: boolean
}

export enum FileType {
  DOCUMENT = 'document',
  IMAGE = 'image',
  Document = 'document',
  Image = 'image'
}

export enum PaymentMethod {
  CreditCard = 'card',
  Eft = 'eft',
  Qr = 'qr'
}

export enum RentalPeriod {
  Unlimited = '0',
  '3Days' = '3',
  '4Days' = '4',
  '5Days' = '5'
}

export enum YesNo {
  Yes = 'yes',
  No = 'no'
}

export enum ApprovalIntention {
  Owner = 'Owner',
  Renter = 'Renter'
}

export type FileExtended = File & {
  preview?: string
}

export type HandoverRole = Role.Admin | Role.Owner | Role.Driver
export type HandoverNoteExtended = HandoverNote & {
  from: HandoverRole
}

export enum HandoverStage {
  Checkin,
  Checkout
}

export enum VehicleViewPosition {
  Left = 'left',
  Right = 'right',
  Front = 'front',
  Back = 'back',
  Dashboard = 'dashboard',
  Odometer = 'odometer'
}

export type ZendeskFileEntity = {
  id: string
  filename: string
  token: string
  url: string
}
