import { SxStyleProp } from 'rebass'

export const radioStyles: SxStyleProp = {
  color: 'textDark'
}

export const wrapperStyles = (horizontal: boolean): SxStyleProp => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'start',
  width: ['100%', 'unset'],
  '&:not(:last-child)': {
    mb: horizontal ? [1, 0] : [1],
    mr: horizontal ? [2, 4] : 0
  }
})

export const formGroupStyles = (horizontal: boolean): SxStyleProp => ({
  display: horizontal ? 'flex' : 'block',
  alignItems: 'center',
  flexWrap: 'wrap',
  "input[type='radio']": {
    '&:focus': {
      '& + svg': {
        backgroundColor: 'transparent',
        '& + svg': {
          backgroundColor: 'transparent'
        }
      }
    },
    '&:not(:checked)': {
      color: 'primary',
      '& + svg': {
        backgroundColor: 'transparent',
        '& + svg': {
          backgroundColor: 'transparent',
          color: 'primary'
        }
      }
    }
  }
})
