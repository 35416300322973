import axios from 'axios'
import { ZendeskFileEntity } from '../types'
import { getAuthToken } from '../helpers/authenticate'

export const zendeskFileUploader = async (files: FileList): Promise<ZendeskFileEntity[]> => {
  const apiUrl = `${process.env.NX_RMR_API_URL || process.env.GATSBY_NX_RMR_API_URL}/zendesk/upload`
  try {
    const jwtToken = getAuthToken()

    const data = new FormData()
    Array.from(files).forEach((file) => data.append('files', file))
    const headers = {
      Authorization: jwtToken.token ? `Bearer ${jwtToken.token}` : '',
      'Content-Type': 'multipart/form-data'
    }
    const res = await axios.post(`${apiUrl}`, data, { headers })
    return res?.data
  } catch (error) {
    console.log({ error })
    throw error
  }
}
