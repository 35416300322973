import { SxStyleProp } from 'rebass'
import theme from '../../theme'

export const modalOverlayStyles: SxStyleProp = {
  bg: 'baseModal',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: 3,
  zIndex: theme.zIndex.modal
}

export const modalContentStyles = {
  position: 'relative',
  bg: 'baseWhite',
  px: [2, 3],
  py: [6, 6],
  borderRadius: `${theme.borderRadius.large}`,
  boxShadow: '0 0 59px 0px rgba(58, 58, 58, 0.18)'
}

export const closeIconStyles = {
  position: 'absolute',
  top: 2,
  right: 3,
  fontSize: '20px',
  color: `${theme.colors.textDark}`,
  cursor: 'pointer'
}
