import React, { FC } from 'react'
import { Box, SxStyleProp, Flex, BoxProps } from 'rebass'
import theme from '../../theme'
import Text from '../Text/Text'
import Icon from '../Icon/Icon'

type IconPosition = 'top' | 'bottom' | 'right' | 'left'
type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse'

const flexDirection: Record<IconPosition, FlexDirection> = {
  top: 'column',
  bottom: 'column-reverse',
  left: 'row',
  right: 'row-reverse'
}

export interface IconWithContentProps extends BoxProps {
  /** children */
  children?: React.ReactNode
  /** Headline for content */
  headline?: string
  /** Descrition for contnet */
  description?: string
  /** Name of icon */
  icon: string
  /** Position of Icon */
  iconPosition?: IconPosition
  /** Sx Props for Icon */
  iconSx?: SxStyleProp
  /** Sx Props for Text */
  textSx?: SxStyleProp
  /** Sx Props */
  sx?: SxStyleProp
}

const IconWithContent: FC<IconWithContentProps> = ({
  headline,
  description,
  icon,
  iconPosition,
  iconSx,
  textSx,
  sx,
  ...props
}) => {
  const isVertical = iconPosition === 'top' || iconPosition === 'bottom'
  return (
    <Box {...props} sx={{ ...sx }}>
      <Flex alignItems={['center']} flexDirection={flexDirection[iconPosition]} sx={{ gap: 2 }}>
        <Icon name={icon} color={theme.colors.primary} sx={iconSx} />
        <Flex flexDirection={'column'} flex={1}>
          {!!headline && (
            <Text
              textAlign={[isVertical ? 'center' : 'left']}
              size={'medium'}
              color={theme.colors.textDark}
              fontWeight={theme.fontWeights.semiBold}
              lineHeight={1.3}
              sx={textSx}
            >
              {headline}
            </Text>
          )}
          {!!description && (
            <Text
              textAlign={[isVertical ? 'center' : 'left']}
              mt={[1]}
              size={'medium'}
              color={theme.colors.textGrayLight}
              lineHeight={1.3}
              sx={textSx}
            >
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

IconWithContent.defaultProps = {
  headline: '',
  description: '',
  icon: '',
  iconPosition: 'left',
  iconSx: {},
  textSx: {},
  sx: {}
}

export default IconWithContent
