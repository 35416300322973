import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdateHandoverMutation } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'

interface DefaultValues {
  fuel?: string
  odometer?: number
  acceptIdentity?: boolean
  checklist?: Record<string, boolean>
}

interface UseUpdateHandoverArgs {
  onSuccess?: () => void
  onError?: (e: Error) => void
  defaultValues?: DefaultValues
  schema?: any
  handoverId: string
}

export function useUpdateHandover({ onSuccess, onError, defaultValues, schema, handoverId }: UseUpdateHandoverArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const [updateHandover, { loading }] = useUpdateHandoverMutation()

  const onSubmit = useCallback(
    async (data) => {
      try {
        const { ...input } = data

        const response = await updateHandover({ variables: { input, id: handoverId } })

        if (response.errors) {
          onSubmitError()
          return
        }

        if (response.data) {
          onSuccess()
          onSubmitSuccess('Information saved successfully')
        }
      } catch (err) {
        console.log(err)
        onError && onError(err)
        onSubmitError()
      }
    },
    [updateHandover, onSuccess, onError, submitResult, onSubmitError, onSubmitSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult
  }
}
