import React, { FC } from 'react'
import { Box, SxStyleProp } from 'rebass'
import Skeleton from 'react-loading-skeleton'
import PriceDistribution, { PriceDistributionOption } from '../PriceDistribution'

export interface PriceProps {
  price: string
  priceDistribution: PriceDistributionOption
  sx?: SxStyleProp
}

const Price: FC<PriceProps> = ({ price, priceDistribution, sx, ...props }) => (
  <Box {...props}>
    <Box as="span" sx={{ fontSize: ['h4Mobile', 'h5'], color: 'textBlue', fontWeight: 'extraBold', ...sx }}>
      {price || <Skeleton />}
    </Box>{' '}
    {price && <PriceDistribution option={priceDistribution} />}
  </Box>
)

export default Price

Price.defaultProps = {
  price: '',
  priceDistribution: 'daily',
  sx: {}
}
