import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSubmitResult } from './useSubmitResult'
import {
  CreateVehicleInput,
  CreateVehicleMutation,
  useCreateVehicleMutation,
  UpdateVehicleInput,
  UpdateVehicleMutation,
  useUpdateVehicleMutation,
  useGetVehicleYearsQuery,
  useGetVehicleMakesQuery,
  useGetVehicleModelsQuery,
  useGetVehicleVariantsQuery,
  useGetVehicleCategoriesQuery,
  useGetVehicleFeaturesQuery,
  VehicleInformationData,
  validationMessages
} from '@rmr/controllers'
import { SelectOption } from '@rmr/components'
import { VehicleDocumentTypeEnum, VehicleResultUnion } from '../lib/generated/generated'

// import { InputValidator } from '@rmr/helpers'

interface UseVehicleInfoArgs {
  onSuccessCreate: (response: CreateVehicleMutation) => void
  onSuccessUpdate: (response: UpdateVehicleMutation) => void
  defaultValues: VehicleInformationData
}

export function useVehicleInfoForm({ onSuccessCreate, onSuccessUpdate, defaultValues }: UseVehicleInfoArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const vehicleFormSchema = yup.object().shape({
    name: yup.string().nullable().required(validationMessages.en.required),
    licence_no: yup.string().nullable().required(validationMessages.en.required),
    vin: yup
      .string()
      .nullable()
      .required(validationMessages.en.required)
      .test('vin', validationMessages.en.vinLength, (val) => val && val.length === 17),
    year: yup.string().nullable().required(validationMessages.en.required),
    make: yup.string().nullable().required(validationMessages.en.required),
    model: yup.string().nullable().required(validationMessages.en.required),
    transunionId: yup.string().nullable().required(validationMessages.en.required),
    categoryId: yup.string().nullable().required(validationMessages.en.required),
    fueltype: yup.string().nullable().required(validationMessages.en.required),
    color: yup.string().nullable().required(validationMessages.en.required),
    transmission: yup.string().nullable().required(validationMessages.en.required),
    featureIds: yup.array().of(yup.string()),
    odometer: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, validationMessages.en.required)
      .integer(validationMessages.en.integer)
      .required(validationMessages.en.required),
    description: yup.string().nullable().required(validationMessages.en.required),
    tracker: yup.bool(),
    licenceDisk: yup.string().nullable().required(validationMessages.en.required),
    trackingDeviceCertificate: yup
      .string()
      .nullable()
      .when('tracker', {
        is: true,
        then: yup.string().nullable(),
        otherwise: yup.string().nullable().required(validationMessages.en.required)
      })
  })

  const form = useForm({
    resolver: yupResolver(vehicleFormSchema),
    defaultValues
  })

  const formData = form.getValues()
  const { data: yearsData, loading: loadingYears } = useGetVehicleYearsQuery()
  const { data: makesData, loading: loadingMakes } = useGetVehicleMakesQuery({
    variables: { year: formData.year },
    skip: !formData.year
  })
  const { data: modelsData, loading: loadingModels } = useGetVehicleModelsQuery({
    variables: { year: formData.year, make: formData.make },
    skip: !formData.year || !formData.make
  })
  const { data: variantsData, loading: loadingVariants } = useGetVehicleVariantsQuery({
    variables: { year: formData.year, make: formData.make, model: formData.model },
    skip: !formData.year || !formData.make || !formData.model
  })
  const { data: categoriesData, loading: loadingCategories } = useGetVehicleCategoriesQuery()
  const { data: featuresData, loading: loadingFeatures } = useGetVehicleFeaturesQuery()

  const yearOptions: SelectOption[] | [] = useMemo(() => {
    if (yearsData && yearsData.vehicleYears) {
      return yearsData.vehicleYears.map(({ year }) => ({ value: year, label: year }))
    } else {
      return defaultValues.year ? [{ value: defaultValues.year, label: defaultValues.year }] : []
    }
  }, [yearsData])

  const makeOptions: SelectOption[] | [] = useMemo(() => {
    if (makesData && makesData.vehicleMakes) {
      return makesData.vehicleMakes.map(({ make }) => ({ value: make, label: make }))
    } else {
      return defaultValues?.make ? [{ value: defaultValues.make, label: defaultValues.make }] : []
    }
  }, [makesData])

  const modelOptions: SelectOption[] | [] = useMemo(() => {
    if (modelsData && modelsData.vehicleModels) {
      return modelsData.vehicleModels.map(({ model }) => ({ value: model, label: model }))
    } else {
      return defaultValues?.model ? [{ value: defaultValues.model, label: defaultValues.model }] : []
    }
  }, [modelsData])

  const variantOptions: SelectOption[] | [] = useMemo(() => {
    if (variantsData && variantsData.vehicleVariants) {
      return variantsData.vehicleVariants.map(({ id, variant }) => ({ value: id, label: variant }))
    } else {
      return defaultValues?.transunionId ? [{ value: defaultValues.transunionId, label: '' }] : []
    }
  }, [variantsData])

  const categoryOptions: SelectOption[] | [] = useMemo(() => {
    if (categoriesData && categoriesData.vehicleCategories) {
      return categoriesData.vehicleCategories.map(({ id, name }) => ({ value: id, label: name }))
    } else {
      return defaultValues?.categoryId ? [{ value: defaultValues.categoryId, label: '' }] : []
    }
  }, [categoriesData])

  const featureOptions: SelectOption[] | [] = useMemo(() => {
    if (featuresData && featuresData.vehicleFeatures) {
      return featuresData.vehicleFeatures.map(({ id, name, icon }) => ({ value: id, label: name, icon }))
    } else {
      return []
    }
  }, [featuresData])

  const [createVehicleMutation, { loading: loadingCreate }] = useCreateVehicleMutation()
  const [updateVehicleMutation, { loading: loadingUpdate }] = useUpdateVehicleMutation()

  const checkError = useCallback(
    (vehicleResult: VehicleResultUnion) => {
      if (vehicleResult.__typename === 'Vehicle') {
        onSubmitSuccess()
      } else {
        onSubmitError('error', vehicleResult.message)
      }
    },
    [onSubmitSuccess, onSubmitError]
  )

  const onSubmitCreate = useCallback(
    async (data: VehicleInformationData) => {
      const {
        name,
        licence_no,
        vin,
        categoryId,
        transunionId,
        color,
        fueltype,
        odometer,
        transmission,
        featureIds,
        description,
        licenceDisk,
        roadsworthyCertificate,
        trackingDeviceCertificate,
        tracker
      } = data

      const documents = [{ type: VehicleDocumentTypeEnum.LicenseDisk, fileId: licenceDisk }]
      if (roadsworthyCertificate) {
        documents.push({ type: VehicleDocumentTypeEnum.RoadworthyCertificate, fileId: roadsworthyCertificate })
      }
      if (trackingDeviceCertificate) {
        documents.push({ type: VehicleDocumentTypeEnum.TrackerDocumentation, fileId: trackingDeviceCertificate })
      }
      const vehicleInputData: CreateVehicleInput = {
        name,
        licence_no,
        vin,
        transunionId,
        categoryId,
        fueltype,
        color,
        transmission,
        odometer,
        featureIds,
        description,
        documents,
        tracker
      }

      try {
        const response = await createVehicleMutation({ variables: { input: vehicleInputData } })
        if (response.data) {
          onSuccessCreate(response.data)
          const { createVehicle } = response.data

          checkError(createVehicle as VehicleResultUnion)
        } else {
          onSuccessCreate(undefined)
        }
      } catch (err) {
        console.log('onSubmit Create Error: ', { err })
        if (err.message) {
          onSubmitError('error', err.message)
        } else {
          onSubmitError()
        }
      }
    },
    [createVehicleMutation, onSuccessCreate, onSubmitError, onSubmitSuccess]
  )
  const onSubmitUpdate = useCallback(
    async (data: VehicleInformationData) => {
      const {
        id,
        name,
        licence_no,
        vin,
        categoryId,
        transunionId,
        color,
        fueltype,
        odometer,
        transmission,
        featureIds,
        description,
        licenceDisk,
        roadsworthyCertificate,
        trackingDeviceCertificate,
        tracker
      } = data

      const documents = []
      if (licenceDisk) {
        documents.push({ type: VehicleDocumentTypeEnum.LicenseDisk, fileId: licenceDisk })
      }
      if (roadsworthyCertificate) {
        documents.push({ type: VehicleDocumentTypeEnum.RoadworthyCertificate, fileId: roadsworthyCertificate })
      }
      if (trackingDeviceCertificate) {
        documents.push({ type: VehicleDocumentTypeEnum.TrackerDocumentation, fileId: trackingDeviceCertificate })
      }
      const vehicleInputData: UpdateVehicleInput = {
        id,
        name,
        licence_no,
        vin,
        transunionId,
        categoryId,
        fueltype,
        color,
        transmission,
        odometer,
        featureIds,
        description,
        documents,
        tracker
      }
      try {
        const response = await updateVehicleMutation({ variables: { input: vehicleInputData } })
        if (response.data) {
          onSuccessUpdate(response.data)
          const { updateVehicle } = response.data
          checkError(updateVehicle as VehicleResultUnion)
        } else {
          onSuccessUpdate(undefined)
        }
      } catch (err) {
        console.log('onSubmit Update Error: ', { err })
        if (err.message) {
          onSubmitError('error', err.message)
        } else {
          onSubmitError()
        }
      }
    },
    [updateVehicleMutation, onSuccessUpdate]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(formData.id ? onSubmitUpdate : onSubmitCreate),
    submitting:
      loadingCreate ||
      loadingUpdate ||
      loadingYears ||
      loadingMakes ||
      loadingModels ||
      loadingVariants ||
      loadingCategories ||
      loadingFeatures,
    yearOptions,
    makeOptions,
    modelOptions,
    variantOptions,
    categoryOptions,
    featureOptions,
    submitResult,
    onSubmitError,
    onSubmitSuccess
  }
}
