import React, { FC } from 'react'
import { Box, BoxProps, Flex, SxStyleProp } from 'rebass'
import useMedia from 'use-media'

import theme from '../../theme'
import Text from '../Text'
import Icon from '../Icon'
import { activeTrackStyles, labelWrapperStyles, labelStyles, stepStyles, trackStyles } from './StepsWizard.styled'

export interface WizardStep {
  name: string
  isClickable?: boolean
  actionOnClick?: () => void
}

export interface StepsWizardProps extends BoxProps {
  /** Steps of Wizard */
  steps: WizardStep[]
  /** Current Step */
  currentStep?: number
  /** Is current Step completed? */
  isCompleted?: boolean
  /** With Track Sides */
  withSides?: boolean
  /** Sx styles props */
  sx?: SxStyleProp
}

const StepsWizard: FC<StepsWizardProps> = ({ steps, currentStep, isCompleted, withSides, sx, ...props }) => {
  const isMobile = useMedia({ maxWidth: theme.breakpoints[0] }) // 576px
  if (isMobile) withSides = false
  if (currentStep > steps.length) currentStep = steps.length
  const totalSteps = withSides ? steps.length + 1 : steps.length - 1
  const actualIndex = withSides ? (steps.length === currentStep ? currentStep + 1 : currentStep) : currentStep - 1
  return (
    <Box sx={{ py: [4], ...sx }} {...props}>
      <Box sx={{ position: 'relative', ...sx }} {...props}>
        <Box sx={trackStyles} />
        <Box sx={activeTrackStyles(actualIndex / totalSteps)} />
        {steps.map((step, index) => {
          const { name, isClickable, actionOnClick } = step
          const actualIndex = withSides ? index + 1 : index
          const active = index === currentStep - 1
          const completed = index < currentStep - 1 || (active && isCompleted)
          return (
            <React.Fragment key={index}>
              <Box
                sx={stepStyles(actualIndex / totalSteps, completed || active, isClickable)}
                onClick={() => isClickable && actionOnClick()}
              >
                {completed ? <Icon name="check" /> : index + 1}
              </Box>
              <Flex sx={labelWrapperStyles(actualIndex / totalSteps)}>
                <Text as="p" size="small" fontWeight="bold" textAlign="center" sx={labelStyles(completed || active)}>
                  {name}
                </Text>
              </Flex>
            </React.Fragment>
          )
        })}
      </Box>
    </Box>
  )
}

export default StepsWizard

StepsWizard.defaultProps = {
  steps: [],
  currentStep: 0,
  isCompleted: false,
  withSides: false,
  sx: {}
}
