import React, { FC } from 'react'
import { SxStyleProp, Box, FlexProps } from 'rebass'
import Heading from '../Heading'
import Text from '../Text'
import { StyledTestimonial, StyledImage, StyledImageBorder, StyledContainer, StyledQuote } from './Testimonial.styled'
import theme from '../../theme'

export interface TestimonialProps extends FlexProps {
  /** Who said this */
  cite?: string
  /** Content of the testimonial */
  quote?: string
  /** Image */
  image?: React.ReactNode
  /** Sx Props */
  sx?: SxStyleProp
}

const Testimonial: FC<TestimonialProps> = ({ cite, quote, image, sx, ...props }) => {
  return (
    <StyledTestimonial as={'blockquote'} {...props} py={[4, 6]} sx={{ ...sx }}>
      {image && (
        <StyledImageBorder>
          <StyledImage width={['8rem']} height={['8rem']}>
            {image}
          </StyledImage>
        </StyledImageBorder>
      )}

      <StyledContainer>
        {cite && (
          <Heading
            mt={[1, 2]}
            size="h5"
            fontWeight={theme.fontWeights.bold}
            textAlign="center"
            color={theme.colors.primary}
          >
            {cite}
          </Heading>
        )}
        <StyledQuote px={[4, 8]}>
          {quote && (
            <Box mt={[1, 2]}>
              <Text
                size="large"
                textAlign="center"
                fontWeight={theme.fontWeights.medium}
                color={theme.colors.textLight}
              >
                {quote}
              </Text>
            </Box>
          )}
        </StyledQuote>
      </StyledContainer>
    </StyledTestimonial>
  )
}

Testimonial.defaultProps = {
  cite: '',
  quote: '',
  image: null,
  sx: {}
}

export default Testimonial
