import React, { FC } from 'react'

import Text from '../Text'

const options = {
  daily: 'p/d',
  weekly: 'p/w',
  monthly: 'p/m',
  annually: 'p/y'
}

export type PriceDistributionOption = keyof typeof options

export interface PriceDistributionProps {
  option: PriceDistributionOption
}

const PriceDistribution: FC<PriceDistributionProps> = ({ option }) => (
  <Text as="span" size="small" color="textLight">
    {options[option]}
  </Text>
)

export default PriceDistribution

PriceDistribution.defaultProps = {
  option: 'daily'
}
