import { useCallback, useState } from 'react'
import { UsePaginationRes } from '../types'

export function usePagination({ limit = 10, initPage = 1 } = {}): UsePaginationRes {
  const [currentPage, setPage] = useState(initPage)

  const nextPage = useCallback(() => setPage(currentPage + 1), [currentPage, setPage])
  const prevPage = useCallback(() => setPage(currentPage - 1), [currentPage, setPage])

  return { currentPage, offset: (currentPage - 1) * limit, limit, nextPage, prevPage, setPage }
}
