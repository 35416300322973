import React, { FC } from 'react'
import { Box, SxStyleProp, Flex, BoxProps } from 'rebass'
import Skeleton from 'react-loading-skeleton'

import theme from '../../theme'
import Text from '../Text/Text'
import Heading from '../Heading'
import { StyledBookingEngine } from './BookingEngine.styled'
import { Input } from '../Form'
import Button from '../Button'
import Popover from '../Popover'

export interface BookingEngineProps extends BoxProps {
  /* Vehicle Id */
  vehicleId: string
  /** Rental period in number of days*/
  duration: number
  /** Total price for rental period */
  priceTotal: string
  /** Price per day */
  pricePerDay: string
  /** Price of the Deposit */
  priceDeposit: number
  /** Price for the Insurance */
  priceInsurance: number
  /** Maximum rental period */
  minRentalPeriod: number | string
  /** Details to display before booking */
  smallPrint?: string
  /** Sx Props */
  sx?: SxStyleProp
  /** Children nodes */
  children?: React.ReactNode
  /** Component is loading */
  loading?: boolean
}

const formatPrice = (currency: string, price: number) => {
  return `${currency}${(price / 100).toFixed(2)}`
}

const BookingEngine: FC<BookingEngineProps> = ({
  duration,
  priceTotal,
  pricePerDay,
  priceDeposit,
  priceInsurance,
  minRentalPeriod,
  smallPrint,
  sx,
  loading,
  ...props
}) => {
  return (
    <StyledBookingEngine px={[4]} pt={[4]} pb={[3]} {...props} sx={{ ...sx }}>
      <Flex alignItems={['flex-start']} justifyContent={['flex-end']} flexDirection={['column']}>
        <Flex>
          <Text
            textAlign={'left'}
            ml={[1]}
            mb={[1]}
            size={'large'}
            color={theme.colors.textDark}
            lineHeight={1}
            fontWeight={theme.fontWeights.extraBold}
          >
            {!loading ? pricePerDay : <Skeleton style={{ width: '50px' }} />}
          </Text>
          <Text ml={[1]} size={'large'} textAlign={'left'} mb={[1]} color={theme.colors.textGrayLight} lineHeight={1}>
            {!loading ? `per day` : <Skeleton style={{ width: '50px' }} />}
          </Text>
        </Flex>
        <Text ml={[1]} size={'tiny'} textAlign={'left'} mb={[1]} color={theme.colors.baseBlack} lineHeight={1}>
          {!loading ? (
            <>
              {`est. total ${priceTotal}`}
              <Popover>excluding insurance and deposit calculated on the next step</Popover>
            </>
          ) : (
            <Skeleton style={{ width: '100px' }} />
          )}
        </Text>
        <Box my={[3]} width={[1]} sx={{ borderBottom: `1px dotted ${theme.colors.borderLight}` }} />
        {props.children}
        <Flex flexDirection={['column']} width={[1]} mt={[2]}>
          <Text mb={[1]} size={'tiny'} color={theme.colors.textGrayLight} lineHeight={1}>
            {!loading ? `Minimum rental period of ${minRentalPeriod} days` : <Skeleton style={{ width: '100%' }} />}
          </Text>
          <Text mb={[1]} size={'tiny'} color={theme.colors.textGrayLight} lineHeight={1}>
            {!loading ? smallPrint : <Skeleton style={{ width: '100%' }} />}
          </Text>
        </Flex>
      </Flex>
    </StyledBookingEngine>
  )
}

BookingEngine.defaultProps = {
  priceTotal: null,
  pricePerDay: null,
  priceDeposit: null,
  priceInsurance: null,
  minRentalPeriod: null,
  smallPrint: null,
  loading: false
}

export default BookingEngine
