import React from 'react'
import { Box, SxStyleProp, Button, ButtonProps } from 'rebass'
import { buttonStyles, lineStyles } from './Hamburger.styled'

interface HamburgerProps extends ButtonProps {
  open: boolean
  sx?: SxStyleProp
}

const Hamburger = ({ open, sx, ...props }: HamburgerProps) => {
  return (
    <Button variant="noStyles" {...props} sx={{ ...buttonStyles, ...sx }}>
      <Box as="div" sx={{ ...lineStyles(open) }} />
      <Box as="div" sx={{ ...lineStyles(open) }} />
      <Box as="div" sx={{ ...lineStyles(open) }} />      
    </Button>
  )
}
Hamburger.defaultProps = {
  open: false,
  sx: {}
}

export default Hamburger
