import { useCallback, useState } from 'react'
import { lookupError } from '../i18n'

export type SubmitState = {
  submitted: boolean
  success: boolean
  error: string
  message: string
}
type SubmitResultTuple = [
  SubmitState,
  (message?: string) => void,
  (error?: string, message?: string) => void,
  () => void
]

const initialState = {
  submitted: false,
  success: false,
  error: '',
  message: ''
}

export function useSubmitResult(): SubmitResultTuple {
  const [submitResult, setSubmitResult] = useState<SubmitState>(initialState)

  const onSuccess = useCallback(
    (message = '') =>
      setSubmitResult({
        submitted: true,
        success: true,
        error: '',
        message
      }),
    [setSubmitResult]
  )
  const onError = useCallback(
    (error = 'Something went wrong', message = 'There was an error during processing of your request') =>
      setSubmitResult({
        submitted: true,
        success: false,
        error,
        message: message ? lookupError(message) : message
      }),
    [setSubmitResult]
  )
  const resetMessage = useCallback(() => {
    console.log('reset message!')
    setSubmitResult(initialState)
  }, [setSubmitResult])

  return [submitResult, onSuccess, onError, resetMessage]
}
