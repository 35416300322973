import React, { forwardRef, useState } from 'react'
import { CardProps as RebassCardProps, Box, Flex, SxStyleProp, Image } from 'rebass'
import ReactStars from 'react-rating-stars-component'
import Skeleton from 'react-loading-skeleton'
import languages, { Language } from '../../i18n'
import { PriceDistributionOption } from '../PriceDistribution'
import Price from '../Price'
import {
  vehicleCardStyles,
  imageWrapperStyles,
  headerImagestyles,
  priceStyles,
  imageStyles
} from './VehicleCardMobile.styled'
import Icon from '../Icon/Icon'
import Text from '../Text'
import theme from '../../theme'

export interface VehicleCardMobileProps extends RebassCardProps {
  /** Card Image */
  image: string
  /** Car Name */
  carName?: string
  /** Car Price */
  price?: string
  /** Car Price Distribution*/
  priceDistribution?: PriceDistributionOption
  /** For Reservations - start date Label*/
  fromLabel?: string
  /** For Reservations - start date*/
  from?: string
  /** For Reservations - end date Label*/
  toLabel?: string
  /** For Reservations - end date Label*/
  to?: string
  /** Number of trips */
  totalTrips?: number
  /** Rating */
  rating?: number
  /** language */
  language?: Language
  /** Show Arrow? */
  showArrow?: boolean
  /** boxShadow around component? */
  boxShadow?: boolean
  /** Horizonatl Padding around Text? */
  paddingText?: (number | string) | (number | string)[]
  /** Padding around component? */
  padding?: (number | string) | (number | string)[]
  /** Sx Styles */
  sx?: SxStyleProp
  /** Click handler */
  onClick?: () => void
}

const VehicleCardMobile = forwardRef<HTMLDivElement, VehicleCardMobileProps>(
  (
    {
      image,
      carName,
      totalTrips,
      price,
      priceDistribution,
      fromLabel,
      from,
      toLabel,
      to,
      rating,
      language,
      paddingText,
      padding,
      sx,
      ...props
    },
    ref
  ) => {
    const [portrait, setPortrait] = useState(false)

    return (
      <Flex ref={ref} {...props} sx={{ ...(vehicleCardStyles as SxStyleProp), ...sx }}>
        <Box flex={portrait ? '.6 .6' : '.8 .8'} sx={imageWrapperStyles}>
          {image && (
            <Box sx={headerImagestyles(portrait)}>
              <Image
                src={image}
                onLoad={(e) => {
                  const { width, height } = e.currentTarget.getBoundingClientRect()
                  if (height > width) setPortrait(true)
                }}
                sx={imageStyles(portrait)}
              />
            </Box>
          )}
        </Box>

        <Box flex={portrait ? '1.4 1.4' : '1.2 1.2'} ml={[1]} px={paddingText} mt={[1]} sx={{ zIndex: 1 }}>
          <Box>
            <Text as="span" size="medium" color="textBlack" fontWeight="extraBold">
              {carName || <Skeleton />}
            </Text>
          </Box>
          <Flex alignItems="center">
            {(!Number.isNaN(rating) &&
              rating >= 0 &&
              (rating > 0 ? (
                <ReactStars
                  classNames="car-rating"
                  count={5}
                  edit={false}
                  activeColor={theme.colors.primaryLight}
                  color={theme.colors.bgGrayLight}
                  value={rating}
                  emptyIcon={<Icon name="star" />}
                  filledIcon={<Icon name="star" />}
                />
              ) : (
                <Box>
                  <Text size="small" color="textLight" mt={'4px'}>
                    {languages[language].noRating}
                  </Text>
                </Box>
              ))) || (
              <Text size="small" color="textLight" mt={'4px'}>
                <Skeleton />
              </Text>
            )}
          </Flex>
          {price && (
            <Text mt={2} size="small" color="textLight">
              <Price price={price} priceDistribution={priceDistribution} sx={priceStyles} />
            </Text>
          )}
          {from && (
            <Box mt={2}>
              <Text as="span" size="small" color="textBlack" fontWeight="extraBold">
                {fromLabel}
              </Text>{' '}
              <Text as="span" size="small" color="textLight">
                {from}
              </Text>
            </Box>
          )}
          {to && (
            <Box>
              <Text as="span" size="small" color="textBlack" fontWeight="extraBold">
                {toLabel}
              </Text>{' '}
              <Text as="span" size="small" color="textLight">
                {to}
              </Text>
            </Box>
          )}
        </Box>
      </Flex>
    )
  }
)

VehicleCardMobile.defaultProps = {
  image: null,
  carName: '',
  totalTrips: null,
  rating: null,
  price: '',
  priceDistribution: 'daily',
  fromLabel: '',
  from: '',
  toLabel: '',
  to: '',
  language: 'en',
  paddingText: 0,
  padding: 0,
  sx: {},
  onClick: () => null
}

export default VehicleCardMobile
