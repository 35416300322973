import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import theme from '../../theme'
import { Container } from '../Grid'

export const StyledTestimonial = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledImageBorder = styled(Box)`
  border-radius: ${theme.borderRadius.circle};
  overflow: hidden;
  border-color: ${theme.colors.primary};
  border-style: solid;
  border-width: 8px;
  box-shadow: 0 6px 30px hsl(0deg 0% 39% / 20%);
`

export const StyledImage = styled(Box)`
  border-radius: ${theme.borderRadius.circle};
  overflow: hidden;
  border-color: ${theme.colors.baseWhite};
  border-style: solid;
  border-width: 8px;
  img {
    max-width: 100%;
    height: auto;
  }
`

export const StyledContainer = styled(Container)`
  max-width: 840px;
`
export const StyledQuote = styled(Box)`
  position: relative;
  color: ${theme.colors.textDark};
  font-weight: ${theme.fontWeights.extraBold};
  font-size: 4rem;
  &::before {
    content: '“';
    position: absolute;
    left: 0;
    top: 0;
    line-height: 0.75;
  }
  &::after {
    content: '”';
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 0;
  }
  @media (min-width: ${theme.breakpoints[2]}) {
    font-size: 7rem;
  }
`
