import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

import { Container, stepsWrapperStyles } from '@rmr/components'
import ForgotForm from './ForgotForm'
import { Box, Flex } from 'rebass'
import { GatsbyImage } from 'gatsby-plugin-image'
import { wrapperStyles, imageStyles, sectionStyles, stepsWrapper } from './Signup'
import StepWizard from 'react-step-wizard'

import { languages } from '../common/i18n'
import Success from './Success'
import SEO from './Seo'

const Forgot: FC<RouteComponentProps> = () => {
  const { heroImage } = useStaticQuery<GatsbyTypes.ForgotQuery>(graphql`
    query Forgot {
      heroImage: file(relativePath: { eq: "bg-image6.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <Box sx={{ position: 'relative', overflow: 'auto' }}>
      <SEO
        title="Forgot password | RentMyRide"
        description="It's like a car rental, just better."
        path="/app/approval-to-list"
      />
      <Box as="section" sx={sectionStyles}>
        {/* <Box sx={imageStyles}>
          <GatsbyImage
            image={heroImage.childImageSharp.gatsbyImageData}
            alt="hero image"
            objectFit="cover"
            style={{ height: '100%' }}
          />
        </Box> */}
        <Container>
          <Flex sx={wrapperStyles}>
            <Box sx={{ ...stepsWrapperStyles, ...stepsWrapper, px: [3] }}>
              <StepWizard>
                <ForgotForm />
                <Success
                  headline={languages.en.auth.forgot.successHeadline}
                  text={languages.en.auth.forgot.successText}
                />
              </StepWizard>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default Forgot
