import { SxStyleProp } from 'rebass'

export const formStyles: SxStyleProp = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: ['repeat(1, 1fr)', null, 'repeat(2, 1fr)'],
  rowGap: [1],
  columnGap: [2]
}
export const filesWrapperStyles: SxStyleProp = {
  my: [2, 3],
  width: '100%',
  display: 'grid',
  gridTemplateColumns: ['repeat(1, 1fr)', null, null, 'repeat(3, 1fr)'],
  rowGap: [3],
  columnGap: [2, 2, 4]
}

export const uploadComponentStyles: SxStyleProp = {
  minHeight: [300],
  pb: [2]
}
