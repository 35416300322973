import { SxStyleProp } from 'rebass'

export const formStyles: SxStyleProp = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: ['repeat(1, 1fr)', null, 'repeat(2, 1fr)'],
  alignItems: 'start',
  rowGap: [1],
  columnGap: [2]
}

export const fullWidthField: SxStyleProp = {
  gridColumn: [null, null, 'span 2']
}

export const trackerWrapperStyles: SxStyleProp = {
  bg: '#EEEEEE',
  px: [2],
  py: [2],
  mt: [2]
}
