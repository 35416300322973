import { SxStyleProp } from 'rebass'

import theme from '../../../theme'

export const wrapperStyles = (groupMode: boolean): SxStyleProp => ({
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  width: groupMode ? '100%' : 'unset'
})
export const iconStyles: SxStyleProp = {
  px: 1
}

export const thumbLabelStyles: SxStyleProp = {
  fontSize: ['small', 'medium'],
  top: ['-40px', '-50px']
}

export const buttonWrapperStyles: SxStyleProp = {
  mt: [4],
  justifyContent: 'center'
}

export const groupModeContainerStyles: SxStyleProp = {
  flex: '1 0 auto',
  mt: [5, 6],
  pl: [3],
  pr: [1],
  width: ['100%']
}
