import React from 'react'
import { Flex } from 'rebass'
import Button from '../Button'

interface PaginationProps {
  isFirst: boolean
  prevPage: string
  numPages: number
  currentPage: number
  isLast: boolean
  nextPage: string
  onNext: (page) => void
  onPrevious: (page) => void
}

const Pagination: React.FC<PaginationProps> = ({ isFirst, prevPage, isLast, nextPage, onNext, onPrevious }) => {
  return (
    <Flex sx={{ width: '100%', justifyContent: 'center' }}>
      {!isFirst ? (
        <Button sx={{ mr: '8px' }} onClick={() => onPrevious(prevPage)}>
          Previous
        </Button>
      ) : null}

      {!isLast ? <Button onClick={() => onNext(nextPage)}>Next</Button> : null}
    </Flex>
  )
}

export default Pagination
