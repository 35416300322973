import { Dates, RangeDates, SearchBarVariant } from '@rmr/components'
import React, { useEffect, useMemo } from 'react'
import { SxStyleProp } from 'rebass'
import moment, { Moment } from 'moment'
import { useVehicleAvailabilityQuery } from '@rmr/controllers'
import { languages } from '../common/i18n'

interface TripDatesProps {
  vehicleId: string
  startDate: Moment
  endDate: Moment
  onDatesChange: (dates: Dates) => void
  minimumDays: number
  maximumDays: number
  onError: (error: string) => void
}

const TripDates = ({
  vehicleId,
  startDate,
  endDate,
  onDatesChange,
  minimumDays,
  maximumDays,
  onError
}: TripDatesProps) => {
  const todayBis = new Date()
  const firstDayBis = new Date(todayBis.getFullYear(), todayBis.getMonth(), 1, 12)
  const lastDayBis = new Date(todayBis.getFullYear() + 1, todayBis.getMonth(), 0, 12)

  const { data: dataAvailability, loading } = useVehicleAvailabilityQuery({
    variables: { id: vehicleId, startDate: firstDayBis, endDate: lastDayBis }
  })

  const { blockedDays, bookedDays }: { blockedDays: Moment[]; bookedDays: Moment[] } = useMemo(() => {
    const initial = { blockedDays: [], bookedDays: [] }
    if (!dataAvailability || dataAvailability.vehicle.__typename !== 'Vehicle') {
      return initial
    }
    return dataAvailability.vehicle.daily.reduce((acc, day) => {
      if (!day.isAvailable) acc.blockedDays.push(moment(day.date))
      if (day.isBooked) acc.bookedDays.push(moment(day.date))
      return acc
    }, initial)
  }, [dataAvailability])

  const inPeriod = (day: Moment, startDateUnix: number, endDateUnix: number): boolean => {
    const dayUnix = day.unix()
    return dayUnix >= startDateUnix && dayUnix <= endDateUnix
  }

  useEffect(() => {
    if (startDate && endDate) {
      const startDateUnix = startDate.unix()
      const endDateUnix = endDate.unix()
      const hasBlockedDays =
        blockedDays.some((day) => inPeriod(day, startDateUnix, endDateUnix)) ||
        bookedDays.some((day) => inPeriod(day, startDateUnix, endDateUnix))
      if (hasBlockedDays) {
        onError(languages['en'].vehicleListing.blockedDaysError)
      }
    }
  }, [startDate, endDate, blockedDays, bookedDays, onError])

  return (
    <RangeDates
      variant={SearchBarVariant.DEFAULT}
      startDate={startDate}
      endDate={endDate}
      onDatesChange={onDatesChange}
      blockedDays={blockedDays}
      bookedDays={bookedDays}
      minimumDays={minimumDays}
      maximumDays={maximumDays}
      loadingAvailability={loading}
      sx={rangeDatesStyles}
    />
  )
}

export default TripDates

const rangeDatesStyles: SxStyleProp = {
  '.DateRangePickerInput': {
    width: '100%',
    justifyContent: 'center'
  },
  '.DateInput_input': {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Montserrat, sans-serif',
    color: '#494949'
  }
}
