import styled from '@emotion/styled'
import { SxStyleProp, Flex, FlexProps } from 'rebass'

import theme from '../../theme/theme'

export const vehicleCardStyles: SxStyleProp = {
  maxWidth: '500px',
  minHeight: '100px',
  bg: 'bgWhite',
  borderTopLeftRadius: `none`,
  borderBottomLeftRadius: `none`,
  flexDirection: 'row',
  overflow: 'hidden',
  height: '100%'
}

export const imageWrapperStyles: SxStyleProp = {
  position: 'relative'
}

export const headerImagestyles = (portrait: boolean): SxStyleProp => ({
  position: 'relative',
  width: '100%',
  pt: portrait ? '100%' : 0
})

const withPortrailStyles = (portrait) =>
  portrait
    ? {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        objectFit: 'cover'
      }
    : {}
export const imageStyles = (portrait: boolean): SxStyleProp => ({
  width: '100%',
  ...withPortrailStyles(portrait)
})

export const priceStyles: SxStyleProp = {
  color: 'textBlack',
  fontSize: 'largeMobile'
}
