export const processLink = (link: string, setTarget?: '_blank' | '_self') => {
  let href = '',
    to = '',
    target = ''
  if (link && (link.indexOf('http://') !== -1 || link.indexOf('https://') !== -1)) {
    href = link
    target = '_blank'
  }
  if (link && link.indexOf('mailto:')) {
    href = link
  }
  if (!href) {
    to = link
  }

  return { href, to, target: setTarget ? setTarget : target }
}
