import React from 'react'
import processString from 'react-process-string'
import { Box, Link, SxStyleProp } from 'rebass'

const isOk = (text: string, whiteList: string[]) => {
  for (const domain of whiteList) {
    if (text.toLowerCase().includes(domain.toLowerCase())) return true
  }
  return false
}

const defaultWhiteList = ['rentmyride.co.za', 'rentmyride.zendesk.com']
const defaultSx = { a: { color: 'primary' } }

interface LinkifyProps {
  /** Text to be processed */
  text: string
  /** Array of domains that will be processed */
  whitelist?: string[]
  /** sx props */
  sx: SxStyleProp
}
const Linkify = ({ text, whitelist = defaultWhiteList, sx }: LinkifyProps) => {
  const config = [
    {
      regex: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/gim,
      fn: (key, result) => {
        return isOk(result[0], whitelist) ? (
          <Box as="span" key={key} sx={{ ...defaultSx, ...sx }}>
            <Link target="_blank" rel="noreferrer" href={`${result[1]}://${result[3]}`}>
              {`${result[1]}://${result[3]}`}
            </Link>
          </Box>
        ) : (
          result[0]
        )
      }
    },
    {
      regex: /(\w[\w.-]*)@(\w+:{0,1}\w*@)?(\S+)/gim, //regex to match a username
      fn: (key, result) => {
        return isOk(result[0], whitelist) ? (
          <Box as="span" key={key} sx={{ ...defaultSx, ...sx }}>
            <Link target="_blank" rel="noreferrer" href={`mailto:${result[1]}@${result[3]}`}>
              {`${result[1]}@${result[3]}`}
            </Link>
          </Box>
        ) : (
          result[0]
        )
      }
    },
    {
      regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
      fn: (key, result) => {
        return isOk(result[0], whitelist) ? (
          <Box as="span" key={key} sx={{ ...defaultSx, ...sx }}>
            <Link target="_blank" rel="noreferrer" href={`https://${result[1]}.${result[2]}${result[3]}`}>
              {result[1]}.{result[2]}
              {result[3]}
            </Link>
            {result[4]}
          </Box>
        ) : (
          result[0]
        )
      }
    }
  ]

  const processed = processString(config)(text)
  return processed
}

Linkify.defaultProps = {
  text: '',
  whitelist: undefined,
  sx: {}
}

export default Linkify
