import React, { FC } from 'react'
import Slider from 'react-slick'
import { BoxProps, SxStyleProp } from 'rebass'

import { variants, Variant } from './Carousel.options'
import { StyledCarousel, carouselStyles } from './Carousel.styled'

export interface CarouselProps extends BoxProps {
  /** React Slick Settings */
  variant: Variant
  /** Array of Slides */
  children: React.ReactNode
  /** Sx Prop Styles */
  sx?: SxStyleProp
}

const Carousel: FC<CarouselProps> = ({ children, variant, sx, ...props }) => {
  return (
    <StyledCarousel {...props} sx={{ ...sx, ...carouselStyles }}>
      <Slider {...variants[variant]}>{children}</Slider>
    </StyledCarousel>
  )
}
Carousel.defaultProps = {
  children: null,
  variant: 'vehiclesCarousel',
  sx: {}
}

export default Carousel
