import React, { FC } from 'react'
import { Box, BoxProps, Flex, SxStyleProp } from 'rebass'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import theme from '../../theme'
import {
  faCaretDown,
  faCircleNotch,
  faCheck as faCheckSolid,
  faEye,
  faEyeSlash,
  faHeart as faHeartSolid,
  faImage,
  faInfoCircle,
  faPaperclip,
  faPen,
  faQuestionCircle,
  faSort,
  faStar,
  faStarHalfAlt,
  faTimes,
  faUser,
  faCirclePlus
} from '@fortawesome/free-solid-svg-icons'
import { faShieldCheck as faShieldCheckSolid } from '@fortawesome/pro-solid-svg-icons'
import {
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faCaretCircleRight,
  faEye as faEyeLight,
  faCarBump,
  faDog,
  faHandHolding,
  faAward,
  faBadgePercent,
  faBalanceScale,
  faBicycle,
  faCalendarDay,
  faCamera,
  faCameraHome,
  faCar,
  faCars,
  faCarSide,
  faChargingStation,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCircle0,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faCircle5,
  faCircle6,
  faCircle7,
  faCircle8,
  faCircle9,
  faChild,
  faClipboardListCheck,
  faCreditCardBlank,
  faCreditCardFront,
  faCommentTimes,
  faEdit,
  faExclamationCircle,
  faFileAlt,
  faFilter,
  faGasPump,
  faHandshake,
  faHeat,
  faMapMarkedAlt as faMapLocationDotThin,
  faMobile,
  faMoneyBillWave,
  faMoneyCheckAlt,
  faPlug,
  faRoute,
  faSearchLocation,
  faSensorOn,
  faShieldCheck,
  faSignalStream,
  faStar as faStarThin,
  faSteeringWheel,
  faSun,
  faTachometerAltFast,
  faTachometerAltAverage,
  faTag,
  faTicketAlt,
  faThumbsUp,
  faTire,
  faTruckMonster,
  faTruckPickup,
  faTruck,
  faUmbrella,
  faUsdCircle,
  faWheelchair,
  faPlane,
  faCopy,
  faBullhorn,
  faFaceTongueMoney,
  faQuestionCircle as faQuestionCircleThin,
  faQrcode
} from '@fortawesome/pro-light-svg-icons'
import {
  faApple,
  faAndroid,
  faBluetoothB,
  faFacebookF,
  faFacebook,
  faTwitter,
  faUsb,
  faGoogle,
  faInstagram,
  faLinkedinIn,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import { faCircleNotch as faCircleNotchDuotone } from '@fortawesome/pro-duotone-svg-icons'

import {
  faEnvelope,
  faEquals,
  faHeart,
  faHandHoldingUsd,
  faMapLocationDot,
  faPersonSeat,
  faShieldCheck as faShieldCheckRegular
} from '@fortawesome/pro-regular-svg-icons'
import { IconType } from './Icon.options'

export interface IconProps extends BoxProps {
  /** Name of the icon */
  name: IconType | string
  /** FontAwesomeIcon Props */
  fontAwesomeIconProps?: Omit<FontAwesomeIconProps, 'icon'>
  /** Sx Props for icon wrappers*/
  sxIconWrapper?: SxStyleProp
}
const renderIcon = (
  name: string,
  fontAwesomeIconProps: Omit<FontAwesomeIconProps, 'icon'>,
  sxIconWrapper: SxStyleProp
): React.ReactNode => {
  switch (name) {
    case 'zero':
      return <FontAwesomeIcon icon={fa0} {...fontAwesomeIconProps} />
    case 'one':
      return <FontAwesomeIcon icon={fa1} {...fontAwesomeIconProps} />
    case 'two':
      return <FontAwesomeIcon icon={fa2} {...fontAwesomeIconProps} />
    case 'three':
      return <FontAwesomeIcon icon={fa3} {...fontAwesomeIconProps} />
    case 'four':
      return <FontAwesomeIcon icon={fa4} {...fontAwesomeIconProps} />
    case 'five':
      return <FontAwesomeIcon icon={fa5} {...fontAwesomeIconProps} />
    case 'six':
      return <FontAwesomeIcon icon={fa6} {...fontAwesomeIconProps} />
    case 'seven':
      return <FontAwesomeIcon icon={fa7} {...fontAwesomeIconProps} />
    case 'eight':
      return <FontAwesomeIcon icon={fa8} {...fontAwesomeIconProps} />
    case 'nine':
      return <FontAwesomeIcon icon={fa9} {...fontAwesomeIconProps} />
    case 'zeroCircle':
      return <FontAwesomeIcon icon={faCircle0} {...fontAwesomeIconProps} />
    case 'oneCircle':
      return <FontAwesomeIcon icon={faCircle1} {...fontAwesomeIconProps} />
    case 'twoCircle':
      return <FontAwesomeIcon icon={faCircle2} {...fontAwesomeIconProps} />
    case 'threeCircle':
      return <FontAwesomeIcon icon={faCircle3} {...fontAwesomeIconProps} />
    case 'fourCircle':
      return <FontAwesomeIcon icon={faCircle4} {...fontAwesomeIconProps} />
    case 'fiveCircle':
      return <FontAwesomeIcon icon={faCircle5} {...fontAwesomeIconProps} />
    case 'sixCircle':
      return <FontAwesomeIcon icon={faCircle6} {...fontAwesomeIconProps} />
    case 'sevenCircle':
      return <FontAwesomeIcon icon={faCircle7} {...fontAwesomeIconProps} />
    case 'eightCircle':
      return <FontAwesomeIcon icon={faCircle8} {...fontAwesomeIconProps} />
    case 'nineCircle':
      return <FontAwesomeIcon icon={faCircle9} {...fontAwesomeIconProps} />
    case 'plus':
      return <FontAwesomeIcon icon={faCirclePlus} {...fontAwesomeIconProps} />
    case 'faceMoney':
      return <FontAwesomeIcon icon={faFaceTongueMoney} {...fontAwesomeIconProps} />
    case 'speaker':
      return <FontAwesomeIcon icon={faBullhorn} {...fontAwesomeIconProps} />
    case 'caretCircleRight':
      return <FontAwesomeIcon icon={faCaretCircleRight} {...fontAwesomeIconProps} />
    case 'eyeOpenLight':
      return <FontAwesomeIcon icon={faEyeLight} {...fontAwesomeIconProps} />
    case 'dog':
      return <FontAwesomeIcon icon={faDog} {...fontAwesomeIconProps} />
    case 'bumpy':
      return <FontAwesomeIcon icon={faCarBump} {...fontAwesomeIconProps} />
    case 'handHolding':
      return <FontAwesomeIcon icon={faHandHolding} {...fontAwesomeIconProps} />
    case 'linkedin':
      return <FontAwesomeIcon icon={faLinkedinIn} {...fontAwesomeIconProps} />
    case 'instagram':
      return <FontAwesomeIcon icon={faInstagram} {...fontAwesomeIconProps} />
    case 'creditCardFront':
      return <FontAwesomeIcon icon={faCreditCardFront} {...fontAwesomeIconProps} />
    case 'moneyCheck':
      return <FontAwesomeIcon icon={faMoneyCheckAlt} {...fontAwesomeIconProps} />
    case 'qrCode':
      return <FontAwesomeIcon icon={faQrcode} {...fontAwesomeIconProps} />
    case 'pen':
      return <FontAwesomeIcon icon={faPen} {...fontAwesomeIconProps} />
    case 'edit':
      return <FontAwesomeIcon icon={faEdit} {...fontAwesomeIconProps} />
    case 'caretDown':
      return <FontAwesomeIcon icon={faCaretDown} {...fontAwesomeIconProps} />
    case 'usb':
      return <FontAwesomeIcon icon={faUsb} {...fontAwesomeIconProps} />
    case 'sun':
      return <FontAwesomeIcon icon={faSun} {...fontAwesomeIconProps} />
    case 'thumbsUp':
      return <FontAwesomeIcon icon={faThumbsUp} {...fontAwesomeIconProps} />
    case 'ticket':
      return <FontAwesomeIcon icon={faTicketAlt} {...fontAwesomeIconProps} />
    case 'plane':
      return <FontAwesomeIcon icon={faPlane} {...fontAwesomeIconProps} />
    case 'route':
      return <FontAwesomeIcon icon={faRoute} {...fontAwesomeIconProps} />
    case 'balance':
      return <FontAwesomeIcon icon={faBalanceScale} {...fontAwesomeIconProps} />
    case 'bicycle':
      return <FontAwesomeIcon icon={faBicycle} {...fontAwesomeIconProps} />
    case 'wheelchair':
      return <FontAwesomeIcon icon={faWheelchair} {...fontAwesomeIconProps} />
    case 'monsterTruck':
      return <FontAwesomeIcon icon={faTruckMonster} {...fontAwesomeIconProps} />
    case 'bakkie':
      return <FontAwesomeIcon icon={faTruckPickup} {...fontAwesomeIconProps} />
    case 'truck':
      return <FontAwesomeIcon icon={faTruck} {...fontAwesomeIconProps} />
    case 'child':
      return <FontAwesomeIcon icon={faChild} {...fontAwesomeIconProps} />
    case 'handshake':
      return <FontAwesomeIcon icon={faHandshake} {...fontAwesomeIconProps} />
    case 'heat':
      return <FontAwesomeIcon icon={faHeat} {...fontAwesomeIconProps} />
    case 'blankCard':
      return <FontAwesomeIcon icon={faCreditCardBlank} {...fontAwesomeIconProps} />
    case 'apple':
      return <FontAwesomeIcon icon={faApple} {...fontAwesomeIconProps} />
    case 'android':
      return <FontAwesomeIcon icon={faAndroid} {...fontAwesomeIconProps} />
    case 'bluetooth':
      return <FontAwesomeIcon icon={faBluetoothB} {...fontAwesomeIconProps} />
    case 'sensor':
      return <FontAwesomeIcon icon={faSensorOn} {...fontAwesomeIconProps} />
    case 'camera':
      return <FontAwesomeIcon icon={faCamera} {...fontAwesomeIconProps} />
    case 'cameraHome':
      return <FontAwesomeIcon icon={faCameraHome} {...fontAwesomeIconProps} />
    case 'user':
      return <FontAwesomeIcon icon={faUser} {...fontAwesomeIconProps} />
    case 'filter':
      return <FontAwesomeIcon icon={faFilter} {...fontAwesomeIconProps} />
    case 'arrowLeft':
      return <FontAwesomeIcon icon={faChevronLeft} {...fontAwesomeIconProps} />
    case 'arrowRight':
      return <FontAwesomeIcon icon={faChevronRight} {...fontAwesomeIconProps} />
    case 'arrowDown':
      return <FontAwesomeIcon icon={faChevronDown} {...fontAwesomeIconProps} />
    case 'badgePercent':
      return <FontAwesomeIcon icon={faBadgePercent} {...fontAwesomeIconProps} />
    case 'award':
      return <FontAwesomeIcon icon={faAward} {...fontAwesomeIconProps} />
    case 'blackCheckOutline':
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          width={[30]}
          height={[30]}
          bg={theme.colors.baseWhite}
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            border: `1px solid ${theme.colors.baseBlack}`,
            svg: { width: '15px !important' },
            ...sxIconWrapper
          }}
        >
          <FontAwesomeIcon icon={faCheck} color={theme.colors.baseBlack} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'blackCheckOutlineInverted':
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          width={[30]}
          height={[30]}
          bg={theme.colors.baseBlack}
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            border: `1px solid ${theme.colors.baseBlack}`,
            svg: { width: '15px !important' },
            ...sxIconWrapper
          }}
        >
          <FontAwesomeIcon icon={faCheck} color={theme.colors.baseWhite} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'broadRoundBorder':
      return (
        <Box
          alignItems="center"
          justifyContent="center"
          width={[20]}
          height={[20]}
          bg={theme.colors.baseWhite}
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            border: `5px solid ${theme.colors.borderLight}`,
            ...sxIconWrapper
          }}
        />
      )
    case 'calendar':
      return <FontAwesomeIcon icon={faCalendarDay} className="fa-flip-horizontal" {...fontAwesomeIconProps} />
    case 'cars':
      return <FontAwesomeIcon icon={faCars} {...fontAwesomeIconProps} />
    case 'carSide':
      return <FontAwesomeIcon icon={faCarSide} {...fontAwesomeIconProps} />
    case 'chargingStation':
      return <FontAwesomeIcon icon={faChargingStation} {...fontAwesomeIconProps} />
    case 'check':
      return <FontAwesomeIcon icon={faCheck} {...fontAwesomeIconProps} />
    case 'checkSolid':
      return <FontAwesomeIcon icon={faCheckSolid} {...fontAwesomeIconProps} />
    case 'checkCircle':
      return <FontAwesomeIcon icon={faCheckCircle} {...fontAwesomeIconProps} />
    case 'checklist':
      return <FontAwesomeIcon icon={faClipboardListCheck} {...fontAwesomeIconProps} />
    case 'close':
      return <FontAwesomeIcon icon={faTimes} {...fontAwesomeIconProps} />
    case 'commentTimes':
      return <FontAwesomeIcon icon={faCommentTimes} {...fontAwesomeIconProps} />
    case 'deposit':
      return <FontAwesomeIcon icon={faHandHoldingUsd} {...fontAwesomeIconProps} />
    case 'document':
      return <FontAwesomeIcon icon={faFileAlt} {...fontAwesomeIconProps} />
    case 'whatsapp':
      return <FontAwesomeIcon icon={faWhatsapp} {...fontAwesomeIconProps} />
    case 'whatsappOutline':
      return (
        <Flex
          width={[50]}
          height={[50]}
          alignItems="center"
          justifyContent="center"
          bg="transparent"
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'borderDark',
            ...sxIconWrapper
          }}
        >
          <FontAwesomeIcon icon={faWhatsapp} fontSize="25px" color={theme.colors.bgDark} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'envelope':
      return <FontAwesomeIcon icon={faEnvelope} {...fontAwesomeIconProps} />
    case 'envelopeOutline':
      return (
        <Flex
          width={[50]}
          height={[50]}
          alignItems="center"
          justifyContent="center"
          bg="transparent"
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'borderDark',
            ...sxIconWrapper
          }}
        >
          <FontAwesomeIcon icon={faEnvelope} color={theme.colors.bgDark} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'copy':
      return <FontAwesomeIcon icon={faCopy} {...fontAwesomeIconProps} />
    case 'copyOutline':
      return (
        <Flex
          width={[50]}
          height={[50]}
          alignItems="center"
          justifyContent="center"
          bg="transparent"
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'borderDark',
            ...sxIconWrapper
          }}
        >
          <FontAwesomeIcon icon={faCopy} color={theme.colors.bgDark} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'equals':
      return <FontAwesomeIcon icon={faEquals} {...fontAwesomeIconProps} />
    case 'error':
      return <FontAwesomeIcon icon={faExclamationCircle} {...fontAwesomeIconProps} />
    case 'eyeClosed':
      return <FontAwesomeIcon icon={faEyeSlash} {...fontAwesomeIconProps} />
    case 'eyeOpen':
      return <FontAwesomeIcon icon={faEye} {...fontAwesomeIconProps} />
    case 'facebook':
      return <FontAwesomeIcon icon={faFacebookF} {...fontAwesomeIconProps} />
    case 'facebookCircle':
      return <FontAwesomeIcon icon={faFacebook} {...fontAwesomeIconProps} />
    case 'facebookOutline':
      return (
        <Flex
          width={[50]}
          height={[50]}
          alignItems="center"
          justifyContent="center"
          bg="transparent"
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'borderDark',
            ...sxIconWrapper
          }}
        >
          <FontAwesomeIcon icon={faFacebookF} color={theme.colors.bgDark} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'frontCar':
      return <FontAwesomeIcon icon={faCar} {...fontAwesomeIconProps} />
    case 'gasPump':
      return <FontAwesomeIcon icon={faGasPump} {...fontAwesomeIconProps} />
    case 'google':
      return <FontAwesomeIcon icon={faGoogle} {...fontAwesomeIconProps} />
    case 'greenCheck':
      return (
        <Flex
          width={[40]}
          height={[40]}
          alignItems="center"
          justifyContent="center"
          bg="statusSuccess"
          sx={{ borderRadius: `${theme.borderRadius.circle}`, svg: { width: '15px !important' }, ...sxIconWrapper }}
        >
          <FontAwesomeIcon icon={faCheck} color={theme.colors.baseWhite} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'heart':
      return <FontAwesomeIcon icon={faHeart} {...fontAwesomeIconProps} />
    case 'heartSolid':
      return <FontAwesomeIcon icon={faHeartSolid} {...fontAwesomeIconProps} />
    case 'info':
      return <FontAwesomeIcon icon={faInfoCircle} {...fontAwesomeIconProps} />
    case 'image':
      return <FontAwesomeIcon icon={faImage} {...fontAwesomeIconProps} />
    case 'imagePlaceholder':
      return (
        <Flex
          width={[60]}
          height={[60]}
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            boxShadow: `0 0 40px 0px ${theme.colors.bgShadow}`,
            ...sxIconWrapper
          }}
        >
          <FontAwesomeIcon icon={faImage} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'loader':
      return <FontAwesomeIcon icon={faCircleNotch} {...fontAwesomeIconProps} />
    case 'loaderDuotone':
      return <FontAwesomeIcon icon={faCircleNotchDuotone} {...fontAwesomeIconProps} />
    case 'money':
      return <FontAwesomeIcon icon={faMoneyBillWave} {...fontAwesomeIconProps} />
    case 'mapLocation':
      return <FontAwesomeIcon icon={faMapLocationDot} {...fontAwesomeIconProps} />
    case 'mobile':
      return <FontAwesomeIcon icon={faMobile} {...fontAwesomeIconProps} />
    case 'navigation':
      return <FontAwesomeIcon icon={faMapLocationDotThin} {...fontAwesomeIconProps} />
    case 'odometer':
      return <FontAwesomeIcon icon={faTachometerAltFast} {...fontAwesomeIconProps} />
    case 'paperClip':
      return <FontAwesomeIcon icon={faPaperclip} {...fontAwesomeIconProps} />
    case 'personSeat':
      return <FontAwesomeIcon icon={faPersonSeat} {...fontAwesomeIconProps} />
    case 'plug':
      return <FontAwesomeIcon icon={faPlug} {...fontAwesomeIconProps} />
    case 'price':
      return <FontAwesomeIcon icon={faUsdCircle} {...fontAwesomeIconProps} />
    case 'question':
      return <FontAwesomeIcon icon={faQuestionCircle} {...fontAwesomeIconProps} />
    case 'questionThin':
      return <FontAwesomeIcon icon={faQuestionCircleThin} {...fontAwesomeIconProps} />
    case 'redClose':
      return (
        <Flex
          width={[25]}
          height={[25]}
          alignItems="center"
          justifyContent="center"
          bg="statusError"
          sx={{ borderRadius: `${theme.borderRadius.circle}`, svg: { width: '10px !important' }, ...sxIconWrapper }}
        >
          <FontAwesomeIcon icon={faTimes} color={theme.colors.baseWhite} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'blackClose':
      return (
        <Flex
          width={[30]}
          height={[30]}
          alignItems="center"
          justifyContent="center"
          bg="baseBlack"
          sx={{ borderRadius: `${theme.borderRadius.circle}`, svg: { width: '10px !important' }, ...sxIconWrapper }}
        >
          <FontAwesomeIcon icon={faTimes} color={theme.colors.baseWhite} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'blackCircle':
      return (
        <Box
          width={[30]}
          height={[30]}
          bg="baseBlack"
          sx={{ borderRadius: `${theme.borderRadius.circle}`, svg: { width: '10px !important' }, ...sxIconWrapper }}
        />
      )

    case 'searchLocation':
      return <FontAwesomeIcon icon={faSearchLocation} {...fontAwesomeIconProps} />
    case 'select':
      return <FontAwesomeIcon icon={faSort} {...fontAwesomeIconProps} />
    case 'shieldCheck':
      return <FontAwesomeIcon icon={faShieldCheck} {...fontAwesomeIconProps} />
    case 'shieldCheckRegular':
      return <FontAwesomeIcon icon={faShieldCheckRegular} {...fontAwesomeIconProps} />
    case 'shieldCheckSolid':
      return <FontAwesomeIcon icon={faShieldCheckSolid} {...fontAwesomeIconProps} />
    case 'signal':
      return <FontAwesomeIcon icon={faSignalStream} {...fontAwesomeIconProps} />
    case 'starHalf':
      return <FontAwesomeIcon icon={faStarHalfAlt} {...fontAwesomeIconProps} />
    case 'star':
      return <FontAwesomeIcon icon={faStar} {...fontAwesomeIconProps} />
    case 'starThin':
      return <FontAwesomeIcon icon={faStarThin} {...fontAwesomeIconProps} />
    case 'steeringWheel':
      return <FontAwesomeIcon icon={faSteeringWheel} {...fontAwesomeIconProps} />
    case 'tachometerAltAverage':
      return <FontAwesomeIcon icon={faTachometerAltAverage} {...fontAwesomeIconProps} />
    case 'tag':
      return <FontAwesomeIcon icon={faTag} className="fa-flip-horizontal" {...fontAwesomeIconProps} />
    case 'tire':
      return <FontAwesomeIcon icon={faTire} {...fontAwesomeIconProps} />
    case 'twitter':
      return <FontAwesomeIcon icon={faTwitter} {...fontAwesomeIconProps} />
    case 'twitterOutline':
      return (
        <Flex
          width={[50]}
          height={[50]}
          alignItems="center"
          justifyContent="center"
          bg="transparent"
          sx={{
            borderRadius: `${theme.borderRadius.circle}`,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'borderDark',
            ...sxIconWrapper
          }}
        >
          <FontAwesomeIcon icon={faTwitter} color={theme.colors.bgDark} {...fontAwesomeIconProps} />
        </Flex>
      )
    case 'umbrella':
      return <FontAwesomeIcon icon={faUmbrella} {...fontAwesomeIconProps} />
    default:
      return <FontAwesomeIcon icon={faEye} {...fontAwesomeIconProps} />
  }
}

const Icon: FC<IconProps> = ({ name, fontAwesomeIconProps, sxIconWrapper, ...props }) => {
  return (
    <Box as="span" {...props}>
      {renderIcon(name, fontAwesomeIconProps, sxIconWrapper)}
    </Box>
  )
}

export default Icon
Icon.defaultProps = {
  name: null,
  fontAwesomeIconProps: null,
  sxIconWrapper: {}
}
