import React, { useRef, useState } from 'react'
import { Banner, Content, Button, RangeSlider, theme, Link } from '@rmr/components'
import { Box } from 'rebass'
import { graphql, navigate } from 'gatsby'
import parse from 'html-react-parser'
import GatsbyImage from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

export const getColorScheme = {
  white: {
    bg: theme.colors.bgWhite,
    headline: theme.colors.textGray,
    text: theme.colors.textGray,
    subtitle: theme.colors.textGray,
    bannerBrandLine: theme.colors.bgDark
  },
  black: {
    bg: theme.colors.bgBlack,
    headline: theme.colors.textWhite,
    text: theme.colors.textWhite,
    subtitle: theme.colors.textLight,
    bannerBrandLine: theme.colors.bgWhite
  },
  gray: {
    bg: theme.colors.bgLight,
    headline: theme.colors.bgBlack,
    text: theme.colors.textGray,
    subtitle: theme.colors.textWhite,
    bannerBrandLine: theme.colors.bgWhite
  },
  orange: {
    bg: theme.colors.primary,
    headline: theme.colors.textWhite,
    text: theme.colors.textWhite,
    subtitle: theme.colors.textWhite,
    bannerBrandLine: theme.colors.bgWhite
  }
}

const Calculator = () => {
  const [value, setValue] = useState(150000)

  return (
    <Box mb={[2, 4]}>
      <RangeSlider
        max={300000}
        min={100000}
        step={5000}
        unit={'R'}
        unitLeft={true}
        onChangeValues={(values) => {
          setValue(values[0])
        }}
        initialValues={[value]}
      />
      <Box mt={[4]} mr={[0, 2]} mb={[0, 1]}>
        <Button type="button" variant="outline" onClick={() => navigate(`/earn?value=${value}`)}>
          See what you can earn
        </Button>
      </Box>
    </Box>
  )
}

const BannerWrapper = ({
  subtitle,
  headline,
  bannerContent,
  variant,
  bannerImage,
  imagePosition,
  backgroundColor,
  actions,
  iconName,
  iconColor
}) => {
  const inputSlider = useRef()
  const imageCheck = (variant, image) => {
    if (image.length > 0) {
      return variant === 'brand' ? (
        <GatsbyImage fluid={bannerImage[0].localFile.childImageSharp.fluid} style={{ width: '100%', height: '100%' }} />
      ) : (
        <BackgroundImage
          fluid={bannerImage[0].localFile.childImageSharp.fluid}
          style={{ width: '100%', height: '100%' }}
        />
      )
    } else {
      return null
    }
  }

  return (
    <Banner
      headline={headline}
      subtitle={subtitle}
      variant={variant}
      iconName={iconName}
      iconColor={iconColor}
      imagePosition={imagePosition}
      colorScheme={backgroundColor}
      bgColor={backgroundColor ? getColorScheme[backgroundColor].bg : getColorScheme.black.bg}
      headlineColor={backgroundColor ? getColorScheme[backgroundColor].headline : getColorScheme.black.headline}
      textColor={backgroundColor ? getColorScheme[backgroundColor].text : getColorScheme.black.text}
      subtitleColor={backgroundColor ? getColorScheme[backgroundColor].subtitle : getColorScheme.black.subtitle}
      headerLineColor={
        backgroundColor ? getColorScheme[backgroundColor].bannerBrandLine : getColorScheme.black.bannerBrandLine
      }
      image={bannerImage && imageCheck(variant, bannerImage)}
    >
      {/* TODO: Add color to Content component to render text in correct color */}
      {bannerContent && <Content>{parse(bannerContent)}</Content>}

      {/* TODO: add button action and calculation as part of component (curenly coming from WP) */}
      {variant === 'calculator' ? (
        <Calculator />
      ) : (
        actions &&
        actions.map((a, index) => {
          return (
            a.url && (
              <Box mr={[0, 2]} mb={[0, 1]} key={index}>
                <Link to={a.url && a.url.url}>
                  <Button type="button" variant={a.actionType}>
                    {a.url && a.url.title}
                  </Button>
                </Link>
              </Box>
            )
          )
        })
      )}
    </Banner>
  )
}

export default BannerWrapper

export const BannerFields = graphql`
  fragment BannerFields on WpPage_Layoutsettings_Layout_Banner {
    variant
    subtitle
    headline
    bannerContent
    imagePosition
    iconName
    iconColor
    bannerImage {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1900, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    actions {
      actionType
      url {
        target
        title
        url
      }
    }
    backgroundColor
  }
`
