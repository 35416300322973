import { Button, Heading, Loader, Text, stepsWrapperStyles, Icon, Modal } from '@rmr/components'
import React, { useCallback, useState } from 'react'
import { Box } from 'rebass'
import Layout from '../Layout'
import { languages } from '../../../common/i18n'
import { useResendEmailVerificationMutation } from '@rmr/controllers'
import { StepProps } from '../ApprovalToList'

const EmailConfirm = ({ idRef, step, user }: StepProps) => {
  const [emailSent, setEmailSent] = useState(false)
  const [resendEmailVerificationMutation, { loading }] = useResendEmailVerificationMutation()

  const resendEmailVerification = useCallback(async () => {
    try {
      await resendEmailVerificationMutation({ variables: { input: { email: user.email } } })
      setEmailSent(true)
    } catch (error) {
      alert('email could not sent. Please contact us')
    }
  }, [resendEmailVerificationMutation, setEmailSent])
  return (
    <React.Fragment>
      {loading && <Loader />}

      <Layout badgeProps={{ variant: 'circle', size: [150], icon: step.icon }} {...step} idRef={idRef} steps={[]}>
        <Heading size="h6" color="textBlack" textAlign="center" mb={1}>
          {languages.en.getApprovedList.verifyEmailTitle}
        </Heading>

        <Text size="small" color="textLight" textAlign="center">
          <Box
            as="span"
            sx={{ span: { fontWeight: 'bold' } }}
            dangerouslySetInnerHTML={{ __html: languages.en.getApprovedList.verifyEmailText(user.email) }}
          />
        </Text>

        <Box mx="auto" mt={[4]}>
          <Button variant="simpleOutlineRound" onClick={() => resendEmailVerification()}>
            {languages.en.getApprovedList.resendEmail}
          </Button>
        </Box>
      </Layout>
      <Modal isModalOpen={emailSent} closeModal={() => setEmailSent(false)}>
        <Box sx={{ textAlign: 'center' }}>
          <Heading size="h5" color="statusSuccess" mb={[2]}>
            {languages.en.verificationSuccess.headline}
          </Heading>
          <Text size="body">{languages.en.signup.checkEmailMessage}</Text>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export { EmailConfirm }
