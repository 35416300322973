import React, { useState, useRef, useEffect } from 'react'
import { Box, BoxProps, SxStyleProp } from 'rebass'

import { modalOverlayStyles, modalContentStyles, closeIconStyles } from './Modal.styled'

import Icon from '../Icon'
import './modal.css'

export interface ModalProps extends BoxProps {
  /**
   * Flag indicating whether the modal is open or not.
   */
  isModalOpen: boolean
  /**
   * Callback function for closing the modal
   * (i.e. setting `isModalOpen` to false, wherever that is stored in the component tree).
   */
  closeModal?: () => void
  /**
   * In some instances we need content to overflow out of the modal
   */
  canOverflow?: boolean
  /**
   * Must Modal close when clicking outside
   */
  closeOnClickOutside?: boolean
  /**
   * The content to be rendered in the modal.
   */
  children: React.ReactNode
  /**
   * Sx Prop Styles
   */
  sx: SxStyleProp
}

const Modal = ({ isModalOpen, children, closeModal, canOverflow, closeOnClickOutside, sx }: ModalProps) => {
  const [badgeHeight, setBadgeHeight] = useState(0)

  const modalRef = useRef(null)

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && closeModal && closeOnClickOutside) {
      closeModal()
    }
  }

  const handleBadgeHeight = (e: HTMLDivElement) => {
    if (e && e.clientHeight > 0 && e.offsetTop > 0) {
      const newBadgeHeight = e.clientHeight / 2 + e.offsetTop
      if (newBadgeHeight !== badgeHeight) {
        setBadgeHeight(newBadgeHeight)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  })

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('hide-overflow')
      document.documentElement.classList.add('hide-overflow')
    } else {
      document.body.classList.remove('hide-overflow')
      document.documentElement.classList.remove('hide-overflow')
    }
    return () => {
      document.body.classList.remove('hide-overflow')
      document.documentElement.classList.remove('hide-overflow')
    }
  }, [isModalOpen])

  return isModalOpen ? (
    <Box sx={{ ...(modalOverlayStyles as SxStyleProp), ...sx }}>
      <Box ref={modalRef} sx={modalContentStyles}>
        {closeModal && <Icon onClick={() => closeModal()} name="close" sx={closeIconStyles} />}
        <Box mx="auto" sx={{ maxHeight: '80vh', overflow: canOverflow ? 'visible' : 'auto' }}>
          {children}
        </Box>
      </Box>
    </Box>
  ) : null
}

Modal.defaultProps = {
  isModalOpen: false,
  canOverflow: false,
  closeOnClickOutside: true,
  children: null,
  sx: {}
}

export default Modal
