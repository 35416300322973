import React, { forwardRef } from 'react'
import { Button as RebassButton, ButtonProps as RebassButtonProps, SxStyleProp, Box } from 'rebass'
import { buttonStyles } from './Button.styled'
import { ButtonVariant } from '../../theme/buttons'

export type IconPosition = 'left' | 'right'
export type ButtonType = 'button' | 'submit' | 'reset'
export interface ButtonProps extends RebassButtonProps {
  /** Button type */
  type?: ButtonType
  /** Button variant */
  variant?: ButtonVariant
  /** Button Text */
  label?: string
  /** Icon */
  icon?: React.ReactNode
  /** Icon Position */
  iconPosition?: IconPosition
  /** Full Width?*/
  fullWidth?: boolean
  /** onClick Handler */
  onClick?: () => void
  /** Sx Props */
  sx?: SxStyleProp
  /** Children */
  children?: React.ReactNode
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, label, icon, iconPosition, fullWidth, sx, ...props }, ref) => {
    const renderChildren = (
      label: string,
      children: React.ReactNode,
      icon: React.ReactNode,
      iconPosition: IconPosition
    ): React.ReactNode => {
      const content = label || children
      if (!icon) return <>{content}</>
      if (iconPosition === 'left') {
        return (
          <>
            {icon}
            {content && <Box sx={{ ml: 1 }}>{content}</Box>}
          </>
        )
      } else {
        return (
          <>
            {content && <Box sx={{ mr: 1 }}>{content}</Box>}
            {icon}
          </>
        )
      }
    }
    return (
      <RebassButton ref={ref} {...props} sx={{ ...buttonStyles(fullWidth), ...sx }}>
        {renderChildren(label, children, icon, iconPosition)}
      </RebassButton>
    )
  }
)

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  onClick: () => null,
  sx: {},
  label: '',
  icon: null,
  iconPosition: 'left',
  fullWidth: false,
  children: null
}

export default Button
