import { createElement, ReactNode } from 'react'
import { SxStyleProp } from 'rebass'
import BannerCenter from './BannerCenter'
import BannerSplit from './BannerSplit'
import BannerBrand from './BannerBrand'
import BannerIcon from './BannerIcon'

const bannerVariants = {
  center: BannerCenter,
  split: BannerSplit,
  brand: BannerBrand,
  calculator: BannerSplit,
  icon: BannerIcon
}

export interface BannerProps {
  /** Variant sets the layout of the Banner */
  variant: BannerVariant
  /** The Headline text */
  headline?: string
  /** The subtitle text */
  subtitle?: string
  /** Image for the Banner */
  image?: string
  /** Where should the image be positioned */
  imagePosition?: string
  /** Set the color scheme of the Banner */
  colorScheme?: string
  /** Sx Props */
  sx?: SxStyleProp
  /** Children */
  children?: ReactNode
  /** Actions */
  actions?: ReactNode
  /** Background color of Banner */
  bgColor?: string
  /** Color of the line appearing below the headline */
  headerLineColor?: string
  /** Color of the headline text */
  headlineColor?: string
  /** Color of text contnet */
  textColor?: string
  /** Color of the subtitle text */
  subtitleColor?: string
  /** Name of icon for Icon Banner badge */
  iconName?: string
  /** Name of icon for Icon Banner badge */
  iconColor?: `success` | 'warning' | 'error' | 'info' | 'white' | 'orange' | 'gray' | 'black'
}

export type BannerVariant = 'center' | 'split' | 'brand' | 'calculator' | 'icon'

const Banner = ({ variant, children, ...props }) =>
  createElement(bannerVariants[variant], { ...props, variant }, children)

export default Banner
