import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useLocation, WindowLocation, navigate } from '@reach/router'
import { Box, BoxProps, Flex, Image } from 'rebass'

import {
  useVehicleInfoForm,
  CreateVehicleMutation,
  UpdateVehicleMutation,
  VehicleDocumentEnum,
  VehicleInformationData,
  VehicleDocument,
  VehicleFuel,
  VehicleColor,
  VehicleTransmission,
  validationMessages,
  languages as enumLanguages
} from '@rmr/controllers'
import {
  Button,
  Input,
  Select,
  Textarea,
  FormMessage,
  MessageColor,
  Loader,
  MultipleFilter,
  LabelTooltip,
  Text,
  Error,
  Checkbox
} from '@rmr/components'

import { formStyles, fullWidthField, trackerWrapperStyles } from './VehicleInformationForm.styled'
import { languages } from '../../../../common/i18n'
import { MAX_CHARACTERS } from '../../../../common/constants'
import { scrollToHeadline } from '../../../StepsNavigation'
import UploadDocuments from '../UploadDocuments'
import { enumToOptionsArray, processTrimUpperCase } from '@rmr/helpers'
import trackerLogo from '../../../../images/tracker-logo.svg'

type LocationState = {
  comingFromVehicleForm: boolean
}

export type Documents = {
  licenceDisk: string
  roadsworthyCertificate: string
  trackingDeviceCertificate: string
}

const initialDocumentState: Documents = {
  licenceDisk: '',
  roadsworthyCertificate: '',
  trackingDeviceCertificate: ''
}

export type Document = keyof typeof initialDocumentState

interface VehicleInformationFormProps extends BoxProps {
  idRef: string
  vehicleid: string
  vehicleInformation: VehicleInformationData
  nextStep: () => void
  existingDocuments: VehicleDocument[]
}

const VehicleInformationForm = forwardRef<HTMLButtonElement, VehicleInformationFormProps>(
  ({ idRef, vehicleid, vehicleInformation, existingDocuments, nextStep, ...props }, ref) => {
    const location = useLocation() as WindowLocation<LocationState>

    const handleNextStep = () => {
      nextStep()
      scrollToHeadline(idRef)
    }

    const onSuccessCreate = ({ createVehicle }: CreateVehicleMutation) => {
      if (createVehicle && createVehicle.__typename === 'Vehicle') {
        handleNextStep()
        navigate(`${location.pathname}/${createVehicle.id}`.replace('//', '/'), {
          state: { comingFromVehicleForm: true }
        })
      }
    }
    const onSuccessUpdate = ({ updateVehicle }: UpdateVehicleMutation) => {
      if (updateVehicle && updateVehicle.__typename === 'Vehicle') {
        handleNextStep()
      }
    }

    const {
      clearErrors,
      register,
      setValue,
      watch,
      onSubmit,
      submitting,
      submitResult,
      onSubmitError,
      onSubmitSuccess,
      errors,
      yearOptions,
      makeOptions,
      modelOptions,
      variantOptions,
      categoryOptions,
      featureOptions
    } = useVehicleInfoForm({
      onSuccessCreate,
      onSuccessUpdate,
      defaultValues: vehicleInformation
    })
    const licence_no = watch('licence_no')
    const vin = watch('vin')
    const year = watch('year')
    const make = watch('make')
    const model = watch('model')
    const transunionId = watch('transunionId')
    const description = watch('description')

    const updateStateDocuments = useCallback(
      (document: VehicleDocumentEnum, value: string) => {
        setValue(document, value)
        clearErrors(document)
      },
      [clearErrors, setValue]
    )

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault()
      onSubmit(e)
    }

    useEffect(() => {
      register('featureIds')
      register('id')
      register('licenceDisk')
      register('roadsworthyCertificate')
      register('trackingDeviceCertificate')
    }, [register])

    useEffect(() => {
      setValue('id', vehicleid)
      setValue('featureIds', vehicleInformation.featureIds)
      setValue('licenceDisk', vehicleInformation.licenceDisk)
      setValue('roadsworthyCertificate', vehicleInformation.roadsworthyCertificate)
      setValue('trackingDeviceCertificate', vehicleInformation.trackingDeviceCertificate)
    }, [
      vehicleid,
      vehicleInformation.year,
      vehicleInformation.featureIds,
      vehicleInformation.licenceDisk,
      vehicleInformation.roadsworthyCertificate,
      vehicleInformation.trackingDeviceCertificate,
      setValue
    ])

    useEffect(() => {
      if (location && location.state && location.state && location.state.comingFromVehicleForm) {
        nextStep()
      }
    }, [location, nextStep])

    useEffect(() => {
      if (Object.keys(errors).length > 0) {
        submitResult.message !== validationMessages.en.warnErrors &&
          onSubmitError(null, validationMessages.en.warnErrors)
      } else if (submitResult.message === validationMessages.en.warnErrors) {
        onSubmitSuccess()
      }
    }, [errors, onSubmitError, onSubmitSuccess, submitResult.message])

    return (
      <React.Fragment>
        {submitting && <Loader />}
        <Box as="form" onSubmit={handleSubmit} sx={formStyles} {...props}>
          <Input
            name="name"
            label={languages['en'].listYourCar.vehicleInformation.nameLabel}
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.nameToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.namePlaceholder}
            rebassInputProps={{
              autoComplete: 'on'
            }}
            ref={register}
            error={errors.name && errors.name.message}
            sx={{ ...fullWidthField }}
          />
          <Input
            name="licence_no"
            label={languages['en'].listYourCar.vehicleInformation.licence_noLabel}
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.licence_noToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.licence_noPlaceholder}
            rebassInputProps={{
              autoComplete: 'on',
              value: licence_no,
              onChange: (e) => {
                setValue('licence_no', processTrimUpperCase(e.target.value))
                clearErrors('licence_no')
              }
            }}
            ref={register}
            error={errors.licence_no && errors.licence_no.message}
          />
          <Input
            name="vin"
            label={languages['en'].listYourCar.vehicleInformation.vinLabel}
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.vinToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.vinPlaceholder}
            rebassInputProps={{
              autoComplete: 'on',
              value: vin,
              onChange: (e) => {
                setValue('vin', processTrimUpperCase(e.target.value))
                clearErrors('vin')
              }
            }}
            ref={register}
            error={errors.vin && errors.vin.message}
          />
          <Select
            name="year"
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.yearToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.yearPlaceholder}
            ref={register}
            rebassSelectProps={{
              defaultValue: year,
              onChange: (e) => {
                clearErrors('year')
                setValue('year', e.target.value)
                setValue('make', '')
                setValue('model', '')
                setValue('transunionId', '')
                // setVehicleInformationState((vehicleInformationState) => {
                //   const newState: VehicleInformationData = JSON.parse(JSON.stringify(vehicleInformationState))
                //   newState['year'] = e.target.value
                //   newState['make'] = ''
                //   newState['model'] = ''
                //   newState['transunionId'] = ''
                //   return newState
                // })
              },
              value: year,
              autoComplete: 'on'
            }}
            options={yearOptions}
            label={languages['en'].listYourCar.vehicleInformation.yearLabel}
            error={errors.year && errors.year.message}
          />
          <Select
            name="make"
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.makeToolTipInfo}
            placeholder={
              !year
                ? languages['en'].listYourCar.vehicleInformation.makePlaceholderDisabled
                : languages['en'].listYourCar.vehicleInformation.makePlaceholder
            }
            options={makeOptions}
            label={languages['en'].listYourCar.vehicleInformation.makeLabel}
            rebassSelectProps={{
              defaultValue: vehicleInformation.make,
              onChange: (e) => {
                clearErrors('make')
                setValue('make', e.target.value)
                setValue('model', '')
                setValue('transunionId', '')
                // setVehicleInformationState((vehicleInformationState) => {
                //   const newState: VehicleInformationData = JSON.parse(JSON.stringify(vehicleInformationState))
                //   newState['make'] = e.target.value
                //   newState['model'] = ''
                //   newState['transunionId'] = ''
                //   return newState
                // })
              },
              value: make,
              autoComplete: 'on',
              disabled: !year
            }}
            ref={register}
            error={errors.make && errors.make.message}
          />
          <Select
            name="model"
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.modelToolTipInfo}
            placeholder={
              !make
                ? languages['en'].listYourCar.vehicleInformation.modelPlaceholderDisabled
                : languages['en'].listYourCar.vehicleInformation.modelPlaceholder
            }
            options={modelOptions}
            label={languages['en'].listYourCar.vehicleInformation.modelLabel}
            ref={register}
            rebassSelectProps={{
              defaultValue: vehicleInformation.model,
              onChange: (e) => {
                clearErrors('model')
                setValue('model', e.target.value)
                setValue('transunionId', '')
                // setVehicleInformationState((vehicleInformationState) => {
                //   const newState: VehicleInformationData = JSON.parse(JSON.stringify(vehicleInformationState))
                //   newState['model'] = e.target.value
                //   newState['transunionId'] = ''
                //   return newState
                // })
              },
              value: model,
              autoComplete: 'on',
              disabled: !make
            }}
            error={errors.model && errors.model.message}
          />
          <Select
            name="transunionId"
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.variantToolTipInfo}
            placeholder={
              !model
                ? languages['en'].listYourCar.vehicleInformation.variantPlaceholderDisabled
                : languages['en'].listYourCar.vehicleInformation.variantPlaceholder
            }
            options={variantOptions}
            label={languages['en'].listYourCar.vehicleInformation.variantLabel}
            rebassSelectProps={{
              defaultValue: vehicleInformation.transunionId,
              onChange: (e) => {
                setValue('transunionId', e.target.value)
                clearErrors('transunionId')
              },
              value: transunionId,
              autoComplete: 'on',
              disabled: !model
            }}
            ref={register}
            error={errors.transunionId && errors.transunionId.message}
          />
          <Select
            name="categoryId"
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.categoryToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.categoryPlaceholder}
            options={categoryOptions}
            label={languages['en'].listYourCar.vehicleInformation.categoryLabel}
            rebassSelectProps={{
              defaultValue: vehicleInformation.categoryId,
              // onChange,
              // value: vehicleInformationState.categoryId,
              autoComplete: 'on'
            }}
            ref={register}
            error={errors.categoryId && errors.categoryId.message}
          />
          <Select
            name="fueltype"
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.fueltypeToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.fueltypePlaceholder}
            options={enumToOptionsArray(VehicleFuel, enumLanguages.en.enums.VehicleFuel)}
            label={languages['en'].listYourCar.vehicleInformation.fueltypeLabel}
            rebassSelectProps={{
              defaultValue: vehicleInformation.fueltype,
              // onChange,
              // value: vehicleInformationState.fueltype ? vehicleInformationState.fueltype : '',
              autoComplete: 'on'
            }}
            ref={register}
            error={errors.fueltype && errors.fueltype.message}
          />
          <Select
            name="color"
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.colorToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.colorPlaceholder}
            options={enumToOptionsArray(VehicleColor, enumLanguages.en.enums.VehicleColor)}
            label={languages['en'].listYourCar.vehicleInformation.colorLabel}
            rebassSelectProps={{
              defaultValue: vehicleInformation.color,

              // onChange,
              // value: vehicleInformationState.color ? vehicleInformationState.color : '',
              autoComplete: 'on'
            }}
            ref={register}
            error={errors.color && errors.color.message}
          />
          <Select
            name="transmission"
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.transmissionToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.transmissionPlaceholder}
            options={enumToOptionsArray(VehicleTransmission, enumLanguages.en.enums.VehicleTransmission)}
            label={languages['en'].listYourCar.vehicleInformation.transmissionLabel}
            rebassSelectProps={{
              defaultValue: vehicleInformation.transmission,

              // onChange,
              // value: vehicleInformationState.transmission ? vehicleInformationState.transmission : '',
              autoComplete: 'on'
            }}
            ref={register}
            error={errors.transmission && errors.transmission.message}
          />
          <Box>
            <LabelTooltip
              labelText={languages['en'].listYourCar.vehicleInformation.featuresLabel}
              toolTipInfo={languages['en'].listYourCar.vehicleInformation.featuresToolTipInfo}
              mb={1}
            />
            {featureOptions && featureOptions.length > 0 && (
              <MultipleFilter
                initialValues={vehicleInformation.featureIds}
                filterName={languages['en'].rentCar.filters.features.filterName}
                options={featureOptions}
                onSet={(options) => {
                  setValue('featureIds', options)
                  clearErrors('featureIds')
                  // setVehicleInformationState((vehicleInformationState) => {
                  //   const newState: VehicleInformationData = JSON.parse(JSON.stringify(vehicleInformationState))
                  //   newState['featureIds'] = options
                  //   return newState
                  // })
                }}
                selectMode={true}
                showButton={false}
                sx={{ width: '100% !important' }}
              />
            )}
          </Box>
          <Input
            type="number"
            name="odometer"
            label={languages['en'].listYourCar.vehicleInformation.odometerLabel}
            toolTipInfo={languages['en'].listYourCar.vehicleInformation.odometerToolTipInfo}
            placeholder={languages['en'].listYourCar.vehicleInformation.odometerPlaceholder}
            rebassInputProps={{
              min: 0,
              autoComplete: 'on',
              defaultValue: vehicleInformation.odometer ? vehicleInformation.odometer : null
              // onChange
            }}
            ref={register}
            error={errors.odometer && errors.odometer.message}
          />
          <Box sx={{ ...fullWidthField }}>
            <Textarea
              name="description"
              label={languages['en'].listYourCar.vehicleInformation.descriptionLabel}
              toolTipInfo={languages['en'].listYourCar.vehicleInformation.descriptionToolTipInfo}
              placeholder={languages['en'].listYourCar.vehicleInformation.descriptionPlaceholder}
              sx={{ mb: 1 }}
              rebassTextareaProps={{
                autoComplete: 'on',
                rows: 4
                // value: vehicleInformationState.description,
                // onChange
              }}
              ref={register}
            />
            {errors && errors.description && (
              <Error sx={{ position: 'static', mt: -1 }}>{errors.description.message}</Error>
            )}
            <Text
              size="tiny"
              color={MAX_CHARACTERS - description.length === 0 ? 'statusError' : 'textDark'}
            >{`Characters remaining: ${MAX_CHARACTERS - description.length}`}</Text>
          </Box>

          {(process.env.GATSBY_SHOW_TRACKER_OPTION || false) && (
            <>
              <Box sx={{ ...fullWidthField, ...trackerWrapperStyles }}>
                <Box>
                  <Flex sx={{ gap: [1, 3, 5], flexDirection: ['column', 'row'], alignItems: 'center' }}>
                    <Box flex="1">
                      <Box mb={2}>
                        <Text fontWeight="bold" size="medium" mb={1}>
                          {languages.en.listYourCar.vehicleInformation.trackerHeadline}{' '}
                        </Text>
                        <Text size="small"> {languages.en.listYourCar.vehicleInformation.trackerText} </Text>
                      </Box>
                      <Checkbox
                        name="tracker"
                        rebassCheckboxProps={{
                          // checked: vehicleInformationState.tracker,
                          onChange: (e) => {
                            e.target.checked && clearErrors('trackingDeviceCertificate')
                          }
                        }}
                        sx={{ mb: 0 }}
                        ref={register}
                      >
                        <Text fontWeight="bold" size="small" ml={[0]}>
                          {languages.en.listYourCar.vehicleInformation.trackerLabel}
                        </Text>
                      </Checkbox>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              {errors && errors.tracker && <Error sx={{ position: 'static', mt: -1 }}>{errors.tracker.message}</Error>}
            </>
          )}

          <Box sx={{ ...fullWidthField }}>
            <UploadDocuments
              existingDocuments={existingDocuments}
              updateStateDocuments={updateStateDocuments}
              errors={errors}
            />
          </Box>
          <Box sx={{ ...fullWidthField, mb: [2] }}>
            <FormMessage
              showMessage={submitResult.submitted}
              message={submitResult.message}
              color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
            />
          </Box>
          <Box width={0} height={0} overflow="hidden">
            <Button ref={ref} type="submit" label="submit" />
          </Box>
        </Box>
      </React.Fragment>
    )
  }
)
export default VehicleInformationForm
