import React, { FC } from 'react'
import { SxStyleProp, Box, BoxProps } from 'rebass'
import { Container } from '../Grid'
import Heading from '../Heading'
import Text from '../Text'
import { StyledHero, StyledContent, StyledImages, StyledOverlay } from './Hero.styled'
import theme from '../../theme'
type HeroType = 'primary' | 'calculator' | 'search' | 'small'
export interface HeroProps extends BoxProps {
  /** Hero type */
  type?: HeroType
  /** Image(s) for the gallery */
  images?: Array<React.ReactNode> | Element
  /** Headline text on Hero */
  headline?: string | Element
  /** Descriptive text to support the Headline */
  description?: string
  /** Sx Props */
  sx?: SxStyleProp
  /** Children */
  children?: React.ReactNode
}

const Hero: FC<HeroProps> = ({ type, images, headline, description, children, sx, ...props }) => {
  return (
    <StyledHero type={type} {...props} py={[4, 6]} sx={{ ...sx }}>
      <StyledImages>{images}</StyledImages>
      <StyledOverlay />
      <StyledContent>
        <Container>
          {headline && (
            <Heading
              size="h1"
              lineHeight={[1, 1.2]}
              fontWeight={theme.fontWeights.light}
              textAlign="center"
              color={theme.colors.textWhite}
            >
              {headline}
            </Heading>
          )}
          {description && (
            <Box mt={[2, 4]}>
              <Text textAlign="center" color={theme.colors.textWhite}>
                {description}
              </Text>
            </Box>
          )}
        </Container>
        {children && children}
      </StyledContent>
    </StyledHero>
  )
}

Hero.defaultProps = {
  type: 'primary',
  images: null,
  headline: '',
  description: '',
  children: null,
  sx: {}
}

export default Hero
