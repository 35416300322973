import { makeReference } from '@apollo/client'
import { ImageWrapper, Text, VehicleCardFeature, VehicleCardSimple } from '@rmr/components'
import { SearchVehicleInput, VehicleEdge } from '@rmr/controllers'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import Skeleton from 'react-loading-skeleton'
import { Box, Flex, Link as RebassLink } from 'rebass'
import { VEHICLE_SEARCH_LIMIT } from '../../common/constants'
import { languages } from '../../common/i18n'
import NoVehicles from '../../images/no-vehicles.png'
import { getVehicleCardDetails } from '../../utils/helpers'
import Map, { Bounds } from '../Map'
import { RentCarQueryParams } from '../RentCar/RentCar'
import {
  mapContainerStyles,
  vehiclesContainer,
  vehiclesWrapperStyles,
  vehicleWrapperStyles
} from './VehiclesDisplay.styled'

interface VehiclesDisplayProps {
  showMap: boolean
  filter: SearchVehicleInput
  isMobile: boolean
  updateFilter: (keys: string[], values: any[]) => void
  vehicles: VehicleEdge[]
  loading: boolean
  isMapSearch: boolean
  initBounds?: Bounds
}

export const VehiclesDisplay = ({
  showMap,
  vehicles = [],
  loading,
  isMobile,
  updateFilter,
  filter,
  isMapSearch,
  initBounds
}: VehiclesDisplayProps) => {
  const { startDate, endDate } = filter

  const [activeVehicle, setActiveVehicle] = useState<string | null>(null)
  const [vehicleHover, setVehicleHover] = useState<string | null>(null)

  const onBoundsChange = useCallback(
    (bounds, isMapSearch) => {
      if (isMapSearch) {
        updateFilter(
          ['latitude', 'longitude', 'isMapSearch', ...Object.keys(bounds)],
          [null, null, isMapSearch, ...Object.values(bounds)]
        )
      }
    },
    [updateFilter]
  )
  useEffect(() => {
    if (!filter.isMapSearch && !filter.latitude && !filter.longitude) {
      updateFilter(
        [
          'latitude',
          'longitude',
          'isMapSearch',
          'northEastLatitude',
          'northEastLongitude',
          'southWestLatitude',
          'southWestLongitude'
        ],
        [null, null, true, -11.2972, 38.6767, -43.6998, 10.8153]
      )
    }
  }, [filter.isMapSearch, filter.latitude, filter.longitude, updateFilter])

  return (
    <Box as="section" sx={vehiclesContainer(showMap)}>
      <Box sx={{ px: [2, 4], mb: [10], position: 'relative' }}>
        {loading ? (
          <Box sx={vehiclesWrapperStyles}>
            {Array(VEHICLE_SEARCH_LIMIT)
              .fill(1)
              .map((_, index) => (
                <Box key={index} sx={{ zIndex: 1 }}>
                  <VehicleCardSimple
                    price=""
                    priceDistribution="daily"
                    carName=""
                    slides={[<ImageWrapper key={index} src={''} alt="placeholder" />]}
                    totalTrips={-1}
                    rating={-1}
                    language="en"
                    boxShadow={false}
                    padding={[2]}
                  />
                </Box>
              ))}
          </Box>
        ) : vehicles.length === -1 ? (
          <Box sx={vehiclesWrapperStyles}>
            {vehicles.map(({ node }, index) => {
              const {
                id,
                pricePerDay,
                defaultName,
                name,
                images,
                stats,
                addresses,
                max_kilometers_per_day,
                is_deliver_airport,
                is_deliver_renter
              } = node
              const active = id === activeVehicle || id === vehicleHover
              let url = `/app/listing/${id}`
              if (startDate && endDate) {
                url += `?startDate=${startDate?.unix()}&endDate=${endDate?.unix()}`
              }
              const { location, features } = getVehicleCardDetails(node)

              return (
                <LazyLoad
                  key={id}
                  offset={100}
                  once={true}
                  placeholder={
                    <Box sx={{ zIndex: 1 }}>
                      <VehicleCardSimple
                        price=""
                        priceDistribution="daily"
                        carName=""
                        slides={[<ImageWrapper key={0} src={''} alt="placeholder" />]}
                        totalTrips={-1}
                        rating={-1}
                        language="en"
                        boxShadow={false}
                        padding={[2]}
                      />
                    </Box>
                  }
                >
                  <Box sx={{ zIndex: !isMobile && active ? 1 : 0 }}>
                    <RebassLink
                      href={url}
                      target="_blank"
                      onMouseEnter={() => showMap && setVehicleHover(id)}
                      onMouseLeave={() => showMap && setVehicleHover(null)}
                      sx={vehicleWrapperStyles(!isMobile && active)}
                    >
                      <VehicleCardSimple
                        price={pricePerDay.formattedNoDecimals}
                        priceDistribution="daily"
                        carName={defaultName || name}
                        slides={images.slice(0, 5).map((img) => (
                          <ImageWrapper key={img.id} src={img.file.url} alt={name} />
                        ))}
                        totalTrips={stats.trips}
                        rating={Math.ceil(stats.ratings)}
                        location={location}
                        features={features}
                        language="en"
                        boxShadow={!isMobile && active ? true : false}
                        padding={[2]}
                      />
                    </RebassLink>
                  </Box>
                </LazyLoad>
              )
            })}
          </Box>
        ) : (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
            <Box width={[8 / 12, 4 / 12]} mb={[2, 4]}>
              <ImageWrapper ratio={1.422} src={NoVehicles} alt={languages['en'].rentCar.noResultsImageAlt} />
            </Box>
            <Text textAlign="center">{'Rentals Temporarily Paused'}</Text>
            <Text size={'small'} textAlign="center">
              We apologize for the inconvenience and assure you we're working to resolve the issue as quickly as
              possible
            </Text>
          </Flex>
        )}
      </Box>

      <Box sx={mapContainerStyles(isMobile, showMap)}>
        {vehicles && showMap && 0 ? (
          <Map
            isMapSearch={isMapSearch}
            vehicles={vehicles}
            filter={filter}
            vehicleHover={vehicleHover}
            vehicleActive={activeVehicle}
            onActiveVehicle={setActiveVehicle}
            startDate={startDate?.unix()}
            endDate={endDate?.unix()}
            isMobile={isMobile}
            showPin={true}
            initBounds={initBounds}
            onBoundsChange={onBoundsChange}
          />
        ) : (
          <Box />
        )}
      </Box>
    </Box>
  )
}
