import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import theme from '../../theme'

export const StyledEarnResults = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: ${theme.borderRadius.medium};
  box-shadow: 1px 1px 15px 1px ${theme.colors.bgShadow};
  position: relative;
  text-align: center;
  &::before {
    position: absolute;
    top: -20px;
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 25px 20px 25px;
    border-color: transparent transparent ${theme.colors.primary} transparent;
  }
`

export const StyledEarnSlider = styled(Box)``
