import React, { FC } from 'react'
import { Box, SxStyleProp, Flex } from 'rebass'
import Skeleton from 'react-loading-skeleton'

import theme from '../../theme'
import Text from '../Text/Text'
import Icon from '../Icon/Icon'
import languages from '../../i18n'
import { Vehicle } from '@rmr/controllers'
import IconWithContent from '../IconWithContent'

export interface VehicleDetailsProps {
  vehicle: Vehicle
  /** Set the primary headline */
  headlineDetails?: string
  /** Set the primary headline */
  headlineDescription?: string
  /** Set the vehicle description */
  description?: string
  /** Sx Props */
  sx?: SxStyleProp
  /** Array of Vehicle details items */
  items?: [DetailItemsProps]
  /** Component is loading */
  loading?: boolean
}
export interface DetailItemsProps {
  type?: string
  value?: string
  key: string
}

const VehicleDetails: FC<VehicleDetailsProps> = ({
  vehicle,
  headlineDetails = 'Details',
  headlineDescription = 'Description',
  description,
  items,
  sx,
  loading,
  ...props
}) => {
  return (
    <Box {...props} sx={{ ...sx }} mt={[4, 6]} mb={[2, 2, 4]}>
      <Flex sx={{ mb: [2, 2, 4] }} flexDirection={['column', null, 'row']} flexWrap="wrap">
        <Box width={[1, null, null, null, 4 / 12]}>
          <Text mb={[1, 2]} size={'medium'} color={theme.colors.textDark} fontWeight={theme.fontWeights.bold}>
            {!loading ? headlineDetails : <Skeleton style={{ width: '100px' }} />}
          </Text>
        </Box>
        <Flex width={[1, null, null, null, 8 / 12]} flexWrap="wrap" flexDirection={['column', 'row']}>
          {!loading
            ? vehicle.detailsList.map((detail) => {
                const { label, icon, description } = detail
                return (
                  <Flex key={label} width={[1, 6 / 12]} flex="0 0 auto" alignItems="center" mb={4}>
                    <IconWithContent
                      headline={label}
                      description={description}
                      icon={icon}
                      iconSx={{ fontSize: '30px', width: '40px' }}
                    />
                  </Flex>
                )
              })
            : Array(6)
                .fill(1)
                .map((_, index) => (
                  <Flex key={index} sx={{ flexBasis: '50%', my: 1, alignItems: 'center' }}>
                    <Box sx={{ width: 50, height: 50, borderRadius: '50%', overflow: 'hidden', mr: 1 }}>
                      <Skeleton style={{ width: '100%', height: '100%', display: 'block' }} />
                    </Box>
                    <Skeleton style={{ width: '100px' }} />
                  </Flex>
                ))}
        </Flex>
      </Flex>
      {(loading || vehicle.description) && (
        <Flex sx={{}} flexDirection={['column', null, 'row']} flexWrap="wrap">
          <Box width={[1, null, null, null, 4 / 12]}>
            <Text mb={[1, 2]} size={'medium'} color={theme.colors.textDark} fontWeight={theme.fontWeights.bold}>
              {!loading ? headlineDescription : <Skeleton style={{ width: '100px' }} />}
            </Text>
          </Box>
          <Box width={[1, null, null, null, 8 / 12]}>
            <Text
              mb={[1, 2]}
              ml={[1]}
              size={'medium'}
              color={theme.colors.textGrayLight}
              fontWeight={theme.fontWeights.semiBold}
            >
              {!loading ? vehicle.description : <Skeleton style={{ width: '100%' }} />}
              {loading && (
                <Box mt={1}>
                  <Skeleton style={{ width: '100%' }} />
                </Box>
              )}
            </Text>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

VehicleDetails.defaultProps = {
  headlineDetails: '',
  headlineDescription: '',
  description: '',
  items: null,
  sx: {},
  loading: false
}

export default VehicleDetails
