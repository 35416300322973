export class FileValidation {
  file: File

  constructor(file: File) {
    this.file = file
  }

  // 2 MB
  private maxSize = 2097152

  private acceptedTypes: string[] = [
    'application/pdf',
    'image/img',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]

  private imageTypes = ['image/img', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']

  isValidFileType() {
    return this.acceptedTypes.includes(this.file.type)
  }

  isValidSize() {
    return this.file.size < this.maxSize
  }

  isValidFile() {
    return this.isValidFileType() && this.isValidSize()
  }

  isValidImageType() {
    return this.imageTypes.includes(this.file.type)
  }
}
