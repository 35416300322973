import React from 'react'
import { Box, BoxProps, SxStyleProp } from 'rebass'
import ReactPhoneInput2 from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Label from '../Label'
import Error from '../Error'
import { fieldStyles } from './PhoneInput.styled'

export interface PhoneInputProps extends BoxProps {
  /** Field Label */
  fieldLabel?: string
  /** Field Label */
  fieldName?: string
  /** Initial Country Code */
  countryCodeInit?: string
  /** Initial Phone Number */
  phoneNumber?: string
  /** Error message */
  errorMessage?: string
  /** Date Change Handler */
  onPhoneChange?: (phoneNumber: string, countryCode: string, phoneCountryCode: string) => void
  /** Sx prop styles */
  sx?: SxStyleProp
}

const PhoneInput = ({
  fieldLabel,
  fieldName,
  countryCodeInit,
  phoneNumber,
  onPhoneChange,
  errorMessage,
  sx,
  ...props
}: PhoneInputProps) => {
  return (
    <Box sx={{ ...fieldStyles, ...sx }} {...props}>
      {!!fieldLabel && <Label mb={1}>{fieldLabel}</Label>}
      <ReactPhoneInput2
        key={countryCodeInit}
        country={countryCodeInit.toLowerCase()}
        value={phoneNumber}
        inputProps={{
          name: fieldName
        }}
        onChange={(phone, country: any) => {
          onPhoneChange(`+${phone}`, country?.countryCode?.toUpperCase(), `+${country?.dialCode}`)
        }}
      />
      {errorMessage && <Error>{errorMessage}</Error>}
    </Box>
  )
}

PhoneInput.defaultProps = {
  fieldLabel: '',
  fieldName: 'phoneNumber',
  countryCodeInit: 'ZA',
  phoneNumber: '',
  errorMessage: '',
  onPhoneChange: null,
  sx: {}
}

export default PhoneInput
