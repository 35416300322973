import { SxStyleProp } from 'rebass'

export const cardWrapperStyles: SxStyleProp = {
  width: ['100%', null, null, 850, 1000],
  position: 'relative',
  display: ['grid', 'grid', 'flex'],
  justifyContent: 'space-between',
  gridTemplateColumns: ['repeat(1,1fr)', 'repeat(3,1fr)'],
  gridGap: [1]
}

export const lineStyles: SxStyleProp = {
  position: 'absolute',
  top: '60px',
  left: '50%',
  width: [500],
  transform: ['translateX(-50%)'],
  height: '5px',
  bg: 'bgGrayLight',
  display: ['none', null, 'block']
}
