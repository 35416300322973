import { SxStyleProp } from 'rebass'
import theme from '../../theme'

export const headerStyles = (hasScrolled: boolean): SxStyleProp => ({
  position: 'sticky',
  top: 0,
  left: 0,
  width: '100%',
  bg: hasScrolled ? 'bgBlack' : 'transparent',
  transition: 'background 0.2s',
  height: [80],
  zIndex: [theme.zIndex.max - 1, null, null, theme.zIndex.header],
  '& > div': {
    height: '100%',
    display: 'flex',
    alignItems: ['start', null, null, 'center']
  }
})

export const navBarStyles = {
  flex: 1,
  flexDirection: ['column', null, null, 'row'],
  alignItems: ['center', null, null, 'unset'],
  justifyContent: [null, null, null, 'space-between'],
  bg: 'bgBlack'
}

export const logoWrapperStyles = {
  position: ['absolute', null, null, 'static'],
  top: 2,
  left: 4
}

export const fullLogoStyles = {
  display: ['none', null, null, 'block']
}
export const emblemStyles = {
  display: ['block', null, null, 'none']
}

export const navigationWrapperStyles = (open: boolean): SxStyleProp => ({
  position: ['fixed', null, null, 'unset'],
  top: [80, null, null, 'unset'],
  left: 0,
  width: ['100%', null, null, 'unset'],
  zIndex: [-1, null, null, 'unset'],
  flexDirection: ['column', null, null, 'row'],
  bg: ['bgBlack', null, null, 'transparent'],
  overflow: [open ? 'auto' : 'hidden', null, null, 'unset'],
  transition: open ? 'transform 0.3s linear' : 'transform 0s linear',
  height: [open ? 'calc(100vh - 80px)' : 0, null, null, 'unset'],
  transform: [open ? 'translateY(0)' : 'translateY(-100%)', null, null, 'unset'],
  alignItems: 'center',
  pt: [open ? 5 : 0, null, null, 0],
  pb: [open ? 5 : 0, null, null, 0]
})
export const navigationStyles: SxStyleProp = {
  mr: [null, null, null, 2]
}
export const navButtonsWrapperStyles: SxStyleProp = {
  mt: [2, null, null, 0]
}
export const navButtonStyles: SxStyleProp = {
  px: [4, null, null],
  '&:last-child': {
    ml: [1]
  }
}

export const hamburgerStyles = {
  display: ['flex', null, null, 'none'],
  position: 'absolute',
  top: 2,
  right: 4
}
