import { Loader, UploadComponent } from '@rmr/components'
import { FileType, fileUploader, maxSize, VehicleDocumentEnum, VehicleDocumentTypeEnum } from '@rmr/controllers'
import React, { useCallback, useState } from 'react'
import { uploadComponentStyles } from './UploadDocuments.styled'

type HandleUploadCallback = (files: FileExtended[]) => void

interface UploadDocumentProps {
  updateStateDocuments: (document: VehicleDocumentEnum, value: string) => void
  initFiles: File[]
  currentDocuments: { [key in VehicleDocumentEnum]: string }
  documentType: VehicleDocumentEnum
  label: string
  description: string
  error: string
}
const UploadDocument = ({
  updateStateDocuments,
  initFiles,
  currentDocuments,
  documentType,
  label,
  description,
  error
}: UploadDocumentProps) => {
  const [loading, setLoading] = useState(false)
  const handleUploadCallback: HandleUploadCallback = useCallback(
    async (files) => {
      try {
        setLoading(true)

        if (files.length > 0) {
          if (files[0].preview && !currentDocuments[documentType]) {
            const file = await fileUploader(files[0], FileType.DOCUMENT)
            if (file) {
              updateStateDocuments(documentType, file.id)
              currentDocuments[documentType] = file.id
            }
          }
        } else {
          if (currentDocuments[documentType]) {
            updateStateDocuments(documentType, '')
            currentDocuments[documentType] = ''
          }
        }
      } catch (error) {
        console.log('File Upload Error: ', error)
      } finally {
        setLoading(false)
      }
    },
    [currentDocuments, documentType, updateStateDocuments]
  )
  return (
    <>
      {loading && <Loader />}
      <UploadComponent
        variant="vertical"
        labelSize="small"
        maxFiles={1}
        maxSize={maxSize}
        fileType={FileType.Document}
        sx={uploadComponentStyles}
        initFiles={initFiles}
        label={label}
        description={description}
        callback={handleUploadCallback}
        error={error}
      />
    </>
  )
}

export default UploadDocument
