import React, { useState, useEffect } from 'react'
import { Box, Flex, SxStyleProp } from 'rebass'

import Container from '../Grid/Container'
import Logo from '../Logo'
import Hamburger from '../Hamburger'
// import Notice from '../Notice'
import Emblem from '../Emblem'
import Button, { ButtonProps } from '../Button'
import Link from '../Link'
import { MenuItemType } from '../MenuItem'
import Navigation from '../Navigation'
import {
  headerStyles,
  navBarStyles,
  logoWrapperStyles,
  fullLogoStyles,
  emblemStyles,
  navigationWrapperStyles,
  navigationStyles,
  navButtonsWrapperStyles,
  navButtonStyles,
  hamburgerStyles
} from './Header.styled'

interface NavButton extends ButtonProps {
  to?: string
  onClick?: () => void
  target?: '_blank' | '_self'
}

export interface HeaderProps {
  /** Home Link */
  homeLink: string
  /** Menu Items */
  menuItems?: MenuItemType[]
  /** Nav Buttons */
  navButtons?: NavButton[]
  /** Dark mode */
  dark?: boolean
  /** Render Notice */
  renderNotice?: () => JSX.Element
  /** Sx prop styles */
  sx?: SxStyleProp
}

const Header = ({ homeLink, menuItems, navButtons, dark, renderNotice, sx, ...props }: HeaderProps) => {
  const [open, setOpen] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(dark)

  const checkTarget: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if ((e.target as HTMLElement).localName === 'a') {
      const href = (e.target as HTMLElement).attributes.getNamedItem('href')
      if (href && href.value && (href.value[0] === '/' || href.value.substr(0, 4) === 'http')) {
        setOpen(false)
      }
    }
  }

  const handleScroll = () => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 30) {
      setHasScrolled(true)
    } else {
      setHasScrolled(dark)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })
  return (
    <>
      <Box as="header" sx={{ ...headerStyles(hasScrolled), ...sx }} {...props}>
        <Container maxWidth={'1620px'}>
          <Flex as="nav" sx={navBarStyles}>
            <Box sx={logoWrapperStyles} onClick={() => setOpen(false)}>
              <Link to={homeLink} aria-label="RentMyRide Logo">
                <Logo sx={fullLogoStyles} />
                <Emblem sx={emblemStyles} />
              </Link>
            </Box>
            <Flex sx={navigationWrapperStyles(open)} onClick={checkTarget}>
              <Navigation key={open ? 1 : 0} menuItems={menuItems} sx={navigationStyles} />
              <Flex sx={navButtonsWrapperStyles}>
                {navButtons.map(({ to, target, ...navButtonRest }, index) => {
                  return (
                    <Box key={index} onClick={(e) => setOpen(false)}>
                      {to ? (
                        <Link to={to} target={target}>
                          <Button variant="outlineRound" {...navButtonRest} sx={navButtonStyles} />
                        </Link>
                      ) : (
                        <Button variant="outlineRound" {...navButtonRest} sx={navButtonStyles} />
                      )}
                    </Box>
                  )
                })}
              </Flex>
            </Flex>
          </Flex>
          <Hamburger open={open} sx={hamburgerStyles} onClick={() => setOpen((open) => !open)} aria-label="menu" />
        </Container>
      </Box>
      {renderNotice && renderNotice()}
    </>
  )
}

Header.defaultProps = {
  homelink: {
    href: '#'
  },
  menuItems: [],
  navButtons: [],
  dark: false,
  renderNotice: null,
  sx: {}
}

export default Header
