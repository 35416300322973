import React from 'react'
import { Box, Flex, Image, Link as RebassLink } from 'rebass'
import { itemStyles, reservationWrapperStyles } from './ReservationQuote.styled'
import { addressToString, Reservation, useMeQuery } from '@rmr/controllers'
import {
  Container,
  ListItems,
  ListItem,
  StepsWrapper,
  VehicleCardMobile,
  Text,
  Icon,
  stepsWrapperStyles
} from '@rmr/components'

import { languages } from '../../../common/i18n'
import PaymentForm from '../PaymentForm'
import Dates from './Dates'

interface ReservationSettlementProps {
  reservation: Reservation
}

const ReservationSettlement = ({ reservation }: ReservationSettlementProps) => {
  const {
    data: { me }
  } = useMeQuery()

  const { vehicle, address } = reservation

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
  }

  return (
    <Box as="section" sx={{ pt: [15], pb: [6] }}>
      <Container>
        <StepsWrapper
          badgeProps={{ variant: 'circle', size: [150], icon: 'money' }}
          title={'Rental Settlement'}
          subtitle={
            'After clicking “Proceed", you will be redirected to the payment gateway to complete your rental payment securely.'
          }
          sx={stepsWrapperStyles}
        >
          <Box as="form" onSubmit={handleSubmit} sx={reservationWrapperStyles}>
            <Box>
              <Box mb={[3]}>
                <VehicleCardMobile
                  carName={vehicle.name}
                  image={vehicle.images[0].file.url}
                  totalTrips={vehicle.stats.trips}
                  rating={vehicle.stats.ratings}
                  language="en"
                  boxShadow={false}
                />
              </Box>
              <ListItems>
                <ListItem
                  icon="equal"
                  description="Amount Due"
                  price={reservation.orderLineTotals.balance.formatted}
                  isLast={true}
                />
              </ListItems>
            </Box>
            <Box>
              <Dates startDate={reservation.dates.startFullDate} endDate={reservation.dates.endFullDate} />
              <Box sx={itemStyles}>
                <Text size="large" color="textBlack" fontWeight="bold" mb={3}>
                  {languages.en.booking.deliverOptions}
                </Text>
                {address && (
                  <Box>
                    <Text size="small" color="textBlack" fontWeight="bold">
                      {languages.en.booking.addressLabel}
                    </Text>
                    <Flex alignItems="center">
                      <Text size="small" color="textLight">
                        {addressToString(address)}
                      </Text>
                      {address.placeId && (
                        <RebassLink
                          href={`https://www.google.com/maps/place/?q=place_id:${address.placeId}`}
                          flexShrink={0}
                          ml={2}
                          target="_blank"
                        >
                          <Icon name="navigation" color="primary" />
                        </RebassLink>
                      )}
                    </Flex>
                  </Box>
                )}
              </Box>
              <Box mt={2}>
                <Text size="large" color="textBlack" fontWeight="bold" mb={2}>
                  {languages.en.booking.paymentLabel}
                </Text>
                <PaymentForm reservation={reservation} cards={me?.cards ?? []} settle={true} />
              </Box>
            </Box>
          </Box>
        </StepsWrapper>
      </Container>
    </Box>
  )
}

export default ReservationSettlement
