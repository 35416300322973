import React, { FC } from 'react'
import { Box, SxStyleProp, Flex, BoxProps, Image } from 'rebass'
import theme from '../../theme'
import Text from '../Text/Text'
import StarRating from '../StarRating/StarRating'
import Heading from '../Heading'
import Icon from '../Icon'
import { avatarStyles } from './ProfileSummarySmall.styled'
import Separator from '../Separator'

export interface ProfileSummarySmallProps extends BoxProps {
  /** Profile image url */
  profileImageUrl?: string
  /** Title */
  title?: string
  /** Children */
  children: React.ReactNode
  /** Sx Style Prop */
  sx?: SxStyleProp
}

const ProfileSummarySmall: FC<ProfileSummarySmallProps> = ({ profileImageUrl, sx, title, children, ...props }) => {
  return (
    <Flex alignItems="center" sx={sx} {...props}>
      <Flex sx={avatarStyles} mr={[2]}>
        {profileImageUrl ? (
          <Image src={profileImageUrl} alt={title} sx={{ height: '100%', width: '100%', objectFit: 'cover' }} />
        ) : (
          <Icon name="user" />
        )}
      </Flex>
      <Box>
        <Text size="small" color="textBlack" fontWeight="bold">
          {title}
        </Text>
        {children}
      </Box>
    </Flex>
  )
}

ProfileSummarySmall.defaultProps = {
  profileImageUrl: '',
  title: '',
  children: null,
  sx: {}
}

export default ProfileSummarySmall
