import { SxStyleProp } from 'rebass'

export const avatarStyles: SxStyleProp = {
  width: [50],
  height: [50],
  borderRadius: '50%',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center'
}
