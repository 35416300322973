// eslint-disable-next-line @typescript-eslint/no-var-requires
import { parsePhoneNumber, NumberFormat, CountryCode } from 'libphonenumber-js/mobile'

export class InputValidator {
  input: string

  constructor(payload: string) {
    this.input = payload.toLowerCase().trim()
  }

  validateIdNumber(): boolean {
    let correct = true

    if (this.input.length !== 13) {
      return false
    }

    let tempTotal = 0
    let checkSum = 0
    let multiplier = 1
    for (let i = 0; i < 13; ++i) {
      tempTotal = parseInt(this.input.charAt(i), 10) * multiplier
      if (tempTotal > 9) {
        tempTotal = parseInt(tempTotal.toString().charAt(0), 10) + parseInt(tempTotal.toString().charAt(1), 10)
      }
      checkSum = checkSum + tempTotal
      multiplier = multiplier % 2 === 0 ? 1 : 2
    }

    if (checkSum % 10 !== 0) {
      correct = false
    }

    return correct
  }

  validateEmail(): boolean {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.input)
  }

  validateCellphone(countryCode?: CountryCode): boolean {
    if (!countryCode) countryCode = 'ZA'
    try {
      const phoneNumber = this.input
      const number = parsePhoneNumber(phoneNumber, countryCode)
      return number.isValid()
    } catch {
      return false
    }
  }

  formatCellphone(format: NumberFormat, countryCode: CountryCode = 'ZA'): string {
    const phoneNumber = this.input
    return parsePhoneNumber(phoneNumber, countryCode).format(format)
  }

  // Luhn algorithm
  validateCreditCardNumber(): boolean {
    const arr = (this.input + '')
      .split('')
      .reverse()
      .map((x) => parseInt(x))
    const lastDigit = arr.splice(0, 1)[0]
    let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0)
    sum += lastDigit
    return sum % 10 === 0
  }
}
