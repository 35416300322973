import React from 'react'
import { Flex, FlexProps, SxStyleProp } from 'rebass'

import MenuItem, { MenuItemType } from '../MenuItem'
import { navigationStyles, menuItemStyles } from './Navigation.styled'

export interface NavigationProps extends FlexProps {
  /** Menu  */
  menuItems: MenuItemType[]
  /** Sx prop styles  */
  sx: SxStyleProp
}

const Navigation = ({ menuItems, sx, ...rest }: NavigationProps) => {
  return (
    <Flex sx={{ ...navigationStyles, ...sx }} {...rest}>
      {menuItems.map((menuItem, index) => {
        const { id } = menuItem
        return <MenuItem key={menuItem.id ? menuItem.id : index} {...menuItem} sx={menuItemStyles} />
      })}
    </Flex>
  )
}

Navigation.defaultProps = {
  menuItems: [],
  sx: {}
}

export default Navigation
