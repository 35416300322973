import styled from '@emotion/styled'
import theme from '../../theme'
import { Box } from 'rebass'
import { SxStyleProp } from 'rebass'

export const StyledCarousel = styled(Box)`
  .vehicle-listing-carousel {
    .slick-dots {
      bottom: 25px;
      li {
        width: 10px;
        height: 10px;
      }
      button {
        padding: 0;
        width: 10px;
        height: 10px;
      }
      button::before {
        font-size: 7px;
        width: 10px;
        height: 10px;
        color: ${theme.colors.baseWhite};
        opacity: 0.5;
        content: '';
        background-color: ${theme.colors.baseWhite};
        border-radius: ${theme.borderRadius.circle};
      }
      .slick-active {
        button::before {
          opacity: 1;
        }
      }
    }
  }
  .instructions-carousel {
    .slick-arrow {
      font-size: 18px;
      background-color: ${theme.colors.bgGrayLight};
      border: 2px solid ${theme.colors.borderDark};
      text-align: center;
      width: 30px;
      height: 30px;
      border-radius: ${theme.borderRadius.circle};
      display: flex !important;
      align-items: center;
      justify-content: center;
      z-index: 9;
      &.slick-prev {
        left: -8px;
        &:before {
          content: '';
        }
        svg {
          margin-right: 2px;
        }
      }
      &.slick-next {
        right: -8px;
        &:before {
          content: '';
        }
        svg {
          margin-left: 2px;
        }
      }
    }
    .slick-dots {
      bottom: -10px;
      li {
        width: 10px;
        height: 10px;
      }
      button {
        width: 10px;
        height: 10px;
        padding: 0;
      }
      button::before {
        font-size: 7px;
        width: 10px;
        height: 10px;
        opacity: 0.5;
        content: '';
        background-color: transparent;
        border: 2px solid ${theme.colors.borderDark};
        background-color: ${theme.colors.baseWhite};
        border-radius: ${theme.borderRadius.circle};
      }
      .slick-active {
        button::before {
          opacity: 1;
          border-color: ${theme.colors.baseBlack};
          background-color: ${theme.colors.baseBlack};
        }
      }
    }
  }
  .vehicle-card-carousel {
    .slick-dots {
      bottom: 15px;
      li {
        width: 6px;
        height: 6px;
      }
      button {
        width: 6px;
        height: 6px;
        padding: 0;
      }
      button::before {
        font-size: 7px;
        width: 6px;
        height: 6px;
        opacity: 0.5;
        content: '';
        background-color: ${theme.colors.baseWhite};
        border-color: transparent;
        border-radius: ${theme.borderRadius.circle};
      }
      .slick-active {
        button::before {
          opacity: 1;
          background-color: ${theme.colors.baseWhite};
          border-color: transparent;
        }
      }
    }
  }
  .vehicle-cards-carousel {
    .slick-slide {
      padding-right: 4px;
      padding-left: 4px;
    }
    .slick-arrow {
      font-size: 24px;
      text-align: center;
      z-index: 9;
      &.slick-prev {
        &:before {
          content: '';
        }
        svg {
          margin-right: 2px;
        }
      }
      &.slick-next {
        &:before {
          content: '';
        }
        svg {
          margin-left: 2px;
        }
      }
    }
    .slick-dots {
      li {
        width: 10px;
        height: 10px;
      }
      button {
        width: 10px;
        height: 10px;
        padding: 0;
      }
      button::before {
        font-size: 7px;
        width: 10px;
        height: 10px;
        opacity: 0.5;
        content: '';
        background-color: transparent;
        border: 2px solid ${theme.colors.borderDark};
        background-color: ${theme.colors.baseWhite};
        border-radius: ${theme.borderRadius.circle};
      }
      .slick-active {
        button::before {
          opacity: 1;
          border-color: ${theme.colors.baseBlack};
          background-color: ${theme.colors.baseBlack};
        }
      }
    }
  }
  .cards-carousel {
    .slick-slide {
      padding-right: 4px;
      padding-left: 4px;
    }
    .slick-arrow {
      font-size: 24px;
      text-align: center;
      z-index: 9;
      &.slick-prev {
        &:before {
          content: '';
        }
        svg {
          margin-right: 2px;
        }
      }
      &.slick-next {
        &:before {
          content: '';
        }
        svg {
          margin-left: 2px;
        }
      }
    }
    .slick-dots {
      li {
        width: 10px;
        height: 10px;
      }
      button {
        width: 10px;
        height: 10px;
        padding: 0;
      }
      button::before {
        font-size: 7px;
        width: 10px;
        height: 10px;
        opacity: 0.5;
        content: '';
        background-color: transparent;
        border: 2px solid ${theme.colors.borderDark};
        background-color: ${theme.colors.baseWhite};
        border-radius: ${theme.borderRadius.circle};
      }
      .slick-active {
        button::before {
          opacity: 1;
          border-color: ${theme.colors.baseBlack};
          background-color: ${theme.colors.baseBlack};
        }
      }
    }
  }
`

export const carouselStyles: SxStyleProp = {
  '.slick-track': {
    display: 'flex !important'
  },

  '.slick-slide': {
    height: 'inherit !important',
    '&>div': {
      height: '100%'
    }
  }
}
