import { SxStyleProp } from 'rebass'

export const wrapperStyles: SxStyleProp = {
  maxWidth: [1000],
  width: '100%',
  mt: [-2, -4],
  pb: [2],
  px: [0],
  overflow: 'hidden'
}

export const formStyles: SxStyleProp = {
  width: '100%'
  // display: 'grid',
  // gridTemplateColumns: ['repeat(1, 1fr)', null, 'repeat(2, 1fr)'],
  // alignItems: 'start',
  // rowGap: [1],
  // columnGap: [2]
}

export const fullWidthField: SxStyleProp = {
  gridColumn: [null, null, 'span 2']
}

export const fieldStyles: SxStyleProp = {
  width: '100%',
  '.react-tel-input': {
    '.form-control': {
      height: '50px',
      width: '100%',
      border: '2px solid #CACACA'
    },
    '.flag-dropdown': {
      border: '2px solid #CACACA'
    }
  }
}

export const buttonStyles: SxStyleProp = {
  mx: 'auto'
}

export const termsButtonStyles: SxStyleProp = {
  display: 'inline',
  fontWeight: 'bold',
  p: 0
}

export const checkboxStyles: SxStyleProp = {
  'label ': { alignItems: ['start'] }
}
