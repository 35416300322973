import { SxStyleProp } from 'rebass'

import theme from '../../../theme'

export const wrapperStyles: SxStyleProp = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative'
}

export const iconStyles: SxStyleProp = {
  px: 1
}

export type TooltipOffset = { translate: string | number; left: string | number; right: string | number }

export const contentStyles = (active: boolean): SxStyleProp => ({
  minWidth: 'max-content',
  py: [1],
  px: [2],
  bg: active ? 'primaryLight' : 'transparent',
  cursor: 'pointer',
  color: active ? `${theme.colors.textWhite} !important` : `${theme.colors.textDark} !important`,
  ':hover': {
    bg: 'primaryLight',
    color: `${theme.colors.textWhite} !important`
  }
})
