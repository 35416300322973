import React, { FC } from 'react'
import {
  theme,
  SectionFeatures,
  FeatureIconRow,
  FeatureIconLarge,
  FeatureIconStack,
  FeatureIconCard,
  SectionFeatureTypes
} from '@rmr/components'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const getColorScheme = {
  white: {
    headline: {
      featuresStrip: theme.colors.textLight,
      featuresVertical: theme.colors.textWhite,
      featureOverlay: theme.colors.textLight,
      featuresPoster: theme.colors.textLight
    },
    bg: theme.colors.bgWhite,
    headlineModule: theme.colors.textDark,
    text: theme.colors.textLight,
    badgeBg: theme.colors.primary,
    icon: theme.colors.bgLight,
    iconBorder: theme.colors.borderLight
  },
  black: {
    headline: {
      featuresStrip: theme.colors.textLight,
      featuresVertical: theme.colors.textWhite,
      featureOverlay: theme.colors.textWhite,
      featuresPoster: theme.colors.textLight
    },
    bg: theme.colors.bgBlack,
    headlineModule: theme.colors.textWhite,
    text: theme.colors.textWhite,
    badgeBg: theme.colors.primary,
    icon: theme.colors.bgWhite,
    iconBorder: theme.colors.borderLight
  },
  gray: {
    headline: {
      featuresStrip: theme.colors.textLight,
      featuresVertical: theme.colors.textWhite,
      featureOverlay: theme.colors.textWhite,
      featuresPoster: theme.colors.textLight
    },
    bg: theme.colors.bgLight,
    headlineModule: theme.colors.textDark,
    text: theme.colors.textLight,
    badgeBg: theme.colors.primary,
    icon: theme.colors.bgWhite,
    iconBorder: theme.colors.borderLight
  },
  orange: {
    headline: {
      featuresStrip: theme.colors.textGray,
      featuresVertical: theme.colors.textGray,
      featureOverlay: theme.colors.textGray,
      featuresPoster: theme.colors.textGray
    },
    bg: theme.colors.primary,
    headlineModule: theme.colors.textWhite,
    text: theme.colors.textGray,
    badgeBg: theme.colors.bgDark,
    icon: theme.colors.bgWhite,
    iconBorder: theme.colors.borderLight
  }
}

interface SectionFeaturesWrapperProps {
  /** Variant of the Section */
  variant: SectionTypes
  /** Headline for the Section */
  headline?: string
  /** background color acting as color scheme selector */
  backgroundColor?: string
  /** Features */
  features?: any[]
  /** Types of features to render as children */
  featureType: SectionFeatureTypes
  /** Image for the section */
  sectionImage?: React.ReactNode
  /** Content in addition to the features */
  content?: React.ReactNode | string
}

type SectionTypes = 'featuresStrip' | 'featuresVertical' | 'featureOverlay' | 'featuresPoster'

const getSectionImageStyles = {
  featuresStrip: {
    width: '100%',
    height: '100%'
  },
  featuresVertical: {
    width: '100%',
    height: '100%'
  },
  featureOverlay: {
    width: '100%',
    height: '100%'
  },
  featuresPoster: {
    width: '100%',
    height: 'auto'
  }
}

const getFeatureMap = {
  feautureIconLarge: FeatureIconLarge,
  featureRow: FeatureIconRow,
  featureCard: FeatureIconCard,
  featureStack: FeatureIconStack
}
const SectionFeaturesWrapper: FC<SectionFeaturesWrapperProps> = ({
  headline,
  features,
  featureType,
  backgroundColor,
  sectionImage,
  variant,
  content
}) => {
  const Feature = getFeatureMap[featureType]

  return (
    <SectionFeatures
      headline={headline}
      headlineColor={
        backgroundColor ? getColorScheme[backgroundColor].headline[variant] : getColorScheme.black.headline
      }
      bgColor={backgroundColor ? getColorScheme[backgroundColor].bg : getColorScheme.black.bg}
      variant={variant}
      featureType={featureType}
      content={content}
      sectionImage={
        variant !== ('featureOverlay' || 'featuresVertical')
          ? sectionImage && (
              <GatsbyImage
                className="GatsbyImage"
                fluid={sectionImage.localFile.childImageSharp.fluid}
                style={{ ...getSectionImageStyles[variant] }}
              />
            )
          : sectionImage && sectionImage.sourceUrl
      }
    >
      {features &&
        features.map(({ badgeTextColor, iconColor, ...f }, index) => {
          return (
            <Feature
              key={index}
              headlineColor={
                backgroundColor ? getColorScheme[backgroundColor].headlineModule : getColorScheme.black.headlineModule
              }
              textColor={backgroundColor ? getColorScheme[backgroundColor].text : getColorScheme.black.text}
              badgeBgColor={backgroundColor ? getColorScheme[backgroundColor].badgeBg : getColorScheme.black.badgeBg}
              iconBgColor={backgroundColor ? getColorScheme[backgroundColor].icon : getColorScheme.black.icon}
              iconBorderColor={
                backgroundColor ? getColorScheme[backgroundColor].iconBorder : getColorScheme.black.iconBorder
              }
              iconColor={theme.colors[iconColor]}
              badgeTextColor={theme.colors[badgeTextColor]}
              {...f}
            />
          )
        })}
    </SectionFeatures>
  )
}

export default SectionFeaturesWrapper

export const SectionFeatureFields = graphql`
  fragment SectionFeatureFields on WpPage_Layoutsettings_Layout_Features {
    # these fields are the same as on SectionFeaturesSplitWrapper. changing the one means you need to change the other
    featureType
    headline
    variant
    backgroundColor
    content
    sectionImage: featureImage {
      sourceUrl
      localFile {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    featureType
    features {
      badge
      badgeIcon: badgeicon
      featureContent
      headline
      icon
      iconColor: iconcolor
      badgeTextColor: badgecolor
    }
  }
`
