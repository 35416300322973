import React, { useCallback, useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import { DocumentType, FileType, useProfileUpdate } from '@rmr/controllers'
import { Error, DatePicker } from '@rmr/components'
import { Box } from 'rebass'
import * as yup from 'yup'
import Layout from '../Layout'
import { idRef, getFile } from '../helpers'
import Documents from '../Documents'
import { StepProps } from '../ApprovalToList'
import { languages } from '../../../common/i18n'

const schema = yup.object().shape({
  hasDriversLicence: yup.bool().oneOf([true], 'Please upload your drivers license'),
  driversLicenseFirstIssued: yup
    .string()
    .nullable()
    .when('hasDriversLicence', {
      is: true,
      then: yup.string().nullable().required('Required Field. Please enter a valid date'),
      otherwise: yup.string().nullable()
    })
})

const PersonalDocuments = ({ currentStep, previousStep, nextStep, step, steps, user }: StepProps) => {
  const defaultHasDriversLicence = useMemo(
    () => !!user?.documents.find(({ type }) => type === DocumentType.DriversLicense),
    [user?.documents]
  )
  const {
    setValue,
    setError,
    register,
    onSubmit,
    clearErrors,
    errors,
    setFiles,
    files,
    submitting: loadingUpdate
  } = useProfileUpdate({
    onSuccess: () => {
      nextStep()
    },
    defaultValues: {
      ...user,
      profile: { ...user.profile, hasDriversLicence: defaultHasDriversLicence }
    },
    schema
  })

  const validateFiles = useCallback(() => files.some((file) => file.type === DocumentType.DriversLicense), [files])

  const [date, setDate] = useState(
    user.profile.driversLicenseFirstIssued ? moment(user.profile.driversLicenseFirstIssued) : undefined
  )

  const onDateChange = useCallback(
    (year: number, month: number, day: number) => {
      if (day && month && year) {
        const date = moment().set({ year: year, month: month - 1, date: day })
        if (date.get('month') + 1 === month) {
          clearErrors('driversLicenseFirstIssued')
          setValue('driversLicenseFirstIssued', date)
        } else {
          setError('driversLicenseFirstIssued', { message: languages.en.documents.invalidDateError })
          setValue('driversLicenseFirstIssued', null)
        }
      } else if (date) {
        setDate(undefined)
        setValue('driversLicenseFirstIssued', null)
      }
    },
    [clearErrors, setValue, setError, date]
  )

  useEffect(() => {
    register('driversLicenseFirstIssued')
    register('hasDriversLicence')
    if (user.profile.driversLicenseFirstIssued) {
      setDate(moment(user.profile.driversLicenseFirstIssued))
    }
    setValue('hasDriverLicence', validateFiles())
  }, [register, setValue, user.profile.driversLicenseFirstIssued, validateFiles])
  return (
    <Layout
      badgeProps={{ variant: 'circle', size: [150], icon: step.icon }}
      {...step}
      currentStep={currentStep}
      previousStep={previousStep}
      buttonNextClick={onSubmit}
      idRef={idRef}
      steps={steps}
    >
      <Box maxWidth={[400]} mx="auto">
        <Box mb={3}>
          <Documents
            key={DocumentType.DriversLicense}
            idRef={idRef}
            user={user}
            title={"Driver's Licence "}
            file={getFile(files, DocumentType.DriversLicense)}
            onFileSet={(file) => {
              clearErrors('hasDriversLicence')
              setValue('hasDriversLicence', true)
              setFiles((files) => [...files, { type: DocumentType.DriversLicense, fileId: file.id }])
            }}
            onFileRemove={(fileId) => {
              setValue('hasDriversLicence', false)
              setValue('driversLicenseFirstIssued', null)
              setDate(null)
              setFiles((files) => {
                return files.filter((f) => f.fileId !== fileId)
              })
            }}
            fileType={FileType.Document}
          />
          {errors && errors.hasDriversLicence && (
            <Error sx={{ position: 'static' }}>{errors.hasDriversLicence.message}</Error>
          )}
        </Box>
        <Box>
          <DatePicker
            dateInit={date}
            dateLabel={languages.en.documents.dateLabel}
            yearPlaceholder={languages['en'].documents.dateYearPlaceholder}
            monthPlaceholder={languages['en'].documents.dateMonthPlaceholder}
            dayPlaceholder={languages['en'].documents.dateDayPlaceholder}
            onDateChange={onDateChange}
          />
          {errors?.driversLicenseFirstIssued?.message && (
            <Error sx={{ position: 'static', mt: -1 }}>{errors?.driversLicenseFirstIssued?.message}</Error>
          )}
        </Box>
      </Box>
    </Layout>
  )
}

export { PersonalDocuments }
