import { TextFontWeight } from './types'
import theme from '../../theme'

export const textSizes = {
  tiny: {
    fontSize: ['tinyMobile', 'tiny']
  },
  small: {
    fontSize: ['smallMobile', 'small']
  },
  body: {
    fontSize: ['bodyMobile', 'body'],
    lineHeight: 1.6
  },
  medium: {
    fontSize: ['mediumMobile', 'medium']
  },
  large: {
    fontSize: ['largeMobile', 'large'],
    letterSpacing: theme.letterSpacing.small
  }
}
export type TextSize = keyof typeof textSizes

export const textFontWeights: Array<keyof TextFontWeight> = [
  'light',
  'regular',
  'medium',
  'semiBold',
  'bold',
  'extraBold'
]
