import { Icon, SelectOptionExtended, Radio } from '@rmr/components'
import React, { useState, useRef } from 'react'
import { Box, SxStyleProp, BoxProps } from 'rebass'

import useOnClickOutside from '../../../hooks/useOnClickOutside'

import Text from '../../Text'
import FilterButton from '../FilterButton'
import FilterDropdown from '../FilterDropdown'
import { wrapperStyles, contentStyles } from './SingleFilter.styled'

export type SingleFilterAction = (value: string | number) => void

export interface SingleFilterProps extends BoxProps {
  /** Initial Value */
  initialValue?: string | number
  /** Filter Name */
  filterName: string
  /** Options to filter/sort from */
  options: SelectOptionExtended[]
  /** Callback with Selected option */
  onSet: SingleFilterAction
  /** Group Mode */
  groupMode?: boolean
  /** Radio Mode */
  radioMode?: boolean
  /** Sx prop styles */
  sx?: SxStyleProp
  /** Dropdown Sx prop styles */
  dropdownSx?: SxStyleProp
  /** Button Sx prop styles */
  buttonSx?: SxStyleProp
}

const SingleFilter = ({
  initialValue = null,
  filterName,
  options,
  onSet,
  buttonSx,
  groupMode,
  radioMode,
  dropdownSx,
  sx,
  ...props
}: SingleFilterProps) => {
  const [activeOption, setActiveOption] = useState<SelectOptionExtended>(options.find((e) => e.value === initialValue))
  const [open, setOpen] = useState(false)
  const filterRef = useRef(null)
  const buttonRef = useRef(null)
  useOnClickOutside(filterRef, () => open && setOpen(false))

  const selectOption = (option: SelectOptionExtended) => {
    setOpen(false)
    setActiveOption(option)
    onSet(option.value)
  }

  return (
    <Box ref={filterRef} sx={{ ...wrapperStyles, ...sx }} {...props}>
      {radioMode ? (
        <React.Fragment>
          <Text fontWeight="extraBold" mb={1}>
            {filterName}
          </Text>
          <Radio
            name={filterName}
            variant="horizontal"
            selectedOption={!!activeOption ? activeOption.value : 'null'}
            options={options.map((o) => ({ ...o, value: !!o.value ? o.value : 'null' }))}
            rebassRadioProps={{
              onChange: (e) => {
                let value = e.target.value
                if (value === 'null') value = null
                selectOption({ value, label: e.target.name })
              }
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FilterButton
            ref={buttonRef}
            filterName={filterName}
            open={open}
            activeLabel={!!activeOption ? `${activeOption.label}` : undefined}
            buttonSx={buttonSx}
            onClickButton={() => setOpen((open) => !open)}
            onClickCloseButton={(e) => {
              e.stopPropagation()
              setOpen(false)
              setActiveOption(null)
              onSet(null)
            }}
            groupMode={groupMode}
          />
          <FilterDropdown isOpen={open} sx={dropdownSx} buttonRef={buttonRef}>
            {options.map((option, index) => (
              <Text
                key={index}
                size="tiny"
                sx={contentStyles(activeOption && option.label === activeOption.label)}
                onClick={() => selectOption(option)}
              >
                {option.icon && (
                  <Icon name={option.icon} width={[50]} textAlign="center" fontAwesomeIconProps={{ size: '2x' }} />
                )}
                {option.label}
              </Text>
            ))}
          </FilterDropdown>
        </React.Fragment>
      )}
    </Box>
  )
}

SingleFilter.defaultProps = {
  filterName: '',
  options: [],
  groupMode: false,
  radioMode: false,
  sx: {},
  dropdownSx: {},
  buttonSx: {}
}

export default SingleFilter
