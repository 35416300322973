import { useCallback, useRef, useState } from 'react'

export type Paging = { first?: number; after?: string; last?: number; before?: string }

type TPagination = {
  page: number
  limit: number
  paging?: Paging
  setCursor: (cursors: { startCursor: string; endCursor: string }) => void
  nextPage: () => Paging
  prevPage: () => Paging
  reset: () => void
}

const resetPaging = (limit: number, initBefore?: string, initAfter?: string) => {
  return {
    first: initBefore ? undefined : limit,
    last: initBefore ? limit : undefined,
    before: initBefore,
    after: initAfter
  }
}

const useCursorPagination = (limit = 12, initPage = 1, initBefore?: string, initAfter?: string): TPagination => {
  const [page, setPage] = useState(initPage)
  const endCursorRef = useRef<string>()
  const startCursorRef = useRef<string>()
  const [paging, setPaging] = useState<Paging>(() => resetPaging(limit, initBefore, initAfter))

  const nextPage = () => {
    const paging = { first: limit, after: endCursorRef.current }
    setPage(page + 1)
    setPaging(paging)
    return paging
  }
  const prevPage = () => {
    const paging = { last: limit, before: startCursorRef.current }
    setPage(page - 1)
    setPaging(paging)
    return paging
  }
  const setCursorCb = ({ startCursor, endCursor }: { startCursor: string; endCursor: string }) => {
    endCursorRef.current = endCursor
    startCursorRef.current = startCursor
  }

  const reset = useCallback(() => {
    setPage(1)
    endCursorRef.current = null
    startCursorRef.current = null
    setPaging(resetPaging(limit))
  }, [setPage, setPaging, limit])

  return { page, limit, nextPage, prevPage, paging, setCursor: setCursorCb, reset }
}

export { useCursorPagination }
export type { TPagination }
