import React, { FC } from 'react'
import { SxStyleProp, Flex } from 'rebass'
import theme from '../../theme'
import Text from '../Text/Text'
import Icon from '../Icon/Icon'
import { IconType } from '../Icon/Icon.options'
import Heading from '../Heading/Heading'
import styled from '@emotion/styled'

interface StyledItemProps {
  isFirst: boolean
  isLast: boolean
  isMain: boolean
}

const StyledItem = styled(Flex)<StyledItemProps>`
  border-bottom-width: ${(props) => (props.isFirst ? '0px' : props.isLast ? '0px' : '0px')};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => (props.isFirst ? theme.colors.borderBase : theme.colors.borderLight)};
  border-top: ${(props) => (props.isMain ? `1px solid ${theme.colors.borderBase}` : 'none')};
  background-color: transparent;
  transition: all ${theme.speed.default};
  &:hover {
    background-color: ${theme.colors.bgPrimaryLight};
  }
`
const StyledIcon = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: ${theme.borderRadius.circle};
  border: 2px solid ${theme.colors.borderBase};
`

export interface ListItemProps {
  /** Title of the item */
  title?: string
  /** Icon to display */
  icon: IconType | string
  /** Set the Price of the item */
  price?: string
  /** Set the Item description to appear below the Title */
  description?: string
  /** Set supporting text to appear above the Price */
  priceEyebrow?: string
  /** Main item on the list */
  main?: boolean
  /** Is this the first Child in the list? */
  isFirst?: boolean
  /** Is this the last Child in the list? */
  isLast?: boolean
  /** Sx Props */
  sx?: SxStyleProp
}

const ListItem: FC<ListItemProps> = ({
  title,
  price,
  description,
  icon,
  priceEyebrow,
  isFirst,
  isLast,
  main,
  sx,
  ...props
}) => {
  return (
    <StyledItem
      isFirst={isFirst}
      isLast={isLast}
      isMain={main}
      width={[1]}
      flexWrap="wrap"
      flexDirection={['row']}
      alignItems="stretch"
      {...props}
      sx={sx}
      mb={[2]}
      pt={[1]}
    >
      <Flex width={[9 / 12, 8 / 12]} flexDirection={['row']}>
        <Flex width={[10 / 12]} flexDirection={['column']} justifyContent={['center']} px={[1]}>
          {title && (
            <Text
              size={main ? 'medium' : 'medium'}
              // size={isLast ? 'medium' : 'medium'}
              color={theme.colors.textDark}
              fontWeight={theme.fontWeights.bold}
              lineHeight={1}
              mb={['4px']}
              sx={{ fontSize: ['14px', main ? 'body' : 'small'] }}
            >
              {title}
            </Text>
          )}
          <Flex>
            {description && (
              <Text
                size={'tiny'}
                color={theme.colors.textGrayLight}
                lineHeight={1}
                mr={1}
                sx={{ fontSize: ['13px', 'small'] }}
              >
                {description}
              </Text>
            )}
            {priceEyebrow && (
              <Text
                size={'small'}
                color={theme.colors.textGrayLight}
                lineHeight={1}
                sx={{ fontSize: ['13px', 'small'] }}
              >
                {priceEyebrow}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width={[3 / 12, 4 / 12]}
        flexDirection={['column']}
        justifyContent={['center']}
        alignItems="flex-end"
        px={[0, 2]}
      >
        {price && (
          <Text
            // size={isLast ? 'body' : 'body'}
            color={main ? theme.colors.primary : theme.colors.baseBlack}
            sx={{ fontSize: ['small', main ? '16px' : '14px'] }}
          >
            {price}
          </Text>
        )}
      </Flex>
    </StyledItem>
  )
}

ListItem.defaultProps = {
  title: '',
  icon: '',
  price: null,
  description: '',
  priceEyebrow: '',
  isFirst: false,
  isLast: false,
  sx: {}
}

export default ListItem
