import { Loader } from '@rmr/components'
import { useAuth } from '@rmr/controllers'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { Box } from 'rebass'

import Main from '../components/Main'
import ReferAFriend from '../components/ReferAFriend'

const ReferAFriendPage = () => {
  const { isAuthed, loading } = useAuth()

  useEffect(() => {
    if (isAuthed) {
      navigate('/app/refer-a-friend/')
    }
  }, [isAuthed])
  return (
    <Main darkHeader noFooter path="/rent-a-car">
      {loading || isAuthed ? <Loader position="static" /> : <ReferAFriend />}
    </Main>
  )
}

export default ReferAFriendPage
