import styled from '@emotion/styled'
import {  Flex, Box } from 'rebass'

// Strip Styles
export const StyledContentStrip = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
`

// Poster Styles
export const StyledContentPoster = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
`
export const StyledSectionImagePoster = styled(Flex)`
  position: relative;
`

/* Vertical Styles */
export const StyledContentVertical = styled(Flex)`
  flex-direction: column;
`

export const StyledSectionImageVertical = styled(Box)`
  position: relative;
`

export const StyledHeadlineVertical = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`
