import { Container } from '@rmr/components'
import React from 'react'
import { Box } from 'rebass'
import { navigate } from '@reach/router'
import SearchBar from '../SearchBar'
import { getLink } from '../../utils/helpers'

const SearchBarWrapper = () => {
  return (
    <Container>
      <Box
        sx={{
          mt: [4],
          width: ['100%'],
          maxWidth: [1000],

          mx: 'auto',
          bg: 'bgWhite',
          borderRadius: '8px',
          px: [3, 4],
          py: [3]
        }}
      >
        <SearchBar
          place={{}}
          onHandleSubmit={(payload) => {
            navigate(getLink(payload))
          }}
        />
      </Box>
    </Container>
  )
}

export default SearchBarWrapper
