import React, { FC } from 'react'
import { Box, BoxProps, Link as RebassLink } from 'rebass'
import VehicleCard, { VehicleCardProps } from '../VehicleCard'
import Carousel from '../Carousel'
import { carouselStyles } from './VehicleCardCarousel.styled'

export interface Car extends VehicleCardProps {
  id?: string
  href?: string
}

export interface VehicleCardCarouselProps extends BoxProps {
  /** Array of Vehicle Cards */
  cars: Car[]
}

const VehicleCardCarousel: FC<VehicleCardCarouselProps> = ({ cars, ...props }) => {
  return (
    <Box {...props} sx={carouselStyles}>
      <Carousel variant="vehiclesCarousel">
        {cars.map(({ id, href, ...rest }, index) => (
          <React.Fragment key={id}>
            {href ? (
              <RebassLink href={href} target="_blank" sx={{ height: '100%' }}>
                <VehicleCard {...rest} sx={{ height: '100%' }} />
              </RebassLink>
            ) : (
              <Box>
                <VehicleCard {...rest} />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Carousel>
    </Box>
  )
}
VehicleCardCarousel.defaultProps = {
  cars: []
}

export default VehicleCardCarousel
