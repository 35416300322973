import React, { FC } from 'react'
import { Container } from '../Grid'
import Heading from '../Heading'
import { StyledContentVertical, StyledSectionImageVertical, StyledHeadlineVertical } from './SectionFeatures.styled'
import theme from '../../theme'
import { SectionFeaturesTypes } from './SectionFeatures'
import { Box } from 'rebass'
import Content from '../Content'
import parse from 'html-react-parser'

const SectionFeaturesVertical: FC<SectionFeaturesTypes> = ({
  headline,
  headlineColor,
  bgColor,
  children,
  content,
  sectionImage,
  sx,
  ...props
}) => {
  return (
    <Box {...props} bg={bgColor} pb={[4, 6]} sx={{ ...sx }}>
      {sectionImage && (
        <StyledSectionImageVertical width={['100%']} height={['20vh', '30vh', '50vh']}>
          {sectionImage}
          <StyledHeadlineVertical>
            {headline && (
              <Heading
                size="h4"
                fontWeight={theme.fontWeights.bold}
                textAlign="center"
                color={headlineColor}
                mb={[2, 4]}
              >
                {headline}
              </Heading>
            )}
          </StyledHeadlineVertical>
        </StyledSectionImageVertical>
      )}
      <StyledContentVertical className="StyledContentVertical" pt={[2]} px={[2, 8, 10]}>
        <Container>{children && children}</Container>
        {content && (
          <Box>
            <Content>{parse(content as string)}</Content>
          </Box>
        )}
      </StyledContentVertical>
    </Box>
  )
}

SectionFeaturesVertical.defaultProps = {
  headline: '',
  headlineColor: 'textDark',
  bgColor: 'white',
  variant: 'featuresVertical',
  content: '',
  sx: {}
}

export default SectionFeaturesVertical
