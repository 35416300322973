import Linkify from '../Linkify'
import React from 'react'

import { Box, BoxProps, SxStyleProp } from 'rebass'

interface ProcessedTextProps extends BoxProps {
  /** Text to be processed */
  text: string
  /** Array of domains that will be processed */
  whitelist?: string[]
  /** sx props */
  sx: SxStyleProp
}

const ProcessedText = ({ text, whitelist, sx, ...props }: ProcessedTextProps) => {
  const textArray = text.split('\n')
  const noBlankLinesText = textArray.filter((line) => line).join('\n')
  return (
    <Box as="p" sx={{ whiteSpace: 'pre-wrap', ...sx }} {...props}>
      <Linkify text={noBlankLinesText} whitelist={whitelist} />
    </Box>
  )
}

ProcessedText.defaultProps = {
  text: '',
  whitelist: undefined,
  sx: {}
}

export default ProcessedText
