import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import Text from '../Text'
import theme from '../../theme'
import Content from '../Content'
import parse from 'html-react-parser'
import Icon from '../Icon/Icon'
import { FeatureIconTypes } from './types'

const StyledFeatureIconStack = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`

const StyledIcon = styled(Icon)`
  position: relative;
  svg {
    width: 100% !important;
    height: auto;
  }
`

const StyledFeatureContent = styled(Box)`
  display: block;
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.medium};
`

export const FeatureIconStack: FC<FeatureIconTypes> = ({
  headline,
  featureContent,
  icon,
  textColor,
  iconColor,
  headlineColor,
  sx,
  ...props
}) => {
  return (
    <StyledFeatureIconStack width={['100%']} px={[1, 2]} mb={[4, 4, 0]} {...props} sx={{ ...sx }}>
      {icon && (
        <StyledIcon
          className="StyledIcon"
          mb={[1, 2]}
          width={['3rem']}
          name={icon}
          fontAwesomeIconProps={{ color: iconColor }}
        />
      )}

      {headline && (
        <Text mb={[1, 2]} size="body" fontWeight={theme.fontWeights.extraBold} color={headlineColor}>
          {headline}
        </Text>
      )}
      {featureContent && (
        <StyledFeatureContent color={textColor}>
          <Content>{parse(featureContent)}</Content>
        </StyledFeatureContent>
      )}
    </StyledFeatureIconStack>
  )
}

export default FeatureIconStack
