import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useUpdateReservationDatesMutation, UpdateReservationDatesMutation } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'
import { successMessages, validationMessages } from '../i18n'

export interface useUpdateReservationDatesFormData {
  startDate: string
  endDate: string
}

interface UseUpdateReservationDatesArgs {
  reservationId: string
  onSuccess?: (response: UpdateReservationDatesMutation) => void
  onError?: (e: Error) => void
}

const updateReservationDatesFormSchema = yup.object().shape({
  startDate: yup.string().required(validationMessages.en.required),
  endDate: yup.string().required(validationMessages.en.required)
})

export function useUpdateReservationDates({ reservationId, onSuccess, onError }: UseUpdateReservationDatesArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError, resetSubmitResult] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(updateReservationDatesFormSchema)
  })

  const [updateReservationDates, { loading }] = useUpdateReservationDatesMutation()

  const onSubmit = useCallback(
    async (data: useUpdateReservationDatesFormData) => {
      const { startDate, endDate } = data

      try {
        const response = await updateReservationDates({
          variables: {
            reservationId,
            input: {
              startDate: startDate,
              endDate: endDate
            }
          }
        })
        onSuccess && onSuccess(response.data)
        const { updateReservationDates: updateReservationDatesResult } = response.data

        if (updateReservationDatesResult.__typename === 'Reservation') {
          onSubmitSuccess(successMessages.en.updateReservationDates)
        } else {
          // @ts-ignore
          onSubmitError(null, updateReservationDatesResult.message)
        }
      } catch (err) {
        console.log(err)
        onError && onError(err)
        onSubmitError()
      }
    },
    [updateReservationDates, onSuccess, onError, reservationId, onSubmitError, onSubmitSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult,
    resetSubmitResult
  }
}
