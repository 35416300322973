import React from 'react'
import { Box } from 'rebass'

const Column = (props) => <Box {...props} />

Column.defaultProps = {
  px: '15px',
  flex: '0 1 auto'
}

export default Column
