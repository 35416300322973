import React, { FC } from 'react'
import { Box } from 'rebass'
import Heading from '../Heading'
import Text from '../Text'
import {
  StyledBannerSplit,
  StyledContentSplit,
  StyledImageSplit,
  StyledHeaderSplit,
  StyledHeaderLine
} from './Banner.styled'
import { BannerProps } from './Banner'
import theme from '../../theme'

const BannerSplit: FC<BannerProps> = ({
  variant,
  headline,
  subtitle,
  image,
  children,
  imagePosition,
  colorScheme,
  headlineColor,
  bgColor,
  subtitleColor,
  textColor,
  sx,
  ...props
}) => {
  const imageOrder = (position) => {
    switch (position) {
      case 'left':
        return 0
      case 'right':
        return 1
      default:
        return 0
    }
  }

  return (
    <StyledBannerSplit {...props} bg={bgColor} flexDirection={['column', 'column', 'row']} sx={{ ...sx }}>
      <StyledImageSplit
        order={[0, 0, imageOrder(imagePosition)]}
        width={['100%', '100%', '50%']}
        height={['400px', '600px', 'auto']}
      >
        {image}
      </StyledImageSplit>
      <StyledContentSplit px={[4, 6]} py={[4, 6]} width={['100%', '100%', '50%']}>
        <Box>
          {subtitle && (
            <Text textAlign="left" color={subtitleColor}>
              {subtitle}
            </Text>
          )}
          {headline && (
            <StyledHeaderSplit mb={[3, 6]}>
              <Heading size="h3" fontWeight={theme.fontWeights.extraBold} textAlign="left" color={headlineColor}>
                {headline}
              </Heading>
              <StyledHeaderLine mt={[1, 3]} />
            </StyledHeaderSplit>
          )}
          {children && <Box color={textColor}>{children}</Box>}
        </Box>
      </StyledContentSplit>
    </StyledBannerSplit>
  )
}

BannerSplit.defaultProps = {
  variant: 'center',
  headline: '',
  subtitle: '',
  image: null,
  children: null,
  imagePosition: 'left',
  sx: {}
}

export default BannerSplit
