// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-refer-a-friend-tsx": () => import("./../../../src/pages/refer-a-friend.tsx" /* webpackChunkName: "component---src-pages-refer-a-friend-tsx" */),
  "component---src-pages-rent-a-car-tsx": () => import("./../../../src/pages/rent-a-car.tsx" /* webpackChunkName: "component---src-pages-rent-a-car-tsx" */),
  "component---src-templates-wp-page-template-tsx": () => import("./../../../src/templates/wp-page-template.tsx" /* webpackChunkName: "component---src-templates-wp-page-template-tsx" */),
  "component---src-templates-wp-post-template-tsx": () => import("./../../../src/templates/wp-post-template.tsx" /* webpackChunkName: "component---src-templates-wp-post-template-tsx" */),
  "component---src-templates-wp-posts-template-tsx": () => import("./../../../src/templates/wp-posts-template.tsx" /* webpackChunkName: "component---src-templates-wp-posts-template-tsx" */)
}

