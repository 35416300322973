import { HeadingFontWeight } from './types'

export const headingSizes = {
  h1: {
    fontSize: ['h1Mobile', 'h1'],
    lineHeight: '1.2em'
  },
  h2: {
    fontSize: ['h2Mobile', 'h2'],
    lineHeight: '1.35em'
  },
  h3: {
    fontSize: ['h3Mobile', 'h3'],
    lineHeight: '1.35em'
  },
  h4: {
    fontSize: ['h4Mobile', 'h4'],
    lineHeight: '1.35em'
  },
  h5: {
    fontSize: ['h5Mobile', 'h5'],
    lineHeight: '1.35em'
  },
  h6: {
    fontSize: ['h6Mobile', 'h6'],
    lineHeight: '1.35em'
  }
}

export type HeadingSizes = typeof headingSizes
type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
export const headingTags: Array<HeadingTag> = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
export const headingFontWeights: Array<keyof HeadingFontWeight> = [
  'light',
  'regular',
  'medium',
  'semiBold',
  'bold',
  'extraBold'
]
