import React, { FC } from 'react'
import { Box, SxStyleProp, Flex } from 'rebass'
import Skeleton from 'react-loading-skeleton'
import theme from '../../theme'
import Text from '../Text/Text'
import StarRating from '../StarRating/StarRating'
import Heading from '../Heading'
import { StyledImage, StyledImageBorder } from './ProfileSummary.styled'
import Separator from '../Separator'

export interface ProfileSummaryProps {
  /** Headline of Profile Summary */
  headline?: string
  /** Profile image url */
  profileImageUrl?: string
  /** Name of the Profile */
  name?: string
  /** City Name of Profile owner */
  locationCity?: string
  /** Province Name of Profile owner */
  locationProvince?: string
  /** Country Name of Profile owner */
  locationCountry?: string
  /** Date when Owner joined */
  dateJoined?: string
  /** Value of Ratings */
  rating?: number
  /** Sx Props */
  sx?: SxStyleProp
  /** Component is loading */
  loading?: boolean
}

const ProfileSummary: FC<ProfileSummaryProps> = ({
  headline,
  name,
  locationCity,
  locationProvince,
  locationCountry,
  dateJoined,
  rating,
  profileImageUrl,
  sx,
  loading,
  ...props
}) => {
  return (
    <Box {...props} sx={{ ...sx }}>
      <Flex alignItems={['center']} justifyContent={['center']} flexDirection={['column']}>
        <Text
          as="span"
          textAlign={'center'}
          mb={[1, null, 2]}
          ml={[2, null, 0]}
          size={'medium'}
          color={theme.colors.textGrayLight}
          lineHeight={1}
        >
          {!loading ? headline : <Skeleton style={{ width: '100px' }} />}
        </Text>
        <Flex flexDirection={['row', null, 'column']} alignItems={['center']}>
          {(loading || profileImageUrl) && (
            <StyledImageBorder
              mb={[1, null, 2]}
              mr={[2, null, 0]}
              width={['calc(6rem + 8px)', null, '8rem']}
              height={['calc(6rem + 8px)', null, '8rem']}
              sx={{
                flex: '1 0 auto',
                borderWidth: name ? ['4px', null, '8px'] : 0,
                boxShadow: name ? `0 6px 30px hsl(0deg 0% 39% / 20%)` : 'none'
              }}
            >
              <StyledImage
                width={['6rem', null, name ? '7rem' : '8rem']}
                height={['6rem', null, name ? '7rem' : '8rem']}
                sx={{
                  borderWidth: name ? ['4px', null, '8px'] : 0
                }}
              >
                {profileImageUrl ? (
                  <img src={profileImageUrl} alt={'vista'} />
                ) : loading ? (
                  <Skeleton style={{ display: 'block', width: '100%', height: '100%' }} />
                ) : null}
              </StyledImage>
            </StyledImageBorder>
          )}
          <Flex flexDirection="column" alignItems={['flex-start', null, 'center']}>
            <Heading
              mb={[1]}
              size={'h6'}
              color={theme.colors.primary}
              lineHeight={1}
              fontWeight={theme.fontWeights.bold}
              textAlign={'center'}
            >
              {!loading ? name : <Skeleton style={{ width: '120px' }} />}
            </Heading>
            <Text sx={{ fontSize: ['14px', '16px'] }} lineHeight={1.3} textAlign={[null, 'center']}>
              {!loading ? locationCity : <Skeleton style={{ width: '120px' }} />}
            </Text>
            <Text sx={{ fontSize: ['14px', '16px'] }} lineHeight={1.3} textAlign={[null, 'center']}>
              {!loading ? locationProvince : <Skeleton style={{ width: '120px' }} />}
            </Text>
            <Text sx={{ fontSize: ['14px', '16px'] }} lineHeight={1.3} textAlign={[null, 'center']}>
              {!loading ? locationCountry : <Skeleton style={{ width: '120px' }} />}
            </Text>
            {!loading ? (
              <Separator sx={{ height: [0, null, '2px'], my: ['2px', null, 3] }} bg={theme.colors.borderLight} />
            ) : (
              <Box sx={{ height: [0, null, '2px'], my: ['2px', null, 3] }}>
                <Skeleton style={{ width: '60px', height: '100%' }} />
              </Box>
            )}
            <Text textAlign={'center'} sx={{ fontSize: ['13px', '14px'] }} lineHeight={1} mt={[1, 0]}>
              {!loading ? `Joined ${dateJoined}` : <Skeleton style={{ width: '100px' }} />}
            </Text>
            {!loading ? (
              <Separator sx={{ height: [0, null, '2px'], my: [0, null, 3] }} bg={theme.colors.borderLight} />
            ) : (
              <Box sx={{ height: [0, null, '2px'], my: [0, null, 3] }}>
                <Skeleton style={{ width: '60px', height: '100%' }} />
              </Box>
            )}
            <Flex alignItems="center" mb={[1]}>
              <Text
                as="span"
                size={'medium'}
                color={theme.colors.textGrayLight}
                fontWeight={theme.fontWeights.semiBold}
                lineHeight={1}
              >
                {rating}
              </Text>

              {rating > 0 ? (
                <StarRating
                  ml={[1]}
                  activeColor={theme.colors.statusRating}
                  color={theme.colors.bgGrayLight}
                  size={14}
                  value={rating}
                  edit={false}
                />
              ) : loading ? (
                <Skeleton style={{ width: '100px', height: '100%' }} />
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

ProfileSummary.defaultProps = {
  headline: '',
  name: '',
  locationCity: '',
  locationProvince: '',
  locationCountry: '',
  dateJoined: '',
  rating: null,
  profileImageUrl: '',
  loading: false
}

export default ProfileSummary
