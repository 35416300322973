export const statusColors = {
  statusSuccess: '#73BA27',
  statusError: '#DD0C21',
  statusWarning: '#F39423',
  statusInfo: '#357DD6',
  statusRating: '#FFA61C'
}

const colors = {
  // Primary Brand Colors
  primary: '#F47735',
  primaryLight: '#F4990C',

  // Base
  baseModal: 'rgba(0, 0, 0, 0.6)',
  baseWhite: '#FFFFFF',
  baseBlack: '#000000',
  baseTransparent: 'transparent',
  baseWhiteTransparent: 'rgba(255, 255, 255, 0.8)',

  // Border
  borderBase: 'rgba(189, 189, 189,0.75)',
  borderInput: '#BDBDBD',
  borderLight: '#D9D9D9',
  borderDark: '#858688',
  borderWhite: '#ffffff',

  // Text
  textBlack: '#0B0C0D',
  textWhite: '#ffffff',
  textGray: '#494949',
  textGrayLight: '#BABCC7',
  textDark: '#494949',
  textMuted: '#747371',
  textLight: '#808080',
  textBlue: '#5A8FC5',

  //Background
  bgBlack: '#0B0C0D',
  bgDark: '#2A2C30',
  bgGrayDark: '#767574',
  bgGrayLight: '#BABCC7',
  bgLight: '#F1F1F1',
  bgPrimaryLight: '#FDFAF6',
  bgWhite: '#FFFFFF',
  bgShadow: '#c9c9c9',
  bgGreenLight: '#E0F2CC',

  // Brand
  // brandOrange: '#F47735',
  // brandRed: '#DD7A85',
  // brandPurple: '#922E79',
  // brandGreen: '#00A79D',
  // brandNavy: '#050F29',
  // brandNavyLight: '#101F46',
  // brandNavyBright: '#172956',
  // brandBlueBayoux: '#4B6282',
  // brandMirage: '#181F28',
  // brandEbonyClay: '#1F2A37',
  // brandEbonyClayLight: '#232F3D',
  // brandCadetBlue: '#ABADC2',
  // brandFiord: '#445775',
  // brandLynch: '#6E809B',

  // Status
  ...statusColors
}

export default colors
