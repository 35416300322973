import React, { FC } from 'react'
import { Container } from '../Grid'
import Heading from '../Heading'
import theme from '../../theme'
import { SectionFeaturesTypes } from './SectionFeatures'
import { Box, Flex, FlexProps } from 'rebass'
import styled from '@emotion/styled'

interface OverlayProps extends FlexProps {
  sectionImage: string
}

const StyledOverlay = styled(Flex)<OverlayProps>`
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &::before {
    content: '';
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), ${theme.colors.bgBlack}),
      url(${(props) => props.sectionImage});
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
    position: absolute;
    width: 80%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    @media (max-width: ${theme.breakpoints[2]}) {
      width: 100%;
      /* background-image: none; */
      background-position: center center;
      height: 300px;
    }
  }
  &::after {
    content: '';
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), ${theme.colors.bgBlack} 99%, ${theme.colors.bgBlack} 0);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    @media (max-width: ${theme.breakpoints[2]}) {
      height: 0;
    }
  }
`

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 3;
`

const SectionFeaturesOverlay: FC<SectionFeaturesTypes> = ({
  headline,
  headlineColor,
  sectionImage,
  bgColor,
  children,
  sx,
  content,
  ...props
}) => {
  return (
    <StyledOverlay
      className="StyledOverlay"
      {...props}
      bg={bgColor}
      sectionImage={sectionImage as string}
      pt={['300px', '300px', '300px', 10]}
      pb={[2, 6]}
      sx={{ ...sx }}
    >
      <StyledContainer>
        <Flex flexDirection={['column']} width={['100%', '100%', '100%', '50%']} ml={[0, 'auto']}>
          {headline && (
            <Heading
              size="h5"
              fontWeight={theme.fontWeights.extraBold}
              textAlign={['center', 'center', 'center', 'left']}
              color={headlineColor}
              mb={[2, 4]}
            >
              {headline}
            </Heading>
          )}
          <Flex flexDirection={['column', 'column', 'row']}>
            {children && (
              <Box mb={[2]} width={['100%']}>
                {children}
              </Box>
            )}
          </Flex>
        </Flex>
      </StyledContainer>
    </StyledOverlay>
  )
}

SectionFeaturesOverlay.defaultProps = {
  headline: '',
  headlineColor: 'textDark',
  bgColor: 'white',
  variant: 'featuresPoster',
  sx: {}
}

export default SectionFeaturesOverlay
