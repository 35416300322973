import { SxStyleProp } from 'rebass'

export const sectionStyles = {
  py: 5,
  bg: 'bgBlack'
}
export const containerStyles = {
  flexDirection: 'column',
  alignItems: 'center'
}

export const logoWrapperStyles = {
  mb: 2
}

export const logoStyles = {}

export const navigationWrapperStyles = {
  flexDirection: ['column', 'row'],
  alignItems: 'center',
  justifyContent: 'center'
}

export const itemStyles = {
  fontSize: 'tiny',
  color: 'textLight',
  px: [0, 2],
  '&:not(:last-child)': {
    borderRightWidth: [0, '1px'],
    borderRightStyle: 'solid',
    borderRightColor: 'baseWhite',
    mb: [2, 0]
  }
}

export const linkStyles = {
  ...itemStyles,
  textDecoration: 'none',
  transition: 'color 0.2s',
  ':hover': {
    color: 'primary'
  }
}
