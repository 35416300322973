import { SxStyleProp } from 'rebass'

export const wrapperStyles: SxStyleProp = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: [1],
  cursor: 'pointer'
}

export const showLinkWrapperStyles: SxStyleProp = {
  position: 'relative',
  cursor: 'pointer',
  maxWidth: 400
}

export const copiedStyles: SxStyleProp = {
  position: 'absolute',
  bottom: 'calc(100% + 5px)',
  left: '50%',
  transform: 'translateX(-50%)'
}

export const showLinkCopiedStyles: SxStyleProp = {
  position: 'absolute',
  top: 'calc(100% - 10px)',
  right: 0
}
