import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  useCreateReviewMutation,
  ReviewResult,
  Category,
  ReviewCategoriesInput,
  CreateReviewInput
} from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'

interface CategoryReviewFormData {
  [reviewCategory: string]: string
}

export interface ReviewReservationFormData extends CategoryReviewFormData {
  privateComment: string
  publicComment: string
}

interface UseReviewReservationArgs {
  reservationId: string
  onSuccess?: (response: ReviewResult) => void
  reviewCategories: Category[]
}

export function useReviewReservation({ reservationId, onSuccess, reviewCategories }: UseReviewReservationArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const reviewCategoriesFormSchema = useMemo(
    () =>
      reviewCategories.reduce((acc, cur) => {
        acc[cur.name] = yup
          .number()
          .nullable()
          .required('Required Field')
          .test(cur.name, 'Required Field', (value) => value > 0)
        return acc
      }, {}),
    [reviewCategories]
  )
  const reviewCategoriesDefaultValues = useMemo(
    () =>
      reviewCategories.reduce((acc, cur) => {
        acc[cur.name] = null
        return acc
      }, {}),
    [reviewCategories]
  )

  const reviewReservationFormSchema = useMemo(
    () =>
      yup.object().shape({
        publicComment: yup.string().required('Required Field'),
        privateComment: yup.string(),
        ...reviewCategoriesFormSchema
      }),
    [reviewCategoriesFormSchema]
  )

  const form = useForm({
    resolver: yupResolver(reviewReservationFormSchema),
    defaultValues: {
      publicComment: '',
      privateComment: '',
      ...reviewCategoriesDefaultValues
    }
  })

  const [createReview, { loading: loadingCreateReview }] = useCreateReviewMutation()

  const onSubmit = useCallback(
    async (data: ReviewReservationFormData) => {
      const { publicComment, privateComment, ...categoriesData } = data

      const categories: ReviewCategoriesInput[] = Object.keys(categoriesData).map((category) => ({
        name: category,
        rating: +categoriesData[category],
        comments: ' '
      }))
      try {
        const input: CreateReviewInput = {
          privateComment,
          publicComment,
          categories
        }

        const variables = {
          reservationId,
          input
        }

        const response = await createReview({
          variables
        })

        const { createReview: reviewResult } = response.data

        onSuccess && onSuccess(reviewResult as ReviewResult)

        if (reviewResult.__typename === 'Review') {
          onSubmitSuccess()
        } else if (reviewResult.__typename === 'ReviewTimeExpiredError') {
          onSubmitError(null, reviewResult.message)
        } else {
          onSubmitError()
        }
      } catch (err) {
        console.log(err)
        onSubmitError(null, err.message)
      }
    },
    [createReview, onSuccess, reservationId, onSubmitError, onSubmitSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loadingCreateReview,
    submitResult
  }
}
