import React from 'react'
import { Box, BoxProps } from 'rebass'

import { LinkProps } from '../Link'
import { MenuItemType } from '../MenuItem'
import FooterNavigation from './FooterNavigation'
import CopyrightSection from './CopyrightSection'

export interface FooterProps extends BoxProps {
  /** Menu Items on FooterNavigation Section*/
  menuItems: MenuItemType[]
  /** Home Link */
  homeLink: string
  /** Menu Items on Copyright Section*/
  legalMenuItems: MenuItemType[]
}

const Footer = ({ menuItems, homeLink, legalMenuItems, ...props }: FooterProps) => {
  return (
    <Box as="footer" {...props}>
      <FooterNavigation className="FooterNavigation" menuItems={menuItems} />
      <CopyrightSection homeLink={homeLink} menuItems={legalMenuItems} />
    </Box>
  )
}

export default Footer

Footer.defaultProps = {
  menuItems: [],
  homelink: {
    href: '/'
  },
  legalMenuItems: []
}
