import React, { FC } from 'react'
import { Box, Flex, SxStyleProp } from 'rebass'
import { RouteComponentProps, navigate } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import StepWizard from 'react-step-wizard'
import { Container, stepsWrapperStyles } from '@rmr/components'

import { wrapperStyles, imageStyles, sectionStyles, stepsWrapper } from './Signup'
import ResetForm from './ResetForm'
import Success from './Success'
import { languages } from '../common/i18n'
import SEO from './Seo'

interface ResetProps extends RouteComponentProps {
  resetToken?: string
}

const Reset: FC<ResetProps> = ({ resetToken }) => {
  const { heroImage } = useStaticQuery<GatsbyTypes.ResetQuery>(graphql`
    query Reset {
      heroImage: file(relativePath: { eq: "bg-image6.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <Box sx={{ position: 'relative', overflow: 'auto' }}>
      <SEO
        title="Reset password | RentMyRide"
        description="It's like a car rental, just better."
        path="/app/approval-to-list"
      />
      <Box as="section" sx={sectionStyles}>
        {/* <Box sx={imageStyles}>
          <GatsbyImage
            image={heroImage.childImageSharp.gatsbyImageData}
            alt="hero image"
            objectFit="cover"
            style={{ height: '100%' }}
          />
        </Box> */}
        <Container>
          <Flex sx={wrapperStyles}>
            <Box sx={{ ...stepsWrapperStyles, ...stepsWrapper }}>
              <StepWizard>
                <ResetForm resetToken={resetToken} />
                <Success
                  headline={languages.en.auth.reset.successHeadline}
                  text={languages.en.auth.reset.successText}
                  buttonText={languages.en.auth.reset.loginButtonText}
                  onClick={() => navigate('/app/login/')}
                />
              </StepWizard>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default Reset
