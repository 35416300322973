import { SxStyleProp } from 'rebass'

export const sectionStyles: SxStyleProp = {
  py: [15]
}

export const stepsWrapperStyles: SxStyleProp = {
  width: ['100%', null, 700, 900, 1100],
  mx: 'auto'
}

export const wrapperStyles: SxStyleProp = {
  flexDirection: 'column',
  alignItems: 'center',
  px: [2, 0],
  pt: [4],
  pb: [3]
}

export const stepsWizzardStyles: SxStyleProp = {
  alignSelf: 'stretch',
  mb: [8],
  px: [3]
}
