import { GtmEvent } from './constants'

declare global {
  interface Window {
    dataLayer: any
  }
}

interface EventData {
  [key: string]: string | number
}

interface logGtmEventArgs {
  eventName: GtmEvent
  data?: EventData
}

export const logGtmEvent = ({ eventName, data = {} }: logGtmEventArgs) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: eventName,
      ...data
    })
  }
}
