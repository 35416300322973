import { WizardStep } from '@rmr/components'
import { VehicleDocument, VehicleInformationData } from '@rmr/controllers'
import React, { useRef } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'

import { languages } from '../../../common/i18n'
import Layout from '../Layout'
import VehicleInformationForm from './VehicleInformationForm'

interface VehicleInformationProps extends Partial<StepWizardChildProps> {
  idRef: string
  vehicleid: string
  vehicleInformation: VehicleInformationData
  existingDocuments: VehicleDocument[]
  wizardSteps: WizardStep[]
}
const VehicleInformation = ({
  idRef,
  currentStep,
  goToNamedStep,
  nextStep,
  previousStep,
  vehicleid,
  vehicleInformation,
  existingDocuments,
  wizardSteps
}: VehicleInformationProps) => {
  const formButtonRef = useRef<HTMLButtonElement | null>()
  const handleClick = () => {
    if (formButtonRef) {
      formButtonRef.current.click()
    }
  }
  return (
    <Layout
      headline={languages['en'].listYourCar.vehicleInformation.headline}
      currentStep={currentStep - 1}
      previousStep={previousStep}
      buttonNextClick={handleClick}
      idRef={idRef}
      wizardSteps={wizardSteps}
      goToNamedStep={goToNamedStep}
    >
      <VehicleInformationForm
        ref={formButtonRef}
        idRef={idRef}
        vehicleid={vehicleid}
        vehicleInformation={vehicleInformation}
        existingDocuments={existingDocuments}
        nextStep={nextStep}
      />
    </Layout>
  )
}

export default VehicleInformation
