import React from 'react'
import { VehicleImage } from '@rmr/controllers'
import { ImageCard } from '@rmr/components'
import { Box, Image } from 'rebass'
import { wrapperStyles } from './DisplayImages.styled'
import { Buttons } from '../UploadImages'

export type UploadedFile = {
  position: string
  file: FileExtended
  fileId: string
  positionLabel: string
}

export type UploadedFiles = {
  removeFile: (index: number) => void
  files: Array<UploadedFile>
}

interface DisplayImagesProps {
  buttons: Buttons
  uploadedFiles: UploadedFiles
  existingImages: VehicleImage[]
}

const DisplayImages = ({ uploadedFiles }: DisplayImagesProps) => {
  return (
    <Box sx={wrapperStyles}>
      {uploadedFiles.files.map(({ file, positionLabel }, index) => {
        const src = file?.preview || URL.createObjectURL(file)
        return (
          <Box key={index}>
            <ImageCard
              image={<Image src={src} alt={file.name} />}
              onClose={() => uploadedFiles.removeFile(index)}
              label={positionLabel}
              onLoad={() => URL.revokeObjectURL(src)}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default DisplayImages
