import React from 'react'
import { Notice, Button, Link } from '@rmr/components'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Flex } from 'rebass'

function NoticeWrapper() {
  const { wp } = useStaticQuery<GatsbyTypes.NoticeQuery>(graphql`
    query Notice {
      wp {
        themeGeneralSettings {
          noticeOptions {
            headline
            description
            isActive: isactive
            canClose: canclose
            actions {
              actionType
              url {
                target
                title
                url
              }
            }
          }
        }
      }
    }
  `)
  if (!wp.themeGeneralSettings.noticeOptions.isActive) return null
  return (
    <Notice {...wp.themeGeneralSettings.noticeOptions}>
      {wp.themeGeneralSettings.noticeOptions.actions && (
        <Flex alignItems="center" justifyContent="center">
          {wp.themeGeneralSettings.noticeOptions.actions.map((a, index) => {
            return (
              a.url && (
                <Box key={index}>
                  <Link to={a.url?.url}>
                    <Button type="button" variant={a.actionType}>
                      {a.url && a.url.title}
                    </Button>
                  </Link>
                </Box>
              )
            )
          })}
        </Flex>
      )}
    </Notice>
  )
}

export default NoticeWrapper
