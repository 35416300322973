import React from 'react'
import { Box, BoxProps, SxStyleProp } from 'rebass'

const formGroupStyles: SxStyleProp = {
  mb: [1, 2],
  position: 'relative'
}

interface FormGroupProps extends BoxProps {
  children: React.ReactNode
  /**
   * Sx prop
   */
  sx?: SxStyleProp
}

const FormGroup = ({ children, sx, ...props }: FormGroupProps) => (
  <Box sx={{ ...formGroupStyles, ...sx }} {...props}>
    {children}
  </Box>
)

export default FormGroup

FormGroup.defaultProps = {
  children: null,
  sx: {}
}
