import axios from 'axios'
import { FileType } from '../types'
import { getAuthToken } from '../helpers/authenticate'
import { FileEntity } from '..'
import { resizeImageFile } from '@rmr/helpers'

export const fileUploader = async (file: File, fileType: FileType.DOCUMENT | FileType.IMAGE): Promise<FileEntity> => {
  const apiUrl = `${process.env.NX_REMOTE_URL || process.env.GATSBY_NX_REMOTE_URL}/files/upload`
  try {
    const jwtToken = getAuthToken()
    const data = new FormData()
    const headers = {
      Authorization: jwtToken.token ? `Bearer ${jwtToken.token}` : '',
      'Content-Type': 'multipart/form-data'
    }
    let res
    switch (fileType) {
      case FileType.DOCUMENT:
        data.append('file', file)
        res = await axios.post(`${apiUrl}/document`, data, { headers })
        break
      case FileType.IMAGE:
        const resizedFile = (await resizeImageFile({ file })) as File
        data.append('file', resizedFile)
        res = await axios.post(`${apiUrl}/image`, data, { headers })
        break
      default:
        throw new Error('Please Provide a valid type')
    }
    return res?.data
  } catch (error) {
    console.log({ error })
    throw error
  }
}
