import { SxStyleProp } from 'rebass'

export const wrapperStyles: SxStyleProp = {
  width: ['100%'],
  display: 'grid',
  gridTemplateColumns: ['repeat(1, 1fr)', null, 'repeat(2, 1fr)'],
  columnGap: [2, 2, 2, 2, 4],
  rowGap: [2]
}

export const fullWidthField: SxStyleProp = {
  gridColumn: [null, null, 'span 2']
}
