import React, { FC, useEffect } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { Box } from 'rebass'
import { useForgotPassword } from '@rmr/controllers'
import { Heading, Button, Input, FormFooter, FormMessage, MessageColor, Loader } from '@rmr/components'
import { languages } from '../../common/i18n'
import { processTrimLowerCase } from '@rmr/helpers'
import { fieldStyles, buttonStyles } from './ForgotForm.styled'
import { Link } from 'gatsby'

export type FormData = {
  email: string
}

const ForgotForm: FC<Partial<StepWizardChildProps>> = ({ nextStep }) => {
  const onSuccess = () => {
    nextStep()
  }

  const onError = (err) => {
    console.error(err)
  }

  const { register, setValue, clearErrors, watch, errors, onSubmit, submitting, submitResult, onSubmitSuccess } =
    useForgotPassword({
      onSuccess,
      onError
    })
  const email = watch('email')

  useEffect(() => {
    register({ name: 'email' })
  }, [register])
  return (
    <React.Fragment>
      {submitting && <Loader />}
      <Box maxWidth={500} mx="auto">
        <Heading size="h4" fontWeight="extraBold" mb={4} textAlign="center">
          {languages.en.auth.forgot.headline}
        </Heading>
        <Box as="form" onSubmit={onSubmit} mb={0}>
          <Box>
            <Input
              type="text"
              name="email"
              label="Email*"
              rebassInputProps={{
                autoComplete: 'email',
                value: email,
                onChange: (e) => {
                  errors && errors.email && errors.email.message && clearErrors('email')
                  submitResult && submitResult.message && onSubmitSuccess()
                  setValue('email', processTrimLowerCase(e.target.value))
                }
              }}
              sx={{ ...fieldStyles, mb: 0 }}
              error={errors.email && errors.email.message}
            />
            <Link to="/app/login/">
              <Button variant="transparent" label={languages.en.auth.forgot.rememberPasswordLabel} ml="auto" />
            </Link>
          </Box>

          <FormMessage
            showMessage={submitResult.submitted}
            message={submitResult.message}
            color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
          />
          <Button type="submit" label={languages.en.auth.forgot.buttonText} sx={buttonStyles} />
        </Box>
        <Link to="/app/signup/">
          <FormFooter text={languages.en.auth.noAccount} buttonText={languages.en.auth.buttonSignUp} />
        </Link>
      </Box>
    </React.Fragment>
  )
}
export default ForgotForm
