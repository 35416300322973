import React, { FC } from 'react'
import { Heading as RebassHeading, HeadingProps as RebassHeadingProps, SxStyleProp } from 'rebass'

import { HeadingFontWeight } from './types'
import { headingSizes, HeadingSizes } from './Heading.options'

type TextTransform = 'none' | 'uppercase' | 'lowercase' | 'capitalize'
type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'

export interface HeadingProps extends RebassHeadingProps {
  /** HTML heading tag */
  as?: HeadingTag
  /** Size of heading */
  size?: keyof HeadingSizes
  /** Weight of heading */
  fontWeight?: keyof HeadingFontWeight
  /** Colour of text in heading */
  textTransform?: TextTransform
  /** Colour of text in heading */
  color?: string
  /** Sx prop */
  sx?: SxStyleProp
  /** Standard React children */
  children?: React.ReactNode
}

const Heading: FC<HeadingProps> = ({ children, as, size, sx, ...props }) => {
  const headingProps = { ...headingSizes[size], ...sx }
  return (
    <RebassHeading as={as} sx={{ ...headingProps }} {...props}>
      {children}
    </RebassHeading>
  )
}

Heading.defaultProps = {
  as: 'h3',
  size: 'h3',
  color: 'textDark',
  fontWeight: 'extraBold',
  fontFamily: 'sans',
  textTransform: 'none',
  children: null,
  letterSpacing: 'small',
  sx: {}
}

export default Heading
