export const carouselStyles = {
  '.hero-image-gallery': {}
}

export const imageContainerStyles = {
  height: ['100vh', 'unset'],
  '& > :first-of-type': {
    width: '100%',
    height: ['100%', 'unset'],
    objectFit: ['cover', 'unset']
  }
}
