import React, { useState } from 'react'
import { Box, BoxProps, Flex } from 'rebass'
import Text from '../Text'
import Icon from '../Icon'
import IconWithContent from '../IconWithContent'
import { copiedStyles, wrapperStyles, showLinkWrapperStyles, showLinkCopiedStyles } from './CopyToClipboard.styled'
import theme from '../../theme'
import Input from '../Form/Input'

export interface CopyToClipboardProps extends BoxProps {
  /** Variant of component */
  variant?: 'iconText' | 'showLink'
  /** Label of component */
  label?: string
  /** Icon of component */
  icon?: string
  /** Text to copy to clipboard */
  copyText: string
}

const CopyToClipboard = ({ variant, label, icon, copyText, ...props }: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false)

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 2500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Box {...props}>
      <input type="text" value={copyText} readOnly hidden />
      {/* Bind our handler function to the onClick button property */}
      {variant === 'iconText' ? (
        <Box sx={wrapperStyles} onClick={handleCopyClick}>
          <IconWithContent headline={label} icon={icon} iconPosition="top" />
          {isCopied && (
            <Text size="small" color="statusSuccess" sx={copiedStyles}>
              Copied!
            </Text>
          )}
        </Box>
      ) : (
        <Flex sx={showLinkWrapperStyles}>
          <Flex sx={{ gap: 2, flex: 1 }}>
            <Input
              labelInline={true}
              label={
                <Icon
                  name={icon}
                  sx={{ fontSize: '20px', cursor: 'pointer' }}
                  color="white"
                  onClick={handleCopyClick}
                />
              }
              inlineLabelSx={{
                display: 'flex',
                width: '40px',
                backgroundColor: 'primary',
                label: { justifyContent: 'center' },
                svg: { ml: 0 },
                px: 0
              }}
              name="copyText"
              rebassInputProps={{ value: copyText, readOnly: true }}
              innerSx={{
                fontSize: ['small', 'medium'],
                color: 'textDark',
                fontWeight: 'medium',
                '&[readonly]': {
                  bg: 'none'
                },
                '&:focus': {
                  outline: 'none',
                  borderLeftWidth: 0
                },
                borderWidth: '1px',
                borderColor: 'borderInput',
                borderStyle: 'solid',
                borderLeftWidth: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
              sx={{ flex: 1, '> div': { border: 'none' } }}
            />
          </Flex>
          {isCopied && (
            <Text size="small" color="statusSuccess" sx={showLinkCopiedStyles}>
              Copied!
            </Text>
          )}
        </Flex>
      )}
    </Box>
  )
}
CopyToClipboard.defaultProps = {
  variant: 'iconText',
  label: 'Copy Link',
  icon: 'copyOutline',
  copyText: 'copy to text'
}

export default CopyToClipboard
