import { navigate } from '@reach/router'
import {
  Container,
  Icon,
  Input,
  ListItem,
  ListItems,
  Loader,
  Popover,
  ProfileSummarySmall,
  Radio,
  StepsWrapper,
  Text,
  Textarea,
  VehicleCardMobile
} from '@rmr/components'
import {
  extractAddressFieldsFromGooglePlace,
  PlaceType,
  Reservation,
  ReservationStatus,
  useAcceptQuote,
  useSetReservationDeliveryMutation
} from '@rmr/controllers'
import { commaSeparatedStringsToArray, GtmCategory, GtmEvent, logGtmEvent } from '@rmr/helpers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { Box, Flex } from 'rebass'
import { languages } from '../../../common/i18n'
import ApplyRemoveCoupon from '../../ApplyRemoveCoupon'
import { stepsWrapperStyles } from '../../ApprovalToList/GetApprovedList/GetApprovedList.styled'
import { ChangeDates } from '../../ChangeDates'
import ButtonArea from './ButtonArea'
import Dates from './Dates'
import { itemStyles, reservationWrapperStyles } from './ReservationQuote.styled'
import { fixImageUrl } from '../../../utils/fixImageUrl'

const autocompleteCountries = commaSeparatedStringsToArray(process.env.GATSBY_AUTOCOMPLETE_COUNTRIES)

interface ReservationQuoteProps {
  reservation: Reservation
  onRefetch: () => void
}

const ReservationQuote = ({ reservation, onRefetch }: ReservationQuoteProps) => {
  const { id: reservationId, vehicle, lineItems, couponCodes } = reservation

  const [selectedDeliverOption, setSelectedDeliverOption] = useState(reservation.locationId || null)
  const [isDropoff, setIsDropoff] = useState(reservation.isDelivery ? 1 : 0)

  const deliveryLocations = useMemo(
    () => vehicle.addresses.filter((address) => address.type !== PlaceType.Pickup),
    [vehicle.addresses]
  )

  const pickupLocations = useMemo(
    () => vehicle.addresses.filter((address) => address.type === PlaceType.Pickup),
    [vehicle.addresses]
  )

  const { errors, watch, setValue, clearErrors, onSubmit, register, submitting, submitResult } = useAcceptQuote({
    reservationId,
    onSuccess: () => {
      logGtmEvent({
        eventName: GtmEvent.BOOKING_REQUEST_SUBMITTED,
        data: { category: GtmCategory.BOOKING, value: lineItems[2]?.price, period: lineItems[0]?.priceEyebrow }
      })
      navigate(`/app/booking/confirm/${reservationId}`)
    },
    onError: (err) => {
      console.log(err.message)
    },
    isDropoff
  })

  const onPlaceSelected = useCallback(
    (place) => {
      clearErrors('address')
      if (place.place_id) {
        const placeObject = extractAddressFieldsFromGooglePlace(place)
        setValue('address', JSON.stringify({ ...placeObject, type: PlaceType.Dropoff }))
      } else {
        setValue('address', '')
      }
    },
    [clearErrors, setValue]
  )

  const { ref: googlePlacesRef } = usePlacesWidget({
    // Loaded on gatsby-ssr
    // apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    inputAutocompleteValue: 'off',
    onPlaceSelected,
    options: {
      types: ['address'],
      componentRestrictions: { country: autocompleteCountries }
    }
  })
  const [setDelivery, { loading: loadingDelivery }] = useSetReservationDeliveryMutation()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(e)
  }

  const onChangeDeliverOption = useCallback(
    async (value: string) => {
      await setDelivery({
        variables: {
          id: reservation.id,
          input: {
            locationId: value
          }
        }
      })

      setSelectedDeliverOption(value)
      setIsDropoff(0)
      setValue('address', '')
      if (googlePlacesRef.current) {
        ;(googlePlacesRef.current as HTMLInputElement).value = ''
      }
    },
    [googlePlacesRef, reservation.id, setDelivery, setValue]
  )

  const onChangeDropoffOption = useCallback(
    async (value) => {
      await setDelivery({
        variables: {
          id: reservation.id,
          input: {
            isDropoff: value
          }
        }
      })

      setSelectedDeliverOption(null)
      setIsDropoff(1)
      setValue('address', '')
    },
    [setDelivery, reservation.id, setValue]
  )

  const expirationHandler = useCallback(() => {
    navigate(`/app/booking/expired/${reservationId}`)
  }, [reservationId])

  useEffect(() => {
    if (reservation.__typename === 'Reservation') {
      if (reservation.status === ReservationStatus.Expired) {
        expirationHandler()
      } else if (reservation.status === ReservationStatus.Requested) {
        navigate(`/app/booking/confirm/${reservationId}`)
      } else if (reservation.status === ReservationStatus.Accepted) {
        navigate(`/app/booking/payment/${reservationId}`)
      } else if (reservation.status !== ReservationStatus.Quoted) {
        // pending to set correct url
        window.location.href = process.env.GATSBY_NX_DASHBOARD_LINK
      }
    }
  }, [reservation.status, vehicle.id, reservation.__typename, expirationHandler, reservationId])

  useEffect(() => {
    register('accept_terms')
    register('address')
  }, [register])

  return (
    <React.Fragment>
      {loadingDelivery && <Loader />}
      <Box as="section" sx={{ pt: [15], pb: [6] }}>
        <Container>
          <StepsWrapper
            badgeProps={{ variant: 'circle', size: [150], icon: 'calendar' }}
            title={languages.en.booking.quoteTitle}
            subtitle={languages.en.booking.quoteSubtitle}
            sx={stepsWrapperStyles}
          >
            <Box as="form" onSubmit={handleSubmit} sx={reservationWrapperStyles}>
              <Box>
                <Box>
                  <VehicleCardMobile
                    // price={vehicle.pricePerDay.formatted}
                    // priceDistribution="daily"
                    carName={vehicle.name}
                    image={vehicle.images[0].file.url}
                    totalTrips={vehicle.stats.trips}
                    rating={vehicle.stats.ratings}
                    language="en"
                    boxShadow={false}
                  />
                </Box>
                <Box py={2}>
                  <ListItems>
                    {lineItems.map((line) => {
                      return <ListItem {...line} key={line.title} />
                    })}
                  </ListItems>
                </Box>
                <Box>
                  <ApplyRemoveCoupon id={reservation.id} appliedCoupon={couponCodes[0]} onRefetch={onRefetch} />
                </Box>
              </Box>

              <Box mt={[3, 0]}>
                <Box sx={{ position: 'relative' }}>
                  <Dates startDate={reservation.dates.startFullDate} endDate={reservation.dates.endFullDate} />
                  <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                    <ChangeDates reservation={reservation} onRefetch={onRefetch} />
                  </Box>
                </Box>
                <Box sx={itemStyles}>
                  <Text size="large" color="textBlack" fontWeight="bold" mb={3}>
                    {languages.en.booking.pickupReturnLocationHeading}
                  </Text>
                  {pickupLocations.length ? (
                    <>
                      <Text size="small" color="textBlack" fontWeight="bold" mb={1}>
                        {languages.en.booking.pickupLocationLabel}
                      </Text>

                      <Radio
                        name="deliverOption"
                        variant={'vertical'}
                        options={pickupLocations.map((address) => ({
                          label: `${address.suburb}, ${address.city}, ${address.postalCode}`,
                          value: address.id
                        }))}
                        selectedOption={selectedDeliverOption}
                        rebassRadioProps={{
                          onChange: (e) => {
                            onChangeDeliverOption(e.target.value)
                          }
                        }}
                      />
                      <Text size="tiny" color="textBlack" mb={2}>
                        {languages.en.booking.exactAddressNotice}
                      </Text>
                    </>
                  ) : null}

                  {deliveryLocations.length ? (
                    <>
                      <Text size="small" color="textBlack" fontWeight="bold" mb={1}>
                        {languages.en.booking.deliveryLocationsHeading}
                      </Text>

                      <Radio
                        name="deliverOption"
                        variant={'vertical'}
                        options={deliveryLocations.map((address) => ({
                          label: `${address.name} - ${
                            address.deliveryFeeAmount.amountFloat === 0 ? 'Free' : address.deliveryFeeAmount.formatted
                          }`,
                          value: address.id
                        }))}
                        selectedOption={selectedDeliverOption}
                        rebassRadioProps={{
                          onChange: (e) => {
                            onChangeDeliverOption(e.target.value)
                          }
                        }}
                      />
                    </>
                  ) : null}

                  {reservation.vehicle.is_deliver_renter ? (
                    <Radio
                      name="isDropoff"
                      variant={'vertical'}
                      options={[
                        {
                          label: `Vehicle Drop-off - ${
                            !reservation.vehicle.deliveryFeeRenter ||
                            reservation.vehicle.deliveryFeeRenter.amountFloat === 0
                              ? 'Free'
                              : reservation.vehicle.deliveryFeeRenter.formatted
                          }`,
                          value: 1
                        }
                      ]}
                      selectedOption={isDropoff}
                      rebassRadioProps={{
                        onChange: (e) => {
                          onChangeDropoffOption(e.target.checked)
                        }
                      }}
                    />
                  ) : null}
                  <input hidden readOnly type="number" name="isDropoff" value={isDropoff} ref={register} />
                  <Input
                    ref={googlePlacesRef}
                    name="vehicleLocation"
                    label={languages['en'].booking.selectDropoffAddress}
                    placeholder={languages['en'].booking.addDelivery}
                    rebassInputProps={{
                      autoComplete: 'off',
                      onChange: (e) => !e.target.value && setValue('address', ''),
                      onKeyUp: (e) => {
                        if (e.code === 'Space' && !e.currentTarget.value.trim()) {
                          setValue('address', '')
                        }
                      }
                    }}
                    error={errors.address && errors.address.message}
                    sx={{ display: !isDropoff ? 'none' : 'block' }}
                  />
                  <Text size="tiny" color="textBlack" mb={2}>
                    {`${vehicle.user.fullName} ${languages.en.booking.deliveryDetailsCoordinate}`}
                  </Text>
                </Box>
                <Box sx={itemStyles}>
                  <Text size="large" color="textBlack" fontWeight="bold">
                    {languages.en.booking.messageHost}
                    <Popover>{languages.en.booking.requiredForTripInfo}</Popover>
                  </Text>
                  {languages.en.booking.reasonTravelling && (
                    <Flex mb={[3]}>
                      <Icon name="info" color="statusError" mr={1} />
                      <Text size="tiny" color="statusError">
                        {languages.en.booking.reasonTravelling}
                      </Text>
                    </Flex>
                  )}
                  <ProfileSummarySmall
                    title={vehicle.user.fullName}
                    profileImageUrl={fixImageUrl(vehicle.user.avatar)}
                    mt={[3]}
                    mb={[1]}
                  >
                    <Text size="small" color="textLight">
                      {`Joined ${vehicle.user.joinDate}`}
                    </Text>
                  </ProfileSummarySmall>
                  <Textarea
                    name="renter_message"
                    placeholder={languages.en.booking.requiredForTripInfo}
                    rebassTextareaProps={{
                      disabled: submitting
                    }}
                    ref={register}
                    error={errors.renter_message && errors.renter_message.message}
                  />
                </Box>

                <ButtonArea
                  submitResult={submitResult}
                  error={errors.accept_terms && errors.accept_terms.message}
                  submitting={submitting}
                  register={register}
                />
              </Box>
            </Box>
          </StepsWrapper>
        </Container>
      </Box>
    </React.Fragment>
  )
}

export default ReservationQuote
