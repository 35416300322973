import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Button, Content, Link } from '@rmr/components'
import Layout from '../Layout'
import { Box } from 'rebass'
import { StepProps } from '../ApprovalToList'
import { languages } from '../../../common/i18n'

const Success = (props: StepProps) => {
  const { step, currentStep, idRef } = props

  const { wpPage } = useStaticQuery<GatsbyTypes.ApprovalSuccessQuery>(graphql`
    query ApprovalSuccess {
      wpPage(slug: { eq: "approval-success" }) {
        layoutSettings {
          layout {
            __typename
            ... on WpPage_Layoutsettings_Layout_Banner {
              ...BannerFields
            }
          }
        }
      }
    }
  `)
  const {
    layoutSettings: { layout }
  } = wpPage
  let heading = '',
    subheading = '',
    content = ''
  if (layout[0].__typename === 'WpPage_Layoutsettings_Layout_Banner') {
    const { headline, subtitle, bannerContent } = layout[0]
    heading = headline
    subheading = subtitle
    content = bannerContent
  }

  return (
    <Layout
      badgeProps={{ variant: 'circle', size: [150], icon: step.icon, color: 'statusSuccess' }}
      title={heading}
      subtitle={subheading}
      separator={true}
      separatorColor="statusSuccess"
      currentStep={currentStep}
      previousStep={null}
      steps={[]}
      idRef={idRef}
      isCompleted={true}
    >
      <Content sx={{ '& p': { fontSize: 'medium' } }}>
        <Box dangerouslySetInnerHTML={{ __html: content }} />
      </Content>
      <Box mx="auto" width={1}>
        <a href={process.env.GATSBY_NX_DASHBOARD_LINK}>
          <Button variant="primary" mx="auto" mt={[3]}>
            {languages.en.approvalConfirmation.buttonText}
          </Button>
        </a>
      </Box>
    </Layout>
  )
}

export { Success }
