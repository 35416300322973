export function OBJtoXML(obj) {
  let xml = ''
  for (const prop in obj) {
    xml += obj[prop] instanceof Array ? '' : '<' + prop + '>'
    if (obj[prop] instanceof Array) {
      for (const array in obj[prop]) {
        xml += '<' + prop + '>'
        xml += OBJtoXML(new Object(obj[prop][array]))
        xml += '</' + prop + '>'
      }
    } else if (typeof obj[prop] == 'object') {
      xml += OBJtoXML(new Object(obj[prop]))
    } else if (typeof obj[prop] == 'string') {
      xml += obj[prop].replace(/&/g, '&amp;')
    } else {
      xml += obj[prop]
    }
    xml += obj[prop] instanceof Array ? '' : '</' + prop + '>'
  }
  return xml.replace(/<\/?[0-9]{1,}>/g, '')
}
