import React, { useState, useRef } from 'react'
import { Box, Flex, SxStyleProp, BoxProps } from 'rebass'

import useOnClickOutside from '../../../hooks/useOnClickOutside'
import Button from '../../Button'
import RangeSlider, { buttonLabel } from '../../RangeSlider'
import theme from '../../../theme'
import FilterButton from '../FilterButton'
import FilterDropdown from '../FilterDropdown'

import { wrapperStyles, thumbLabelStyles, buttonWrapperStyles, groupModeContainerStyles } from './RangeFilter.styled'

export type RangeFilterAction = (values: number[]) => void

export interface RangeFilterProps extends BoxProps {
  /** Filter Name */
  filterName: string
  /** initial Values */
  initialValues?: number[]
  /** Min Value */
  min: number
  /** Max Value */
  max: number
  /** Unit Left ? */
  unitLeft?: boolean
  /** Unit */
  unit: string
  /** If unit can be plural */
  unitPlural: string
  /** Button Text */
  buttonText?: string
  /** Callback with Selected values */
  onChangeValues: RangeFilterAction
  /** Group Mode */
  groupMode?: boolean
  /** Sx prop styles */
  sx?: SxStyleProp
  /** Dropdown Sx prop styles */
  dropdownSx?: SxStyleProp
  /** Button Sx prop styles */
  buttonSx?: SxStyleProp
}

const RangeFilter = ({
  filterName,
  min,
  max,
  initialValues = [min, max],
  unit,
  unitLeft,
  unitPlural,
  buttonText,
  onChangeValues,
  groupMode,
  sx,
  buttonSx,
  dropdownSx,
  ...props
}: RangeFilterProps) => {
  const [values, setValues] = useState(initialValues)
  const [open, setOpen] = useState(false)
  const filterRef = useRef(null)
  const buttonRef = useRef(null)

  useOnClickOutside(filterRef, () => open && setOpen(false))

  const handleSelectedValues = (values: number[]) => {
    setValues(values)
  }

  const applyHandler = () => {
    setOpen(false)
    onChangeValues(values)
  }

  const selectedValues = values[0] > min || values[1] < max
  const formattedValues = values.map((value) => buttonLabel('', '', value, unit, unitPlural, unitLeft))

  return (
    <Box ref={filterRef}>
      <Box sx={{ ...wrapperStyles(groupMode), ...sx }} {...props}>
        <FilterButton
          ref={buttonRef}
          filterName={filterName}
          showButton={!groupMode}
          open={open}
          activeLabel={selectedValues ? `${formattedValues[0]} - ${formattedValues[1]}` : undefined}
          buttonSx={buttonSx}
          onClickButton={() => setOpen((open) => !open)}
          onClickCloseButton={(e) => {
            setValues([min, max])
            onChangeValues([min, max])
            setOpen(false)
            e.stopPropagation()
          }}
          groupMode={groupMode}
        />
        {groupMode ? (
          <Box sx={groupModeContainerStyles}>
            <RangeSlider
              initialValues={values}
              min={min}
              max={max}
              unit={unit}
              unitPlural={unitPlural}
              unitLeft={unitLeft}
              onChangeValues={(values) => {
                handleSelectedValues(values)
                onChangeValues(values)
              }}
              rangeColors={[theme.colors.bgLight, theme.colors.primary, theme.colors.bgLight]}
              showStaticLabel={false}
              showthumbLabel={true}
              thumbLabelTop={true}
              thumbLabelSx={thumbLabelStyles}
            />
          </Box>
        ) : (
          <FilterDropdown
            isOpen={open}
            sx={{
              width: ['100vw'],
              maxWidth: [300, null, null, 500],
              maxHeight: ['50vh', null, null, 400],
              px: [2],
              ...dropdownSx
            }}
            buttonRef={buttonRef}
          >
            <Box sx={{ pt: [8], pb: [3], px: [4] }}>
              <RangeSlider
                initialValues={values}
                min={min}
                max={max}
                unit={unit}
                unitPlural={unitPlural}
                onChangeValues={handleSelectedValues}
                rangeColors={[theme.colors.bgLight, theme.colors.primary, theme.colors.bgLight]}
                showStaticLabel={false}
                showthumbLabel={true}
                thumbLabelTop={true}
                thumbLabelSx={thumbLabelStyles}
              />
              <Flex sx={buttonWrapperStyles}>
                <Button onClick={applyHandler}>{buttonText}</Button>
              </Flex>
            </Box>
          </FilterDropdown>
        )}
      </Box>
    </Box>
  )
}

RangeFilter.defaultProps = {
  filterName: '',
  initialValues: [1, 100],
  min: 1,
  max: 100,
  unit: 'R',
  unitPlural: 'R',
  unitLeft: true,
  buttonText: 'Apply',
  onChangeValues: () => null,
  groupMode: false,
  sx: {},
  dropdownSx: {},
  buttonSx: {}
}

export default RangeFilter
