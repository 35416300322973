import React, { FC } from 'react'
import { SxStyleProp, Flex, Box, FlexProps } from 'rebass'
import SectionFeaturesStrip from './SectionFeaturesStrip'
import SectionFeaturesPoster from './SectionFeaturesPoster'
import SectionFeaturesOverlay from './SectionFeaturesOverlay'
import SectionFeaturesVertical from './SectionFeaturesVertical'
import { SectionFeatureTypes } from './types'

export interface SectionFeaturesTypes {
  /** Headline */
  headline?: string
  /** Color of the Headline */
  headlineColor?: string
  /** Color of the background */
  bgColor?: 'gray' | 'black' | 'white' | 'orange'
  /** Variant of the Section */
  variant?: 'featuresStrip' | 'featuresVertical' | 'featureOverlay' | 'featuresPoster'
  /** Variant of the Section */
  featureType?: SectionFeatureTypes
  /** Image to feature on the section */
  sectionImage?: React.ReactNode | string
  /** Content in addition to the features */
  content?: React.ReactNode | string
  /** Sx Props */
  sx?: SxStyleProp
}

const getSectionMap = {
  featuresStrip: SectionFeaturesStrip,
  featuresPoster: SectionFeaturesPoster,
  featureOverlay: SectionFeaturesOverlay,
  featuresVertical: SectionFeaturesVertical
}
interface GetSectionStyles {
  [key: string]: FlexProps
}

const getSectionStyles: GetSectionStyles = {
  featuresStrip: {
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['flex-start', 'flex-start', 'space-around']
  },
  featuresPoster: {
    flexDirection: ['row', 'column', 'column'],
    justifyContent: ['flex-start', 'flex-start', 'space-around'],
    flexWrap: ['wrap']
  },
  featureOverlay: {
    flexDirection: ['row'],
    justifyContent: ['flex-start', 'flex-start', 'space-between'],
    flexWrap: ['wrap']
  },
  featuresVertical: {
    flexDirection: ['column']
  }
}

const getFeatureStyles = {
  feautureIconLarge: {
    mb: [4, 0],
    width: ['100%']
  },
  featureRow: {
    mb: [4],
    width: ['100%', '100%', '100%'],
    px: [2]
  },
  featureCard: {
    mb: [4],
    width: ['100%']
  },
  featureStack: {
    mb: [2, 2, 4],
    width: '50%'
  }
}

const SectionFeatures: FC<SectionFeaturesTypes> = ({
  headline,
  headlineColor,
  sectionImage,
  bgColor,
  content,
  variant,
  featureType,
  children,
  sx,
  ...props
}) => {
  const Section = getSectionMap[variant]
  return (
    <Section
      headline={headline}
      headlineColor={headlineColor}
      bgColor={bgColor}
      variant={variant}
      sectionImage={sectionImage}
      content={content}
      {...props}
      sx={{ ...sx }}
    >
      <Flex className="Children Flex" {...getSectionStyles[variant]}>
        {React.Children.map(children, (child) => (
          <Box className="Child Box" {...getFeatureStyles[featureType]}>
            {child}
          </Box>
        ))}
      </Flex>
    </Section>
  )
}

SectionFeatures.defaultProps = {
  headline: '',
  headlineColor: 'textDark',
  bgColor: 'white',
  variant: 'featuresStrip',
  sectionImage: null,
  sx: {}
}

export default SectionFeatures
