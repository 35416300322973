import React, { forwardRef } from 'react'
import { SxStyleProp, BoxProps, Box } from 'rebass'
import { Radio as RebassRadio, Label } from '@rebass/forms'

import Text from '../../Text'
import FormGroup from '../FormGroup'

import { RadioProps as RebassRadioProps } from '@rebass/forms'

import { radioStyles, wrapperStyles, formGroupStyles } from './Radio.styled'

export interface RadioProps extends BoxProps {
  /** Variant: horizontal or vertical */
  variant?: 'horizontal' | 'vertical'
  /** Field name */
  name: string
  /** Selected Option */
  selectedOption?: string | number
  /** Radio Options */
  options: { label: React.ReactNode; value: string | number; disabled?: boolean }[]
  /** Wrapper Sx prop */
  sx?: SxStyleProp
  /** Inner Sx prop */
  innerSx?: SxStyleProp
  /** Rebass radio props */
  rebassRadioProps?: RebassRadioProps
}

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ variant, name, selectedOption, options, sx, innerSx, rebassRadioProps, ...props }, ref) => {
    const isHorizontal = variant === 'horizontal'

    return (
      <FormGroup {...props} sx={{ ...formGroupStyles(isHorizontal), ...sx }}>
        {options &&
          options.map(({ label, value, disabled = false }, index) => {
            return (
              <Label key={index} sx={{ ...wrapperStyles(isHorizontal), opacity: disabled ? 0.4 : 1 }}>
                <RebassRadio
                  name={name}
                  id={`${label}`}
                  checked={`${selectedOption}` === `${value}`}
                  value={value}
                  ref={ref}
                  disabled={disabled}
                  sx={{ ...radioStyles, ...innerSx }}
                  {...rebassRadioProps}
                />
                <Box
                  sx={{ flex: 1, fontSize: 'small', color: 'textDark', fontWeight: 'bold', mt: '3px' }}
                  ml={isHorizontal ? -1 : 0}
                >
                  {label}
                </Box>
              </Label>
            )
          })}
      </FormGroup>
    )
  }
)

Radio.defaultProps = {
  variant: 'vertical',
  name: null,
  options: [],
  sx: {},
  innerSx: {},
  rebassRadioProps: {}
}

export default Radio
