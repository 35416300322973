/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface StaticQueryData {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
      author: string
      siteUrl: string
    }
  }
}

interface SEOProps {
  readonly title: string
  readonly description?: string
  readonly meta?: []
  readonly path: string
}

function SEO({ title, description = '', meta = [], path }: SEOProps) {
  const { site }: StaticQueryData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const metaKeywords = site.siteMetadata.keywords

  const pageUrl = path ? `${site.siteMetadata.siteUrl}${path}` : `${site.siteMetadata.siteUrl}`

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `keywords`,
          content: metaKeywords
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `business.business`
        },
        // for apple devices
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1`
        }
      ].concat(meta)}
    />
  )
}

export default SEO
