import React, { FC } from 'react'
import { Flex } from 'rebass'

import Button from '../../Button'
import Text from '../../Text'

export interface FormFooterProps {
  /** Text */
  text: string
  /** Button Label */
  buttonText: string
  /** handler */
  handler?: () => void
}

const FormFooter: FC<FormFooterProps> = ({ text, buttonText, handler }) => (
  <Flex flexDirection={['column', 'column', 'row']} alignItems="center" justifyContent="center" mt={3} flexWrap={'wrap'}>
    <Text size="body" fontWeight="medium">
      {text}
    </Text>
    <Button variant="transparent" label={buttonText} onClick={handler} sx={{ py: 0, fontSize: 'medium' }} />
  </Flex>
)

export default FormFooter

FormFooter.defaultProps = {
  text: "Don't have an account?",
  buttonText: 'Sign up',
  handler: () => null
}
