import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  UpdateVehicleInput,
  UpdateVehicleMutation,
  useUpdateVehicleMutation,
  TermsConditionsData
} from '@rmr/controllers'

interface UseTermsConditionsArgs {
  onSuccess: (response: UpdateVehicleMutation) => void
  onError: (e: Error) => void
  defaultValues: TermsConditionsData
}

export function useTermsConditionsForm({ onSuccess, onError, defaultValues }: UseTermsConditionsArgs) {
  const TermsConditionsFormSchema = yup.object().shape({
    accept_terms: yup.bool().oneOf([true], 'Please accept our T&Cs')
  })

  const form = useForm({
    resolver: yupResolver(TermsConditionsFormSchema),
    defaultValues
  })

  const [updateVehicleMutation, { loading: loadingUpdate }] = useUpdateVehicleMutation()

  const onSubmit = useCallback(
    async (data: TermsConditionsData) => {
      const { id, accept_terms } = data
      const vehicleInputData: UpdateVehicleInput = {
        id,
        t_c_accepted_at: accept_terms ? new Date() : null,
        review: true
      }
      try {
        const response = await updateVehicleMutation({ variables: { input: vehicleInputData } })
        onSuccess(response.data)
      } catch (err) {
        console.log('onSubmit Update Error: ', err)
        onError(err)
      }
    },
    [updateVehicleMutation, onSuccess, onError]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loadingUpdate
  }
}
