import { SxStyleProp } from 'rebass'

export const wrapperStyles: SxStyleProp = {
  px: [0, 2, 4],
  pt: [4],
  display: 'grid',
  gridTemplateColumns: ['repeat(1,1fr)', null, '60% auto'],
  columnGap: [8],
  rowGap: [2]
}
