import { SxStyleProp } from 'rebass'
import theme from '../../../theme'

const minimalActiveStyles = (percentage: number): SxStyleProp => ({
  position: 'relative',
  border: `1px solid ${theme.colors.statusSuccess}`,
  zIndex: 1,
  ':before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${percentage}%`,
    height: `100%`,
    bg: 'statusSuccess',
    opacity: 0.2,
    zIndex: 0
  }
})

export const dropZoneStyles = (
  active: boolean,
  minimal: boolean,
  isMinimalActive: boolean,
  percentage: number
): SxStyleProp =>
  Object.assign(
    {
      alignItems: 'center',
      flex: '1 0 auto',
      position: 'relative',
      p: minimal ? 0 : 3,
      bg: 'bgLight',
      border: `1px dashed ${active ? theme.colors.primary : theme.colors.borderDark}`,
      cursor: 'pointer',
      opacity: active ? 0.5 : 1,
      transition: 'all 0.2s'
    },
    isMinimalActive ? minimalActiveStyles(percentage) : {}
  )

export const placeholderStylesVertical: SxStyleProp = {
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}
export const placeholderStylesHorizontal: SxStyleProp = {
  flexDirection: ['column', 'row'],
  width: '100%',
  alignItems: 'center',
  justifyContent: 'start'
}

export const placeholderStylesMinimal: SxStyleProp = {
  px: [2, 3],
  py: 2,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'start'
}

export const iconStylesVertical: SxStyleProp = {
  mb: 2,
  '>div': {
    width: ['80px !important', '80px !important'],
    height: ['80px !important', '80px !important']
  },
  svg: {
    width: '40% !important',
    height: '40% !important'
  }
}
export const iconStylesHorizontal: SxStyleProp = {
  mr: [0, 2],
  mb: [2, 0],
  '>div': {
    width: '80px !important',
    height: '80px !important'
  },
  svg: {
    width: '40% !important',
    height: '40% !important'
  }
}
