import { Button, Loader, OptionWithTitle, Radio, SelectWithTitles, Text } from '@rmr/components'
import {
  CardToken,
  PaymentMethod,
  Reservation,
  TransactionStatus,
  useGetPaymentMethodsQuery,
  usePaymentForm,
  useSetReservationMethodMutation
} from '@rmr/controllers'
import { GtmCategory, GtmEvent, logGtmEvent } from '@rmr/helpers'
import { navigate } from 'gatsby'
import React, { useMemo, useState } from 'react'
import { Box, Flex } from 'rebass'
import { languages } from '../../../common/i18n'
import { formStyles } from './PaymentForm.styled'

interface PaymentFormProps {
  reservation: Reservation
  cards: CardToken[]
  settle?: boolean
}

const PaymentForm = ({ reservation, cards, settle = false }: PaymentFormProps) => {
  const amount = reservation?.orderLineTotals?.balance?.amountFloat ?? 0
  const paymentForm = React.useRef<HTMLFormElement>(null)
  const paymentIdField = React.useRef(null)
  const checksumIdField = React.useRef(null)

  const [paymentMethodIndex, setPaymentMethodIndex] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState({ paymentMethodId: 'paystack', cardId: null })

  const { data, loading } = useGetPaymentMethodsQuery({ variables: { id: reservation.id } })
  const [setReservationMethod, { loading: loadingMethod }] = useSetReservationMethodMutation()

  const { isCard, isEFT, isQR } = useMemo(() => {
    if (data?.getPaymentMethods) {
      const { channels } = data.getPaymentMethods[paymentMethodIndex]
      const isCard = channels.includes('card')
      const isEFT = channels.includes('eft')
      const isQR = channels.includes('qr')
      return { isCard, isEFT, isQR }
    }
    return { isCard: false, isEFT: false }
  }, [data?.getPaymentMethods, paymentMethodIndex])

  const optionsWithTitles: OptionWithTitle[] = useMemo(() => {
    const activeCards = isCard
      ? cards.filter((c) => {
          return new Date(c.expiryDate) > new Date()
        })
      : []

    const options =
      activeCards.length > 0
        ? [
            {
              title: languages.en.paymentForm.linkedMethodsLabel,
              options: activeCards.map((c) => ({
                label: `${c.cardType.toUpperCase()} - ${c.lastFourDigits} -  ${c.expiryMonth}/${c.expiryYear.substr(
                  2,
                  2
                )}`,
                value: c.id,
                icon: 'creditCardFront'
              }))
            }
          ]
        : []
    const defaultOptions = []
    if (isCard) {
      defaultOptions.push({
        label: languages.en.paymentForm.paymentMethods[PaymentMethod.CreditCard],
        value: PaymentMethod.CreditCard,
        icon: 'creditCardFront'
      })
    }
    if (isEFT) {
      defaultOptions.push({
        label: languages.en.paymentForm.paymentMethods[PaymentMethod.Eft],
        value: PaymentMethod.Eft,
        icon: 'moneyCheck'
      })
    }
    if (isQR) {
      defaultOptions.push({
        label: languages.en.paymentForm.paymentMethods[PaymentMethod.Qr],
        value: PaymentMethod.Qr,
        icon: 'qrCode'
      })
    }
    return options.concat({
      title: languages.en.paymentForm.otherMethodsLabel,
      options: defaultOptions
    })
  }, [cards, isCard, isEFT, isQR])

  const onSuccess = ({ method, metadata }: PaymentResponse) => {
    if (metadata.status === TransactionStatus.Complete) {
      navigate(`/app/booking/complete/${reservation.id}`)
    } else {
      if (method === 'paygate') {
        paymentIdField.current.value = metadata['PAY_REQUEST_ID']
        checksumIdField.current.value = metadata['CHECKSUM']

        paymentForm.current.submit()
      } else if ((method === 'paystack' || method === 'paystack_partial') && metadata?.data?.authorization_url) {
        window.location.href = metadata.data.authorization_url
      }
    }
  }

  const onError = (err?: any) => {
    logGtmEvent({
      eventName: GtmEvent.BOOKING_PAYMENT_FAILURE,
      data: {
        category: GtmCategory.BOOKING,
        value: reservation?.lineItems[2]?.price
      }
    })
    console.log({ err })
  }

  const { onSubmit, processing } = usePaymentForm({
    reservationId: reservation.id,
    paymentMethod,
    onSuccess,
    onError,
    settle
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(e)
  }

  if (loading) return null
  return (
    <React.Fragment>
      {loadingMethod && <Loader />}
      <Box as="form" sx={formStyles} onSubmit={handleSubmit} autoComplete="off">
        {!settle && data?.getPaymentMethods?.length > 1 && (
          <Box sx={{ pb: [2] }}>
            <Radio
              name="paymentMethodId"
              variant="vertical"
              selectedOption={paymentMethodIndex}
              options={data.getPaymentMethods.map(({ name, amount, description }, index) => {
                return {
                  value: index,
                  label: (
                    <Box mt="-3px">
                      <Flex sx={{ justifyContent: 'space-between' }}>
                        <Text as="span" size="small" fontWeight="bold">
                          {name}
                        </Text>
                        <Text as="span" size="small" fontWeight="bold">
                          {amount.formatted}
                        </Text>
                      </Flex>
                      <Text size="small" color="textLight">
                        {description}
                      </Text>
                    </Box>
                  )
                }
              })}
              rebassRadioProps={{
                onChange: (e) => {
                  const code = data.getPaymentMethods[+e.target.value].code
                  setPaymentMethodIndex(+e.target.value)
                  setPaymentMethod({ paymentMethodId: code, cardId: null })
                  setReservationMethod({
                    variables: { id: reservation.id, code }
                  })
                }
              }}
            />
          </Box>
        )}
        <Box sx={{ pb: [2] }}>
          <Flex flexDirection={['column', 'row']}>
            <SelectWithTitles
              key={paymentMethodIndex}
              filterName={languages.en.paymentForm.paymentMethodsLabel}
              optionsWithTitle={optionsWithTitles}
              onSet={(paymentMethod: PaymentMethod | string) => {
                let cardId = ''
                if (
                  [PaymentMethod.CreditCard, PaymentMethod.Eft, PaymentMethod.Qr].includes(
                    paymentMethod as PaymentMethod
                  )
                ) {
                  cardId = ''
                } else {
                  cardId = paymentMethod as string
                }

                setPaymentMethod((paymentMethod) => ({ ...paymentMethod, cardId }))
              }}
              sx={{ flex: '1 0 auto', mr: [0, 2], mb: [2, 0], height: [52] }}
              buttonSx={{ height: '100%' }}
            />
            <Button
              disabled={
                !paymentMethod.paymentMethodId || typeof paymentMethod.cardId !== 'string' || processing || amount === 0
              }
              variant="primary"
              mx={[null, 'auto']}
              onClick={onSubmit}
            >
              {!processing ? languages.en.booking.buttonPayment : languages.en.booking.buttonPaymentProcessing}
            </Button>
          </Flex>
        </Box>
      </Box>
      <form action="https://secure.paygate.co.za/payweb3/process.trans" method="POST" ref={paymentForm}>
        <input type="hidden" name="PAY_REQUEST_ID" value="" ref={paymentIdField} />
        <input type="hidden" name="CHECKSUM" value="" ref={checksumIdField} />
      </form>
    </React.Fragment>
  )
}

export default PaymentForm
