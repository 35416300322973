import styled from '@emotion/styled'
import theme from '../../theme'
import { Box } from 'rebass'

export const StyledContent = styled(Box)`
  &.wp-content {
    figure {
      width: 100% !important;
      margin-bottom: 2rem;
      &.aligncenter {
        text-align: center;
      }
      &.alignright {
        text-align: right;
      }
      .wp-caption-text {
        margin-top: 0;
      }
      .gatsby-image-wrapper {
        margin-bottom: 0;
      }
    }
    .gatsby-image-wrapper {
      margin-bottom: 2rem;
    }
    audio {
      margin-bottom: 2rem;
    }
    iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
    }
  }
  * {
    line-height: 1.5;
    letter-spacing: ${theme.letterSpacing.medium};
    font-weight: ${theme.fontWeights.regular};
  }
  img {
    width: inherit;
    max-width: 100%;
    height: auto;
  }
  p {
    line-height: 1.5;
    margin-bottom: ${theme.space[2]}px;
    a {
      word-break: break-word;
    }
  }
  a {
    color: ${theme.colors.primary};
    text-decoration: underline !important;
    &:hover {
      color: ${theme.colors.primaryLight};
      text-decoration: underline !important;
    }
  }
  p b,
  p strong,
  li strong {
    font-weight: ${theme.fontWeights.extraBold};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.primary};
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  h1 b,
  h2 b,
  h3 b,
  h4 b,
  h5 b,
  h6 b,
  h1 strong,
  h2 strong,
  h3 strong,
  h4 strong,
  h5 strong,
  h6 strong {
    font-weight: ${theme.fontWeights.bold};
    a {
      font-weight: ${theme.fontWeights.bold};
    }
  }
  h5 b,
  h6 b,
  h5 strong,
  h6 strong {
    font-weight: ${theme.fontWeights.bold};
  }
  em,
  i {
    font-style: italic;
    &.icon {
      font-style: normal;
    }
  }
  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-left: ${theme.space[2]}px;
    margin-bottom: ${theme.space[4]}px;
  }

  ol li,
  ul li {
    margin-bottom: ${theme.space[2]}px;
    padding-left: ${theme.space[2]}px;
  }

  ol li {
    list-style-type: decimal;
    ol li {
      list-style-type: lower-roman;
    }
  }
  ul li {
    list-style-type: disc;
  }

  small {
    font-size: ${theme.fontSizes.small};
  }
  hr {
    height: 0px;
    margin: 0px;
    padding: 0;
    border-style: solid;
    border-width: 0;
    border-bottom: 4px solid ${theme.colors.primary};
    width: 280px;
    margin-top: ${theme.space[5]}px;
    margin-bottom: ${theme.space[5]}px;
    margin-left: auto;
    margin-right: auto;
    &.center {
      margin-left: auto;
      margin-right: auto;
    }
  }
  blockquote {
    margin: 0;
    margin-top: ${theme.space[6]}px;
    margin-bottom: ${theme.space[6]}px;
    padding-left: ${theme.space[4]}px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: ${theme.fontSizes.h4};
    border-left: 4px solid ${theme.colors.primary};
  }
  cite {
    font-style: italic;
    font-size: ${theme.fontSizes.small};
  }
  address {
    font-style: normal;
  }
  figcaption {
    margin-top: ${theme.space[1]}px;
    font-size: ${theme.fontSizes.small};
  }
  h6 {
    font-size: ${theme.fontSizes.h6Mobile};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h6};
    }
  }
  h5 {
    font-size: ${theme.fontSizes.h5Mobile};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h5};
    }
  }
  h4 {
    font-size: ${theme.fontSizes.h4Mobile};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h4};
    }
  }
  h3 {
    font-size: ${theme.fontSizes.h3Mobile};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h3};
    }
  }
  h2 {
    font-size: ${theme.fontSizes.h2Mobile};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h2};
    }
  }
  h1 {
    font-size: ${theme.fontSizes.h1Mobile};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h1};
    }
  }
`
