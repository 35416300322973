import { Moment } from 'moment'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { Flex, SxStyleProp } from 'rebass'
import useMedia from 'use-media'
import languages from '../../i18n'
import theme from '../../theme'
import { wrapperStyles } from './RangeDates.styled'
import Error from '../Form/Error'

export enum SearchBarVariant {
  DEFAULT = 'default',
  SEARCH_PAGE = 'search_page'
}

const defaultProps = {
  // example props for the demo

  // input related props
  startDateId: languages['en'].searchBar.fromLabel,
  startDatePlaceholderText: languages['en'].searchBar.fromLabel,
  endDateId: languages['en'].searchBar.toLabel,
  endDatePlaceholderText: languages['en'].searchBar.toLabel,
  disabled: false,
  required: true,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,
  block: false,
  small: false,
  regular: false,

  // calendar presentation and interaction related props
  // renderMonthText: null,
  // anchorDirection: 'left',
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,
  // openDirection: 'down',

  // navigation related props
  // navPosition: 'top',
  navPrev: null,
  navNext: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData('fr').longDateFormat('L')
  // phrases: null,
}

export interface Dates {
  startDate: Moment
  endDate: Moment
}

interface RangeDatesProps {
  variant?: SearchBarVariant
  startDate?: Moment
  endDate?: Moment
  onDatesChange: (dates: Dates) => void
  error?: string
  blockedDays?: Moment[]
  bookedDays?: Moment[]
  minimumDays?: number
  maximumDays?: number
  canSelectPast?: boolean
  sx?: SxStyleProp
  timezone?: string
  loadingAvailability?: boolean
}

const RangeDates = ({
  variant = SearchBarVariant.DEFAULT,
  startDate,
  endDate,
  onDatesChange,
  error = '',
  blockedDays,
  bookedDays,
  minimumDays,
  maximumDays,
  timezone = 'Africa/Johannesburg',
  canSelectPast = false,
  loadingAvailability = false,
  sx = {}
}: RangeDatesProps) => {
  const isMobile = useMedia({ maxWidth: theme.breakpoints[0] }) // 576px
  const isTablet = useMedia({ maxWidth: theme.breakpoints[2] }) // 992px
  const [focusedInput, setFocusedInput] = useState(null)

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput)
  }

  const isOutsideRange = (day: Moment) => {
    const dayClone = day.clone()
    return (
      (!canSelectPast &&
        !dayClone.tz('Africa/Johannesburg').startOf('day').isAfter(moment().tz('Africa/Johannesburg'))) ||
      (focusedInput === 'endDate' &&
        maximumDays &&
        (dayClone.tz('Africa/Johannesburg').isBefore(startDate) ||
          dayClone.tz('Africa/Johannesburg').isAfter(startDate.clone().add(maximumDays, 'days'))))
    )
  }

  const isSameDay = (day: Moment) => (date) => {
    const dayClone = day.clone()
    const dateClone = date.clone()
    return dayClone.tz('Africa/Johannesburg').isSame(dateClone.tz('Africa/Johannesburg'), 'day')
  }

  const isDayBlocked = (day: Moment) => {
    return blockedDays && (blockedDays.some(isSameDay(day)) || bookedDays.some(isSameDay(day)))
  }

  return (
    <Flex sx={{ ...wrapperStyles(variant), ...sx }}>
      <DateRangePicker
        {...defaultProps}
        readOnly={true}
        orientation={isTablet ? 'vertical' : 'horizontal'}
        withFullScreenPortal={isMobile}
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={startDate && startDate.unix() ? startDate : undefined}
        endDate={endDate && endDate.unix() ? endDate : undefined}
        hideKeyboardShortcutsPanel={true}
        isDayBlocked={isDayBlocked}
        minimumNights={minimumDays ? minimumDays - 1 : undefined}
        isOutsideRange={isOutsideRange}
        disabled={loadingAvailability}
      />
      {error && <Error>{error}</Error>}
    </Flex>
  )
}

export default RangeDates
