export enum GtmEvent {
  USER_SIGNUP = 'USER_SIGNUP',
  USER_EMAIL_VERIFIED = 'USER_EMAIL_VERIFIED',
  USER_APPROVAL_REQUEST = 'USER_APPROVAL_REQUEST',
  USER_APPROVAL_ACCEPTANCE = 'USER_APPROVAL_ACCEPTANCE',
  VEHICLE_LISTING_REQUEST = 'VEHICLE_LISTING_REQUEST',
  VEHICLE_LISTING_ACCEPTANCE = 'VEHICLE_LISTING_ACCEPTANCE',
  BOOKING_REQUEST_SUBMITTED = 'BOOKING_REQUEST_SUBMITTED',
  BOOKING_REQUEST_APPROVED = 'BOOKING_REQUEST_APPROVED',
  BOOKING_REQUEST_REJECTED = 'BOOKING_REQUEST_REJECTED',
  BOOKING_PAYMENT_SUCCESS = 'BOOKING_PAYMENT_SUCCESS',
  BOOKING_PAYMENT_FAILURE = 'BOOKING_PAYMENT_FAILURE'
}
export enum GtmCategory {
  AUTHENTICATION = 'AUTHENTICATION',
  USER_APPROVAL = 'USER_APPROVAL',
  VEHICLE_LISTING = 'VEHICLE_LISTING',
  BOOKING = 'BOOKING'
}

export const refCookieName = 'ref'
