import { SxStyleProp } from 'rebass'
import theme from '../../theme'
import { SearchBarVariant } from './RangeDates'

export const wrapperStyles = (variant: SearchBarVariant): SxStyleProp => ({
  position: 'relative',
  alignSelf: ['center', 'center', 'stretch'],
  maxWidth: '100%',
  // justifyContent: ['center'],
  mb: [2, 4, 0],
  '& > div:first-of-type': {
    flex: '1 0 auto'
  },
  //   React Dates
  '.DateRangePicker': {
    maxWidth: '100%',
    borderWidth: variant === SearchBarVariant.DEFAULT ? '2px' : 0,
    borderStyle: 'solid',
    borderColor: 'borderInput',
    borderRadius: '4px',
    '> div': {
      height: '100%',
      display: 'flex'
    }
  },
  '.DateRangePicker_picker ': {
    top: ['67px !important', null, '70px !important']
  },
  '.DateRangePickerInput ': {
    maxWidth: '100%',
    display: 'flex',
    borderWidth: 0,
    alignItems: 'center',

    '> div': {
      display: 'flex'
    }
  },
  '.DateInput': {
    height: '100%',
    width: [110, 130]
  },
  '.DateInput_fang ': {
    top: '60px !important'
  },
  '.DateInput_input': {
    color: 'textDark',
    fontSize: ['medium', 'medium'],
    fontWeight: 'bold',
    svg: {
      top: '54px !important'
    }
  },
  '.DateInput_input__focused': {
    borderColor: 'primary'
  },
  '.CalendarDay__selected_span': {
    color: 'textWhite',
    bg: 'primaryLight'
    // borderColor: 'primaryLight'
  },
  '.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover ': {
    color: 'textWhite',
    bg: 'primary'
    // borderColor: 'primary'
  },
  '.CalendarDay__hovered_span:hover, .CalendarDay__hovered_span': {
    color: 'textWhite',
    bg: 'primaryLight'
    // borderColor: 'primaryLight'
  },
  '.DateRangePicker_picker__portal': {
    zIndex: theme.zIndex.max
  }
})

export const fieldStyles: SxStyleProp = {
  mb: 0,
  display: 'flex'
}
