import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Box, Flex } from 'rebass'
import { Container, Content, Heading } from '@rmr/components'
import parse from 'html-react-parser'

interface ContentFormHubspotWrapperProps {
  portalId: string
  formId: string
  content: string
  formHeadline: string
}
const ContentFormHubspotWrapper = ({ portalId, formId, content, formHeadline }: ContentFormHubspotWrapperProps) => {
  useEffect(() => {
    if (portalId && formId) {
      const script = document.createElement('script')
      // script.src = 'js-eu1.hsforms.net/forms/shell.js'
      script.src = 'https://js.hsforms.net/forms/shell.js'
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.hbspt) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.hbspt.forms.create({
            region: 'eu1',
            portalId: portalId,
            formId: formId,
            target: '#hubspotForm'
          })
        }
      })
    }
  }, [formId, portalId])

  return (
    <Box as="section" py={[6, 10]}>
      <Container>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Box width={[1, 1, 1 / 2]} sx={{ px: [2, 4] }}>
            <Content>{parse(content)}</Content>
          </Box>
          <Box width={[1, 1, 1 / 2]} sx={{ px: [2, 4] }}>
            {!!formHeadline && (
              <Heading size="h5" fontWeight="extraBold" mb={2} textAlign="left">
                {formHeadline}
              </Heading>
            )}
            <div id="hubspotForm"></div>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default ContentFormHubspotWrapper
