import React, { forwardRef } from 'react'
import { SxStyleProp, BoxProps as RebassBoxProps, Box, Flex } from 'rebass'
import { Select as RebassSelect, SelectProps as RebassSelectProps } from '@rebass/forms'

import Label from '../Label'
import LabelTooltip from '../LabelTooltip'
import Error from '../Error'
import FormGroup from '../FormGroup'

import { wrapperStyles } from './Select.styled'
import { fieldStyles, fieldWrapper, inlineLabelStyles, inlineLabelStylesWrapper } from '../Form.styled'

export type SelectOption = {
  key?: string | number
  value: string | number
  label: string
}

export interface SelectProps extends RebassBoxProps {
  /**
   * Field label
   */
  label?: string
  /**
   * Is the label inline or above field?
   */
  labelInline?: boolean
  /**
   * Info text for help
   */
  toolTipInfo?: string
  /**
   * Field placeholder
   */
  placeholder?: string
  /**
   * Field name
   */
  name: string
  /**
   * Select Options
   */
  options: SelectOption[]
  /**
   * Selected Options (for multiple select)
   */
  selectedOptions?: (string | number)[]
  /**
   * Error message
   */
  error?: string
  /**
   * Wrapper Sx prop
   */
  sx?: SxStyleProp
  /**
   * Inner Sx prop
   */
  innerSx?: SxStyleProp
  /**
   * InlineLabel Sx prop
   */
  inlineLabelSx?: SxStyleProp
  /**
   * Rebass Select props
   */
  rebassSelectProps?: RebassSelectProps
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      labelInline,
      toolTipInfo,
      placeholder,
      name,
      options,
      selectedOptions,
      error,
      sx,
      innerSx,
      inlineLabelSx,
      rebassSelectProps,
      ...props
    },
    ref
  ) => {
    const selectOptions: SelectOption[] = placeholder ? [{ value: '', label: placeholder }, ...options] : options
    return (
      <FormGroup {...props} sx={{ ...wrapperStyles, ...sx }}>
        {!labelInline && <LabelTooltip labelText={label} toolTipInfo={toolTipInfo} mb={1} />}
        <Flex sx={fieldWrapper(labelInline)}>
          {labelInline && (
            <Flex sx={{ ...inlineLabelStylesWrapper, ...inlineLabelSx }}>
              <Label sx={inlineLabelStyles}>{label}</Label>
            </Flex>
          )}
          <RebassSelect name={name} sx={{ ...fieldStyles, ...innerSx }} {...rebassSelectProps} ref={ref}>
            {selectOptions.map(({ value, key, label }, index) => {
              return (
                <option key={key || index} value={value}>
                  {label}
                </option>
              )
            })}
          </RebassSelect>
        </Flex>
        {error && <Error>{error}</Error>}
      </FormGroup>
    )
  }
)

Select.defaultProps = {
  label: '',
  labelInline: false,
  toolTipInfo: '',
  placeholder: '',
  name: '',
  options: [],
  error: '',
  sx: {},
  inlineLabelSx: {},
  innerSx: {},
  rebassSelectProps: {}
}

export default Select
