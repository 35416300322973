import React, { FC } from 'react'
import { SxStyleProp } from 'rebass'
import Heading from '../Heading'
import RangeSlider from '../RangeSlider'
import Text from '../Text'
import { StyledEarnResults, StyledEarnSlider } from './Earn.styled'
import theme from '../../theme'

//
// Start Earn Results
//
export interface EarnResultsProps {
  /** Market value price of vehicle */
  price?: number
  /** Number of days the car will be rented out */
  days?: number
  /** Sx Props */
  sx?: SxStyleProp
  /** Children */
  children?: React.ReactNode
}

export const EarnResults: FC<EarnResultsProps> = ({ price, days, children, sx, ...props }) => {
  return (
    <StyledEarnResults bg={theme.colors.primary} px={[2, 15]} py={[2]} mt={[4]} mb={[6]} {...props} sx={{ ...sx }}>
      {children && (
        <>
          <Heading color={theme.colors.textWhite} size={'h1'}>
            {children}
          </Heading>
          <Text color={theme.colors.textWhite}>per month</Text>
        </>
      )}
    </StyledEarnResults>
  )
}

EarnResults.defaultProps = {
  price: null,
  days: null,
  sx: {},
  children: null
}

//
// End Earn Results
//

//
// Start Earn Slider
//

export interface EarnSliderProps {
  /** Headline of the slider box */
  headline?: string
  /** Unit label */
  unit?: string
  /** Plural instance of unit label*/
  unitPlural?: string
  /** Initial value */
  initial?: number
  /** Min value */
  min?: number
  /** Max value */
  max?: number
  /** Unit Left*/
  unitLeft?: boolean
  /** Step Value */
  step?: number
  /** on Change Handler */
  onChange: (values: number[]) => void
  /** Sx Props */
  sx?: SxStyleProp
  /** Children */
  children?: React.ReactNode
}

export const EarnSlider: FC<EarnSliderProps> = ({
  headline,
  unit,
  unitPlural,
  initial,
  min,
  max,
  step,
  unitLeft,
  onChange,
  children,
  sx,
  ...props
}) => {
  return (
    <StyledEarnSlider
      width={1}
      mx={[2]}
      px={[8]}
      pt={[4]}
      pb={[16]}
      mb={[2]}
      bg={theme.colors.bgWhite}
      {...props}
      sx={{ borderRadius: `${theme.borderRadius.medium}` }}
    >
      {headline && (
        <Heading size={'h6'} mb={[6]}>
          {headline}
        </Heading>
      )}
      <RangeSlider
        step={step}
        initialValues={[initial]}
        min={min}
        max={max}
        showStaticLabel={false}
        showthumbLabel={true}
        thumbLabelTop={false}
        unitLeft={unitLeft}
        unit={unit}
        unitPlural={unitPlural}
        limitsSx={{ mt: -8 }}
        onChangeValues={onChange}
        thumbLabelSx={{ fontSize: 'h6' }}
      />
    </StyledEarnSlider>
  )
}

EarnSlider.defaultProps = {
  headline: '',
  unit: '',
  unitPlural: '',
  sx: {},
  children: null,
  unitLeft: false,
  min: 100,
  max: 2000,
  initial: 1000
  // default values set otherwise there is an error that min is equal/bigger than max
}

//
// End Earn Slider
//
