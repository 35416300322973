import React, { useState, useRef } from 'react'
import { Box, SxStyleProp } from 'rebass'
import useMedia from 'use-media'

import theme from '../../theme'
import Link from '../Link'
import Icon from '../Icon'

import useOnClickOutside from '../../hooks/useOnClickOutside'
import useMaxDimension from '../../hooks/useMaxDimension'

import { headingStyles, dropdownStyles, chevronStyles, subMenuStyles, subMenuItemStyles } from './MenuItem.styled'

export type MenuItemType = {
  id?: string
  name: string
  to?: string
  subMenuItems?: MenuItemType[]
}

export interface MenuItemProps extends MenuItemType {
  sx?: SxStyleProp
}

const MenuItem = ({ name, to, subMenuItems, sx }: MenuItemProps) => {
  const isNotDesktop = useMedia({ maxWidth: theme.breakpoints[3] }) // 992px
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [subMenuHeight, setSubMenuHeight] = useState(null)
  const ref = useRef(null)
  useOnClickOutside(ref, () => !isNotDesktop && openSubMenu && setOpenSubMenu(false))
  const [maxDimension, measure] = useMaxDimension(subMenuItems ? subMenuItems.length : 0, 'clientWidth')

  const dropDown = subMenuItems && subMenuItems.length
  return (
    <Box ref={ref} sx={{ position: 'relative', ...sx }}>
      <Box onClick={dropDown ? () => setOpenSubMenu((openSubMenu) => !openSubMenu) : undefined}>
        <Link to={to} sx={headingStyles} aria-label={name}>
          {name} {dropDown && <Icon as="span" name="arrowDown" sx={chevronStyles} />}
        </Link>
      </Box>
      {dropDown && (
        <Box sx={dropdownStyles(openSubMenu, subMenuHeight, maxDimension)}>
          <Box
            as="ul"
            ref={(ul) => ul && ul.clientHeight !== subMenuHeight && setSubMenuHeight(ul.clientHeight)}
            sx={subMenuStyles}
          >
            {subMenuItems.map(({ name, to }, index) => {
              return (
                <Box ref={(element) => measure(element, index)} key={index} as="li" sx={subMenuItemStyles}>
                  <Link to={to}>{name}</Link>
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
    </Box>
  )
}

MenuItem.defaultProps = {
  menuItem: {
    name: 'name'
  },
  sx: {}
}

export default MenuItem
