import { useLocation } from '@reach/router'
import { ButtonSwitch, FilterOption, FilterType, Icon, Text, theme } from '@rmr/components'
import {
  languages as enumLanguages,
  Order,
  SearchVehicleInput,
  useSearchVehicles,
  useSearchVehiclesFilterOptions,
  VehicleFuel,
  VehicleTransmission
} from '@rmr/controllers'
import { enumToOptionsArray } from '@rmr/helpers'
import moment from 'moment'
import queryString, { ParsedQuery } from 'query-string'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Flex } from 'rebass'
import useMedia from 'use-media'
import { languages } from '../../common/i18n'
import { b64_to_utf8, getLink } from '../../utils/helpers'
import Filters from '../Filters'
import { Bounds } from '../Map'
import SearchBar, { SearchBarVariant } from '../SearchBar'
import SearchBarWithSocial from '../SearchBarWithSocial'
import { searchBarStyles } from '../SearchBarWithSocial/SearchBarWithSocial.styled'
import SEO from '../Seo'
import { VehiclesDisplay } from '../VehiclesDisplay'
import {
  backgroundStyles,
  buttonSwitchStyles,
  filtersShowmapStyles,
  mobileButtonsStyles,
  separatorStyles
} from './RentCar.styled'
const vehiclesAreaId = 'vehicles-area'

export interface RentCarQueryParams extends ParsedQuery {
  locality: string
  startDate?: string
  endDate?: string
  sort?: Order
  sortField?: string
  minPrice?: string
  maxPrice?: string
  minYear?: string
  maxYear?: string
  categoryIds?: string[]
  fuelType?: VehicleFuel[]
  transmission?: VehicleTransmission
  delivers?: string
  featureIds?: string[]
  page?: string
  before?: string
  after?: string
  latitude: string
  longitude: string
  placeName: string
  placeId: string
  isMapSearch?: string
  northEastLatitude?: string
  northEastLongitude?: string
  southWestLatitude?: string
  southWestLongitude?: string
}

export interface SearchFilter extends SearchVehicleInput {
  latitude: number
  longitude: number
}

const formatVehicleFilter = (queryParams: RentCarQueryParams) => {
  const {
    startDate,
    endDate,
    sort,
    sortField,
    minPrice,
    maxPrice,
    minYear,
    maxYear,
    categoryIds,
    fuelType,
    transmission,
    delivers,
    featureIds,
    pl = '',
    northEastLatitude,
    northEastLongitude,
    southWestLatitude,
    southWestLongitude,
    isMapSearch
  } = queryParams

  const [latitude, longitude] = b64_to_utf8(pl).split('|')

  return {
    startDate: startDate ? moment.unix(+startDate) : undefined,
    endDate: endDate ? moment.unix(+endDate) : undefined,
    sort: sort ? sort : undefined,
    sortField: sortField ? sortField : undefined,
    price: {
      min: minPrice ? +minPrice : undefined,
      max: maxPrice ? +maxPrice : undefined
    },
    model: {
      after: minYear ? minYear : undefined,
      before: maxYear ? maxYear : undefined
    },
    categoryIds: categoryIds ? (Array.isArray(categoryIds) ? categoryIds : [categoryIds]) : undefined,
    fuelType: fuelType ? (Array.isArray(fuelType) ? fuelType : [fuelType]) : undefined,
    transmission: transmission ? transmission : undefined,
    isDelivery: delivers ? !!delivers : undefined,
    featureIds: featureIds ? (Array.isArray(featureIds) ? featureIds : [featureIds]) : undefined,
    latitude: pl ? parseFloat(latitude) : null,
    longitude: pl ? parseFloat(longitude) : null,
    northEastLatitude: northEastLatitude ? parseFloat(northEastLatitude) : null,
    northEastLongitude: northEastLongitude ? parseFloat(northEastLongitude) : null,
    southWestLatitude: southWestLatitude ? parseFloat(southWestLatitude) : null,
    southWestLongitude: southWestLongitude ? parseFloat(southWestLongitude) : null,
    isMapSearch: !!isMapSearch
  }
}

const formatPlace = (queryParams: RentCarQueryParams) => {
  const { placeId, placeName, isMapSearch } = queryParams

  return {
    placeId,
    placeName: isMapSearch ? 'Map Location' : placeName
  }
}

const RentCar: FC = (props) => {
  const location = useLocation()

  const queryParams = queryString.parse(location.search, { arrayFormat: 'comma' }) as RentCarQueryParams
  const isMapSearch = !!queryParams.isMapSearch

  const initBounds: Bounds = useMemo(() => {
    const { northEastLatitude, northEastLongitude, southWestLatitude, southWestLongitude } = queryParams
    if (northEastLatitude && northEastLongitude && southWestLatitude && southWestLongitude) {
      return {
        northEastLatitude: +northEastLatitude,
        northEastLongitude: +northEastLongitude,
        southWestLatitude: +southWestLatitude,
        southWestLongitude: +southWestLongitude
      }
    }
    return undefined
  }, [queryParams])

  const [searchFilter, setSearchFilter] = useState<SearchFilter>(formatVehicleFilter(queryParams))
  const [place, setPlace] = useState(formatPlace(queryParams))

  const { data, loading: loadingSearch } = useSearchVehicles(searchFilter, 200, undefined, undefined)

  const isMobile = useMedia({ maxWidth: theme.breakpoints[2] }) // 576px
  const [showMap, setShowMap] = useState(true)
  const [showFilters, setShowFilters] = useState(false)
  const filtersRef = useRef<HTMLDivElement | null>(null)

  const {
    categoryOptions = [],
    featureOptions = [],
    yearMin,
    yearMax,
    loading: loadingSearchOptions
  } = useSearchVehiclesFilterOptions()

  const loading = loadingSearch || loadingSearchOptions
  const filters: FilterOption[] = [
    {
      type: FilterType.SingleFilter,
      props: {
        radioMode: isMobile,
        initialValue: searchFilter.sortField === 'price_per_day' && searchFilter.sort ? searchFilter.sort : undefined,
        filterName: languages['en'].rentCar.filters.sortPrice.filterName,
        options: isMobile
          ? [{ label: 'No Sort', value: null }].concat(languages['en'].rentCar.filters.sortPrice.options)
          : languages['en'].rentCar.filters.sortPrice.options,
        onSet: (value) => {
          if (value) {
            updateFilter(['sort', 'sortField'], [value, 'price_per_day'])
          } else {
            updateFilter(['sort', 'sortField'], [null, null])
          }
        }
      }
    },
    {
      type: FilterType.RangeFilter,
      props: {
        filterName: languages['en'].rentCar.filters.price.filterName,
        initialValues: [
          searchFilter.price && searchFilter.price.min
            ? searchFilter.price.min
            : languages['en'].rentCar.filters.price.initialValues[0],
          searchFilter.price.max && searchFilter.price.max
            ? searchFilter.price.max
            : languages['en'].rentCar.filters.price.initialValues[1]
        ],
        min: languages['en'].rentCar.filters.price.min,
        max: languages['en'].rentCar.filters.price.max,
        unit: languages['en'].rentCar.filters.price.unit,
        unitPlural: languages['en'].rentCar.filters.price.unitPlural,
        buttonText: languages['en'].rentCar.filters.price.buttonText,
        onChangeValues: (
          values = [languages['en'].rentCar.filters.price.min, languages['en'].rentCar.filters.price.max]
        ) => {
          if (Array.isArray(values) && values.length === 2) {
            updateFilter(['price'], [{ min: values[0], max: values[1] }])
          } else {
            updateFilter(['price'], [null])
          }
        }
      }
    },
    {
      type: FilterType.MultipleFilter,
      props: {
        initialValues: searchFilter.categoryIds,
        filterName: languages['en'].rentCar.filters.category.filterName,
        options: categoryOptions,
        onSet: (values = []) => {
          updateFilter(['categoryIds'], [values])
        }
      }
    },
    {
      type: FilterType.MultipleFilter,
      props: {
        initialValues: searchFilter.fuelType,
        filterName: languages['en'].rentCar.filters.fuelType.filterName,
        options: enumToOptionsArray(VehicleFuel, enumLanguages.en.enums.VehicleFuel),
        onSet: (values = []) => {
          updateFilter(['fuelType'], [values])
        }
      }
    },
    {
      type: FilterType.SingleFilter,
      props: {
        radioMode: isMobile,
        initialValue: searchFilter.transmission,
        filterName: languages['en'].rentCar.filters.transmission.filterName,
        options: isMobile
          ? [{ label: 'Any', value: null }].concat(
              enumToOptionsArray(VehicleTransmission, enumLanguages.en.enums.VehicleTransmission)
            )
          : enumToOptionsArray(VehicleTransmission, enumLanguages.en.enums.VehicleTransmission),
        onSet: (value) => {
          if (value) {
            updateFilter(['transmission'], [value])
          } else {
            updateFilter(['transmission'], [null])
          }
        }
      }
    }
  ]
  const otherFilters: FilterOption[] = [
    {
      type: FilterType.MultipleFilter,
      props: {
        initialValues: searchFilter.featureIds,
        filterName: languages['en'].rentCar.filters.features.filterName,
        options: featureOptions,
        showButton: false,
        onSet: (values = []) => {
          updateFilter(['featureIds'], [values])
        }
      }
    },
    {
      type: FilterType.MultipleFilter,
      props: {
        checkBoxMode: true,
        initialValues: searchFilter.isDelivery === true ? ['true'] : [],
        filterName: languages['en'].rentCar.filters.isDelivery.filterName,
        options: languages['en'].rentCar.filters.isDelivery.options,
        onSet: (values = []) => {
          updateFilter(['isDelivery'], [Boolean(values[0])])
        }
      }
    },
    // {
    //   type: FilterType.SingleFilter,
    //   props: {
    //     radioMode: true,
    //     initialValue: searchFilter.isDelivery === true ? 1 : searchFilter.isDelivery === false ? 0 : undefined,
    //     filterName: languages['en'].rentCar.filters.isDelivery.filterName,
    //     options: languages['en'].rentCar.filters.isDelivery.options,
    //     onSet: (value) => {
    //       if (value) {
    //         updateFilter(['isDelivery'], [value, 'isDelivery'])
    //       } else {
    //         updateFilter(['isDelivery'], [null])
    //       }
    //     }
    //   }
    // },
    {
      type: FilterType.RangeFilter,
      props: {
        filterName: languages['en'].rentCar.filters.year.filterName,
        initialValues: [
          searchFilter.model && searchFilter.model.after ? +searchFilter.model.after : yearMin,
          searchFilter.model && searchFilter.model.before ? +searchFilter.model.before : yearMax
        ],
        min: yearMin,
        max: yearMax,
        unit: '',
        unitPlural: '',
        buttonText: languages['en'].rentCar.filters.year.buttonText,
        onChangeValues: (values = [yearMin, yearMax]) => {
          if (Array.isArray(values) && values.length === 2) {
            updateFilter(['model'], [{ after: `${values[0]}`, before: `${values[1]}` }])
          } else {
            updateFilter(['model'], [null])
          }
        }
      }
    }

    // {
    //   type: FilterType.RangeFilter,
    //   props: {
    //     filterName: languages['en'].rentCar.filters.price.filterName,
    //     initialValues: [
    //       filter.price && filter.price.min ? filter.price.min : languages['en'].rentCar.filters.price.initialValues[0],
    //       filter.price.max && filter.price.max
    //         ? filter.price.max
    //         : languages['en'].rentCar.filters.price.initialValues[1]
    //     ],
    //     min: languages['en'].rentCar.filters.price.min,
    //     max: languages['en'].rentCar.filters.price.max,
    //     unit: languages['en'].rentCar.filters.price.unit,
    //     unitPlural: languages['en'].rentCar.filters.price.unitPlural,
    //     buttonText: languages['en'].rentCar.filters.price.buttonText,
    //     onChangeValues: (values) => {
    //       if (Array.isArray(values) && values.length === 2) {
    //         updateFilter(['price'], [{ min: values[0], max: values[1] }])
    //       } else {
    //         updateFilter(['price'], [null])
    //       }
    //     }
    //   }
    // },
    // {
    //   type: FilterType.MultipleFilter,
    //   props: {
    //     checkBoxMode: true,
    //     initialValues: filter.categoryIds,
    //     filterName: languages['en'].rentCar.filters.category.filterName,
    //     options: categoryOptions,
    //     onSet: (values = []) => {
    //       updateFilter(['categoryIds'], [values])
    //     }
    //   }
    // },
    // {
    //   type: FilterType.MultipleFilter,
    //   props: {
    //     checkBoxMode: true,
    //     initialValues: filter.fuelType,
    //     filterName: languages['en'].rentCar.filters.fuelType.filterName,
    //     options: fueltypeOptions,
    //     onSet: (values = []) => {
    //       updateFilter(['fuelType'], [values])
    //     }
    //   }
    // },
    // {
    //   type: FilterType.SingleFilter,
    //   props: {
    //     radioMode: true,
    //     initialValue: filter.transmission,
    //     filterName: languages['en'].rentCar.filters.transmission.filterName,
    //     options: [{ label: 'Any', value: null }].concat(transmissionOptions),
    //     onSet: (value) => {
    //       if (value) {
    //         updateFilter(['transmission'], [value])
    //       } else {
    //         updateFilter(['transmission'], [null])
    //       }
    //     }
    //   }
    // },
    // {
    //   type: FilterType.MultipleFilter,
    //   props: {
    //     checkBoxMode: true,
    //     initialValues: filter.featureIds,
    //     filterName: languages['en'].rentCar.filters.features.filterName,
    //     options: featureOptions,
    //     onSet: (values = []) => {
    //       updateFilter(['featureIds'], [values])
    //     }
    //   }
    // }
  ]

  const updateFilter = useCallback(
    (keys: string[], values: any[]) => {
      const filter = {}

      keys.forEach((key, index) => {
        filter[key] = values[index]
      })
      const newFilter = Object.assign({}, searchFilter, filter)
      setSearchFilter(newFilter)

      window.history.pushState('rentals', 'Car rentals near you | RentMyRide', getLink(newFilter))
    },
    [searchFilter]
  )

  useEffect(() => {
    isMobile && setShowMap(false)
  }, [isMobile, setShowMap])
  return (
    <React.Fragment>
      <SEO
        path={'/app/rent-a-car'}
        title={'Car rentals near you | RentMyRide'}
        description={
          "Skip the rental car counter — Book rental cars near you from trusted, local owners on RentMyRide. It's like a rental, just better."
        }
      />
      <SearchBarWithSocial filter={searchFilter}>
        <SearchBar
          place={place}
          onHandleSubmit={(payload) => {
            updateFilter(
              [
                'isMapSearch',
                'northEastLatitude',
                'northEastLongitude',
                'southWestLatitude',
                'southWestLongitude',
                'latitude',
                'longitude',
                'startDate',
                'endDate'
              ],
              [null, null, null, null, null, payload.latitude, payload.longitude, payload.startDate, payload.endDate]
            )
            setPlace({
              placeId: payload.placeId,
              placeName: payload.placeName
            })
          }}
          variant={SearchBarVariant.SEARCH_PAGE}
          filter={searchFilter}
          sx={searchBarStyles}
        />
      </SearchBarWithSocial>
      <Box id={vehiclesAreaId} sx={{ position: 'relative', bottom: [100] }} />
      {!isMobile && (
        <Flex ref={filtersRef} sx={filtersShowmapStyles}>
          <Filters
            loading={loading}
            filters={filters}
            otherFilters={otherFilters}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          ></Filters>
          <Box sx={{ flexShrink: 0, display: ['none', null, null, 'flex'] }}>
            <ButtonSwitch
              label={languages['en'].rentCar.showMapLabel}
              on={showMap}
              onClick={() => setShowMap((showMap) => !showMap)}
              sx={buttonSwitchStyles}
            />
          </Box>
        </Flex>
      )}
      <VehiclesDisplay
        isMapSearch={isMapSearch}
        filter={searchFilter}
        loading={loading}
        vehicles={data?.searchVehicles?.edges || []}
        showMap={showMap}
        isMobile={isMobile}
        updateFilter={updateFilter}
        initBounds={initBounds}
      />
      <Flex sx={mobileButtonsStyles}>
        <ButtonSwitch
          label={languages['en'].rentCar.showMapLabel}
          on={showMap}
          onClick={() => setShowMap((showMap) => !showMap)}
          sx={buttonSwitchStyles}
        />
        <Box sx={separatorStyles} />
        <Flex
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={() => setShowFilters((showFilters) => !showFilters)}
        >
          <Text size="medium" mr={[1]}>
            {languages['en'].rentCar.filtersLabel}
          </Text>
          <Icon name="filter" />
        </Flex>
      </Flex>
      {isMobile && (
        <Box>
          {showFilters && <Box sx={backgroundStyles} />}
          <Filters
            loading={loading}
            filters={[]}
            otherFilters={filters.concat(otherFilters)}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            isMobile={isMobile}
          />
        </Box>
      )}
    </React.Fragment>
  )
}

export default React.memo(RentCar)
