import React from 'react'
import {
  GroupFilter,
  MultipleFilter,
  MultipleFilterProps,
  RangeFilter,
  RangeFilterProps,
  SingleFilter,
  SingleFilterProps,
  FilterType,
  FilterOption
} from '@rmr/components'
import { Box, Flex } from 'rebass'
import { filtersWrapperStyles, filterWrapperStyles, mobileFiltersWrapperStyles, mobileStyles } from './Filters.styled'
import Skeleton from 'react-loading-skeleton'

interface FiltersProps {
  loading: boolean
  filters: FilterOption[]
  otherFilters?: FilterOption[]
  showFilters: boolean
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>
  isMobile?: boolean
}

const Filters = ({
  loading,
  filters,
  otherFilters = [],
  showFilters,
  setShowFilters,
  isMobile = false
}: FiltersProps) => {
  return (
    <Flex sx={isMobile ? mobileFiltersWrapperStyles : filtersWrapperStyles}>
      {filters.map(({ type, props }, index) => {
        let Component
        switch (type) {
          case FilterType.SingleFilter:
            Component = <SingleFilter {...(props as SingleFilterProps)} />
            break
          case FilterType.MultipleFilter:
            Component = <MultipleFilter {...(props as MultipleFilterProps)} />
            break
          case FilterType.RangeFilter:
            Component = <RangeFilter {...(props as RangeFilterProps)} />
            break
        }
        return (
          <Box key={index} sx={filterWrapperStyles}>
            {loading ? (
              <Box width={[100]} height={[40]}>
                <Skeleton style={{ height: '100%' }} />
              </Box>
            ) : (
              Component
            )}
          </Box>
        )
      })}
      {loading && !isMobile ? (
        <Box width={[100]} height={[40]}>
          <Skeleton style={{ height: '100%' }} />
        </Box>
      ) : (
        otherFilters.length > 0 && (
          <Box sx={isMobile ? mobileStyles(showFilters) : {}}>
            <GroupFilter
              filters={otherFilters}
              filterName="Other filters"
              isMobile={isMobile}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </Box>
        )
      )}
    </Flex>
  )
}

export default Filters
