import React from 'react'
import Icon from '../Icon'
import Button from '../Button'

interface ShareProps {
  name?: string
}

const Share: React.FC<ShareProps> = ({ name }) => {
  return (
    <Button
      mx={[1]}
      variant="simpleOutlineRound"
      sx={{
        width: ['42px', '54px'],
        height: ['42px', '54px'],
        fontSize: ['16px', '24px'],
        p: '0',
        flex: '0 0 auto'
      }}
      icon={<Icon name={name} />}
    />
  )
}

Share.defaultProps = {
  name: ''
}

export default Share
