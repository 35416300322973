import { SxStyleProp } from 'rebass'
import theme from '../../theme/theme'
export const headingStyles: SxStyleProp = {
  cursor: 'pointer',
  py: 2,
  px: 2,
  display: ['block !important', null, null, 'unset'],
  textAlign: ['center', null, null, 'unset'],
  color: 'textWhite',
  fontSize: 'medium',
  fontWeight: 'medium',
  textDecoration: 'none',
  transition: 'color .2s',
  '&:hover': {
    color: 'primary'
  }
}

export const dropdownStyles = (openSubMenu: boolean, height: number, width: number = 0): SxStyleProp => ({
  position: ['relative', null, null, 'absolute'],
  bg: 'bgBlack',
  left: 0,
  top: [null, null, null, 'calc(100% + 20px)'],
  overflow: 'hidden',
  height: openSubMenu ? `${height}px` : 0,
  width: `${width}px`,
  transition: 'height .2s',
  borderRadius: `${theme.borderRadius.small}`
})

export const chevronStyles: SxStyleProp = {
  fontSize: 14,
  ml: 1,
  pt: '2px'
}

export const subMenuStyles: SxStyleProp = {
  listStyle: 'none',
  pl: 0,
  pt: [0, null, null, 2],
  pb: [4, null, null, 2]
}
export const subMenuItemStyles: SxStyleProp = {
  whiteSpace: 'nowrap',
  px: [3],
  py: [1],
  a: {
    ...headingStyles
  },
  'a:hover': {
    color: 'primary'
  }
}
