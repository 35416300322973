import React, { FC, useEffect, useMemo, useRef } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { Link, navigate } from 'gatsby'
import { Box, Flex } from 'rebass'
import useMedia from 'use-media'

import {
  ApprovalIntention,
  CreateAccountMutation,
  useAuth,
  useRegistrationForm,
  validationMessages
} from '@rmr/controllers'
import { logGtmEvent, GtmEvent, GtmCategory, getCookie, refCookieName } from '@rmr/helpers'
import 'react-phone-input-2/lib/style.css'
import countryList from 'react-select-country-list'

import {
  Heading,
  Button,
  Input,
  FormFooter,
  PasswordInput,
  FormMessage,
  MessageColor,
  Loader,
  AvatarUploader,
  theme,
  Error,
  Label,
  TermsAndConditions,
  Text,
  Checkbox,
  Select,
  PhoneInput
} from '@rmr/components'

import { languages } from '../../common/i18n'
import { fieldStyles, buttonStyles, formStyles, fullWidthField, wrapperStyles } from './SignupEmailForm.styled'
import { processTrimLowerCase } from '@rmr/helpers'
import { scrollToHeadline } from '../StepsNavigation'
import { getIntentionStr } from '../../utils/helpers'
import AvatarEditor, { ImageState } from 'react-avatar-editor'
import WhatsAppNotice from '../WhatsAppNotice'

const countries = countryList()
  .getData()
  .map((e) => ({ ...e, key: e.value }))

export type FormData = {
  firstName: string
  lastName: string
  email: string
  country: string
  phoneNumber: string
  password: string
  terms: boolean
  referralCode: string
}

interface SignupEmailFormProps extends Partial<StepWizardChildProps> {
  idRef: string
}

const SignupEmailForm: FC<Partial<SignupEmailFormProps>> = ({ nextStep, idRef }) => {
  const { login: setToken } = useAuth()
  const referralCodeCookie = getCookie(refCookieName) ?? ''
  const isMobile = useMedia({ maxWidth: theme.breakpoints[0] }) // 576px
  const avatarRef = useRef<null | AvatarEditor>(null)
  const onSuccess = ({ createAccount }: CreateAccountMutation) => {
    if (createAccount.__typename === 'UserRegisterSuccess') {
      const { referralCode } = createAccount.user.profile
      const data = {
        category: GtmCategory.AUTHENTICATION,
        source: 'EMAIL',
        intention: getIntentionStr(createAccount.user.profile.additionalInfo)
      }
      if (referralCode) {
        Object.assign(data, { referralCode })
      }
      logGtmEvent({
        eventName: GtmEvent.USER_SIGNUP,
        data
      })
      setToken(createAccount.token, createAccount.expiry)
      navigate('/app/create/profile/')
    }
  }

  const onError = (err) => {
    console.log({ err })
  }

  const {
    watch,
    setValue,
    clearErrors,
    register,
    onSubmit,
    errors,
    submitting,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  } = useRegistrationForm({
    onSuccess,
    onError,
    avatarEditor: avatarRef.current,
    referralCode: referralCodeCookie
  })
  const email = watch('email')
  const country = watch('country')
  const phoneNumber = watch('phoneNumber')

  const avatarEditor = (editor) => {
    if (editor) {
      avatarRef.current = editor
    }
  }

  const onLoadSuccess = (imgInfo: ImageState) => {
    if (imgInfo) {
      setValue('avatarReady', true)
      clearErrors('avatarReady')
    }
  }

  const onPhoneChange = (phoneNumber: string) => {
    clearErrors('phoneNumber')
    setValue('phoneNumber', phoneNumber)
  }

  useEffect(() => {
    register('email')
    register('country')
    register('phoneNumber')
    register('avatarReady')
  }, [register])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      submitResult.message !== validationMessages.en.warnErrors && onSubmitError(null, validationMessages.en.warnErrors)
    } else if (submitResult.message === validationMessages.en.warnErrors) {
      onSubmitSuccess()
    }
  }, [errors, onSubmitError, onSubmitSuccess, submitResult.message])

  return (
    <React.Fragment>
      {submitting && <Loader />}
      <Box sx={wrapperStyles}>
        <Flex flexDirection="column" alignItems="center">
          <Heading size="h4" fontWeight="extraBold" mb={4} textAlign="center">
            {languages.en.auth.signup.headline}
          </Heading>
          <Box mb={4}>
            <AvatarUploader ref={avatarEditor} width={isMobile ? 150 : 150} onLoadSuccess={onLoadSuccess} />
            <Label mt={4} sx={{ textAlign: 'center', display: 'block!important' }}>
              Upload a Profile Image
            </Label>
            {errors.avatarReady && (
              <Box mt={1}>
                <Error sx={{ textAlign: 'center' }}>{errors.avatarReady.message}</Error>
              </Box>
            )}
          </Box>
          <Box as="form" onSubmit={onSubmit} sx={formStyles}>
            <Input
              name="firstName"
              label="First Name*"
              sx={{ ...fieldStyles, mr: [0, 3] }}
              ref={register}
              error={errors.firstName && errors.firstName.message}
            />
            <Input
              name="lastName"
              label="Last Name*"
              sx={{ ...fieldStyles }}
              ref={register}
              error={errors.lastName && errors.lastName.message}
            />

            <Input
              type="text"
              name="email"
              label="Email Address*"
              sx={{ ...fieldStyles, mr: [0, 3] }}
              rebassInputProps={{
                autoComplete: 'email',
                value: email,
                onChange: (e) => {
                  errors.email && errors.email.message && clearErrors('email')
                  setValue('email', processTrimLowerCase(e.target.value))
                }
              }}
              error={errors.email && errors.email.message}
            />

            <Select
              name="country"
              label="Your Country*"
              ref={register}
              rebassSelectProps={{
                value: country,
                autoComplete: 'on',
                onChange: (e) => {
                  clearErrors('country')
                  setValue('country', e.target.value)
                }
              }}
              options={countries}
              error={errors.country && errors.country.message}
            />
            <PhoneInput
              key={country}
              fieldLabel="Mobile Number*"
              countryCodeInit={country}
              phoneNumber={phoneNumber}
              onPhoneChange={onPhoneChange}
              errorMessage={errors?.phoneNumber?.message}
              mb={2}
            />

            <PasswordInput
              name="password"
              label="Password*"
              sx={{ ...fieldStyles, mr: [0, 3] }}
              rebassInputProps={{ autoComplete: 'current-password' }}
              ref={register}
              error={errors.password && errors.password.message}
            />
            <Box mb={[2]}>
              <Label mb={1}>
                <Text size="tiny" fontWeight="bold" mr={[2]} flex="1 0 auto">
                  {languages.en.auth.signup.intentionLabel}
                </Text>
              </Label>
              <Checkbox
                name={'intention'}
                textAlign={['left']}
                ref={register}
                rebassCheckboxProps={{
                  value: ApprovalIntention.Owner
                }}
                sx={{ mb: 0 }}
              >
                <Box ml={[2]}>
                  <Text fontWeight="bold" size="small">
                    {languages.en.auth.signup.isOwnerLabel}
                  </Text>
                </Box>
              </Checkbox>
              <Checkbox
                name={'intention'}
                textAlign={['left']}
                ref={register}
                rebassCheckboxProps={{
                  value: ApprovalIntention.Renter
                }}
                sx={{ mb: 0 }}
              >
                <Box ml={[2]}>
                  <Text fontWeight="bold" size="small">
                    {languages.en.auth.signup.isRenterLabel}
                  </Text>
                </Box>
              </Checkbox>
              {errors.intention?.message && <Error>{errors.intention?.message}</Error>}
            </Box>
            <Input
              name="referralCode"
              label="Referral Code"
              sx={{ ...fieldStyles, mr: [0, 3], mb: 5 }}
              ref={register}
              error={errors.referralCode && errors.referralCode.message}
              rebassInputProps={{ readOnly: !!referralCodeCookie }}
            />
            <TermsAndConditions name="terms" error={errors.terms ? errors.terms.message : undefined} ref={register} />
            <WhatsAppNotice />

            <Box sx={{ ...fullWidthField }}>
              <FormMessage
                showMessage={submitResult.submitted && !!submitResult.message}
                message={submitResult.message}
                color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
              />
            </Box>
            <Flex sx={{ ...fullWidthField, justifyContent: 'center' }}>
              <Button type="submit" label={languages.en.auth.buttonSignUp} sx={buttonStyles} />
            </Flex>
            {errors.avatarReady && (
              <Box mt={1}>
                <Error sx={{ textAlign: 'center' }}>{errors.avatarReady.message}</Error>
              </Box>
            )}
          </Box>
          <Link to="/app/login">
            <FormFooter text={languages.en.auth.haveAccount} buttonText={languages.en.auth.buttonSignIn} />
          </Link>
        </Flex>
      </Box>
    </React.Fragment>
  )
}
export default SignupEmailForm
