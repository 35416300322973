import { Content } from '@rmr/components'
import { useProfileUpdate } from '@rmr/controllers'
import { useStaticQuery, graphql } from 'gatsby'
import { languages } from '../../../common/i18n'
import React from 'react'
import { Box } from 'rebass'
import Layout from '../Layout'
import { StepProps } from '../ApprovalToList'

const VerificationCheck = ({ idRef, step, user }: StepProps) => {
  const {
    setValue,
    register,
    onSubmit,
    errors,
    submitting: loadingUpdate
  } = useProfileUpdate({
    defaultValues: user.profile
  })
  const { wp } = useStaticQuery<GatsbyTypes.ApprovalToListRequirementsQuery>(graphql`
    query ApprovalToListRequirements {
      wp {
        themeGeneralSettings {
          approvalToListRequirements {
            approvalToListRequirements
            approvalInternational
          }
        }
      }
    }
  `)
  return (
    <Layout
      badgeProps={{ variant: 'circle', size: [150], icon: step.icon }}
      {...step}
      buttonNextClick={async () => {
        setValue('consent', true)
        await onSubmit()
      }}
      finishLaterButton
      idRef={idRef}
      steps={[]}
    >
      <Box sx={{ px: [0, 2, 4] }}>
        <Content>
          <Box
            dangerouslySetInnerHTML={{
              __html: user.profile?.isSAcitizen
                ? wp.themeGeneralSettings.approvalToListRequirements.approvalToListRequirements
                : wp.themeGeneralSettings.approvalToListRequirements.approvalInternational
            }}
          />
          <p>
            {`${languages['en'].getApprovedList.privacyPolicyText} `}
            <a href="/policies/privacy-policy/" target="_blank">
              {languages['en'].getApprovedList.privacyPolicyLabel}
            </a>
            .
          </p>
        </Content>
        <input type="hidden" {...register('consent')} />
      </Box>
    </Layout>
  )
}

export { VerificationCheck }
