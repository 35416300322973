import { SxStyleProp } from 'rebass'

import { theme } from '@rmr/components'

export const buttonsWrapperStyles: SxStyleProp = {
  display: 'grid',
  gridTemplateColumns: ['repeat(2,1fr)', null, null, 'repeat(3,1fr)'],
  columnGap: [1, 1, 1, 1, 4],
  rowGap: [2, 2, 2, 2, 3]
}

export const uploadImagesWrapperStyles: SxStyleProp = {
  p: 2,
  border: `2px solid ${theme.colors.borderLight}`
}

export const buttonStyles: SxStyleProp = {
  px: [1, null, null, null, 2],
  py: [0],
  height: ['50px', null, null, null, '60px'],
  minWidth: ['80px', null, null, null, '120px']
}
