import React from 'react'
import { Hero, HeroImageGallery, Button, Link } from '@rmr/components'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import GatsbyImage from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import SearchBarWrapper from '../components/SearchBarWrapper'
import { Box, Flex } from 'rebass'

const HeroWrapper = ({ headline, description, type, heroImages, actions }) => {
  const imageCount = (heroImages) => {
    if (heroImages.length > 1) {
      return (
        <HeroImageGallery>
          {heroImages.map((i) => {
            return (
              <GatsbyImage
                key={i.id}
                fluid={i.localFile.childImageSharp.fluid}
                style={{ width: '100%', height: '100%' }}
              />
            )
          })}
        </HeroImageGallery>
      )
    } else {
      return (
        <BackgroundImage
          fluid={heroImages[0].localFile.childImageSharp.fluid}
          style={{ width: '100%', height: '100%' }}
        />
      )
    }
  }
  return (
    <Hero
      headline={parse(headline)}
      description={description}
      type={type}
      images={heroImages && heroImages.length > 0 ? imageCount(heroImages) : null}
    >
      {type === 'search' ? <SearchBarWrapper /> : null}

      {actions && (
        <Flex mt={[2, 4]} alignItems="center" justifyContent="center">
          {actions.map((a, index) => {
            return (
              a.url && (
                <Box mr={[0, 2]} mb={[0, 1]} key={index}>
                  <Link to={a.url && a.url.url}>
                    <Button type="button" variant={a.actionType}>
                      {a.url && a.url.title}
                    </Button>
                  </Link>
                </Box>
              )
            )
          })}
        </Flex>
      )}
    </Hero>
  )
}

export default HeroWrapper

export const HeroFields = graphql`
  fragment HeroFields on WpPage_Layoutsettings_Layout_Hero {
    type
    headline
    description
    heroImages {
      id
      localFile {
        childImageSharp {
          fluid(maxWidth: 1900, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    actions {
      actionType
      url {
        target
        title
        url
      }
    }
  }
`
