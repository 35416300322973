import React from 'react'
const propertyMapping = {
  title: {
    metaProperty: { name: 'title', value: null },
    fallbackPropertyName: 'title'
  },
  metaDesc: {
    metaProperty: { name: 'name', value: 'description' },
    fallbackPropertyName: 'description'
  },
  metaKeywords: {
    metaProperty: { name: 'property', value: 'keywords' },
    fallbackPropertyName: 'keywords'
  },
  opengraphAuthor: {
    metaProperty: { name: 'property', value: 'og:author' },
    fallbackPropertyName: 'author'
  },
  opengraphDescription: {
    metaProperty: { name: 'property', value: 'og:description' },
    fallbackPropertyName: 'description'
  },
  opengraphImage: {
    metaProperty: { name: 'property', value: 'og:image' },
    fallbackPropertyName: 'image',
    imageUrlKey: 'sourceUrl'
  },
  opengraphSiteName: {
    metaProperty: { name: 'property', value: 'og:site_name' },
    fallbackPropertyName: 'siteName'
  },
  opengraphTitle: {
    metaProperty: { name: 'property', value: 'og:title' },
    fallbackPropertyName: 'shortTitle'
  },
  opengraphType: {
    metaProperty: { name: 'property', value: 'og:type' },
    fallbackPropertyName: 'ogType'
  },
  twitterTitle: {
    metaProperty: { name: 'property', value: 'twitter:title' },
    fallbackPropertyName: 'shortTitle'
  },
  twitterDescription: {
    metaProperty: { name: 'property', value: 'twitter:description' },
    fallbackPropertyName: 'description'
  },
  twitterImage: {
    metaProperty: { name: 'property', value: 'twitter:image' },
    fallbackPropertyName: 'image',
    imageUrlKey: 'sourceUrl'
  }
}

interface ExtractMetaTagsArgs {
  seoDefaults: GatsbyTypes.WpConstantSettings_SeoDefaults
  seoIncoming: GatsbyTypes.WpPostTypeSEO
}

export const extractMetaTags = ({ seoDefaults, seoIncoming }: ExtractMetaTagsArgs) => {
  const meta = []
  Object.entries(seoIncoming).forEach(([key, value]) => {
    if (!value && propertyMapping[key]) {
      let fallbackValue
      const metaPropertyName = propertyMapping[key]['metaProperty']['name']
      const metaPropertyValue = propertyMapping[key]['metaProperty']['value']
      const imageKey = propertyMapping[key]['imageUrlKey']
      if (imageKey) {
        fallbackValue = seoDefaults[propertyMapping[key]['fallbackPropertyName']][imageKey]
      } else {
        fallbackValue = seoDefaults[propertyMapping[key]['fallbackPropertyName']]
      }
      if (fallbackValue) {
        meta.push('\n')
        if (key === 'title') {
          meta.push(React.createElement('title', null, fallbackValue))
        } else {
          meta.push(React.createElement('meta', { [metaPropertyName]: metaPropertyValue, content: fallbackValue }))
        }
      }
    }
  })
  return meta
}
