import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'isomorphic-fetch'
import { getAuthToken } from '@rmr/controllers'

const httpLink = createUploadLink({
  uri: `${process.env.GATSBY_NX_RMR_API_URL}/graphql`,
  fetch
})

const cache = new InMemoryCache({
  typePolicies: {
    Vehicle: {
      fields: {
        reviews: relayStylePagination()
      }
    }
  }
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookie if it exists
  const jwtToken = getAuthToken()

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: jwtToken.token ? `Bearer ${jwtToken.token}` : ''
    }
  }
})

const errorLink = onError(({ graphQLErrors, operation, forward, ...rest }) => {
  if (
    graphQLErrors &&
    graphQLErrors.find((err) => {
      if (!Array.isArray(err.message)) {
        return err.message?.match(/unauthorized/i) || err.message?.match(/jwt must be provided/i)
      }
    })
  ) {
    const oldHeaders = operation.getContext().headers
    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: undefined
      }
    })
    // Retry the request, returning the new observable
    return forward(operation)
  }
})

const links = ApolloLink.from([errorLink, httpLink])

export const client = new ApolloClient({
  link: authLink.concat(links),
  cache
})
