export class EarnModule {
  base: number
  baseDayRate: number
  interval: number
  increment: number
  minValue: number

  constructor(base: number, baseDayRate: number) {
    this.base = base
    this.baseDayRate = baseDayRate
    this.interval = 5000
    this.increment = 5
    this.minValue = 10000000
  }

  acceptedTypes = [
    'application/pdf',
    'image/img',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]

  getPricePerDay(marketValue: number, days: number): number {
    const value = marketValue < this.minValue ? this.minValue : marketValue
    const multiplier = Math.ceil((value / 100 - this.base) / this.interval)

    const dayRate = this.baseDayRate + this.increment * multiplier

    return dayRate * days
  }

  getMaxPrice(price: number, percentage) {
    return price + price * (percentage / 100)
  }

  getMinPrice(price: number, percentage) {
    return price - price * (percentage / 100)
  }

  formatPrice(currency: string, price: number) {
    return new Amount(price, currency).formatted
  }
}

export class Amount {
  public price: number
  locale = 'en-ZA'
  currencyCode: string

  constructor(price: number, currencyCode = 'ZAR') {
    this.price = price
    this.currencyCode = currencyCode
  }

  get amount() {
    return (this.price / 100).toFixed(2).toString()
  }

  get amountFloat() {
    return (this.price / 100).toFixed(2)
  }

  get formatted() {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currencyCode,
      minimumFractionDigits: 2
    })
      .format(this.price / 100)
      .replace('ZAR', 'R')
  }

  format(decimals = 2) {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currencyCode,
      minimumFractionDigits: decimals
    })
      .format(this.price / 100)
      .replace('ZAR', 'R')
  }
}
