import { SxStyleProp } from 'rebass'
import { theme } from '@rmr/components'

export const reservationWrapperStyles: SxStyleProp = {
  px: [0, 4],
  py: [4],
  display: 'grid',
  gridTemplateColumns: ['repeat(1, 1fr)', null, null, 'repeat(2, 1fr)'],
  columnGap: [5]
}

export const itemStyles: SxStyleProp = {
  py: [2],
  borderBottom: `1px solid ${theme.colors.borderLight}`,
  alignItems: 'center',
  justifyContent: 'space-between'
}
