import { useCallback } from 'react'
import { useSubmitResult } from '@rmr/controllers'
import { VerifyEmailMutation, useVerifyEmailMutation } from '../lib/generated/generated'

interface useVerifyEmailArgs {
  verify: string
  onSuccess: (response: VerifyEmailMutation) => void
}

export const useVerifyEmail = ({ verify, onSuccess }: useVerifyEmailArgs) => {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const [verifyEmailMutaion, { loading }] = useVerifyEmailMutation()

  const onSubmit = useCallback(async () => {
    try {
      const response = await verifyEmailMutaion({ variables: { verify } })
      const { verifyEmail } = response.data
      if (verifyEmail.__typename === 'AuthTokens') {
        onSuccess(response.data)
        onSubmitSuccess()
      } else if (verifyEmail.message) {
        onSubmitError(null, verifyEmail.message)
      }
    } catch (err) {
      onSubmitError()
    }
  }, [verifyEmailMutaion, onSuccess, onSubmitError, onSubmitSuccess])

  return {
    onSubmit,
    submitting: loading,
    submitResult
  }
}
