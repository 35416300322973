import * as React from 'react'
import moment from 'moment'
import { RouteComponentProps } from '@reach/router'
import { useGetReservationQuery } from '@rmr/controllers'
import { Container, Loader, StepsWrapper, Button, Separator, Text } from '@rmr/components'
import { Box, Flex } from 'rebass'
import { stepsWrapperStyles } from '../ApprovalToList/GetApprovedList/GetApprovedList.styled'
import { languages } from '../../common/i18n'
import { Link, navigate } from 'gatsby'

interface ReservationProps extends RouteComponentProps {
  reservationId?: string
}

export const ReservationExpired: React.FC<ReservationProps> = ({ reservationId }) => {
  const { data, loading } = useGetReservationQuery({
    variables: {
      id: reservationId
    }
  })

  if (loading) {
    return <Loader position="static" />
  }

  if (data.getReservation.__typename !== 'Reservation') {
    navigate('/rent-a-car/')
    return null
  }
  const { vehicle, startDate, endDate } = data.getReservation
  return (
    <Box as="section" sx={{ pt: [15], pb: [6] }}>
      <Container>
        <StepsWrapper
          badgeProps={{ variant: 'circle', size: [150], icon: 'error', color: 'statusError' }}
          title={languages.en.bookingExpired.title}
          // subtitle={languages.en.bookingExpired.subtitle}
          sx={stepsWrapperStyles}
        >
          <Box py={[4]} px={[4]}>
            <Flex sx={{ justifyContent: 'center', mb: 5 }}>
              <Separator bg="statusError" width={[87]} />
            </Flex>
            {languages.en.bookingExpired.textLines.map((text) => (
              <Text size="medium" color="textLight" textAlign="center" mb={2}>
                {text}
              </Text>
            ))}

            <Flex sx={{ justifyContent: 'center', mt: [5] }}>
              <Link
                to={`/app/listing/${vehicle.id}?startDate=${moment(startDate).unix()}&endDate=${moment(
                  endDate
                ).unix()}`}
              >
                <Button variant="primary">{languages.en.booking.expirationButtonText}</Button>
              </Link>
            </Flex>
          </Box>
        </StepsWrapper>
      </Container>
    </Box>
  )
}

export default ReservationExpired
