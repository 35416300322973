import React, { FC, useEffect } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import { Box, Flex } from 'rebass'
import { useAuth, useVerifyEmail, VerifyEmailMutation } from '@rmr/controllers'
import { Loader, stepsWrapperStyles, Button, Container } from '@rmr/components'
import { languages } from '../../common/i18n'
import { wrapperStyles, imageStyles, sectionStyles, stepsWrapper } from '../Signup'
import { GatsbyImage } from 'gatsby-plugin-image'
import Success from '../Success'
import { logGtmEvent, GtmEvent, GtmCategory } from '@rmr/helpers'

interface EmailVerifiedProps extends RouteComponentProps {
  token?: string
}

const VerifyEmail: FC<EmailVerifiedProps> = ({ token }) => {
  const { heroImage } = useStaticQuery<GatsbyTypes.VerifyEmailQuery>(graphql`
    query VerifyEmail {
      heroImage: file(relativePath: { eq: "bg-image6.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)

  const onSuccess = ({ verifyEmail }: VerifyEmailMutation) => {
    logGtmEvent({ eventName: GtmEvent.USER_EMAIL_VERIFIED, data: { category: GtmCategory.AUTHENTICATION } })
  }

  const { onSubmit, submitting, submitResult } = useVerifyEmail({ onSuccess, verify: token })

  useEffect(() => {
    onSubmit()
  }, [])

  return submitting ? (
    <Loader position="static" />
  ) : (
    <Box sx={{ position: 'relative', overflow: 'auto' }}>
      <Box as="section" sx={sectionStyles}>
        {/* <Box sx={imageStyles}>
          <GatsbyImage
            image={heroImage.childImageSharp.gatsbyImageData}
            alt="hero image"
            objectFit="cover"
            style={{ width: '100%', height: '100%' }}
          />
        </Box> */}
        <Container>
          <Flex sx={wrapperStyles}>
            <Box sx={{ ...stepsWrapperStyles, ...stepsWrapper }}>
              <Success
                hasSuccess={submitResult.error ? false : true}
                headline={
                  !submitResult.error
                    ? languages.en.verifyEmail.headlineSuccess
                    : languages.en.verifyEmail.headlineFailed
                }
                text={
                  !submitResult.error
                    ? languages.en.verifyEmail.successfulVerification
                    : languages.en.verifyEmail.failedVerification
                }
              />

              {!submitResult.error && (
                <Flex
                  mt={[3]}
                  justifyContent={['center']}
                  alignItems={['center']}
                  flexDirection={['column', null, 'row']}
                >
                  <Flex mr={[0, null, 2]} mb={[2, null, 0]}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate('/app/list-your-car/')
                      }}
                    >
                      {languages.en.verifyEmail.listcarButtonText}
                    </Button>
                  </Flex>
                  <Flex mr={[0, null, 2]} mb={[2, null, 0]}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate('/rent-a-car/')
                      }}
                    >
                      {languages.en.verifyEmail.rentcarButtonText}
                    </Button>
                  </Flex>
                  <Flex>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate('/app/approval-to-list')
                      }}
                    >
                      {languages.en.verifyEmail.approvalButtonText}
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default VerifyEmail
