import React, { FC } from 'react'
import { SxStyleProp, Box, Flex, BoxProps } from 'rebass'
import Text from '../Text'
import { StyledNotice, closeIconStyles, StyledContainer } from './Notice.styled'
import theme from '../../theme'
import { Icon } from '@rmr/components'
import parse from 'html-react-parser'

export interface NoticeProps extends BoxProps {
  /** Headline text on Notice */
  headline?: string
  /** Descriptive text to support the Headline */
  description?: string
  /** Background color of the Notice */
  bg?: string
  /** Text color of the Notice */
  color?: string
  /** Can this notice be closed? */
  canClose?: boolean
  /** Is the notice active and visible? */
  isActive?: boolean
  /** Sx Props */
  sx?: SxStyleProp
  /** Children */
  children?: React.ReactNode
}

const Notice: FC<NoticeProps> = ({
  onClick,
  headline,
  bg,
  canClose,
  isActive,
  color,
  description,
  children,
  sx,
  ...props
}) => {
  return (
    <StyledNotice {...props} py={[1]} sx={{ ...sx }} bg={bg}>
      <StyledContainer>
        {canClose && (
          <Icon onClick={onClick} name="close" sx={closeIconStyles} fontAwesomeIconProps={{ color: color }} />
        )}
        <Flex
          justifyContent={!children ? 'center' : 'space-between'}
          flexDirection={['column', 'row']}
          alignItems="center"
        >
          <Flex flexDirection="column">
            {headline && (
              <Text textAlign={!children ? ['center'] : ['center', 'left']} size="small" color={color}>
                {parse(headline)}
              </Text>
            )}
            {description && (
              <Text
                as="span"
                textAlign={!children ? 'center' : ['center', 'left']}
                size="small"
                fontWeight={theme.fontWeights.regular}
                color={color}
              >
                {parse(description)}
              </Text>
            )}
          </Flex>
          {children && <Box mt={[1, 0]}>{children}</Box>}
        </Flex>
      </StyledContainer>
    </StyledNotice>
  )
}

Notice.defaultProps = {
  headline: '',
  description: '',
  children: null,
  canClose: true,
  bg: theme.colors.primary,
  color: theme.colors.textWhite,
  sx: {}
}

export default Notice
