import React, { FC } from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'
import { useAuth, useMeQuery, User } from '@rmr/controllers'
import { Loader } from '@rmr/components'

export interface WithUserProps extends RouteComponentProps {
  user?: User
}

interface PrivateRouteProps extends RouteComponentProps {
  component: React.FC<WithUserProps>
  requirement?: 'isApprovedOwner' | undefined
  step?: string
}

const WithUser = ({ children }) => {
  const { data, loading } = useMeQuery()

  return children(data, loading)
}

const PrivateRoute: FC<PrivateRouteProps> = ({ navigate, component: Component, location, requirement, ...rest }) => {
  const { isAuthed, loading } = useAuth()
  if (loading) {
    return <Loader position="static" />
  }

  if (isAuthed) {
    return (
      <WithUser>
        {(user, loading) => {
          if (loading) return <Loader position="static" />

          if (requirement && !user.me.profile[requirement]) {
            return <Redirect noThrow to="/app/approval-to-list" />
          }

          return <Component {...rest} user={user.me} />
        }}
      </WithUser>
    )
  }

  return <Redirect noThrow to={`/app/login?redirect=${location.pathname}`} state={location} />
}

export default PrivateRoute
