import React, { forwardRef } from 'react'
import { Box, BoxProps, SxStyleProp } from 'rebass'
import Icon from '../Icon'
import Text from '../Text'
import { IconType } from '../Icon/Icon.options'
import { circleStyles, circleOutlinedStyles } from './Badge.styled'

const variants = {
  circle: circleStyles,
  circleOutlined: circleOutlinedStyles
}

export type BadgeVariant = keyof typeof variants

export interface BadgeProps extends BoxProps {
  /** Badge Variant */
  variant: BadgeVariant
  /** Badge color */
  color?: string
  /** Icon name */
  icon?: IconType | string
  /** Badge content color */
  contentColor?: string
  /** Label */
  label?: string
  /** Image */
  image?: React.ReactNode
  /** FontAwesomeBadge Props */
  sx?: SxStyleProp
}

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ variant, color, icon, contentColor, label, image, sx, ...props }, ref) => {
    return (
      <Box ref={ref} sx={{ ...variants[variant](color, contentColor), ...sx }} {...props}>
        {icon && <Icon name={icon} />}
        {label && (
          <Text size="small" color={contentColor}>
            {label}
          </Text>
        )}
        {image && image}
      </Box>
    )
  }
)

export default Badge
Badge.defaultProps = {
  variant: null,
  color: 'bgGrayLight',
  icon: null,
  contentColor: 'textWhite',
  label: '',
  image: null,
  sx: {},
  size: [200]
}
