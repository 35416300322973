import React, { useState, FC } from 'react'
import { Box, BoxProps, SxStyleProp } from 'rebass'

import Text, { TextSize } from '../../Text'

const messageWrapperStyles = {
  overflow: 'hidden',
  transition: 'height 0.2s'
}

export type MessageColor = 'statusSuccess' | 'statusError' | 'statusWarning' | 'statusInfo'

export interface FormMessageProps extends BoxProps {
  /** Message */
  message: string
  /** Show Message? */
  showMessage: boolean
  /** Message Color  */
  color: MessageColor
  /** Font Size  */
  size?: TextSize
  /** sx prop  */
  sx?: SxStyleProp
}

const FormMessage: FC<FormMessageProps> = ({ message, showMessage, color, size, sx, ...props }) => {
  const [messageHeight, setMessageHeight] = useState(0)

  const messageRefCallBack = (element: HTMLParagraphElement) => {
    if (element && element.clientHeight > messageHeight) {
      setMessageHeight(element.clientHeight)
    }
  }
  return (
    <Box height={showMessage && !!message ? `${messageHeight}px` : 0} sx={{ ...messageWrapperStyles, sx }} {...props}>
      <Text as="p" ref={messageRefCallBack} color={color} size={size} fontWeight="bold">
        {message && (
          <Box
            py={2}
            sx={{ a: { color: 'primary', textDecoration: 'underline' } }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </Text>
    </Box>
  )
}
export default FormMessage

FormMessage.defaultProps = {
  message: 'This is a message',
  showMessage: false,
  color: 'statusSuccess',
  size: 'medium',
  sx: {}
}
