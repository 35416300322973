import React, { forwardRef } from 'react'
import { Flex, SxStyleProp } from 'rebass'
import { Textarea as RebassTextarea, TextareaProps as RebassTextareaProps } from '@rebass/forms'

import LabelTooltip from '../LabelTooltip'
import Error from '../Error'
import FormGroup from '../FormGroup'

import { fieldStyles, fieldWrapper } from '../Form.styled'

export interface TextareaProps {
  /**
   * Field label
   */
  label?: string
  /**
   * Info text for help
   */
  toolTipInfo?: string
  /**
   * Field placeholder
   */
  placeholder?: string
  /**
   * Field name
   */
  name: string
  /**
   * Error message
   */
  error?: string
  /**
   * Wrapper Sx prop
   */
  sx?: SxStyleProp
  /**
   * Inner Sx prop
   */
  innerSx?: SxStyleProp
  /**
   * Rebass Textarea Props
   */
  rebassTextareaProps?: RebassTextareaProps
}
const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, toolTipInfo, error, placeholder, name, innerSx, rebassTextareaProps, ...props }, ref) => {
    return (
      <FormGroup {...props}>
        <LabelTooltip labelText={label} toolTipInfo={toolTipInfo} mb={1} />
        <Flex sx={fieldWrapper()}>
          <RebassTextarea
            {...{ placeholder, name }}
            sx={{ ...fieldStyles, ...innerSx }}
            {...rebassTextareaProps}
            ref={ref}
          />
        </Flex>
        {error ? <Error>{error}</Error> : null}
      </FormGroup>
    )
  }
)
Textarea.defaultProps = {
  label: null,
  toolTipInfo: null,
  placeholder: null,
  name: null,
  error: null,
  sx: {},
  innerSx: {},
  rebassTextareaProps: {}
}

export default Textarea
