import { theme } from '@rmr/components'
import { SxStyleProp } from 'rebass'

export const wrapperStyles: SxStyleProp = {
  zIndex: theme.zIndex.header - 1,
  bg: 'bgLight',
  py: [6]
}

export const searchBarStyles: SxStyleProp = {
  flex: ['1 0 70%', null, null, '1 0 70%']
}

export const barSocialStyles: SxStyleProp = {
  flexDirection: ['column', null, null, 'row'],
  justifyContent: [null, null, null, 'space-between']
}

export const socialWrapperStyles: SxStyleProp = {
  flex: ['1 0 30%', null, null, '1 0 30%'],
  justifyContent: ['center', null, null, 'flex-end'],
  mt: [0],
}

export const socialStyles: SxStyleProp = {
  ml: [2],
  cursor: 'pointer'
}
