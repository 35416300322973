import { useCallback } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateDisputeMutation, CreateDisputeInput, ZendeskTicketType } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'
import { ZendeskFileEntity } from '../types'
import { validationMessages } from '../i18n'

interface DefaultValues {
  subject: string
  body: string
  attachments: ZendeskFileEntity[]
  includeOthers: boolean
}

interface useCreateDisputeArgs {
  onSuccess?: () => void
  onError?: (e: Error) => void
  defaultValues?: DefaultValues
  handoverId: string
}

const createDisputeSchema = yup.object().shape({
  subject: yup.string().required(validationMessages.en.required),
  body: yup.string().required(validationMessages.en.required)
})

export function useCreateDispute({ onSuccess, onError, handoverId }: useCreateDisputeArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()
  const form = useForm({
    resolver: yupResolver(createDisputeSchema),
    defaultValues: {
      subject: '',
      body: '',
      includeOthers: false,
      attachments: []
    }
  })

  const [createDispute, { loading }] = useCreateDisputeMutation()

  const onSubmit = useCallback(
    async (data) => {
      try {
        const { attachments, ...rest } = data as DefaultValues
        const input: CreateDisputeInput = {
          ...rest,
          type: ZendeskTicketType.Incident,
          attachments: attachments.map((e) => e.token)
        }

        const response = await createDispute({ variables: { input, handoverId } })

        if (response.errors) {
          onSubmitError()
          return
        }

        if (response.data) {
          onSuccess && onSuccess()
          onSubmitSuccess('Claim logged successfully. Check your email inbox')
        }
      } catch (err) {
        console.log(err)
        onError && onError(err)
        onSubmitError()
      }
    },
    [createDispute, handoverId, onSubmitError, onSuccess, onSubmitSuccess, onError]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult
  }
}
