import { SxStyleProp } from 'rebass'
import { theme } from '@rmr/components'

export const vehiclesContainer = (showMap: boolean): SxStyleProp => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: showMap
    ? ['0 100%', null, null, '50% 50%', '66.66667% 33.3333%']
    : ['100% 0%', null, null, '100% 0%', '100% 0%']
})

export const vehiclesWrapperStyles: SxStyleProp = {
  display: 'grid',
  gridTemplateColumns: ['minmax(280px, 1fr)', 'minmax(300px, 1fr)', 'repeat(auto-fill, minmax(350px, 1fr))'],
  gap: [1],
  mt: [4, null, null, 0],
  mx: [0, null, null, -2]
}

export const vehicleWrapperStyles = (active: boolean): SxStyleProp => ({
  cursor: 'pointer',
  transformOrigin: 'center center',
  transform: active ? 'scale(1)' : 'scale(1)',
  transition: 'transform 0.2s'
})

export const mapContainerStyles = (isMobile: boolean, showMap: boolean): SxStyleProp => ({
  position: isMobile ? 'fixed' : 'sticky',
  top: `80px`,
  height: 'calc(100vh - 80px)',
  width: isMobile && showMap ? '100vw' : 'auto',
  zIndex: isMobile ? theme.zIndex.max - 1 : 0
})
