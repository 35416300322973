// 10 MB
export const maxSize = 10485760

export const documentTypes: string[] = [
  'application/pdf',
  'image/img',
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

export const imageTypes = ['image/img', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']

export const banks = [
  'Capitec',
  'Nedbank',
  'First National Bank',
  'Absa',
  'Standard Bank',
  'Investec',
  'Discovery',
  'Bidvest',
  'Other'
]

export const provinces = [
  'Eastern Cape',
  'Free State',
  'Gauteng',
  'KwaZulu-Natal',
  'Limpopo',
  'Mpumalanga',
  'Northern Cape',
  'North West',
  'Western Cape'
]

export const bankAccountTypes = ['Cheque/Current', 'Savings', 'Transmission']

export const ZA = 'ZA'
