import React from 'react'
import { SxStyleProp } from 'rebass'

import Text from '../../Text'

const errorStyles: SxStyleProp = {
  // position: 'absolute',
  // top: 'calc(100% + 0px)',
  // left: 0,
}

interface ErrorProps {
  children: React.ReactNode
  sx?: SxStyleProp
}

const Error = ({ children, sx = {}, ...props }: ErrorProps) => (
  <Text color="statusError" size="tiny" fontWeight="bold" sx={{ ...errorStyles, ...sx }} {...props}>
    {children}
  </Text>
)

export default Error
