import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Box, Flex } from 'rebass'
import { Container, Content } from '@rmr/components'
import parse from 'html-react-parser'
import ReferralSignup from '../components/ReferralSignup'

const SELF_HOSTED_FORMS = {
  signup: ReferralSignup
}

interface ContentSelfHostedFormWrapperProps {
  formId: string
  content: string
  formHeadline: string
}
const ContentSelfHostedFormWrapper = ({ formId, content, formHeadline }: ContentSelfHostedFormWrapperProps) => {
  const Form = SELF_HOSTED_FORMS[formId]
  if (!Form) return null
  return (
    <Box as="section" py={[6, 10]}>
      <Container>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Box width={[1, 1, 1 / 2]} sx={{ px: [2, 4] }}>
            <Content>{parse(content)}</Content>
          </Box>
          <Box width={[1, 1, 1 / 2]} sx={{ px: [2, 4] }}>
            <Form formHeadline={formHeadline} />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default ContentSelfHostedFormWrapper
