import { theme } from '@rmr/components'
import { SxStyleProp } from 'rebass'

export const wrapperStyles = (active: boolean): SxStyleProp => ({
  position: 'relative',
  width: 20,
  height: 20,
  transform: 'translate(-50%,-50%)',
  zIndex: active ? 1 : 0,
  ':hover': {
    zIndex: 2
  }
})

export const iconsWrapper: SxStyleProp = {
  position: 'relative',
  height: '100%'
}

export const pinIcon = (mouseOver: boolean, active: boolean): SxStyleProp => ({
  position: 'absolute',
  top: '0',
  left: '0',
  color: active ? 'bgBlack' : 'primary',
  '&:first-of-type': {
    svg: {
      width: [20],
      height: [20],
      transition: 'transform 0.3s',
      opacity: mouseOver && !active ? 1 : 0,
      transform: mouseOver && !active ? 'scale(1.2)' : 'scale(1)'
    }
  },
  '&:last-child': {
    cursor: 'pointer',
    svg: {
      opacity: mouseOver && !active ? 0 : 1,
      width: [mouseOver && !active ? 0 : 20],
      height: [mouseOver && !active ? 0 : 20],
      transform: mouseOver && !active ? 'scale(0)' : 'scale(1)',
      transition: 'transform 0.3s',
      '&:hover': {
        transform: active ? 'scale(1)' : 'scale(1.2)',
        color: 'bgBlack'
      }
    }
  }
})

export const vehicleCardWrapperStyles: SxStyleProp = {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%,calc(-100% - 20px))'
}
export const cardVehicleStyles: SxStyleProp = {
  width: [250, null, 300],
  fontFamily: theme.fonts.sans,
  img: {
    maxWidth: '100% !important'
  }
}
