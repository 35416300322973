import React, { FC } from 'react'
import { Box, SxStyleProp, Text } from 'rebass'
import Skeleton from 'react-loading-skeleton'
import theme from '../../theme'
import Heading from '../Heading/Heading'

export interface HeadingListingProps {
  /** Set the primary headline */
  headline: string
  /** Add a supporting subtitle */
  subtitle: string
  /** Sx Props */
  sx?: SxStyleProp
}

const HeadingListing: FC<HeadingListingProps> = ({ headline, subtitle, sx, ...props }) => {
  return (
    <>
      <Box mb={[2, 4]} {...props} sx={{ ...sx }}>
        <Text mb={[1, 2]} size={'medium'} color={theme.colors.textGrayLight} fontWeight={theme.fontWeights.semiBold}>
          {subtitle || <Skeleton style={{ width: '100px' }} />}
        </Text>
        <Heading size={'h3'} lineHeight={1} sx={{ fontSize: '28px !important' }}>
          {headline || <Skeleton style={{ width: '200px' }} />}
        </Heading>
      </Box>
    </>
  )
}

HeadingListing.defaultProps = {
  headline: '',
  subtitle: '',
  sx: {}
}

export default HeadingListing
