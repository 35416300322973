import { Button, Input, RangeDates } from '@rmr/components'
import { SearchVehicleInput } from '@rmr/controllers'
import { commaSeparatedStringsToArray } from '@rmr/helpers'
import React, { useEffect, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { Flex, SxStyleProp } from 'rebass'
import { languages } from '../../common/i18n'
import { buttonStyles, datesStyles, inputStyles, labelStyles, locationStyles, wrapperStyles } from './SearchBar.styled'

const autocompleteCountries = commaSeparatedStringsToArray(process.env.GATSBY_AUTOCOMPLETE_COUNTRIES)

export enum LocationSources {
  LOCATION_SEARCH,
  VEHICLE_LOCATIONS
}

export enum SearchBarVariant {
  DEFAULT = 'default',
  SEARCH_PAGE = 'search_page'
}

const stateDateWrapper = (date) => date

const minimumRentalDays = process.env.GATSBY_MINIMUM_RENTAL_PERIOD
  ? +process.env.GATSBY_MINIMUM_RENTAL_PERIOD
  : undefined

interface SearchBarProps {
  variant?: SearchBarVariant
  filter?: SearchVehicleInput
  sx?: SxStyleProp
  onHandleSubmit: (payload: any) => void
  place?: { placeName: string; placeId: string }
}

const SearchBar = ({
  variant = SearchBarVariant.DEFAULT,
  filter = {},
  sx = {},
  onHandleSubmit,
  place
}: SearchBarProps) => {
  const [error, setError] = useState('')

  const [form, setForm] = useState({
    latitude: null,
    longitude: null,
    placeId: place?.placeId ?? '',
    placeName: place?.placeName ?? '',
    startDate: filter.startDate,
    endDate: filter.endDate
  })

  useEffect(() => {
    if (filter.isMapSearch) {
      setForm((currentForm) => ({
        ...currentForm,
        placeName: 'Map Search'
      }))
    }
  }, [filter.isMapSearch])
  const { ref: googlePlacesRef } = usePlacesWidget({
    // Loaded on gatsby-ssr
    // apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    inputAutocompleteValue: 'off',
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: autocompleteCountries }
    },
    onPlaceSelected: (place) => {
      if (place.geometry) {
        setForm((currentForm) => ({
          ...currentForm,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          placeId: place.place_id,
          placeName: place.formatted_address
        }))
      }
    }
  })

  const onDatesChange = ({ startDate, endDate }) => {
    setError('')
    setForm({
      ...form,
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate)
    })
  }

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((currentForm) => ({
      ...currentForm,
      placeName: e.target.value
    }))
  }

  const onSubmit = () => {
    onHandleSubmit && onHandleSubmit(form)
  }

  return (
    <Flex sx={{ ...wrapperStyles, ...sx }}>
      <Input
        ref={googlePlacesRef}
        name="search_bar_location_id"
        label={languages['en'].searchBar.locationLabel}
        labelInline={true}
        inlineLabelSx={labelStyles(variant)}
        innerSx={inputStyles(variant)}
        placeholder={'Search city, airport, address or hotel'}
        rebassInputProps={{
          autoComplete: 'on',
          value: form.placeName,
          onChange: onChangeInput,
          onFocus: () => {
            if (form.placeName === 'Map Search') {
              setForm((currentForm) => ({
                ...currentForm,
                placeName: ''
              }))
            }
          }
        }}
        sx={locationStyles(variant)}
      />

      <RangeDates
        variant={variant}
        startDate={form.startDate}
        endDate={form.endDate}
        onDatesChange={onDatesChange}
        minimumDays={minimumRentalDays > 0 ? minimumRentalDays : 0}
        error={error}
        sx={datesStyles}
      />
      <Button variant="primary" label={languages['en'].searchBar.buttonLabel} sx={buttonStyles} onClick={onSubmit} />
    </Flex>
  )
}

export default SearchBar
