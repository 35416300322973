import { useStaticQuery, graphql } from 'gatsby'

import { wpMenuItemsMapping, WpMenuItems } from '../utils/helpers'

type WpMenu = {
  slug: string
  menuItems: WpMenuItems
}

interface Data {
  wp: {
    generalSettings: {
      url: string
    }
  }
  allWpMenu: { edges: { node: WpMenu }[] }
}

export const useWpMenu = () => {
  const { wp, allWpMenu }: Data = useStaticQuery<GatsbyTypes.WpMenuQuery>(graphql`
    query WpMenu {
      wp {
        generalSettings {
          url
        }
      }
      allWpMenu {
        edges {
          node {
            menuItems {
              nodes {
                id
                label
                url
                parentId
                childItems {
                  nodes {
                    id
                    label
                    url
                    parentId
                  }
                }
              }
            }
            slug
          }
        }
      }
    }
  `)
  const menuItems = allWpMenu.edges.reduce((acc, { node }) => {
    const { slug, menuItems } = node
    const filteredMenuItems: WpMenuItems = { nodes: menuItems.nodes.filter(({ parentId }) => !parentId) }
    acc[slug] = wpMenuItemsMapping(filteredMenuItems, wp.generalSettings.url)
    return acc
  }, {})
  return menuItems
}
