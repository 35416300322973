import React, { forwardRef } from 'react'
import { Card as RebassCard, CardProps as RebassCardProps, Box, Flex, SxStyleProp, Image } from 'rebass'
import Content from '../Content'
import parse from 'html-react-parser'
import { CardPostStyles, imageWrapperStyles } from './CardPost.styled'
import styled from '@emotion/styled'
import theme from '../../theme'
import Button from '../Button'
import Heading from '../Heading'
import Text from '../Text'

export interface CardPostProps extends RebassCardProps {
  /** Card Image */
  image?: string
  /**Image Alt text */
  alt?: string
  /** title under image */
  title?: string
  /** card category under image */
  category?: string
  /** title under image */
  excerpt?: string
  /** Sx Styles */
  sx?: SxStyleProp
}

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const CardPost = forwardRef<HTMLDivElement, CardPostProps>(
  ({ image, title, excerpt, category, alt, sx, ...props }, ref) => {
    return (
      <RebassCard ref={ref} {...props} sx={{ ...(CardPostStyles as SxStyleProp), ...sx }}>
        <Box mb={2} sx={imageWrapperStyles}>
          {image && <StyledImage src={image} alt={alt} />}
        </Box>
        {category && (
          <Box>
            <Text size="small" color="textLight">
              {category}
            </Text>
          </Box>
        )}
        {title && (
          <Box mb={2}>
            <Heading size="h3" color={theme.colors.primary}>
              {title}
            </Heading>
          </Box>
        )}
        {excerpt && (
          <Box color={theme.colors.textDark}>
            <Content mb={2}>{excerpt}</Content>
          </Box>
        )}
        <Box mb={2}>
          <Button variant="transparent" sx={{ textDecoration: 'underline', padding: 0 }} size="body" color="textLight">
            {'Read more'}
          </Button>
        </Box>
      </RebassCard>
    )
  }
)

CardPost.defaultProps = {
  image: null,
  alt: '',
  label: '',
  sx: {}
}

export default CardPost
