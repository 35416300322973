import { navigate } from 'gatsby'
import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Loader } from '@rmr/components'
const Default: FC<RouteComponentProps> = () => {
  useEffect(() => {
    navigate('/')
  }, [])
  return <Loader position="static" />
}

export default Default
