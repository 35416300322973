import React from 'react'
import StepWizard, { StepWizardChildProps } from 'react-step-wizard'
import { Box } from 'rebass'
import { Container, IconType, WizardStep } from '@rmr/components'
import { IDDocument, Selfie, PersonalDocuments, VerificationCheck, EmailConfirm, MobileNumber, Success } from './steps'
import { idRef } from './helpers'
import { User, UserStatus } from '@rmr/controllers'
import { languages } from '../../common/i18n'
import SEO from '../Seo'

const customTransitions = {
  enterRight: 'animated-steps step-enter-right',
  enterLeft: 'animated-steps step-enter-left',
  exitRight: 'animated-steps step-exit-right',
  exitLeft: 'animated-steps step-exit-left'
}

type StepType = {
  icon: IconType
  title: string
  subtitle: string
  isHidden?: boolean
  buttonNextlabel?: string
}

export interface StepProps extends Partial<StepWizardChildProps> {
  idRef: string
  user?: User
  step: StepType
  steps?: WizardStep[]
}

interface Step extends StepType {
  content: React.FC<StepProps>
}

const steps: Step[] = [
  {
    title: 'Identification',
    subtitle: 'Please provide information you will be using for verification.',
    icon: 'user',
    content: IDDocument
  },
  {
    title: 'Selfie',
    subtitle: 'Get ready to strike a pose and take a selfie',
    icon: 'camera',
    content: Selfie
  },
  {
    title: 'Documents',
    subtitle: 'Time for some important docs',
    icon: 'document',
    content: PersonalDocuments
  },
  {
    title: 'Contact',
    subtitle: 'Enter your mobile number and we’ll text you a verification code.',
    icon: 'mobile',
    content: MobileNumber
  },
  {
    title: '',
    subtitle: '',
    icon: 'check',
    isHidden: true,
    content: Success
  }
]

const ApprovalToList = ({ user }: { user: User }) => {
  const getWizardSteps = () => {
    if (!user.profile.consentAt) {
      return (
        <VerificationCheck
          idRef={idRef}
          user={user}
          steps={[]}
          step={{
            title: languages['en'].getApprovedList.title,
            subtitle: languages['en'].getApprovedList.subTitle,
            icon: 'eyeOpen',
            buttonNextlabel: languages['en'].getApprovedList.buttonNextLabel
          }}
        />
      )
    }

    return steps.map((step) => {
      const { content: Component, ...rest } = step
      return (
        <Component
          idRef={idRef}
          key={step.title}
          step={rest}
          user={user}
          steps={steps.filter((p) => !p.isHidden).map((s) => ({ name: s.title }))}
        />
      )
    })
  }

  return (
    <Box as="section" sx={{ pt: [15], pb: [6] }}>
      <SEO
        title="Get Approval | RentMyRide"
        description="RentMyRide is building a car sharing platform based upon trust & community. Keeping you safe is a top priority."
        path="/app/approval-to-list"
      />
      <Container>
        {user.status === UserStatus.Review ? (
          <Success
            idRef={idRef}
            user={user}
            step={{
              title: languages['en'].approvalConfirmation.title,
              subtitle: languages['en'].approvalConfirmation.subtitle,
              icon: 'check',
              buttonNextlabel: null
            }}
          />
        ) : (
          <StepWizard transitions={customTransitions} isLazyMount={true}>
            {getWizardSteps()}
          </StepWizard>
        )}
      </Container>
    </Box>
  )
}

export default ApprovalToList
