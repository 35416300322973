import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Box } from 'rebass'
import { FieldErrors } from 'react-hook-form'

import { VehicleDocument, VehicleDocumentEnum, VehicleDocumentTypeEnum } from '@rmr/controllers'

import { filesWrapperStyles } from './UploadDocuments.styled'
import UploadDocument from './UploadDocument'
import { languages } from '../../../../common/i18n'

interface UploadDocumentsProps {
  existingDocuments: VehicleDocument[]
  updateStateDocuments: (document: VehicleDocumentEnum, value: string) => void
  errors?: FieldErrors
}

const UploadDocuments = ({ existingDocuments, updateStateDocuments, errors = {} }: UploadDocumentsProps) => {
  const existingDocumentsMap = useMemo(() => {
    return existingDocuments.reduce(
      (acc, cur) => {
        const { id, type } = cur
        acc[VehicleDocumentEnum[type]] = id
        return acc
      },
      { licenceDisk: '', roadsworthyCertificate: '', trackingDeviceCertificate: '' }
    )
  }, [existingDocuments])

  const currentDocuments = useRef(existingDocumentsMap).current

  const initFiles = useCallback(
    (type: VehicleDocumentTypeEnum) => {
      const document = existingDocuments.find((e) => {
        return e.type === type
      })
      if (document) {
        const newFile = new File([''], document.type)
        return [newFile]
      } else return []
    },
    [existingDocuments]
  )

  return (
    <Box sx={filesWrapperStyles}>
      <UploadDocument
        updateStateDocuments={updateStateDocuments}
        currentDocuments={currentDocuments}
        documentType={VehicleDocumentEnum.LICENSE_DISK}
        initFiles={initFiles(VehicleDocumentTypeEnum.LicenseDisk)}
        label={languages['en'].listYourCar.vehicleInformation.licenceDiskLabel}
        description={languages['en'].listYourCar.vehicleInformation.licenceDiskNote}
        error={errors.licenceDisk ? errors.licenceDisk.message : ''}
      />
      <UploadDocument
        updateStateDocuments={updateStateDocuments}
        initFiles={initFiles(VehicleDocumentTypeEnum.TrackerDocumentation)}
        currentDocuments={currentDocuments}
        documentType={VehicleDocumentEnum.TRACKER_DOCUMENTATION}
        label={languages['en'].listYourCar.vehicleInformation.trackingDeviceCertificateLabel}
        description={languages['en'].listYourCar.vehicleInformation.trackingDeviceCertificateNote}
        error={errors.trackingDeviceCertificate ? errors.trackingDeviceCertificate.message : ''}
      />
      <UploadDocument
        updateStateDocuments={updateStateDocuments}
        initFiles={initFiles(VehicleDocumentTypeEnum.RoadworthyCertificate)}
        currentDocuments={currentDocuments}
        documentType={VehicleDocumentEnum.ROADWORTHY_CERTIFICATE}
        label={languages['en'].listYourCar.vehicleInformation.roadworthyCertificateLabel}
        description={languages['en'].listYourCar.vehicleInformation.roadworthyCertificateNote}
        error={errors.roadsworthyCertificate ? errors.roadsworthyCertificate.message : ''}
      />
    </Box>
  )
}

export default UploadDocuments
