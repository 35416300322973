import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationMessages, languages } from '../i18n'
import { useForgotPasswordMutation } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'
interface UseForgotPasswordArgs {
  onSuccess?: () => void
  onError?: (e: Error) => void
}

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email(validationMessages.en.email)
    .required(validationMessages.en.required)
    .test('email', validationMessages.en.email, (val) => val && val.length >= 6)
})

export function useForgotPassword({ onSuccess, onError }: UseForgotPasswordArgs = {}) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: ''
    }
  })

  const [forgotPasswordMutation, { loading }] = useForgotPasswordMutation()

  const onSubmit = useCallback(
    async (data: { email: string }) => {
      try {
        const response = await forgotPasswordMutation({ variables: { input: data } })
        const { forgotPassword } = response.data
        if (forgotPassword.__typename === 'ForgotPasswordSuccess') {
          onSuccess()
          onSubmitSuccess()
        } else if (forgotPassword.__typename === 'EmailNotFoundError') {
          onSubmitSuccess(languages.en.forgotPassword.emailNotFound)
        } else {
          onSubmitError()
        }
      } catch (err) {
        onError(err)
        onSubmitError('error', err.message)
      }
    },
    [forgotPasswordMutation, onSuccess, onError, onSubmitError, onSubmitSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult,
    onSubmitSuccess
  }
}
