export const fieldStyles = {
  width: '100%',
  flex: '1',
  mb: 1
}

export const buttonStyles = {
  mt: [2],
  mx: 'auto'
}
