import React from 'react'
import { Box, SxStyleProp, BoxProps } from 'rebass'

import Icon from '../Icon'

import { iconsStyles, wrapperStyles } from './StatusWrapper.styled'

export interface StatusWrapperProps extends BoxProps {
  /** Is Wrapper active */
  on: boolean
  /** Is Wrapper active */
  error?: boolean
  /** Component children */
  children: React.ReactNode
  /** Sx Prop styles*/
  sx?: SxStyleProp
}

const StatusWrapper = ({ children, on, error, sx, ...props }: StatusWrapperProps) => {
  return (
    <Box {...props} sx={{ ...wrapperStyles(on, error), ...sx }}>
      {children}
      {!error && <Icon name="greenCheck" sx={iconsStyles(on)} />}
    </Box>
  )
}
StatusWrapper.defaultProps = {
  on: false,
  error: false,
  children: null,
  sx: {}
}

export default StatusWrapper
