import React, { FC } from 'react'
import { Container } from '../Grid'
import Heading from '../Heading'
import theme from '../../theme'
import { Box, Flex, SxStyleProp, BoxProps } from 'rebass'
import styled from '@emotion/styled'

export interface SectionTypes extends BoxProps {
  /** Headline of the Section */
  headline?: string
  /** Background color of the Section */
  bgColor?: string
  /** Headline  color of the Section */
  headlineColor?: string
  /** Children of the Section */
  children?: React.ReactNode[] | React.ReactNode
  /** Sx Props */
  sx?: SxStyleProp
}

export const StyledContent = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
`

const Section: FC<SectionTypes> = ({ headline, bgColor, headlineColor, children, sx, ...props }) => {
  return (
    <Box as="section" {...props} bg={bgColor} sx={{ py: [4, 6], ...sx }}>
      <Container>
        <StyledContent>
          {headline && (
            <Heading
              size="h6"
              fontWeight={theme.fontWeights.regular}
              textAlign="center"
              color={headlineColor}
              mb={[2, 4]}
            >
              {headline}
            </Heading>
          )}
          {children && children}
        </StyledContent>
      </Container>
    </Box>
  )
}

Section.defaultProps = {
  headline: '',
  headlineColor: 'textDark',
  bgColor: 'white',
  children: null,
  sx: {}
}

export default Section
