import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useVerifyOtpMutation, useSendOtpMutation, useResendOtpMutation } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'
import { languages } from '../i18n'

interface UseVerifyOtpArgs {
  onSuccess?: (response: { success: boolean }) => void
  onBlocked: () => void
}

const otpSchema = yup.object().shape({
  otp: yup.string().required('Required field'),
  fingerPrint: yup.string().required('Required field')
})

export function useVerifyOtp({ onSuccess, onBlocked }: UseVerifyOtpArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(otpSchema),
    defaultValues: {
      otp: '',
      fingerPrint: ''
    }
  })

  const { setValue } = form

  const [resendOtp, { loading: resendLoading }] = useResendOtpMutation()
  const [sendOtp, { loading: sendLoading }] = useSendOtpMutation()
  const [verifyOtp, { loading: verifyLoading }] = useVerifyOtpMutation()

  const onSubmit = useCallback(
    async (data) => {
      try {
        const result = await verifyOtp({
          variables: {
            input: {
              code: data.otp,
              fingerPrint: data.fingerPrint
            }
          }
        })
        if (result.data.verifyOtp.__typename === 'OtpSuccessOutput') {
          onSuccess && onSuccess({ success: true })
        } else if (result.data.verifyOtp.__typename === 'OtpFailureOutput') {
          onSubmitError('error', result.data.verifyOtp.error)
        } else if (result.data.verifyOtp.__typename === 'OtpNotFoundError') {
          onSubmitError('error', result.data.verifyOtp.message)
        } else if (
          result.data.verifyOtp.__typename === 'TooManyOtpAttemptsError' ||
          result.data.verifyOtp.__typename === 'AccountBlockedError'
        ) {
          onSubmitError('error', result.data.verifyOtp.message)
          onBlocked()
        } else {
          onSubmitError('error', 'Invalid or expired OTP')
        }
      } catch (err) {
        console.log(err)
        onSubmitError()
      }
    },
    [verifyOtp, onSuccess, onSubmitError, onBlocked]
  )

  const onSendOtp = useCallback(async () => {
    try {
      const result = await sendOtp()
      if (result.data.sendOtp.__typename === 'OtpSuccessOutput') {
        setValue('fingerPrint', result.data.sendOtp.fingerPrint)
        onSubmitSuccess()
      } else if (result.data.sendOtp.__typename === 'OtpFailureOutput') {
        onSubmitError('error', result.data.sendOtp.error)
      } else if (result.data.sendOtp.__typename === 'OtpNotFoundError') {
        onSubmitError('error', result.data.sendOtp.message)
      } else if (
        result.data.sendOtp.__typename === 'TooManyOtpAttemptsError' ||
        result.data.sendOtp.__typename === 'AccountBlockedError'
      ) {
        onSubmitError('error', result.data.sendOtp.message)
        onBlocked()
      } else {
        onSubmitError('error', 'Invalid or expired OTP')
      }
    } catch (err) {
      onSubmitError()
      return err
    }
  }, [onBlocked, onSubmitError, onSubmitSuccess, sendOtp, setValue])

  const onResendOtp = useCallback(async () => {
    try {
      const result = await resendOtp()
      if (result.data.resendOtp.__typename === 'OtpSuccessOutput') {
        setValue('fingerPrint', result.data.resendOtp.fingerPrint)
        onSubmitSuccess(languages.en.resendOTPmessage)
      } else if (result.data.resendOtp.__typename === 'OtpFailureOutput') {
        onSubmitError('error', result.data.resendOtp.error)
      } else if (result.data.resendOtp.__typename === 'OtpNotFoundError') {
        onSubmitError('error', result.data.resendOtp.message)
      } else if (
        result.data.resendOtp.__typename === 'TooManyOtpAttemptsError' ||
        result.data.resendOtp.__typename === 'AccountBlockedError'
      ) {
        onSubmitError('error', result.data.resendOtp.message)
        onBlocked()
      } else {
        onSubmitError('error', 'Invalid or expired OTP')
      }
    } catch (err) {
      onSubmitError()
      return err
    }
  }, [onBlocked, onSubmitError, onSubmitSuccess, resendOtp, setValue])

  return {
    ...form,
    onSendOtp,
    onResendOtp,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: resendLoading || sendLoading || verifyLoading,
    submitResult,
    onSubmitSuccess
  }
}
