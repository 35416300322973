import { theme } from '@rmr/components'
import { SxStyleProp } from 'rebass'

export const filtersShowmapStyles: SxStyleProp = {
  pl: [null, null, null, 4],
  pt: [2],
  position: 'sticky',
  top: `80px`,
  bg: 'bgWhite',
  zIndex: theme.zIndex.header - 2,
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  display: ['none !important', null, null, 'flex !important']
}

export const vehiclesContainer = (showMap: boolean): SxStyleProp => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: showMap
    ? ['0 100%', null, null, '50% 50%', '66.66667% 33.3333%']
    : ['100% 0%', null, null, '100% 0%', '100% 0%']
})

export const vehiclesWrapperStyles: SxStyleProp = {
  display: 'grid',
  gridTemplateColumns: ['minmax(280px, 1fr)', 'minmax(300px, 1fr)', 'repeat(auto-fill, minmax(350px, 1fr))'],
  gap: [1],
  mt: [4, null, null, 0],
  mx: [0, null, null, -2]
}

export const vehicleWrapperStyles = (active: boolean): SxStyleProp => ({
  cursor: 'pointer',
  transformOrigin: 'center center',
  transform: active ? 'scale(1)' : 'scale(1)',
  transition: 'transform 0.2s'
})

export const buttonSwitchStyles: SxStyleProp = { transform: 'scale(0.8)' }

export const mapContainerStyles = (isMobile: boolean, showMap: boolean): SxStyleProp => ({
  position: isMobile ? 'fixed' : 'sticky',
  top: `80px`,
  height: 'calc(100vh - 80px)',
  width: isMobile && showMap ? '100vw' : 'auto',
  zIndex: isMobile ? theme.zIndex.max - 1 : 0
})

export const mobileButtonsStyles: SxStyleProp = {
  display: ['flex', null, null, 'none'],
  position: 'fixed',
  bottom: [3],
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: '25px',
  px: [4],
  py: [1],
  bg: 'bgWhite',
  zIndex: theme.zIndex.max - 1,
  boxShadow: `0 0 30px 1px rgba(0,0,0,0.5)`
}

export const separatorStyles: SxStyleProp = {
  width: '2px',
  bg: 'borderLight',
  mr: [1]
}

export const backgroundStyles: SxStyleProp = {
  bg: 'baseModal',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndex.header
}
