import React, { useEffect, useRef, useState } from 'react'
import { Box, BoxProps, Image } from 'rebass'

import { VehicleImage } from '@rmr/controllers'
import { Text, UploadComponent, ButtonToggle, VehicleCardSimple, ImageWrapper } from '@rmr/components'
import { languages } from '../../../../common/i18n'
import { MAX_FILE_SIZE } from '../../../../common/constants'
import { buttonsWrapperStyles, buttonStyles, uploadImagesWrapperStyles } from './UploadImages.styled'
import { UploadedFiles } from '../DisplayImages'

export type HandleUploadCallback = (files: FileExtended[], handleRemoveFile: (index: number) => void) => void

type Button = { on: boolean; success: boolean; label: string; fileId: string; file: FileExtended }
export type Buttons = {
  [key: string]: Button
}

interface UploadImagesProps extends BoxProps {
  buttons: Buttons
  uploadedFiles: UploadedFiles
  existingImages: VehicleImage[]
  vehicleName: string
  setButtons: React.Dispatch<React.SetStateAction<Buttons>>
  handleUploadCallback: HandleUploadCallback
  initFiles: File[]
}

const UploadImages = ({
  buttons,
  setButtons,
  uploadedFiles,
  vehicleName,
  handleUploadCallback,
  initFiles = []
}: UploadImagesProps) => {
  const uploaderRef = useRef<HTMLInputElement>(null)
  const imageTypeTitle = languages['en'].listYourCar.uploadVehicleImages.imageTypeTitle
  const carouselTitle = languages['en'].listYourCar.uploadVehicleImages.carouselTitle
  const uploadImageTitle = languages['en'].listYourCar.uploadVehicleImages.uploadImageTitle

  const handleButtonClick = (position: string) => {
    const newButtons = { ...buttons }
    Object.keys(newButtons).forEach((p) => (newButtons[p].on = false))
    newButtons[position].on = true
    setButtons(newButtons)
    uploaderRef.current.click()
  }
  return (
    <Box sx={uploadImagesWrapperStyles}>
      <Text size="tiny" mb={[1]} fontWeight="bold">
        {uploadedFiles.files.length < Object.keys(buttons).length ? imageTypeTitle : carouselTitle}
      </Text>
      <Box sx={uploadedFiles.files.length >= Object.keys(buttons).length ? { height: 0, overflow: 'hidden' } : {}}>
        <UploadComponent
          ref={uploaderRef}
          variant="vertical"
          showButton={false}
          mb={[2]}
          maxSize={MAX_FILE_SIZE}
          maxFiles={Object.keys(buttons).length}
          previewFiles={false}
          callback={handleUploadCallback}
          sx={{ minHeight: [300] }}
          initFiles={initFiles}
        />
      </Box>
      {uploadedFiles.files.length >= Object.keys(buttons).length && (
        <Box mb={2}>
          <VehicleCardSimple
            carName={vehicleName}
            slides={uploadedFiles.files.map(({ file }) => {
              const src = file?.preview || URL.createObjectURL(file)
              return (
                <ImageWrapper key={file.name} src={src} alt={vehicleName} onLoad={() => URL.revokeObjectURL(src)} />
              )
            })}
          />
        </Box>
      )}

      <Text size="tiny" mb={[1]} fontWeight="bold">
        {uploadImageTitle}
      </Text>
      <Box sx={buttonsWrapperStyles}>
        {Object.keys(buttons).map((position) => (
          <ButtonToggle
            key={position}
            on={buttons[position].on}
            success={buttons[position].success}
            onClick={() => handleButtonClick(position)}
            sx={buttonStyles}
          >
            {buttons[position].label}
          </ButtonToggle>
        ))}
      </Box>
    </Box>
  )
}

export default UploadImages
