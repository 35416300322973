import styled from '@emotion/styled'
import { Box, BoxProps, Flex } from 'rebass'

export const StyledSectionSplit = styled(Box)`
  position: relative;
`

export const StyledContent = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
`

export const StyledHeader = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const StyledHeaderLine = styled(Box)`
  height: 4px;
  width: 4rem;
`

export const StyledModules = styled(Flex)`
  width: 100%;
`

export const StyledModule = styled(Flex)`
  flex-direction: column;
`
interface StyledModuleContent extends BoxProps {
  listTextColor: 'string'
  listImageColor: 'string'
}

export const StyledModuleContent = styled(Box)<StyledModuleContent>`
  ul {
    list-style: none !important;
    padding-left: 0px !important;
    margin-left: 1em !important;
  }

  ul > li {
    padding-left: 1em !important;
    color: ${(props) => (props.listTextColor ? props.listTextColor : `inherit`)};
  }

  ul > li::marker {
    list-style: none;
    content: '\\2714';
    color: ${(props) => (props.listImageColor ? props.listImageColor : `inherit`)};
    display: inline-block;
    width: 1em;
    margin-right: 1em;
  }
`
