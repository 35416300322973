import React, { useEffect, useState } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import {
  ReservationFieldsFragment,
  Category,
  useReviewReservation,
  UserFieldsFragment,
  ReviewResult
} from '@rmr/controllers'
import { Box, Image } from 'rebass'
import {
  Button,
  ProfileSummarySmall,
  StarRating,
  StepsWrapper,
  Text,
  Textarea,
  theme,
  VehicleCardMobile,
  Error,
  FormMessage,
  MessageColor,
  Loader
} from '@rmr/components'

import { languages } from '../../common/i18n'
import { wrapperStyles } from './ReservationReview.styled'
import { scrollToHeadline } from '../StepsNavigation'
import { fixImageUrl } from '../../utils/fixImageUrl'

interface ReservationReviewContentProps extends Partial<StepWizardChildProps> {
  idRef: string
  reservation: ReservationFieldsFragment
  user: UserFieldsFragment
  reviewCategories: Category[]
}
const ReservationReviewContent = ({
  idRef,
  reservation,
  user,
  reviewCategories,
  nextStep
}: ReservationReviewContentProps) => {
  const { vehicle, startDate, endDate, user: driver } = reservation
  const isOwner = user.id === vehicle.user.id
  const userReviewed = {
    fullName: isOwner ? driver.fullName : vehicle.user.__typename === 'VehicleUser' ? vehicle.user.fullName : '',
    avatar: isOwner ? driver.avatar : vehicle.user.__typename === 'VehicleUser' ? vehicle.user.avatar : '',
    rating: isOwner ? driver.rating : vehicle.user.__typename === 'VehicleUser' ? vehicle.user.rating : 0
  }
  const [reviewCategoriesState, setReviewCategoriesState] = useState(
    reviewCategories.map((rc) => ({ name: rc.name, rating: 0, comments: ' ' }))
  )

  const { clearErrors, errors, setValue, onSubmit, register, submitting, submitResult } = useReviewReservation({
    reservationId: reservation.id,
    onSuccess: (response: ReviewResult) => {
      if (response.__typename === 'Review') {
        nextStep()
        scrollToHeadline(idRef)
      }
    },
    reviewCategories
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(e)
  }

  useEffect(() => {
    reviewCategories.forEach((rc) => {
      if (rc.__typename === 'Category' && rc.name) {
        // @ts-ignore
        register(rc.name)
      }
    })
  }, [])
  return (
    <React.Fragment>
      {submitting && <Loader />}
      <Box maxWidth={[300, 500, 1000]} mx="auto" pt={[10]} px={[0, null, 5]} pb={[1, 3]}>
        <StepsWrapper
          badgeProps={{ variant: 'circle', size: [150], icon: 'checklist' }}
          title={!isOwner ? languages.en.reservationReview.titleOwner : languages.en.reservationReview.titleDriver}
          subtitle={
            !isOwner ? languages.en.reservationReview.subTitleOwner : languages.en.reservationReview.subTitleDriver
          }
          separator={true}
          separatorColor="bgDark"
          bg="bgWhite"
        >
          <Box as="form" onSubmit={handleSubmit}>
            <Box sx={wrapperStyles}>
              <Box>
                {/* <Box mb={[3]}>
                  <Text>{`${languages.en.booking.datesLabel}: ${moment(startDate).format('DD-MM-YYYY')} - ${moment(
                    endDate
                  ).format('DD-MM-YYYY')}`}</Text>
                </Box> */}
                <Box mb={[3]}>
                  <VehicleCardMobile
                    // price={vehicle.pricePerDay.formatted}
                    // priceDistribution="daily"
                    fromLabel="From:"
                    from={reservation.dates.startDate}
                    to={reservation.dates.endDate}
                    toLabel="To:"
                    carName={vehicle.name}
                    image={vehicle.images[0].file.url}
                    totalTrips={vehicle.stats.trips}
                    rating={vehicle.stats.ratings}
                    language="en"
                    boxShadow={false}
                  />
                </Box>
                <ProfileSummarySmall
                  title={userReviewed.fullName}
                  profileImageUrl={fixImageUrl(userReviewed.avatar)}
                  mb={[3]}
                >
                  {userReviewed.rating >= 0 ? (
                    <StarRating
                      activeColor={theme.colors.statusRating}
                      color={theme.colors.bgGrayLight}
                      size={14}
                      value={userReviewed.rating}
                      edit={false}
                    />
                  ) : null}
                </ProfileSummarySmall>
                <Textarea
                  label={languages.en.reservationReview.publicCommentLabel}
                  toolTipInfo={languages.en.reservationReview.publicCommentTooltip}
                  name="publicComment"
                  rebassTextareaProps={{
                    disabled: submitting
                  }}
                  ref={register}
                  error={errors.publicComment && errors.publicComment.message}
                />
                <Textarea
                  label={languages.en.reservationReview.privateCommentLabel}
                  toolTipInfo={languages.en.reservationReview.privateCommentTooltip}
                  name="privateComment"
                  rebassTextareaProps={{
                    disabled: submitting
                  }}
                  ref={register}
                  error={errors.privateComment && errors.privateComment.message}
                />
              </Box>
              <Box>
                {reviewCategories.map((reviewCategory, index) => {
                  return (
                    <Box key={reviewCategory.id} mb={3}>
                      <Text mb={1} fontWeight="bold">
                        {reviewCategory.name}
                      </Text>
                      <StarRating
                        activeColor={theme.colors.statusRating}
                        color={theme.colors.bgGrayLight}
                        size={20}
                        value={reviewCategoriesState[index].rating}
                        edit={true}
                        acceptHalfStar={false}
                        onChangeValue={(value) => {
                          setReviewCategoriesState((reviewCategoriesState) => {
                            const newReviewCategoriesState = reviewCategoriesState.slice()
                            newReviewCategoriesState[index].rating = value
                            return newReviewCategoriesState
                          })
                          // @ts-ignore
                          setValue(reviewCategory.name, value)
                          if (value > 0) {
                            // @ts-ignore
                            clearErrors(reviewCategory.name)
                          }
                        }}
                      />
                      {errors[reviewCategory.name] && <Error>{errors[reviewCategory.name].message}</Error>}
                    </Box>
                  )
                })}
              </Box>
            </Box>
            <Box mt={[3]}>
              <FormMessage
                showMessage={submitResult.submitted}
                message={submitResult.message}
                color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
              />
              <Button type="submit" maxWidth={200} mx="auto" variant="primary" disabled={submitting}>
                {languages.en.reservationReview.buttonText}
              </Button>
            </Box>
          </Box>
        </StepsWrapper>
      </Box>
    </React.Fragment>
  )
}

export default ReservationReviewContent
