import React, { createContext, useContext, useMemo, useEffect, useCallback, useState } from 'react'
import {
  getAuthToken,
  setAuthToken,
  useRefreshTokenLazyQuery,
  useLogoutMutation,
  deleteAuthToken
} from '@rmr/controllers'
import { useApolloClient } from '@apollo/client'

interface AuthContextInterface {
  loading: boolean
  isAuthed: boolean
  shouldCheck: boolean
  login: (token: string, expiry: string) => void
  logout: () => void
}

const AuthContext = createContext({} as AuthContextInterface)

export const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext)
  if (typeof context === 'undefined') {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

const subMinutes = function (dt, minutes) {
  return new Date(dt.getTime() - minutes * 60000)
}
const AuthProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true)
  const [isAuthed, setAuthed] = useState(false)
  const [shouldCheck, setCheck] = useState(true)
  const apolloClient = useApolloClient()
  const [serverLogout] = useLogoutMutation()

  const [refreshToken, { loading }] = useRefreshTokenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.refreshToken.__typename === 'AuthTokens') {
        setAuthToken(data.refreshToken?.token, data.refreshToken?.expiry)
        setAuthed(true)
      }
      setLoading(false)
    },
    onError: () => {
      setAuthed(false)
      setCheck(false)
      setLoading(false)
    }
  })

  const login = useCallback((token: string, expiry: string) => {
    setAuthToken(token, expiry)
    setAuthed(true)
    setCheck(true)
    setLoading(false)
  }, [])

  const logout = useCallback(async () => {
    deleteAuthToken()
    setCheck(false)
    await Promise.all([serverLogout(), apolloClient.clearStore()])
    setAuthed(false)
    window.localStorage.setItem('logout', Date.now().toString())
  }, [apolloClient, serverLogout])

  const syncLogout = async (event) => {
    if (event.key === 'logout') {
      setAuthed(false)
      setCheck(false)
    }
  }

  const value = useMemo(
    () => ({
      loading: isLoading,
      isAuthed,
      shouldCheck,
      login,
      logout
    }),
    [isAuthed, shouldCheck, login, logout, loading]
  )

  useEffect(() => {
    refreshToken()
  }, [])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const { token, expiry } = getAuthToken()

      if (token) {
        if (subMinutes(new Date(expiry), 1) <= new Date(expiry)) {
          refreshToken()
        }
      } else {
        if (shouldCheck) {
          refreshToken()
        }
      }
    }, 600000)

    window.addEventListener('storage', syncLogout)

    return () => {
      window.removeEventListener('storage', syncLogout)
      clearInterval(intervalId)
      window.localStorage.removeItem('logout')
    }
  })

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
export { AuthContext, AuthProvider }
