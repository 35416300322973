import React, { forwardRef, useMemo } from 'react'

import { Box, BoxProps, Flex, SxStyleProp } from 'rebass'

import useUploadFile from '../../hooks/useUploadFile'
import { FileType } from '@rmr/controllers'

import Modal from '../Modal'
import Text, { TextSize } from '../Text'
import StatusWrapper from '../StatusWrapper'
import { wrapperStyles } from './UploadComponent.styled'
import UploadComponentVertical, { UploadComponentVariantProps } from './UploadComponentVertical'
import UploadComponentHorizontal from './UploadComponentHorizontal'
import UploadMinimal from './UploadMinimal'
import { FileExtended } from './types'

const fileUploadVariants = {
  vertical: UploadComponentVertical,
  horizontal: UploadComponentHorizontal,
  minimal: UploadMinimal
}

type Variant = keyof typeof fileUploadVariants

export interface UploadComponentProps extends BoxProps {
  /** Variant */
  variant: Variant
  /** Label */
  label?: string
  /** Label */
  description?: string
  /** Label */
  labelSize?: TextSize
  /** Maximum number of files */
  maxFiles?: number
  /** Maximun file size (in bytes) */
  maxSize?: number
  /** Document or Image */
  fileType?: FileType
  /** Show Browse Button */
  showButton?: boolean
  /** Preview Files? */
  previewFiles?: boolean
  /** Error message */
  error?: string
  /** Initial Files */
  initFiles?: File[] | FileExtended[]
  /** Sx Styles */
  sx?: SxStyleProp
  /** Callback */
  callback: (files: FileExtended[], handleRemoveFile: (index: number) => void) => void
}

const UploadComponent = forwardRef<HTMLInputElement, UploadComponentProps>(
  (
    {
      variant,
      label,
      labelSize,
      maxFiles,
      maxSize,
      fileType,
      showButton,
      previewFiles,
      sx,
      initFiles,
      callback,
      error,
      description,
      ...props
    },
    ref
  ) => {
    const { getRootProps, getInputProps, isDragActive, open, message, setMessage, files, handleRemoveFile } =
      useUploadFile({
        maxFiles,
        maxSize,
        fileType,
        callback,
        initFiles
      })

    if (ref) {
      ;(ref as React.MutableRefObject<HTMLInputElement>).current = (getInputProps() as any)?.ref?.current
    }

    const Component = fileUploadVariants[variant]
    const componentProps: UploadComponentVariantProps = {
      maxFiles,
      maxSize,
      files,
      getRootProps,
      getInputProps,
      isDragActive,
      openBroser: open,
      handleRemoveFile,
      showButton,
      previewFiles,
      sx,
      error,
      description
    }
    const isMinimal = variant === 'minimal'
    const success = !isMinimal && maxFiles > 0 && files.length === maxFiles

    return (
      <>
        <Flex {...props} sx={{ ...wrapperStyles, ...sx }}>
          <StatusWrapper on={success} error={!!error} px={2} pt={2} pb={maxSize ? 5 : 2}>
            <Text size={labelSize} fontWeight="extraBold" mb={1}>
              {label}
              {description && (
                <Text size="tiny" mt={1}>
                  {description}
                </Text>
              )}
            </Text>

            <Component {...componentProps} success={success} />
          </StatusWrapper>
        </Flex>
        <Modal isModalOpen={!!message} closeModal={() => setMessage('')}>
          <Flex alignItems="center" justifyContent="center" py={3} px={2}>
            <Text size="small" dangerouslySetInnerHTML={{ __html: message }} />
          </Flex>
        </Modal>
      </>
    )
  }
)

UploadComponent.defaultProps = {
  variant: 'vertical',
  label: '',
  labelSize: 'large',
  maxFiles: 1,
  maxSize: null,
  showButton: true,
  fileType: FileType.Image,
  previewFiles: true,
  initFiles: [],
  sx: {},
  callback: () => null
}

export default UploadComponent
