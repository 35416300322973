import { SxStyleProp } from 'rebass'
import theme from '../../theme'

export const wrapperStyles: SxStyleProp = {
  position: 'relative',
  bg: 'baseWhite',
  p: [2, 3],
  borderRadius: `${theme.borderRadius.large}`,
  boxShadow: '0 0 59px 0px rgba(58, 58, 58, 0.18)'
}

export const closeIconStyles: SxStyleProp = {
  position: 'absolute',
  top: 2,
  right: 3,
  fontSize: '20px',
  color: `${theme.colors.textDark}`,
  cursor: 'pointer'
}

export const topBadgeStyles = (offset: number): SxStyleProp => {
  return {
    mx: 'auto',
    mt: `-${offset}px`,
    mb: 1
  }
}
