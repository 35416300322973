import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Box, Flex } from 'rebass'
import * as yup from 'yup'

import Layout from '../Layout'
import { Loader, Input, Text, Button, FormMessage, MessageColor, PhoneInput, Checkbox, theme } from '@rmr/components'
import { useProfileUpdate, useUpdateProfileMutation, useVerifyOtp, useAuth } from '@rmr/controllers'

import { StepProps } from '../ApprovalToList'
import { languages } from '../../../common/i18n'
import { GtmCategory, GtmEvent, InputValidator, logGtmEvent } from '@rmr/helpers'

const timerStart = 30
const resendTimeoutStart = timerStart * 1000

const initialDisableState = { isResendDisabled: true, timer: timerStart }

const disableReducer = (state, action) => {
  switch (action.type) {
    case 'enable':
      return { isResendDisabled: false, timer: null, isResent: true }
    case 'disable':
      return { ...state, ...initialDisableState }
    case 'countDown':
      return { ...state, timer: state.timer - 1 }
    default:
      throw new Error()
  }
}

function validatePhoneNumber(value) {
  if (value) {
    const inputValidator = new InputValidator(value)
    if (!inputValidator.validateCellphone()) return false
  }
  return true
}

const schema = yup.object().shape({
  phoneNumber: yup
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .required('Required Field')
    .test('phoneNumber', 'Please enter a valid mobile number', function (value) {
      return validatePhoneNumber(value)
    }),
  altPhoneNumber: yup
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .test('altPhoneNumber', 'Please enter a valid mobile number', function (value) {
      return validatePhoneNumber(value)
    }),
  whatsAppOtp: yup.bool()
})

const VerifyOtp = (props: StepProps & ReturnType<typeof useVerifyOtp>) => {
  const { user, onSendOtp, onResendOtp, register, errors, submitResult, setValue, clearErrors, onSubmitSuccess } = props
  const isWhatsApp = user.profile.whatsAppOtp
  const [{ isResendDisabled, timer, isResent }, disableDispatch] = useReducer(disableReducer, initialDisableState)

  const resendOtpDisableHandler = () => {
    disableDispatch({ type: 'disable' })
    const interval = setInterval(() => {
      disableDispatch({ type: 'countDown' })
    }, 1000)
    setTimeout(() => {
      clearInterval(interval)
      disableDispatch({ type: 'enable' })
    }, resendTimeoutStart)
  }

  const sendOtp = useCallback(() => {
    onSendOtp().then(() => {
      resendOtpDisableHandler()
    })
  }, [onSendOtp])

  const resendOtp = () => {
    setValue('otp', '')
    onResendOtp().then(() => {
      resendOtpDisableHandler()
    })
  }

  useEffect(() => {
    setTimeout(sendOtp, 1)
  }, [sendOtp])

  const displayTimer = timer && !submitResult?.error && isResendDisabled ? ` (${timer})` : ''
  return (
    <>
      {isResendDisabled && (
        <>
          <Text size="body" sx={{ mb: [1] }}>
            {languages['en'].mobileNumber.OTP(user.profile.phoneNumber)}
          </Text>
          <Text size="small" fontWeight="semiBold" color={theme.colors.statusInfo} sx={{ mb: [4] }}>
            {languages['en'].mobileNumber.viaOTP(isWhatsApp, isResent)}
          </Text>
        </>
      )}
      <Box>
        <Flex as="form" onSubmit={(e) => e.preventDefault()}>
          <Input
            name="otp"
            label={'One Time Pin'}
            ref={register}
            rebassInputProps={{
              onChange: () => {
                errors.otp && clearErrors('otp')
                submitResult && onSubmitSuccess()
              }
            }}
            error={errors.otp && errors.otp.message}
          />
          <input name="fingerPrint" type="hidden" ref={register} />
        </Flex>
        <Button variant="transparent" mt={-1} onClick={resendOtp} disabled={isResendDisabled} sx={{ padding: 0 }}>
          {`${languages['en'].mobileNumber.resendOTP}${displayTimer}`}
        </Button>
      </Box>
      <FormMessage
        showMessage={submitResult.submitted}
        message={submitResult.message}
        color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
      />
    </>
  )
}

const MobileNumber = (props: StepProps) => {
  const [updateProfileMutation] = useUpdateProfileMutation()
  const { idRef, currentStep, previousStep, nextStep, user, step, steps } = props
  const [formStep, setStep] = useState('input')
  const { logout } = useAuth()

  const onSuccess = () => {
    setStep('otp')
  }

  const {
    clearErrors,
    setValue,
    register,
    onSubmit,
    formState,
    getValues,
    errors,
    watch,
    submitting: loadingUpdate,
    ...rest
  } = useProfileUpdate({
    onSuccess,
    schema,
    defaultValues: { ...user, profile: { ...user.profile, whatsAppOtp: true } }
  })
  const phoneNumber = watch('phoneNumber')
  const altPhoneNumber = watch('altPhoneNumber')

  const onPhoneChange = (phoneNumber: string) => {
    clearErrors('phoneNumber')
    setValue('phoneNumber', phoneNumber)
  }
  const onAltPhoneChange = (phoneNumber: string) => {
    clearErrors('altPhoneNumber')
    setValue('altPhoneNumber', phoneNumber)
  }

  const onBlocked = useCallback(() => {
    setTimeout(() => {
      logout()
    }, 3000)
  }, [logout])

  const verifyOtp = useVerifyOtp({
    onSuccess: useCallback(() => {
      updateProfileMutation({
        variables: {
          input: {
            review: true
          }
        }
      }).then(() => {
        logGtmEvent({ eventName: GtmEvent.USER_APPROVAL_REQUEST, data: { category: GtmCategory.USER_APPROVAL } })
        nextStep()
      })
    }, [nextStep, updateProfileMutation]),
    onBlocked
  })
  const { onSubmit: onVerify, submitting: loadingVerifyOtp } = verifyOtp

  useEffect(() => {
    register('phoneNumber')
    register('altPhoneNumber')
  }, [register])

  const loading = loadingUpdate || loadingVerifyOtp
  return (
    <Layout
      badgeProps={{ variant: 'circle', size: [150], icon: step.icon }}
      title={step.title}
      subtitle={step.subtitle}
      currentStep={currentStep}
      previousStep={previousStep}
      buttonNextClick={formStep === 'input' ? onSubmit : onVerify}
      steps={steps}
      idRef={idRef}
    >
      {loading && <Loader />}
      {formStep === 'otp' ? (
        <VerifyOtp {...props} {...verifyOtp} />
      ) : (
        <Box as="form">
          <Flex mb={5} sx={{ gap: [2], flexDirection: ['column', 'column', 'row'] }}>
            <PhoneInput
              key={`${user?.profile?.country}1`}
              fieldLabel="Mobile Number*"
              countryCodeInit={user?.profile?.country}
              phoneNumber={phoneNumber}
              onPhoneChange={onPhoneChange}
              errorMessage={errors?.phoneNumber?.message}
            />
            <PhoneInput
              key={`${user?.profile?.country}2`}
              fieldLabel="Alternative Mobile Number*"
              countryCodeInit={user?.profile?.country}
              phoneNumber={altPhoneNumber}
              onPhoneChange={onAltPhoneChange}
              errorMessage={errors?.altPhoneNumber?.message}
            />
          </Flex>
          <Flex mt={2} justifyContent="center">
            <Checkbox
              name="whatsAppOtp"
              sx={{ mr: [6] }}
              toolTipInfo={languages['en'].mobileNumber.whatsAppOtpTooltip}
              ref={register}
            >
              {languages['en'].mobileNumber.whatsAppOtpLabel}
            </Checkbox>
          </Flex>
        </Box>
      )}
    </Layout>
  )
}

export { MobileNumber }
