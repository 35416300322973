import { VehiclePlace } from '../lib/generated/generated'

// @ts-ignore
const formFields: { [key: string]: keyof google.maps.GeocoderAddressComponent } = {
  street_number: 'short_name',
  route: 'long_name', // addressLine1
  administrative_area_level_1: 'long_name', // state
  locality: 'long_name', // city
  postal_code: 'short_name', // zipCode
  sublocality_level_1: 'short_name', //suburb
  sublocality: 'short_name',
  country: 'long_name'
}

// @ts-ignore
function extractAddressFieldsFromGooglePlace(place: google.maps.places.PlaceResult) {
  const fields = place.address_components.reduce((acc, cur) => {
    cur.types.forEach((addressType) => {
      if (formFields[addressType]) {
        const val = cur[formFields[addressType]]
        if (val instanceof Array) {
          acc[addressType] = val.join(' ')
        } else {
          acc[addressType] = val
        }
      }
    })

    return acc
  }, {} as { [key: string]: string })

  return {
    streetNumber: fields.street_number,
    addressLine1: fields.route,
    city: fields.locality,
    suburb: fields.sublocality_level_1 || fields.sublocality,
    state: fields.administrative_area_level_1 || '',
    postalCode: fields.postal_code || '',
    country: fields.country,
    placeId: place.place_id,
    placeName: place.name,
    placeFormattedAddress: place.formatted_address,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng()
  }
}

function addressToString(address: Partial<VehiclePlace> & { placeName?: string; placeFormattedAddress?: string }) {
  const { placeFormattedAddress } = address
  if (placeFormattedAddress) {
    return placeFormattedAddress
  }
  return ['streetNumber', 'addressLine1', 'addressLine2', 'suburb', 'city', 'postalCode', 'country']
    .map((key, index, parent) => {
      if (address[key]) return `${address[key]}${index !== parent.length - 1 ? ', ' : ''}`
      return ''
    })
    .join('')
}

export { extractAddressFieldsFromGooglePlace, addressToString }
