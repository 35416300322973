import React, { Fragment, forwardRef } from 'react'
import { Card as RebassCard, CardProps as RebassCardProps, Box, Flex, SxStyleProp } from 'rebass'
import ReactStars from 'react-rating-stars-component'
import Skeleton from 'react-loading-skeleton'
import languages, { Language } from '../../i18n'
import Carousel from '../Carousel'
import { PriceDistributionOption } from '../PriceDistribution'
import Price from '../Price'
import {
  vehicleCardStyles,
  carouselStyles,
  headerImagestyles,
  headerWrapperStyles,
  priceWrapperStyles,
  priceStyles,
  arrowStyles,
  wrapperStyles
} from './VehicleCardSimple.styled'
import Icon from '../Icon/Icon'

import Text from '../Text'
import theme from '../../theme'
import Popover from '../Popover'
import { IconType } from '../Icon'

export interface VehicleCardFeature {
  icon: string
  label?: string
  tooltip?: string
}

export interface VehicleCardSimpleProps extends RebassCardProps {
  /** Array of Slides */
  slides: React.ReactNode[]
  /** Car Name */
  carName?: string
  /** Car Price */
  price?: string
  /** Car Price Distribution*/
  priceDistribution?: PriceDistributionOption
  /** Number of trips */
  totalTrips?: number
  /** Rating */
  rating?: number
  /** Location */
  location?: string
  /** Features */
  features?: VehicleCardFeature[]
  /** language */
  language?: Language
  /** Show Arrow? */
  showArrow?: boolean
  /** boxShadow around component? */
  boxShadow?: boolean
  /** Horizonatl Padding around Text? */
  paddingText?: (number | string) | (number | string)[]
  /** Padding around component? */
  padding?: (number | string) | (number | string)[]
  /** Center Text under image? */
  centerText?: boolean
  /** Sx Styles */
  sx?: SxStyleProp
  /** Click handler */
  onClick?: () => void
}

const VehicleCardSimple = forwardRef<HTMLDivElement, VehicleCardSimpleProps>(
  (
    {
      slides,
      carName,
      totalTrips,
      price,
      priceDistribution,
      rating,
      location,
      features = [],
      language,
      showArrow,
      boxShadow,
      paddingText,
      padding,
      centerText,
      sx,
      ...props
    },
    ref
  ) => {
    return (
      <Box sx={{ position: 'relative' }}>
        <Box sx={wrapperStyles(padding, boxShadow)}>
          <RebassCard ref={ref} {...props} sx={{ ...(vehicleCardStyles as SxStyleProp), ...sx }}>
            <Box sx={headerWrapperStyles}>
              {slides.length === 1 ? (
                <Box sx={headerImagestyles}>{slides[0]}</Box>
              ) : (
                <Carousel className="Carousel" variant="vehicleCardCarousel" sx={carouselStyles}>
                  {slides.map((slide, index) => (
                    <Fragment key={index}>{slide} </Fragment>
                  ))}
                </Carousel>
              )}
              {price && (
                <Box sx={priceWrapperStyles}>
                  <Text as="span" size="tiny" color="textLight">
                    {languages[language].from}
                  </Text>
                  <Box>
                    <Price price={price} priceDistribution={priceDistribution} sx={priceStyles} />
                  </Box>
                </Box>
              )}
            </Box>
            <Box px={paddingText} mt={[2]} mb={[1]} sx={{ zIndex: 1 }}>
              <Box textAlign={centerText ? 'center' : 'left'}>
                <Text as="p" size="medium" color="textBlack" fontWeight="extraBold" sx={{ textTransform: 'uppercase' }}>
                  {carName || <Skeleton />}
                </Text>
                <Text as="p" size="small" color="textGrayLight">
                  {location || (!carName && <Skeleton />)}
                </Text>
              </Box>
              <Flex justifyContent="space-between" mt={1}>
                <Flex alignItems="center" justifyContent={centerText ? 'center' : undefined}>
                  {carName ? (
                    <ReactStars
                      classNames="car-rating"
                      count={1}
                      edit={false}
                      activeColor={theme.colors.primaryLight}
                      color={theme.colors.primaryLight}
                      value={rating}
                      emptyIcon={<Icon name="star" />}
                      filledIcon={<Icon name="star" />}
                    />
                  ) : (
                    <Text size="small" width={14} mr={1} color="textLight">
                      <Skeleton />
                    </Text>
                  )}
                  {(!Number.isNaN(rating) &&
                    rating >= 0 &&
                    (rating > 0 ? (
                      <Flex alignItems="center">
                        <Text size="small" color="textLight" ml={1}>
                          {rating}
                        </Text>
                      </Flex>
                    ) : (
                      <Box>
                        {totalTrips > 0 && (
                          <Text size="small" color="textLight" ml={1}>
                            {languages[language].noRating}
                          </Text>
                        )}
                      </Box>
                    ))) || (
                    <Text size="small" width={80} color="textLight">
                      <Skeleton />
                    </Text>
                  )}
                  {!Number.isNaN(totalTrips) && totalTrips >= 0 ? (
                    <Text ml={[1]} size="small" color="textLight">
                      {totalTrips > 0 ? `(${totalTrips} ${languages[language].trips})` : languages[language].noTrips}
                    </Text>
                  ) : (
                    <Text ml={[1]} width={80} size="small" color="textLight">
                      <Skeleton />
                    </Text>
                  )}
                </Flex>
                <Flex sx={{ justifyContent: 'end' }}>
                  {features.length > 0
                    ? features?.map(({ icon, label, tooltip }) => (
                        <Flex>
                          {tooltip ? (
                            <Popover icon={icon as IconType} iconColor="primary" mr={-1}>
                              {tooltip}
                            </Popover>
                          ) : (
                            <Icon name={icon} />
                          )}
                          {!!label && (
                            <Text size="small" ml={1}>
                              {label}
                            </Text>
                          )}
                        </Flex>
                      ))
                    : !carName && (
                        <Text width={1 / 2} size="small">
                          <Skeleton />
                        </Text>
                      )}
                </Flex>
              </Flex>
            </Box>
          </RebassCard>
        </Box>
        {showArrow && <Box sx={arrowStyles} />}
      </Box>
    )
  }
)

VehicleCardSimple.defaultProps = {
  slides: [],
  carName: 'Car Model',
  totalTrips: 0,
  rating: 5,
  price: '',
  priceDistribution: 'daily',
  language: 'en',
  showArrow: false,
  boxShadow: false,
  paddingText: 0,
  padding: 0,
  centerText: false,
  sx: {},
  onClick: () => null
}

export default VehicleCardSimple
