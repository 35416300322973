import React, { FC } from 'react'
import { SxStyleProp, Flex } from 'rebass'

export interface SocialSharingProps {
  /** Children to contain the Buttons and Links */
  children: React.ReactNode
  /** Sx Props */
  sx?: SxStyleProp
}

const SocialSharing: FC<SocialSharingProps> = ({ children, sx, ...props }) => {
  return (
    <Flex {...props} sx={{ ...sx }} alignItems={['center']} justifyContent={['center']}>
      {children && children}
    </Flex>
  )
}

SocialSharing.defaultProps = {
  children: null
}

export default SocialSharing
