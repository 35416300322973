import { useCallback, useEffect } from 'react'
import { navigate, useLocation } from '@reach/router'
import { parse } from 'query-string'

import {
  useProviderRegisterMutation,
  OAuthProviderTypes,
  useProviderLoginMutation,
  useAuth,
  useSubmitResult,
  SubmitState,
  ProviderAuthResultUnion
} from '@rmr/controllers'
import { logGtmEvent, GtmEvent, GtmCategory, getCookie, refCookieName } from '@rmr/helpers'

export enum ProviderType {
  Register,
  Login
}

const useSocialAuth = (
  type: ProviderType,
  oAuthProviderType: OAuthProviderTypes,
  onSubmit: (submitResult: SubmitState) => void,
  callback?: () => void
) => {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const { login: setToken } = useAuth()
  const location = useLocation()
  const { redirect = '/' } = parse(location.search)

  const onCompleted = (providerAuthResult: ProviderAuthResultUnion) => {
    if (providerAuthResult.__typename === 'AuthTokens') {
      if (type === ProviderType.Register) {
        const referralCode = getCookie(refCookieName)
        const data = { category: GtmCategory.AUTHENTICATION, source: oAuthProviderType }
        if (referralCode) {
          Object.assign(data, { referralCode })
        }
        logGtmEvent({
          eventName: GtmEvent.USER_SIGNUP,
          data
        })
      }
      onSubmitSuccess()
      setToken(providerAuthResult.accessToken, null)
      callback && callback()
      if (type === ProviderType.Login) {
        redirect && navigate(redirect as string)
        return
      }
    } else if (providerAuthResult.message) {
      onSubmitError('error', providerAuthResult.message)
    } else {
      onSubmitError()
    }
  }

  const onError = (error: Error) => {
    console.log({ error })
    const { message } = error
    onSubmitError('error', message)
  }

  const [providerRegister, { loading: loadingRegister }] = useProviderRegisterMutation({
    onCompleted: ({ providerRegister }) => onCompleted(providerRegister as ProviderAuthResultUnion),
    onError
  })

  const [providerLogin, { loading: loadingLogin }] = useProviderLoginMutation({
    onCompleted: ({ providerLogin }) => onCompleted(providerLogin as ProviderAuthResultUnion),
    onError: (error) => {
      console.log({ error })
      const { message } = error
      onSubmitError('error', message)
    }
  })

  const onSuccess = useCallback(
    async (response) => {
      const { accessToken } = response
      if (!accessToken) {
        onSubmitError()
        return
      }
      try {
        let response
        if (type === ProviderType.Register) {
          // get referral Code from cookies
          const referralCode = getCookie(refCookieName) ?? ''
          response = await providerRegister({
            variables: { input: { accessToken, provider: oAuthProviderType, referralCode } }
          })
        } else {
          response = await providerLogin({
            variables: { input: { accessToken, provider: oAuthProviderType } }
          })
        }
        const { errors } = response
        if (errors) onSubmitError()
      } catch (error) {
        console.log({ error })
        onSubmitError()
      }
    },
    [oAuthProviderType, onSubmitError, providerLogin, providerRegister, type]
  )
  useEffect(() => {
    if (submitResult && submitResult.submitted) {
      onSubmit(submitResult)
    }
  }, [submitResult, onSubmit])

  return { onSuccess, loadingLogin, loadingRegister, submitResult }
}

export default useSocialAuth
