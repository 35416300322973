import buttons from './buttons'
import colors from './colors'

export const breakpoints = [576, 768, 992, 1200]

const theme = {
  buttons,
  breakpoints: breakpoints.map((breakpoint) => `${breakpoint}px`),

  fonts: {
    serif: 'serif',
    sans: 'Montserrat, sans-serif'
  },

  fontWeights: {
    extraBold: '800',
    bold: '700',
    semiBold: '600',
    medium: '500',
    regular: '400',
    light: '300'
  },

  fontSizes: {
    // Text sizes
    tinyMobile: '11px',
    smallMobile: '12px',
    mediumMobile: '14px',
    bodyMobile: '16px',
    largeMobile: '18px',

    tiny: '12px',
    small: '14px',
    medium: '16px',
    body: '18px',
    large: '20px',

    // Heading sizes
    h6Mobile: '15px',
    h5Mobile: '17px',
    h4Mobile: '20px',
    h3Mobile: '24px',
    h2Mobile: '32px',
    h1Mobile: '52px',

    h6: '18px',
    h5: '20px',
    h4: '24px',
    h3: '28px',
    h2: '38px',
    h1: '70px'
  },

  colors,

  speed: {
    default: '0.3s',
    slow: '0.5s',
    fast: '0.1s'
  },

  letterSpacing: {
    small: '-.025em',
    medium: '-.05em'
  },

  zIndex: {
    negative: -1,
    level: 0,
    default: 1,
    header: 999,
    modal: 1000,
    max: 9999999999
  },

  space: [
    0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144, 152, 160, 168, 176, 184, 192, 200,
    208, 216, 224, 232, 240, 248, 256
  ],

  opacity: {
    none: 0,
    faint: 0.3,
    half: 0.5,
    medium: 0.6,
    full: 1
  },

  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '12px',
    circle: '50%'
  },

  icons: {
    download: 'download',
    error: 'error',
    success: 'success',
    warning: 'warning',
    info: 'info',
    loading: 'loading',
    play: 'play',
    pause: 'pause',
    logout: 'logout',
    time: 'time'
  },

  forms: {
    input: {
      color: 'textGray',
      borderRadius: '4px',
      backgroundColor: 'baseWhite',
      borderColor: 'border',
      padding: '14px',
      width: '100%',
      fontFamily: 'Montserrat, sans-serif'
    },
    textarea: {
      color: 'textGray',
      borderRadius: '4px',
      backgroundColor: 'baseWhite',
      borderColor: 'border',
      padding: '14px',
      width: '100%',
      fontFamily: 'Montserrat, sans-serif'
    }
  }
}

export default theme
