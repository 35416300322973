import { Button, Error, Icon, StatusWrapper, Text } from '@rmr/components'
import { DocumentType, useProfileUpdate, FileType, acceptedFileTypes, fileUploader } from '@rmr/controllers'
import React, { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import Webcam from 'react-webcam'
import { Box, Image } from 'rebass'
import { getFile, idRef } from '../helpers'
import Layout from '../Layout'
import { StepProps } from '../ApprovalToList'

const schema = yup.object().shape({
  hasSelfie: yup.bool().oneOf([true], 'A selfie is required.')
})

const containerStyles = {
  width: '300px',
  height: '300px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}

const Selfie = ({ currentStep, previousStep, nextStep, step, steps, user }: StepProps) => {
  const [loading, setLoading] = useState(false)
  const { register, setValue, setError, errors, clearErrors, setFiles, files, onSubmit } = useProfileUpdate({
    onSuccess: () => {
      nextStep()
    },
    schema,
    defaultValues: { ...user, profile: { ...user.profile, hasSelfie: false } }
  })

  const validateFiles = useCallback(() => files.some((file) => file.type === DocumentType.Selfie), [files])

  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: 'user'
  }
  const acceptedTypes = acceptedFileTypes(FileType.Image)

  const webcamRef = React.useRef(null)
  const hiddenFileInputRef = React.useRef(null)

  const handleUploadCallback = useCallback(async (files) => {
    try {
      setLoading(true)

      if (files.length === 1) {
        const file = await fileUploader(files[0], FileType.DOCUMENT)

        return file
      }
    } catch (error) {
      console.log('File Upload Error: ', error)
    } finally {
      setLoading(false)
    }
  }, [])

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    if (!imageSrc) {
      setError('hasSelfie', {
        type: 'validate',
        message: 'Unable to capture image. Please check browser allows for camera permissions and try again.'
      })
      return
    }

    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'selfie-capture.jpeg', { type: 'image/jpeg' })

        return handleUploadCallback([file])
      })
      .then((file) => {
        clearErrors('hasSelfie')
        setFiles((files) => [
          ...files.filter((f) => f.type !== DocumentType.Selfie),
          { type: DocumentType.Selfie, fileId: file.id, preview: imageSrc }
        ])
      })
  }, [clearErrors, handleUploadCallback, setError, setFiles])

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    if (!acceptedTypes.includes(file.type)) {
      setError('hasSelfie', {
        type: 'validate',
        message: 'The file provided is not a supported type.'
      })
      return
    }
    handleUploadCallback([file]).then((res) => {
      clearErrors('hasSelfie')
      setFiles((files) => [
        ...files.filter((f) => f.type !== DocumentType.Selfie),
        { type: DocumentType.Selfie, fileId: res.id, preview: URL.createObjectURL(file) }
      ])
    })
  }

  const previewField = getFile(files, DocumentType.Selfie)

  const retakeButton = (
    <Button
      sx={{ width: '100%' }}
      onClick={() => {
        clearErrors('hasSelfie')
        setFiles((files) => {
          return files.filter((f) => f.fileId !== previewField.fileId)
        })
      }}
    >
      Retake
    </Button>
  )

  useEffect(() => {
    register('hasSelfie')
    setValue('hasSelfie', validateFiles())
  }, [register, setValue, user.profile.dateOfBirth, validateFiles])

  return (
    <Layout
      badgeProps={{ variant: 'circle', size: [150], icon: step.icon }}
      {...step}
      currentStep={currentStep}
      previousStep={previousStep}
      buttonNextClick={() => {
        onSubmit()
      }}
      idRef={idRef}
      steps={steps}
    >
      <StatusWrapper px={2} py={2}>
        {loading ? (
          <Box sx={containerStyles}>Uploading</Box>
        ) : previewField ? (
          <Box>
            {previewField.preview ? (
              <Box>
                <Image sx={{ width: '300px', height: '300px', objectFit: 'contain' }} src={previewField.preview} />
                {retakeButton}
              </Box>
            ) : (
              <Box>
                <Box sx={containerStyles}>
                  <Icon name="paperClip" fontAwesomeIconProps={{ size: '2x' }} />
                  <Text>Selfie</Text>
                </Box>
                {retakeButton}
              </Box>
            )}
          </Box>
        ) : (
          <>
            <Webcam
              audio={false}
              height={300}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={300}
              videoConstraints={videoConstraints}
            />
            <input
              ref={hiddenFileInputRef}
              type="file"
              name="selfie"
              style={{ display: 'none' }}
              onChange={uploadFile}
              accept={acceptedTypes.join(', ')}
            />
            {/* <Box mt={[1]} sx={{ display: 'grid', gap: [1], gridTemplateColumns: '1fr 1fr', width: '300px' }}> */}
            <Box mt={[1]}>
              <Button onClick={capture} fullWidth>
                Capture
              </Button>
              {/* <Button onClick={browseFilesClick}>Upload File</Button> */}
            </Box>
          </>
        )}
      </StatusWrapper>
      {errors.hasSelfie?.message && <Error sx={{ position: 'static', mt: 1 }}>{errors.hasSelfie.message}</Error>}
    </Layout>
  )
}

export { Selfie }
