import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { AcceptQuoteMutation, useAcceptQuoteMutation } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'

export interface AcceptQuoteFormData {
  renter_message: string
  accept_terms: boolean
  isDropoff: number
  address: string
}

interface UseAcceptQuoteArgs {
  reservationId: string
  onSuccess?: (response: AcceptQuoteMutation) => void
  onError?: (e: Error) => void
  isDropoff: number
}

const acceptQuoteFormSchema = yup.object().shape({
  address: yup.string().when('isDropoff', {
    is: '1',
    then: yup.string().required('Required Field. Please enter your drop-off location'),
    otherwise: yup.string()
  }),
  accept_terms: yup.bool().oneOf([true], 'Please accept our T&Cs'),
  renter_message: yup.string().required('Required Field')
})

export function useAcceptQuote({ reservationId, onSuccess, onError, isDropoff }: UseAcceptQuoteArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(acceptQuoteFormSchema),
    defaultValues: {
      renter_message: '',
      accept_terms: false,
      isDropoff,
      address: ''
    }
  })

  const [acceptQuote, { loading }] = useAcceptQuoteMutation()

  const onSubmit = useCallback(
    async (data: AcceptQuoteFormData) => {
      const { accept_terms, renter_message, address: addressInput } = data
      let address = ''
      if (addressInput) {
        address = JSON.parse(addressInput)
      }
      const input = {
        terms: accept_terms,
        message: renter_message
      }
      if (address) Object.assign(input, { address })

      try {
        const response = await acceptQuote({
          variables: {
            id: reservationId,
            input
          }
        })
        onSuccess && onSuccess(response.data)
        const { acceptQuote: acceptQuoteResult } = response.data

        if (acceptQuoteResult.__typename === 'Reservation') {
          onSubmitSuccess()
        } else {
          // @ts-ignore
          onSubmitError(null, acceptQuoteResult.message)
        }
      } catch (err) {
        console.log(err)
        onError && onError(err)
        onSubmitError()
      }
    },
    [acceptQuote, onSuccess, onError, reservationId, onSubmitError, onSubmitSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult
  }
}
