import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { Loader } from '@rmr/components'
import { useGetReservationQuery } from '@rmr/controllers'
import ReservationConfirmation from './ReservationConfirmation'
import ReservationQuote from './ReservationQuote'
import ReservationPayment from './ReservationQuote/ReservationPayment'
import ReservationSettlement from './ReservationQuote/ReservationSettlement'
import SEO from '../Seo'

export enum DeliverOption {
  PickUp,
  DeliverRenter,
  DeliverAirport
}

interface ReservationProps extends RouteComponentProps {
  reservationId?: string
  step: 'quote' | 'payment' | 'settlement' | 'confirmation'
}

export const Reservation: React.FC<ReservationProps> = ({ reservationId, step }) => {
  const { data, loading, refetch } = useGetReservationQuery({
    variables: {
      id: reservationId
    }
  })
  const onRefetch = () => refetch()
  if (loading) {
    if (!data?.getReservation) {
      return <Loader position="static" />
    } else {
      return <Loader />
    }
  }

  if (data.getReservation.__typename === 'Reservation') {
    let content = <ReservationQuote reservation={data.getReservation} onRefetch={onRefetch} />
    if (step === 'payment') {
      content = <ReservationPayment reservation={data.getReservation} onRefetch={onRefetch} />
    } else if (step === 'settlement') {
      return <ReservationSettlement reservation={data.getReservation} />
    } else if (step === 'confirmation') {
      content = <ReservationConfirmation reservation={data.getReservation} />
    }

    return (
      <React.Fragment>
        <SEO
          title={'Rental Request | RentMyRide'}
          description={
            "Skip the rental car counter — Book rental cars near you from trusted, local owners on RentMyRide. It's like a rental, just better."
          }
        />
        {content}
      </React.Fragment>
    )
  } else {
    navigate('/rent-a-car/')
    return null
  }
}
export default Reservation
