import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import Text from '../Text'
import theme from '../../theme'
import Content from '../Content'
import Badge from '../Badge'
import parse from 'html-react-parser'
import { FeatureIconTypes, BadgeProps, IconProps } from './types'

const StyledFeatureIconRow = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledIconWrapper = styled(Flex)`
  position: relative;
`

const StyledBadge = styled(Flex)<BadgeProps>`
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: ${theme.borderRadius.circle};
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'inherit')};
  width: 2.25rem;
  height: 2.25rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (min-width: ${theme.breakpoints[0]}) {
    width: 3.5rem;
    height: 3.5rem;
  }
`

const StyledFeatureContent = styled(Box)`
  display: block;
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.medium};
`

export const FeatureIconRow: FC<FeatureIconTypes> = ({
  headline,
  featureContent,
  badge,
  icon,
  headlineColor,
  textColor,
  badgeTextColor,
  badgeBgColor,
  iconColor,
  iconBgColor,
  sx,
  ...props
}) => {
  return (
    <StyledFeatureIconRow
      className="StyledFeatureIconRow"
      width={['100%']}
      px={[1, 6]}
      mb={[4, 4, 0]}
      {...props}
      sx={{ ...sx }}
    >
      <StyledIconWrapper className="StyledIconWrapper">
        {icon && (
          <Badge
            className="Badge"
            mx="auto"
            mb={[2]}
            variant={'circle'}
            icon={icon}
            contentColor={iconColor}
            color={iconBgColor}
            size={[100, 200]}
          />
        )}
        {badge && (
          <StyledBadge className="StyledBadge" bgColor={badgeBgColor}>
            <Text size="small" fontWeight={theme.fontWeights.extraBold} color={badgeTextColor}>
              {badge}
            </Text>
          </StyledBadge>
        )}
      </StyledIconWrapper>
      {headline && (
        <Text size="body" textAlign="center" fontWeight={theme.fontWeights.extraBold} color={headlineColor}>
          {headline}
        </Text>
      )}
      {featureContent && (
        <StyledFeatureContent textAlign="center" color={textColor}>
          <Content>{parse(featureContent)}</Content>
        </StyledFeatureContent>
      )}
    </StyledFeatureIconRow>
  )
}

export default FeatureIconRow
