import React, { useState } from 'react'
import { Box, SxStyleProp, BoxProps } from 'rebass'
import { useOffset } from '@rmr/components'
import theme from '../../theme'

import Icon from '../Icon'
import Text from '../Text'

import { IconType } from '../Icon'

import { dropdownStyles, popoverStyles, iconStyles, contentStyles, dropdownWrapperStyles } from './Popover.styled'

export interface PopoverProps extends BoxProps {
  /** Icon to tap/hover to show info */
  icon: IconType
  /** Color of Icon */
  iconColor: string
  /** Info Background */
  infoBg: string
  /** Info Text Color */
  infoColor: string
  /** Is the info open and visible */
  isOpen: boolean
  /** Info text to display on popover */
  children: React.ReactNode
  /** Sx prop styles */
  sx?: SxStyleProp
}

const Popover = ({ children, icon, iconColor, infoBg, infoColor, isOpen, sx, ...props }: PopoverProps) => {
  const [open, setOpen] = useState(isOpen)
  const [iconEl, setIconEl] = useState(null)
  const [dropdownEl, setDropDownEl] = useState(null)
  const offset = useOffset(iconEl, dropdownEl)
  return (
    <Box sx={{ ...popoverStyles, ...sx }} {...props}>
      <Box
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onTouchStart={() => setOpen((open) => !open)}
        ref={(div) => div && setIconEl(div)}
      >
        <Icon name={icon} color={theme.colors[iconColor]} fontAwesomeIconProps={{ size: '1x' }} sx={iconStyles} />
      </Box>
      <Box sx={dropdownWrapperStyles(open, dropdownEl ? dropdownEl.clientHeight : 0, offset)}>
        <Box ref={(div) => div && setDropDownEl(div)} sx={dropdownStyles} bg={infoBg}>
          <Text color={infoColor} size="tiny" sx={contentStyles}>
            {children}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

Popover.defaultProps = {
  icon: 'info',
  iconColor: 'bgGrayLight',
  infoBg: 'bgDark',
  infoColor: 'textWhite',
  children: '',
  isOpen: false,
  sx: {}
}

export default Popover
