import { VehicleViewPosition } from './types'

import {
  VehicleFuel,
  VehicleTransmission,
  VehicleColor,
  HandoverStatus,
  PlaceType,
  TransactionType,
  ReservationStatus
} from './lib/generated/generated'

import { RentalPeriod } from './types'

export const languages = {
  en: {
    resendOTPmessage: 'A new OTP has been sent',
    forgotPassword: {
      emailNotFound: 'Check your inbox for a reset link'
    },
    maxDailyDistanceOptions: [
      { key: '100', value: 100, label: '100km' },
      { key: '200', value: 200, label: '200km' },
      { key: '300', value: 300, label: '300km' },
      { key: '0', value: 0, label: '400km+' }
    ],
    enums: {
      RentalPeriod: {
        [RentalPeriod['3Days']]: '2 Days',
        [RentalPeriod['3Days']]: '3 Days',
        [RentalPeriod['4Days']]: '4 Days',
        [RentalPeriod['5Days']]: '5 Days',
        [RentalPeriod.Unlimited]: 'Unlimited'
      },
      VehicleFuel: {
        [VehicleFuel.Diesel]: 'Diesel',
        [VehicleFuel.Petrol]: 'Petrol',
        [VehicleFuel.HybridDiesel]: 'Hybrid Diesel',
        [VehicleFuel.HybridPetrol]: 'Hybrid Petrol',
        [VehicleFuel.Electric]: 'Electric'
      },
      VehicleTransmission: {
        [VehicleTransmission.Manual]: 'Manual',
        [VehicleTransmission.Automatic]: 'Automatic'
      },
      VehicleColor: {
        [VehicleColor.Black]: 'Black',
        [VehicleColor.Blue]: 'Blue',
        [VehicleColor.Gold]: 'Gold',
        [VehicleColor.Green]: 'Green',
        [VehicleColor.Grey]: 'Grey',
        [VehicleColor.Other]: 'Other',
        [VehicleColor.Red]: 'Red',
        [VehicleColor.Silver]: 'Silver',
        [VehicleColor.White]: 'White',
        [VehicleColor.Yellow]: 'Yellow'
      },
      HadoverStatus: {
        [HandoverStatus.Checkin]: 'Check-in',
        [HandoverStatus.Current]: 'Trip in progress',
        [HandoverStatus.Checkout]: 'Check-out',
        [HandoverStatus.Complete]: 'Completed',
        [HandoverStatus.NoShow]: 'No Show',
        [HandoverStatus.Disputed]: 'Disputed'
      },
      ChangeableReservationStatus: {
        [ReservationStatus.Confirmed]: 'Confirmed',
        [ReservationStatus.Requested]: 'Requested',
        [ReservationStatus.Accepted]: 'Accepted',
        [ReservationStatus.Expired]: 'Expired',
        [ReservationStatus.UnderAssessment]: 'Under Assessment'
      },
      TransactionTypeOptions: {
        [TransactionType.Payment]: 'Payment',
        [TransactionType.Deposit]: 'Deposit',
        [TransactionType.Refund]: 'Refund',
        [TransactionType.Payout]: 'Payout'
      },
      VehicleViewPositionOptions: [
        {
          key: VehicleViewPosition.Front,
          value: VehicleViewPosition.Front,
          label: 'Front'
        },
        {
          key: VehicleViewPosition.Back,
          value: VehicleViewPosition.Back,
          label: 'Back'
        },
        {
          key: VehicleViewPosition.Left,
          value: VehicleViewPosition.Left,
          label: 'Left Profile'
        },
        {
          key: VehicleViewPosition.Right,
          value: VehicleViewPosition.Right,
          label: 'Right Profile'
        },
        {
          key: VehicleViewPosition.Dashboard,
          value: VehicleViewPosition.Dashboard,
          label: 'Dashboard'
        },
        {
          key: VehicleViewPosition.Odometer,
          value: VehicleViewPosition.Odometer,
          label: 'Odometer'
        }
      ],
      placeTypeOptions: {
        [PlaceType.Pickup]: 'Pickup',
        [PlaceType.Dropoff]: 'Dropoff',
        [PlaceType.Address]: 'Address',
        [PlaceType.Airport]: 'Airport',
        [PlaceType.Hotel]: 'Hotel',
        [PlaceType.TrainStation]: 'Train Station',
        [PlaceType.ShoppingMall]: 'Shopping Mall',
        [PlaceType.Other]: 'Other'
      }
    }
  }
}

export const validationMessages = {
  en: {
    avatar: 'Please upload an image',
    email: 'Please enter a valid email address',
    mobileNumber: 'Please enter a valid mobile number',
    password: 'Please enter a valid password',
    passwordLength: 'Password must have at least 6 characters',
    required: 'Required field',
    oneCheckbox: 'Please select at least 1 option',
    termsAndConditions: "Please accept our T's & C's",
    dates: 'Please enter a valid date range',
    warnErrors: 'Please check your form inputs. You have not provided all required fields.',
    vinLength: 'VIN number must have 17 characters',
    integer: 'Field must be an integer'
  }
}
export const successMessages = {
  en: {
    updateReservationDates: 'Dates changed successfully'
  }
}

export const errorMessages = {
  en: {
    'error.invalidCredentials': 'Invalid email and password combination. Please try again.',
    'error.credentals_taken_error': 'User already exists',
    'error.provider_not_registered_error': 'User does not exist',
    'error.userNotFound': 'User does not exist',
    'error.userNotVerified': 'User has not been verified',
    'error.passwordResetTokenExpired': 'Token expired. Please try again.',
    'error.invalid_reservation': 'Invalid reservation',
    'error.vehicle_unavailable_error': 'Vehicle is not available',
    'error.reservation_doesnot_exist': 'Reservation does not exist',
    'error.reservation_invalid_action': 'Invalid action on reservation',
    'error.cancelation_time_has_expired': 'Cancelation time has expired',
    'error.reservation_expired': 'Reservation has expired',
    'error.invalid_reservation_dates': 'Invalid dates',
    'error.vehicle_is_booked_for_this_period': 'Vehicle is already booked on these dates',
    'error.vehicle_unavailable': 'Vehicle is not available',
    'error.invalid_duration_criteria': 'Invalid duration criteria',
    'error.reservation_already_processed': 'The reservation has already been processed',
    'error.vehicle_unauthorized_access': 'Unauthorized access to vehicle',
    'error.quotation_expired_error': 'Quotation has expired',
    'error.invalidVehicleCategory': 'Invalid vehicle category',
    'error.invalidVehicleSelected': 'Selected vehicle is not valid',
    'error.vehicleNotFoundError': 'Vehicle does not exist',
    'error.vehicleIsNotApprovedError': 'Vehicle has not been approved yet',
    'error.vehicleHasToBeRejectedToResubmit': 'Vehicle has to be rejected before resubmission',
    'error.youCanOnlyUpdateYourVehicles': 'Unauthorized vehicle update',
    'error.vehicleNotFound': 'Vehicle does not exist',
    'error.provider_already_assigned_error': 'User already exists',
    'error.no_email_found_on_profile': 'No email found on profile',
    'Please Provide a valid Email': 'Invalid email and password combination. Please try again.',
    'error.un_authorized_access': 'Unauthorized access',
    'error.value_should_contain_no_spaces': 'Value should not contain spaces',
    'error.reservation_incomplete': 'You cannot review a rental that has not been completed',
    'error.otp_not_found': 'OTP invalid. Please try again',
    'error.reservation_already_reviewed': 'Rental already reviewed!',
    'error.too_many_otp_attempts':
      'You have resent too many times. You account has been blocked. Please <a href="/contact/">contact support</a>',
    'error.account_blocked_error': 'You account has been blocked. Please <a href="/contact/">contact support</a>',
    'error.review_time_expired_error': 'Review period has expired',
    'error.vehicle_licence_no_already_exists': 'Licence number already exists!',
    'error.vehicle_vin_already_exists': 'VIN number already exists',
    'error.bookingNotAllowedError': "You can't book your own vehicle",
    'error.vehicle_licence_no_already_exists,error.vehicle_vin_already_exists':
      'VIN number and Licence number already exists',
    'error.orderLineItemNotFoundError': 'Order line item not found',
    defaultError: 'Unexpected error. Please contact admin'
  }
}

export const lookupError = (errorKey: string, language = 'en') => {
  let lookupLanguage = language
  if (!errorMessages[language]) lookupLanguage = 'en'
  if (errorKey && errorMessages[lookupLanguage][errorKey]) {
    return errorMessages[lookupLanguage][errorKey]
  } else {
    return errorKey
  }
}

export type ErrorMessages = keyof typeof errorMessages
export type ValidationMessages = keyof typeof validationMessages
export type Languages = keyof typeof languages
