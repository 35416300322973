import React, { useState, FC } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { navigate, Link } from 'gatsby'

import { Box } from 'rebass'
import { Heading, Button, PasswordInput, FormMessage, Loader, MessageColor } from '@rmr/components'
import { ResetPasswordMutation, useResetPassword } from '@rmr/controllers'

import { fieldStyles, buttonStyles } from './ResetForm.styled'
import { languages } from '../../common/i18n'

export type FormData = {
  password: string
}

interface ResetFormProps extends Partial<StepWizardChildProps> {
  resetToken: string
}
const ResetForm: FC<ResetFormProps> = ({ resetToken, nextStep }) => {
  const onSuccess = ({ resetPassword }: ResetPasswordMutation) => {
    if (resetPassword) {
      nextStep()
    }
  }

  const onError = (err) => {
    console.error(err)
  }

  const { register, errors, onSubmit, submitting, submitResult } = useResetPassword({ resetToken, onSuccess, onError })

  return (
    <React.Fragment>
      {submitting && <Loader />}
      <Box maxWidth={500}>
        <Heading size="h4" fontWeight="extraBold" mb={4} textAlign="center">
          {languages.en.auth.reset.headline}
        </Heading>
        <Box as="form" onSubmit={onSubmit} mb={0}>
          <Box>
            <PasswordInput
              name="password"
              label={languages.en.auth.reset.newPasswordLabel}
              sx={{ ...fieldStyles }}
              ref={register}
              error={errors.password && errors.password.message}
            />
            <PasswordInput
              name="confirm_password"
              label={languages.en.auth.reset.confirmPasswordLabel}
              sx={{ ...fieldStyles, mb: 0 }}
              ref={register}
              error={errors.confirm_password && errors.confirm_password.message}
            />
            <Link to="/app/login/">
              <Button variant="transparent" label={languages.en.auth.forgot.rememberPasswordLabel} ml="auto" />
            </Link>
          </Box>
          <FormMessage
            showMessage={submitResult.submitted}
            message={submitResult.message}
            color={`${submitResult.error ? 'statusError' : 'statusSuccess'}` as MessageColor}
          />
          <Button type="submit" label={languages.en.auth.reset.buttonText} sx={buttonStyles} mx={'auto'} />
        </Box>
      </Box>
    </React.Fragment>
  )
}
export default ResetForm
