import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Box } from 'rebass'
import { Container, Content, Heading } from '@rmr/components'
import { Helmet } from 'react-helmet'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Layout from '../modules/Layout'
import NoticeWrapper from '../components/NoticeWrapper'
import { extractMetaTags } from '../utils/extractMetaTags'

interface WpPageProps {
  data: {
    wpPage: GatsbyTypes.WpPage
    wp: GatsbyTypes.Wp
  }
  path: string
}
const WpPage = ({ data }: WpPageProps) => {
  const { wpPage, wp } = data
  const { content, title, seo, layoutSettings } = wpPage
  const extraMeta = extractMetaTags({
    seoDefaults: wp.constantSettings.seo_defaults,
    seoIncoming: wpPage.seo
  })
  return (
    <Main darkHeader renderNotice={() => <NoticeWrapper />}>
      <Helmet>
        {seo?.fullHead ? parse(seo.fullHead) : null}
        {extraMeta}
      </Helmet>

      {layoutSettings && layoutSettings.hasdynamiclayout && layoutSettings.layout ? (
        <Layout layout={layoutSettings.layout} />
      ) : (
        <Box as="section" py={[6, 10]}>
          <Container>
            {title && (
              <Heading fontWeight="boldest" size="h1" textAlign="center" mb={[6, 10]}>
                {title}
              </Heading>
            )}
            {content && <Content>{parse(content)}</Content>}
          </Container>
        </Box>
      )}
    </Main>
  )
}

export default WpPage

export const query = graphql`
  query WpPageQuery($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      id
      slug
      content
      title
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        fullHead
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterImage {
          sourceUrl
        }
        twitterTitle
      }
      layoutSettings {
        hasdynamiclayout
        layout {
          __typename
          ... on WpPage_Layoutsettings_Layout_HubspotForm {
            ...HubspotFields
          }
          ... on WpPage_Layoutsettings_Layout_ContentForm {
            ...ContentFormFields
          }
          ... on WpPage_Layoutsettings_Layout_Hero {
            ...HeroFields
          }
          ... on WpPage_Layoutsettings_Layout_Heroearnings {
            ...HeroEarningsFields
          }
          ... on WpPage_Layoutsettings_Layout_Banner {
            ...BannerFields
          }
          ... on WpPage_Layoutsettings_Layout_SectionSplit {
            ...SectionSplitFields
          }
          ... on WpPage_Layoutsettings_Layout_Features {
            ...SectionFeatureFields
          }
          ... on WpPage_Layoutsettings_Layout_Testimonials {
            ...TestimonialFields
          }
          ... on WpPage_Layoutsettings_Layout_SectionFeaturesSplit {
            ...SectionFeaturesSplitFields
          }
          ... on WpPage_Layoutsettings_Layout_Faqs {
            ...FaqFields
          }
          ... on WpPage_Layoutsettings_Layout_FeaturedVehicles {
            headline
            vehicleIds {
              id: vehicleId
            }
          }
          ... on WpPage_Layoutsettings_Layout_SectionCardCarousel {
            ...SectionCardsCarouselWrapperFields
          }
        }
      }
    }
    wp {
      constantSettings {
        seo_defaults {
          siteName
          title
          shortTitle
          description
          baseUrl
          image {
            sourceUrl
          }
          author
          keywords
          ogType
        }
      }
    }
  }
`
