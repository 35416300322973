import React, { FC } from 'react'
import { Box, Flex, SxStyleProp } from 'rebass'
import { RouteComponentProps } from '@reach/router'
import { GatsbyImage } from 'gatsby-plugin-image'
import { stepsWrapperStyles, Container } from '@rmr/components'
import { wrapperStyles, imageStyles, sectionStyles, stepsWrapper } from './Signup'
import { graphql, useStaticQuery } from 'gatsby'
import LoginForm from './LoginForm'
import SEO from './Seo'

const Login: FC<RouteComponentProps> = () => {
  const { heroImage } = useStaticQuery<GatsbyTypes.LoginQueryQuery>(graphql`
    query LoginQuery {
      heroImage: file(relativePath: { eq: "bg-image6.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)

  return (
    <Box as="section" sx={{ ...sectionStyles }}>
      <SEO
        title="Login | RentMyRide"
        description="We founded RentMyRide to allow mobility for everyone and change the way that people use cars."
        path="/app/approval-to-list"
      />
      {/* <Box sx={imageStyles}>
        <GatsbyImage
          image={heroImage.childImageSharp.gatsbyImageData}
          alt="hero image"
          objectFit="cover"
          style={{ width: '100%', height: '100%' }}
        />
      </Box> */}
      <Container>
        <Flex sx={wrapperStyles}>
          <Box sx={{ ...stepsWrapperStyles, ...stepsWrapper }}>
            <LoginForm />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Login
