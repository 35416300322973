import { ReactElement } from 'react'
import { Text, Icon, Error } from '@rmr/components'
import { Box, Flex, SxStyleProp } from 'rebass'
//   const itemStyle = 'flex border border-gray-200 gap-x-4 items-center w-full rounded-md py-2 px-4 rounded-md'
const addItemStyles: SxStyleProp = {
  display: 'flex',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'borderInput',
  gap: 1,
  alignItems: 'center',
  height: ['50px'],
  width: '100%',
  borderRadius: '4px',
  py: 1,
  px: 1,
  cursor: 'pointer'
}

interface RepeaterProps<T> {
  data: T[]
  renderItem: (item: T, index: number) => JSX.Element
  onAdd: (item: T) => void
  onRemove: (index: number) => void
  addRecipientLabel: string
  errors: string[]
}

const Repeater = <T,>(props: RepeaterProps<T>): ReactElement => {
  const { data, renderItem, onAdd, onRemove, addRecipientLabel, errors } = props

  const onClickAdd = () => {
    onAdd({} as T)
  }
  const onClickRemove = (index: number) => () => {
    onRemove(index)
  }

  return (
    <Flex flexDirection="column" sx={{ gap: 1 }}>
      <Flex flexDirection="column">
        {data.map((item, index) => {
          return (
            <>
              <Flex key={index} sx={{ alignItems: 'center', gap: 1 }}>
                <Box flex="1 0 95%">{renderItem(item, index)}</Box>
                <Box flex="1 0 5%">
                  {index > 0 && (
                    <Icon
                      name="close"
                      onClick={onClickRemove(index)}
                      color="red"
                      sx={{ cursor: 'pointer', fontSize: '20px' }}
                    />
                  )}
                </Box>
              </Flex>
              {!!errors[index] && <Error sx={{ position: 'static', mt: -1, mb: 1 }}>{errors[index]}</Error>}
            </>
          )
        })}
      </Flex>
      <Flex sx={{ alignItems: 'center', gap: 1, cursor: 'pointer' }} onClick={onClickAdd}>
        <Icon name="plus" />
        <Text size="small">{addRecipientLabel}</Text>
      </Flex>
    </Flex>
  )
}

export default Repeater
