import { SxStyleProp } from 'rebass'
import theme from '../../theme'

export const wrapperStyles: SxStyleProp = {
  position: 'relative',
  borderRadius: '50%',
  bg: 'bgLight'
}

export const placeholderStyles: SxStyleProp = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  cursor: 'pointer'
}
export const iconStyles: SxStyleProp = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  transform: 'translate(-10%,-10%)',
  borderWidth: '2px',
  cursor: 'pointer',
  borderColor: 'bgLight',
  boxShadow: `0 0 30px 1px ${theme.colors.bgShadow}`
}
