import colors from './colors'

const commonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  cursor: 'pointer',
  outline: 0,
  fontFamily: 'sans',
  transition: 'all 0.2s',
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.4
  }
}

const primary = {
  ...commonStyles,
  textTransform: 'uppercase',
  bg: 'primary',
  fontSize: ['tiny', 'small'],
  fontWeight: ['extraBold'],
  letterSpaing: '0.02857em',
  border: '1px solid transparent',
  '&:hover:enabled': {
    bg: 'baseWhite',
    color: 'primary',
    borderColor: 'primary'
  }
}

const black = {
  ...primary,
  bg: 'baseBlack',
  textTransform: 'none',
  fontWeight: ['bold'],
  '&:hover:enabled': {
    bg: 'baseWhite',
    color: 'baseBlack',
    borderColor: 'baseBlack'
  }
}

const gray = {
  ...primary,
  bg: 'bgGrayLight',
  textTransform: 'uppercase',
  fontWeight: ['bold'],
  '&:hover:enabled': {
    bg: 'baseWhite',
    color: 'textGrayLight',
    borderColor: 'bgGrayLight'
  }
}

const blackRound = {
  ...commonStyles,
  bg: 'baseBlack',
  color: 'textWhite',
  borderRadius: 50,
  px: 5,
  fontSize: 'tiny',
  fontWeight: `bold`
}

const green = {
  ...black,
  bg: 'statusSuccess',
  '&:hover:enabled': {
    bg: 'baseWhite',
    color: 'statusSuccess',
    borderColor: 'statusSuccess'
  }
}
const greenRound = {
  ...blackRound,
  bg: 'statusSuccess'
}

const outline = {
  ...commonStyles,
  bg: 'baseWhite',
  color: 'textDark',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'borderBase',
  fontSize: 'small',
  fontWeight: `bold`,
  '&:hover:enabled': {
    boxShadow: `0 0 5px 0px ${colors.bgShadow}`
  }
}

const simpleOutlineRound = {
  ...outline,
  borderRadius: 50
}

const buttons = {
  primary,
  black,
  blackRound,
  green,
  greenRound,
  gray,
  outline,
  simpleOutlineRound,
  outlineRound: {
    ...commonStyles,
    bg: 'transparent',
    color: 'textWhite',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'textWhite',
    borderRadius: 50,
    px: 5,
    fontSize: 'tiny',
    fontWeight: `bold`,
    textTransform: 'uppercase',
    '&:hover:enabled': {
      borderColor: 'primary',
      bg: 'primary'
    }
  },
  mutedRound: {
    ...blackRound,
    bg: 'baseWhite',
    color: 'borderLight',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'borderLight'
  },
  transparent: {
    ...commonStyles,
    bg: 'transparent',
    color: 'primary',
    fontSize: 'small',
    fontWeight: `medium`,
    '&:hover:enabled': {
      color: 'textDark'
    }
  },
  noStyles: {
    ...commonStyles,
    bg: 'transparent'
  }
}

export default buttons

export type ButtonVariant = keyof typeof buttons
