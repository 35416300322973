import React, { useState, forwardRef } from 'react'
import { Box, Flex, SxStyleProp } from 'rebass'
import { Input as RebassInput, InputProps as RebassInputProps } from '@rebass/forms'

import Icon from '../../Icon'
import LabelTooltip from '../LabelTooltip'
import Error from '../Error'
import FormGroup from '../FormGroup'
import { Label } from '@rmr/components'

import { fieldStyles, fieldWrapper, inlineLabelStyles } from '../Form.styled'
import { wrapperStyles, iconStyles } from './PasswordInput.styled'

export interface PasswordInputProps {
  /**
   * Field label
   */
  label?: string
  /**
   * Is the label inline or above field?
   */
  labelInline?: boolean
  /**
   * Info text for help
   */
  toolTipInfo?: string
  /**
   * Field placeholder
   */
  placeholder?: string
  /**
   * Field name
   */
  name: string
  /**
   * Error message
   */
  error?: string
  /**
   * Wrapper Sx prop
   */
  sx?: SxStyleProp
  /**
   * Inner Sx prop
   */
  innerSx?: SxStyleProp
  /**
   * Rebass Input props
   */
  rebassInputProps?: RebassInputProps
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ label, labelInline, toolTipInfo, placeholder, name, error, innerSx, rebassInputProps, ...props }, ref) => {
    const [passwordShown, setPasswordShown] = useState(false)

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true)
    }

    return (
      <FormGroup {...props}>
        {!labelInline && <LabelTooltip labelText={label} toolTipInfo={toolTipInfo} mb={1} />}
        <Flex sx={fieldWrapper(labelInline)}>
          {labelInline && <Label sx={inlineLabelStyles}>{label}</Label>}
          <Box sx={wrapperStyles}>
            <RebassInput
              type={passwordShown ? 'text' : 'password'}
              {...{ placeholder, name }}
              sx={{ ...fieldStyles, ...innerSx }}
              {...rebassInputProps}
              ref={ref}
            />
            <Box as="i" aria-hidden="true" sx={iconStyles} onClick={togglePasswordVisiblity}>
              {passwordShown ? <Icon name="eyeOpen" /> : <Icon name="eyeClosed" />}
            </Box>
          </Box>
        </Flex>
        {error && <Error>{error}</Error>}
      </FormGroup>
    )
  }
)

PasswordInput.defaultProps = {
  label: null,
  labelInline: false,
  toolTipInfo: null,
  placeholder: null,
  name: null,
  error: null,
  sx: {},
  innerSx: {}
}

export default PasswordInput
