import React, { useState, useEffect } from 'react'
import { Hero, HeroImageGallery, Heading, Text, Container, EarnResults, EarnSlider, theme } from '@rmr/components'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import { parse as searchParse } from 'query-string'
import parse from 'html-react-parser'
import GatsbyImage from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { Flex, Box } from 'rebass'
import { Amount, EarnModule } from '@rmr/helpers'
import { EARN_PARAMS } from '../common/constants'

const HeroEarningsWrapper = ({ headline, description, heroImages, earnings, duration, value }) => {
  const params = useLocation()
  const initValue = searchParse(params.search) as { value: string }

  const imageCount = (heroImages) => {
    if (heroImages.length > 1) {
      return (
        <HeroImageGallery>
          {heroImages.map((i, index) => {
            return (
              <GatsbyImage
                key={index}
                fluid={i.localFile.childImageSharp.fluid}
                style={{ width: '100%', height: '100%' }}
              />
            )
          })}
        </HeroImageGallery>
      )
    } else {
      return (
        <BackgroundImage
          fluid={heroImages[0].localFile.childImageSharp.fluid}
          style={{ width: '100%', height: '100%' }}
        />
      )
    }
  }

  const getValue = (inputValue: string, defaulValue: number) => {
    if (Number.isInteger(parseInt(inputValue))) {
      return parseInt(inputValue)
    }

    return defaulValue
  }

  const [durationValue, setDurationValue] = useState(duration.defaultDuration)
  const [marketValue, setMarketValue] = useState(
    initValue.value ? getValue(initValue.value, value.defaultValue) : value.defaultValue
  )
  const earn = new EarnModule(EARN_PARAMS.base, EARN_PARAMS.baseDayRate)

  const [earnValue, setEarnValue] = useState(earn.getPricePerDay(marketValue, durationValue))

  useEffect(() => {
    setEarnValue(Math.ceil(earn.getPricePerDay(marketValue * 100, durationValue)))
  }, [durationValue, marketValue])

  return (
    <>
      <Hero
        type={'calculator'}
        headline={parse(headline)}
        description={description}
        images={heroImages && heroImages.length > 0 ? imageCount(heroImages) : null}
      >
        <Container mt={[4]} mb={[2]}>
          <Flex flexDirection={['column', 'column', 'column', 'row']} justifyContent={'center'} alignItems={'center'}>
            <EarnSlider
              key={'value'}
              headline={value.headline}
              initial={marketValue}
              min={
                initValue.value
                  ? Math.min(value.minimumValue, getValue(initValue.value, value.minimumValue))
                  : value.minimumValue
              }
              max={
                initValue.value
                  ? Math.max(value.maximumValue, getValue(initValue.value, value.maximumValue))
                  : value.maximumValue
              }
              step={2500}
              unit={'R'}
              unitLeft={true}
              onChange={(marketValues) => {
                setMarketValue(marketValues[0])
              }}
            />
            <EarnSlider
              key={'duration'}
              headline={duration.headline}
              initial={duration.defaultDuration}
              min={duration.minimumDuration}
              max={duration.maximumDuration}
              unit={'Day'}
              unitPlural={'Days'}
              unitLeft={false}
              onChange={(durationValues) => {
                setDurationValue(durationValues[0])
              }}
            />
          </Flex>
        </Container>
      </Hero>

      <Box py={[4, 6]} width="100%" bg={theme.colors.bgWhite}>
        <Container>
          <Flex
            mx={'auto'}
            width={[1, 8 / 12]}
            alignItems={'center'}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            {earnings && earnings.headline && (
              <Heading
                mb={[1, 2]}
                textAlign="center"
                size="h6"
                fontWeight={theme.fontWeights.regular}
                color={theme.colors.textLight}
              >
                {earnings.headline}
              </Heading>
            )}

            <EarnResults price={marketValue} days={durationValue} width={[1, 'auto']}>
              {new Amount(earnValue * 100).format(0)}
            </EarnResults>

            {earnings && earnings.description && (
              <Text textAlign="center" size="small" color={theme.colors.textLight}>
                {parse(earnings.description)}
              </Text>
            )}
          </Flex>
        </Container>
      </Box>
    </>
  )
}

export default HeroEarningsWrapper

export const HeroEarningsFields = graphql`
  fragment HeroEarningsFields on WpPage_Layoutsettings_Layout_Heroearnings {
    headline
    description
    heroImages {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1900, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    value {
      headline
      defaultValue
      maximumValue
      minimumValue
    }
    duration {
      headline
      maximumDuration
      minimumDuration
      defaultDuration
    }
    earnings {
      headline
      description
    }
  }
`
