import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Moment } from 'moment'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRequestReservationMutation, RequestReservationMutation } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'
import { validationMessages } from '../i18n'

interface UseRequestReservationArgs {
  onSuccess: (response?: RequestReservationMutation, needsAuth?: boolean, needsApproval?: boolean) => void
  onError: (e: Error) => void
  vehicleId: string
  startDate: Moment
  endDate: Moment
  isApprovedToDrive: boolean
  isAuthed: boolean
}

const reservationSchema = yup.object().shape({
  startDate: yup.string().required(validationMessages.en.required),
  endDate: yup.string().required(validationMessages.en.required),
  locationId: yup.string().required(validationMessages.en.required)
})

export function useRequestReservation({
  onSuccess,
  onError,
  vehicleId,
  startDate,
  endDate,
  isApprovedToDrive,
  isAuthed
}: UseRequestReservationArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(reservationSchema)
  })

  const [requestReservation, { loading }] = useRequestReservationMutation()

  const onSubmit = useCallback(async () => {
    const values = form.getValues()

    if (!values.locationId) {
      onSubmitError('error', 'Please select pick up and return location')
      return
    }

    if (!isAuthed) {
      onSuccess(null, true, null)
      return
    } else {
      if (!isApprovedToDrive) {
        onSuccess(null, false, true)
        return
      }
    }
    try {
      const input = {
        startDate: values.startDate.toISOString(),
        endDate: values.endDate.toISOString(),
        locationId: null,
        isDropoff: false,
        vehicleId
      }

      if (values.locationId === 'dropoff') {
        input.isDropoff = true
      } else {
        input.locationId = values.locationId
      }
      const response = await requestReservation({
        variables: {
          input
        }
      })

      onSuccess(response.data)
      const { requestReservation: requestReservationResult } = response.data
      if (requestReservationResult.__typename === 'Reservation') {
        onSubmitSuccess()
      } else {
        // @ts-ignore
        onSubmitError('error', requestReservationResult.message)
      }
    } catch (err) {
      onError(err)
      onSubmitError()
    }
  }, [
    isAuthed,
    onSuccess,
    isApprovedToDrive,
    requestReservation,
    startDate,
    endDate,
    vehicleId,
    onSubmitSuccess,
    onSubmitError,
    onError
  ])

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult,
    onSubmitError,
    onSubmitSuccess
  }
}
