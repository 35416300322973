import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useCreatePaymentReservationMutation } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'

interface UsePaymentArgs {
  reservationId: string
  paymentMethod: { paymentMethodId: string; cardId?: string; settle?: boolean }
  onSuccess: (paymentResponse: { method: string; metadata: any }) => void
  onError: (e?: Error) => void
  settle?: boolean
}

export function usePaymentForm({ reservationId, paymentMethod, onSuccess, onError, settle = false }: UsePaymentArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm()

  const [createPayment, { loading: processing }] = useCreatePaymentReservationMutation()

  const onSubmit = useCallback(async () => {
    if (!paymentMethod || !paymentMethod.paymentMethodId) {
      onSubmitError('error', 'Please choose a payment method')
      return null
    }

    try {
      const input = {
        methodId: paymentMethod.paymentMethodId,
        metadata: { token: paymentMethod.cardId },
        settle: settle
      }
      const response = await createPayment({
        variables: {
          id: reservationId,
          input
        }
      })

      const {
        createPaymentReservation: { method, metadata }
      } = response.data
      if (metadata?.status === 'FAILED') {
        onError()
        onSubmitError()
      } else {
        onSuccess({ method, metadata })
        onSubmitSuccess()
      }
    } catch (err) {
      onError(err)
      onSubmitError()
    }
  }, [paymentMethod, onSubmitError, createPayment, reservationId, settle, onSuccess, onSubmitSuccess, onError])

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    processing,
    submitResult
  }
}
