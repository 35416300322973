import React, { forwardRef, useState, useEffect } from 'react'
import AvatarEditor, { ImageState } from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { Box, SxStyleProp, Image } from 'rebass'
import RangeSlider from '../RangeSlider'
import Badge from '../Badge'
import { iconStyles, placeholderStyles, wrapperStyles } from './AvatarUploader.styled'

export interface AvatarUploaderProps {
  /** Optional Initial Avatar URL */
  initialAvatarUrl?: string
  /** Canvas Width */
  width?: number
  /** Canvas Height */
  height?: number
  /** Accept Files */
  accept?: string | string[]
  /** Sx Prop */
  sx?: SxStyleProp
  /** On image load  */
  onLoadSuccess: (imgInfo: ImageState) => void
}
const AvatarUploader = forwardRef<AvatarEditor, AvatarUploaderProps>(
  ({ initialAvatarUrl, width, height = width, accept, sx, onLoadSuccess }, ref) => {
    const [iconHover, setIconHover] = useState(false)
    const [image, setImage] = useState(null)
    const [scale, setScale] = useState(1.1)

    const onDrop = (dropped) => {
      setImage(dropped[0])
    }

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      accept,
      multiple: false
    })

    useEffect(() => {
      if (initialAvatarUrl) {
        fetch(initialAvatarUrl)
          .then((res) => res.blob()) // Gets the response and returns it as a blob
          .then((blob) => {
            const fileName = initialAvatarUrl.split('/').pop()
            const newImage = new File([blob], fileName, { type: blob.type })
            setImage(newImage)
          })
          .catch((error) => {
            console.log({ error })
          })
      }
    }, [initialAvatarUrl])

    return (
      <Box width={width} height={height} sx={{ ...wrapperStyles, ...sx }} {...getRootProps()}>
        {!image && (
          <Badge
            variant="circle"
            icon={initialAvatarUrl ? undefined : 'user'}
            color="bgLight"
            size={['100%', '100%']}
            sx={placeholderStyles}
            image={
              initialAvatarUrl ? <Image src={initialAvatarUrl} alt="avatar" width="100%" height="100%" /> : undefined
            }
            onClick={open}
          />
        )}
        <input {...getInputProps()} />
        <AvatarEditor
          ref={ref}
          image={image}
          width={width}
          height={height}
          borderRadius={width}
          border={0}
          color={[255, 255, 255, 1]} // RGBA
          scale={scale}
          rotate={0}
          style={{ overflow: 'hidden', borderRadius: '50%' }}
          onLoadSuccess={onLoadSuccess}
        />
        <Box onMouseEnter={() => setIconHover(true)} onMouseLeave={() => setIconHover(false)}>
          <Badge
            variant="circleOutlined"
            icon={iconHover ? 'pen' : 'camera'}
            size={['25%', '25%']}
            sx={iconStyles}
            contentColor="primary"
            onClick={open}
          />
        </Box>
        {!!image && (
          <Box mt={-1}>
            <RangeSlider
              initialValues={[scale]}
              step={0.01}
              min={1}
              max={2}
              showLimits={false}
              onChangeValues={(values) => setScale(values[0])}
              showthumbLabel={false}
              showStaticLabel={false}
              thumbSx={{ width: 12, height: 12, borderWidth: 6 }}
            />
          </Box>
        )}
      </Box>
    )
  }
)
export default AvatarUploader

AvatarUploader.defaultProps = {
  initialAvatarUrl: null,
  width: 200,
  height: undefined,
  accept: ['image/jpeg', 'image/png'],
  sx: {}
}
