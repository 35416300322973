import React, { FC } from 'react'
import { Box, BoxProps, SxStyleProp } from 'rebass'

import { flexStyles } from './FlexGrid.styled'

export interface FlexGridProps extends BoxProps {
  /** Children */
  children: React.ReactNode[]
  /** Sx Styles */
  sx?: SxStyleProp
}

const FlexGrid = ({ children, sx, ...props }: FlexGridProps) => {
  return (
    <Box {...props} sx={{ ...flexStyles, ...sx }}>
      {children.map((child, index) => (
        <Box key={index} p={2}>
          {child}
        </Box>
      ))}
    </Box>
  )
}

FlexGrid.defaultProps = {
  children: [],
  sx: {}
}

export default FlexGrid
