import { useEffect, useMemo, useState, useCallback } from 'react'
import { useDropzone, FileRejection, DropzoneInputProps } from 'react-dropzone'
import filesize from 'filesize'
import { FileType, acceptedFileTypes } from '@rmr/controllers'
import languages from '../i18n'

export interface useUploadFileArgs {
  maxFiles?: number
  maxSize?: number
  fileType?: FileType
  initFiles: any[]
  callback: (files: File[], handleRemoveFile: (index: number) => void) => void
}
const useUploadFile = ({ maxFiles, maxSize, fileType, initFiles, callback }: useUploadFileArgs) => {
  const [message, setMessage] = useState('')
  const [files, setFiles] = useState(initFiles)

  const accept = useMemo(() => acceptedFileTypes(fileType), [fileType])

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      if (maxFiles && acceptedFiles.length + files.length > maxFiles) {
        const message = `${languages['en'].tooManyFiles} ${maxFiles}`
        setMessage(message)
        return null
      }
      setFiles((files) => {
        const newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
        return [...files, ...newFiles]
      })
    },
    [maxFiles, files.length]
  )

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const messages = fileRejections.reduce((acc, cur) => {
        const { errors, file } = cur
        errors.forEach(({ code }) => {
          if (!acc[code]) {
            acc[code] = [file]
          } else {
            acc[code].push(file)
          }
        })
        return acc
      }, {})
      let message = ''
      Object.keys(messages).forEach((errorCode) => {
        switch (errorCode) {
          case 'too-many-files':
            message = `${languages['en'].tooManyFiles}: ${maxFiles}`
            break
          case 'file-too-large':
            message = `${languages['en'].maxSize}: ${filesize(maxSize)}`
            break
          case 'file-invalid-type':
            message = `${languages['en'].invalidFileType} (${languages['en'].fileTypesAccepted}: ${accept.join(', ')})`
            break
        }
        message += `<br/>----------------------------`
        message += `<br/>Files Rejected:`
        messages[errorCode].forEach((file) => (message += `<br/>${file.name} (${filesize(file.size)})`))
      })
      setMessage(message)
    },
    [accept, maxFiles, maxSize]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    // noClick: true,
    maxFiles,
    maxSize,
    accept,
    onDropAccepted,
    onDropRejected,
    multiple: false
  })

  const handleRemoveFile = useCallback(
    (index: number) => {
      setFiles((files) => files.filter((f, i) => i !== index))
    },
    [setFiles]
  )

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => file.preview && URL.revokeObjectURL(file.preview))
    callback(files, handleRemoveFile)
  }, [callback, files, handleRemoveFile])

  return { getRootProps, getInputProps, isDragActive, open, message, setMessage, files, handleRemoveFile }
}

export default useUploadFile
