import { SxStyleProp } from 'rebass'

export const sectionStyles: SxStyleProp = {
  py: [15]
}
export const stepsWrapperStyles: SxStyleProp = {
  width: ['100%', null, 700, 900, 1100],
  mx: 'auto'
}

export const wrapperStyles: SxStyleProp = {
  px: [0, 0, 0, 0, 80],
  pt: [6],
  pb: [3]
}
