import React, { FC } from 'react'
import { Link as RebassLink, LinkProps as RebassLinkProps, SxStyleProp } from 'rebass'
import { processLink } from '../../utils/helpers'
import { useTheme, Theme } from '@emotion/react'

interface extendedTheme extends Theme {
  linkComponent?: React.ElementType
}

export interface LinkProps extends RebassLinkProps {
  to: string
  target?: '_blank' | '_self'
  label?: string
  onClick?: () => void
  as?: React.ElementType
  children?: React.ReactNode
  sx?: SxStyleProp
}

const Link: FC<LinkProps> = ({ children, to, target, sx, as, ...rest }) => {
  const linkProps = { ...rest, ...processLink(to, target) }
  const theme = useTheme() as extendedTheme
  const externalLink = to.indexOf('http://') !== -1 || to.indexOf('https://') !== -1 || to === '#'
  const linkComponent = externalLink ? 'a' : as ? as : theme.linkComponent ? theme.linkComponent : 'a'
  return (
    <RebassLink as={linkComponent} sx={{ display: 'inline-block', textDecoration: 'none', ...sx }} {...linkProps}>
      {children}
    </RebassLink>
  )
}

export default Link
