import React, { FC, ReactNode } from 'react'
import { Box, SxStyleProp } from 'rebass'
import theme from '../../theme'
import styled from '@emotion/styled'

const StyledSummary = styled(Box)`
  border-radius: ${theme.borderRadius.small};
  border: 2px solid ${theme.colors.borderBase};
`

export interface ListItemsProps {
  /** Children of ListItems */
  children?: ReactNode
  /** Sx Props */
  sx?: SxStyleProp
}

const ListItems: FC<ListItemsProps> = ({ children, sx, ...props }) => {
  const numChildren = React.Children.count(children)

  return (
    <StyledSummary p={[1]} {...props} sx={{ ...sx }}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          if (index === 0) {
            return React.cloneElement(child, { isFirst: true })
          } else if (index === numChildren - 1) {
            return React.cloneElement(child, { isLast: true })
          }
        }
        return child
      })}
    </StyledSummary>
  )
}

ListItems.defaultProps = {
  children: null,
  sx: {}
}

export default ListItems
