import React, { useState, useRef, useMemo, useCallback } from 'react'
import { Box, SxStyleProp, BoxProps } from 'rebass'

import useOnClickOutside from '../../../hooks/useOnClickOutside'

import FilterButton from '../FilterButton'

import FilterDropdown from '../FilterDropdown'
import { MultipleFilterAction, MultipleFilterProps } from '../MultipleFilter'
import { RangeFilterAction, RangeFilterProps } from '../RangeFilter'
import { SingleFilterAction, SingleFilterProps } from '../SingleFilter'
import { wrapperStyles } from './GroupFilter.styled'
import GroupFilterContent from './GroupFilterContent'
import theme from '../../../theme'

type FilterProps = SingleFilterProps | MultipleFilterProps | RangeFilterProps

export enum FilterType {
  SingleFilter,
  MultipleFilter,
  RangeFilter
}

const numberOfSelectedFilters = (filters: SelectedFilters) => {
  let count = 0
  const filtersArray = Object.keys(filters)
  for (let i = 0; i < filtersArray.length; i++) {
    if (filters[filtersArray[i]]) {
      count++
    }
  }
  return count
}

const getFilterAction = (props: FilterProps, type: FilterType): GroupFilterAction => {
  if (type === FilterType.SingleFilter) {
    const singleFilterProps = props as SingleFilterProps
    return singleFilterProps.onSet
  } else if (type === FilterType.MultipleFilter) {
    const multipleFilterProps = props as MultipleFilterProps
    return multipleFilterProps.onSet
  } else if (type === FilterType.RangeFilter) {
    const rangeFilterProps = props as RangeFilterProps
    return rangeFilterProps.onChangeValues
  }
}

export type FilterOption = {
  type: FilterType
  props: FilterProps
}

type GroupFilterAction = SingleFilterAction | MultipleFilterAction | RangeFilterAction

type FiltersActions = {
  [key: string]: GroupFilterAction
}

export type SelectedFilters = {
  [key: string]: boolean
}

export interface GroupFilterProps extends BoxProps {
  /** Filter name */
  filterName?: string
  /** List of filters */
  filters: FilterOption[]
  /** Callback with applied filters */
  onSet?: (selectedFilters: SelectedFilters) => void
  sx?: SxStyleProp
  /** Button Sx prop styles */
  buttonSx?: SxStyleProp
  /** is Mobile? */
  isMobile?: boolean
  /** show Filters? (mobile view) */
  showFilters?: boolean
  /** show Filters? (mobile view) */
  setShowFilters?: React.Dispatch<React.SetStateAction<boolean>>
}

const GroupFilter = ({
  filterName,
  filters,
  onSet,
  sx,
  buttonSx,
  isMobile,
  showFilters,
  setShowFilters,
  ...props
}: GroupFilterProps) => {
  const selectedFiltersValuesRef = useRef({})
  const appliedFiltersValuesRef = useRef({})

  const filtersActions: FiltersActions = useMemo(
    () =>
      filters.reduce((acc, { type, props }) => {
        acc[props.filterName] = getFilterAction(props, type)
        return acc
      }, {}),
    [filters]
  )

  const noFiltersState: SelectedFilters = useMemo(
    () =>
      filters.reduce((acc, { props }) => {
        acc[props.filterName] = false
        return acc
      }, {}),
    [filters]
  )

  const initialFiltersState: SelectedFilters = useMemo(() => {
    const filtersValues = {}
    const filtersState = filters.reduce((acc, { type, props }) => {
      let selected = false
      if (type === FilterType.SingleFilter) {
        const singleFilterProps = props as SingleFilterProps
        if (singleFilterProps.initialValue) {
          selected = true
          filtersValues[singleFilterProps.filterName] = singleFilterProps.initialValue
        }
      } else if (type === FilterType.MultipleFilter) {
        const multipleFilterProps = props as MultipleFilterProps
        if (multipleFilterProps.initialValues && multipleFilterProps.initialValues.length > 0) {
          selected = true
          filtersValues[multipleFilterProps.filterName] = multipleFilterProps.initialValues
        }
      } else if (type === FilterType.RangeFilter) {
        const rangeFilterProps = props as RangeFilterProps
        if (rangeFilterProps.initialValues) {
          if (
            rangeFilterProps.initialValues[0] > rangeFilterProps.min ||
            rangeFilterProps.initialValues[1] < rangeFilterProps.max
          ) {
            selected = true
            filtersValues[rangeFilterProps.filterName] = rangeFilterProps.initialValues
          }
        }
      }
      acc[props.filterName] = selected
      return acc
    }, {})
    selectedFiltersValuesRef.current = JSON.parse(JSON.stringify(filtersValues))
    appliedFiltersValuesRef.current = JSON.parse(JSON.stringify(filtersValues))
    return filtersState
  }, [filters])

  const [appliedFilters, setAppliedFilters] = useState(JSON.parse(JSON.stringify(initialFiltersState)))
  const [selectedFilters, setSelectedFilters] = useState(JSON.parse(JSON.stringify(initialFiltersState)))
  const [counter, setCounter] = useState(numberOfSelectedFilters(JSON.parse(JSON.stringify(initialFiltersState))))
  const [open, setOpen] = useState(false)

  const closeWithoutApplying = () => {
    isMobile ? setShowFilters(false) : setOpen(false)
    setSelectedFilters(JSON.parse(JSON.stringify(appliedFilters)))
    setCounter(numberOfSelectedFilters(appliedFilters))
    selectedFiltersValuesRef.current = JSON.parse(JSON.stringify(appliedFiltersValuesRef.current))
  }

  const filterRef = useRef(null)
  const buttonRef = useRef(null)
  useOnClickOutside(filterRef, () => open && closeWithoutApplying())

  const updateFilters = useCallback(
    (filterName: string, isSelected: boolean) => {
      setSelectedFilters((selectedFilters) => {
        selectedFilters[filterName] = isSelected
        setCounter(numberOfSelectedFilters(selectedFilters))
        return selectedFilters
      })
    },
    [setSelectedFilters]
  )

  const resetFilters = () => {
    setSelectedFilters(JSON.parse(JSON.stringify(noFiltersState)))
    selectedFiltersValuesRef.current = {}
    setCounter(0)
    applyFilters(noFiltersState)
    isMobile ? setShowFilters(false) : setOpen(false)
  }

  const applyFilters = (selectedFilters: SelectedFilters) => {
    Object.keys(selectedFilters).forEach((filterName) => {
      if (selectedFilters[filterName]) {
        filtersActions[filterName](selectedFiltersValuesRef.current[filterName])
      } else if (appliedFiltersValuesRef.current[filterName]) {
        filtersActions[filterName](null)
      }
    })
    setAppliedFilters(JSON.parse(JSON.stringify(selectedFilters)))
    appliedFiltersValuesRef.current = JSON.parse(JSON.stringify(selectedFiltersValuesRef.current))
    onSet(selectedFilters)
  }

  return (
    <Box ref={filterRef}>
      <Box sx={{ ...wrapperStyles, ...sx, zIndex: open ? theme.zIndex.modal + 1 : undefined }} {...props}>
        {!isMobile ? (
          <React.Fragment>
            <FilterButton
              ref={buttonRef}
              filterName={filterName}
              activeLabel={
                numberOfSelectedFilters(appliedFilters) > 0 ? `${numberOfSelectedFilters(appliedFilters)}` : undefined
              }
              onClickButton={() => setOpen((open) => !open)}
              onClickCloseButton={(e) => {
                resetFilters()
                e.stopPropagation()
              }}
              open={open}
              buttonSx={buttonSx}
            />
            <FilterDropdown
              isOpen={open}
              sx={{ width: [500], maxWidth: 'unset' }}
              containerSx={{ maxHeight: 'unset' }}
              buttonRef={buttonRef}
            >
              <GroupFilterContent
                filters={filters}
                selectedFilters={selectedFilters}
                applyFilters={applyFilters}
                updateFilters={updateFilters}
                resetFilters={resetFilters}
                closeWithoutApplying={closeWithoutApplying}
                open={open}
                setOpen={setOpen}
                counter={counter}
                selectedFiltersValuesRef={selectedFiltersValuesRef}
                appliedFiltersValuesRef={appliedFiltersValuesRef}
              />
            </FilterDropdown>
          </React.Fragment>
        ) : (
          <GroupFilterContent
            filters={filters}
            selectedFilters={selectedFilters}
            applyFilters={applyFilters}
            updateFilters={updateFilters}
            resetFilters={resetFilters}
            closeWithoutApplying={closeWithoutApplying}
            open={isMobile ? showFilters : open}
            setOpen={isMobile ? setShowFilters : setOpen}
            counter={counter}
            selectedFiltersValuesRef={selectedFiltersValuesRef}
            appliedFiltersValuesRef={appliedFiltersValuesRef}
          />
        )}
      </Box>
    </Box>
  )
}

GroupFilter.defaultProps = {
  initialFiltersState: {},
  filterName: '',
  filters: [],
  onSet: () => null,
  sx: {},
  buttonSx: {},
  isMobile: false
}

export default GroupFilter
