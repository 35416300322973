import Resizer from 'react-image-file-resizer'

export enum CompressFormat {
  JPEG = 'JPEG',
  PNG = 'PNG',
  WEBP = 'WEBP'
}
export enum OutputType {
  Base64 = 'base64',
  Blob = 'blob',
  File = 'file'
}

interface ResizeFileArgs {
  file: File
  maxWidth?: number
  maxHeight?: number
  compressFormat?: CompressFormat
  quality?: number
  rotation?: number
  responseUriFunc?: (uri: string) => void
  outputType?: OutputType
}

export const resizeImageFile = ({
  file,
  maxWidth = 1920,
  maxHeight,
  compressFormat = CompressFormat.JPEG,
  quality = 80,
  rotation = 0,
  responseUriFunc,
  outputType = OutputType.File
}: ResizeFileArgs) => {
  if (!maxHeight) maxHeight = maxWidth
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      responseUriFunc
        ? responseUriFunc
        : (uri) => {
            resolve(uri)
          },
      outputType
    )
  })
}
