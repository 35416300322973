import { SxStyleProp } from 'rebass'
import theme from '../../theme'

export const trackStyles: SxStyleProp = {
  bg: 'bgLight',
  height: '6px',
  width: '100%',
  borderRadius: '6px'
}
export const activeTrackStyles = (width: number): SxStyleProp => ({
  bg: 'primary',
  height: '6px',
  borderRadius: '6px',
  position: 'absolute',
  top: 0,
  left: 0,
  width: `${width * 100}%`
})

export const stepStyles = (left: number, highlight: boolean, isClickable: boolean): SxStyleProp => ({
  bg: highlight ? 'primary' : 'baseWhite',
  border: `5px solid ${highlight ? theme.colors.primary : theme.colors.bgLight}`,
  color: highlight ? 'textWhite' : 'textBlack',
  fontWeight: 'bold',
  width: [35, 55],
  height: [35, 55],
  borderRadius: `${theme.borderRadius.circle}`,
  position: 'absolute',
  top: 0,
  left: `${left * 100}%`,
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: isClickable ? 'pointer' : 'unset'
})

export const labelWrapperStyles = (left: number): SxStyleProp => ({
  position: 'absolute',
  top: ['20px', '40px'],
  left: `${left * 100}%`,
  transform: 'translateX(-50%)',
  width: [60, 100, 120, 'unset'],
  justifyContent: 'center'
})

export const labelStyles = (bold: boolean): SxStyleProp => ({
  color: bold ? 'textBlack' : 'textGrayLight',
  display: 'flex',
  justifyContent: 'center',
  fontSize: ['10px !important', '12px !important', '14px !important']
})
