import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  UpdateProfileMutation,
  ProfileUpdateInput,
  useUpdateProfileMutation,
  User,
  AddressInput
} from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'
import { parseMobileNumber } from '../helpers/phoneNumber'
import moment from 'moment'
import { addressToString } from '../helpers/addresses'

interface UseProfileUpdateArgs {
  onSuccess?: (response: UpdateProfileMutation) => void
  onError?: (e: Error) => void
  defaultValues?: User & {
    profile?: {
      hasDriversLicence?: boolean
      hasIdDocument?: boolean
      hasProofAddress?: boolean
      hasSelfie?: boolean
      addresses?: AddressInput[]
    }
  }
  schema?: any
}

const updateProfileSchema = yup.object().shape({
  consent: yup.boolean()
})

export function useProfileUpdate({
  onSuccess,
  onError,
  defaultValues,
  schema = updateProfileSchema
}: UseProfileUpdateArgs = {}) {
  const [fileErrors, setFileErrors] = useState<{ [x: string]: { message: string } }>({})

  const [files, setFiles] = useState(
    defaultValues.documents ? defaultValues.documents.map((doc) => ({ type: doc.type, fileId: doc.file.id })) : []
  )
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues.profile
  })

  const [updateProfileMutation, { loading }] = useUpdateProfileMutation()

  const onSubmit = useCallback(
    async (data) => {
      const update: ProfileUpdateInput = data

      if (files) {
        update.documents = files.map((f) => ({ fileId: f.fileId, type: f.type }))
      }

      try {
        const {
          phoneNumber,
          altPhoneNumber,
          hasDriversLicence,
          driversLicenseFirstIssued,
          isInternational,
          hasIdDocument,
          hasProofAddress,
          hasSelfie,
          addresses,
          whatsapp,
          ...input
        } = data

        if (phoneNumber) {
          const phoneNumberObject = parseMobileNumber(phoneNumber)
          Object.assign(input, {
            phoneNumber: phoneNumberObject.number,
            countryCode: `+${phoneNumberObject.countryCallingCode}`
          })
        }

        if (altPhoneNumber) {
          const phoneNumberObject = parseMobileNumber(altPhoneNumber)
          Object.assign(input, {
            altPhoneNumber: phoneNumberObject.number,
            altCountryCode: `+${phoneNumberObject.countryCallingCode}`
          })
        }

        if (driversLicenseFirstIssued) {
          Object.assign(input, { driversLicenseFirstIssued: moment(driversLicenseFirstIssued).toISOString() })
        }

        if (addresses) {
          const { __typename, id, ...addressfields } = addresses[0]

          Object.assign(input, { address: [{ ...addressfields }] })
        }
        const response = await updateProfileMutation({ variables: { input } })
        onSuccess && onSuccess(response.data)

        if (response.errors) {
          onSubmitError()
          return
        }

        if (response.data) {
          onSubmitSuccess()
        }
      } catch (err) {
        console.log(err)
        onError && onError(err)
        onSubmitError()
      }
    },
    [updateProfileMutation, onSuccess, onError, onSubmitError, onSubmitSuccess, files]
  )

  return {
    ...form,
    files,
    setFiles,
    fileErrors,
    setFileErrors,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  }
}
