import { DocumentType } from '@rmr/controllers'
import { SxStyleProp } from 'rebass'

interface StateFiles {
  type: DocumentType
  fileId: string
  preview?: string
}

const idRef = 'approved-to-list-top'
const uploadWrapperStyles: SxStyleProp = {
  display: 'grid',
  gridTemplateColumns: ['repeat(1, 1fr)', null, null, 'repeat(2, 1fr)'],
  rowGap: [2],
  columnGap: [2, 2, 4],
  mb: [3],
  width: '100%'
}

const getFile = (files: StateFiles[], doctype: DocumentType) => {
  const file = files.find((f) => f.type === doctype)
  if (file) {
    return file
  }

  return null
}

export { idRef, getFile, uploadWrapperStyles }
export type { StateFiles }
