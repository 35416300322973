export const processTrimLowerCase = (email: string) => email.replace(' ', '').toLowerCase()
export const processTrimUpperCase = (email: string) => email.replace(' ', '').toUpperCase()

export const limitText = (text: string, limit = 200) => {
  if (!text) return text
  if (text.length <= limit) {
    return text
  } else {
    return text.substring(0, limit) + '...'
  }
}

export const processCouponCode = (couponCode) => {
  const regex = /[^A-Za-z0-9\-_+]/g
  return couponCode.toUpperCase().replace(regex, '')
}
