import { SxStyleProp } from 'rebass'

import theme from '../../../theme'

export const wrapperStyles: SxStyleProp = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxHeight: ['100vh', null, null, 450]
}
export const applyButtonStyles: SxStyleProp = {
  flexDirection: 'column',
  width: '100%',
  p: [1],
  fontSize: `${theme.fontSizes.tiny} !important`
}

export const closeIconStyles = {
  position: 'absolute',
  top: 0,
  right: 3,
  fontSize: '20px',
  color: `${theme.colors.textDark}`,
  cursor: 'pointer'
}
