import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAddNoteToHandoverMutation, AddNoteToHandoverInput } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'
import { NoteTypeEnum } from '..'

interface DefaultValues {
  comment: string
}

interface UseUpdateHandoverArgs {
  onSuccess?: () => void
  onError?: (e: Error) => void
  defaultValues?: DefaultValues
  schema?: any
  handoverId: string
}

export function useHandoverComment({ onSuccess, onError, defaultValues, schema, handoverId }: UseUpdateHandoverArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const [addNoteToHandover, { loading }] = useAddNoteToHandoverMutation()

  const onSubmit = useCallback(
    async (data) => {
      try {
        const { comment } = data as DefaultValues
        const input: AddNoteToHandoverInput = {
          type: NoteTypeEnum.VehicleImages,
          comment
        }

        const response = await addNoteToHandover({ variables: { input, handoverId } })

        if (response.errors) {
          onSubmitError()
          return
        }

        if (response.data.addNoteToHandover.__typename === 'HandoverNote') {
          onSuccess && onSuccess()
          onSubmitSuccess('Comment saved successfully')
        }
      } catch (err) {
        console.log(err)
        onError && onError(err)
        onSubmitError()
      }
    },
    [addNoteToHandover, handoverId, onSubmitError, onSuccess, onSubmitSuccess, onError]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult
  }
}
