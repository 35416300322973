import React from 'react'
import { Flex } from 'rebass'
import { scroller } from 'react-scroll'
import { Button } from '@rmr/components'
import { languages } from '../common/i18n'
import { Link } from 'gatsby'

export const scrollToHeadline = (id) => {
  scroller.scrollTo(id, {
    duration: 500,
    smooth: 'easeInOut'
  })
}

interface StepsNavigationProps {
  previousStep?: () => void
  buttonNextClick?: () => void
  buttonBacklabel?: string
  buttonNextlabel?: string
  finishLaterButton?: boolean
  finishLaterLabel?: string
  currentStep?: number
  idRef: string
}

const StepsNavigation = ({
  previousStep,
  currentStep,
  buttonNextClick,
  finishLaterButton,
  finishLaterLabel = languages['en'].listYourCar.finishLaterLabel,
  buttonBacklabel = languages['en'].listYourCar.buttonTextBack,
  buttonNextlabel = languages['en'].listYourCar.buttonTextNext,
  idRef
}: StepsNavigationProps) => {
  const handleClickBack = () => {
    previousStep()
    scrollToHeadline(idRef)
  }
  const handleClickNext = () => {
    buttonNextClick()
  }
  return (
    <Flex mt={[3]}>
      {currentStep > 0 && previousStep && (
        <Button variant="gray" onClick={handleClickBack} mr={[1]}>
          {buttonBacklabel}
        </Button>
      )}
      {buttonNextClick && (
        <Button variant="primary" onClick={handleClickNext} mr={[1]}>
          {buttonNextlabel}
        </Button>
      )}
      {finishLaterButton && (
        <Link to="/rent-a-car/">
          <Button variant="primary">{finishLaterLabel}</Button>
        </Link>
      )}
    </Flex>
  )
}

export default StepsNavigation
