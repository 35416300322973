import React, { FC } from 'react'
import { graphql } from 'gatsby'
import SectionFeaturesWrapper from './SectionFeaturesWrapper'
import { Flex } from 'rebass'

interface SectionFeaturesSplitWrapperProps {
  /** Children */
  children: React.ReactNode
}

const SectionFeaturesSplitWrapper: FC<SectionFeaturesSplitWrapperProps> = ({ children }) => {
  return (
    <Flex flexDirection={['column', 'column', 'row']}>
      {children.map((i, index) => {
        return <SectionFeaturesWrapper key={index} {...i} />
      })}
    </Flex>
  )
}

export default SectionFeaturesSplitWrapper

export const SectionFeaturesSplitFields = graphql`
  fragment SectionFeaturesSplitFields on WpPage_Layoutsettings_Layout_SectionFeaturesSplit {
    children: sectionFeaturesSplit {
      # these fields are the same as on SectionFeaturesWrapper. changing the one means you need to change the other
      featureType
      headline
      variant
      backgroundColor
      content
      sectionImage: featureImage {
        sourceUrl
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      featureType
      features {
        badge
        badgeIcon: badgeicon
        featureContent
        headline
        icon
        iconColor: iconcolor
        badgeTextColor: badgecolor
      }
    }
  }
`
