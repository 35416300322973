import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  UpdateVehicleInput,
  UpdateVehicleMutation,
  useUpdateVehicleMutation,
  RateCalculationData,
  VehicleAvailabilityType,
  useSubmitResult
} from '@rmr/controllers'

interface UseRateCalculationArgs {
  onSuccess: (response: UpdateVehicleMutation) => void
  defaultValues: RateCalculationData
}

export function useRateCalculationForm({ onSuccess, defaultValues }: UseRateCalculationArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const RateCalculationFormSchema = yup.object().shape({
    price_per_day: yup.number().nullable().required('Required field'),
    availability: yup.string().nullable().required('Required field')
  })

  const form = useForm({
    resolver: yupResolver(RateCalculationFormSchema),
    defaultValues
  })

  const [updateVehicleMutation, { loading: loadingUpdate }] = useUpdateVehicleMutation()

  const onSubmit = useCallback(
    async (data: RateCalculationData) => {
      const { id, price_per_day, availability } = data
      const vehicleInputData: UpdateVehicleInput = {
        id,
        price_per_day: price_per_day,
        is_weekends_only: +availability === VehicleAvailabilityType.WeekendsOnly,
        is_weekdays_only: +availability === VehicleAvailabilityType.WeekdaysOnly
      }
      try {
        const response = await updateVehicleMutation({ variables: { input: vehicleInputData } })
        if (response?.data) {
          onSuccess(response.data)
          onSubmitSuccess()
        } else {
          onSuccess(undefined)
          onSubmitError()
        }
      } catch (err) {
        console.log('onSubmit Update Error: ', err)
        if (err.message) {
          onSubmitError('error', err.message)
        } else {
          onSubmitError()
        }
      }
    },
    [updateVehicleMutation, onSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loadingUpdate,
    submitResult,
    onSubmitError,
    onSubmitSuccess
  }
}
