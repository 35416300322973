import React from 'react'
import { Box, Flex } from 'rebass'

import {
  Button,
  Icon,
  Heading,
  SingleFilter,
  MultipleFilter,
  RangeFilter,
  SingleFilterProps,
  MultipleFilterProps,
  RangeFilterProps
} from '@rmr/components'

import { applyButtonStyles, closeIconStyles, wrapperStyles } from './GroupFilterContent.styled'
import { SelectedFilters, FilterOption, FilterType } from './GroupFilter'

interface GroupFilterContentProps {
  filters: FilterOption[]
  selectedFilters: SelectedFilters
  resetFilters: () => void
  applyFilters: (selectedFilters: SelectedFilters) => void
  updateFilters: (filterName: string, isSelected: boolean) => void
  closeWithoutApplying: () => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  counter: number
  selectedFiltersValuesRef: React.MutableRefObject<{}>
  appliedFiltersValuesRef: React.MutableRefObject<{}>
}
const GroupFilterContent = ({
  filters,
  resetFilters,
  selectedFilters,
  applyFilters,
  updateFilters,
  closeWithoutApplying,
  open,
  setOpen,
  counter,
  selectedFiltersValuesRef,
  appliedFiltersValuesRef
}: GroupFilterContentProps) => {
  return (
    <Flex sx={wrapperStyles}>
      <Box sx={{ position: 'relative', mt: 2 }}>
        <Flex justifyContent="center">
          <Heading size="h5" fontWeight="extraBold" mb={1} textAlign="center">
            Filters
            {counter > 0 ? ` (${counter})` : undefined}
          </Heading>
        </Flex>
        <Icon onClick={closeWithoutApplying} name="close" sx={closeIconStyles} />
      </Box>
      <Box sx={{ flex: '1 1 auto', overflow: ['auto'], p: [3, 3, 2] }}>
        {filters.map(({ type, props }, index) => {
          const { filterName } = props
          let Component
          switch (type) {
            case FilterType.SingleFilter:
              const singleFilterProps = props as SingleFilterProps
              Component = (
                <Box mb={2}>
                  <SingleFilter
                    key={open || selectedFilters[filterName] ? 1 : 0}
                    {...singleFilterProps}
                    groupMode={true}
                    dropdownSx={{ boxShadow: 'none' }}
                    onSet={(value) => {
                      selectedFiltersValuesRef.current[props.filterName] = value
                      if (value) {
                        updateFilters(filterName, true)
                      } else {
                        updateFilters(filterName, false)
                      }
                    }}
                  />
                </Box>
              )
              break
            case FilterType.MultipleFilter:
              const multipleFilterProps = props as MultipleFilterProps
              Component = (
                <Box mb={multipleFilterProps.checkBoxMode ? [2] : [3, 4]}>
                  <MultipleFilter
                    key={open || selectedFilters[filterName] ? 1 : 0}
                    {...multipleFilterProps}
                    groupMode={true}
                    buttonText="apply"
                    dropdownSx={{ boxShadow: 'none' }}
                    onSet={(values = []) => {
                      selectedFiltersValuesRef.current[filterName] = values
                      if (values.length > 0) {
                        updateFilters(filterName, true)
                      } else {
                        updateFilters(filterName, false)
                      }
                    }}
                    selectMode={true}
                    sx={{ width: '100% !important' }}
                  />
                </Box>
              )
              break
            case FilterType.RangeFilter:
              const rangeFilterProps = props as RangeFilterProps
              const { min, max } = rangeFilterProps
              Component = (
                <Box mb={1}>
                  <RangeFilter
                    key={open || selectedFilters[filterName] ? 1 : 0}
                    {...rangeFilterProps}
                    groupMode={true}
                    sx={{ boxShadow: 'none', pr: 3, mb: 3 }}
                    onChangeValues={(values: number[]) => {
                      selectedFiltersValuesRef.current[props.filterName] = values
                      if (values[0] > min || values[1] < max) {
                        !selectedFilters[filterName] && updateFilters(filterName, true)
                      } else {
                        selectedFilters[filterName] && updateFilters(filterName, false)
                      }
                    }}
                  />
                </Box>
              )
              break
          }
          return <Box key={index}>{Component}</Box>
        })}
      </Box>
      <Flex p={[1]}>
        <Button
          variant="primary"
          mr={1}
          sx={applyButtonStyles}
          onClick={() => {
            setOpen(false)
            applyFilters(selectedFilters)
          }}
        >
          <Box as="div">Apply</Box>
        </Button>
        <Button
          variant="gray"
          disabled={!(appliedFiltersValuesRef.current && Object.keys(appliedFiltersValuesRef.current).length > 0)}
          sx={applyButtonStyles}
          onClick={() => {
            resetFilters()
          }}
        >
          <Box as="div">Reset</Box>
        </Button>
      </Flex>
    </Flex>
  )
}

export default GroupFilterContent
