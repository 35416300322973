import React, { Fragment, forwardRef } from 'react'
import { Card as RebassCard, CardProps as RebassCardProps, Box, Flex, SxStyleProp } from 'rebass'
import ReactStars from 'react-rating-stars-component'
import Skeleton from 'react-loading-skeleton'
import languages, { Language } from '../../i18n'
import Carousel from '../Carousel'
import { PriceDistributionOption } from '../PriceDistribution'
import Price from '../Price'
import { vehicleCardStyles, carouselStyles, headerImagestyles } from './VehicleCard.styled'

import Text from '../Text'
import theme from '../../theme'
import Icon from '../Icon/Icon'

export interface VehicleCardProps extends RebassCardProps {
  /** Array of Slides */
  slides: React.ReactNode[]
  /** Car Make */
  carMake: string
  /** Car Model */
  carModel: string
  /** Car Price */
  price?: string
  /** Car Price Distribution*/
  priceDistribution?: PriceDistributionOption
  /** Number of review */
  totalReviews?: number
  /** Number of trips */
  totalTrips?: number
  /** Rating */
  rating?: number
  /** language */
  language?: Language
  /** Sx Styles */
  sx?: SxStyleProp
  /** Click handler */
  onClick?: () => void
}

const VehicleCard = forwardRef<HTMLDivElement, VehicleCardProps>(
  (
    { slides, carMake, carModel, totalReviews, totalTrips, price, priceDistribution, rating, language, sx, ...props },

    ref
  ) => {
    return (
      <RebassCard ref={ref} {...props} padding={3} sx={{ ...(vehicleCardStyles as SxStyleProp), ...sx }}>
        <Box mb={3}>
          {slides.length === 0 ? (
            <Box sx={headerImagestyles}>{slides[0]}</Box>
          ) : (
            <Carousel variant="vehicleCardCarousel" sx={carouselStyles}>
              {slides.map((slide, index) => (
                <Fragment key={index}>{slide} </Fragment>
              ))}
            </Carousel>
          )}
        </Box>

        <Box mb={3}>
          <Text size="large" color="textLight">
            {carMake || carModel ? '' : <Skeleton />}
          </Text>
          <Text size="large" color="textBlack" fontWeight="extraBold">
            {carModel || <Skeleton />}
          </Text>
        </Box>

        <Flex alignItems="flex-start" mt="auto" justifyContent={['space-between']} flexWrap={['wrap']}>
          <Box
            sx={{
              marginRight: [2],
              marginBottom: [2],
              flex: '1 0 auto'
            }}
          >
            {!Number.isNaN(totalTrips) && totalTrips >= 0 ? (
              <Text size="small" color="textLight" mb={[1]}>
                {totalReviews > 0 ? `${totalReviews} ${languages[language].reviews} / ` : null}
                {totalTrips > 0 ? `${totalTrips} ${languages[language].trips}` : languages[language].noTrips}
              </Text>
            ) : (
              <Text width={80} size="small" color="textLight" mb={[1]}>
                <Skeleton />
              </Text>
            )}
            {(!Number.isNaN(rating) &&
              rating >= 0 &&
              (rating > 0 ? (
                <ReactStars
                  classNames="car-rating"
                  count={5}
                  edit={false}
                  activeColor={theme.colors.primaryLight}
                  color={theme.colors.bgGrayLight}
                  value={rating}
                  emptyIcon={<Icon name="star" />}
                  filledIcon={<Icon name="star" />}
                />
              ) : (
                <Box>
                  <Text size="small" color="textLight" mt={'4px'}>
                    {languages[language].noRating}
                  </Text>
                </Box>
              ))) || (
              <Text size="small" width={80} color="textLight" mt={'4px'}>
                <Skeleton />
              </Text>
            )}
          </Box>
          {price ? (
            <Box sx={{ flex: '0 0 auto' }}>
              <Text size="small" color="textLight">
                {languages[language].from}
              </Text>
              <Box>
                <Price price={price} priceDistribution={priceDistribution} />
              </Box>
            </Box>
          ) : (
            <Box sx={{ flex: '0 0 auto' }}>
              <Text size="small" width={50} color="textLight">
                <Skeleton />
              </Text>
              <Box width={100}>
                <Price price={price} priceDistribution={priceDistribution} />
              </Box>
            </Box>
          )}
        </Flex>
      </RebassCard>
    )
  }
)

VehicleCard.defaultProps = {
  slides: [],
  carMake: 'Car Make',
  carModel: 'Car Model',
  totalReviews: 0,
  totalTrips: 0,
  rating: 0,
  price: '',
  priceDistribution: 'daily',
  language: 'en',
  sx: {},
  onClick: () => null
}

export default VehicleCard
