import { SxStyleProp } from 'rebass'

export const checkboxStyles: SxStyleProp = {
  color: 'textDark',
  mr: 1
}

export const wrapperStyles: SxStyleProp = {
  display: 'flex',
  alignItems: 'center'
}

export const formGroupStyles: SxStyleProp = {
  "input[type='checkbox']": {
    '&:focus': {
      '& + svg': {
        backgroundColor: 'transparent',
        '& + svg': {
          backgroundColor: 'transparent'
        }
      }
    },
    '&:not(:checked)': {
      color: 'primary',
      '& + svg': {
        backgroundColor: 'transparent',
        '& + svg': {
          backgroundColor: 'transparent',
          color: 'primary'
        }
      }
    }
  }
}
