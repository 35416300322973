import { Icon, SelectOptionExtended, Radio } from '@rmr/components'
import React, { useState, useRef } from 'react'
import { Box, SxStyleProp, BoxProps } from 'rebass'

import useOnClickOutside from '../../hooks/useOnClickOutside'

import Text from '../Text'
import FilterButton from '../Filters/FilterButton'
import FilterDropdown from '../Filters/FilterDropdown'
import { wrapperStyles, contentStyles } from './SelectWithTitles.styled'
import { selectButtonStyles } from '../Filters/MultipleFilter/MultipleFilter.styled'

export interface OptionWithTitle {
  title: string
  options: SelectOptionExtended[]
}

export type SelectWithTitlesAction = (value: string | number) => void

export interface SelectWithTitlesProps extends BoxProps {
  /** Initial Value */
  initialValue?: string | number
  /** Filter Name */
  filterName: string
  /** Options to filter/sort from */
  optionsWithTitle: OptionWithTitle[]
  /** Callback with Selected option */
  onSet: SelectWithTitlesAction
  /** Sx prop styles */
  sx?: SxStyleProp
  /** Dropdown Sx prop styles */
  dropdownSx?: SxStyleProp
  /** Button Sx prop styles */
  buttonSx?: SxStyleProp
}

const SelectWithTitles = ({
  filterName,
  optionsWithTitle,
  onSet,
  buttonSx,
  dropdownSx,
  sx,
  ...props
}: SelectWithTitlesProps) => {
  const [activeOption, setActiveOption] = useState<SelectOptionExtended>()
  const [open, setOpen] = useState(false)
  const filterRef = useRef(null)
  const buttonRef = useRef(null)
  useOnClickOutside(filterRef, () => open && setOpen(false))

  const selectOption = (option: SelectOptionExtended) => {
    setOpen(false)
    setActiveOption(option)
    onSet(option.value)
  }

  return (
    <Box ref={filterRef} sx={{ ...wrapperStyles, ...sx }} {...props}>
      <React.Fragment>
        <FilterButton
          ref={buttonRef}
          filterName={activeOption ? activeOption.label : filterName}
          open={open}
          buttonSx={{ ...selectButtonStyles, ...buttonSx }}
          onClickButton={() => setOpen((open) => !open)}
        />
        <FilterDropdown isOpen={open} sx={dropdownSx} buttonRef={buttonRef}>
          <Box px={2} py={3}>
            {optionsWithTitle.map(({ title, options }, index) => (
              <Box key={index}>
                <Text size="small" fontWeight="bold">
                  {title}
                </Text>
                {options.map((option, index) => (
                  <Text
                    key={index}
                    size="small"
                    sx={contentStyles(activeOption && option.label === activeOption.label)}
                    onClick={() => selectOption(option)}
                  >
                    {option.icon && <Icon name={option.icon} width={[15]} textAlign="center" mr={1} />}
                    <div>
                      {option.label}
                      {option.description ? (
                        <div>
                          {' '}
                          <Text size="tiny">{option.description}</Text>
                        </div>
                      ) : null}
                    </div>
                  </Text>
                ))}
              </Box>
            ))}
          </Box>
        </FilterDropdown>
      </React.Fragment>
    </Box>
  )
}

SelectWithTitles.defaultProps = {
  filterName: '',
  optionsWithTitle: [],
  sx: {},
  dropdownSx: {},
  buttonSx: {}
}

export default SelectWithTitles
