import React, { useState, useMemo, useEffect } from 'react'
import { Box, BoxProps, Flex, SxStyleProp } from 'rebass'

import Label from '../Form/Label'
import Text from '../Text'
import Select from '../Form/Select'
import Error from '../Form/Error'
import { Moment } from 'moment'

export interface DatePickerProps extends BoxProps {
  /** dateLabel */
  dateLabel?: string
  /** Year field name */
  yearName?: string
  /** Month field name */
  monthName?: string
  /** Day field name */
  dayName?: string
  /** Year placeholder */
  yearPlaceholder?: string
  /** Month placeholder */
  monthPlaceholder?: string
  /** Day placeholder */
  dayPlaceholder?: string
  /** Initial Date */
  dateInit?: Moment
  /** Error message */
  errorMessage?: string
  /** Date Change Handler */
  onDateChange?: (year?: number, month?: number, day?: number) => void
  /** Sx prop styles */
  sx?: SxStyleProp
}

const DatePicker = ({
  dateLabel,
  yearName,
  monthName,
  dayName,
  yearPlaceholder,
  monthPlaceholder,
  dayPlaceholder,
  dateInit,
  errorMessage,
  onDateChange,
  sx,
  ...props
}: DatePickerProps) => {
  const [dayOptions, monthOptions, yearOptions] = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const years = [],
      months = [],
      days = []
    for (let i = 0; i < 80; i++) {
      years.push({ value: currentYear - i, label: `${currentYear - i}` })
    }
    for (let i = 1; i <= 12; i++) {
      months.push({ value: i, label: i > 9 ? `${i}` : `0${i}` })
    }
    for (let i = 1; i <= 31; i++) {
      days.push({ value: i, label: i > 9 ? `${i}` : `0${i}` })
    }
    return [days, months, years]
  }, [])
  const [dateYear, setDateYear] = useState(dateInit ? dateInit.get('year') : null)
  const [dateMonth, setDateMonth] = useState(dateInit ? dateInit.get('month') + 1 : null)
  const [dateDay, setDateDay] = useState(dateInit ? dateInit.get('date') : null)

  useEffect(() => {
    onDateChange && onDateChange(dateYear, dateMonth, dateDay)
  }, [dateDay, dateMonth, dateYear, onDateChange])
  return (
    <Box sx={sx} {...props}>
      <Label mb={0}>
        <Text size="small" mr={[2]} flex="1 0 auto">
          {dateLabel}{' '}
        </Text>
      </Label>
      <Flex alignItems="center" sx={{ position: 'relative' }}>
        <Select
          name={yearName}
          placeholder={yearPlaceholder}
          options={yearOptions}
          rebassSelectProps={{
            onChange: (e) => (e.target.value ? setDateYear(+e.target.value) : setDateYear(null)),
            value: dateYear
          }}
          sx={{
            minWidth: [90],
            mr: 1,
            svg: {
              ml: '-30px'
            }
          }}
          innerSx={{ px: 2 }}
        />
        <Select
          name={monthName}
          placeholder={monthPlaceholder}
          options={monthOptions}
          rebassSelectProps={{
            onChange: (e) => (e.target.value ? setDateMonth(+e.target.value) : setDateMonth(null)),
            value: dateMonth
          }}
          sx={{
            minWidth: [70],
            mr: 1,
            svg: {
              ml: '-30px'
            }
          }}
        />
        <Select
          name={dayName}
          placeholder={dayPlaceholder}
          options={dayOptions}
          rebassSelectProps={{
            onChange: (e) => (e.target.value ? setDateDay(+e.target.value) : setDateDay(null)),
            value: dateDay
          }}
          sx={{
            minWidth: [70],
            svg: {
              ml: '-30px'
            }
          }}
        />
        {errorMessage && <Error sx={{ position: 'absolute', mt: 11 }}>{errorMessage}</Error>}
      </Flex>
    </Box>
  )
}

DatePicker.defaultProps = {
  dateLabel: '',
  yearName: 'year',
  monthName: 'month',
  dayName: 'day',
  yearPlaceholder: '',
  monthPlaceholder: '',
  dayPlaceholder: '',
  dateInit: null,
  errorMessage: '',
  onDateChange: null,
  sx: {}
}

export default DatePicker
