import React, { useRef, useEffect, useMemo } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { Box } from 'rebass'
import useMedia from 'use-media'

import {
  useRateCalculationForm,
  UpdateVehicleMutation,
  RateCalculationData,
  RecommendationPrice
} from '@rmr/controllers'

import {
  MessageColor,
  Button,
  FormMessage,
  Text,
  Heading,
  Separator,
  theme,
  RangeSlider,
  Radio,
  Loader,
  WizardStep
} from '@rmr/components'
import { languages } from '../../../common/i18n'
import { scrollToHeadline } from '../../StepsNavigation'
import Layout from '../Layout'

import { headlineStyles, limitsLabelStyles, radioWrapperStyles, rangeSliderThumb } from './RateCalculation.styled'

interface RateCalculationProps extends Partial<StepWizardChildProps> {
  idRef: string
  vehicleid: string
  rateCalculation: RateCalculationData
  recommendedPrice: RecommendationPrice
  wizardSteps: WizardStep[]
}
const RateCalculation = ({
  idRef,
  currentStep,
  previousStep,
  nextStep,
  goToNamedStep,
  vehicleid,
  rateCalculation,
  recommendedPrice,
  wizardSteps
}: RateCalculationProps) => {
  const initialValue = useMemo(() => {
    let value
    if (rateCalculation.price_per_day) {
      value = rateCalculation.price_per_day
    } else {
      value = recommendedPrice.price.amountFloat
    }
    if (value > recommendedPrice.max.amountFloat) {
      return recommendedPrice.max.amountFloat
    }
    if (value < recommendedPrice.min.amountFloat) {
      return recommendedPrice.min.amountFloat
    }
    return value
  }, [
    rateCalculation.price_per_day,
    recommendedPrice.price.amountFloat,
    recommendedPrice.min.amountFloat,
    recommendedPrice.max.amountFloat
  ])

  const isMobile = useMedia({ maxWidth: theme.breakpoints[0] }) // 576px

  const formButtonRef = useRef<HTMLButtonElement | null>()

  const handleClickNext = () => {
    if (formButtonRef) {
      formButtonRef.current.click()
    }
  }

  const currency = recommendedPrice.price.currencyCode

  const handleNextStep = () => {
    nextStep()
    scrollToHeadline(idRef)
  }

  const onSuccess = ({ updateVehicle }: UpdateVehicleMutation) => {
    if (updateVehicle.__typename === 'Vehicle') {
      handleNextStep()
    }
  }

  const { watch, register, setValue, onSubmit, submitting, submitResult } = useRateCalculationForm({
    onSuccess,
    defaultValues: rateCalculation
  })

  const availability = watch('availability')
  const price_per_day = watch('price_per_day')

  const onSliderChange = (values: number[]) => {
    setValue('price_per_day', values[0] * 100)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(e)
  }

  useEffect(() => {
    setValue('price_per_day', rateCalculation.price_per_day * 100)
  }, [rateCalculation.price_per_day, setValue])

  useEffect(() => {
    register('id')
    register('availability')
    register('price_per_day')
    setValue('id', vehicleid)
    setValue(
      'price_per_day',
      rateCalculation.price_per_day ? rateCalculation.price_per_day * 100 : recommendedPrice.price.amountFloat * 100
    )
    setValue('availability', rateCalculation.availability)
  }, [
    rateCalculation.availability,
    rateCalculation.price_per_day,
    recommendedPrice.price.amountFloat,
    register,
    setValue,
    vehicleid
  ])

  return (
    <Layout
      idRef={idRef}
      headline={languages['en'].listYourCar.rateCalculation.headline}
      currentStep={currentStep - 1}
      previousStep={previousStep}
      buttonNextClick={handleClickNext}
      wizardSteps={wizardSteps}
      goToNamedStep={goToNamedStep}
    >
      {submitting && <Loader />}
      <Box as="form" onSubmit={handleSubmit} sx={{ alignSelf: 'stretch', px: [2, null, 0] }}>
        <Text size="small" color="textLight" sx={{ mt: [-3] }}>
          {languages['en'].listYourCar.rateCalculation.intro}
        </Text>
        <Box mt={[15]} mb={[5]}>
          {recommendedPrice.max.amountFloat > recommendedPrice.min.amountFloat && (
            <RangeSlider
              key={recommendedPrice.price.amountFloat}
              initialValues={[initialValue]}
              min={recommendedPrice.min.amountFloat}
              max={recommendedPrice.max.amountFloat}
              onChangeValues={onSliderChange}
              customMinLimitLabel={`/ ${languages['en'].listYourCar.rateCalculation.minLabel}`}
              customMaxLimitLabel={`${languages['en'].listYourCar.rateCalculation.maxLabel} /`}
              customThumbLabel={
                recommendedPrice.price.amountFloat * 100 === price_per_day
                  ? `${languages['en'].listYourCar.rateCalculation.recommendedRate}`
                  : ''
              }
              unit={currency}
              unitPlural={currency}
              showStaticLabel={false}
              showthumbLabel={true}
              thumbLabelTop={true}
              thumbLabelSx={rangeSliderThumb}
              limitsLabelSx={limitsLabelStyles}
            />
          )}
        </Box>
        <Box sx={{ my: [2], border: `1px dashed ${theme.colors.borderLight}` }} />
        <Box sx={headlineStyles}>
          <Heading size="h6">{languages['en'].listYourCar.vehicleAvailability.headline}</Heading>
          <Separator />
        </Box>
        <Text size="small" color="textLight" mb={[2]}>
          {languages['en'].listYourCar.vehicleAvailability.intro}
        </Text>
        <Box sx={radioWrapperStyles}>
          <Radio
            name="availability"
            variant={isMobile ? 'vertical' : 'horizontal'}
            options={languages['en'].listYourCar.vehicleAvailability.availabilityOptions}
            selectedOption={availability}
            rebassRadioProps={{
              onChange: (e) => setValue('availability', e.target.value)
            }}
          />
        </Box>
        <Box sx={{ mb: [2] }}>
          <FormMessage
            showMessage={submitResult.submitted}
            message={submitResult.message}
            color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
          />
        </Box>
        <Box width={0} height={0} overflow="hidden">
          <Button ref={formButtonRef} type="submit" label="submit" />
        </Box>
      </Box>
    </Layout>
  )
}

export default RateCalculation
