import React, { useState, FC, useRef, useEffect } from 'react'
import { Coords } from 'google-map-react'
import { Moment } from 'moment'
import { Box, Link as RebassLink } from 'rebass'
import { AllVehicleFieldsFragment, Vehicle } from '@rmr/controllers'
import { VehicleCardSimple, useOnClickOutside, ImageWrapper, Link } from '@rmr/components'

import { cardVehicleStyles, iconsWrapper, pinIcon, vehicleCardWrapperStyles, wrapperStyles } from './LocationPin.styled'

interface LocationPinProps extends Coords {
  hovered: boolean
  vehicleActive: string | null
  vehicle: AllVehicleFieldsFragment
  onActiveVehicle: React.Dispatch<React.SetStateAction<string>>
  mapContainerRef: React.MutableRefObject<HTMLDivElement>
  setCenterZoom: React.Dispatch<
    React.SetStateAction<{
      center: {
        lat: number
        lng: number
      }
      zoom: number
    }>
  >
  startDate?: string
  endDate?: string
}
const LocationPin: FC<LocationPinProps> = ({
  hovered,
  vehicleActive,
  onActiveVehicle,
  vehicle,
  mapContainerRef,
  setCenterZoom,
  startDate,
  endDate
}) => {
  const [vehicleCardEl, setVehicleCardEl] = useState<HTMLDivElement | null>(null)
  const active = vehicleActive === vehicle.id
  const { id, pricePerDay, name, images, stats } = vehicle

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(wrapperRef, (e) => {
    const event = e as MouseEvent | TouchEvent
    // this checks whether event ocurred on map or pin
    // @ts-ignore
    active && event.target.localName === 'div' && onActiveVehicle(null)
  })

  const selectVehicle = (id: string) => {
    onActiveVehicle(id)
  }

  useEffect(() => {
    // center vehicle card if out of map boundaries
    if (mapContainerRef.current && vehicleCardEl) {
      const mapLeft = mapContainerRef.current.getBoundingClientRect().x
      const vehicleCardLeft = vehicleCardEl.getBoundingClientRect().x
      const mapTop = mapContainerRef.current.getBoundingClientRect().y
      const vehicleCardTop = vehicleCardEl.getBoundingClientRect().y
      const mapWidth = mapContainerRef.current.clientWidth
      const vehicleCardWidth = vehicleCardEl.clientWidth
      const mapHeight = mapContainerRef.current.clientHeight
      const vehicleCardHeight = vehicleCardEl.clientHeight
      const mapBottom = mapTop + mapHeight
      const vehicleCardBottom = vehicleCardTop + vehicleCardHeight
      const mapRight = mapLeft + mapWidth
      const vehicleCardRight = vehicleCardLeft + vehicleCardWidth

      if (
        mapLeft > vehicleCardLeft ||
        mapTop > vehicleCardTop ||
        vehicleCardRight > mapRight ||
        vehicleCardBottom > mapBottom
      ) {
        setCenterZoom((centerZoom) => ({
          zoom: centerZoom.zoom,
          center: { lat: vehicle.lat, lng: vehicle.lng }
        }))
      }
    }
  }, [mapContainerRef, vehicleCardEl, vehicle, setCenterZoom])

  let url = `/app/listing/${id}`
  if (startDate && endDate) {
    url += `?startDate=${+startDate}&endDate=${+endDate}`
  }
  return (
    <Box
      ref={wrapperRef}
      sx={wrapperStyles(active || hovered)}
      onTouchEnd={(e) => {
        !active && selectVehicle(vehicle.id)
      }}
      onMouseDown={(e) => {
        !active && selectVehicle(vehicle.id)
      }}
    >
      <Box sx={iconsWrapper}>
        <Box sx={pinIcon(hovered, active)}>
          <svg width="158.75mm" height="158.75mm" viewBox="0 0 158.75 158.75" version="1.1" id="svg873">
            <circle r="79.375" cy="79.375" cx="79.375" id="path12" fill="#000000" strokeWidth={0.264583} />
            <g fill="#ffffff" id="layer1" transform="matrix(0.73157292,0,0,0.73157292,-8.6131597,-0.06933346)">
              <g fill="#ffffff" id="g865" transform="matrix(1.3419216,0,0,1.3419216,70.272578,56.19183)">
                <path
                  fill="#ffffff"
                  d="M 67.52,42.67 A 22.54,22.54 0 0 0 52.89,48.05 22.62,22.62 0 0 0 15.63,65.3 v 28.49 h 16 V 65.3 a 6.58,6.58 0 0 1 13.16,0 v 28.49 h 16.1 V 65.3 a 6.58,6.58 0 0 1 13.16,0 v 28.49 h 16.1 V 65.3 A 22.65,22.65 0 0 0 67.52,42.67 Z"
                  transform="translate(-15.63,-15.69)"
                  id="path8"
                />
                <ellipse fill="#ffffff" cx="22.66" cy="11.73" rx="12.1" ry="11.73" id="ellipse10" />
                <ellipse fill="#ffffff" cx="51.93" cy="11.75" rx="12.1" ry="11.73" id="ellipse12" />
              </g>
            </g>
          </svg>
        </Box>
        <Box sx={pinIcon(hovered, active)}>
          <svg id="svg8" version="1.1" viewBox="0 0 111.06371 108.59562" height="108.59562mm" width="111.06371mm">
            <g transform="translate(-40.723358,-72.80843)" id="layer1">
              <ellipse
                ry="54.29781"
                rx="55.531853"
                cy="127.10624"
                cx="96.255211"
                id="path10"
                fill="currentcolor"
                strokeWidth={0.264583}
              />
              <ellipse
                ry="13.574452"
                rx="13.882963"
                cy="127.10624"
                cx="96.255211"
                id="path12"
                fill="white"
                strokeWidth={0.197932}
              />
            </g>
          </svg>
        </Box>
      </Box>
      {active && (
        <RebassLink href={url} target="_blank" sx={{ display: 'block' }}>
          <Box ref={(div) => div && setVehicleCardEl(div)} sx={vehicleCardWrapperStyles}>
            <VehicleCardSimple
              price={pricePerDay.formattedNoDecimals}
              priceDistribution="daily"
              carName={name}
              // only 1 image
              slides={images.slice(0, 1).map((img) => (
                <ImageWrapper key={img.id} src={img.file.url} alt={name} />
              ))}
              totalTrips={stats.trips}
              rating={Math.ceil(stats.ratings)}
              language="en"
              boxShadow={true}
              showArrow={true}
              paddingText={[2]}
              sx={cardVehicleStyles}
            />
          </Box>
        </RebassLink>
      )}
    </Box>
  )
}

export default LocationPin
