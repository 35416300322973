import React from 'react'

import { Box, BoxProps, SxStyleProp } from 'rebass'

export interface SvgProps extends BoxProps {
  /** Svg Children */
  children?: React.ReactNode
  /** Svg width */
  width?: string
  /** Svg height */
  height?: string
  /** Svg color */
  color?: string
  /** Sx prop styles */
  sx?: SxStyleProp
}

const Svg = ({ children, width, height, color, sx }: SvgProps) => <Box sx={{ width, color, ...sx }}>{children}</Box>

Svg.defaultProps = {
  children: null,
  width: '100px',
  height: 'auto',
  color: 'baseWhite',
  sx: {}
}

export default Svg
