import React, { FC } from 'react'
import { Share, SocialSharing } from '@rmr/components'
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share'

export interface SocialShareProps {
  children?: React.ReactElement | React.ReactNode
  /**  URL of the shared page */
  url: string
}

const SocialSharingWrapper: FC<SocialShareProps> = ({ url }) => {
  if (!url) return null
  return (
    <SocialSharing
      sx={{
        flexDirection: ['row', 'row', 'column'],
        justifyContent: ['space-around', 'space-around', 'flex-end'],
        alignItems: ['center'],
        width: '100%'
      }}
    >
      <FacebookShareButton url={url}>
        <Share name={'facebook'} />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <Share name={'twitter'} />
      </TwitterShareButton>
      <EmailShareButton url={url}>
        <Share name={'envelope'} />
      </EmailShareButton>
    </SocialSharing>
  )
}

export default SocialSharingWrapper
