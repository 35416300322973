import React from 'react'

import { Box, BoxProps, SxStyleProp } from 'rebass'

export interface SeparatorProps extends BoxProps {
  /** Flag indicating whether the modal is open or not. */
  bg: string
  /**  Sx Prop Styles */
  sx?: SxStyleProp
}

const Separator = ({ bg, sx, ...props }: SeparatorProps) => (
  <Box sx={{ height: '4px', width: [57], bg, my: [1], ...sx }} {...props} />
)
Separator.defaultProps = {
  bg: 'bgBlack',
  sx: {}
}

export default Separator
