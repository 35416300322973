import { SxStyleProp } from 'rebass'

export const checkboxStyles: SxStyleProp = {
  'label ': { alignItems: ['start'] },
  width: '100%'
}
export const tcHeadlineStyles: SxStyleProp = {
  a: {
    textDecoration: 'underline',
    color: 'primary'
  }
}
export const tcTextStyles: SxStyleProp = {
  a: {
    textDecoration: 'underline',
    color: 'primary'
  }
}
