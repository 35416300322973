export const carouselStyles = {
  px: '40px',
  '.testimonials-carousel': {
    '.slick-dots': {
      bottom: '-30px',
      li: {
        width: '25px',
        height: '25px',
        margin: '0'
      },
      button: {
        width: '25px',
        height: '25px',
        padding: 0,
        margin: 0
      },
      'button::before': {
        fontSize: '35px',
        width: '25px',
        height: '25px',
        color: 'bgWhite',
        opacity: 0.4
      },
      '.slick-active': {
        'button::before': {
          opacity: 1
        }
      }
    }
  }
}

export const testimonialStyles = {}
