import React, { useContext } from 'react'
import { Box } from 'rebass'

import { Header, Footer } from '@rmr/components'
import { AuthContext } from '@rmr/controllers'
import { RouteComponentProps } from '@reach/router'

import useHeaderMenu from '../menus/useHeaderMenu'
import useFooterMenu from '../menus/useFooterMenu'

interface MainProps extends RouteComponentProps {
  darkHeader?: boolean
  children: React.ReactNode
  renderNotice?: () => JSX.Element
}
const Main = ({ children, darkHeader, renderNotice }: MainProps) => {
  const { isAuthed } = useContext(AuthContext)

  const dynamicuseHeaderMenu = useHeaderMenu()

  if (isAuthed) {
    dynamicuseHeaderMenu.navButtons = [
      {
        label: 'Dashboard',
        to: process.env.GATSBY_NX_DASHBOARD_LINK,
        target: '_self'
      }
    ]
  }
  return (
    <>
      <Header {...dynamicuseHeaderMenu} dark={darkHeader} renderNotice={renderNotice} />
      <Box as="main">{children}</Box>
      <Footer {...useFooterMenu()} />
    </>
  )
}

export default Main

Main.defaultProps = {
  children: null,
  darkHeader: false,
  noFooter: false,
  renderNotice: null
}
