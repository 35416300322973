import { MenuItemType, VehicleCardFeature } from '@rmr/components'
import {
  addressToString,
  AllVehicleFieldsFragment,
  SearchVehicleFieldsFragment,
  SearchVehicleInput,
  Vehicle,
  VehicleAvailabilityType,
  VehicleDocumentTypeEnum
} from '@rmr/controllers'
import dayjs from 'dayjs'
import queryString from 'query-string'
import { languages } from '../common/i18n'
import { VehicleState as VehicleListingState } from '../components/ListYourCar/ListYourCar'

export type WpMenuItem = {
  id: string
  label: string
  url: string
  parentId: string
  childItems: {
    nodes: WpMenuItem[]
  }
}

export type WpMenuItems = { nodes: WpMenuItem[] }

export const wpMenuItemsMapping = (wpMenuItems: WpMenuItems, wpUrl: string): MenuItemType[] => {
  const menuItems: MenuItemType[] = wpMenuItems.nodes.map(({ id, label, url, childItems }) => {
    const menuItems = childItems && childItems.nodes.length > 0 ? wpMenuItemsMapping(childItems, wpUrl) : undefined
    return { id, name: label, to: url.replace(wpUrl, ''), subMenuItems: menuItems }
  })

  return menuItems
}

export const object2objectMapping = <T>(objDestination: T, objSource: unknown): T => {
  const output = JSON.parse(JSON.stringify(objDestination))
  Object.keys(output).forEach((property) => {
    if (objSource[property] !== null) {
      if ((!isNaN(objSource[property]) && objSource[property] >= 0) || !!objSource[property]) {
        output[property] = objSource[property]
      }
    }
  })
  return output
}

export const vehicleListingMapping = (
  defaultState: VehicleListingState,
  vehicle: AllVehicleFieldsFragment
): VehicleListingState => {
  const output: VehicleListingState = JSON.parse(JSON.stringify(defaultState))
  const vehicleObject: AllVehicleFieldsFragment = JSON.parse(JSON.stringify(vehicle))
  if (vehicleObject['category']) {
    vehicleObject['categoryId'] = vehicleObject['category'].id
  }
  if (vehicleObject['features']) {
    vehicleObject['featureIds'] = vehicleObject['features'].map((feature) => feature.id)
  }

  if (vehicleObject['details']) {
    vehicleObject['transunionId'] = vehicleObject['details'].id
    vehicleObject['year'] = vehicleObject['details'].year
    vehicleObject['make'] = vehicleObject['details'].make
    vehicleObject['model'] = vehicleObject['details'].model
  }

  // vehicle information
  output.vehicleInformation = object2objectMapping(defaultState.vehicleInformation, vehicleObject)
  vehicleObject.documents.forEach(({ type, file }) => {
    if (type === VehicleDocumentTypeEnum.LicenseDisk) {
      output.vehicleInformation.licenceDisk = file.id
    } else if (type === VehicleDocumentTypeEnum.RoadworthyCertificate) {
      output.vehicleInformation.roadsworthyCertificate = file.id
    } else if (type === VehicleDocumentTypeEnum.TrackerDocumentation) {
      output.vehicleInformation.trackingDeviceCertificate = file.id
    }
  })
  // vehicle Documents
  output.existingDocuments = vehicleObject.documents
  // vehicle Images
  output.existingImages = vehicleObject.images

  // Rental Conditions
  output.rentalConditions = object2objectMapping(defaultState.rentalConditions, vehicleObject)
  if (vehicleObject.addresses && vehicleObject.addresses.length > 0) {
    const { type, __typename, id, ...rest } = vehicleObject.addresses[0]
    output.rentalConditions.address = JSON.stringify({ ...rest })
    output.rentalConditions.vehicleLocation = addressToString(vehicleObject.addresses[0])
  }
  // vehicle rate calculation and availability
  if (vehicleObject['recommendedPrice'] && vehicleObject['recommendedPrice'].price) {
    output.rateCalculation.price_per_day = vehicleObject['recommendedPrice'].price.amountFloat
  }

  output.recommendedPrice = object2objectMapping(
    defaultState.recommendedPrice,
    vehicleObject.recommendedPrice ? vehicleObject.recommendedPrice : vehicleObject
  )
  output.rateCalculation = object2objectMapping(defaultState.rateCalculation, vehicleObject)
  if (vehicleObject?.pricePerDay?.amountFloat) {
    output.rateCalculation.price_per_day = vehicleObject.pricePerDay.amountFloat
  }

  let availability = VehicleAvailabilityType.allDates
  if (vehicleObject.is_weekdays_only) availability = VehicleAvailabilityType.WeekdaysOnly
  if (vehicleObject.is_weekends_only) availability = VehicleAvailabilityType.WeekendsOnly
  output.rateCalculation.availability = availability
  // vehicle accept terms
  output.termsConditions.accept_terms = vehicleObject.t_c_accepted_at ? true : false
  return output
}

export const getLink = (
  filter: SearchVehicleInput,
  page?: number,
  before?: string,
  after?: string,
  anchor?: string
) => {
  const { price, model, startDate, endDate, place, latitude, longitude, ...rest } = filter

  let searchFilter: any = {}
  Object.keys(rest)
    .filter((key) => !!rest[key])
    .forEach((key) => {
      searchFilter[key] = rest[key]
    })
  if (price && price.min) {
    searchFilter.minPrice = price.min
  }
  if (price && price.max) {
    searchFilter.maxPrice = price.max
  }

  if (startDate && endDate) {
    searchFilter.startDate = startDate.unix()
    searchFilter.endDate = endDate.unix()
  }

  if (place) {
    searchFilter = {
      ...searchFilter,
      ...place
    }
  }

  if (latitude && longitude) {
    searchFilter.pl = utf8_to_b64(`${latitude}|${longitude}`)
  }

  let url = queryString.stringify(
    { ...searchFilter, page, before, after },
    { arrayFormat: 'separator', arrayFormatSeparator: '|' }
  )

  if (anchor) url += `#${anchor}`

  return `/rent-a-car/?${url}`
}

export function formatDate(date, format = 'DD/MM/YYYY'): string {
  return date ? dayjs(date).format(format) : ''
}

export const getIntentionStr = (additionalInfo: { isOwner?: boolean; isRenter?: boolean }) => {
  const intent = []
  if (additionalInfo?.isOwner) {
    intent.push(languages.en.auth.signup.intentToList)
  }

  if (additionalInfo?.isRenter) {
    intent.push(languages.en.auth.signup.intentToRent)
  }
  return intent ? intent.join('; ') : null
}

export const getVehicleCardDetails = (vehicle: Vehicle) => {
  const { addresses, max_kilometers_per_day, is_deliver_airport, is_deliver_renter } = vehicle
  let location = ''
  const address = addresses?.[0]
  if (address) {
    location = `${address.city ? address.city : ''}${
      address.state ? (address.city ? `, ${address.state}` : address.state) : ''
    }`
  }
  const features: VehicleCardFeature[] = []
  if (typeof max_kilometers_per_day === 'number') {
    features.push({
      icon: 'odometer',
      label:
        max_kilometers_per_day === 0
          ? `${languages.en.vehicleListing.unlimitedKms} kms`
          : `${max_kilometers_per_day}km`,
      tooltip: languages.en.vehicleListing.featuresTooltips.kmDay
    })
  }
  if (is_deliver_airport) {
    features.push({
      icon: 'plane',
      tooltip: languages.en.vehicleListing.featuresTooltips.airportDelivery
    })
  }
  if (is_deliver_renter) {
    features.push({
      icon: 'truck',
      tooltip: languages.en.vehicleListing.featuresTooltips.renterDelivery
    })
  }
  return { location, features }
}

const isBrowser = () => typeof window !== 'undefined'

export function utf8_to_b64(str) {
  if (!isBrowser()) return str
  return window.btoa(unescape(encodeURIComponent(str)))
}

export function b64_to_utf8(str) {
  if (!isBrowser()) return str
  return decodeURIComponent(escape(window.atob(str)))
}
