import React, { forwardRef } from 'react'
import { SxStyleProp, BoxProps, Box } from 'rebass'
import { Checkbox as RebassCheckbox, Label } from '@rebass/forms'

import Text from '../../Text'
import FormGroup from '../FormGroup'
import Popover from '../../Popover'

import { CheckboxProps as RebassCheckboxProps } from '@rebass/forms'

import Error from '../Error'
import { checkboxStyles, wrapperStyles, formGroupStyles } from './Checkbox.styled'

export interface CheckboxProps extends BoxProps {
  /** Field label */
  children: React.ReactNode
  /** Info text for help */
  toolTipInfo?: string
  /** Field name */
  name: string
  /** Error message */
  error?: string
  /** Wrapper Sx prop */
  sx?: SxStyleProp
  /** checkbox Sx prop */
  innerSx?: SxStyleProp
  /** Label Sx prop */
  labelSx?: SxStyleProp
  /** Rebass checkbox props */
  rebassCheckboxProps?: RebassCheckboxProps
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, toolTipInfo, name, error, sx, innerSx, labelSx, rebassCheckboxProps, ...props }, ref) => {
    return (
      <FormGroup {...props} sx={{ ...formGroupStyles, ...sx }}>
        {children && (
          <Label sx={wrapperStyles}>
            <Box minWidth="25px">
              <RebassCheckbox name={name} ref={ref} sx={{ ...checkboxStyles, ...innerSx }} {...rebassCheckboxProps} />
            </Box>
            <Text size="tiny" color="textDark" fontWeight="bold" sx={{ ml: [1, 'unset'], ...labelSx }}>
              {children}
            </Text>
            {toolTipInfo && <Popover>{toolTipInfo}</Popover>}
          </Label>
        )}
        {error && <Error>{error}</Error>}
      </FormGroup>
    )
  }
)

Checkbox.defaultProps = {
  children: null,
  name: null,
  error: null,
  sx: {},
  innerSx: {},
  labelSx: {},
  rebassCheckboxProps: {}
}

export default Checkbox
