import React from 'react'

import { SxStyleProp, Flex, FlexProps } from 'rebass'

import Popover from '../../Popover'
import Label from '../Label'

interface LabelTooltipProps extends FlexProps {
  labelText?: React.ReactNode
  toolTipInfo?: string
  sx?: SxStyleProp
}

const LabelTooltip = ({ labelText, toolTipInfo, sx, ...props }: LabelTooltipProps) => (
  <Flex alignItems="center" sx={sx} {...props}>
    {labelText && <Label sx={{ width: 'auto' }}>{labelText}</Label>}
    {toolTipInfo && <Popover>{toolTipInfo}</Popover>}
  </Flex>
)

export default LabelTooltip

LabelTooltip.defaultProps = {
  labelText: null,
  toolTipInfo: null,
  sx: {}
}
