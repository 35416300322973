import React from 'react'

import { Label as RebassLabel, LabelProps as RebassLabelProps } from '@rebass/forms'
import { SxStyleProp } from 'rebass'

const labelStyles = {
  fontSize: ['tiny'],
  fontWeight: 'bold',
  color: 'textDark'
}

interface LabelProps extends RebassLabelProps {
  children: React.ReactNode
  sx?: SxStyleProp
}

const Label = ({ children, sx, ...props }: LabelProps) => (
  <RebassLabel {...props} sx={{ ...labelStyles, ...sx }}>
    {children}
  </RebassLabel>
)

export default Label

Label.defaultProps = {
  children: null,
  sx: {}
}
