import React, { FC } from 'react'
import { Container } from '../Grid'
import Heading from '../Heading'
import Text from '../Text'
import { StyledBannerCenter, StyledContentCenter, StyledHeaderCenter, StyledHeaderLine } from './Banner.styled'
import { BannerProps } from './Banner'
import theme from '../../theme'
import { Box } from 'rebass'

const BannerCenter: FC<BannerProps> = ({
  variant,
  headline,
  subtitle,
  children,
  bgColor,
  headlineColor,
  textColor,
  subtitleColor,
  sx,
  ...props
}) => {
  return (
    <StyledBannerCenter {...props} py={[2, 6]} bg={bgColor} sx={{ ...sx }}>
      <StyledContentCenter>
        <Container>
          {subtitle && (
            <Text textAlign="center" color={subtitleColor}>
              {subtitle}
            </Text>
          )}
          {headline && (
            <StyledHeaderCenter mb={[3, 6]}>
              <Heading size="h2" fontWeight={theme.fontWeights.extraBold} textAlign="center" color={headlineColor}>
                {headline}
              </Heading>
              <StyledHeaderLine mt={[1, 3]} />
            </StyledHeaderCenter>
          )}
          {children && <Box color={textColor}>{children}</Box>}
        </Container>
      </StyledContentCenter>
    </StyledBannerCenter>
  )
}

BannerCenter.defaultProps = {
  variant: 'center',
  headline: '',
  subtitle: '',
  children: null,
  sx: {}
}

export default BannerCenter
