import { RouteComponentProps } from '@reach/router'
import { Loader } from '@rmr/components'
import { useAuth, useMeLazyQuery } from '@rmr/controllers'
import React, { FC, useEffect, useMemo, useState } from 'react'
import StepWizard from 'react-step-wizard'
import { Box, SxStyleProp } from 'rebass'
import { languages } from '../../common/i18n'
import SignupSocialForm from '../SignupSocialForm'
import Success from '../Success'
import ReferralSignupStart from './ReferralSignupStart'

export enum SignupType {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  EMAIL = 'email'
}

const idRef = 'success_id'

interface ReferralSignupProps extends RouteComponentProps {
  formHeadline: string
}

const ReferralSignup: FC<ReferralSignupProps> = ({ formHeadline }) => {
  const { isAuthed, loading: loadingAuth } = useAuth()
  const [getMe, { loading: loadingUser, data: dataMe }] = useMeLazyQuery()
  const user = useMemo(() => dataMe?.me, [dataMe?.me])
  const [signupType, setSignupType] = useState(null)
  const loading = loadingAuth || loadingUser
  useEffect(() => {
    if (isAuthed && !dataMe) {
      getMe()
    }
  }, [dataMe, getMe, isAuthed])
  return (
    <>
      {loading && <Loader />}

      <Box sx={{ px: 4 }}>
        <StepWizard isLazyMount>
          <ReferralSignupStart idRef={idRef} getMe={getMe} user={user} formHeadline={formHeadline} />
          <SignupSocialForm idRef={idRef} user={user} />
          <Success
            idRef={idRef}
            headline={languages.en.signup.success}
            text={
              signupType === SignupType.EMAIL ? languages.en.signup.checkEmailMessage : languages.en.signup.socialSignup
            }
            socialSignup={signupType !== SignupType.EMAIL}
          />
        </StepWizard>
      </Box>
    </>
  )
}

export default ReferralSignup

export const sectionStyles: SxStyleProp = {
  position: 'relative',
  py: [6, 8]
}

export const stepsWrapper: SxStyleProp = {
  maxWidth: '700px',
  width: '100%',
  py: [4, 8],
  px: [4, 16],
  overflow: 'hidden'
}

export const imageStyles: SxStyleProp = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  height: '100%',
  width: '100%'
}

export const wrapperStyles: SxStyleProp = {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}
