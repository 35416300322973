import React from 'react'
import { SxStyleProp } from 'rebass'
import theme from '../../theme'

import Button, { ButtonProps } from '../Button'
import Icon from '../Icon'

interface ButtonToggleProps {
  /** State of button: on or off */
  on: boolean
  /** State of button: success */
  success: boolean
  /** Props of the sucesss Button */
  onSucessButtonProps?: ButtonProps
  /** Props of the on Button */
  onButtonProps?: ButtonProps
  /** Props of the off Button */
  offButtonProps?: ButtonProps
  /** handler to run when button is clicked */
  onClick?: () => void
  /** Button label */
  label?: string
  /** Sx Props */
  sx?: SxStyleProp
  /** Button children */
  children?: React.ReactNode
}

const ButtonToggle = ({
  children,
  label,
  on,
  success,
  onButtonProps,
  onSucessButtonProps,
  offButtonProps,
  onClick,
  sx
}: ButtonToggleProps) => {
  if (on) {
    return (
      <Button
        {...onButtonProps}
        sx={{ ...onButtonProps.sx, ...sx }}
        label={label}
        children={children}
        onClick={onClick}
      />
    )
  } else if (success) {
    return (
      <Button {...onSucessButtonProps} sx={{ ...onSucessButtonProps.sx, ...sx }} label={label} children={children} />
    )
  }
  return (
    <Button
      {...offButtonProps}
      sx={{ ...offButtonProps.sx, ...sx }}
      label={label}
      children={children}
      onClick={onClick}
    />
  )
}

export default ButtonToggle

const baseButtonProps = {
  type: 'button',
  sx: {
    px: [2],
    py: [0],
    height: ['60px'],
    minWidth: ['120px']
  }
}

ButtonToggle.defaultProps = {
  label: null,
  on: false,
  success: false,
  onButtonProps: {
    ...baseButtonProps,
    variant: 'blackRound',
    icon: (
      <>
        <Icon name="blackCheckOutline" />
      </>
    )
  },
  onSucessButtonProps: {
    ...baseButtonProps,
    variant: 'greenRound',
    icon: (
      <>
        <Icon name="check" color={theme.colors.baseWhite} fontAwesomeIconProps={{ size: '2x' }} />
      </>
    ),
    sx: { ...baseButtonProps.sx, cursor: 'default' }
  },
  offButtonProps: {
    ...baseButtonProps,
    variant: 'mutedRound',
    icon: (
      <>
        <Icon name="broadRoundBorder" />
      </>
    )
  },
  onClick: null,
  sx: {},
  children: null
}
