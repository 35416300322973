import React, { useState, useCallback } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'

import { Box, Flex } from 'rebass'
import { UploadComponent, UploadComponentProps, Icon, Text } from '@rmr/components'
import { FileType, maxSize, User, userDocTypeTitles, fileUploader } from '@rmr/controllers'

const uploadCommonProps: Partial<UploadComponentProps> = {
  variant: 'vertical',
  maxFiles: 1,
  maxSize,
  fileType: FileType.Document
}

const documentStyle = {
  position: 'relative',
  height: '200px',
  width: '200px',
  border: '1px solid',
  borderColor: 'borderBase',
  borderRadius: '4px',
  padding: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

type HandleUploadCallback = (files: FileExtended[]) => void

interface DocumentsProps extends Partial<StepWizardChildProps> {
  idRef: string
  user: User
  title: string
  onFileSet: (data: any) => void
  onFileRemove: (fileId: string) => void
  file: any
  fileType: FileType
}

const Documents = ({ title, onFileRemove, onFileSet, file, fileType }: DocumentsProps) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const handleUploadCallback: HandleUploadCallback = useCallback(
    async (files) => {
      if (!files.length) {
        return
      }
      setError('')
      try {
        setLoading(true)
        if (files.length === 1) {
          const file = await fileUploader(files[0], FileType.DOCUMENT)
          file && onFileSet(file)
        } else {
          setError('Only one file can be uploaded at a time.')
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    },
    [onFileSet]
  )

  if (file || loading) {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Box sx={documentStyle}>
          {loading ? (
            <Text>Loading...</Text>
          ) : (
            <>
              <Icon name="paperClip" fontAwesomeIconProps={{ size: '2x' }} />
              <Text>{userDocTypeTitles[file.type]}</Text>
              <Icon
                name="redClose"
                sx={{ position: 'absolute', top: '-10px', right: '-10px', cursor: 'pointer' }}
                onClick={() => onFileRemove(file.fileId)}
              />
            </>
          )}
        </Box>
      </Flex>
    )
  }

  return (
    <UploadComponent
      {...uploadCommonProps}
      fileType={fileType}
      label={title}
      callback={(files: FileExtended[]) => handleUploadCallback(files)}
      error={error}
    />
  )
}

export default Documents
