import React, { FC, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { parse } from 'query-string'
import { navigate, useLocation } from '@reach/router'
import { Box, Flex } from 'rebass'
import { LoginMutation, useLoginForm, useAuth, SubmitState } from '@rmr/controllers'
import { Heading, Button, Input, FormMessage, PasswordInput, MessageColor, Loader, FormFooter } from '@rmr/components'
import { buttonStyles } from './LoginForm.styled'
import { languages } from '../../common/i18n'
import { formStyles, fieldStyles } from '../SignupEmailForm/SignupEmailForm.styled'
import FacebookLogin from '../FacebookLogin'
import GoogleLogin, { ProviderType } from '../GoogleLogin/GoogleLogin'
import { processTrimLowerCase } from '@rmr/helpers'

export type FormData = {
  email: string
  password: string
  rememberMe: boolean
}

interface LoginFormProps {
  callback?: () => void
}

const LoginForm: FC<LoginFormProps> = ({ callback }) => {
  const [socialSubmitResult, setSocialSubmitResult] = useState<SubmitState>({
    submitted: false,
    success: false,
    error: '',
    message: ''
  })
  const onSubmitSocial = (submitResult: SubmitState) => {
    setSocialSubmitResult(submitResult)
    if (submitResult.success) {
      if (callback) {
        return callback()
      }
      redirect && navigate(redirect as string)
    }
  }
  const location = useLocation()
  const { redirect = '/' } = parse(location.search)

  const { login: setToken } = useAuth()

  const onSuccess = async ({ login }: LoginMutation) => {
    if (login.__typename === 'AuthTokens') {
      setToken(login.token, login.expiry)
      if (callback) {
        return callback()
      }
      redirect && navigate(redirect as string)
    }
  }

  const onError = (err) => console.log(err)

  const { watch, setValue, clearErrors, register, onSubmit, errors, submitting, submitResult, onSubmitSuccess } =
    useLoginForm({
      onSuccess,
      onError
    })

  const email = watch('email')

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    onSubmit(e)
  }
  useEffect(() => {
    register({ name: 'email' })
  }, [register])
  return (
    <React.Fragment>
      {submitting && <Loader />}
      <Box>
        <Heading size="h4" fontWeight="extraBold" mb={4} textAlign="center">
          {languages.en.auth.signin.headline}
        </Heading>
        <>
          <Box as="form" onSubmit={handleSubmit} sx={{ ...formStyles, gridTemplateColumns: 'repeat(1, 1fr)', mb: 4 }}>
            <Input
              type="text"
              name="email"
              label="Email*"
              sx={{ ...fieldStyles }}
              rebassInputProps={{
                autoComplete: 'email',
                value: email,
                onChange: (e) => {
                  errors && errors.email && errors.email.message && clearErrors('email')
                  submitResult && submitResult.message && onSubmitSuccess()
                  setValue('email', processTrimLowerCase(e.target.value))
                }
              }}
              error={errors.email && errors.email.message}
            />

            <Box>
              <PasswordInput
                name="password"
                label="Password*"
                sx={{ ...fieldStyles, mb: 0 }}
                rebassInputProps={{ autoComplete: 'current-password', value: undefined }}
                ref={register}
                error={errors.password && errors.password.message}
              />
              <Link to="/app/forgot/">
                <Button variant="transparent" label={languages.en.auth.signin.forgotPasswordLabel} ml="auto" />
              </Link>
            </Box>

            <FormMessage
              showMessage={submitResult.submitted || socialSubmitResult.submitted}
              message={submitResult.message || socialSubmitResult.message}
              color={
                `${submitResult.error || socialSubmitResult.error ? 'statusError' : 'statusSuccess'}` as MessageColor
              }
            />
            <Button type="submit" label={languages.en.auth.signin.buttonText} sx={buttonStyles} mx="auto" />
          </Box>
          <Box mb={2}>
            <FacebookLogin onSubmit={onSubmitSocial} callback={callback} />
          </Box>
          <GoogleLogin onSubmit={onSubmitSocial} callback={callback} />
          <Link to="/app/signup">
            <FormFooter text={languages.en.auth.noAccount} buttonText={languages.en.auth.buttonSignUp} />
          </Link>
        </>
      </Box>
    </React.Fragment>
  )
}
export default LoginForm
