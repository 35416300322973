import { SxStyleProp } from 'rebass'
import theme from '../../theme/theme'
export const lineStyles = (open: boolean): SxStyleProp => ({
  width: '32px',
  height: '3px',
  borderRadius: `${theme.borderRadius.medium}`,
  bg: 'baseWhite',
  position: 'relative',
  transformOrigin: '1px',
  transition: 'all 0.3s linear',
  '&:nth-of-type(1)': {
    transform: open ? 'rotate(45deg)' : 'rotate(0deg)'
  },
  '&:nth-of-type(2)': {
    transform: open ? 'translateX(-20px)' : 'translateX(0px)',
    opacity: open ? 0 : 1
  },
  '&:nth-of-type(3)': {
    transform: open ? 'rotate(-45deg)' : 'rotate(0deg)'
  }
})

export const buttonStyles: SxStyleProp = {
  cursor: 'pointer',
  position: 'relative',
  border: 'none',
  outline: 'none',
  p: 0,
  m: 0,
  height: '32px',
  width: '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  mt: '5px'
}
