import { Button } from '@rmr/components'
import { navigate } from 'gatsby'
import React from 'react'
import { Flex } from 'rebass'
import { languages } from '../common/i18n'

export const OptionsButtons = () => {
  return (
    <Flex mt={[3]} justifyContent={['center']} alignItems={['center']} flexDirection={['column', null, 'row']}>
      <Flex mr={[0, null, 2]} mb={[2, null, 0]}>
        <Button
          variant="primary"
          onClick={() => {
            navigate('/app/list-your-car/')
          }}
        >
          {languages.en.verifyEmail.listcarButtonText}
        </Button>
      </Flex>
      <Flex mr={[0, null, 2]} mb={[2, null, 0]}>
        <Button
          variant="primary"
          onClick={() => {
            navigate('/rent-a-car/')
          }}
        >
          {languages.en.verifyEmail.rentcarButtonText}
        </Button>
      </Flex>
      <Flex>
        <Button
          variant="primary"
          onClick={() => {
            navigate('/app/approval-to-list')
          }}
        >
          {languages.en.verifyEmail.approvalButtonText}
        </Button>
      </Flex>
    </Flex>
  )
}
