import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  UpdateVehicleInput,
  UpdateVehicleMutation,
  useUpdateVehicleMutation,
  RentalConditionsData,
  PlaceType,
  VehicleResultUnion
} from '@rmr/controllers'
import { useSubmitResult } from './useSubmitResult'

const rentalConditionsFormSchema = yup.object().shape({
  max_kilometers_per_day: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Required field'),
  minimum_days: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Required field'),
  is_drive_on_unpaved_roads: yup.bool(),
  is_allow_pets: yup.bool(),
  is_collect: yup.bool(),
  is_delivery: yup.bool(),
  is_deliver_renter: yup.bool().when('is_delivery', {
    is: true,
    then: yup.bool().when('is_deliver_airport', {
      is: false,
      then: yup.bool().oneOf([true], 'Please choose an option'),
      otherwise: yup.bool()
    }),
    otherwise: yup.bool()
  }),
  is_deliver_airport: yup.bool().when('is_delivery', {
    is: true,
    then: yup.bool().when('is_deliver_renter', {
      is: false,
      then: yup.bool().oneOf([true], 'Please choose an option'),
      otherwise: yup.bool()
    }),
    otherwise: yup.bool()
  }),
  vehicleLocation: yup.string().nullable().required('Required field'),
  address: yup.string().nullable().required('Please select an option from the list'),
  is_show_location: yup.bool(),
  delivery_fee_airport: yup.number().when('is_deliver_airport', {
    is: true,
    then: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
    otherwise: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
  }),
  delivery_fee_renter: yup.number().when('is_deliver_renter', {
    is: true,
    then: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
    otherwise: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
  }),
  delivery_radius: yup.number().when('is_delivery', {
    is: true,
    then: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
    otherwise: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
  })
})

interface UseRentalConditionsArgs {
  onSuccess: (response: UpdateVehicleMutation) => void
  defaultValues: RentalConditionsData
}

export function useRentalConditionsForm({ onSuccess, defaultValues }: UseRentalConditionsArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(rentalConditionsFormSchema),
    defaultValues
  })

  const [updateVehicleMutation, { loading: loadingUpdate }] = useUpdateVehicleMutation()

  const checkError = (vehicleResult: VehicleResultUnion) => {
    if (vehicleResult.__typename === 'Vehicle') {
      onSubmitSuccess()
    } else {
      onSubmitError('error', vehicleResult.message)
    }
  }

  const onSubmit = useCallback(
    async (data: RentalConditionsData) => {
      const {
        id,
        max_kilometers_per_day,
        minimum_days,
        is_drive_on_unpaved_roads,
        is_allow_pets,
        is_collect,
        is_delivery,
        is_deliver_renter,
        is_deliver_airport,
        address,
        is_show_location,
        delivery_fee_airport,
        delivery_fee_renter,
        delivery_radius
      } = data

      const addressParsed = JSON.parse(address)
      const { deliveryFeeAmount, placeId, googlePlaceId, createdAt, updatedAt, name, ...restAddressParsed } =
        addressParsed

      const vehicleInputData: UpdateVehicleInput = {
        id,
        max_kilometers_per_day,
        minimum_days,
        is_drive_on_unpaved_roads,
        is_allow_pets,
        is_collect,
        is_show_location,
        is_delivery,
        is_deliver_airport,
        is_deliver_renter,
        address: {
          type: PlaceType.Pickup,
          placeName: name || addressParsed.city,
          googlePlaceId: placeId || googlePlaceId,
          ...restAddressParsed
        },
        delivery_fee_airport,
        delivery_fee_renter,
        delivery_radius
      }
      try {
        const response = await updateVehicleMutation({ variables: { input: vehicleInputData } })
        if (response.data) {
          onSuccess(response.data)
          const { updateVehicle } = response.data
          checkError(updateVehicle as VehicleResultUnion)
        } else {
          onSuccess(undefined)
          onSubmitError()
        }
      } catch (err) {
        console.log('onSubmit Update Error: ', { err })
        if (err.message) {
          onSubmitError('error', err.message)
        } else {
          onSubmitError()
        }
      }
    },
    [updateVehicleMutation, onSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loadingUpdate,
    submitResult,
    onSubmitError,
    onSubmitSuccess
  }
}
