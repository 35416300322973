import React from 'react'

import Svg, { SvgProps } from '../Svg'

const Logo = (props: SvgProps) => (
  <Svg {...props}>
    <svg viewBox="0 0 315.53 78.09">
      <title id="title6">RMR Horizontal logo - White</title>
      <path
        id="path8"
        transform="translate(-15.63 -15.69)"
        d="M67.52,42.67a22.54,22.54,0,0,0-14.63,5.38A22.62,22.62,0,0,0,15.63,65.3V93.79h16V65.3a6.58,6.58,0,0,1,13.16,0V93.79h16.1V65.3a6.58,6.58,0,0,1,13.16,0V93.79H90.15V65.3A22.65,22.65,0,0,0,67.52,42.67Z"
        fill="currentcolor"
      />
      <ellipse id="ellipse10" ry="11.73" rx="12.1" cy="11.73" cx="22.66" fill="currentcolor" />
      <ellipse id="ellipse12" ry="11.73" rx="12.1" cy="11.75" cx="51.93" fill="currentcolor" />
      <path
        id="path14"
        transform="translate(-15.63 -15.69)"
        d="M139.41,59.66a9.15,9.15,0,0,1-.95,4.16,6.67,6.67,0,0,1-2.74,2.87l6.12,11.4h-7.18l-4.75-9.84h-2v9.84h-6.5V51.49h8.67Q139.4,51.49,139.41,59.66Zm-9.84-2.59h-1.64v5.74h1.94A2.85,2.85,0,0,0,132,62a2.9,2.9,0,0,0,.78-2.15C132.76,58,131.69,57.07,129.57,57.07Z"
        fill="currentcolor"
      />
      <path
        id="path16"
        transform="translate(-15.63 -15.69)"
        d="M162.47,69.77H149.4a3.27,3.27,0,0,0,1.31,2.33,4.46,4.46,0,0,0,2.91,1,4.72,4.72,0,0,0,2.64-.7,5,5,0,0,0,1.54-1.43L162,74.17a8.29,8.29,0,0,1-2.89,2.91,9.71,9.71,0,0,1-5.58,1.5A10,10,0,0,1,142.9,68.17a10.81,10.81,0,0,1,2.74-7.51,9.11,9.11,0,0,1,7.07-3A9.21,9.21,0,0,1,160,60.51q2.53,2.87,2.54,8C162.51,69,162.5,69.46,162.47,69.77Zm-7.35-6.14a3.12,3.12,0,0,0-2.3-.86,3,3,0,0,0-2.28.9,3.42,3.42,0,0,0-.95,2.07h6.46A3.14,3.14,0,0,0,155.12,63.63Z"
        fill="currentcolor"
      />
      <path
        id="path18"
        transform="translate(-15.63 -15.69)"
        d="M176.76,78.09V66.19a2.92,2.92,0,0,0-.72-2.11,2.54,2.54,0,0,0-1.9-.74,2.58,2.58,0,0,0-2,.8,2.91,2.91,0,0,0-.73,2v11.9h-6.34v-20h6.19v2a5.44,5.44,0,0,1,2-1.79,6.07,6.07,0,0,1,3-.76,7.34,7.34,0,0,1,3.09.61,5.48,5.48,0,0,1,2,1.5,6.61,6.61,0,0,1,1.1,2.24,13,13,0,0,1,.51,2.47,24.52,24.52,0,0,1,.12,2.57V78.09Z"
        fill="currentcolor"
      />
      <path
        id="path20"
        transform="translate(-15.63 -15.69)"
        d="M193.48,53.92v4.22h3.38v5.13h-3.38V70.6c0,1.44.83,2.17,2.47,2.17.43,0,.79,0,1.07,0v5.43a10,10,0,0,1-2.44.23,7.48,7.48,0,0,1-5.56-1.9c-1.26-1.27-1.88-3.33-1.88-6.19v-7H185.2V58.14h1.94V53.92Z"
        fill="currentcolor"
      />
      <path
        id="path22"
        transform="translate(-15.63 -15.69)"
        d="M218.49,75.92h-6l-5.85-15.28V78.09h-6.23V51.49h8.43l6.61,16.83,6.65-16.83h8.44v26.6h-6.23V60.64Z"
        fill="currentcolor"
      />
      <path
        id="path24"
        transform="translate(-15.63 -15.69)"
        d="M253.71,58.14l-9.88,26.75h-6l2.69-7.45-7.44-19.3h6.8l3.53,11.47,3.69-11.47Z"
        fill="currentcolor"
      />
      <path
        id="path26"
        transform="translate(-15.63 -15.69)"
        d="M274.31,59.66a9.15,9.15,0,0,1-.95,4.16,6.67,6.67,0,0,1-2.74,2.87l6.12,11.4h-7.18l-4.75-9.84h-2v9.84h-6.5V51.49H265Q274.3,51.49,274.31,59.66Zm-9.84-2.59h-1.64v5.74h1.94a2.85,2.85,0,0,0,2.11-.78,2.9,2.9,0,0,0,.78-2.15C267.66,58,266.59,57.07,264.47,57.07Z"
        fill="currentcolor"
      />
      <path
        id="path28"
        transform="translate(-15.63 -15.69)"
        d="M284.45,56a3.53,3.53,0,0,1-2.54,1,3.48,3.48,0,0,1-2.53-1,3.25,3.25,0,0,1-1-2.45,3.32,3.32,0,0,1,1-2.49,3.77,3.77,0,0,1,5.07,0,3.27,3.27,0,0,1,1,2.47A3.19,3.19,0,0,1,284.45,56Zm.69,2.19v20h-6.35v-20Z"
        fill="currentcolor"
      />
      <path
        id="path30"
        transform="translate(-15.63 -15.69)"
        d="M303,78.09V76.53a6.73,6.73,0,0,1-2,1.37,7.86,7.86,0,0,1-3.37.68,9.18,9.18,0,0,1-7-2.93,10.57,10.57,0,0,1-2.73-7.52,11,11,0,0,1,2.56-7.58,8.69,8.69,0,0,1,6.86-2.91,7.6,7.6,0,0,1,3.38.69,5.84,5.84,0,0,1,1.94,1.33v-9h6.35V78.09Zm-.23-9.92a5.2,5.2,0,0,0-1.18-3.63,4,4,0,0,0-3.11-1.31,3.88,3.88,0,0,0-3.12,1.37,5.25,5.25,0,0,0-1.18,3.57,5.09,5.09,0,0,0,1.18,3.53A3.94,3.94,0,0,0,298.44,73a3.88,3.88,0,0,0,3.13-1.38A5.28,5.28,0,0,0,302.73,68.17Z"
        fill="currentcolor"
      />
      <path
        id="path32"
        transform="translate(-15.63 -15.69)"
        d="M331.12,69.77H318a3.31,3.31,0,0,0,1.31,2.33,4.46,4.46,0,0,0,2.91,1,4.72,4.72,0,0,0,2.64-.7,4.91,4.91,0,0,0,1.54-1.43l4.18,3.23a8.2,8.2,0,0,1-2.89,2.91,9.69,9.69,0,0,1-5.58,1.5,10,10,0,0,1-10.6-10.41,10.8,10.8,0,0,1,2.73-7.51,9.12,9.12,0,0,1,7.07-3,9.21,9.21,0,0,1,7.26,2.87c1.7,1.91,2.54,4.58,2.54,8Q331.15,69.31,331.12,69.77Zm-7.36-6.14a3.1,3.1,0,0,0-2.3-.86,3,3,0,0,0-2.28.9,3.48,3.48,0,0,0-.95,2.07h6.46A3.14,3.14,0,0,0,323.76,63.63Z"
        fill="currentcolor"
      />
    </svg>
  </Svg>
)
Logo.defaultProps = {
  width: '204px',
  color: 'baseWhite',
  sx: {}
}

export default Logo
