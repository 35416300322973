export const wrapperStyles = {
  position: 'relative',
  pr: [7]
}
export const iconStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: [0],
  cursor: 'pointer',
  color: 'primary',
  fontSize: [20]
}
