import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ResetPasswordMutation, useResetPasswordMutation } from '../lib/generated/generated'
import { useSubmitResult } from './useSubmitResult'

interface UseResetPasswordArgs {
  resetToken: string
  onSuccess: (response: ResetPasswordMutation) => void
  onError: (e: Error) => void
}

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Required field')
    .test('len', 'Password must have at least 6 characters', (val) => val && val.length >= 6),
  confirm_password: yup
    .string()
    .test('password', 'Password must have at least 6 characters', (val) => val && val.length >= 6)
    .oneOf([yup.ref('password'), null], 'Passwords must match')
})

export function useResetPassword({ resetToken, onSuccess, onError }: UseResetPasswordArgs) {
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      password: '',
      confirm_password: ''
    }
  })

  const [resetPasswordMutation, { loading }] = useResetPasswordMutation()

  const onSubmit = useCallback(
    async (data: { password: string }) => {
      try {
        const response = await resetPasswordMutation({
          variables: { input: { newPassword: data.password, token: resetToken } }
        })
        onSuccess(response.data)
        const { resetPassword } = response.data
        if (resetPassword) {
          onSubmitSuccess()
        } else {
          onSubmitError(null, '')
        }
      } catch (err) {
        onError(err)
        onSubmitError()
      }
    },
    [resetPasswordMutation, onSuccess, onError, resetToken, onSubmitError, onSubmitSuccess]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading,
    submitResult
  }
}
