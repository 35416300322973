import React, { FC } from 'react'
import { Container } from '../Grid'
import Heading from '../Heading'
import { StyledContentStrip } from './SectionFeatures.styled'
import theme from '../../theme'
import { SectionFeaturesTypes } from './SectionFeatures'
import { Box } from 'rebass'

const SectionFeaturesStrip: FC<SectionFeaturesTypes> = ({
  headline,
  headlineColor,
  bgColor,
  children,
  content,
  sx,
  ...props
}) => {
  return (
    <Box {...props} bg={bgColor} py={[4, 6]} sx={{ ...sx }}>
      <Container>
        <StyledContentStrip>
          {headline && (
            <Heading
              size="h6"
              fontWeight={theme.fontWeights.regular}
              textAlign="center"
              color={headlineColor}
              mb={[2, 4]}
            >
              {headline}
            </Heading>
          )}
          {children && children}
        </StyledContentStrip>
      </Container>
    </Box>
  )
}

SectionFeaturesStrip.defaultProps = {
  headline: '',
  headlineColor: 'textDark',
  bgColor: 'white',
  variant: 'featuresStrip',
  sx: {}
}

export default SectionFeaturesStrip
