import React, { ReactNode } from 'react'
import { BoxProps, SxStyleProp } from 'rebass'

import { StyledContent } from './Content.styled'

export interface ContentProps extends BoxProps {
  children: ReactNode
  sx: SxStyleProp
}

const Content = ({ children, sx, ...props }: ContentProps) => {
  return (
    <StyledContent sx={sx} {...props}>
      {children}
    </StyledContent>
  )
}

export default Content

Content.defaultProps = {
  children: null,
  sx: {}
}
