import React from 'react'
import { Router } from '@reach/router'

import Main from '../components/Main'
import RentCar from '../components/RentCar'

const App = () => (
  <Router>
    <Main darkHeader noFooter path="/rent-a-car">
      <RentCar path="/" />
    </Main>
  </Router>
)

export default App
