import React from 'react'
import { LazyLoadTypes } from 'react-slick'
import Icon from '../Icon'

const PrevArrow = (props) => <Icon {...props} sx={{ ...arrowStyles }} name="arrowLeft" />
const NextArrow = (props) => (
  <Icon {...props} sx={{ ...arrowStyles, left: 'unset', right: '-40px' }} name="arrowRight" />
)

// export interface Variants {
//   [key: string]: Settings
// }

export type Variant = keyof typeof variants

export const variants = {
  vehiclesCarousel: {
    className: 'vehicle-cards-carousel',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: false,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipe: false,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1110,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  },
  cardsCarousel: {
    className: 'cards-carousel',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: false,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    swipe: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1110,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  },
  heroCarousel: {
    className: 'hero-image-gallery',
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
  },
  testimonialsCarousel: {
    className: 'testimonials-carousel',
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
  },
  vehicleListingGallery: {
    className: 'vehicle-listing-carousel',
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    lazyLoad: 'ondemand' as LazyLoadTypes
  },
  instructionsCarousel: {
    className: 'instructions-carousel',
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    lazyLoad: 'ondemand' as LazyLoadTypes
  },
  vehicleCardCarousel: {
    className: 'vehicle-card-carousel',
    arrows: false,
    autoplay: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand' as LazyLoadTypes
  }
}

const arrowStyles = {
  fontSize: [40],
  color: 'textBlack',
  left: '-40px',
  opacity: 0.5,
  transition: 'opacity 0.2s',
  '&:hover': {
    color: 'textBlack',
    opacity: 1
  }
}
