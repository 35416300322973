import { SxStyleProp } from 'rebass'

import theme from '../../theme'

export const wrapperStyles: SxStyleProp = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative'
}

export const iconStyles: SxStyleProp = {
  px: 1
}

export type TooltipOffset = { translate: string | number; left: string | number; right: string | number }

export const contentStyles = (active: boolean): SxStyleProp => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 'max-content',
  py: [1],
  px: [1],
  bg: active ? 'bgLight' : 'transparent',
  cursor: 'pointer',
  color: active ? `${theme.colors.textDark} !important` : `${theme.colors.textDark} !important`,
  ':hover': {
    bg: 'bgLight',
    color: `${theme.colors.textDark} !important`
  }
})
