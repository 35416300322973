import React, { FC, useState, useMemo, useEffect } from 'react'
import StepWizard from 'react-step-wizard'
import { RouteComponentProps } from '@reach/router'
import { Box, Flex, SxStyleProp } from 'rebass'

import { stepsWrapperStyles, Container, Loader } from '@rmr/components'

import { languages } from '../common/i18n'
import SignupDashboard from './SignupDashboard'
import SignupEmailForm from './SignupEmailForm'
import Success from './Success'
import SEO from './Seo'
import SignupSocialForm from './SignupSocialForm'
import { useMeLazyQuery, useAuth } from '@rmr/controllers'

export enum SignupType {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  EMAIL = 'email'
}

const idRef = 'success_id'

const Signup: FC<RouteComponentProps> = () => {
  const { isAuthed, loading: loadingAuth } = useAuth()
  const [getMe, { loading: loadingUser, data: dataMe }] = useMeLazyQuery()
  const user = useMemo(() => dataMe?.me, [dataMe?.me])
  const [signupType, setSignupType] = useState(null)
  const loading = loadingAuth || loadingUser
  useEffect(() => {
    if (isAuthed && !dataMe) {
      getMe()
    }
  }, [dataMe, getMe, isAuthed])

  return (
    <>
      {loading && <Loader />}
      <Box sx={{ position: 'relative', overflow: 'auto' }}>
        <SEO
          title="Register | RentMyRide"
          description="We founded RentMyRide to allow mobility for everyone and change the way that people use cars. "
          path="/app/approval-to-list"
        />
        <Box as="section" sx={sectionStyles}>
          <Container>
            <Flex sx={wrapperStyles}>
              <Box sx={{ ...stepsWrapperStyles, ...stepsWrapper }}>
                <StepWizard isLazyMount>
                  <SignupDashboard
                    getMe={getMe}
                    user={user}
                    onSetSignupType={(type: SignupType) => setSignupType(type)}
                  />
                  {signupType === SignupType.EMAIL ? (
                    <SignupEmailForm idRef={idRef} />
                  ) : (
                    <SignupSocialForm idRef={idRef} user={user} />
                  )}
                  <Success
                    idRef={idRef}
                    headline={languages.en.signup.success}
                    text={
                      signupType === SignupType.EMAIL
                        ? languages.en.signup.checkEmailMessage
                        : languages.en.signup.socialSignup
                    }
                    socialSignup={signupType !== SignupType.EMAIL}
                  />
                </StepWizard>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Box>
    </>
  )
}

export default Signup

export const sectionStyles: SxStyleProp = {
  position: 'relative',
  py: [6, 8]
}

export const stepsWrapper: SxStyleProp = {
  maxWidth: '700px',
  width: '100%',
  py: [4, 8],
  px: [4, 16],
  overflow: 'hidden'
}

export const imageStyles: SxStyleProp = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  height: '100%',
  width: '100%'
}

export const wrapperStyles: SxStyleProp = {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}
