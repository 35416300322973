import { SxStyleProp } from 'rebass'
import theme from '../../theme'

export const wrapperStyles = (on: boolean, error: boolean): SxStyleProp => ({
  flex: '1 0 auto',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  border: `3px solid ${error ? theme.colors.statusError : on ? theme.colors.statusSuccess : theme.colors.borderLight}`,
  borderRadius: `${theme.borderRadius.small}`,
  transition: 'border 0.2s'
})

export const iconsStyles = (on: boolean): SxStyleProp => ({
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(50%,-50%)',
  opacity: on ? 1 : 0,
  transition: 'opacity 0.2s'
})
