import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Box, SxStyleProp, Flex } from 'rebass'
import dayjs from 'dayjs'
import theme from '../../theme'
import Text from '../Text/Text'
import Button from '../Button'
import StarRating from '../StarRating/StarRating'
import { ReviewConnection, useGetVehicleReviewsQuery } from '@rmr/controllers'
import { Loader } from '@rmr/components'

const numberOfReviews = 10

export interface VehicleReviewsProps {
  /** Set the  headline */
  vehicleId: string
  /** Set the  headline */
  headline?: string
  /** Number of reviews */
  reviewsCount?: number
  /** Average of all ratings */
  averageRating?: number
  /** Sx Props */
  sx?: SxStyleProp
  /** Array of Review items */
  items?: [ReviewItemsProps]
  /** Show More Button Text */
  showMoreButtonText: string
}
export interface ReviewItemsProps {
  name?: string
  date?: string
  rating?: string
  review?: string
  profileImageURL?: string
}

const StyledImage = styled(Box)`
  border-radius: ${theme.borderRadius.circle};
  overflow: hidden;
  border-color: ${theme.colors.baseWhite};
  border-style: solid;
  border-width: 8px;
  img {
    max-width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
`

const ReviewList = ({ reviews }: { reviews: ReviewConnection }) => {
  if (reviews.edges.length === 0) return null
  return (
    <Flex flexDirection={['column']}>
      {reviews.edges.map(({ node }) => {
        const { id, rating, profile, comment, createdAt } = node
        return (
          <Box key={id} mb={[4, 6]}>
            <Flex flexDirection={['column', 'row']}>
              <Box mr={4}>
                <StyledImage
                  width={'80px'}
                  height={'80px'}
                  sx={{ borderRadius: `${theme.borderRadius.circle}`, overflow: 'hidden', background: '#eee' }}
                  mb={[1, 0]}
                >
                  {/* TODO: Add render prop and pass in Image component */}
                  {profile.avatar ? <img src={profile.avatar} alt={''} /> : null}
                </StyledImage>{' '}
              </Box>
              <Box flex={1}>
                <Flex alignItems="center" mb={[1]}>
                  <Text
                    size={'medium'}
                    color={theme.colors.textGrayLight}
                    fontWeight={theme.fontWeights.semiBold}
                    lineHeight={1}
                  >
                    {rating.toFixed(2)}
                  </Text>
                  <StarRating
                    ml={[1]}
                    activeColor={theme.colors.statusRating}
                    color={theme.colors.bgGrayLight}
                    size={16}
                    value={Math.floor(rating)}
                    edit={false}
                  />
                </Flex>
                <Text
                  size={'small'}
                  color={theme.colors.textBlack}
                  fontWeight={theme.fontWeights.semiBold}
                  lineHeight={1}
                >
                  {profile.name}, {dayjs(createdAt).format('D MMMM YYYY')}
                </Text>
                {comment && (
                  <Text
                    mt={[1, 2]}
                    size={'small'}
                    color={theme.colors.textGrayLight}
                    fontWeight={theme.fontWeights.semiBold}
                  >
                    {comment}
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
        )
      })}
    </Flex>
  )
}

const VehicleReviews: FC<VehicleReviewsProps> = ({
  headline,
  items,
  reviewsCount,
  averageRating,
  showMoreButtonText,
  sx,
  ...props
}) => {
  const { data, fetchMore, networkStatus } = useGetVehicleReviewsQuery({
    variables: {
      vehicleId: props.vehicleId,
      first: numberOfReviews
    },
    notifyOnNetworkStatusChange: true
  })

  if (!data || networkStatus === 1) return null

  const isRefetching = networkStatus === 3
  const { getVehicleReviews: reviews } = data
  const { hasNextPage, endCursor } = reviews.pageInfo

  return (
    <Box {...props} sx={{ ...sx }}>
      <Flex sx={{}} flexDirection={['column', 'column', 'row']}>
        <Box width={[1, 1, 4 / 12]}>
          <Text mb={[1, 2]} size={'medium'} color={theme.colors.textDark} fontWeight={theme.fontWeights.bold}>
            {headline}
          </Text>
        </Box>
        <Box width={[1, 1, 8 / 12]}>
          <Flex alignItems="center" mb={[2, 4]}>
            <Text size={'medium'} color={theme.colors.textGrayLight} fontWeight={theme.fontWeights.semiBold} mr={[2]}>
              {reviewsCount} {`Review${reviewsCount === 1 ? '' : 's'}`}
            </Text>
            <StarRating
              ml={[1]}
              activeColor={theme.colors.statusRating}
              color={theme.colors.bgGrayLight}
              size={16}
              value={averageRating}
              edit={false}
            />
          </Flex>
          {/* @ts-ignore */}
          <ReviewList reviews={reviews} />
          {/* {isRefetching && <Loader position="absolute" />} */}
          {hasNextPage && (
            <Box sx={{ position: 'relative', height: 80 }}>
              {isRefetching ? (
                <Loader position="absolute" size="small" justifyContent="flex-start" />
              ) : (
                <Button
                  variant="transparent"
                  onClick={() =>
                    fetchMore({
                      variables: {
                        id: props.vehicleId,
                        first: numberOfReviews,
                        after: endCursor
                      }
                    })
                  }
                >
                  {showMoreButtonText}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

VehicleReviews.defaultProps = {
  headline: '',
  items: null
}

export default VehicleReviews
