import React from 'react'
import { Box } from 'rebass'

const Container = (props) => <Box {...props} />

Container.defaultProps = {
  mx: 'auto',
  px: '15px',
  maxWidth: '1280px'
}

export default Container
