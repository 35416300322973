import { SxStyleProp } from 'rebass'

export const stepsWrapperStyles: SxStyleProp = {
  width: '100%',
  mx: 'auto',
  p: [0, 1, 6],
  pt: [6],
  pb: [2, 2, 2],
  px: [0, 1, 6]
}

export const headlineStyles: SxStyleProp = {
  pt: [2, null, 0],
  px: [2, null, 0]
}

export const wrapperStyles: SxStyleProp = {
  flexDirection: 'column',
  alignItems: 'center',
  px: [2, 0],
  pt: [4],
  pb: [3]
}

export const stepsWizzardStyles: SxStyleProp = {
  alignSelf: 'stretch',
  mb: [10, 8, 8],
  px: ['20px', 3]
}
