import { theme } from '@rmr/components'
import { SxStyleProp } from 'rebass'
import { SearchBarVariant } from './SearchBar'

export const wrapperStyles: SxStyleProp = {
  flexDirection: ['column', null, 'row'],
  zIndex: theme.zIndex.header - 1,
  maxWidth: ['300px', '400px', 'none'],
  margin: ['0 auto', null, '0']
}

export const locationStyles = (variant: SearchBarVariant): SxStyleProp => ({
  flex: '1 0 auto',
  mb: [2, 2, 0],
  mr: [0, 0, 2],
  '> div': {
    height: [null, null, '100%'],
    borderWidth: variant === SearchBarVariant.DEFAULT ? 2 : 0,
    '> div': {
      height: [null, null, '100%'],
      flex: ['1 0 auto', null, 'none']
    }
  }
})

export const selectStyles = (variant: SearchBarVariant): SxStyleProp => ({
  fontSize: 'medium',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  borderTopLeftRadius: variant === SearchBarVariant.SEARCH_PAGE ? '0px' : '4px',
  borderBottomLeftRadius: variant === SearchBarVariant.SEARCH_PAGE ? '0px' : '4px'
})
export const inputStyles = (variant: SearchBarVariant): SxStyleProp => ({
  ...selectStyles(variant),
  p: 2,
  alignSelf: 'stretch'
})

export const buttonStyles: SxStyleProp = {
  ml: [0, 0, 2],
  minWidth: [150, null, 'unset'],
  height: ['51px', null, 'auto']
  // width: [100, null, 'unset'],
  // alignSelf: ['end', null, 'stretch']
}

export const labelStyles = (variant: SearchBarVariant): SxStyleProp =>
  variant === SearchBarVariant.DEFAULT
    ? {}
    : {
        bg: 'primary',
        label: {
          color: 'textWhite'
        }
      }
export const datesStyles: SxStyleProp = {
  flex: ['1 0 auto', null, 'none'],
  height: ['51px', null, 'auto'],
  '.DateRangePickerInput': {
    width: ['100%', null, 'auto'],
    '.DateInput': { width: ['100%', null, '130px'] }
  },
  marginBottom: ['16px !important', null, '0 !important']
}
