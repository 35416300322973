import queryString from 'query-string'
import { getCookie, setCookie, refCookieName } from '@rmr/helpers'
import { wrapRootElement as wrap } from './root-wrapper'
import './src/styles/global.css'
import './src/styles/slick.css'
export const wrapRootElement = wrap

// Handle referral codes through cookies
const envCookieDuration = +process.env.GATSBY_REFERRAL_CODE_COOKIE_DURATION_DAYS
const cookieDuration = !isNaN(envCookieDuration) ? envCookieDuration : 30
export const onPreRouteUpdate = ({ location }) => {
  const queryParams = queryString.parse(location.search)
  const { ref } = queryParams
  if (ref) {
    const currentRef = getCookie(refCookieName)
    if (!currentRef) {
      setCookie(refCookieName, ref, cookieDuration)
    } else if (currentRef !== ref) {
      setCookie(refCookieName, ref, cookieDuration)
    }
  }
}
