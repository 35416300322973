import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { Section, CardOverlay, Carousel, Link } from '@rmr/components'

export interface CardsCarouselProps {
  headline?: string
  cards: [CardsProps]
}

export interface CardsProps {
  headline?: string
  image?: CardImageProps
  url?: CardUrlProps
}

export interface CardImageProps {
  sourceUrl: string
}

export interface CardUrlProps {
  target?: string
  title?: string
  url: string
}

const SectionCardsCarouselWrapper: FC<CardsCarouselProps> = ({ headline, cards, ...props }) => {
  return (
    <Section headline={headline}>
      <Carousel variant="cardsCarousel" sx={{ width: '100%' }} px={[40]}>
        {cards.map((c, index) => {
          return (
            <Link to={c.url && c.url.url} label={c.url && c.url.title} key={index}>
              <CardOverlay imageUrl={c.image.sourceUrl} hasHover={c.url ? true : false} {...c} />
            </Link>
          )
        })}
      </Carousel>
    </Section>
  )
}

export default SectionCardsCarouselWrapper

export const SectionCardsCarouselWrapperFields = graphql`
  fragment SectionCardsCarouselWrapperFields on WpPage_Layoutsettings_Layout_SectionCardCarousel {
    headline
    cards {
      headline
      image {
        sourceUrl
      }
      url {
        target
        title
        url
      }
    }
  }
`
