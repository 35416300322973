import React from 'react'
import { Box } from 'rebass'

import languages from '../../../i18n'
import Button from '../../Button'
import DropZoneArea from '../DropZoneArea'
import FilePreview from '../FilePreview'
import { buttonStyles } from './UploadComponentHorizontal.styled'
import { UploadComponentVariantProps } from '../UploadComponentVertical'

const UploadComponentHorizontal = ({
  maxFiles,
  maxSize,
  files,
  getRootProps,
  getInputProps,
  isDragActive,
  openBroser,
  handleRemoveFile,
  showButton,
  previewFiles,
  sx,
  error,
  success,
  ...props
}: UploadComponentVariantProps & { success: boolean }) => {
  return (
    <Box {...props} sx={sx}>
      {files && files.length < maxFiles && (
        <Box sx={{ position: 'relative' }}>
          <DropZoneArea
            variant="horizontal"
            maxSize={maxSize}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragActive={isDragActive}
          />
          {showButton && (
            <Button variant="black" onClick={openBroser} sx={buttonStyles}>
              {languages['en'].browseFiles}
            </Button>
          )}
        </Box>
      )}
      <FilePreview
        files={files}
        maxFiles={maxFiles}
        maxSize={maxSize}
        onClose={handleRemoveFile}
        previewFiles={previewFiles}
        error={success ? '' : error}
      />
    </Box>
  )
}

export default UploadComponentHorizontal
