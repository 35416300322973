import { graphql } from 'gatsby'
import React from 'react'
import ContentFormHubspotWrapper from './ContentFormHubspotWrapper'
import ContentSelfHostedFormWrapper from './ContentSelfHostedFormWrapper'

const ContentFormWrapper = ({
  formType,
  formHeadline,
  hubspotFormDetails,
  rmrAppFormDetails,
  content
}: GatsbyTypes.ContentFormFieldsFragment) => {
  switch (formType) {
    case 'hubspot':
      return (
        <ContentFormHubspotWrapper
          portalId={hubspotFormDetails.portalId}
          formId={hubspotFormDetails.formId}
          content={content}
          formHeadline={formHeadline}
        />
      )
    case 'rmrapp':
      return (
        <ContentSelfHostedFormWrapper formId={rmrAppFormDetails.formId} content={content} formHeadline={formHeadline} />
      )
    default:
      return null
  }
}

export default ContentFormWrapper

export const ContentFormFields = graphql`
  fragment ContentFormFields on WpPage_Layoutsettings_Layout_ContentForm {
    formType
    formHeadline
    hubspotFormDetails {
      formId
      portalId
    }
    rmrAppFormDetails {
      formId
    }
    content
  }
`
