import React, { FC } from 'react'
import { Box, SxStyleProp, Flex } from 'rebass'
import Skeleton from 'react-loading-skeleton'

import theme from '../../theme'
import Text from '../Text/Text'
import Icon from '../Icon/Icon'
import { Vehicle } from '@rmr/controllers'

export interface VehicleFeaturesProps {
  /** Set the primary headline */
  headline?: string
  /** Sx Props */
  sx?: SxStyleProp
  /** Array of Vehicle details items */
  items?: [FeatureItemsProps]
  vehicle: Vehicle
  /** Component is loading */
  loading?: boolean
}
export interface FeatureItemsProps {
  type?: string
  value?: string
  key: string
}

const VehicleFeatures: FC<VehicleFeaturesProps> = ({
  vehicle,
  headline = 'Features',
  items,
  sx,
  loading,
  ...props
}) => {
  return (
    <Box {...props} sx={{ ...sx }}>
      <Flex sx={{}} flexDirection={['column', null, 'row']} flexWrap="wrap">
        <Box width={[1, null, null, null, 4 / 12]}>
          <Text mb={[1, 2]} size={'medium'} color={theme.colors.textDark} fontWeight={theme.fontWeights.bold}>
            {!loading ? headline : <Skeleton style={{ width: '100px' }} />}
          </Text>
        </Box>
        <Flex width={[1, null, null, null, 8 / 12]} flexWrap="wrap" flexDirection={['row']}>
          {loading
            ? Array(6)
                .fill(1)
                .map((_, index) => (
                  <Flex key={index} sx={{ flexBasis: '50%', my: 1, alignItems: 'center' }}>
                    <Box sx={{ width: 50, height: 50, borderRadius: '50%', overflow: 'hidden', mr: 1 }}>
                      <Skeleton style={{ width: '100%', height: '100%', display: 'block' }} />
                    </Box>
                    <Skeleton style={{ width: '100px' }} />
                  </Flex>
                ))
            : vehicle.features &&
              vehicle.features.map((feature) => {
                if (vehicle.features && vehicle.features.length > 0) {
                  return (
                    <Flex
                      key={feature.id}
                      width={['unset', null, null, null, 6 / 12]}
                      flex={[null, null, null, null, '1 0 auto']}
                      alignItems="center"
                      flexWrap="nowrap"
                      mr={[2, null, null, null, 0]}
                    >
                      <Box flex="0 0 auto" width="40px" mr={[1]} textAlign="center">
                        <Icon fontSize="30px" name={feature.icon || 'default'} color={theme.colors.primary} />
                      </Box>
                      <Text
                        size={'medium'}
                        color={theme.colors.textGrayLight}
                        fontWeight={theme.fontWeights.semiBold}
                        lineHeight={1}
                      >
                        {feature.name}
                      </Text>
                    </Flex>
                  )
                }
              })}
        </Flex>
      </Flex>
    </Box>
  )
}

VehicleFeatures.defaultProps = {
  headline: '',
  items: null,
  sx: {},
  loading: false
}

export default VehicleFeatures
