import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateAccountInput, CreateAccountMutation, useCreateAccountMutation } from '../lib/generated/generated'

import { useSubmitResult } from './useSubmitResult'
import { validationMessages } from '../i18n'

import { InputValidator } from '@rmr/helpers'
import { parseMobileNumber } from '../helpers/phoneNumber'
import { ApprovalIntention, FileType } from '../types'
import { fileUploader } from '../helpers/fileUploader'
import AvatarEditor from 'react-avatar-editor'

interface UseRegistrationArgs {
  onSuccess: (response: CreateAccountMutation) => void
  onError: (e: Error) => void
  avatarEditor: AvatarEditor
  referralCode: string
}

const createAccountFormSchema = yup.object().shape({
  firstName: yup.string().required(validationMessages.en.required),
  lastName: yup.string().required(validationMessages.en.required),
  email: yup.string().required(validationMessages.en.required).email(validationMessages.en.email),
  country: yup.string().required(validationMessages.en.required),
  phoneNumber: yup
    .string()
    .required(validationMessages.en.required)
    .test('phoneNumber', validationMessages.en.mobileNumber, function (value) {
      const inputValidator = new InputValidator(value)
      if (!inputValidator.validateCellphone()) return false
      return true
    }),
  password: yup
    .string()
    .required(validationMessages.en.required)
    .test('password', validationMessages.en.passwordLength, (val) => val && val.length >= 6),
  terms: yup
    .bool()
    .required(validationMessages.en.required)
    .test('terms', validationMessages.en.termsAndConditions, (value) => {
      return !!value
    }),
  referralCode: yup.string(),
  avatarReady: yup.bool().oneOf([true], validationMessages.en.avatar),
  intention: yup.array().nullable().min(1, validationMessages.en.oneCheckbox)
})

export function useRegistrationForm({ onSuccess, onError, avatarEditor, referralCode }: UseRegistrationArgs) {
  const [loadingUploadImage, setLoadingUploadImage] = useState(false)
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(createAccountFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      country: 'ZA',
      phoneNumber: '',
      password: '',
      terms: false,
      avatarReady: false,
      intention: null,
      referralCode
    }
  })

  const [createAccountMutation, { loading }] = useCreateAccountMutation()

  const onSubmit = useCallback(
    async (data: CreateAccountInput & { avatarReady: boolean; intention: ApprovalIntention[] }) => {
      try {
        // proces profile image
        setLoadingUploadImage(true)
        const imageCanvas = avatarEditor.getImage()

        const makeFile = new Promise((resolve) => {
          imageCanvas.toBlob((blob) => {
            let extension = ''
            if (blob.type) {
              const blobTypeArray = blob.type.split('/')
              if (Array.isArray(blobTypeArray) && blobTypeArray[1]) {
                extension = blobTypeArray[1]
              }
            }
            const imageFile = new File([blob], `avatar${extension ? `.${extension}` : ''}`, { type: blob.type })
            resolve(imageFile)
          })
        })
        const imageFile = (await makeFile) as File

        // upload profile image
        let avatar = ''
        if (imageFile) {
          const file = await fileUploader(imageFile, FileType.IMAGE)
          avatar = file?.url ? file.url : ''
        }

        const { phoneNumber, avatarReady, intention, ...rest } = data

        const isOwner = intention.includes(ApprovalIntention.Owner)
        const isRenter = intention.includes(ApprovalIntention.Renter)
        const meta = { isOwner, isRenter }
        let parsedPhoneNumber, countryCode
        if (phoneNumber) {
          const phoneNumberObject = parseMobileNumber(phoneNumber)
          parsedPhoneNumber = phoneNumberObject?.number
          countryCode = phoneNumberObject?.countryCallingCode ? `+${phoneNumberObject.countryCallingCode}` : ''
        } else {
          parsedPhoneNumber = undefined
          countryCode = undefined
        }
        const input = { phoneNumber: parsedPhoneNumber, countryCode, ...rest, avatar, meta }
        const response = await createAccountMutation({
          variables: { input }
        })
        onSuccess(response.data)
        const { createAccount } = response.data
        if (createAccount.__typename === 'UserRegisterSuccess') {
          onSubmitSuccess()
        } else if (createAccount.message) {
          onSubmitError('error', createAccount.message)
        } else {
          onSubmitError()
        }
      } catch (err) {
        onError(err)
        onSubmitError('error', err.message)
      } finally {
        setLoadingUploadImage(false)
      }
    },
    [avatarEditor, createAccountMutation, onSuccess, onSubmitSuccess, onSubmitError, onError]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading || loadingUploadImage,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  }
}
