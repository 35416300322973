import { Button, FormMessage, Input, Loader, MessageColor, PasswordInput, TermsAndConditions } from '@rmr/components'
import { CreateAccountMutation, useAuth, useRegistrationReferralForm, validationMessages } from '@rmr/controllers'
import { getCookie, GtmCategory, GtmEvent, logGtmEvent, processTrimLowerCase, refCookieName } from '@rmr/helpers'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import countryList from 'react-select-country-list'
import { Box, Flex } from 'rebass'
import { languages } from '../../common/i18n'
import { getIntentionStr } from '../../utils/helpers'
import {
  buttonStyles,
  fieldStyles,
  formStyles,
  fullWidthField,
  wrapperStyles
} from '../SignupEmailForm/SignupEmailForm.styled'
import { scrollToHeadline } from '../StepsNavigation'
import WhatsAppNotice from '../WhatsAppNotice'

const countries = countryList()
  .getData()
  .map((e) => ({ ...e, key: e.value }))

export type FormData = {
  firstName: string
  lastName: string
  email: string
  country: string
  phoneNumber: string
  password: string
  terms: boolean
  referralCode: string
}
interface ReferralSignupForm {
  idRef: string
}

const ReferralSignupForm = ({ idRef }: ReferralSignupForm) => {
  const [readOnlyRefCode, setReadOnlyRefCode] = useState(false)
  const { login: setToken } = useAuth()
  const onSuccess = ({ createAccount }: CreateAccountMutation) => {
    if (createAccount.__typename === 'UserRegisterSuccess') {
      const { referralCode } = createAccount.user.profile

      const data = {
        category: GtmCategory.AUTHENTICATION,
        source: 'EMAIL',
        intention: getIntentionStr(createAccount.user.profile.additionalInfo)
      }

      logGtmEvent({
        eventName: GtmEvent.USER_SIGNUP,
        data
      })

      setToken(createAccount.token, createAccount.expiry)

      scrollToHeadline(idRef)

      navigate('/app/create/profile')
    }
  }

  const onError = (err) => {
    console.log({ err })
  }

  const {
    watch,
    setValue,
    clearErrors,
    register,
    onSubmit,
    errors,
    submitting,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  } = useRegistrationReferralForm({
    onSuccess,
    onError
  })
  const email = watch('email')

  useEffect(() => {
    const referralCodeCookie = getCookie(refCookieName) ?? ''
    if (referralCodeCookie) {
      setReadOnlyRefCode(true)
    }
    setValue('referralCode', referralCodeCookie)
  }, [])

  useEffect(() => {
    register('email')
  }, [register])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      submitResult.message !== validationMessages.en.warnErrors && onSubmitError(null, validationMessages.en.warnErrors)
    } else if (submitResult.message === validationMessages.en.warnErrors) {
      onSubmitSuccess()
    }
  }, [errors, onSubmitError, onSubmitSuccess, submitResult.message])

  return (
    <React.Fragment>
      {submitting && <Loader />}
      <Box sx={wrapperStyles}>
        <Flex flexDirection="column" alignItems="center">
          <Box as="form" onSubmit={onSubmit} sx={formStyles}>
            <Input
              name="firstName"
              label="First Name*"
              sx={{ ...fieldStyles, mr: [0, 3] }}
              ref={register}
              error={errors.firstName && errors.firstName.message}
            />
            <Input
              name="lastName"
              label="Last Name*"
              sx={{ ...fieldStyles }}
              ref={register}
              error={errors.lastName && errors.lastName.message}
            />
            <Input
              type="text"
              name="email"
              label="Email Address*"
              sx={{ ...fieldStyles, mr: [0, 3] }}
              rebassInputProps={{
                autoComplete: 'email',
                value: email,
                onChange: (e) => {
                  errors.email && errors.email.message && clearErrors('email')
                  setValue('email', processTrimLowerCase(e.target.value))
                }
              }}
              error={errors.email && errors.email.message}
            />

            <PasswordInput
              name="password"
              label="Password*"
              sx={{ ...fieldStyles, mr: [0, 3] }}
              rebassInputProps={{ autoComplete: 'current-password' }}
              ref={register}
              error={errors.password && errors.password.message}
            />
            <Input
              name="referralCode"
              label="Referral Code"
              sx={{ ...fieldStyles, mr: [0, 3], mb: 5 }}
              ref={register}
              error={errors.referralCode && errors.referralCode.message}
              rebassInputProps={{ readOnly: readOnlyRefCode }}
            />
            <TermsAndConditions name="terms" error={errors.terms ? errors.terms.message : undefined} ref={register} />
            <WhatsAppNotice />
            <Box sx={{ ...fullWidthField }}>
              <FormMessage
                showMessage={submitResult.submitted && !!submitResult.message}
                message={submitResult.message}
                color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
              />
            </Box>
            <Flex sx={{ ...fullWidthField, justifyContent: 'center' }}>
              <Button type="submit" label={languages.en.auth.buttonSignUp} sx={buttonStyles} />
            </Flex>
          </Box>
        </Flex>
      </Box>
    </React.Fragment>
  )
}
export default ReferralSignupForm
