import React, { FC, useEffect, useState, useMemo, useCallback } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { navigate } from '@reach/router'
import { Box, Flex } from 'rebass'
import { Heading, Button, Icon, Text, FormMessage, MessageColor, Link, Loader } from '@rmr/components'
import { languages } from '../../common/i18n'
import GoogleLogin, { ProviderType } from '../GoogleLogin'
import FacebookLogin from '../FacebookLogin'
import { SubmitState, Exact, MeQuery } from '@rmr/controllers'
import { SignupType } from '../Signup'
import { QueryLazyOptions } from '@apollo/client'

const outlineButtonStyles = {
  height: [50, 60],
  fontSize: ['tiny', 'small', 'medium']
}

interface SignupDashboardProps extends Partial<StepWizardChildProps> {
  onSetSignupType: (type: SignupType) => void
  getMe: (
    options?: QueryLazyOptions<
      Exact<{
        [key: string]: never
      }>
    >
  ) => void
  user: MeQuery['me']
}

const SignupDashboard: FC<SignupDashboardProps> = ({ onSetSignupType, getMe, user, nextStep, lastStep }) => {
  const [submitResult, setSubmitResult] = useState<SubmitState>({
    submitted: false,
    success: false,
    error: '',
    message: ''
  })

  const onSubmit = useCallback(
    (submitResult: SubmitState) => {
      setSubmitResult(submitResult)
      const { success } = submitResult
      if (success) {
        getMe()
        navigate('/app/create/profile')
      }
    },
    [getMe]
  )

  const onClick = (type: SignupType) => {
    onSetSignupType(type)
    nextStep()
  }

  useEffect(() => {
    if (user?.profile) {
      const { error } = submitResult
      if (
        !user?.profile?.phoneNumber ||
        !user?.profile?.country ||
        !user?.profile?.avatar ||
        !user?.profile?.additionalInfo ||
        !user?.termsAcceptedAt
      ) {
        const { error } = submitResult
        if (!error) nextStep()
      } else {
        if (!error) lastStep()
      }
    }
  }, [lastStep, nextStep, submitResult, user])

  return (
    <Box>
      <Heading size="h4" fontWeight="extraBold" mb={4} textAlign="center">
        {languages.en.auth.signup.dashboardHeadline}
      </Heading>
      <Button
        label={languages.en.auth.emailLabel}
        variant="outline"
        fullWidth
        mb={2}
        sx={outlineButtonStyles}
        icon={<Icon name="envelope" sx={{ fontSize: [24] }} />}
        onClick={() => onClick(SignupType.EMAIL)}
      />
      <Box mb={2}>
        <FacebookLogin onSubmit={onSubmit} providerType={ProviderType.Register} />
      </Box>
      <Box mb={4}>
        <GoogleLogin onSubmit={onSubmit} providerType={ProviderType.Register} />
      </Box>
      <FormMessage
        showMessage={submitResult.submitted}
        message={submitResult.message}
        color={`${submitResult.error ? 'statusError' : 'statusSuccess'}` as MessageColor}
      />
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="center"
        mt={2}
        flexWrap={'wrap'}
      >
        <Link to="/app/login/">
          <Text size="body" fontWeight="medium" mr={[0, 0, 2]} mb={[2, 2, 0]}>
            {languages.en.auth.signup.haveAccount}
          </Text>
        </Link>
        <Link to="/app/login/">
          <Button
            variant="transparent"
            label={languages.en.auth.signup.haveAccountButtonText}
            sx={{ py: 0, fontSize: 'medium' }}
          />
        </Link>
      </Flex>
    </Box>
  )
}
export default SignupDashboard
