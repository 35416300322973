import React, { FC } from 'react'
import { SxStyleProp, Box, Button } from 'rebass'
import { Container } from '../Grid'
import Heading from '../Heading'
import {
  StyledSectionSplit,
  StyledContent,
  StyledHeader,
  StyledHeaderLine,
  StyledModules,
  StyledModule,
  StyledModuleContent
} from './SectionSplit.styled'
import theme from '../../theme'
import Content from '../Content'
import parse from 'html-react-parser'
import Link from '../Link'

export interface SectionSplitTypes {
  /** Headline */
  headline?: string
  /** Color Scheme of the Section */
  colorScheme?: 'black' | 'white' | 'gray' | 'orange' | string
  /** Sx Props */
  sx?: SxStyleProp
  /**Modules */
  modules?: [ModuleTypes]
  /** Children */
  children?: React.ReactNode
}
export interface ModuleTypes {
  headline?: string
  content?: React.ReactNode
  actions?: [ActionTypes]
}
export interface ActionTypes {
  actionType: 'primary' | 'outline' | 'transparent' | 'outlineRound'
  url: UrlTypes
}

export interface UrlTypes {
  url: string
  title: string
  target: string
}

export const getColorScheme = {
  white: {
    bg: theme.colors.bgWhite,
    heading: theme.colors.textLight,
    headingModule: theme.colors.textDark,
    text: theme.colors.textLight,
    listImage: theme.colors.primary,
    listText: theme.colors.textDark
  },
  black: {
    bg: theme.colors.bgDark,
    heading: theme.colors.textLight,
    headingModule: theme.colors.textWhite,
    text: theme.colors.textLight,
    listImage: theme.colors.primary,
    listText: theme.colors.textWhite
  },
  gray: {
    bg: theme.colors.bgLight,
    heading: theme.colors.textLight,
    headingModule: theme.colors.textDark,
    text: theme.colors.textLight,
    listImage: theme.colors.primary,
    listText: theme.colors.textGray
  },
  orange: {
    bg: theme.colors.primary,
    heading: theme.colors.textGray,
    headingModule: theme.colors.textWhite,
    text: theme.colors.textGray,
    listImage: theme.colors.textWhite,
    listText: theme.colors.textWhite
  }
}

const SectionSplit: FC<SectionSplitTypes> = ({ headline, colorScheme, modules, children, sx, ...props }) => {
  return (
    <StyledSectionSplit {...props} bg={getColorScheme[colorScheme].bg} py={[4, 6]} sx={{ ...sx }}>
      <Container>
        <StyledContent>
          {headline && (
            <Box mb={[2, 4]}>
              <Heading
                size="h6"
                fontWeight={theme.fontWeights.regular}
                textAlign="center"
                color={getColorScheme[colorScheme].heading}
              >
                {headline}
              </Heading>
            </Box>
          )}
          {modules && modules.length > 0 ? (
            <StyledModules flexDirection={['column', 'column', 'row']}>
              {modules.map((c, index) => {
                return (
                  <StyledModule width={['100%', '100%', '50%']} mx={[1, 2]} key={index} mb={[4, 4, 0]}>
                    <StyledHeader mb={[2, 4]}>
                      <Heading size="h4" color={getColorScheme[colorScheme].headingModule}>
                        {c.headline}
                      </Heading>
                      <StyledHeaderLine mt={[1, 3]} bg={getColorScheme[colorScheme].headingModule} />
                    </StyledHeader>
                    {c.content && (
                      <StyledModuleContent
                        color={getColorScheme[colorScheme].text}
                        listTextColor={getColorScheme[colorScheme].listText}
                        listImageColor={getColorScheme[colorScheme].listImage}
                      >
                        <Content>{parse(c.content as string)}</Content>
                      </StyledModuleContent>
                    )}
                    {c.actions &&
                      c.actions.map((a, index) => {
                        return (
                          a.url && (
                            <Box mr={[0, 2]} mb={[0, 1]} key={index}>
                              <Link to={a.url && a.url.url}>
                                <Button as={'span'} type="button" variant={a.actionType}>
                                  {a.url && a.url.title}
                                </Button>
                              </Link>
                            </Box>
                          )
                        )
                      })}
                  </StyledModule>
                )
              })}
            </StyledModules>
          ) : null}
        </StyledContent>
      </Container>
    </StyledSectionSplit>
  )
}

SectionSplit.defaultProps = {
  headline: '',
  colorScheme: 'black',
  children: null,
  modules: null,
  sx: {}
}

export default SectionSplit
