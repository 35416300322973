import { theme } from '@rmr/components'
import { SxStyleProp } from 'rebass'

export const filtersWrapperStyles: SxStyleProp = {
  flexWrap: 'wrap'
}
export const mobileFiltersWrapperStyles: SxStyleProp = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}
export const filterWrapperStyles: SxStyleProp = {
  mb: [2],
  ':not(:last-child)': {
    mr: [0, null, null, 2]
  }
}

export const mobileStyles = (show = false): SxStyleProp => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  bg: 'bgWhite',
  transform: show ? 'translateX(0)' : 'translateX(100%)',
  transition: 'transform 0.2s',
  zIndex: theme.zIndex.max
})
