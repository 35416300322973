import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { UpdateAccountInput, UpdateAccountMutation, useUpdateAccountMutation } from '../lib/generated/generated'

import { useSubmitResult } from './useSubmitResult'
import { validationMessages } from '../i18n'

import { InputValidator } from '@rmr/helpers'
import { parseMobileNumber } from '../helpers/phoneNumber'
import { ApprovalIntention, FileType } from '../types'
import { fileUploader } from '../helpers/fileUploader'
import AvatarEditor from 'react-avatar-editor'

type DefaultValues = {
  firstName: string
  lastName: string
  country: string
  phoneNumber: string
  terms: boolean
  avatarReady: boolean
  intention: any
  referralCode: string
}

interface UseUpdateUserArgs {
  onSuccess: (response: UpdateAccountMutation) => void
  onError: (e: Error) => void
  avatarEditor: AvatarEditor
  defaultValues: DefaultValues
}

const updateAccountFormSchema = yup.object().shape({
  country: yup.string().required(validationMessages.en.required),
  phoneNumber: yup
    .string()
    .required(validationMessages.en.required)
    .test('phoneNumber', validationMessages.en.mobileNumber, function (value) {
      const inputValidator = new InputValidator(value)
      if (!inputValidator.validateCellphone()) return false
      return true
    }),
  terms: yup
    .bool()
    .required(validationMessages.en.required)
    .test('terms', validationMessages.en.termsAndConditions, (value) => {
      return !!value
    }),
  avatarReady: yup.bool().oneOf([true], validationMessages.en.avatar),
  intention: yup.array().nullable().min(1, validationMessages.en.oneCheckbox)
})

export function useUpdateUserForm({ onSuccess, onError, avatarEditor, defaultValues }: UseUpdateUserArgs) {
  const [loadingUploadImage, setLoadingUploadImage] = useState(false)
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(updateAccountFormSchema),
    defaultValues
  })

  const [updateAccountMutation, { loading }] = useUpdateAccountMutation()

  const onSubmit = useCallback(
    async (data: UpdateAccountInput & { avatarReady: boolean; intention: ApprovalIntention[] }) => {
      try {
        // upload profile image
        let avatar
        if (avatarEditor?.props?.image) {
          // proces profile image
          setLoadingUploadImage(true)
          const imageCanvas = avatarEditor.getImage()

          const makeFile = new Promise((resolve) => {
            imageCanvas.toBlob((blob) => {
              let extension = ''
              if (blob.type) {
                const blobTypeArray = blob.type.split('/')
                if (Array.isArray(blobTypeArray) && blobTypeArray[1]) {
                  extension = blobTypeArray[1]
                }
              }
              const imageFile = new File([blob], `avatar${extension ? `.${extension}` : ''}`, { type: blob.type })
              resolve(imageFile)
            })
          })
          const imageFile = (await makeFile) as File

          if (imageFile) {
            const file = await fileUploader(imageFile, FileType.IMAGE)
            avatar = file?.url ? file.url : ''
          }
        }

        const { phoneNumber, avatarReady, intention, ...rest } = data

        const isOwner = intention.includes(ApprovalIntention.Owner)
        const isRenter = intention.includes(ApprovalIntention.Renter)
        const meta = { isOwner, isRenter }
        let parsedPhoneNumber, countryCode
        if (phoneNumber) {
          const phoneNumberObject = parseMobileNumber(phoneNumber)
          parsedPhoneNumber = phoneNumberObject?.number
          countryCode = phoneNumberObject?.countryCallingCode ? `+${phoneNumberObject.countryCallingCode}` : ''
        } else {
          parsedPhoneNumber = undefined
          countryCode = undefined
        }
        const input = { phoneNumber: parsedPhoneNumber, countryCode, ...rest, meta }
        if (avatar) {
          Object.assign(input, { avatar })
        }
        const response = await updateAccountMutation({
          variables: { input }
        })
        onSuccess(response.data)
        const { updateAccount } = response.data
        if (updateAccount.__typename === 'UserUpdateSuccess') {
          onSubmitSuccess()
        } else if (updateAccount.message) {
          onSubmitError('error', updateAccount.message)
        } else {
          onSubmitError()
        }
      } catch (err) {
        onError(err)
        onSubmitError('error', err.message)
      } finally {
        setLoadingUploadImage(false)
      }
    },
    [avatarEditor, updateAccountMutation, onSuccess, onSubmitSuccess, onSubmitError, onError]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading || loadingUploadImage,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  }
}
