import React, { Fragment, forwardRef } from 'react'
import { Card as RebassCard, CardProps as RebassCardProps, Box, Flex, SxStyleProp } from 'rebass'

import { vehicleCardStyles, imageWrapperStyles, headerImagestyles, closeStyles } from './ImageCard.styled'
import Icon from '../Icon'

import Text from '../Text'

export interface ImageCardProps extends RebassCardProps {
  /** Card Image */
  image: React.ReactNode
  /** Label under image */
  label?: string
  /** Close handler */
  onClose?: () => void
  /** Sx Styles */
  sx?: SxStyleProp
}

const ImageCard = forwardRef<HTMLDivElement, ImageCardProps>(({ image, label, onClose, sx, ...props }, ref) => {
  return (
    <RebassCard ref={ref} {...props} sx={{ ...(vehicleCardStyles as SxStyleProp), ...sx }}>
      <Box mb={2} sx={imageWrapperStyles}>
        {image && <Box sx={headerImagestyles}>{image}</Box>}
        {onClose && (
          <Box sx={closeStyles} onClick={onClose}>
            <Icon name="redClose" />
          </Box>
        )}
      </Box>

      {label && (
        <Box>
          <Text size="small" color="textLight">
            {label}
          </Text>
        </Box>
      )}
    </RebassCard>
  )
})

ImageCard.defaultProps = {
  image: null,
  label: '',
  onClose: () => null,
  sx: {}
}

export default ImageCard
