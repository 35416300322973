import React from 'react'
import { Heading, Content, Container, Icon, theme } from '@rmr/components'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Box, Flex } from 'rebass'
import styled from '@emotion/styled'
import { Disclosure, Transition } from '@headlessui/react'

const StyledDisclosure = styled(Disclosure)`
  button {
    cursor: pointer;
    border: none;
    background: none;
    color: ${theme.colors.textDark};
    margin-bottom: 16px;
    transition: all ${theme.speed.default};
    &:hover,
    &.open {
      color: ${theme.colors.textLight};
    }
    .icon {
      color: ${theme.colors.textDark};
      svg {
        transform: rotate(0deg);
        transition: all ${theme.speed.default};
      }

      &.open svg {
        transform: rotate(-180deg);
        color: ${theme.colors.textLight};
      }
    }
  }
`

interface FaqsProps {
  questions?: [QuestionProps]
}

interface QuestionProps {
  faqCategory?: string
  faqItems?: [FaqItemProps]
}

interface FaqItemProps {
  collapseHeadline?: string
  collapseContent?: React.ReactNode | string
}

const FaqsWrapper: FaqsProps = ({ questions }) => {
  return (
    <Container py={[4, 10]}>
      {questions && questions.length > 0
        ? questions.map((q, index) => {
            return (
              <Box py={[1, 2]}>
                {q.faqCategory && (
                  <Box key={index}>
                    <Heading size="h4" mb={[1, 2]} color={theme.colors.primary}>
                      {q.faqCategory}
                    </Heading>
                  </Box>
                )}
                {q.faqItems && q.faqItems.length > 0
                  ? q.faqItems.map((i, index) => {
                      return (
                        <StyledDisclosure key={index} as="div">
                          {({ open }) => {
                            return (
                              <>
                                <Disclosure.Button className={`${open ? 'open' : 'closed'}`}>
                                  <Flex justifyContent="center" alignItems="center">
                                    <Box sx={{ flex: '0 0 auto' }}>
                                      <Icon
                                        className={`icon ${open ? 'open' : 'closed'}`}
                                        name="arrowDown"
                                        fontSize="20px"
                                        mr={[1]}
                                        mb={'4px'}
                                      />
                                    </Box>
                                    <Heading
                                      size="h6"
                                      pb={[1]}
                                      fontWeight={theme.fontWeights.bold}
                                      color="inherit"
                                      textAlign="left"
                                    >
                                      {i.collapseHeadline}
                                    </Heading>
                                  </Flex>
                                </Disclosure.Button>
                                <Transition
                                  show={open}
                                  enter="transition-opacity duration-75"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  leave="transition-opacity duration-150"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Disclosure.Panel static>
                                    <Box pb={[1]} color={theme.colors.textLight}>
                                      <Content>{parse(i.collapseContent)}</Content>
                                    </Box>
                                  </Disclosure.Panel>
                                </Transition>
                              </>
                            )
                          }}
                        </StyledDisclosure>
                      )
                    })
                  : null}
              </Box>
            )
          })
        : null}
    </Container>
  )
}

export default FaqsWrapper

export const FaqFields = graphql`
  fragment FaqFields on WpPage_Layoutsettings_Layout_Faqs {
    questions {
      faqCategory
      faqItems {
        collapseHeadline
        collapseContent
      }
    }
  }
`
