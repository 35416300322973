import React, { forwardRef } from 'react'
import { Box, SxStyleProp } from 'rebass'
import { filterButtonStyles } from './FilterButton.styled'
import Text from '../../Text'
import Button from '../../Button'
import Icon from '../../Icon'

interface FilterButtonProps {
  filterName: string
  onClickButton: () => void
  onClickCloseButton?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  activeLabel?: string
  open: boolean
  showButton?: boolean
  groupMode?: boolean
  buttonSx: SxStyleProp
}
const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  (
    {
      filterName,
      onClickButton,
      onClickCloseButton,
      activeLabel,
      open,
      showButton = true,
      groupMode = false,
      buttonSx
    },
    ref
  ) => {
    return (
      <React.Fragment>
        {groupMode && (
          <Text fontWeight="extraBold" mb={1}>
            {filterName}
          </Text>
        )}
        {showButton && (
          <Button
            ref={ref}
            variant="outline"
            onClick={onClickButton}
            sx={{ ...filterButtonStyles(open || !!activeLabel), ...buttonSx }}
          >
            {filterName}
            {!!activeLabel && ` (${activeLabel})`}
            {!!activeLabel && !!onClickCloseButton ? (
              <Box>
                <Icon name="close" color="bgWhite" sx={{ ml: 2, zIndex: 1 }} onClick={onClickCloseButton} />
              </Box>
            ) : (
              <Box>
                <Icon name="caretDown" color={open ? 'textWhite' : 'textDark'} sx={{ ml: 2, mr: 1, zIndex: 1 }} />
              </Box>
            )}
          </Button>
        )}
      </React.Fragment>
    )
  }
)

export default FilterButton
