import { theme } from '@rmr/components'
import { SxStyleProp } from 'rebass'

export const wrapperStyles = (padding: (number | string) | (number | string)[], boxShadow): SxStyleProp => ({
  bg: 'bgWhite',
  borderRadius: `${theme.borderRadius.medium}`,
  overflow: 'hidden',
  boxShadow: boxShadow ? `0 0 20px 0px ${theme.colors.bgShadow}` : 'none',
  padding: padding,
  transition: 'all 0.2s',
  display: 'flex !important',
  flexDirection: 'row'
})

export const vehicleCardStyles: SxStyleProp = {
  position: 'relative',
  border: 'none',
  overflow: 'hidden',
  bg: 'bgWhite',
  p: '0 !important',
  display: 'flex !important',
  flexDirection: 'column',
  width: '100%'
}

export const carouselStyles: SxStyleProp = {
  width: '100%',
  'div.slick-slide': {
    paddingLeft: '0px !important',
    paddingRight: '0px !important'
  }
}

export const headerImagestyles: SxStyleProp = {
  width: '100%'
}

export const headerWrapperStyles: SxStyleProp = {
  position: 'relative'
}

export const priceWrapperStyles: SxStyleProp = {
  bg: 'baseWhiteTransparent',
  position: 'absolute',
  bottom: [0],
  left: 0,
  px: [0, 1],
  py: [0, 1],
  pt: ['4px', 1],
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px'
}

export const priceStyles: SxStyleProp = {
  color: 'textBlack',
  fontSize: ['large', 'h6']
}
export const arrowStyles: SxStyleProp = {
  bg: 'bgWhite',
  width: [20],
  height: [20],
  position: 'absolute',
  top: '100%',
  left: '50%',
  transform: 'translate(-50%,-50%) rotate(45deg)'
}
