import React, { FC, useState, useMemo, useEffect } from 'react'
import StepWizard from 'react-step-wizard'
import { RouteComponentProps, useLocation } from '@reach/router'
import { Box, Flex, SxStyleProp } from 'rebass'

import { stepsWrapperStyles, Container, Loader, Modal, FormMessage } from '@rmr/components'

import { languages } from '../common/i18n'
import SignupDashboard from './SignupDashboard'
import SignupEmailForm from './SignupEmailForm'
import Success from './Success'
import SEO from './Seo'
import SignupSocialForm from './SignupSocialForm'
import { useMeLazyQuery, useAuth } from '@rmr/controllers'

export enum SignupType {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  EMAIL = 'email'
}

const idRef = 'success_id'
type ExtendedLocation = Location & {
  state: {
    fromReferAFriend?: boolean
  }
}

const CreateProfile: FC<RouteComponentProps> = ({ user }) => {
  const location = useLocation() as ExtendedLocation
  const [openModal, setOpenModal] = useState(location.state?.fromReferAFriend)

  return (
    <>
      <Box sx={{ position: 'relative', overflow: 'auto' }}>
        <SEO
          title="Create Profile | RentMyRide"
          description="We founded RentMyRide to allow mobility for everyone and change the way that people use cars. "
          path="/app/approval-to-list"
        />
        <Box as="section" sx={sectionStyles}>
          <Container>
            <Flex sx={wrapperStyles}>
              <Box sx={{ ...stepsWrapperStyles, ...stepsWrapper }}>
                <StepWizard isLazyMount>
                  <SignupSocialForm idRef={idRef} user={user} />
                  <p>hello</p>
                </StepWizard>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Box>
      <Modal isModalOpen={openModal} closeModal={() => setOpenModal(false)}>
        <Box>
          <FormMessage showMessage={true} message={languages.en.referFriend.noProfileCreated} color="statusInfo" />
        </Box>
      </Modal>
    </>
  )
}

export default CreateProfile

export const sectionStyles: SxStyleProp = {
  position: 'relative',
  py: [6, 8]
}

export const stepsWrapper: SxStyleProp = {
  maxWidth: '700px',
  width: '100%',
  py: [4, 8],
  px: [4, 16],
  overflow: 'hidden'
}

export const imageStyles: SxStyleProp = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  height: '100%',
  width: '100%'
}

export const wrapperStyles: SxStyleProp = {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}
