import React from 'react'
import { useGoogleLogin } from 'react-google-login'
import { StaticImage } from 'gatsby-plugin-image'
import { Button, Loader } from '@rmr/components'
import { languages } from '../../common/i18n'
import useSocialAuth from '../../hooks/useSocialAuth'
import { OAuthProviderTypes, SubmitState } from '@rmr/controllers'

export enum ProviderType {
  Register,
  Login
}
const GoogleLogo = () => (
  <StaticImage
    src={'../../images/google.png'}
    alt="Google logo"
    placeholder="blurred"
    layout="fixed"
    width={25}
    height={25}
    quality={100}
    loading="eager"
  />
)

interface GoogleLoginProps {
  providerType?: ProviderType
  onSubmit: (sumitResult: SubmitState) => void
  callback?: () => void
}

const GoogleLogin = ({ providerType = ProviderType.Register, onSubmit, callback }: GoogleLoginProps) => {
  const { onSuccess, loadingLogin, loadingRegister } = useSocialAuth(
    providerType,
    OAuthProviderTypes.Google,
    onSubmit,
    callback
  )

  const { signIn } = useGoogleLogin({
    clientId: process.env.GATSBY_GOOGLE_CLIENT_ID,
    onSuccess
  })

  return (
    <React.Fragment>
      {(loadingLogin || loadingRegister) && <Loader />}
      <Button
        label={languages.en.auth.googleLabel}
        variant="outline"
        fullWidth
        sx={outlineButtonStyles}
        icon={<GoogleLogo />}
        onClick={() => signIn()}
      />
    </React.Fragment>
  )
}

export default GoogleLogin

const outlineButtonStyles = {
  height: [50, 60],
  fontSize: ['tiny', 'small', 'medium']
}
