import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { Box } from 'rebass'

import { languages } from '../../../common/i18n'
import Layout from '../Layout'
import DisplayImages, { UploadedFile, UploadedFiles } from './DisplayImages'
import UploadImages, { HandleUploadCallback, Buttons } from './UploadImages'
import { wrapperStyles, fullWidthField } from './UploadVehicleImages.styled'
import {
  fileUploader,
  useUpdateVehicleMutation,
  UpdateVehicleInput,
  ImageWithView,
  VehicleImage,
  languages as globalLanguages,
  FileType
} from '@rmr/controllers'
import { scrollToHeadline } from '../../StepsNavigation'
import { Loader, FormMessage, MessageColor, Modal, WizardStep } from '@rmr/components'
import ImageInfoCarousel from '../ImageInfoCarousel'

interface UploadVehicleImagesProps extends Partial<StepWizardChildProps> {
  idRef: string
  vehicleid: string
  vehicleName: string
  existingImages: VehicleImage[]
  wizardSteps: WizardStep[]
}

const positions = globalLanguages.en.enums.VehicleViewPositionOptions

const resetButtonsState: Buttons = positions.reduce((acc, cur, index) => {
  let on = false
  if (index === 0) on = true
  acc[cur.value] = { on, success: false, fileId: null, file: null, label: cur.label }
  return acc
}, {})

const UploadVehicleImages = ({
  idRef,
  currentStep,
  previousStep,
  nextStep,
  goToNamedStep,
  vehicleid,
  vehicleName,
  existingImages,
  wizardSteps
}: UploadVehicleImagesProps) => {
  const [loadingUploadImage, setLoadingUploadImage] = useState(false)
  const [{ success, message }, setMessage] = useState({ success: false, message: '' })
  const initialFilesState: UploadedFiles = useMemo(
    () => ({
      removeFile: null,
      files: existingImages.map((e) => {
        const newFile: UploadedFile = {
          fileId: e.fileId,
          file: new File([''], e.view),
          position: e.view,
          positionLabel: resetButtonsState[e.view].label
        }
        newFile.file.preview = e.file.url
        return newFile
      })
    }),
    [existingImages]
  )

  const initialButtonsState: Buttons = useMemo(
    () =>
      positions.reduce((acc, cur, index) => {
        let on = false,
          success = false,
          fileId = null,
          file = null
        if (index === 0) on = true
        const existingFile = initialFilesState.files.find((file) => file.position)
        if (existingFile) {
          on = false
          success = true
          file = existingFile.file
          fileId = existingFile.fileId
        }
        acc[cur.value] = { on, success, fileId, file, label: cur.label }
        return acc
      }, {}),
    [initialFilesState.files]
  )

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>(initialFilesState)
  const [buttons, setButtons] = useState<Buttons>(JSON.parse(JSON.stringify(initialButtonsState)))

  const [updateVehicleMutation, { loading: loadingUpdate }] = useUpdateVehicleMutation()

  const handleClickNext = useCallback(async () => {
    try {
      if (uploadedFiles.files.length < 6) {
        setMessage({ success: false, message: languages['en'].listYourCar.uploadVehicleImages.numberMessage })
      } else {
        const vehicleImages: ImageWithView[] = uploadedFiles.files.map(({ fileId, position }) => ({
          fileId,
          view: position
        }))
        const vehicleInputData: UpdateVehicleInput = {
          id: vehicleid,
          vehicleImages
        }

        await updateVehicleMutation({ variables: { input: vehicleInputData } })

        nextStep()
        scrollToHeadline(idRef)
      }
    } catch (error) {
      console.log('vehicle update images error', { error })
      setMessage({ success: false, message: languages['en'].listYourCar.uploadVehicleImages.serverProblemMessage })
    }
  }, [uploadedFiles.files, vehicleid, updateVehicleMutation, nextStep, idRef])

  const handleUploadCallback: HandleUploadCallback = useCallback(
    async (files, handleRemoveFile) => {
      try {
        const activePosition = Object.keys(buttons).find((position) => buttons[position].on)
        const newUploadedFiles: UploadedFile[] = []
        const newFiles = files.slice()
        const uniqueFiles = []
        if (files.length !== uploadedFiles.files.length) {
          let fileToUpload, fileId
          newFiles.forEach((newFile, index) => {
            // Avoid duplicates and delete them if so
            if (uniqueFiles.indexOf(newFile.name) !== -1) {
              setMessage({ success: false, message: languages['en'].listYourCar.uploadVehicleImages.sameFileMessage })
              handleRemoveFile(index)
            } else {
              uniqueFiles.push(newFile.name)
              // check if file has been already been loaded
              const uploadedFile = uploadedFiles.files.find((uploadedFile) => uploadedFile.file.name === newFile.name)
              // avoid more than 1 image per position and delete them if so
              if (
                !newUploadedFiles.find((newUploadedFile) => {
                  return newUploadedFile.position === activePosition
                })
              ) {
                if (!uploadedFile) {
                  fileToUpload = newFile

                  // will update fileId after image is uploaded
                  newUploadedFiles.push({
                    position: activePosition,
                    file: newFile,
                    fileId: '',
                    positionLabel: buttons[activePosition].label
                  })
                } else {
                  newUploadedFiles.push(uploadedFile)
                }
              } else {
                handleRemoveFile(index)
              }
            }
          })
          if (fileToUpload) {
            // upload images
            setLoadingUploadImage(true)

            const file = await fileUploader(fileToUpload, FileType.IMAGE)
            fileId = file?.id
            // update newFile with fileID
            const newUploadedFileIndex = newUploadedFiles.findIndex(
              (newUploadedFile) => newUploadedFile.position === activePosition
            )
            if (newUploadedFileIndex > -1) {
              newUploadedFiles[newUploadedFileIndex].fileId = fileId
              delete newUploadedFiles[newUploadedFileIndex].file.preview
            }
          }
          setUploadedFiles({ removeFile: handleRemoveFile, files: newUploadedFiles })
          // update buttons
          const newButtons: Buttons = JSON.parse(JSON.stringify(resetButtonsState))
          newUploadedFiles.forEach(({ position }) => {
            newButtons[position].on = false
            newButtons[position].success = true
          })

          const buttonKeys = Object.keys(newButtons)
          let onButtonCount = 0
          for (let i = 0; i < buttonKeys.length; i++) {
            const button = newButtons[buttonKeys[i]]
            if (button.success) {
              button.on = false
            } else {
              if (onButtonCount === 0) {
                button.on = true
                onButtonCount++
              } else {
                button.on = false
              }
            }
          }
          setButtons(newButtons)
        } else {
          // in case removeFile hasn't been passed yet
          if (uploadedFiles && !uploadedFiles.removeFile) {
            setUploadedFiles({ removeFile: handleRemoveFile, files: uploadedFiles.files })
          }
        }
      } catch (error) {
        console.log('File Upload Error: ', error)
      } finally {
        setLoadingUploadImage(false)
      }
    },
    [buttons, setButtons, uploadedFiles, setUploadedFiles]
  )

  const hasImages = existingImages.length > 0 || (uploadedFiles && uploadedFiles.files.length > 0)

  return (
    <Layout
      idRef={idRef}
      headline={languages['en'].listYourCar.uploadVehicleImages.headline}
      currentStep={currentStep - 1}
      previousStep={previousStep}
      buttonNextClick={handleClickNext}
      wizardSteps={wizardSteps}
      goToNamedStep={goToNamedStep}
    >
      {loadingUpdate || (loadingUploadImage && <Loader />)}

      <Box sx={wrapperStyles}>
        <Box>
          <UploadImages
            initFiles={initialFilesState.files.map(({ file }) => file)}
            buttons={buttons}
            vehicleName={vehicleName}
            uploadedFiles={uploadedFiles}
            existingImages={existingImages}
            setButtons={setButtons}
            handleUploadCallback={handleUploadCallback}
          />
        </Box>
        <Box>
          {hasImages ? (
            <DisplayImages buttons={buttons} existingImages={existingImages} uploadedFiles={uploadedFiles} />
          ) : (
            <ImageInfoCarousel />
          )}
        </Box>
        <Modal isModalOpen={!!message} closeModal={() => setMessage({ success: false, message: '' })}>
          <Box sx={{ ...fullWidthField, mt: [3] }}>
            <FormMessage
              showMessage={!!message}
              message={message}
              color={`${!success ? 'statusError' : 'statusSuccess'}` as MessageColor}
            />
          </Box>
        </Modal>
      </Box>
    </Layout>
  )
}

export default UploadVehicleImages
