import React, { FC, useState, useEffect } from 'react'
import { Range, getTrackBackground } from 'react-range'
import { IMarkProps } from 'react-range/lib/types'
import { Box, BoxProps, Flex, SxStyleProp } from 'rebass'

import theme from '../../theme'

import {
  staticLabelStyles,
  limitsStyles,
  limitsLabelStyles,
  trackStyles,
  activeTrackStyles,
  thumbStyles,
  thumbLabelStyles
} from './RangeSlider.styled'

export const buttonLabel = (
  customLabel: string,
  position: string,
  value: number,
  unit: string,
  unitPlural: string,
  unitLeft: boolean
): string => {
  if (!unitPlural) {
    unitPlural = unit
  }

  if (value > 1) {
    unit = unitPlural
  }
  let label = unitLeft ? `${unit} ${value}` : `${value} ${unit}`
  if (customLabel) {
    if (position === 'top') label = `<span>${customLabel}</span>${label}`
    if (position === 'left') label = `<span>${customLabel}</span> ${label}`
    if (position === 'right') label = `${label} <span>${customLabel}</span>`
  }
  return label
}

export interface RangeSliderProps extends BoxProps {
  /** initial Values */
  initialValues: number[]
  /** Input */
  values?: number[]
  /** Range Colors */
  rangeColors?: string[]
  /** Min Value */
  min: number
  /** Max Value */
  max: number
  /** Step Value */
  step?: number
  /** on Change Handler */
  onChangeValues: (values: number[]) => void
  /** Custom Static Label*/
  customStaticLabel?: string
  /** Show Value Static Label*/
  showStaticLabel?: boolean
  /** Show Ranges */
  showLimits?: boolean
  /** Custom Thumb label*/
  customThumbLabel?: string
  /** Show Value on Thumb */
  showthumbLabel?: boolean
  /** Value above Thumb? */
  thumbLabelTop?: boolean
  /** Custom Min limit label? */
  customMinLimitLabel?: string
  /** Custom Max limit label? */
  customMaxLimitLabel?: string
  /** Unit */
  unit?: string
  /** If unit can be plural */
  unitPlural?: string
  /** Unit Left ? */
  unitLeft?: boolean
  /** Sx styles Track */
  trackSx?: SxStyleProp
  /** Sx styles Thumb */
  thumbSx?: SxStyleProp
  /** Sx styles Thumb Label*/
  thumbLabelSx?: SxStyleProp
  /** Sx styles Static Label*/
  staticLabelSx?: SxStyleProp
  /** Sx styles Limits*/
  limitsSx?: SxStyleProp
  /** Sx styles Limits Label */
  limitsLabelSx?: SxStyleProp
  /** Optional handler for adding step marks to the track */
  renderMark?: (params: { props: IMarkProps; index: number }) => React.ReactNode
  /** Is disabled */
  isDisabled?: boolean
}

const RangeSlider: FC<RangeSliderProps> = ({
  initialValues,
  rangeColors,
  min,
  max,
  step,
  unit,
  unitPlural,
  unitLeft,
  customThumbLabel,
  customStaticLabel,
  customMinLimitLabel,
  customMaxLimitLabel,
  thumbLabelTop,
  showthumbLabel,
  showStaticLabel,
  showLimits,
  onChangeValues,
  trackSx,
  thumbSx,
  thumbLabelSx,
  staticLabelSx,
  limitsSx,
  limitsLabelSx,
  renderMark,
  isDisabled,
  ...props
}) => {
  const [values, setValues] = useState(initialValues)
  const [initialValuesInternal, setInitialValuesInternal] = useState(initialValues)

  useEffect(() => {
    let hasChanged = false
    for (let i = 0; i < initialValues.length; i++) {
      if (initialValuesInternal[i] !== initialValues[i]) {
        hasChanged = true
        break
      }
    }
    if (hasChanged) {
      setValues(initialValues)
      setInitialValuesInternal(initialValues)
    }
  }, [setValues, initialValues, initialValuesInternal, setInitialValuesInternal])
  return (
    <Box {...props}>
      {showStaticLabel && (
        <Flex>
          {values.map((value, index) => (
            <React.Fragment key={value}>
              {index > 0 && (
                <Flex alignItems="center" sx={{ ...staticLabelStyles, ...staticLabelSx, mx: [1] }}>
                  &ndash;
                </Flex>
              )}
              <Box
                sx={{ ...staticLabelStyles, ...staticLabelSx }}
                dangerouslySetInnerHTML={{
                  __html: buttonLabel(customStaticLabel, 'right', value, unit, unitPlural, unitLeft)
                }}
              />
            </React.Fragment>
          ))}
        </Flex>
      )}
      <Range
        step={step}
        min={min}
        max={max}
        disabled={isDisabled}
        values={values}
        onChange={(values) => {
          setValues(values)
          onChangeValues && onChangeValues(values)
        }}
        renderTrack={({ props, children }) => {
          const { style, onMouseDown, onTouchStart, ref, ...rest } = props
          return (
            <Flex
              {...rest}
              onMouseDown={onMouseDown}
              onTouchStart={onTouchStart}
              sx={{
                ...style,
                ...trackStyles,
                ...trackSx
              }}
            >
              <Box
                ref={ref}
                sx={{
                  ...activeTrackStyles,
                  background: getTrackBackground({
                    values: values,
                    colors: rangeColors,
                    min,
                    max
                  })
                }}
              >
                {children}
              </Box>
            </Flex>
          )
        }}
        renderThumb={({ props, index }) => {
          const { style, ref, ...rest } = props
          let left
          if (ref && ref.current) {
            const thumbRect = ref.current.getBoundingClientRect()
            const thumbLabelHalfWidth = ref.current.firstChild.offsetWidth / 2
            const thumbPosition = thumbRect.left + thumbRect.width / 2
            if (thumbPosition - thumbLabelHalfWidth < 30) {
              left = thumbLabelHalfWidth + 30 - thumbPosition
            }
            if (window.innerWidth - (thumbPosition + thumbLabelHalfWidth) < 30) {
              left = window.innerWidth - (thumbPosition + thumbLabelHalfWidth) - 30
            }
          }
          return (
            <Box
              ref={ref}
              {...rest}
              sx={{
                ...style,
                ...(thumbStyles as SxStyleProp),
                ...thumbSx
              }}
            >
              <Box
                sx={{
                  ...thumbLabelStyles(showthumbLabel, thumbLabelTop, left),
                  ...thumbLabelSx
                }}
                dangerouslySetInnerHTML={{
                  __html: buttonLabel(customThumbLabel, 'top', values[index], unit, unitPlural, unitLeft)
                }}
              />
            </Box>
          )
        }}
        renderMark={renderMark}
      />
      {showLimits && (
        <Flex sx={{ ...limitsStyles, ...limitsSx }}>
          <Box
            sx={{ ...limitsLabelStyles, ...limitsLabelSx }}
            dangerouslySetInnerHTML={{
              __html: buttonLabel(customMinLimitLabel, 'right', min, unit, unitPlural, unitLeft)
            }}
          />
          <Box
            sx={{ ...limitsLabelStyles, ...limitsLabelSx }}
            dangerouslySetInnerHTML={{
              __html: buttonLabel(customMaxLimitLabel, 'left', max, unit, unitPlural, unitLeft)
            }}
          />
        </Flex>
      )}
    </Box>
  )
}

RangeSlider.defaultProps = {
  initialValues: [50],
  rangeColors: [theme.colors.primary, theme.colors.bgLight],
  min: 0,
  max: 100,
  step: 1,
  onChangeValues: () => null,
  customThumbLabel: '',
  customStaticLabel: '',
  customMinLimitLabel: '',
  customMaxLimitLabel: '',
  showLimits: true,
  showStaticLabel: true,
  showthumbLabel: false,
  thumbLabelTop: false,
  unitPlural: '',
  unit: '',
  unitLeft: true,
  trackSx: {},
  thumbSx: {},
  thumbLabelSx: {},
  staticLabelSx: {},
  limitsSx: {},
  limitsLabelSx: {},
  renderMark: () => null,
  isDisabled: false
}

export default RangeSlider
