import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { ZA } from '../constants'

const parseMobileNumber = (number: string, countryCode?: CountryCode) => {
  if (!countryCode) countryCode = ZA
  try {
    return parsePhoneNumber(number, countryCode)
  } catch (error) {
    return undefined
  }
}

export { parseMobileNumber }
