import { SxStyleProp } from 'rebass'

import theme from '../../../theme'

export const wrapperStyles: SxStyleProp = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  width: '100%'
}
