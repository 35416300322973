import { Icon, Text } from '@rmr/components'
import React from 'react'
import { Flex } from 'rebass'
import { languages } from '../common/i18n'

interface WhatsAppNoticeProps {
  label?: string
}
const WhatsAppNotice = ({ label = languages.en.auth.signup.whatsAppNotice }: WhatsAppNoticeProps) => {
  return (
    <Flex sx={{ mb: 2 }}>
      <Icon name="whatsapp" fontAwesomeIconProps={{ size: 'lg' }} />
      <Text ml={3} size="tiny">
        {languages.en.auth.signup.whatsAppNotice}
      </Text>
    </Flex>
  )
}

export default WhatsAppNotice
