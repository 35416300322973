import { WizardStep } from '@rmr/components'
import React, { useRef } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'

import { languages } from '../../../common/i18n'
import Layout from '../Layout'
import { VehicleState } from '../ListYourCar'
import RentalConditionsForm from './RentalConditionsForm'

interface RentalConditionsProps extends Partial<StepWizardChildProps> {
  idRef: string
  vehicle: VehicleState
  onSetVehicle: React.Dispatch<React.SetStateAction<VehicleState>>
  wizardSteps: WizardStep[]
}
const RentalConditions = ({
  idRef,
  currentStep,
  previousStep,
  nextStep,
  goToNamedStep,
  vehicle,
  onSetVehicle,
  wizardSteps
}: RentalConditionsProps) => {
  const formButtonRef = useRef<HTMLButtonElement | null>()

  const handleClickNext = () => {
    if (formButtonRef) {
      formButtonRef.current.click()
    }
  }

  return (
    <Layout
      headline={languages['en'].listYourCar.rentalConditions.headline}
      currentStep={currentStep - 1}
      previousStep={previousStep}
      buttonNextClick={handleClickNext}
      idRef={idRef}
      wizardSteps={wizardSteps}
      goToNamedStep={goToNamedStep}
    >
      <RentalConditionsForm
        ref={formButtonRef}
        idRef={idRef}
        vehicle={vehicle}
        onSetVehicle={onSetVehicle}
        nextStep={nextStep}
      />
    </Layout>
  )
}

export default RentalConditions
