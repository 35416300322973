import React from 'react'
import { isMobile } from 'react-device-detect'
import { Box, BoxProps } from 'rebass'
import IconWithContent from '../IconWithContent'
import { wrapperStyles } from './WhatsappShare.styled'

export interface WhatsappShareProps extends BoxProps {
  /** Label of component */
  label?: string
  /** Icon of component */
  icon?: string
  /** Text to share */
  copyText: string
}

const WhatsappShare = ({ label, icon, copyText, ...props }: WhatsappShareProps) => {
  const url = `https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?text=${encodeURIComponent(copyText)}`
  return (
    <Box {...props}>
      <Box as="a" sx={wrapperStyles} href={url} target="_blank" rel="noreferrer">
        <IconWithContent headline={label} icon={icon} iconPosition="top" iconSx={{ fontSize: '25px' }} />
      </Box>
    </Box>
  )
}
WhatsappShare.defaultProps = {
  label: 'WhatsApp',
  icon: 'whatsappOutline',
  copyText: 'copy to text'
}

export default WhatsappShare
