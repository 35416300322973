import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { validationMessages } from '../i18n'
import { CreateAccountInput, CreateAccountMutation, useCreateAccountMutation } from '../lib/generated/generated'
import { ApprovalIntention } from '../types'
import { useSubmitResult } from './useSubmitResult'

interface useRegistrationReferralFormArgs {
  onSuccess: (response: CreateAccountMutation) => void
  onError: (e: Error) => void
}

const createAccountFormSchema = yup.object().shape({
  email: yup.string().required(validationMessages.en.required).email(validationMessages.en.email),
  password: yup
    .string()
    .required(validationMessages.en.required)
    .test('password', validationMessages.en.passwordLength, (val) => val && val.length >= 6),
  terms: yup
    .bool()
    .required(validationMessages.en.required)
    .test('terms', validationMessages.en.termsAndConditions, (value) => {
      return !!value
    }),
  referralCode: yup.string()
})

export function useRegistrationReferralForm({ onSuccess, onError }: useRegistrationReferralFormArgs) {
  const [loadingUploadImage, setLoadingUploadImage] = useState(false)
  const [submitResult, onSubmitSuccess, onSubmitError] = useSubmitResult()

  const form = useForm({
    resolver: yupResolver(createAccountFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      terms: false,
      referralCode: ''
    }
  })

  const [createAccountMutation, { loading }] = useCreateAccountMutation()

  const onSubmit = useCallback(
    async (data: CreateAccountInput & { avatarReady: boolean; intention: ApprovalIntention[] }) => {
      try {
        const input = data
        const response = await createAccountMutation({
          variables: { input }
        })
        onSuccess(response.data)
        const { createAccount } = response.data
        if (createAccount.__typename === 'UserRegisterSuccess') {
          onSubmitSuccess()
        } else if (createAccount.message) {
          onSubmitError('error', createAccount.message)
        } else {
          onSubmitError()
        }
      } catch (err) {
        onError(err)
        onSubmitError('error', err.message)
      } finally {
        setLoadingUploadImage(false)
      }
    },
    [createAccountMutation, onSuccess, onSubmitSuccess, onSubmitError, onError]
  )

  return {
    ...form,
    onSubmit: form.handleSubmit(onSubmit),
    submitting: loading || loadingUploadImage,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  }
}
