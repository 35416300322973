import { SxStyleProp } from 'rebass'

import { theme } from '@rmr/components'

export const headlineStyles: SxStyleProp = {
  alignSelf: 'stretch'
}

export const radioWrapperStyles: SxStyleProp = {
  maxWidth: [600]
}

export const rangeSliderThumb: SxStyleProp = {
  height: [80],
  width: [160, 180],
  bg: 'baseWhite',
  boxShadow: `0 0 30px 1px ${theme.colors.bgShadow}`,
  top: ['-110px'],
  textAlign: 'center',
  borderRadius: '8px',
  span: {
    color: 'textBlack',
    fontSize: ['small']
  }
}

export const limitsLabelStyles: SxStyleProp = {
  color: 'textBlack',
  fontWeight: 'extraBold',
  fontSize: ['large'],
  span: {
    color: 'textLight',
    fontWeight: 'medium',
    fontSize: ['small']
  }
}
