import { Button, Checkbox } from '@rmr/components'
import React, { useState, useRef, useMemo, useCallback } from 'react'
import { Box, Flex, SxStyleProp, BoxProps } from 'rebass'

import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { SelectOption } from '../../Form'
import Icon from '../../Icon'
import Text from '../../Text'
import FilterButton from '../FilterButton'
import FilterDropdown from '../FilterDropdown'

import theme from '../../../theme'

import {
  wrapperStyles,
  contentStyles,
  closeIconStyles,
  applyButtonStyles,
  selectButtonStyles,
  buttonStyles
} from './MultipleFilter.styled'

export type ActiveOptions = {
  [key: string]: { value: number | string; active: boolean }
}
const extractValues = (activeOptions: ActiveOptions) => {
  const values: string[] = []
  if (activeOptions) {
    Object.keys(activeOptions).forEach(
      (option) => activeOptions[option].active && values.push(`${activeOptions[option].value}`)
    )
  }
  return values
}

export type SelectOptionExtended = SelectOption & {
  icon?: string
  description?: string
}

export type MultipleFilterAction = (values: string[]) => void

export interface MultipleFilterProps extends BoxProps {
  /** Initial Values */
  initialValues?: (string | number)[]
  /** Filter Name */
  filterName: string
  /** Options to filter/sort from */
  options: SelectOptionExtended[]
  /** Callback with Selected option */
  onSet: MultipleFilterAction
  /** Within a Box */
  groupMode?: boolean
  /** checkbox Mode */
  checkBoxMode?: boolean
  /** selectMode */
  selectMode?: boolean
  /** Show Button? */
  showButton?: boolean
  /** Sx prop styles */
  sx?: SxStyleProp
  /** Dropdown Sx prop styles */
  dropdownSx?: SxStyleProp
  /** Button Sx prop styles */
  buttonSx?: SxStyleProp
  /** Button Text */
  buttonText?: string
}

const MultipleFilter = ({
  initialValues = [],
  filterName,
  options,
  onSet,
  groupMode,
  checkBoxMode,
  selectMode,
  sx,
  showButton,
  buttonSx,
  dropdownSx,
  buttonText,
  ...props
}: MultipleFilterProps) => {
  const noOptionsState: ActiveOptions = useMemo(
    () =>
      options.reduce((acc, option) => {
        acc[option.label] = { active: false, value: option.value }
        return acc
      }, {}),
    [options]
  )
  const initialOptionsState: ActiveOptions = useMemo(
    () =>
      options.reduce((acc, option) => {
        acc[option.label] = { active: !!initialValues.find((e) => e === option.value), value: option.value }
        return acc
      }, {}),
    [options, initialValues]
  )

  const [activeOptions, setActiveOptions] = useState(initialOptionsState)
  const [open, setOpen] = useState(false)
  const filterRef = useRef(null)
  const buttonRef = useRef(null)

  const applyFilter = () => {
    onSet(extractValues(activeOptions))
    setOpen(false)
  }

  useOnClickOutside(filterRef, () => open && applyFilter())

  const toggleOption = useCallback(
    (option: string, value: boolean) => {
      const newActiveOptions = JSON.parse(JSON.stringify(activeOptions))
      newActiveOptions[option].active = value
      setActiveOptions(newActiveOptions)
    },
    [activeOptions, setActiveOptions]
  )
  const applyCheckboxFilter = useCallback(
    (option: string, value: boolean) => {
      const newActiveOptions = JSON.parse(JSON.stringify(activeOptions))
      newActiveOptions[option].active = value
      setActiveOptions(newActiveOptions)
      onSet(extractValues(newActiveOptions))
    },
    [activeOptions, onSet]
  )

  const selectedOptions = (options: ActiveOptions) => {
    let count = 0
    const optionsArray = Object.keys(options)
    for (let i = 0; i < optionsArray.length; i++) {
      if (options[optionsArray[i]].active) {
        count++
      }
    }
    return count
  }

  return (
    <Box ref={filterRef}>
      {checkBoxMode ? (
        <React.Fragment>
          <Text fontWeight="extraBold" mb={1}>
            {filterName}
          </Text>
          <Flex sx={{ flexWrap: 'wrap' }}>
            {options.map((option, index) => {
              return (
                <Checkbox
                  key={index}
                  name={option.label}
                  rebassCheckboxProps={{
                    checked: activeOptions[option.label] && activeOptions[option.label].active,
                    onChange: (e) => {
                      applyCheckboxFilter(option.label, e.target.checked)
                    }
                  }}
                  innerSx={{ mr: 0 }}
                  labelSx={{ fontSize: ['tiny', 'small'], ml: 1 }}
                  mr={2}
                >
                  {option.label}
                </Checkbox>
              )
            })}
          </Flex>
        </React.Fragment>
      ) : (
        <Box sx={{ ...wrapperStyles, ...sx, zIndex: open ? theme.zIndex.modal + 1 : undefined }} {...props}>
          <FilterButton
            ref={buttonRef}
            filterName={filterName}
            activeLabel={selectedOptions(activeOptions) > 0 ? `${selectedOptions(activeOptions)}` : undefined}
            onClickButton={() => setOpen((open) => !open)}
            onClickCloseButton={(e) => {
              setActiveOptions(noOptionsState)
              onSet([])
              setOpen(false)
              e.stopPropagation()
            }}
            open={open}
            buttonSx={{ ...buttonStyles(selectMode, groupMode), ...buttonSx }}
            groupMode={groupMode}
          />
          <FilterDropdown
            isOpen={open}
            groupMode={groupMode}
            sx={{ boxShadow: selectMode ? 'none' : undefined, ...dropdownSx }}
            buttonRef={buttonRef}
          >
            <Box sx={{ maxHeight: [200], overflow: ['auto'] }}>
              {options.map((option, index) => (
                <Flex key={index} justifyContent="space-between" width="100%">
                  <Text
                    size="tiny"
                    sx={contentStyles(activeOptions[option.label] && activeOptions[option.label].active)}
                    onClick={() => toggleOption(option.label, true)}
                  >
                    {option.icon && (
                      <Icon name={option.icon} width={[50]} textAlign="center" fontAwesomeIconProps={{ size: '2x' }} />
                    )}
                    {option.label}
                  </Text>
                  {activeOptions[option.label] && activeOptions[option.label].active && (
                    <Flex sx={closeIconStyles}>
                      <Icon name="close" color="bgWhite" onClick={() => toggleOption(option.label, false)} />
                    </Flex>
                  )}
                </Flex>
              ))}
            </Box>
            {showButton && (
              <Box p={[1]}>
                <Button variant="primary" sx={applyButtonStyles} onClick={applyFilter}>
                  <Box as="div">{buttonText}</Box>
                </Button>
              </Box>
            )}
          </FilterDropdown>
        </Box>
      )}
    </Box>
  )
}

MultipleFilter.defaultProps = {
  filterName: '',
  options: [],
  groupMode: false,
  sx: {},
  dropdownSx: {},
  showButton: true,
  buttonSx: {},
  buttonText: 'Apply'
}

export default MultipleFilter
