import React, { FC } from 'react'
import { Section, VehicleCardCarousel, Car, ImageWrapper } from '@rmr/components'
import { useGetVehiclesQuery } from '@rmr/controllers'
import { FEATURED_VEHICLE_SEARCH_LIMIT } from '../common/constants'
interface FeaturedVehiclesProps {
  headline?: string
  vehicleIds?: [VehicleProps]
}

interface VehicleProps {
  id?: string
}

const FeaturedVehiclesWrapper: FC<FeaturedVehiclesProps> = ({ headline, ...props }) => {
  const { data, loading } = useGetVehiclesQuery({
    variables: {
      input: {},
      first: FEATURED_VEHICLE_SEARCH_LIMIT
    }
  })

  // if (loading || !data) return null

  const vehicles: Car[] =
    loading || !data
      ? Array(FEATURED_VEHICLE_SEARCH_LIMIT)
          .fill(1)
          .map((_, index) => ({
            id: `${index}`,
            price: '',
            carMake: '',
            carModel: '',
            totalReviews: 0,
            totalTrips: -1,
            rating: -1,
            slides: [<ImageWrapper src={''} alt="placeholder" />]
          }))
      : data.searchVehicles.edges.map(({ node }) => ({
          id: node.id,
          carMake: '',
          // Not working as of 22/07/2021
          // carMake: node.details.make,
          carModel: node.name,
          price: node.pricePerDay.formattedNoDecimals,
          totalReviews: node.stats.reviews,
          totalTrips: node.stats.trips,
          rating: Math.ceil(node.stats.ratings),
          slides: node.images.slice(0, 5).map((img) => <ImageWrapper src={img.file.url} alt={node.name} />),
          href: `/app/listing/${node.id}/`
        }))

  return (
    <Section headline={headline}>
      <VehicleCardCarousel cars={vehicles} pb={[2, 4]} />
    </Section>
  )
}

export default FeaturedVehiclesWrapper
