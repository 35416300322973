import { DocumentType } from '../lib/generated/generated'

const userDocTypeTitles = {
  [DocumentType.Passport]: 'Passport',
  [DocumentType.IdBook]: 'ID Book',
  [DocumentType.IdCard]: 'ID Card',
  [DocumentType.Selfie]: 'Selfie',
  [DocumentType.DriversLicense]: "Driver's License",
  [DocumentType.ProofOfAddress]: 'Proof of Address',
  [DocumentType.OtherDocument]: 'Other'
}

export { userDocTypeTitles }
