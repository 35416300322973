import React, { FC } from 'react'
import { Container } from '../Grid'
import Text from '../Text'
import { Box } from 'rebass'
import {
  StyledBannerBrand,
  StyledContentBrand,
  StyledImageBrand,
  StyledHeaderBrand,
  StyledHeaderLineBrand
} from './Banner.styled'
import { BannerProps } from './Banner'
import theme from '../../theme'

const BannerBrand: FC<BannerProps> = ({
  variant,
  headline,
  subtitle,
  children,
  image,
  bgColor,
  headerLineColor,
  headlineColor,
  textColor,
  sx,
  ...props
}) => {
  return (
    <StyledBannerBrand {...props} py={[4, 6]} bg={bgColor} sx={{ ...sx }}>
      <StyledContentBrand>
        <Container>
          <StyledImageBrand mx={['auto']}>{image}</StyledImageBrand>

          {headline && (
            <>
              <StyledHeaderLineBrand bg={headerLineColor} my={[1, 2]} mx="auto" />
              <StyledHeaderBrand mb={[1]}>
                <Text size="body" fontWeight={theme.fontWeights.extraBold} textAlign="center" color={textColor}>
                  {headline}
                </Text>
              </StyledHeaderBrand>
            </>
          )}

          {children && <Box color={textColor}>{children}</Box>}
        </Container>
      </StyledContentBrand>
    </StyledBannerBrand>
  )
}

BannerBrand.defaultProps = {
  variant: 'center',
  headline: '',
  subtitle: '',
  children: null,
  image: null,
  sx: {}
}

export default BannerBrand
