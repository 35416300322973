import React from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import { BoxProps, Flex, SxStyleProp } from 'rebass'

import languages from '../../../i18n'
import Button from '../../Button'
import DropZoneArea from '../DropZoneArea'
import FilePreview from '../FilePreview'
import { FileExtended } from '../types'

export interface UploadComponentVariantProps extends BoxProps {
  /** Maximum number of files */
  maxFiles?: number
  /** Maximun file size (in bytes) */
  maxSize?: number
  /** Files Selected */
  files?: FileExtended[]
  /** DropZone Root Props */
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps
  /** DropZone Root Props */
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T
  /** Drag Zone Active */
  isDragActive: boolean
  /** Drag Zone Opevn file browser */
  openBroser: () => void
  /** Remove file handler */
  handleRemoveFile: (index: number) => void
  /** Show Browse Button */
  showButton?: boolean
  /** Preview Files? */
  previewFiles?: boolean
  /** Error message */
  error?: string
  /** Sx Styles */
  sx?: SxStyleProp
  /** Error message */
  description?: string
}

const UploadComponentVertical = ({
  maxFiles,
  maxSize,
  files,
  getRootProps,
  getInputProps,
  isDragActive,
  openBroser,
  handleRemoveFile,
  showButton,
  previewFiles,
  sx,
  error,
  success,
  ...props
}: UploadComponentVariantProps & { success: boolean }) => {
  return (
    <Flex {...props} sx={{ flexDirection: ['column'], flex: '1 0 auto', ...sx }}>
      {files && files.length < maxFiles && (
        <Flex sx={{ flexDirection: ['column'], flex: '1 0 auto' }}>
          <DropZoneArea
            variant="vertical"
            maxSize={maxSize}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragActive={isDragActive}
          />
          {showButton && (
            <Button variant="black" onClick={openBroser} fullWidth mt={[2]}>
              {languages['en'].browseFiles}
            </Button>
          )}
        </Flex>
      )}
      <FilePreview
        files={files}
        maxFiles={maxFiles}
        maxSize={maxSize}
        onClose={handleRemoveFile}
        previewFiles={previewFiles}
        error={success ? '' : error}
      />
    </Flex>
  )
}

export default UploadComponentVertical
