import React, { FC } from 'react'
import { SectionSplit } from '@rmr/components'
import { graphql } from 'gatsby'

interface SectionSplitWrapperProps {
  /** Headline for the Section */
  headline?: string
  /** background color acting as color scheme selector */
  backgroundColor?: string
  /** Modules */
  splitsectionContent?: React.ReactNode
}

const SectionSplitWrapper: FC<SectionSplitWrapperProps> = ({ headline, backgroundColor, splitsectionContent }) => {
  return <SectionSplit headline={headline} colorScheme={backgroundColor} modules={splitsectionContent} />
}

export default SectionSplitWrapper

export const SectionSplitFields = graphql`
  fragment SectionSplitFields on WpPage_Layoutsettings_Layout_SectionSplit {
    backgroundColor
    headline
    splitsectionContent {
      actions {
        actionType
        url {
          target
          title
          url
        }
      }
      content
      headline
      actions {
        actionType
        url {
          target
          title
          url
        }
      }
    }
  }
`
