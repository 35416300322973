import theme from '../../theme/theme'

export const vehicleCardStyles = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'borderBase',
  borderRadius: `${theme.borderRadius.small}`,
  p: {
    lineHeight: 1.17
  },
  display: 'flex !important',
  flexDirection: 'column'
}

export const carouselStyles = {
  width: '100%'
}

export const headerImagestyles = {
  width: '100%'
}
