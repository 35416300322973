/** Mutates the date */
function incrementDate(date: Date) {
  date.setDate(date.getDate() + 1)
}

function getDatesInRange(startDate: Date, endDate: Date, inclusive = true): Date[] {
  if (!startDate && !endDate) return []
  if (!endDate) return [startDate]

  const dates = []
  const currentDate = new Date(startDate)
  if (!inclusive) incrementDate(currentDate)
  while (inclusive ? currentDate <= endDate : currentDate < endDate) {
    dates.push(new Date(currentDate))
    incrementDate(currentDate)
  }
  return dates
}

export { getDatesInRange }
