interface Coords {
  lat: number
  lng: number
}
export const getMapBounds = (points: Coords[]): { ne: Coords; sw: Coords } => {
  const ne = { lat: -180, lng: -180 }
  const sw = { lat: 180, lng: 180 }
  points.forEach(({ lat, lng }) => {
    if (lat > ne.lat) ne.lat = lat
    if (lng > ne.lng) ne.lng = lng
    if (lat < sw.lat) sw.lat = lat
    if (lng < sw.lng) sw.lng = lng
  })
  return { ne, sw }
}
