import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import theme from '../../theme'

// Shared Styles
export const StyledHeaderLine = styled(Box)`
  height: 4px;
  width: 6rem;
  background: ${theme.colors.primary};
`

// Banner Center
export const StyledBannerCenter = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledContentCenter = styled(Box)`
  position: relative;
  text-align: center;
`
export const StyledHeaderCenter = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// BannerSplit
export const StyledBannerSplit = styled(Flex)`
  position: relative;
`

export const StyledImageSplit = styled(Flex)`
  flex: 0 0 auto;
`

export const StyledContentSplit = styled(Flex)`
  text-align: left;
`
export const StyledHeaderSplit = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

// BannerBrand
export const StyledBannerBrand = styled(Flex)`

  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledImageBrand = styled(Box)`
  width: 14rem;
  height: auto;
  max-width: 100%;
`

export const StyledContentBrand = styled(Flex)`
  text-align: center;
`
export const StyledHeaderBrand = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const StyledHeaderLineBrand = styled(Box)`
  height: 4px;
  width: 3rem;
`

// Banner Icon

export const StyledBannerIcon = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const StyledHeaderLineIcon = styled(Box)`
  height: 4px;
  width: 6rem;
`