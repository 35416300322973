import React, { FC } from 'react'
import { SxStyleProp } from 'rebass'
import theme from '../../theme'
import { StyledCard, StyledHeading } from './CardOverlay.styled'

export interface CardOverlayProps {
  /** Headline of Profile Summary */
  headline?: string
  /** Image URL */
  imageUrl?: string
  /** Should a hover effect be applied */
  hasHover?: boolean
  /** Sx Props */
  sx?: SxStyleProp
}

const CardOverlay: FC<CardOverlayProps> = ({ headline, imageUrl, hasHover, sx, ...props }) => {
  return (
    <StyledCard imageUrl={imageUrl} hasHover={hasHover} {...props} py={[10]} px={[2]} sx={{ ...sx }}>
      <StyledHeading
        as="span"
        fontWeight={theme.fontWeights.extraBold}
        textAlign={'center'}
        size={'body'}
        color={theme.colors.textWhite}
        lineHeight={1}
        hasHover={hasHover}
      >
        {headline}
      </StyledHeading>
    </StyledCard>
  )
}

CardOverlay.defaultProps = {
  headline: '',
  imageUrl: '',
  hasHover: true
}

export default CardOverlay
