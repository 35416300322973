import styled from '@emotion/styled'
import { Box } from 'rebass'
import theme from '../../theme'

export const StyledImageBorder = styled(Box)`
  border-radius: ${theme.borderRadius.circle};
  overflow: hidden;
  border-color: ${theme.colors.primary};
  border-style: solid;
  position: relative;
`

export const StyledImage = styled(Box)`
  border-radius: ${theme.borderRadius.circle};
  overflow: hidden;
  border-color: ${theme.colors.baseWhite};
  border-style: solid;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`
