import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Box, Image, Flex } from 'rebass'
import { Container, Content, Heading, Icon, Link, Text, theme } from '@rmr/components'
import { Helmet } from 'react-helmet'
import Main from '../components/Main'
import NoticeWrapper from '../components/NoticeWrapper'
import styled from '@emotion/styled'
import SocialSharingWrapper from '../modules/SocialSharingWrapper'
import { extractMetaTags } from '../utils/extractMetaTags'
import { useLocation } from '@reach/router'

import GridPostsWrapper from '../modules/GridPostsWrapper'

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

interface WpPostProps {
  data: {
    wpPost: GatsbyTypes.WpPost
    wp: GatsbyTypes.Wp
  }
  path?: string
  pageContext?: any
}
const WpPost = ({ data, pageContext }: WpPostProps) => {
  const colNum = [1, 2, 2, 3]
  const { wpPost, wp } = data
  const { content, title, seo, date, featuredImage } = wpPost
  const extraMeta = extractMetaTags({
    seoDefaults: wp.constantSettings.seo_defaults,
    seoIncoming: wpPost.seo
  })
  const categoryName = pageContext.category
  const categorySlug = pageContext.categorySlug
  const { pathname } = useLocation()
  const baseUrl = wp.constantSettings.seo_defaults.baseUrl

  return (
    <Main darkHeader renderNotice={() => <NoticeWrapper />}>
      <Helmet>
        {seo?.fullHead ? parse(seo.fullHead) : null}
        {extraMeta}
      </Helmet>
      <Helmet>{seo?.fullHead ? parse(seo.fullHead) : null}</Helmet>
      <Box as="section" py={[6, 10]} sx={{ mx: 'auto', px: '15px', maxWidth: '1440px' }}>
        <Box
          sx={{
            mx: 'auto',
            marginBottom: [6, 10]
          }}
        >
          <Flex alignItems="center" justifyContent={'center'}>
            <Link to={`/blog`}>
              <Text size="small" style={{ textTransform: 'uppercase' }}>
                {'Blog'}
              </Text>
            </Link>
            {categoryName && categoryName !== 'Uncategorized' ? (
              <>
                <Box mx={[1]}>
                  <Icon name={`arrowRight`} />
                </Box>

                <Link to={`/blog/${categorySlug}/`}>
                  <Text color={theme.colors.primary} size="small" style={{ textTransform: 'uppercase' }}>
                    {categoryName}
                  </Text>
                </Link>
              </>
            ) : null}
          </Flex>

          {title && (
            <Heading fontWeight="boldest" size="h1" textAlign="center">
              {title}
            </Heading>
          )}
          {date && (
            <Text textAlign="center" size="tiny">
              {date}
            </Text>
          )}
        </Box>
        {featuredImage && (
          <Box mb={[4]}>
            <StyledImage src={featuredImage.node.sourceUrl} />
          </Box>
        )}
        {content && (
          <Container sx={{ padding: '80px 15px' }}>
            <Flex sx={{ width: '100%' }} flexDirection={['column', 'column', 'row']}>
              <Flex width={[1, 1, 2 / 12]} flex="0 1 auto" order={[1, 1, 0]}>
                <div style={{ position: 'sticky', top: 100, width: '100%' }}>
                  <SocialSharingWrapper url={`${baseUrl}${pathname}`} />
                </div>
              </Flex>

              <Flex className="post-content" width={[1, 1, 8 / 12]} flex="0 1 auto" order={[0, 0, 1]}>
                <Content className="wp-content">{parse(content)}</Content>
              </Flex>
            </Flex>
          </Container>
        )}
        {categoryName && categoryName !== 'Uncategorized' ? (
          <Box>
            <Heading sx={{ paddingBottom: '1rem' }} fontWeight="boldest" size="h3" textAlign="center">
              {'Similar posts'}
            </Heading>
            <GridPostsWrapper category={categorySlug} />
          </Box>
        ) : null}
      </Box>
    </Main>
  )
}

export default WpPost

export const query = graphql`
  query WpPostQuery($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      id
      slug
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        fullHead
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterImage {
          sourceUrl
        }
        twitterTitle
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
    }
    wp {
      constantSettings {
        seo_defaults {
          siteName
          title
          shortTitle
          description
          baseUrl
          image {
            sourceUrl
          }
          author
          keywords
          ogType
        }
      }
    }
  }
`
