import { VehicleFuel } from '@rmr/controllers'

const languages = {
  en: {
    auth: {
      haveAccount: 'Already have an account?'
    },
    tc: {
      acceptLabel: 'Accept Terms and Conditions*',
      text: 'By accepting the terms and conditions, you acknowledge that you have read and understood the <a href="/policies/terms-of-service/" target="_blank">Terms of Service</a> as well as the various <a href="/policies/" target="_blank">Policies & Guidelines</a> and are bound by them.'
    },
    reviews: 'Reviews',
    trips: 'Trips',
    noTrips: 'New',
    noRating: '',
    from: 'From',
    invalidPhoneNumber: 'Please enter a valid cell phone number',
    required: 'Field is required',
    dragDrop: 'Drag & drop here, or click to select files',
    tooManyFiles: 'Maximum number of files',
    invalidFileType: 'Wrong file type',
    fileTypesAccepted: 'File types accepted',
    browseFiles: 'Browse files',
    maxSize: 'Maximum file size allowed',
    enums: {
      VehicleFuel: {
        [VehicleFuel.Diesel]: 'Diesel',
        [VehicleFuel.Petrol]: 'Petrol',
        [VehicleFuel.HybridDiesel]: 'Hybrid Diesel',
        [VehicleFuel.HybridPetrol]: 'Hybrid Petrol',
        [VehicleFuel.Electric]: 'Electric'
      }
    },
    searchBar: {
      fromLabel: 'From',
      toLabel: 'To'
    }
  }
}

export type Language = keyof typeof languages

export default languages
