import { SelectOption } from '@rmr/components'
import { useMemo } from 'react'
import {
  useGetVehicleCategoriesQuery,
  useGetVehicleFeaturesQuery,
  useGetVehicleYearsQuery
} from '../lib/generated/generated'

export const useSearchVehiclesFilterOptions = () => {
  const { data: categoriesData, loading: loadingCategories } = useGetVehicleCategoriesQuery()
  const { data: featuresData, loading: loadingFeatures } = useGetVehicleFeaturesQuery()
  const { data: yearsData, loading: loadingYears } = useGetVehicleYearsQuery()

  const [yearMin, yearMax]: number[] = useMemo(() => {
    const currentDate = new Date()
    let yearMin = 2000,
      yearMax = currentDate.getFullYear()
    if (yearsData && yearsData.vehicleYears) {
      const yearsArray = yearsData.vehicleYears.map(({ year }) => +year).sort((a, b) => a - b)
      if (Array.isArray(yearsArray) && yearsArray.length > 0) {
        yearMin = yearsArray[0]
      }
      if (Array.isArray(yearsArray) && yearsArray.length > 1) {
        yearMax = yearsArray[yearsArray.length - 1]
      }
      return [yearMin, yearMax]
    } else {
      return [yearMin, yearMax]
    }
  }, [yearsData])

  const categoryOptions: SelectOption[] | [] = useMemo(() => {
    if (categoriesData && categoriesData.vehicleCategories) {
      return categoriesData.vehicleCategories.map(({ id, name }) => ({ value: id, label: name }))
    } else {
      return []
    }
  }, [categoriesData])

  const featureOptions: SelectOption[] | [] = useMemo(() => {
    if (featuresData && featuresData.vehicleFeatures) {
      return featuresData.vehicleFeatures.map(({ id, name, icon }) => ({ value: id, label: name, icon }))
    } else {
      return []
    }
  }, [featuresData])

  const loading = loadingCategories || loadingFeatures || loadingYears

  return {
    categoryOptions,
    featureOptions,
    yearMin,
    yearMax,
    loading
  }
}
