import React, { useState, FocusEvent } from 'react'
import { Box, Flex } from 'rebass'

import { Modal, Heading, Text, Badge, Button, Label, Input, TermsAndConditions, Loader } from '@rmr/components'
import { languages } from '../../common/i18n'
import Repeater from '../Repeater'
import { useReferFriendsMutation } from '@rmr/controllers'

enum Status {
  error,
  success,
  idle
}

interface ReferByEmailProps {
  isOpen: boolean
  onClose: () => void
}

const ReferByEmail = ({ isOpen, onClose }: ReferByEmailProps) => {
  const [status, setStatus] = useState(Status.idle)
  const [terms, setTerms] = useState(false)
  const [error, setError] = useState('')
  const [errors, setErrors] = useState([''])
  const [emails, setEmails] = useState([''])

  const [referFriends, { loading }] = useReferFriendsMutation()

  const onChange = (index: number) => (event: FocusEvent<HTMLInputElement>) => {
    setEmails([...emails.slice(0, index), event.target.value, ...emails.slice(index + 1)])
  }

  const renderItem = (item: string, index: number) => {
    return <Input name="email" rebassInputProps={{ defaultValue: emails[index], onBlur: onChange(index) }} />
  }
  const addEmail = () => {
    setError('')
    setEmails([...emails, ''])
    setErrors([...errors, ''])
  }
  const removeEmail = (index: number) => {
    setError('')
    setEmails([...emails.slice(0, index), ...emails.slice(index + 1)])
    setErrors([...errors.slice(0, index), ...errors.slice(index + 1)])
  }

  const reset = () => {
    setStatus(Status.idle)
    setError('')
    setErrors([''])
    setEmails([''])
    setTerms(false)
  }

  const onSendEmail = async () => {
    let newErrors = [...errors]
    let newError = error
    emails.forEach((email, index) => {
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email)) {
        newErrors = [
          ...errors.slice(0, index),
          languages.en.referFriendEmail.invalidEmailError,
          ...errors.slice(index + 1)
        ]
      } else {
        if (errors[index]) {
          newErrors = [...errors.slice(0, index), '', ...errors.slice(index + 1)]
        }
      }
    })

    if (!terms) {
      newError = languages.en.referFriendEmail.termsError
    }
    if (newError || newErrors.filter((e) => !!e).length > 0) {
      setError(newError)
      setErrors(newErrors)
      return
    }
    try {
      const result = await referFriends({ variables: { input: { emails } } })
      if (result) {
        setStatus(Status.success)
        setTimeout(() => {
          reset()
          onClose()
        }, 2000)
      } else {
        setStatus(Status.error)
      }
    } catch (error) {
      console.log({ error })
      setStatus(Status.error)
    }
  }
  return (
    <Modal isModalOpen={isOpen} closeModal={onClose}>
      {loading && <Loader />}
      <Flex alignItems="center" justifyContent="center" maxWidth={[850]} p={[0, 4, null, 6]}>
        <Box maxWidth={[500]}>
          <Badge
            variant="circle"
            size={[125, 125]}
            color="primary"
            icon="envelope"
            contentColor="white"
            mx="auto"
            mb={[4]}
          />
          <Heading size="h5" color="textBlack" textAlign="center" mb={2}>
            {languages['en'].referFriendEmail.title}
          </Heading>
          <Text color="textLight" textAlign="center" mb={2}>
            {languages['en'].referFriendEmail.subtitle}
          </Text>
          <Box height="4px" width={[87]} bg="primary" mx="auto" my={5} />
          <Box mb={4}>
            <Label mb={1} sx={{ fontSize: 'small', fontWeight: 'extraBold' }}>
              {languages.en.referFriendEmail.recipientsLabel}
            </Label>
            <Repeater
              data={emails}
              renderItem={renderItem}
              onAdd={addEmail}
              onRemove={removeEmail}
              addRecipientLabel={languages.en.referFriendEmail.addRecipientLabel}
              errors={errors}
            />
          </Box>
          <Box>
            <Label mb={1} sx={{ fontSize: 'small', fontWeight: 'extraBold' }}>
              {languages.en.referFriendEmail.emailBodyLabel}
            </Label>
            <Text size="medium" color="textGray" mb={2}>
              {languages['en'].referFriendEmail.emailBody}
            </Text>
          </Box>
          <TermsAndConditions
            name="terms"
            label=""
            description={languages.en.referFriendEmail.termsDescription}
            error={error}
            checkboxProps={{
              checked: terms,
              onChange: () => {
                setError('')
                setTerms(!terms)
              }
            }}
          />
          <Flex sx={{ justifyContent: 'center', mt: [4] }}>
            <Button variant="primary" onClick={onSendEmail} disabled={loading || status === Status.success}>
              {languages.en.referFriendEmail.buttonText}
            </Button>
          </Flex>
          {[Status.success, Status.error].includes(status) && (
            <Text color={status === Status.success ? 'statusSuccess' : 'statusError'} textAlign="center" mt={1}>
              {status === Status.success
                ? languages.en.referFriendEmail.successSend
                : languages.en.referFriendEmail.errorSend}
            </Text>
          )}
        </Box>
      </Flex>
    </Modal>
  )
}

export default ReferByEmail
