import React, { Fragment, forwardRef } from 'react'
import { Card as RebassCard, CardProps as RebassCardProps, Box, Flex, SxStyleProp } from 'rebass'
import ReactStars from 'react-rating-stars-component'
import Skeleton from 'react-loading-skeleton'
import languages, { Language } from '../../i18n'
import Carousel from '../Carousel'
import { PriceDistributionOption } from '../PriceDistribution'
import Price from '../Price'
import {
  vehicleCardStyles,
  carouselStyles,
  headerImagestyles,
  headerWrapperStyles,
  priceWrapperStyles,
  priceStyles,
  arrowStyles,
  wrapperStyles
} from './VehicleCardSmall.styled'
import Icon from '../Icon/Icon'

import Text from '../Text'
import theme from '../../theme'
import { VehicleCardSimpleProps } from '../VehicleCardSimple/VehicleCardSimple'

const VehicleCardSmall = forwardRef<HTMLDivElement, VehicleCardSimpleProps>(
  (
    {
      slides,
      carName,
      totalTrips,
      price,
      priceDistribution,
      rating,
      language,

      showArrow,
      boxShadow,
      paddingText,
      padding,
      sx,
      ...props
    },
    ref
  ) => {
    return (
      <Box sx={{ position: 'relative' }}>
        <Box sx={wrapperStyles(padding, boxShadow)}>
          <RebassCard ref={ref} {...props} sx={{ ...(vehicleCardStyles as SxStyleProp), ...sx }}>
            <Box sx={headerWrapperStyles}>
              {slides.length === 1 ? (
                <Box sx={headerImagestyles}>{slides[0]}</Box>
              ) : (
                <Carousel className="Carousel" variant="vehicleCardCarousel" sx={carouselStyles}>
                  {slides.map((slide, index) => (
                    <Fragment key={index}>{slide} </Fragment>
                  ))}
                </Carousel>
              )}
            </Box>
          </RebassCard>
          <RebassCard ref={ref} {...props} sx={{ ...(vehicleCardStyles as SxStyleProp), ...sx }}>
            <Box px={paddingText} mt={[2]} mb={[1]} sx={{ zIndex: 1 }}>
              <Box>
                <Text as="span" lineHeight={1} size="large" color="textBlack" fontWeight="extraBold">
                  {carName || <Skeleton />}
                </Text>
              </Box>
              <Flex alignItems="center">
                {!Number.isNaN(rating) ? (
                  rating > 0 ? (
                    <ReactStars
                      classNames="car-rating"
                      count={5}
                      edit={false}
                      activeColor={theme.colors.primaryLight}
                      color={theme.colors.bgGrayLight}
                      value={rating}
                      emptyIcon={<Icon name="star" />}
                      filledIcon={<Icon name="star" />}
                    />
                  ) : (
                    <Box>
                      <Text size="small" color="textLight" mt={'4px'}>
                        {languages[language].noRating}
                      </Text>
                    </Box>
                  )
                ) : (
                  <Skeleton />
                )}
                <Text ml={[1]} mt={'4px'} size="small" color="textLight">
                  {totalTrips > 0 ? `${totalTrips} ${languages[language].trips}` : languages[language].noTrips}
                </Text>
                {price && (
                  <Box sx={priceWrapperStyles}>
                    <Text as="span" lineHeight={1} size="tiny" color="textLight">
                      {languages[language].from}
                    </Text>
                    <Box>
                      <Price price={price} priceDistribution={priceDistribution} sx={priceStyles} />
                    </Box>
                  </Box>
                )}
              </Flex>
            </Box>
          </RebassCard>
        </Box>
        {showArrow && <Box sx={arrowStyles} />}
      </Box>
    )
  }
)

VehicleCardSmall.defaultProps = {
  slides: [],
  carName: 'Car Model',
  totalTrips: 2,
  rating: 5,
  price: '',
  priceDistribution: 'daily',
  language: 'en',
  showArrow: false,
  boxShadow: false,
  paddingText: 0,
  padding: 0,
  sx: {},
  onClick: () => null
}

export default VehicleCardSmall
