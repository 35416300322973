import styled from '@emotion/styled'
import { Flex, FlexProps } from 'rebass'
import Text, { TextProps } from '../Text/Text'
import theme from '../../theme'

interface StyledCardProps extends FlexProps {
  imageUrl: string
  hasHover: boolean
}

interface StyledHeadingProps extends TextProps {
  hasHover: boolean
}

export const StyledCard = styled(Flex)<StyledCardProps>`
  border-radius: ${theme.borderRadius.medium};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &:hover {
    &:before {
      opacity: ${(props) => (props.hasHover === true ? '0.8' : '0.5')};
      cursor: ${(props) => (props.hasHover === true ? 'pointer' : 'unset')};
    }
  }
  &:before {
    content: '';
    background-color: ${theme.colors.bgBlack};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 2;
    transition: all ${theme.speed.default};
  }
`
export const StyledHeading = styled(Text)<StyledHeadingProps>`
  text-align: center;
  z-index: 3;
  pointer-events: ${(props) => (props.hasHover === true ? 'none' : 'unset')};
  position: relative;
  display: block;
`
