import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { Box, Flex } from 'rebass'
import useMedia from 'use-media'

import {
  ApprovalIntention,
  MeQuery,
  UpdateAccountMutation,
  useUpdateUserForm,
  validationMessages
} from '@rmr/controllers'
import 'react-phone-input-2/lib/style.css'
import countryList from 'react-select-country-list'

import {
  Heading,
  Button,
  FormMessage,
  MessageColor,
  Loader,
  AvatarUploader,
  theme,
  Error,
  Label,
  TermsAndConditions,
  Text,
  Checkbox,
  Select,
  PhoneInput,
  Input
} from '@rmr/components'

import { languages } from '../../common/i18n'
import {
  buttonStyles,
  fieldStyles,
  formStyles,
  fullWidthField,
  wrapperStyles
} from '../SignupSocialForm/SignupSocialForm.styled'
import { scrollToHeadline } from '../StepsNavigation'
import AvatarEditor, { ImageState } from 'react-avatar-editor'
import { getCookie, refCookieName } from '@rmr/helpers'
import { navigate } from 'gatsby'
import WhatsAppNotice from '../WhatsAppNotice'
import { fixImageUrl } from '../../utils/fixImageUrl'

const countries = countryList()
  .getData()
  .map((e) => ({ ...e, key: e.value }))

export type FormData = {
  country: string
  phoneNumber: string
  terms: boolean
}

interface SignupSocialFormProps extends Partial<StepWizardChildProps> {
  idRef: string
  user: MeQuery['me']
}

const SignupSocialForm: FC<Partial<SignupSocialFormProps>> = ({ nextStep, idRef, user }) => {
  const [readOnlyRefCode, setReadOnlyRefCode] = useState(false)

  const avatarRef = useRef<null | AvatarEditor>(null)
  const renterRef = useRef<null | HTMLInputElement>(null)
  const ownerRef = useRef<null | HTMLInputElement>(null)

  const defaultValues = useMemo(() => {
    const intention = []
    const additionalInfo = user?.profile?.additionalInfo
    if (additionalInfo) {
      const { isRenter, isOwner } = additionalInfo
      if (isRenter) {
        intention.push(ApprovalIntention.Renter)
      }
      if (isOwner) {
        intention.push(ApprovalIntention.Owner)
      }
    }
    return {
      firstName: user?.profile?.firstName || '',
      lastName: user?.profile?.lastName || '',
      country: user?.profile?.country || 'ZA',
      phoneNumber: user?.profile?.phoneNumber || '',
      avatarReady: !!user?.profile.avatar || false,
      intention,
      terms: !!user?.termsAcceptedAt || false,
      referralCode: user.profile.additionalInfo.referralCode
    }
  }, [user])

  const onSuccess = ({ updateAccount }: UpdateAccountMutation) => {
    if (updateAccount.__typename === 'UserUpdateSuccess') {
      navigate('/app/approval')
    }
  }

  const onError = (err) => {
    console.log({ err })
  }

  const {
    watch,
    setValue,
    clearErrors,
    register,
    onSubmit,
    errors,
    submitting,
    submitResult,
    onSubmitSuccess,
    onSubmitError
  } = useUpdateUserForm({
    onSuccess,
    onError,
    avatarEditor: avatarRef.current,
    defaultValues
  })
  const country = watch('country')
  const phoneNumber = watch('phoneNumber')

  const avatarEditor = (editor) => {
    if (editor) {
      avatarRef.current = editor
    }
  }

  const onLoadSuccess = (imgInfo: ImageState) => {
    if (imgInfo) {
      setValue('avatarReady', true)
      clearErrors('avatarReady')
    }
  }

  const onPhoneChange = (phoneNumber: string) => {
    clearErrors('phoneNumber')
    setValue('phoneNumber', phoneNumber)
  }

  useEffect(() => {
    const referralCodeCookie = getCookie(refCookieName) ?? ''
    if (referralCodeCookie) {
      setReadOnlyRefCode(true)
    }
    setValue('referralCode', referralCodeCookie)
  }, [])

  useEffect(() => {
    register('country')
    register('phoneNumber')
    register('avatarReady')
  }, [register])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      submitResult.message !== validationMessages.en.warnErrors && onSubmitError(null, validationMessages.en.warnErrors)
    } else if (submitResult.message === validationMessages.en.warnErrors) {
      onSubmitSuccess()
    }
  }, [errors, onSubmitError, onSubmitSuccess, submitResult.message])
  return (
    <React.Fragment>
      {submitting && <Loader />}
      <Box sx={wrapperStyles}>
        <Flex flexDirection="column" alignItems="center">
          <Heading size="h4" fontWeight="extraBold" mb={1} textAlign="center">
            {languages.en.auth.signup.headlineSocial}
          </Heading>
          <Text size="medium" mb={4}>
            {languages.en.auth.signup.subHeadline}
          </Text>
          <Box mb={4}>
            <AvatarUploader
              ref={avatarEditor}
              width={150}
              onLoadSuccess={onLoadSuccess}
              initialAvatarUrl={fixImageUrl(user?.profile?.avatar)}
            />
            <Label mt={4} sx={{ textAlign: 'center', display: 'block!important' }}>
              Upload a Profile Image
            </Label>
            {errors.avatarReady && (
              <Box mt={1}>
                <Error sx={{ textAlign: 'center' }}>{errors.avatarReady.message}</Error>
              </Box>
            )}
          </Box>
          <Box as="form" onSubmit={onSubmit} sx={formStyles}>
            <Input
              name="firstName"
              label="First Name*"
              sx={{ ...fieldStyles, mr: [0, 3] }}
              ref={register}
              error={errors.firstName && errors.firstName.message}
            />
            <Input
              name="lastName"
              label="Last Name*"
              sx={{ ...fieldStyles }}
              ref={register}
              error={errors.lastName && errors.lastName.message}
            />
            <Select
              name="country"
              label="Your Country*"
              ref={register}
              rebassSelectProps={{
                value: country,
                autoComplete: 'on',
                onChange: (e) => {
                  clearErrors('country')
                  setValue('country', e.target.value)
                }
              }}
              options={countries}
              error={errors.country && errors.country.message}
            />
            <PhoneInput
              key={country}
              fieldLabel="Mobile Number*"
              countryCodeInit={country}
              phoneNumber={phoneNumber}
              onPhoneChange={onPhoneChange}
              errorMessage={errors?.phoneNumber?.message}
              mb={2}
            />

            <Box mb={[2]}>
              <Label mb={1}>
                <Text size="tiny" fontWeight="bold" mr={[2]} flex="1 0 auto">
                  {languages.en.auth.signup.intentionLabel}
                </Text>
              </Label>
              <Checkbox
                id="intention"
                name="intention"
                textAlign={['left']}
                ref={(el) => {
                  ownerRef.current = el
                  register(el)
                }}
                rebassCheckboxProps={{
                  value: ApprovalIntention.Owner
                }}
                sx={{ mb: 0 }}
              >
                <Box ml={[2]}>
                  <Text fontWeight="bold" size="small">
                    {languages.en.auth.signup.isOwnerLabel}
                  </Text>
                </Box>
              </Checkbox>
              <Checkbox
                id="intention"
                name="intention"
                textAlign={['left']}
                ref={(el) => {
                  renterRef.current = el
                  register(el)
                }}
                rebassCheckboxProps={{
                  value: ApprovalIntention.Renter
                }}
                sx={{ mb: 0 }}
              >
                <Box ml={[2]}>
                  <Text fontWeight="bold" size="small">
                    {languages.en.auth.signup.isRenterLabel}
                  </Text>
                </Box>
              </Checkbox>
              {errors.intention?.message && <Error>{errors.intention?.message}</Error>}
            </Box>
            <Input
              name="referralCode"
              label="Referral Code"
              sx={{ ...fieldStyles, mr: [0, 3], mb: 2 }}
              ref={register}
              error={errors.referralCode && errors.referralCode.message}
              rebassInputProps={{ readOnly: readOnlyRefCode || !!defaultValues.referralCode }}
            />
            <TermsAndConditions
              name="terms"
              mb={2}
              error={errors.terms ? errors.terms.message : undefined}
              ref={register}
              sx={{ display: defaultValues.terms ? 'none' : 'block' }}
            />
            <WhatsAppNotice />

            <Box sx={{ ...fullWidthField }}>
              <FormMessage
                showMessage={submitResult.submitted && !!submitResult.message}
                message={submitResult.message}
                color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
              />
            </Box>
            <Flex sx={{ ...fullWidthField }}>
              <Button type="submit" label={languages.en.auth.buttonSave} />
            </Flex>
            {errors.avatarReady && (
              <Box mt={1}>
                <Error sx={{ textAlign: 'center' }}>{errors.avatarReady.message}</Error>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </React.Fragment>
  )
}
export default SignupSocialForm
