import React from 'react'
import { Box, Flex, SxStyleProp } from 'rebass'

import Button, { ButtonProps } from '../Button'
import Icon from '../Icon'
import Text from '../Text'

interface ButtonSwitchProps {
  /** State of button: on or off */
  on: boolean
  /** Props of the on Button */
  onButtonProps?: ButtonProps
  /** Props of the off Button */
  offButtonProps?: ButtonProps
  /** handler to run when button is clicked */
  onClick?: () => void
  /** Button label */
  label?: string
  /** Sx Props */
  sx?: SxStyleProp
  /** Button children */
  children?: React.ReactNode
}

const ButtonSwitch = ({ children, label, on, onButtonProps, offButtonProps, sx, onClick }: ButtonSwitchProps) => {
  return (
    <Flex alignItems="center">
      <Text size="medium" mr={1}>
        {label}
      </Text>
      <Box>
        {on ? (
          <Button {...onButtonProps} sx={{ ...onButtonProps.sx, ...sx }} children={children} onClick={onClick} />
        ) : (
          <Button {...offButtonProps} sx={{ ...offButtonProps.sx, ...sx }} children={children} onClick={onClick} />
        )}
      </Box>
    </Flex>
  )
}

export default ButtonSwitch

const baseButtonProps = {
  type: 'button',
  sx: {
    px: ['1px'],
    py: ['1px'],
    minWidth: ['50px']
  }
}

ButtonSwitch.defaultProps = {
  label: '',
  on: false,
  success: false,
  onButtonProps: {
    ...baseButtonProps,
    variant: 'simpleOutlineRound',
    icon: (
      <>
        <Icon
          name="blackCheckOutlineInverted"
          fontAwesomeIconProps={{ size: '1x' }}
          sxIconWrapper={{ width: '25px !important', height: '25px!important' }}
        />
      </>
    ),
    sx: {
      ...baseButtonProps.sx,
      justifyContent: 'flex-end'
    }
  },
  offButtonProps: {
    ...baseButtonProps,
    variant: 'simpleOutlineRound',
    icon: (
      <>
        <Icon name="blackCircle" sxIconWrapper={{ width: '25px !important', height: '25px!important' }} />
      </>
    ),
    sx: {
      ...baseButtonProps.sx,
      justifyContent: 'flex-start',
      opacity: 0.3
    }
  },
  onClick: null,
  sx: {},
  children: null
}
