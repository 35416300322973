import { useOffset } from '@rmr/components'
import React, { useRef, ReactNode } from 'react'
import { Box, SxStyleProp, BoxProps } from 'rebass'

import { dropdownStyles, dropdownWrapperStyles } from './FilterDropdown.styled'

export interface FilterDropdownProps extends BoxProps {
  /** Is it Open? */
  isOpen: boolean
  /** Button Ref*/
  buttonRef: React.MutableRefObject<any>
  /** Within a Box */
  groupMode?: boolean
  /** Component content */
  children: ReactNode
  /** Sx prop styles */
  sx?: SxStyleProp
  /** Sx prop styles for container box*/
  containerSx?: SxStyleProp
}

const FilterDropdown = ({ buttonRef, groupMode, isOpen, children, sx, containerSx, ...props }: FilterDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const offset = useOffset(buttonRef?.current, dropdownRef?.current)
  return (
    <Box
      sx={{
        ...dropdownWrapperStyles(
          isOpen,
          dropdownRef.current ? dropdownRef.current.clientHeight : 0,
          groupMode ? 0 : offset
        ),
        ...sx
      }}
      {...props}
    >
      <Box ref={dropdownRef} sx={{ ...dropdownStyles, ...containerSx }}>
        {children}
      </Box>
    </Box>
  )
}

FilterDropdown.defaultProps = {
  isOpen: false,
  groupMode: false,
  children: null,
  containerSx: {},
  sx: {}
}

export default FilterDropdown
