import React, { FC, useState, useEffect, useMemo } from 'react'
import { RouteComponentProps, useLocation } from '@reach/router'
import StepWizard from 'react-step-wizard'
import { Box } from 'rebass'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import SEO from '../Seo'
import { Hero, Container, Loader, WizardStep } from '@rmr/components'
import {
  useGetVehicleByIdQuery,
  Vehicle,
  VehicleImage,
  ImageWithView,
  VehicleInformationData,
  RentalConditionsData,
  RateCalculationData,
  VehicleAvailabilityType,
  TermsConditionsData,
  VehicleDocument,
  User,
  Amount,
  VehicleFuel,
  VehicleColor,
  VehicleTransmission,
  AllVehicleFieldsFragment
} from '@rmr/controllers'

import { vehicleListingMapping } from '../../utils/helpers'
import RateCalculation from './RateCalculation'
import TermsConditions from './TermsConditions'
import ListingConfirmation from './ListingConfirmation'
import RentalConditions from './RentalConditions'
import ListingGuideline from './ListingGuideline'
import VehicleInformation from './VehicleInformation'
import UploadVehicleImages from './UploadVehicleImages'
import { languages } from '../../common/i18n'

const customTransitions = {
  enterRight: 'animated-steps step-enter-right',
  enterLeft: 'animated-steps step-enter-left',
  exitRight: 'animated-steps step-exit-right',
  exitLeft: 'animated-steps step-exit-left'
}

export interface VehicleState {
  vehicleInformation: VehicleInformationData
  existingDocuments?: VehicleDocument[]
  existingImages?: VehicleImage[]
  vehicleImages: ImageWithView[]
  rentalConditions: RentalConditionsData
  recommendedPrice: {
    price: Amount
    min: Amount
    max: Amount
  }
  rateCalculation: RateCalculationData
  termsConditions: TermsConditionsData
}

// for testing
// const vehicleInfoDefaultValues: VehicleInformationData = {
//   id: '',
//   name: 'Supa dupa car',
//   licence_no: 'NR13698',
//   vin: '1XPFDB9X4PD373330',
//   year: '2011',
//   make: 'AUDI',
//   model: 'A1',
//   transunionId: '2c5f1fca-49e4-43b6-a70e-93bf26618a77',
//   categoryId: '94ef0f18-7f4f-49db-b350-03a279411c75',
//   fueltype: VehicleFuel.Diesel,
//   color: VehicleColor.White,
//   transmission: VehicleTransmission.Manual,
//   featureIds: ['299c06e6-8cd7-4f95-ac8b-fc8b766c0efa', 'ca872dab-ad33-4727-9e76-47c9602b12d1'],
//   odometer: 50000,
//   description: "This is the best car I've ever had",
//   licenceDisk: '',
//   tracker: false
// }

const idRef = 'list-your-car-top'
const confirmationRef = 'confirmationRef'

const defaultAmount = {
  currencyCode: 'ZAR',
  amount: '0.00',
  amountFloat: 0,
  formatted: 'R 0.00',
  formattedNoDecimals: 'R 0'
}

export const defaultState: VehicleState = {
  vehicleInformation: {
    id: '',
    name: '',
    licence_no: '',
    vin: '',
    year: '',
    make: '',
    model: '',
    transunionId: '',
    categoryId: '',
    fueltype: null,
    color: null,
    transmission: null,
    featureIds: [],
    odometer: 0,
    description: '',
    licenceDisk: '',
    roadsworthyCertificate: '',
    trackingDeviceCertificate: '',
    tracker: false
    // temp for testing
    // ...vehicleInfoDefaultValues
  },
  vehicleImages: [],
  rentalConditions: {
    id: '',
    max_kilometers_per_day: null,
    minimum_days: null,
    is_drive_on_unpaved_roads: false,
    is_allow_pets: false,
    is_collect: true,
    is_delivery: true,
    is_deliver_renter: true,
    is_deliver_airport: true,
    vehicleLocation: '',
    address: '',
    is_show_location: false,
    delivery_fee_airport: null,
    delivery_fee_renter: null,
    delivery_radius: null
  },
  recommendedPrice: {
    price: defaultAmount,
    min: defaultAmount,
    max: defaultAmount
  },
  rateCalculation: {
    id: '',
    price_per_day: 0,
    availability: VehicleAvailabilityType.allDates
  },
  termsConditions: {
    id: '',
    accept_terms: false
  }
}

interface Location {
  pathname: string
  state: {
    comingFromVehicleForm: boolean
  }
}

interface ListYourCarProps extends RouteComponentProps {
  vehicleid?: string
  user?: User
}

const ListYourCar: FC<ListYourCarProps> = ({ vehicleid }) => {
  const location = useLocation() as Location

  const [vehicle, setVehicle] = useState<VehicleState>(defaultState)
  const [stateLoaded, setStateLoaded] = useState(false)

  const {
    data: vehicleData,
    loading: vehicleLoading,
    error
  } = useGetVehicleByIdQuery({
    variables: { id: vehicleid },
    skip: !vehicleid
  })

  const { heroImage } = useStaticQuery<GatsbyTypes.ListYourCarQueryQuery>(graphql`
    query ListYourCarQuery {
      heroImage: file(relativePath: { eq: "bg-image1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)

  const {
    vehicleInformation,
    existingDocuments = [],
    existingImages = [],
    rentalConditions,
    rateCalculation,
    recommendedPrice,
    termsConditions
  } = vehicle

  const { initialStep, wizardSteps } = useMemo(() => {
    // Initial step
    let initialStep

    if (vehicleid) {
      initialStep = 2
    } else {
      initialStep = 1
    }
    // Wizard steps

    const clickable = Array<boolean>(5).fill(false)
    clickable[0] = true
    if (vehicleid) clickable[1] = true
    if (existingImages.length > 0) clickable[2] = true
    if (rentalConditions.max_kilometers_per_day) clickable[3] = true
    if (rateCalculation.price_per_day) clickable[4] = true
    const wizardSteps: WizardStep[] = languages['en'].listYourCar.stepsWizard.map((e, i) => ({
      name: e,
      isClickable: clickable[i]
    }))
    return { initialStep, wizardSteps }
  }, [vehicleid])

  useEffect(() => {
    if (vehicleData && vehicleData.vehicle && vehicleData.vehicle.__typename === 'Vehicle') {
      const newState = vehicleListingMapping(defaultState, vehicleData.vehicle as AllVehicleFieldsFragment)
      setVehicle(newState)
      setStateLoaded(true)
    }
  }, [vehicleData, setStateLoaded])

  useEffect(() => {
    if (vehicleid && error) {
      navigate(location.pathname.replace(vehicleid, ''))
    }
    if (vehicleid && vehicleData && vehicleData.vehicle === null) {
      navigate(location.pathname.replace(vehicleid, ''))
    }
  }, [error])

  const loading = vehicleid && (vehicleLoading || !stateLoaded)

  return loading ? (
    <Loader position="static" />
  ) : (
    <React.Fragment>
      <SEO
        path="/app/list-your-car"
        title={'Share your car, detail features, set price, upload photos'}
        description={'Book better cars shared by local owners.'}
      />
      <Box as="section" height={['25vh']}>
        <Hero
          type="primary"
          images={[
            <GatsbyImage
              key={0}
              image={heroImage.childImageSharp.gatsbyImageData}
              alt="hero image"
              style={{ height: '100%' }}
            />
          ]}
          headline={languages['en'].listYourCar.heroHeadline}
          description={languages['en'].listYourCar.heroDescription}
          sx={{ minHeight: 'unset !important', height: ['100%'] }}
        />
      </Box>

      <Box as="section" sx={{ py: [6], overflow: 'hidden' }}>
        <Container maxWidth={[1440]}>
          <StepWizard initialStep={initialStep} transitions={customTransitions}>
            <ListingGuideline wizardSteps={wizardSteps} stepName="guideline" idRef={idRef} />
            <VehicleInformation
              stepName="info"
              wizardSteps={wizardSteps}
              idRef={idRef}
              vehicleid={vehicleid}
              vehicleInformation={vehicleInformation}
              existingDocuments={existingDocuments}
            />
            <UploadVehicleImages
              stepName="images"
              wizardSteps={wizardSteps}
              idRef={idRef}
              vehicleid={vehicleid}
              vehicleName={vehicle.vehicleInformation.name}
              existingImages={existingImages}
            />
            <RentalConditions
              stepName="conditions"
              wizardSteps={wizardSteps}
              idRef={idRef}
              vehicle={vehicle}
              onSetVehicle={setVehicle}
            />
            <RateCalculation
              stepName="rates"
              wizardSteps={wizardSteps}
              idRef={idRef}
              vehicleid={vehicleid}
              rateCalculation={rateCalculation}
              recommendedPrice={recommendedPrice}
            />
            <TermsConditions
              stepName="terms"
              wizardSteps={wizardSteps}
              idRef={idRef}
              confirmationRef={confirmationRef}
              vehicleid={vehicleid}
              termsConditions={termsConditions}
            />
            <ListingConfirmation
              stepName="confirmation"
              wizardSteps={wizardSteps}
              idRef={idRef}
              vehicleid={vehicleid}
            />
          </StepWizard>
        </Container>
      </Box>
    </React.Fragment>
  )
}

export default ListYourCar
