import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ReservationStatus, useGetReservationQuery } from '@rmr/controllers'

import {
  Container,
  Loader,
  StepsWrapper,
  Button,
  Separator,
  Text,
  VehicleCardSimple,
  Link,
  ImageWrapper
} from '@rmr/components'
import { Box, Flex, Image } from 'rebass'
import { stepsWrapperStyles } from '../ApprovalToList/GetApprovedList/GetApprovedList.styled'
import { languages } from '../../common/i18n'
import { getVehicleCardDetails } from '../../utils/helpers'
interface ReservationProps extends RouteComponentProps {
  reservationId?: string
}

export const PaymentConfirmation: React.FC<ReservationProps> = ({ reservationId }) => {
  const [options, setOptions] = React.useState({
    icon: 'check',
    text: languages.en.paymentConfirmation.subtitle,
    headline: languages.en.paymentConfirmation.title,
    buttonText: languages.en.paymentConfirmation.buttonText,
    color: 'statusSuccess',
    lines: languages.en.paymentConfirmation.textLines,
    buttonLink: `${process.env.GATSBY_NX_DASHBOARD_LINK}/reservations/${reservationId}`
  })

  const { data, loading } = useGetReservationQuery({
    variables: {
      id: reservationId
    }
  })

  React.useEffect(() => {
    if (data && data.getReservation.__typename === 'Reservation') {
      const { status } = data.getReservation

      if (status === ReservationStatus.Failed) {
        setOptions({
          icon: 'error',
          color: 'statusError',
          headline: languages.en.paymentError.title,
          text: languages.en.paymentError.subtitle,
          buttonText: languages.en.paymentError.buttonText,
          lines: [],
          buttonLink: `/app/booking/payment/${reservationId}`
        })
      }
    }
  }, [data, reservationId])

  if (loading || data?.getReservation.__typename !== 'Reservation') {
    return <Loader position="static" />
  }

  const { vehicle } = data.getReservation

  const { defaultName, pricePerDay, name, images, stats } = vehicle
  const { location, features } = getVehicleCardDetails(vehicle)
  return (
    <Box as="section" sx={{ pt: [15], pb: [6] }}>
      <Container>
        <StepsWrapper
          badgeProps={{ variant: 'circle', size: [150], icon: options.icon, color: options.color }}
          title={options.headline}
          subtitle={options.text}
          sx={stepsWrapperStyles}
        >
          <Box py={[4]} px={[4]}>
            <Flex sx={{ justifyContent: 'center', mb: 5 }}>
              <Separator bg="statusSuccess" width={[87]} />
            </Flex>
            {options.lines.map((text, index) => (
              <Text key={index} size="medium" color="textLight" textAlign="center" mb={2}>
                {text}
              </Text>
            ))}
            <Box width={[1, 0.5]} mx="auto" mb={[2]}>
              <VehicleCardSimple
                price={pricePerDay.formattedNoDecimals}
                priceDistribution="daily"
                carName={defaultName || name}
                slides={images.slice(0, 5).map((img) => (
                  <ImageWrapper key={img.id} src={img.file.url} alt={name} />
                ))}
                totalTrips={stats.trips}
                rating={Math.ceil(stats.ratings)}
                location={location}
                features={features}
                language="en"
                boxShadow={false}
              />
            </Box>
            <Flex sx={{ justifyContent: 'center', mt: [5] }}>
              <a href={options.buttonLink}>
                <Button variant="primary">{options.buttonText}</Button>
              </a>
            </Flex>
          </Box>
        </StepsWrapper>
      </Container>
    </Box>
  )
}

export default PaymentConfirmation
