import React, { useState } from 'react'
import { Box, BoxProps, Flex, SxStyleProp } from 'rebass'
import ReactStars from 'react-rating-stars-component'
import theme from '../../theme'
import { ratingStyles } from './StarRating.styled'
import Icon from '../Icon'
import Text from '../Text'

export interface StarRatingProps extends BoxProps {
  /** Color of Active stars */
  activeColor?: string
  /** Color of inactive stars */
  color?: string
  /** Number of stars to show */
  count: number
  /** Show rating */
  showRating?: boolean
  /** Size of the stars */
  size: number
  /** Value of the rating */
  value: number
  /** Is the rating editable */
  edit: boolean
  /** Accept half stars */
  acceptHalfStar: boolean
  /** Sx Props */
  sx?: SxStyleProp
  /** On Change Callback */
  onChangeValue?: (value: number) => void
}

const StarRating = ({
  activeColor,
  color,
  count,
  acceptHalfStar,
  showRating,
  value,
  edit,
  size,
  onChangeValue,
  sx,
  ...props
}: StarRatingProps) => {
  const [rating, setRating] = useState(1)
  const handleChange = (e: number) => {
    setRating(e)
    onChangeValue && onChangeValue(e)
  }
  return (
    <Flex {...props} sx={{ ...ratingStyles, ...sx }}>
      <ReactStars
        classNames="car-rating"
        count={count}
        onChange={handleChange}
        edit={edit}
        activeColor={activeColor}
        color={color}
        value={value}
        size={size}
        isHalf={acceptHalfStar}
        emptyIcon={<Icon name="star" />}
        filledIcon={<Icon name="star" />}
        halfIcon={<Icon name="starHalf" />}
      />
      {showRating && (
        <Text size={'medium'} ml={1}>
          {(Math.round(value * 100) / 100).toFixed(2)}
        </Text>
      )}
    </Flex>
  )
}
StarRating.defaultProps = {
  activeColor: theme.colors.baseBlack,
  color: theme.colors.bgGrayLight,
  sx: {},
  onChangeValue: null,
  count: 5,
  showRating: false,
  size: 14,
  edit: true,
  acceptHalfStar: true
}

export default StarRating
