let authToken = {
  token: null,
  expiry: null
}

export const getAuthToken = () => authToken

export const setAuthToken = (token, expiry) => {
  authToken = {
    token,
    expiry
  }

  return true
}

export const deleteAuthToken = () => {
  authToken = {
    token: null,
    expiry: null
  }

  return true
}
