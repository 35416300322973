import { Button, Checkbox, FormMessage, MessageColor, TermsAndConditions, Text } from '@rmr/components'
import { SubmitState } from '@rmr/controllers'
import React from 'react'
import { Box } from 'rebass'
import { languages } from '../../../common/i18n'
import { itemStyles } from './ReservationQuote.styled'

interface ButtonAreaProps {
  error?: string
  submitResult: SubmitState
  submitting: boolean
  register: any
}
const ButtonArea = ({ error, submitResult, submitting, register }: ButtonAreaProps) => {
  return (
    <Box sx={{ ...itemStyles, border: 'none' }}>
      <TermsAndConditions name="accept_terms" error={error} ref={register} />
      <FormMessage
        showMessage={submitResult.submitted}
        message={submitResult.message}
        color={`${submitResult.error ? 'statusError' : 'statusSuccess'}` as MessageColor}
      />
      <Button type="submit" disabled={submitting} variant="primary" mt={2}>
        {!submitting ? languages.en.booking.buttonLabel : languages.en.booking.buttonLabelLoading}
      </Button>
      <Text size="tiny" mt={[1]}>
        {languages.en.booking.note}
      </Text>
    </Box>
  )
}

export default ButtonArea
