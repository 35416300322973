import { SxStyleProp } from 'rebass'

import theme from '../../../theme'

export const dropdownWrapperStyles = (open: boolean, height: number, offset: number): SxStyleProp => ({
  bg: 'bgWhite',
  border: `${open ? '1px' : '0px'} solid ${theme.colors.borderInput}`,
  borderRadius: `${theme.borderRadius.small}`,
  top: 'calc(100% + 15px)',
  position: 'absolute',
  left: '50%',
  transform: `translateX(calc(${offset}px - 50%))`,
  minWidth: `100%`,
  maxWidth: '100%',
  height: open ? `${height}px` : 0,
  overflow: 'hidden',
  transition: 'height 0.2s',
  zIndex: theme.zIndex.modal,
  boxShadow: open ? [`0 0 30px 1px rgba(0,0,0,0.5)`] : 'none'
})

export const dropdownStyles: SxStyleProp = {
  bg: 'bgWhite',
  maxHeight: [260],
  overflow: ['auto']
}
