import React, { useEffect, useState } from 'react'

const useOffset = (parent: Element, dropdown: Element) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const screenPadding = 16
    if (parent && dropdown) {
      const placeholderRect = parent.getBoundingClientRect()
      const dropdownRect = dropdown.getBoundingClientRect()

      let offset = 0
      let position = placeholderRect.left + placeholderRect.width / 2
      let left = position - dropdownRect.width / 2
      let right = position + dropdownRect.width / 2

      if (left < screenPadding) {
        offset = screenPadding - left
      }
      if (right > window.innerWidth - screenPadding) {
        offset = window.innerWidth - right - screenPadding
      }

      setOffset(offset)
    }
  }, [parent, dropdown])
  return offset
}

export default useOffset
