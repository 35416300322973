import React from 'react'
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Button, Icon, Loader } from '@rmr/components'
import { languages } from '../../common/i18n'
import { ProviderType } from '../GoogleLogin'
import useSocialAuth from '../../hooks/useSocialAuth'
import { OAuthProviderTypes, SubmitState } from '@rmr/controllers'

interface FacebookLoginProps {
  providerType?: ProviderType
  onSubmit: (sumitResult: SubmitState) => void
  callback?: () => void
}

const FacebookLogin = ({ providerType = ProviderType.Register, onSubmit, callback }: FacebookLoginProps) => {
  const { onSuccess, loadingLogin, loadingRegister } = useSocialAuth(
    providerType,
    OAuthProviderTypes.Facebook,
    onSubmit,
    callback
  )
  return (
    <React.Fragment>
      {(loadingLogin || loadingRegister) && <Loader />}

      <ReactFacebookLogin
        appId={process.env.GATSBY_FACEBOOK_CLIENT_ID}
        callback={onSuccess}
        fields="name,email,picture"
        scope="public_profile,email"
        render={(renderProps) => (
          <Button
            label={languages.en.auth.facebookLabel}
            variant="outline"
            fullWidth
            onClick={renderProps.onClick}
            sx={outlineButtonStyles}
            icon={<Icon name="facebookCircle" sx={{ fontSize: [24], color: '#4267B2' }} />}
          />
        )}
      />
    </React.Fragment>
  )
}

export default FacebookLogin

const outlineButtonStyles = {
  height: [50, 60],
  fontSize: ['tiny', 'small', 'medium']
}
