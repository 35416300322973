import { CardPost, CssGrid } from '@rmr/components'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

export interface GridPostsWrapperProps {
  category?: string
}
const GridPostsWrapper: FC<GridPostsWrapperProps> = ({ category }) => {
  const data = useStaticQuery<GatsbyTypes.WordPressGridPostsQuery>(graphql`
    query WordPressGridPosts {
      allWpCategory {
        edges {
          node {
            name
            slug
            posts {
              nodes {
                id
                slug
                title
                postOptions {
                  excerpt
                }
                year: date(formatString: "YYYY")
                month: date(formatString: "MM")
                featuredImage {
                  node {
                    altText
                    sourceUrl
                  }
                }
                slug
                categories {
                  nodes {
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const colNum = [1, 2, 2, 3]
  const { edges: categories } = data.allWpCategory
  const pageCategory = [category]
  const relevantPosts = categories.filter(({ node }) => pageCategory.includes(node.slug))

  const postsCategory = relevantPosts[0].node.name
  const similarPosts = relevantPosts[0].node.posts.nodes

  return (
    <CssGrid columns={colNum}>
      {similarPosts
        .filter((p, idx) => idx < 3)
        .map(({ id, title, postOptions, featuredImage, categories, slug, year, month }) => (
          <Link
            to={
              category
                ? `/blog/${category}/${year}/${month}/${slug}/`
                : categories.nodes[0].slug === 'uncategorized'
                ? `/blog/${year}/${month}/${slug}/`
                : `/blog/${categories.nodes[0].slug}/${year}/${month}/${slug}/`
            }
            key={id}
          >
            <CardPost
              category={postsCategory}
              title={title}
              excerpt={postOptions.excerpt}
              image={featuredImage.node.sourceUrl}
              alt={featuredImage.node.altText}
            />
          </Link>
        ))}
    </CssGrid>
  )
}

export default GridPostsWrapper
