export const randomGeo = (latitude: number, longitude: number, radius: number) => {
  const y0 = latitude
  const x0 = longitude
  const rd = radius / 111300 //about 111300 meters in one degree

  const u = Math.random()
  const v = Math.random()

  const w = rd * Math.sqrt(u)
  const t = 2 * Math.PI * v
  const x = w * Math.cos(t)
  const y = w * Math.sin(t)

  //Adjust the x-coordinate for the shrinking of the east-west distances
  //const xp = x / Math.cos(y0)

  const newlat = y + y0
  const newlon = x + x0

  return [newlon, newlat]
}
