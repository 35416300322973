import { SearchVehicleInput, useGetVehiclesQuery } from '../lib/generated/generated'
import { useCursorPagination } from './useCursorPagination'

export const useSearchVehicles = (
  input: SearchVehicleInput,
  limit = 12,
  initPage = 1,
  initBefore?: string,
  initAfter?: string
) => {
  const { startDate, endDate, ...filters } = input

  const searchVehicleInput = {
    startDate: startDate ? startDate.toISOString() : undefined,
    endDate: endDate ? endDate.toISOString() : undefined,
    ...filters
  }

  if (startDate && endDate) {
    searchVehicleInput.dates = {
      startDate: startDate ? startDate.toISOString() : undefined,
      endDate: endDate ? endDate.toISOString() : undefined
    }
  }
  const { page, nextPage, prevPage, paging, setCursor } = useCursorPagination(limit, initPage, initBefore, initAfter)
  const { data, loading, error } = useGetVehiclesQuery({
    variables: { input: searchVehicleInput, ...paging },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { pageInfo } = data.searchVehicles
      if (pageInfo) {
        const { startCursor, endCursor } = pageInfo
        setCursor({ startCursor, endCursor })
      }
    }
  })
  return {
    data,
    loading,
    error,
    page,
    nextPage,
    prevPage,
    before: paging.before,
    after: paging.after
  }
}
