import { SxStyleProp } from 'rebass'

export const wrapperStyles: SxStyleProp = {
  px: [0, 0, 0, 0, 80],
  pt: [40, null, 60],
  pb: [3]
}

export const cardWrapperStyles: SxStyleProp = {
  width: ['100%', null, null, 850, 1000],
  position: 'relative',
  display: ['grid', 'grid', 'flex'],
  justifyContent: 'space-between',
  gridTemplateColumns: ['repeat(2,1fr)', 'repeat(4,1fr)'],
  gridGap: [1]
}

export const cardStyles: SxStyleProp = {
  position: 'relative',
  display: ['flex', 'block'],
  flexDirection: 'column',
  alignItems: 'center'
}
export const tagStyle: SxStyleProp = {
  position: 'absolute',
  top: 0,
  left: 0
}

export const cardBadgeStyles: SxStyleProp = {}

export const cardTextStyles: SxStyleProp = {
  width: '100%',
  position: ['static', 'absolute'],
  top: 'calc(100% + 10px)',
  left: [0]
}

export const cardTitleStyles: SxStyleProp = {
  fontSize: ['small', '13px', 'medium']
}

export const lineStyles: SxStyleProp = {
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  width: '100%',
  height: '5px',
  bg: 'bgGrayLight',
  display: ['none', null, 'block']
}
