import React from 'react'
import { Flex, FlexProps, SxStyleProp } from 'rebass'

import Icon from '../Icon'

type Size = 'large' | 'medium' | 'small'
type Position = 'fixed' | 'absolute' | 'static'

interface LoaderProps extends FlexProps {
  position?: Position
  size?: Size
  sx?: SxStyleProp
}

const Loader = ({ position, size, sx, ...props }: LoaderProps) => {
  return (
    <Flex sx={{ ...spinnerStyles(position, size), ...sx }} {...props}>
      <Icon name="loaderDuotone" fontAwesomeIconProps={{ spin: true }} />
    </Flex>
  )
}

export default Loader

Loader.defaultProps = {
  position: 'fixed',
  size: 'medium',
  sx: {}
}

const spinnerSizes = {
  large: [80, 120],
  medium: [60, 100],
  small: [40, 80]
}

const spinnerStyles = (position: Position, size: Size): SxStyleProp => ({
  bg: position === 'fixed' ? 'rgba(0,0,0,0.3)' : 'transparent',
  position: position,
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  width: '100%',
  height: position === 'static' ? '80vh' : '100%',
  zIndex: 9999,
  video: {
    width: ['40vh'],
    height: ['40vh']
  },
  svg: { color: 'primary', fontSize: spinnerSizes[size] }
})
