import useHeaderMenu from './useHeaderMenu'
import { FooterProps } from '@rmr/components'

import { useWpMenu } from '../hooks/useWpMenu'

const useFooterMenu = (): FooterProps => {
  const menus = useWpMenu()
  return {
    homeLink: useHeaderMenu().homeLink,
    legalMenuItems: menus['legal'],
    menuItems: menus['footer']
  }
}

export default useFooterMenu
