import React, { useMemo } from 'react'
import { Box, Flex, BoxProps, SxStyleProp } from 'rebass'

import { BadgeProps, StepsWrapper, StepsWizard, Heading, Separator, WizardStep } from '@rmr/components'
import { wrapperStyles, stepsWizzardStyles, stepsWrapperStyles, headlineStyles } from './Layout.styled'
import StepsNavigation from '../../StepsNavigation'
import { languages } from '../../../common/i18n'

interface LayoutProps extends BoxProps {
  badgeProps?: BadgeProps
  headline?: string
  stepsWrapperHeadline?: string
  currentStep?: number
  previousStep?: () => void
  buttonNextClick?: () => void
  buttonBacklabel?: string
  buttonNextlabel?: string
  children: React.ReactNode
  idRef: string
  confirmationRef?: string
  separator?: boolean
  separatorColor?: string
  stepsWrapperSx?: SxStyleProp
  showWizard?: boolean
  topPageOffset?: number
  isClickable?: boolean
  wizardSteps: WizardStep[]
  goToNamedStep: (step: string) => void
}

const Layout = ({
  children,
  badgeProps,
  headline,
  stepsWrapperHeadline,
  separator,
  separatorColor,
  currentStep,
  previousStep,
  buttonNextClick,
  buttonBacklabel,
  buttonNextlabel,
  idRef,
  confirmationRef = 'confirmationRef',
  stepsWrapperSx,
  showWizard = true,
  wizardSteps,
  goToNamedStep,
  ...props
}: LayoutProps) => {
  const wizardStepsWithActions = useMemo(() => {
    const stepNames = ['info', 'images', 'conditions', 'rates', 'terms']
    return wizardSteps.map((wizardStep, i) => ({
      ...wizardStep,
      actionOnClick: (wizardSteps[i].actionOnClick = () => goToNamedStep(stepNames[i]))
    }))
  }, [wizardSteps])
  return (
    <Box sx={stepsWrapperStyles} {...props}>
      <Box id={confirmationRef} sx={{ position: 'relative', bottom: [170] }} />
      <Box id={idRef} sx={{ position: 'relative', bottom: [100] }} />
      {showWizard && (
        <Box sx={stepsWizzardStyles}>
          <StepsWizard steps={wizardStepsWithActions} withSides={false} currentStep={currentStep} isCompleted={true} />
        </Box>
      )}
      <StepsWrapper
        badgeProps={badgeProps}
        title={stepsWrapperHeadline}
        separator={separator}
        separatorColor={separatorColor}
        sx={{ ...stepsWrapperStyles, ...stepsWrapperSx }}
      >
        {headline && (
          <Box sx={headlineStyles}>
            <Heading size="h6">{headline}</Heading>
            <Separator />
          </Box>
        )}
        <Flex sx={wrapperStyles}>
          {children}
          <StepsNavigation
            currentStep={currentStep}
            previousStep={previousStep}
            buttonNextClick={buttonNextClick}
            buttonBacklabel={buttonBacklabel}
            buttonNextlabel={buttonNextlabel}
            idRef={idRef}
          />
        </Flex>
      </StepsWrapper>
    </Box>
  )
}

export default Layout
