import { SxStyleProp } from 'rebass'

export const fieldWrapper = (labelInline = false): SxStyleProp => ({
  alignItems: 'center',
  pl: [labelInline ? 2 : 0],
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'borderInput',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  borderTopLeftRadius: labelInline ? '4px' : '4px',
  borderBottomLeftRadius: labelInline ? '4px' : '4px',
  '& > div:last-child': {
    flex: '1 1 auto'
  },
  svg: { ml: [-5] }
})

export const fieldStyles: SxStyleProp = {
  bg: 'baseWhite',
  color: 'textGray',
  fontSize: ['16px', 'small'],
  fontWeight: 'bold',
  border: 'none',
  '&::placeholder, &::-webkit-input-placeholder': {
    color: 'textMuted',
    opacity: 0.5
  },
  '&:-ms-input-placeholder': {
    color: 'textMuted',
    opacity: 0.5
  },
  '&:focus': {
    outline: 'none',
    borderLeftWidth: '3px',
    borderLeftColor: 'textDark'
  },
  '&:disabled': {
    bg: 'bgPrimaryLight'
  },
  '&[readonly]': {
    bg: 'bgPrimaryLight'
  },
  // For Select Component
  option: {
    py: '1px',
    display: 'flex',
    alignItems: 'center'
  }
}

export const requiredError = 'This is a required field'

export const inlineLabelStylesWrapper: SxStyleProp = {
  alignSelf: 'stretch',
  alignItems: 'center',
  width: 'auto',
  px: [2],
  ml: [-2],
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px'
}
export const inlineLabelStyles: SxStyleProp = {
  fontWeight: 'extraBold',
  pb: '1px'
}
