import { Button, Error, FormMessage, Icon, Loader, MessageColor, Modal, Text } from '@rmr/components'
import { Reservation, useUpdateReservationDates, useVehicleAvailabilityQuery } from '@rmr/controllers'
import moment, { Moment } from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Box, Flex } from 'rebass'
import TripDates from './TripDates'

const inPeriod = (day: Moment, startDateUnix: number, endDateUnix: number): boolean => {
  const dayUnix = day.unix()
  return dayUnix >= startDateUnix && dayUnix <= endDateUnix
}

type ChangeDatesProps = {
  reservation: Reservation
  onRefetch: () => void
}

const ChangeDates = ({ reservation, onRefetch }: ChangeDatesProps) => {
  const [showChangeDatesModal, setShowChangeDatesModal] = useState(false)
  const [error, setError] = useState('')

  const { clearErrors, setValue, onSubmit, register, submitting, submitResult, resetSubmitResult } =
    useUpdateReservationDates({
      reservationId: reservation.id,
      onSuccess: () => {
        onRefetch()
        setTimeout(() => {
          setShowChangeDatesModal(false)
          resetSubmitResult()
        }, 1000)
      }
    })

  const [{ startDate, endDate }, setDates] = useState({
    startDate: moment(reservation.startDate),
    endDate: moment(reservation.endDate)
  })

  const onDatesChange = ({ startDate, endDate }) => {
    setError('')
    clearErrors()
    setDates({
      startDate,
      endDate
    })
  }

  useEffect(() => {
    register('startDate')
    register('endDate')
  }, [register])

  useEffect(() => {
    setValue('startDate', startDate)
    setValue('endDate', endDate)
  }, [startDate, endDate, setValue])

  const isDirty =
    moment(reservation.startDate).format('YYYYMMDD') !== startDate?.format('YYYYMMDD') ||
    moment(reservation.endDate).format('YYYYMMDD') !== endDate?.format('YYYYMMDD')

  return (
    <>
      {submitting && <Loader />}
      <Modal
        isModalOpen={showChangeDatesModal}
        closeModal={() => setShowChangeDatesModal(false)}
        canOverflow
        closeOnClickOutside={false}
      >
        <Text mb={2}>Change Rental Dates</Text>
        <>
          <TripDates
            vehicleId={reservation.vehicle.id}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
            minimumDays={reservation.vehicle.minimum_days}
            maximumDays={reservation.vehicle.maximum_days}
            onError={(errorMessage) => setError(errorMessage)}
          />
          {error && <Error sx={{ my: 1 }}>{error}</Error>}
          <Box>
            <Flex sx={{ mt: 2, justifyContent: 'end', gap: 2 }} className="mt-5 flex justify-end spac">
              <Button fullWidth variant="primary" disabled={!isDirty || !!error} onClick={onSubmit}>
                Update Dates
              </Button>
            </Flex>
            <FormMessage
              showMessage={submitResult.submitted}
              message={submitResult.message}
              color={`${submitResult.error ? 'statusError' : 'statusSuccess'}` as MessageColor}
            />
          </Box>
        </>
      </Modal>
      <Flex sx={{ cursor: 'pointer' }} onClick={() => setShowChangeDatesModal(true)}>
        <Icon name="pen" fontAwesomeIconProps={{ size: 'xs' }} />
        <Text size="small" ml={1}>
          Change Dates
        </Text>
      </Flex>
    </>
  )
}

export { ChangeDates }
