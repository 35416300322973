import { Button, Checkbox } from '@rmr/components'
import { VehicleEdge } from '@rmr/controllers'
import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import { Moment } from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Box } from 'rebass'
import { SearchFilter } from '../RentCar'
import LocationPin from './LocationPin'

export type Bounds =
  | {
      northEastLatitude: number
      northEastLongitude: number
      southWestLongitude: number
      southWestLatitude: number
    }
  | undefined

interface MapProps {
  initBounds?: Bounds
  vehicles: VehicleEdge[]
  vehicleHover: string | null
  vehicleActive: string | null
  onActiveVehicle: React.Dispatch<React.SetStateAction<string>>
  startDate?: string
  endDate?: string
  isMobile: boolean
  showPin?: boolean
  onBoundsChange: (bounds: Bounds, isMapSearch: boolean) => void
  filter: SearchFilter
  isMapSearch: boolean
}

const Map = ({
  vehicles,
  vehicleHover,
  vehicleActive,
  onActiveVehicle,
  startDate,
  endDate,
  isMobile,
  showPin = false,
  initBounds,
  onBoundsChange,
  filter,
  isMapSearch
}: MapProps) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const [center, setCenter] = useState<Point>({
    lat: filter.latitude || -28.739,
    lng: filter.longitude || 24.746
  })
  const [zoom, setZoom] = useState(filter.latitude ? 10 : 5)
  const [bounds, setBounds] = useState<GoogleMapReact.Bounds>()
  const [isDragged, setDrag] = useState(false)
  const [toggleMapSearch, setToggleMapSearch] = useState(false)
  const [showSearchButton, setShowSearchButton] = useState(false)

  const searchThisArea = (bounds: GoogleMapReact.Bounds) => {
    onBoundsChange &&
      onBoundsChange(
        {
          northEastLatitude: bounds.ne.lat,
          northEastLongitude: bounds.ne.lng,
          southWestLongitude: bounds.sw.lng,
          southWestLatitude: bounds.sw.lat
        },
        true
      )
  }

  const updateMapState = (value: ChangeEventValue) => {
    if (isDragged) {
      setCenter(value.center)
      setZoom(value.zoom)
      setBounds(value.bounds)

      if (toggleMapSearch) {
        searchThisArea(value.bounds)
      }
      setDrag(false)
    }
  }

  const handleDrag = () => {
    if (!isDragged) {
      setDrag(true)
      if (!toggleMapSearch && !showSearchButton) {
        setShowSearchButton(true)
      }
    }
  }
  const onSearchThisArea = () => {
    setShowSearchButton(false)
    searchThisArea(bounds)
  }
  useEffect(() => {
    if (filter.latitude && filter.longitude) {
      setCenter({ lat: filter.latitude, lng: filter.longitude })
      setZoom(10)
    }
  }, [filter.latitude, filter.longitude])
  return (
    <Box ref={mapContainerRef} sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 1, left: 1, zIndex: 1 }}>
        {showSearchButton ? (
          <Button variant="black" sx={{ py: 1 }} onClick={onSearchThisArea}>
            Search this area
          </Button>
        ) : (
          <Box sx={{ p: 1, bg: 'white', borderRadius: 2 }}>
            <Checkbox
              name="searchType"
              sx={{ mb: 0 }}
              rebassCheckboxProps={{ checked: toggleMapSearch, onChange: () => setToggleMapSearch(!toggleMapSearch) }}
            >
              Search for cars as I move the map
            </Checkbox>
          </Box>
        )}
      </Box>
      <GoogleMapReact
        // Loaded on gatsby-ssr
        // bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
        center={center}
        zoom={zoom}
        options={(maps) => ({
          clickableIcons: !isMobile
        })}
        onDrag={handleDrag}
        onZoomAnimationEnd={handleDrag}
        onChange={updateMapState}
        yesIWantToUseGoogleMapApiInternals
        margin={[50, 50, 50, 50]}
        onGoogleApiLoaded={({ map, maps }) => {
          if (initBounds && isMapSearch) {
            const swLatLng = new maps.LatLng(initBounds.southWestLatitude, initBounds.southWestLongitude)
            const neLatLng = new maps.LatLng(initBounds.northEastLatitude, initBounds.northEastLongitude)
            const bounds = new maps.LatLngBounds(swLatLng, neLatLng)
            map.fitBounds(bounds)
            const { center, zoom } = map
            setZoom(zoom)
            setCenter(center)
            setBounds(bounds)
          }
        }}
      >
        {showPin &&
          vehicles.length > 0 &&
          vehicles.map(({ node: vehicle }) => {
            const hovered = vehicle.id === vehicleHover
            return (
              <LocationPin
                key={vehicle.id}
                lat={vehicle.lat}
                lng={vehicle.lng}
                hovered={hovered}
                vehicleActive={vehicleActive}
                vehicle={vehicle}
                onActiveVehicle={onActiveVehicle}
                mapContainerRef={mapContainerRef}
                setCenterZoom={setZoom}
                startDate={startDate}
                endDate={endDate}
              />
            )
          })}
      </GoogleMapReact>
    </Box>
  )
}

export default Map
