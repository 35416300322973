import React, { forwardRef, useState, useEffect, useCallback } from 'react'
import { Box, BoxProps, Flex } from 'rebass'
import { usePlacesWidget } from 'react-google-autocomplete'

import {
  useRentalConditionsForm,
  UpdateVehicleMutation,
  RentalConditionsData,
  extractAddressFieldsFromGooglePlace,
  RentalPeriod,
  validationMessages,
  languages as enumLanguages
} from '@rmr/controllers'
import {
  Label,
  LabelTooltip,
  Button,
  Input,
  Checkbox,
  Select,
  FormMessage,
  MessageColor,
  Loader,
  theme,
  Text,
  Error
} from '@rmr/components'
import { commaSeparatedStringsToArray, enumToOptionsArray } from '@rmr/helpers'

import { formStyles, fullWidthField, deliveryWrapperStyles, checkboxWrapper } from './RentalConditionsForm.styled'
import { languages } from '../../../../common/i18n'
import { scrollToHeadline } from '../../../StepsNavigation'
import { VehicleState } from '../../ListYourCar'

const autocompleteCountries = commaSeparatedStringsToArray(process.env.GATSBY_AUTOCOMPLETE_COUNTRIES)

interface RentalConditionsFormProps extends BoxProps {
  idRef: string
  vehicle: VehicleState
  onSetVehicle: React.Dispatch<React.SetStateAction<VehicleState>>
  nextStep: () => void
}

const RentalConditionsForm = forwardRef<HTMLButtonElement, RentalConditionsFormProps>(
  ({ idRef, vehicle, onSetVehicle, nextStep, ...props }, ref) => {
    const { vehicleInformation, rentalConditions } = vehicle
    const vehicleid = vehicleInformation.id

    const [deliverySectionHeight, setDeliverySectionHeight] = useState(0)

    const handleNextStep = () => {
      nextStep()
      scrollToHeadline(idRef)
    }

    const onSuccess = ({ updateVehicle }: UpdateVehicleMutation) => {
      if (updateVehicle.__typename === 'Vehicle') {
        handleNextStep()
      }
    }

    const {
      watch,
      setError,
      clearErrors,
      register,
      setValue,
      onSubmit,
      submitting,
      errors,
      submitResult,
      onSubmitError,
      onSubmitSuccess
    } = useRentalConditionsForm({
      onSuccess,
      defaultValues: rentalConditions
    })

    const vehicleLocation = watch('vehicleLocation')
    const is_delivery = watch('is_delivery')
    const is_deliver_renter = watch('is_deliver_renter')
    const is_deliver_airport = watch('is_deliver_airport')

    const onPlaceSelected = useCallback(
      (place) => {
        if (!place.place_id) {
          setError('vehicleLocation', {
            type: 'required',
            message: languages['en'].listYourCar.rentalConditions.placeError
          })
          return
        }
        clearErrors('vehicleLocation')
        clearErrors('address')
        const update = extractAddressFieldsFromGooglePlace(place)
        setValue('address', JSON.stringify(update))
        setValue('vehicleLocation', place.formatted_address)
      },
      [setError, languages]
    )
    const { ref: googlePlacesRef } = usePlacesWidget({
      // Loaded on gatsby-ssr
      // apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
      inputAutocompleteValue: 'off',
      onPlaceSelected,
      options: {
        types: ['address'],
        componentRestrictions: { country: autocompleteCountries }
      }
    })

    const handleSubmit = useCallback(
      (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(e)
      },
      [setError, errors]
    )

    const deliverySectionRefCallBack = (element: HTMLParagraphElement) => {
      if (element && element.clientHeight > deliverySectionHeight) {
        setDeliverySectionHeight(element.clientHeight)
      }
    }

    useEffect(() => {
      register('address')
      register('is_delivery')
      register('is_deliver_airport')
      register('is_deliver_renter')
      register('vehicleLocation')
      register('id')
    }, [register])

    useEffect(() => {
      setValue('id', vehicleid)
    }, [setValue, vehicleid])

    useEffect(() => {
      if (Object.keys(errors).length > 0) {
        submitResult.message !== validationMessages.en.warnErrors &&
          onSubmitError(null, validationMessages.en.warnErrors)
      } else if (submitResult.message === validationMessages.en.warnErrors) {
        onSubmitSuccess()
      }
    }, [Object.keys(errors).length])

    return (
      <React.Fragment>
        {submitting && <Loader />}
        <Box as="form" onSubmit={handleSubmit} sx={{ ...formStyles, alignItems: 'start' }} {...props}>
          <Select
            name="max_kilometers_per_day"
            toolTipInfo={languages['en'].listYourCar.rentalConditions.maxDailyDistanceInfo}
            placeholder={languages['en'].listYourCar.rentalConditions.maxDailyDistancePlaceholder}
            rebassSelectProps={{
              defaultValue: rentalConditions.max_kilometers_per_day,
              autoComplete: 'on'
            }}
            ref={register}
            options={languages['en'].listYourCar.rentalConditions.maxDailyDistanceOptions}
            label={languages['en'].listYourCar.rentalConditions.maxDailyDistanceLabel}
            error={errors.max_kilometers_per_day && errors.max_kilometers_per_day.message}
          />
          <Select
            name="minimum_days"
            toolTipInfo={languages['en'].listYourCar.rentalConditions.minRentalPeriodInfo}
            placeholder={languages['en'].listYourCar.rentalConditions.minRentalPeriodPlaceholder}
            rebassSelectProps={{
              defaultValue: rentalConditions.minimum_days,
              autoComplete: 'on'
            }}
            ref={register}
            options={enumToOptionsArray(RentalPeriod, enumLanguages.en.enums.RentalPeriod)}
            label={languages['en'].listYourCar.rentalConditions.minRentalPeriodLabel}
            error={errors.minimum_days && errors.minimum_days.message}
          />

          <Box sx={{ ...checkboxWrapper, width: ['auto'] }} mt={[2]}>
            <Checkbox
              name="is_drive_on_unpaved_roads"
              sx={{ mr: [6] }}
              toolTipInfo={languages['en'].listYourCar.rentalConditions.allowGravelRoadsInfo}
              rebassCheckboxProps={{
                defaultChecked: rentalConditions.is_drive_on_unpaved_roads
              }}
              ref={register}
              error={errors.is_drive_on_unpaved_roads && errors.is_drive_on_unpaved_roads.message}
            >
              {languages['en'].listYourCar.rentalConditions.allowGravelRoadsLabel}
            </Checkbox>
            <Checkbox
              name="is_allow_pets"
              toolTipInfo={languages['en'].listYourCar.rentalConditions.allowPetsInfo}
              rebassCheckboxProps={{
                defaultChecked: rentalConditions.is_allow_pets
              }}
              ref={register}
              error={errors.is_allow_pets && errors.is_allow_pets.message}
            >
              {languages['en'].listYourCar.rentalConditions.allowPetsLabel}
            </Checkbox>
          </Box>
          <Box sx={{ ...fullWidthField, my: [2], border: `1px dashed ${theme.colors.borderLight}` }} />
          <Text size="small" fontWeight="bold" sx={fullWidthField}>
            {languages['en'].listYourCar.rentalConditions.pickUpDeliveryInfoHeadline}
          </Text>

          <Box sx={checkboxWrapper} mt={[2]}>
            <Checkbox
              name="is_collect"
              sx={{ mr: [6] }}
              toolTipInfo={languages['en'].listYourCar.rentalConditions.pickUpInfo}
              rebassCheckboxProps={{
                defaultChecked: rentalConditions.is_collect,
                onChange: (e) => {
                  clearErrors('is_collect')
                  !e.target.checked && setValue('is_delivery', true)
                }
              }}
              ref={register}
              error={errors.is_collect && errors.is_collect.message}
            >
              {languages['en'].listYourCar.rentalConditions.pickUpLabel}
            </Checkbox>
            <Checkbox
              name="is_delivery"
              toolTipInfo={languages['en'].listYourCar.rentalConditions.deliveryInfo}
              rebassCheckboxProps={{
                checked: is_delivery,
                onChange: (e) => {
                  clearErrors('is_delivery')
                  setValue('is_delivery', e.target.checked)
                  if (!e.target.checked) {
                    setValue('is_collect', true)
                    clearErrors('is_collect')
                    setValue('is_deliver_renter', false)
                    clearErrors('is_deliver_renter')
                    setValue('is_deliver_airport', false)
                    clearErrors('is_deliver_airport')
                    setValue('delivery_fee_airport', null)
                    clearErrors('delivery_fee_airport')
                    setValue('delivery_fee_renter', null)
                    clearErrors('delivery_fee_renter')
                    setValue('delivery_radius', null)
                    clearErrors('delivery_radius')
                  }
                }
              }}
              error={errors.is_delivery && errors.is_delivery.message}
            >
              {languages['en'].listYourCar.rentalConditions.deliveryLabel}
            </Checkbox>
          </Box>
          <Box sx={fullWidthField} />

          <Input
            ref={googlePlacesRef}
            name="vehicleLocation"
            label={languages['en'].listYourCar.rentalConditions.vehicleLocationLabel}
            placeholder={languages['en'].listYourCar.rentalConditions.vehicleLocationPlaceholder}
            rebassInputProps={{
              autoComplete: 'off',
              value: vehicleLocation,
              onChange: (e) => {
                setValue('vehicleLocation', e.target.value)
                clearErrors('vehicleLocation')
              }
            }}
            error={errors.vehicleLocation && errors.vehicleLocation.message}
          />
          <Checkbox
            name="is_show_location"
            sx={{ mr: [6], alignSelf: 'center' }}
            toolTipInfo={languages['en'].listYourCar.rentalConditions.showMyLocationInfo}
            rebassCheckboxProps={{
              defaultChecked: rentalConditions.is_show_location
              // onChange: onChangeInput
            }}
            ref={register}
            error={errors.is_show_location && errors.is_collect.message}
          >
            {languages['en'].listYourCar.rentalConditions.showMyLocationLabel}
          </Checkbox>
          <Box
            sx={{
              ...fullWidthField,
              ...deliveryWrapperStyles(is_delivery, deliverySectionHeight)
            }}
          >
            <Box ref={deliverySectionRefCallBack} sx={formStyles}>
              <Box sx={checkboxWrapper} mt={[2]}>
                <Checkbox
                  name="is_deliver_airport"
                  toolTipInfo={languages['en'].listYourCar.rentalConditions.airportDeliveryInfo}
                  rebassCheckboxProps={{
                    checked: is_deliver_airport,
                    onChange: (e) => {
                      clearErrors('is_deliver_airport')
                      setValue('is_deliver_airport', e.target.checked)
                      if (e.target.checked) {
                        // setValue('is_deliver_renter', false)
                        clearErrors('is_deliver_renter')
                      }
                    }
                  }}
                  error={errors.is_deliver_airport && errors.is_deliver_airport.message}
                >
                  {languages['en'].listYourCar.rentalConditions.airportDeliveryLabel}
                </Checkbox>
                <Checkbox
                  name="is_deliver_renter"
                  toolTipInfo={languages['en'].listYourCar.rentalConditions.deliveryToRenterInfo}
                  rebassCheckboxProps={{
                    checked: is_deliver_renter,
                    onChange: (e) => {
                      clearErrors('is_deliver_renter')
                      setValue('is_deliver_renter', e.target.checked)
                      if (e.target.checked) {
                        // setValue('is_deliver_airport', false)
                        clearErrors('is_deliver_airport')
                      }
                    }
                  }}
                  error={errors.is_deliver_renter && errors.is_deliver_renter.message}
                >
                  {languages['en'].listYourCar.rentalConditions.deliveryToRenterLabel}
                </Checkbox>
              </Box>
              <Box sx={{ ...fullWidthField, my: [2], border: `1px dashed ${theme.colors.borderLight}` }} />
              <Box
                sx={{
                  ...fullWidthField,
                  display: is_deliver_airport || is_deliver_renter ? 'block' : 'none'
                }}
              >
                <Text size="small" fontWeight="bold" sx={fullWidthField}>
                  {languages['en'].listYourCar.rentalConditions.feesHeadline}
                </Text>
                <Box sx={fullWidthField}>
                  <Box sx={{ ...checkboxWrapper, alignItems: 'start' }} mt={[2]}>
                    {is_deliver_airport && (
                      <Select
                        name="delivery_fee_airport"
                        toolTipInfo={languages['en'].listYourCar.rentalConditions.airportDeliveryFeeInfo}
                        placeholder={languages['en'].listYourCar.rentalConditions.airportDeliveryFeePlaceholder}
                        rebassSelectProps={{
                          // onChange: onChangeSelect,
                          defaultValue:
                            rentalConditions.delivery_fee_airport !== null ? rentalConditions.delivery_fee_airport : '',
                          autoComplete: 'on'
                        }}
                        ref={register}
                        options={languages['en'].listYourCar.rentalConditions.airportDeliveryFeeOptions}
                        label={languages['en'].listYourCar.rentalConditions.airportDeliveryFeeLabel}
                        error={errors.delivery_fee_airport && errors.delivery_fee_airport.message}
                      />
                    )}
                    {is_deliver_renter && (
                      <Select
                        name="delivery_fee_renter"
                        toolTipInfo={languages['en'].listYourCar.rentalConditions.deliveryToRenterFeeInfo}
                        placeholder={languages['en'].listYourCar.rentalConditions.deliveryToRenterFeePlaceholder}
                        rebassSelectProps={{
                          // onChange: onChangeSelect,
                          defaultValue:
                            rentalConditions.delivery_fee_renter !== null ? rentalConditions.delivery_fee_renter : '',
                          autoComplete: 'on'
                        }}
                        ref={register}
                        options={languages['en'].listYourCar.rentalConditions.deliveryToRenterFeeOptions}
                        label={languages['en'].listYourCar.rentalConditions.deliveryToRenterFeeLabel}
                        error={errors.delivery_fee_renter && errors.delivery_fee_renter.message}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Flex sx={{ ...fullWidthField, alignItems: [null, 'center'], flexDirection: ['column', 'row'] }}>
                <Label sx={{ flex: '0 0 auto', width: ['auto'] }}>
                  {languages['en'].listYourCar.rentalConditions.deliveryWithinLabelBefore}
                </Label>
                <Box sx={{ flex: '0 0 auto', minWidth: [170], mx: [0, 2], alignSelf: 'center' }}>
                  <Select
                    name="delivery_radius"
                    placeholder={languages['en'].listYourCar.rentalConditions.deliveryWithinPlaceholder}
                    rebassSelectProps={{
                      // onChange: onChangeSelect,
                      defaultValue: rentalConditions.delivery_radius !== null ? rentalConditions.delivery_radius : '',
                      autoComplete: 'on'
                    }}
                    ref={register}
                    options={languages['en'].listYourCar.rentalConditions.deliveryWithinOptions}
                  />
                  {errors.delivery_radius && (
                    <Error sx={{ position: 'absolute', mt: -2 }}>{errors.delivery_radius.message}</Error>
                  )}
                </Box>
                <LabelTooltip
                  labelText={languages['en'].listYourCar.rentalConditions.deliveryWithinLabelAfter}
                  toolTipInfo={languages['en'].listYourCar.rentalConditions.deliveryWithinInfo}
                />
              </Flex>
            </Box>
          </Box>
          <Box sx={{ ...fullWidthField, mb: [2] }}>
            <FormMessage
              showMessage={submitResult.submitted}
              message={submitResult.message}
              color={`${submitResult.success ? 'statusSuccess' : 'statusError'}` as MessageColor}
            />
          </Box>

          <Box width={0} height={0} overflow="hidden">
            <Button ref={ref} type="submit" label="submit" />
          </Box>
        </Box>
      </React.Fragment>
    )
  }
)
export default RentalConditionsForm
