import React, { useState, useRef, useEffect } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { useStaticQuery, graphql } from 'gatsby'
import { Box } from 'rebass'

import { useTermsConditionsForm, UpdateVehicleMutation, TermsConditionsData } from '@rmr/controllers'

import { MessageColor, Button, FormMessage, theme, Content, TermsAndConditions, WizardStep } from '@rmr/components'
import { languages } from '../../../common/i18n'
import Layout from '../Layout'
import { scrollToHeadline } from '../../StepsNavigation'
import { GtmCategory, GtmEvent, logGtmEvent } from '@rmr/helpers'

interface TermsConditionsProps extends Partial<StepWizardChildProps> {
  idRef: string
  confirmationRef: string
  vehicleid: string
  termsConditions: TermsConditionsData
  wizardSteps: WizardStep[]
}
const TermsConditions = ({
  idRef,
  confirmationRef,
  currentStep,
  previousStep,
  nextStep,
  goToNamedStep,
  vehicleid,
  termsConditions,
  wizardSteps
}: TermsConditionsProps) => {
  const [{ success, message }, setMessage] = useState({ success: false, message: '' })

  const { wp } = useStaticQuery<GatsbyTypes.TermsConditionsQueryQuery>(graphql`
    query TermsConditionsQuery {
      wp {
        themeGeneralSettings {
          termsAndConditions {
            termsAndConditions
          }
        }
      }
    }
  `)

  const formButtonRef = useRef<HTMLButtonElement | null>()

  const handleClickNext = () => {
    if (formButtonRef) {
      formButtonRef.current.click()
    }
  }

  const onSuccess = ({ updateVehicle }: UpdateVehicleMutation) => {
    if (updateVehicle.__typename === 'Vehicle') {
      nextStep()
      scrollToHeadline(confirmationRef)
      logGtmEvent({ eventName: GtmEvent.VEHICLE_LISTING_REQUEST, data: { category: GtmCategory.VEHICLE_LISTING } })
    } else {
      setMessage({ success: false, message: languages['en'].listYourCar.termsConditions.updateProblemMessage })
    }
  }

  const onError = () => {
    setMessage({ success: false, message: languages['en'].listYourCar.termsConditions.serverProblemMessage })
  }

  const { register, setValue, onSubmit, submitting, errors } = useTermsConditionsForm({
    onSuccess,
    onError,
    defaultValues: termsConditions
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(e)
  }

  useEffect(() => {
    register('id')
  }, [register])

  useEffect(() => {
    setValue('id', vehicleid)
  }, [setValue])

  return (
    <Layout
      headline={languages['en'].listYourCar.termsConditions.headline}
      currentStep={currentStep - 1}
      previousStep={previousStep}
      buttonNextClick={handleClickNext}
      idRef={idRef}
      confirmationRef={confirmationRef}
      wizardSteps={wizardSteps}
      goToNamedStep={goToNamedStep}
    >
      <Box as="form" onSubmit={handleSubmit} sx={{ alignSelf: 'stretch', px: [2, null, 0] }}>
        <Box sx={{ maxHeight: ['40vh'], overflowY: 'auto' }}>
          <Content>
            <Box dangerouslySetInnerHTML={{ __html: wp.themeGeneralSettings.termsAndConditions.termsAndConditions }} />
          </Content>
        </Box>
        <Box sx={{ my: [4], border: `1px dashed ${theme.colors.borderLight}` }} />
        <TermsAndConditions
          name="accept_terms"
          error={errors.accept_terms ? errors.accept_terms.message : undefined}
          ref={register}
        />
        <Box sx={{ mb: [2] }}>
          <FormMessage
            showMessage={!!message}
            message={message}
            color={`${!success ? 'statusError' : 'statusSuccess'}` as MessageColor}
          />
        </Box>
        <Box width={0} height={0} overflow="hidden">
          <Button ref={formButtonRef} type="submit" label="submit" />
        </Box>
      </Box>
    </Layout>
  )
}

export default TermsConditions
