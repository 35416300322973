import React from 'react'

import { useLocation } from 'react-router-dom'

export const useScrollToLocation = () => {
  const scrolledRef = React.useRef(false)
  const { hash } = useLocation()
  React.useEffect(() => {
    if (hash && !scrolledRef.current) {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 300)
        scrolledRef.current = true
      }
    }
  })
}
