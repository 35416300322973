import React from 'react'
import { Box, Flex } from 'rebass'
import languages from '../../../i18n'
import Button from '../../Button'
import Error from '../../Form/Error'

import DropZoneArea from '../DropZoneArea'
import FilePreview, { PreviewMode } from '../FilePreview'
import { UploadComponentVariantProps } from '../UploadComponentVertical'
import { buttonStyles } from './UploadMinimal.styled'

const UploadMinimal = ({
  maxFiles,
  maxSize,
  files = [],
  getRootProps,
  getInputProps,
  isDragActive,
  openBroser,
  handleRemoveFile,
  showButton,
  previewFiles,
  sx,
  error,
  success,
  ...props
}: UploadComponentVariantProps & { success: boolean }) => {
  const isActive = files.length === 1
  return (
    <Box {...props} sx={sx}>
      <Flex sx={{ flexDirection: ['column', 'row'] }}>
        <Box sx={{ flex: '1 0 auto' }}>
          <DropZoneArea
            variant="minimal"
            maxSize={maxSize}
            files={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragActive={isDragActive}
            hideIcon={true}
          />
        </Box>
        {showButton && (
          <Button variant={isActive ? 'green' : 'black'} onClick={openBroser} sx={buttonStyles}>
            {languages['en'].browseFiles}
          </Button>
        )}
      </Flex>
      <FilePreview
        files={files}
        maxSize={maxSize}
        maxFiles={maxFiles}
        onClose={handleRemoveFile}
        previewMode={PreviewMode.List}
        previewFiles={previewFiles}
        error={success ? '' : error}
      />
    </Box>
  )
}

export default UploadMinimal
