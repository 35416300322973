import React, { FC } from 'react'
import { Container } from '../Grid'
import Heading from '../Heading'
import Text from '../Text'
import { StyledBannerIcon, StyledContentCenter, StyledHeaderCenter, StyledHeaderLineIcon } from './Banner.styled'
import { BannerProps } from './Banner'
import theme from '../../theme'
import { Box } from 'rebass'
import Badge from '../Badge'

const getBadgeColorScheme = {
  success: {
    text: theme.colors.textWhite,
    bg: theme.colors.statusSuccess,
    line: theme.colors.statusSuccess
  },
  warning: {
    text: theme.colors.bgWhite,
    bg: theme.colors.statusWarning,
    line: theme.colors.statusWarning
  },
  error: {
    text: theme.colors.bgWhite,
    bg: theme.colors.statusError,
    line: theme.colors.statusError
  },
  info: {
    text: theme.colors.bgWhite,
    bg: theme.colors.statusInfo,
    line: theme.colors.statusInfo
  },
  white: {
    text: theme.colors.textDark,
    bg: theme.colors.bgWhite,
    line: theme.colors.bgGrayDark
  },
  orange: {
    text: theme.colors.textWhite,
    bg: theme.colors.primary,
    line: theme.colors.primary
  },
  gray: {
    text: theme.colors.textWhite,
    bg: theme.colors.bgGrayDark,
    line: theme.colors.bgGrayDark
  },
  black: {
    text: theme.colors.textWhite,
    bg: theme.colors.bgBlack,
    line: theme.colors.bgGrayDark
  }
}

const BannerIcon: FC<BannerProps> = ({
  variant,
  headline,
  subtitle,
  children,
  bgColor,
  headlineColor,
  textColor,
  subtitleColor,
  iconName,
  iconColor,
  sx,
  ...props
}) => {
  return (
    <StyledBannerIcon {...props} py={[4, 15]} bg={bgColor} sx={{ ...sx }}>
      <StyledContentCenter>
        <Container>
          {iconName && (
            <Badge
              mx="auto"
              mb={[2]}
              variant={'circle'}
              icon={iconName}
              contentColor={getBadgeColorScheme[iconColor].text}
              color={getBadgeColorScheme[iconColor].bg}
            />
          )}
          {subtitle && (
            <Text textAlign="center" color={subtitleColor}>
              {subtitle}
            </Text>
          )}
          {headline && (
            <StyledHeaderCenter mb={[3, 6]}>
              <Heading size="h4" fontWeight={theme.fontWeights.extraBold} textAlign="center" color={headlineColor}>
                {headline}
              </Heading>
              <StyledHeaderLineIcon bg={getBadgeColorScheme[iconColor].line} mt={[1, 3]} />
            </StyledHeaderCenter>
          )}
          {children && <Box color={textColor}>{children}</Box>}
        </Container>
      </StyledContentCenter>
    </StyledBannerIcon>
  )
}

BannerIcon.defaultProps = {
  variant: 'center',
  headline: '',
  subtitle: '',
  children: null,
  sx: {}
}

export default BannerIcon
