import React from 'react'
import { TestimonialProps, TestimonialsCarousel, Heading } from '@rmr/components'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { Box } from 'rebass'
import styled from '@emotion/styled'

interface StyledWrapperProps {
  bgImage: string
}

const StyledWrapper = styled(Box)<StyledWrapperProps>`
  background-image: url(${(props) => (props.bgImage ? props.bgImage : null)});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`

const TestimonialsWrapper = ({ headline, testimonials, backgroundImage }) => {
  const testimonailsWithImage: TestimonialProps[] = testimonials.map((testimonial) => ({
    ...testimonial,
    image: (
      <GatsbyImage
        fluid={testimonial.testimonialImage && testimonial.testimonialImage.localFile.childImageSharp.fluid}
      />
    )
  }))
  return (
    <StyledWrapper py={[4, 10]} textAlign="center" bgImage={backgroundImage.sourceUrl}>
      {headline && <Heading size="h4">{headline}</Heading>}
      <TestimonialsCarousel testimonials={testimonailsWithImage} variant="testimonialsCarousel" />
    </StyledWrapper>
  )
}
export default TestimonialsWrapper

export const TestimonialFields = graphql`
  fragment TestimonialFields on WpPage_Layoutsettings_Layout_Testimonials {
    headline
    backgroundImage {
      sourceUrl
    }
    testimonials: testimonialsOptions {
      cite
      quote
      testimonialImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1900, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
