import React from 'react'
import { Flex, Image } from 'rebass'
import ImageCard from '../../ImageCard'
import Icon from '../../Icon'
import { FileExtended } from '../types'

interface FileCardProps {
  file: FileExtended
  onClose: () => void
}

const FileCard = ({ file, onClose }: FileCardProps) => {
  let element: JSX.Element
  if (file.type.toLowerCase().includes('image')) {
    element = <Image src={file.preview} maxWidth={[100]} />
  } else {
    element = (
      <Flex flexDirection="column" alignItems="center">
        <Icon name="paperClip" fontAwesomeIconProps={{ size: '4x' }} />
      </Flex>
    )
  }
  return <ImageCard image={element} label={file.name} onClose={onClose} />
}

export default FileCard
