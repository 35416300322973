import React from 'react'
import { Box, BoxProps } from 'rebass'

import Container from '../../Grid/Container'
import Link from '../../Link'
import { MenuItemType } from '../../MenuItem'
import Text from '../../Text'
import {
  sectionStyles,
  navigationWrapperStyles,
  textStyles,
  listStyles,
  listItemStyles
} from './FooterNavigation.styled'

interface FooterNavigation extends BoxProps {
  /** Menu Items */
  menuItems?: MenuItemType[]
}

const FooterNavigation = ({ menuItems }: FooterNavigation) => (
  <Box sx={sectionStyles}>
    <Container>
      <Box sx={navigationWrapperStyles}>
        {menuItems.map(({ name, subMenuItems }, index) => (
          <Box key={index}>
            <Text size={'large'} fontWeight="extraBold" color="textWhite" sx={textStyles}>
              {name}
            </Text>
            <Box as="ul" sx={listStyles}>
              {subMenuItems.map(({ name, to }, i) => {
                return (
                  <Box key={i} as="li" sx={listItemStyles}>
                    <Link to={to}>{name}</Link>
                  </Box>
                )
              })}
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  </Box>
)

export default FooterNavigation
